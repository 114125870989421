import React, { useContext, useRef, useEffect } from 'react';
import {
  Typography,
  makeStyles,
  Grid,
  Paper,
  TextField,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import { IPassengerDetails } from '../interfaces/passenger';
import { Passenger } from '../models/Models';
import Button from './Button';
import { borderControlStatus } from '../constants/enums';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import {
  showSymptomSamplingNumber,
  disableSymptomSamplingNumber,
} from '../utils';

interface IProps {
  passenger: Passenger;
  passengerDetails: IPassengerDetails[];
  samplingNumberValue: string;
  validSamplingNumber?: boolean;
  loading: boolean;
  onSubmit: () => void;
  changeSamplingNumber: (samplingNumber: string) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  descriptionTitle: {
    display: 'inline-block',
    fontWeight: 'bold',
    paddingRight: 10,
  },
  descriptionDetails: {
    display: 'inline-block',
  },
  input: {
    margin: theme.spacing(1, 0),
  },
  buttonGrid: {
    textAlign: 'right',
  },
}));

const SymptomPassengerInfo: React.FC<IProps> = ({
  passenger,
  passengerDetails,
  samplingNumberValue,
  validSamplingNumber,
  loading,
  onSubmit,
  changeSamplingNumber,
}) => {
  const theme = useTheme();
  const classes = useStyles({});
  const [lang] = useContext(LangContext);
  const idInput = useRef<HTMLInputElement>(null);
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const inputError =
    !!samplingNumberValue.length &&
    !validSamplingNumber &&
    !disableSymptomSamplingNumber(passenger);

  useEffect(() => {
    focusIdInput();
  }, [passenger]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={2}>
          {passengerDetails.map(renderInfoItem)}
          {showSymptomSamplingNumber(passenger) && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                autoFocus
                autoComplete="off"
                className={classes.input}
                label={translations.samplingNumber[lang]}
                variant="outlined"
                disabled={disableSymptomSamplingNumber(passenger)}
                inputProps={{ ref: idInput }}
                value={samplingNumberValue}
                error={inputError}
                onChange={(e) => changeSamplingNumber(e.target.value)}
              />
              {inputError && (
                <Typography variant="body2" color="error">
                  {translations.prePrintedSamplingNumberError[lang]}
                </Typography>
              )}
            </Grid>
          )}
          {renderButtons()}
        </Grid>
      </Paper>
    </form>
  );

  function renderButtons() {
    if (
      passenger.borderControlStatus &&
      passenger.borderControlStatus.id ===
        borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_NOT_REQUIRED
    ) {
      return (
        <Grid item xs={12}>
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={xsDown ? 12 : undefined}>
              <Button
                type="submit"
                fullWidth={xsDown}
                disabled={!validSamplingNumber || loading}
              >
                {translations.finishSampling[lang]}
              </Button>
            </Grid>
            <Grid item xs={xsDown ? 12 : undefined}>
              <Button
                variant="outlined"
                fullWidth={xsDown}
                onClick={clearInput}
              >
                {translations.clear[lang]}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  }

  function renderInfoItem(
    { label, render, getVal = () => '', hide }: IPassengerDetails,
    index: number
  ) {
    return hide && hide(passenger) ? null : (
      <Grid item xs={12} key={index}>
        <Typography className={classes.descriptionTitle}>{label}:</Typography>
        {render ? (
          render(passenger)
        ) : (
          <Typography
            className={classes.descriptionDetails}
            color="textSecondary"
          >
            {getVal(passenger)}
          </Typography>
        )}
      </Grid>
    );
  }

  function clearInput() {
    changeSamplingNumber('');
    focusIdInput();
  }

  function focusIdInput() {
    if (idInput && idInput.current) {
      idInput.current.focus();
    }
  }
};

export default SymptomPassengerInfo;
