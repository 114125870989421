import React, { useContext, useState, useEffect, useCallback } from 'react';
import { AppBar, Tabs, Tab, Typography, Box, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Layout from '../components/Layout';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import useNotifier from '../hooks/useNotifier';
import Section from '../components/Section';
import VaccineForm, { IFormValues } from '../components/VaccineForm';
import VaccineList from '../components/VaccineList';
import { FormikHelpers } from 'formik';
import * as vaccinationService from '../services/vaccinationService';
import { Vaccine } from '../models/VaccinationModels';
import { IOption } from '../components/Autocomplete';
import {
  ICreateVaccinationCode,
  IUpdateVaccinationCode,
} from '../interfaces/vaccinationCodes';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const LotNumberControlPage: React.FC = () => {
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);
  const [vaccines, setVaccines] = useState<Vaccine[] | null>(null);
  const [vaccineOptions, setVaccineOptions] = useState<IOption[]>([]);
  const [editSelectedVaccine, setEditSelectVaccine] = useState<Vaccine | null>(
    null
  );
  const { notifyError, notifySuccess } = useNotifier();

  const getVaccines = useCallback(() => {
    vaccinationService
      .getVaccines()
      .then((response) => {
        if (response.isOk) {
          const options = response.data.map((o) => {
            return { label: o.name, value: o.atc };
          });
          const uniqOptions = options.filter(
            (option, index, self) =>
              index ===
              self.findIndex(
                (t) => t.label === option.label && t.value === option.value
              )
          );

          setVaccines(response.data);
          setVaccineOptions(uniqOptions);
        } else if (response.statusCode === 401) {
          history.push('/login');
        }
      })
      .catch((e) => {
        setVaccines([]);
      });
  }, [history]);

  useEffect(() => {
    getVaccines();
  }, [getVaccines]);

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    setEditSelectVaccine(null);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={0}>{children}</Box>}
      </Typography>
    );
  }

  const [lang] = useContext(LangContext);
  return (
    <Layout useDefaultSpacing title={translations.lotNumber[lang]}>
      <Section>
        <Paper elevation={2}>
          <AppBar position="static">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="fullWidth"
              scrollButtons="auto"
            >
              <Tab label={translations.addLotNumber[lang]} />
              <Tab label={translations.searchByLotNumber[lang]} />
            </Tabs>
          </AppBar>
          <TabPanel value={tabValue} index={0}>
            <VaccineForm
              vaccineOptions={vaccineOptions}
              onSubmitForm={submitLot}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {vaccines && (
              <VaccineList
                vaccines={vaccines}
                editSelectedVaccine={setEditVaccine}
              />
            )}
            {editSelectedVaccine && (
              <VaccineForm
                vaccineOptions={vaccineOptions}
                editVaccine={editSelectedVaccine}
                onSubmitForm={submitEditUser}
                clearEditVaccine={() => setEditSelectVaccine(null)}
              ></VaccineForm>
            )}
          </TabPanel>
        </Paper>
      </Section>
    </Layout>
  );

  function submitLot(
    values: IFormValues,
    { resetForm }: FormikHelpers<IFormValues>
  ) {
    const vaccineLot: ICreateVaccinationCode = {
      atc: values.atc,
      lotNumber: values.lotNumber,
      description: values.description || undefined,
    };
    vaccinationService
      .createVaccinationCode(vaccineLot)
      .then((response) => {
        if (response.isOk) {
          notifySuccess(translations.LotNumberAdded[lang]);
          getVaccines();
          resetForm();
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
        } else {
          notifyError(translations.errorOccurred[lang]);
        }
      })
      .catch((e) => {
        notifyError(translations.errorOccurred[lang]);
      });
  }

  function submitEditUser(
    values: IFormValues,
    { resetForm }: FormikHelpers<IFormValues>
  ) {
    const vaccineLot: IUpdateVaccinationCode = {
      lotNumber: values.lotNumber,
      description: values.description || undefined,
      isActive: values.active,
    };
    if (editSelectedVaccine) {
      vaccinationService
        .updateVaccinationCode(editSelectedVaccine.id.toString(), vaccineLot)
        .then((response) => {
          if (response.isOk) {
            notifySuccess(translations.LotNumberChanged[lang]);
            getVaccines();
            resetForm();
            setEditSelectVaccine(null);
          } else if (response.statusCode === 401) {
            notifyError(translations.status401[lang]);
          } else {
            notifyError(translations.errorOccurred[lang]);
          }
        })
        .catch((e) => {
          notifyError(translations.errorOccurred[lang]);
        });
    }
  }

  function setEditVaccine(vaccine: Vaccine) {
    setEditSelectVaccine(vaccine);
  }
};
export default LotNumberControlPage;
