import React from 'react';
import { makeStyles, Grid, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import cx from 'classnames';

interface IProps {
  count: number;
  disabled?: boolean;
  setCount: (count: number) => void;
}

const useStyles = makeStyles((theme) => ({
  emptyText: {
    display: 'inline-block',
  },
  container: {
    display: 'inline',
    '&>*': {
      display: 'inline',
    },
  },
  count: {
    fontSize: 18,
    padding: theme.spacing(1),
  },
  button: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  disabled: {
    backgroundColor: theme.palette.grey[400],
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
}));

const ChildCounter: React.FC<IProps> = ({
  count,
  setCount,
  disabled = false,
}) => {
  const classes = useStyles();
  const disableRemove = count <= 0;
  const disableAdd = count >= 99;

  if (disabled) {
    return (
      <Typography className={classes.emptyText} color="textSecondary">
        {count}
      </Typography>
    );
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.container}
    >
      <IconButton
        size="small"
        color="primary"
        onClick={() => !disableRemove && setCount(count - 1)}
        className={cx(classes.button, { [classes.disabled]: disableRemove })}
        disableRipple={disableRemove}
      >
        <RemoveIcon />
      </IconButton>
      <Typography color="textSecondary" className={classes.count}>
        {count}
      </Typography>
      <IconButton
        size="small"
        color="primary"
        onClick={() => !disableAdd && setCount(count + 1)}
        className={cx(classes.button, { [classes.disabled]: disableAdd })}
        disableRipple={disableAdd}
      >
        <AddIcon />
      </IconButton>
    </Grid>
  );
};

export default ChildCounter;
