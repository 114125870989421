import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button as MuiButton,
  ButtonGroup,
} from '@material-ui/core';

import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

import Button from './Button';
import { Moment } from 'moment';
import moment from 'moment';
import { DATE_AND_TIME_FORMAT } from '../constants';
import { IVaccinationGroupDetails } from '../interfaces/vaccinationGroup';

import theme from '../theme';
import { VaccinationPerson } from '../models/VaccinationModels';

interface IProps {
  open: boolean;
  onCancel: () => void;
  group: IVaccinationGroupDetails | null;
}

type ISummoningType = 'details' | 'schedule';

const useStyles = makeStyles(() => ({
  descriptionTitle: {
    display: 'inline-block',
    fontWeight: 'bold',
    paddingRight: 10,
  },
  descriptionDetails: {
    display: 'inline-block',
  },
  table: {
    maxHeight: 400,
    '& th': {
      fontWeight: 600,
      fontSize: 15,
      paddingTop: 0,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  tabs: {
    width: '100%',
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const VaccinationGroupSummoningInfoDialog: React.FC<IProps> = ({
  open,
  onCancel,
  group,
}) => {
  const [lang] = useContext(LangContext);
  const classes = useStyles();
  const [summoningType, setSummoningType] = useState<ISummoningType>('details');

  const isSummoningDetails = summoningType === 'details';

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle>{translations.viewSummoning[lang]}</DialogTitle>
      <DialogContent style={{ minHeight: 80 }} dividers>
        <Grid container spacing={2}>
          <React.Fragment>
            <ButtonGroup
              disableElevation
              color="primary"
              className={classes.tabs}
            >
              <MuiButton
                style={{ flex: 1 }}
                variant={isSummoningDetails ? 'contained' : 'outlined'}
                onClick={() => setSummoningType('details')}
              >
                {translations.latestSummoning[lang]}
              </MuiButton>
              <MuiButton
                style={{ flex: 1 }}
                variant={isSummoningDetails ? 'outlined' : 'contained'}
                onClick={() => setSummoningType('schedule')}
              >
                {translations.summoningSchedule[lang]}
              </MuiButton>
            </ButtonGroup>
            {isSummoningDetails && renderSummary(createSummary())}
            {!isSummoningDetails && renderSummoningSchedule()}
          </React.Fragment>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()} variant="outlined" padding={false}>
          {translations.back[lang]}
        </Button>
      </DialogActions>
    </Dialog>
  );

  function renderSummary(summary: any) {
    return (
      <React.Fragment>
        {summary.map((s: any, index: number) => (
          <Grid item xs={12} key={index}>
            <Typography className={classes.descriptionTitle}>
              {s.label}:
            </Typography>

            <Typography
              className={classes.descriptionDetails}
              color="textSecondary"
            >
              {s.value}
            </Typography>
          </Grid>
        ))}
      </React.Fragment>
    );
  }

  function createSummary() {
    const details = [
      {
        label: translations.name[lang],
        value: group ? group.name : '',
      },
      {
        label: translations.numberOfPersonsInSummoning[lang],
        value: group?.summoningCount ? group.summoningCount.toString() : '',
      },
      {
        label: translations.vaccinationBegins[lang],
        value:
          (group?.summonGroupDate &&
            moment(group.summonGroupDate).format(DATE_AND_TIME_FORMAT)) ||
          '',
      },
      {
        label: translations.vaccinationEnds[lang],
        value:
          (group?.summonGroupDate &&
            group?.slotCapacity &&
            group?.slotLenght &&
            calculateSummoningEndDate(
              moment(group.summonGroupDate),
              group.slotLenght,
              group.slotCapacity
            )) ||
          '',
      },
      {
        label: translations.message[lang],
        value: group?.smsText || '',
      },
      {
        label: translations.numberOfPersonsPerSlot[lang],
        value: group?.slotCapacity || '',
      },
      {
        label: translations.slotDuration[lang],
        value: group?.slotLenght || '',
      },
    ];
    return details;
  }

  function renderSummoningSchedule() {
    return (
      <Grid item xs={12}>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.name[lang]}</TableCell>
                <TableCell align="center">{translations.SSN[lang]}</TableCell>
                <TableCell align="center">
                  {translations.summoning[lang]}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {group?.members &&
                sortGroup(group.members).map((member, index) => (
                  <TableRow hover key={member.ssn}>
                    <TableCell>{member.name}</TableCell>
                    <TableCell align="center">{member.ssn}</TableCell>
                    <TableCell align="center">
                      {member.summoningDate
                        ? moment(member.summoningDate).format(
                            DATE_AND_TIME_FORMAT
                          )
                        : ''}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  }

  function calculateSummoningEndDate(
    startDate: Moment,
    slotDuration: string,
    slotCapacity: string
  ) {
    if (slotCapacity && slotDuration && group?.summoningCount) {
      const groupCount = group.summoningCount;
      const slotCount = groupCount / parseFloat(slotCapacity);
      const addMinutes = Math.ceil(slotCount) * parseInt(slotDuration);
      return moment(startDate)
        .add(addMinutes, 'minutes')
        .format(DATE_AND_TIME_FORMAT);
    }
    return translations.vaccinationEndsNotDefined[lang];
  }

  function sortGroup(members: VaccinationPerson[]) {
    const newArray = [...members].sort(function (
      a: VaccinationPerson,
      b: VaccinationPerson
    ) {
      if (!a.summoningDate) {
        return -1;
      }
      if (!b.summoningDate) {
        return 1;
      }
      return moment(a.summoningDate).isAfter(b.summoningDate) ? 1 : -1;
    });
    return newArray;
  }
};

export default VaccinationGroupSummoningInfoDialog;
