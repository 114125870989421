import React, { useContext } from 'react';
import { Grid, Paper } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';

import { LangContext } from '../../../context/LangContext';
import translations from '../../../assets/json/translations.json';
import { ILocationOption } from '../../../pages/LocationSettingsPage';
import { makeStyles } from '@material-ui/styles';
import Autocomplete from '../../Autocomplete';
import theme from '../../../theme';
import Button from '../../Button';
import { useHistory } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';

interface IProps {
  locationOptions: ILocationOption[];
  location: ILocationOption | null;
  changeLocation: (val: any) => void;
  openSettings: (val: boolean) => void;
}

const useStyles = makeStyles(() => ({
  paper: {
    padding: theme.spacing(2),
  },
  icon: {
    //color: 'white',
    marginLeft: theme.spacing(1),
  },
  settingsContainer: {
    marginRight: theme.spacing(2),
  },
}));

const ScheduleLocationForm: React.FC<IProps> = ({
  locationOptions,
  location,
  changeLocation,
  openSettings,
}) => {
  const [lang] = useContext(LangContext);
  const classes = useStyles();
  const history = useHistory();

  return (
    <Paper className={classes.paper} elevation={2}>
      <Grid container spacing={2} direction="row" justify="space-between">
        <Grid item xs={6}>
          <Autocomplete
            fullWidth
            required
            id="location"
            value={location}
            label={translations.location[lang]}
            items={locationOptions}
            onChange={changeLocation}
          />
        </Grid>
        {location && (
          <Grid item>
            <Button
              className={classes.settingsContainer}
              variant="outlined"
              onClick={() => openSettings(true)}
            >
              {translations.settings[lang]}
              <SettingsIcon className={classes.icon} />
            </Button>
            <Button
              color="secondary"
              onClick={() => history.push(`/schedule/${location.value}`)}
            >
              {translations.calendar[lang]}
              <DateRangeIcon className={classes.icon} />
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default ScheduleLocationForm;
