import React, { useContext, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Layout from '../components/Layout';
import Section from '../components/Section';
import VaccinationGroupList from '../components/VaccinationGroupList';
import VaccinationGroupFilter from '../components/VaccinationGroupFilter';
import LoadingIndicator from '../components/LoadingIndicator';
import useNotifier from '../hooks/useNotifier';
import { LangContext } from '../context/LangContext';
import { VaccinationGroup } from '../models/VaccinationModels';
import translations from '../assets/json/translations.json';
import { TABLE_ROWS_DEFAULT } from '../constants';
import * as vaccinationService from '../services/vaccinationService';
import * as localStorageKeys from '../constants/localStorageKeys';

const GroupVaccinationPage: React.FC = () => {
  const history = useHistory();
  const [lang] = useContext(LangContext);
  const { notifyError } = useNotifier();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [groups, setGroupsList] = useState<VaccinationGroup[] | null>(null);
  const [filteredGroups, setFilteredGroups] = useState<VaccinationGroup[]>([]);
  const [regionSelected, setRegionSelected] = useState(false);

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  useEffect(() => {
    const fetchGroups = async () => {
      const response = await vaccinationService.getGroups();
      if (response.isOk) {
        setGroupsList(response.data);
      } else if (response.statusCode === 401) {
        history.push('/login');
      } else {
        setGroupsList([]);
      }
    };
    fetchGroups();
  }, [history]);

  useEffect(() => {
    setCurrentPage(0);
  }, [filteredGroups]);

  return (
    <Layout useDefaultSpacing title={translations.groupVaccination[lang]}>
      <Section>{renderContent()}</Section>
    </Layout>
  );

  function renderContent() {
    if (groups === null) {
      return (
        <Grid container justify="center" alignItems="center">
          <LoadingIndicator />
        </Grid>
      );
    }

    return (
      <React.Fragment>
        <VaccinationGroupFilter
          groups={groups}
          localStorageKey={localStorageKeys.GROUP_VACCINATION_FILTER_KEY}
          filterGroups={setFilteredGroups}
          setRegionSelected={setRegionSelected}
        />
        <VaccinationGroupList
          groups={filteredGroups}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          regionSelected={regionSelected}
          setCurrentPage={setCurrentPage}
          setRowsPerPage={setRowsPerPage}
          onClick={(group) => history.push(`/group-vaccination/${group.id}`)}
        />
      </React.Fragment>
    );
  }
};

export default GroupVaccinationPage;
