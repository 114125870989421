import React, { useContext, useState, useEffect } from 'react';
import { Typography, makeStyles, Grid, Paper } from '@material-ui/core';

import { Vaccine, VaccinationLocation } from '../models/VaccinationModels';
import Button from './Button';
import ConfirmDialog from './ConfirmDialog';
import Dropdown from './Dropdown';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { getFormattedVaccineName } from '../utils';
import * as localStorageKeys from '../constants/localStorageKeys';

import AutocompleteGroup, { IGroupOption } from './AutocompleteGroup';

export interface IProps {
  vaccines: Vaccine[] | null;
  locations: VaccinationLocation[] | null;
  selectedVaccine: Vaccine | null;
  selectedLocation: VaccinationLocation | null;
  setSelectedVaccine: (vaccine: Vaccine | null) => void;
  setSelectedLocation: (location: VaccinationLocation) => void;
}

const useStyles = makeStyles((theme) => ({
  options: {
    padding: theme.spacing(3),
  },
  optionsTitle: {
    marginBottom: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.main,
  },
  descriptionTitle: {
    fontWeight: 'bold',
    paddingRight: 10,
  },
  descriptionDetails: {
    color: theme.palette.text.secondary,
  },
  editButton: {
    marginLeft: theme.spacing(2),
  },
}));

const VaccinationSettingsGroup: React.FC<IProps> = ({
  vaccines,
  locations,
  selectedVaccine,
  selectedLocation,
  setSelectedVaccine,
  setSelectedLocation,
}) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [updatedVaccineId, setUpdatedVaccineId] = useState<IGroupOption | null>(
    null
  );

  const [updatedLocationId, setUpdatedLocationId] = useState('');

  const loading = vaccines === null || locations === null;
  const filteredVaccines = vaccines
    ? vaccines.filter((v) => v.vaccineIsActive)
    : [];

  useEffect(() => {
    if (!loading && !selectedVaccine) {
      setEditDialogOpen(true);
    }
  }, [loading, selectedVaccine]);

  useEffect(() => {
    setUpdatedVaccineId(
      selectedVaccine
        ? {
            label: getFormattedVaccineName(selectedVaccine),
            value: selectedVaccine.id.toString(),
            filter: selectedVaccine.name,
          }
        : null
    );
    setUpdatedLocationId(
      selectedLocation ? selectedLocation.id.toString() : ''
    );
  }, [selectedVaccine, selectedLocation, editDialogOpen]);

  return (
    <Paper elevation={2} className={classes.options}>
      <Grid container direction="row" alignItems="flex-start">
        <div style={{ flex: 1 }}>
          {loading ? (
            <Typography
              className={classes.descriptionDetails}
              color="textSecondary"
            >
              {translations.loadingSettings[lang]}
            </Typography>
          ) : (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                {renderInfoItem(
                  translations.covid19Vaccine[lang],
                  selectedVaccine
                    ? getFormattedVaccineName(selectedVaccine)
                    : null
                )}
              </Grid>

              <Grid item>
                {renderInfoItem(
                  translations.location[lang],
                  selectedLocation ? selectedLocation.name : null
                )}
              </Grid>
            </Grid>
          )}
        </div>
        <Button
          padding={false}
          color="secondary"
          disabled={loading}
          onClick={() => setEditDialogOpen(true)}
          className={classes.editButton}
        >
          {translations.edit[lang]}
        </Button>
      </Grid>
      {renderDialog()}
    </Paper>
  );

  function renderInfoItem(title: string, text: string | null) {
    return (
      <Typography>
        <span className={classes.descriptionTitle}>{title}:</span>
        {text ? (
          <span className={classes.descriptionDetails}>{text}</span>
        ) : (
          <span className={classes.errorText}>
            {translations.notRegistered[lang]}
          </span>
        )}
      </Typography>
    );
  }

  function renderDialog() {
    return (
      <ConfirmDialog
        open={editDialogOpen}
        title={translations.settings[lang]}
        disableConfirm={
          (!updatedLocationId || !updatedVaccineId) &&
          updatedVaccineId?.value === selectedVaccine?.id.toString() &&
          updatedLocationId === selectedLocation?.id.toString()
        }
        onConfirm={updateSettings}
        onCancel={() => setEditDialogOpen(false)}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <AutocompleteGroup
              fullWidth
              label={translations.covid19Vaccine[lang]}
              id="vaccine"
              value={updatedVaccineId}
              items={filteredVaccines
                .map((v) => ({
                  label: getFormattedVaccineName(v),
                  value: v.id.toString(),
                  filter: v.name,
                }))
                .sort((a, b) => a.filter.localeCompare(b.filter))}
              error={!updatedVaccineId}
              onChange={(val) =>
                setUpdatedVaccineId(val as IGroupOption | null)
              }
            />
          </Grid>

          <Grid item>
            <Dropdown
              fullWidth
              id="location"
              value={updatedLocationId}
              label={translations.location[lang]}
              items={(locations || []).map((l) => ({
                label: l.name,
                value: l.id.toString(),
              }))}
              onChange={(e) => setUpdatedLocationId(e.target.value as string)}
              error={!updatedLocationId}
              disabled={!locations || locations.length <= 1}
            />
          </Grid>
        </Grid>
      </ConfirmDialog>
    );
  }

  function updateSettings() {
    const updatedVaccine = filteredVaccines?.find(
      (v) => v.id.toString() === updatedVaccineId?.value
    );

    const updatedLocation = locations?.find(
      (l) => l.id.toString() === updatedLocationId
    );

    if (updatedVaccine && updatedVaccineId) {
      setSelectedVaccine(updatedVaccine);
      localStorage.setItem(localStorageKeys.VACCINE_ID, updatedVaccineId.value);
    }
    if (updatedLocation) {
      setSelectedLocation(updatedLocation);
      localStorage.setItem(
        localStorageKeys.VACCINATION_LOCATION_ID,
        updatedLocationId
      );
    }

    setEditDialogOpen(false);
  }
};

export default VaccinationSettingsGroup;
