import React, { useContext, useState, useEffect } from 'react';
import moment, { Moment } from 'moment';

import Layout from '../components/Layout';
import useNotifier from '../hooks/useNotifier';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import Vaccination from '../components/Vaccination';

import {
  Vaccine,
  VaccinationLocation,
  VaccinationGroup,
} from '../models/VaccinationModels';
import * as vaccinationService from '../services/vaccinationService';
import * as localStorageKeys from '../constants/localStorageKeys';
import { IVaccinationCommentStatus } from '../interfaces/vaccinationComment';
import { typeOfVaccination, VaccineType } from '../constants/enums';

const VaccinationPage: React.FC = () => {
  const { notifyError } = useNotifier();
  const [lang] = useContext(LangContext);

  const [vaccines, setVaccines] = useState<Vaccine[] | null>(null);
  const [influenzaVaccines, setInfluenzaVaccines] = useState<Vaccine[] | null>(
    null
  );
  const [locations, setLocations] = useState<VaccinationLocation[] | null>(
    null
  );
  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine | null>(null);
  const [selectedLocation, setSelectedLocation] =
    useState<VaccinationLocation | null>(null);
  /* const [selectedFirstOrSecond, setSelectedFirstOrSecond] =
    useState<IFirstOrSecondVaccination | null>(null);
  const [selectedAllowMoreThanTwo, setSelectedAllowMoreThanTwo] =
    useState(false); */
  const [selectedInfluenzaVaccine, setSelectedInfluenzaVaccine] =
    useState<Vaccine | null>(null);
  const [selectedTypeOfVaccination, setSelectedTypeOfVaccination] =
    useState('');
  const [commentStatusList, setCommentStatusList] = useState<
    IVaccinationCommentStatus[] | null
  >(null);
  const [selectedYearFrom, setSelectedYearFrom] = useState<string | null>(null);
  const [selectedYearTo, setSelectedYearTo] = useState<string | null>(null);
  const [selectedSummoningFrom, setSelectedSummoningFrom] =
    useState<Moment | null>(null);
  const [selectedSummoningTo, setSelectedSummoningTo] = useState<Moment | null>(
    null
  );
  const [groups, setGroups] = useState<VaccinationGroup[] | null>(null);
  const [selectedGroups, setSelectedGroups] = useState<
    VaccinationGroup[] | null
  >(null);
  const [selectedAlwaysAllowSecond, setSelectedAlwaysAllowSecond] =
    useState(false);

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  useEffect(() => {
    const fetchSettings = async () => {
      const vaccinePromise = vaccinationService.getVaccines();
      const locationPromise = vaccinationService.getLocations();
      const [vaccineResponse, locationResponse] = await Promise.all([
        vaccinePromise,
        locationPromise,
      ]);

      const savedVaccinationType = localStorage.getItem(
        localStorageKeys.TYPE_OF_VACCINATION
      );
      if (
        savedVaccinationType === typeOfVaccination.COVID_19 ||
        savedVaccinationType === typeOfVaccination.INFLUENZA ||
        savedVaccinationType === typeOfVaccination.COVID_19_AND_INFLUENZA
      ) {
        setSelectedTypeOfVaccination(savedVaccinationType);
      }

      if (vaccineResponse.isOk) {
        const covidVaccines = vaccineResponse.data.filter(
          (item) => item.vaccineType === VaccineType.COVID_19
        );
        const newInfluenzaVaccines = vaccineResponse.data.filter(
          (item) => item.vaccineType === VaccineType.INFLUENZA
        );

        setVaccines(covidVaccines);
        setInfluenzaVaccines(newInfluenzaVaccines);

        const savedVaccineId = localStorage.getItem(
          localStorageKeys.VACCINE_ID
        );
        const savedVaccine = covidVaccines.find(
          (v) => v.id.toString() === savedVaccineId
        );
        if (savedVaccine) {
          setSelectedVaccine(savedVaccine);
        } else if (covidVaccines.length === 1) {
          setSelectedVaccine(covidVaccines[0]);
        }

        const savedInfluenzaVaccineId = localStorage.getItem(
          localStorageKeys.INFLUENZA_VACCINE_ID
        );
        const savedInfluenzaVaccine = newInfluenzaVaccines.find(
          (v) => v.id.toString() === savedInfluenzaVaccineId
        );

        if (savedInfluenzaVaccine) {
          setSelectedInfluenzaVaccine(savedInfluenzaVaccine);
        }
      } else {
        setVaccines([]);
      }

      if (locationResponse.isOk) {
        setLocations(locationResponse.data);
        const savedLocationId = localStorage.getItem(
          localStorageKeys.VACCINATION_LOCATION_ID
        );
        const savedLocation = locationResponse.data.find(
          (l) => l.id.toString() === savedLocationId
        );
        if (savedLocation) {
          setSelectedLocation(savedLocation);
        } else if (locationResponse.data.length === 1) {
          setSelectedLocation(locationResponse.data[0]);
        }
      } else {
        setLocations([]);
      }
    };
    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchCommentStatusList = async () => {
      const { data: statusList } =
        await vaccinationService.getVaccinationCommentStatusList();
      setCommentStatusList(statusList);
    };
    fetchCommentStatusList();
  }, []);

  useEffect(() => {
    const savedYearFrom = localStorage.getItem(
      localStorageKeys.VACCINATION_CONDITIONS_YEAR_FROM
    );
    const savedYearTo = localStorage.getItem(
      localStorageKeys.VACCINATION_CONDITIONS_YEAR_TO
    );
    const savedSummoningFrom = localStorage.getItem(
      localStorageKeys.VACCINATION_CONDITIONS_SUMMONING_FROM
    );
    const savedSummoningTo = localStorage.getItem(
      localStorageKeys.VACCINATION_CONDITIONS_SUMMONING_TO
    );
    const savedAlwaysAllowSecond = localStorage.getItem(
      localStorageKeys.VACCINATION_CONDITIONS_ALWAYS_ALLOW_SECOND
    );

    setSelectedYearFrom(
      savedYearFrom && !isNaN(parseInt(savedYearFrom)) ? savedYearFrom : null
    );
    setSelectedYearTo(
      savedYearTo && !isNaN(parseInt(savedYearTo)) ? savedYearTo : null
    );
    setSelectedSummoningFrom(
      savedSummoningFrom ? moment(savedSummoningFrom) : null
    );
    setSelectedSummoningTo(savedSummoningTo ? moment(savedSummoningTo) : null);
    setSelectedAlwaysAllowSecond(
      !!savedAlwaysAllowSecond && savedAlwaysAllowSecond === 'true'
    );
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      const { data: groups } = await vaccinationService.getGroups();
      const savedGroupIds = JSON.parse(
        localStorage.getItem(localStorageKeys.VACCINATION_CONDITIONS_GROUPS) ||
          '[]'
      );
      setGroups(groups);
      setSelectedGroups(
        (groups || []).filter((g) => savedGroupIds.includes(g.id))
      );
    };
    fetchGroups();
  }, []);

  return (
    <Layout useDefaultSpacing title={translations.vaccination[lang]}>
      <Vaccination
        vaccinationSettings={{
          vaccines,
          influenzaVaccines,
          locations,
          selectedVaccine,
          selectedLocation,
          selectedInfluenzaVaccine,
          selectedTypeOfVaccination,
          setSelectedVaccine,
          setSelectedLocation,
          setSelectedInfluenzaVaccine,
          setSelectedTypeOfVaccination,
        }}
        vaccinationConditions={{
          groups,
          selectedYearFrom,
          selectedYearTo,
          selectedSummoningFrom,
          selectedSummoningTo,
          selectedGroups,
          selectedAlwaysAllowSecond,
          setSelectedYearFrom,
          setSelectedYearTo,
          setSelectedSummoningFrom,
          setSelectedSummoningTo,
          setSelectedGroups,
          setSelectedAlwaysAllowSecond,
        }}
        commentStatusList={commentStatusList}
      />
    </Layout>
  );
};

export default VaccinationPage;
