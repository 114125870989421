import React, { useContext, useEffect, useState } from 'react';

import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  IconButton,
  TextField,
  Grid,
} from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EditIcon from '@material-ui/icons/Edit';

import { CertificateType, Passenger } from '../models/Models';
import { LangContext } from '../context/LangContext';
import ConfirmDialog from './ConfirmDialog';
import translations from '../assets/json/translations.json';
import * as httpService from '../services/httpService.v2';
import useNotifier from '../hooks/useNotifier';
import { accessControl } from '../constants/enums';

import { canChangeRapidTestResult, isAccessible, isRapidTest } from '../utils';
import { UserContext } from '../context/UserContext';
import Dropdown from './Dropdown';
import rapidTestStatuses from '../assets/json/rapidTestStatuses.json';

interface IProps {
  passenger: Passenger;
  updateRapidTestStatus: (
    passenger: Passenger,
    newStatus: number,
    onSuccess: () => void
  ) => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: `calc(100vh - ${theme.dimensions.navbarHeight}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    emptyText: {
      display: 'inline-block',
    },
    button: {
      padding: theme.spacing(0),
      marginLeft: theme.spacing(1),
      marginTop: -5,
    },
    buttonIcon: {
      fontSize: 23,
    },
    inputContainer: {
      marginTop: theme.spacing(1),
    },
  })
);

const SendResultEmail: React.FC<IProps> = ({
  passenger,
  updateRapidTestStatus,
}) => {
  const classes = useStyles({});
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const { notifyError, notifySuccess } = useNotifier();

  const [emailDialog, setEmailDialog] = useState(false);
  const [emailDialogText, setEmailDialogText] = useState('');
  const [rapidTestDialog, setRapidTestDialog] = useState(false);
  const [rapidTestStatus, setRapidTestStatus] = useState(
    passenger?.observationStatus?.id
      ? passenger.observationStatus.id.toString()
      : ''
  );
  const [emailInput, setEmailInput] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passportNumberInput, setPassportNumberInput] = useState('');
  const [showEmailIcon, setShowEmailIcon] = useState(false);
  const [typeOfCertificate, setTypeOfCertificate] =
    useState<CertificateType | null>(null);

  useEffect(() => {
    const checkIfCertificateAvailable = async () => {
      const response =
        await httpService.checkIfCertificateAvailableBasedOnBarcode(
          passenger.serialNumber
        );

      if (response.isOk && response.data && response.data.id) {
        setShowEmailIcon(true);
        setTypeOfCertificate(response.data);
      }
    };

    checkIfCertificateAvailable();
    if (passenger.email) {
      setEmailInput(passenger.email);
    } else {
      setEmailInput('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passenger]);

  function getObservationStatusTranslation(passenger: Passenger) {
    if (passenger.observationStatus) {
      if (passenger.observationStatus.id === 0 && !passenger.sampleTaken) {
        return translations.noSampleTaken[lang];
      } else {
        return passenger.observationStatus.description[lang];
      }
    } else {
      return '';
    }
  }

  return (
    <React.Fragment>
      <Typography className={classes.emptyText} color="textSecondary">
        {getObservationStatusTranslation(passenger)}
      </Typography>
      {showEmailIcon && isAccessible(accessControl.SUPER_SEARCH, user) && (
        <IconButton
          className={classes.button}
          color="primary"
          onClick={() => setEmailDialog(true)}
        >
          <MailOutlineIcon className={classes.buttonIcon} />
        </IconButton>
      )}
      {isRapidTest(passenger.sampleId) &&
        canChangeRapidTestResult(
          passenger.sampleTaken,
          passenger.observationStatus?.id
        ) && (
          <IconButton
            className={classes.button}
            color="primary"
            onClick={() => setRapidTestDialog(true)}
          >
            <EditIcon className={classes.buttonIcon} />
          </IconButton>
        )}
      {renderDialogs()}
    </React.Fragment>
  );

  function renderDialogs() {
    return (
      <>
        <ConfirmDialog
          open={emailDialog}
          title={`${translations.emailDialogTitle[lang]} (${typeOfCertificate?.description[lang]})`}
          text={emailDialogText}
          confirmText={translations.send[lang]}
          onConfirm={() => sendEmail()}
          onCancel={() => onCloseDialog()}
          error={!!emailDialogText}
        >
          {renderDialogContent()}
        </ConfirmDialog>
        <ConfirmDialog
          open={rapidTestDialog}
          title={translations.changeTestResult[lang]}
          onConfirm={() => changeRapidTestStatus()}
          onCancel={() => onCloseDialog()}
          disableConfirm={
            !rapidTestStatus ||
            passenger.observationStatus?.id.toString() === rapidTestStatus
          }
        >
          {renderRapidTestDialogContent()}
        </ConfirmDialog>
      </>
    );
  }

  function renderDialogContent() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            className={classes.inputContainer}
            fullWidth
            autoFocus
            autoComplete="off"
            id="email"
            label={translations.emailInputPlaceholder[lang]}
            variant="outlined"
            value={emailInput}
            type="email"
            error={emailError}
            onChange={(e) => setEmailInput(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.inputContainer}
            fullWidth
            autoComplete="off"
            id="passportNumber"
            label={translations.passportNumber[lang]}
            variant="outlined"
            value={passportNumberInput}
            onChange={(e) => setPassportNumberInput(e.target.value)}
          />
        </Grid>
      </Grid>
    );
  }

  function renderRapidTestDialogContent() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Dropdown
            fullWidth
            required
            id="testResult"
            value={rapidTestStatus}
            label={translations.testResult[lang]}
            items={rapidTestStatuses.map((item) => ({
              label: item.description[lang],
              value: item.id.toString(),
            }))}
            onChange={(e: any) => setRapidTestStatus(e.target.value)}
          />
        </Grid>
      </Grid>
    );
  }

  async function sendEmail() {
    if (validateEmail(emailInput)) {
      setEmailError(false);
      const payload = {
        barcode: passenger.serialNumber,
        email: emailInput,
        passportNumber: passportNumberInput,
      };
      const response = await httpService.sendCertificateBasedOnBarcode(payload);
      if (response.isOk) {
        notifySuccess(translations.emailSentDialogSuccess[lang]);
        onCloseDialog();
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      setEmailError(true);
    }
  }

  async function changeRapidTestStatus() {
    updateRapidTestStatus(passenger, Number(rapidTestStatus), () =>
      onCloseDialog()
    );
  }

  function validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function onCloseDialog() {
    setEmailDialog(false);
    setRapidTestDialog(false);
    setEmailError(false);
    setEmailDialogText('');
  }
};

export default SendResultEmail;
