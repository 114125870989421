import React, { useContext, useState } from 'react';
import {
  makeStyles,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { Formik, Form, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { IOption } from './Autocomplete';
import { LangContext } from '../context/LangContext';

import translations from '../assets/json/translations.json';
import Button from './Button';
import { Vaccine } from '../models/VaccinationModels';
import Dropdown from './Dropdown';

interface IProps {
  vaccineOptions: IOption[];
  onSubmitForm: (
    values: IFormValues,
    { resetForm }: FormikHelpers<IFormValues>
  ) => void;
  editVaccine?: Vaccine | null;
  clearEditVaccine?: () => void;
}

export interface IFormValues {
  atc: string;
  lotNumber: string;
  description: string;
  active: boolean;
}

type IFormik = FormikProps<IFormValues>;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  buttonGrid: {
    textAlign: 'right',
  },
  buttonFormContainer: {
    marginLeft: theme.spacing(2),
  },
  dialogContent: {
    padding: '0px',
    overflow: 'hidden',
  },
}));

const VaccineForm: React.FC<IProps> = ({
  vaccineOptions,
  onSubmitForm,
  editVaccine,
  clearEditVaccine,
}) => {
  const schema = Yup.object().shape({
    atc: Yup.string().required(),
    lotNumber: Yup.string().required(),
  });

  const classes = useStyles();
  const [lang] = useContext(LangContext);
  const [openDialog] = useState(true);

  if (editVaccine) {
    const vaccineValues: IFormValues = {
      atc: editVaccine.atc,
      lotNumber: editVaccine.lotNumber,
      description: editVaccine.description || '',
      active: editVaccine.vaccineIsActive,
    };

    return (
      <Dialog
        open={openDialog}
        onClose={() => {
          clearEditVaccine && clearEditVaccine();
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{editVaccine.name}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {initializeFormik(vaccineValues)}
        </DialogContent>
      </Dialog>
    );
  }

  return <React.Fragment>{initializeFormik(null)}</React.Fragment>;

  function initializeFormik(vaccine: IFormValues | null) {
    return (
      <Formik
        enableReinitialize
        onSubmit={onSubmitForm}
        initialValues={
          vaccine || { atc: '', lotNumber: '', description: '', active: false }
        }
        validationSchema={schema}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} className={classes.paper}>
              {renderForm(formik)}
              {renderButtons(formik)}
            </Grid>
          </Form>
        )}
      </Formik>
    );
  }

  function renderForm({
    values,
    errors,
    handleChange,
    handleBlur,
    submitCount,
  }: IFormik) {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Dropdown
            fullWidth
            required
            id="atc"
            value={values.atc}
            label={translations.vaccine[lang]}
            items={vaccineOptions}
            onChange={handleChange}
            disabled={!!editVaccine}
            error={!!errors.atc && !!submitCount}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoComplete="off"
            required
            id="lotNumber"
            label={translations.lotNumber[lang]}
            variant="outlined"
            value={values.lotNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.lotNumber && !!submitCount}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoComplete="off"
            id="description"
            label={translations.vaccineDescription[lang]}
            variant="outlined"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        {editVaccine && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  id="active"
                  checked={values.active}
                  onChange={handleChange}
                  color="primary"
                />
              }
              label={translations.active[lang]}
            />
          </Grid>
        )}
      </React.Fragment>
    );
  }

  function renderButtons(formik: IFormik) {
    if (editVaccine) {
      return (
        <Grid item xs={12} className={classes.buttonGrid}>
          <Button type="submit" disabled={!formik.dirty || formik.isSubmitting}>
            {translations.editLotNumber[lang]}
          </Button>
          <Button
            className={classes.buttonFormContainer}
            onClick={() => {
              clearEditVaccine && clearEditVaccine();
            }}
            variant="outlined"
          >
            {translations.cancel[lang]}
          </Button>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} className={classes.buttonGrid}>
        <Button type="submit" disabled={formik.isSubmitting}>
          {translations.addLotNumber[lang]}
        </Button>
      </Grid>
    );
  }
};

export default VaccineForm;
