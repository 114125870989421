import React, { useContext } from 'react';
import {
  Grid,
  useTheme,
  useMediaQuery,
  Paper,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import moment, { Moment } from 'moment';
import * as Yup from 'yup';

import Button from './Button';
import DatePicker from './DatePicker';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

import { Form, Formik, FormikProps } from 'formik';

interface IProps {
  onSubmit: (values: IFormValues) => void;
  onClear: () => void;
}

export interface IFormValues {
  arrivalsFrom: Moment | null;
  arrivalsTo: Moment | null;
  hidePreviusCopied: boolean;
}

type IFormik = FormikProps<IFormValues>;

const QuarantinePassengerListFilterOld: React.FC<IProps> = ({
  onSubmit,
  onClear,
}) => {
  const theme = useTheme();
  const [lang] = useContext(LangContext);

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const schema = Yup.object().shape({
    arrivalsFrom: Yup.string().required(),
    arrivalsTo: Yup.string().required(),
  });

  return (
    <Grid>
      <Paper elevation={2} style={{ padding: theme.spacing(2) }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ marginBottom: theme.spacing(1) }}
        ></Grid>
        <Formik
          onSubmit={(values) => onSubmit(values)}
          initialValues={getInitalValues()}
          validationSchema={schema}
          //enableReinitialize
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>{renderFilters(formik)}</Form>
          )}
        </Formik>
      </Paper>
    </Grid>
  );

  function renderFilters(formik: IFormik) {
    const {
      values,
      errors,
      submitCount,
      handleChange,
      handleBlur,
      setFieldValue,
      isSubmitting,
    } = formik;
    return (
      <Grid container spacing={2}>
        <Grid
          item
          xs={smDown ? 12 : undefined}
          sm={smDown ? 6 : undefined}
          md={smDown ? 4 : undefined}
          style={{ flex: !smDown ? 1 : undefined }}
        >
          <DatePicker
            id="arrivalsFrom"
            label={translations.arrivalsFrom[lang]}
            value={values.arrivalsFrom}
            muiProps={{
              disableToolbar: true,
              //maxDate: selectedToDate || undefined,
            }}
            onChange={(e) =>
              setFieldValue('arrivalsFrom', e ? moment(e) : null)
            }
            onBlur={handleBlur}
            error={!!errors.arrivalsFrom && !!submitCount}
          />
        </Grid>

        <Grid
          item
          xs={smDown ? 12 : undefined}
          sm={smDown ? 6 : undefined}
          md={smDown ? 4 : undefined}
          style={{ flex: !smDown ? 1 : undefined }}
        >
          <DatePicker
            id="arrivalsTo"
            label={translations.arrivalsTo[lang]}
            value={values.arrivalsTo}
            muiProps={{
              disableToolbar: true,
              //minDate: selectedFromDate || undefined,
            }}
            onChange={(e) => setFieldValue('arrivalsTo', e ? moment(e) : null)}
            onBlur={handleBlur}
            error={!!errors.arrivalsTo && !!submitCount}
          />
        </Grid>
        <Grid
          item
          xs={smDown ? 12 : undefined}
          sm={smDown ? 6 : undefined}
          md={smDown ? 4 : undefined}
          style={{ flex: !smDown ? 1 : undefined }}
        >
          <FormControlLabel
            style={{ marginRight: theme.spacing(4) }}
            label={translations.hidePreviouslyCopied[lang]}
            control={
              <Checkbox
                id="hidePreviusCopied"
                color="primary"
                checked={values.hidePreviusCopied}
                onChange={handleChange}
              />
            }
          />
        </Grid>

        <Grid item xs={smDown ? 12 : undefined}>
          <Button fullWidth={smDown} type="submit" disabled={isSubmitting}>
            {translations.search[lang]}
          </Button>
        </Grid>
        <Grid item xs={smDown ? 12 : undefined}>
          <Button
            fullWidth={smDown}
            variant="outlined"
            onClick={() => {
              clearForm(formik);
            }}
          >
            {translations.clear[lang]}
          </Button>
        </Grid>
      </Grid>
    );
  }

  function clearForm({ handleReset }: IFormik) {
    handleReset();
    //setSelectedComments([]);
    onClear();
  }

  function getInitalValues() {
    return {
      arrivalsFrom: moment() as Moment | null,
      arrivalsTo: moment() as Moment | null,
      hidePreviusCopied: true,
    };
  }
};

export default QuarantinePassengerListFilterOld;
