import React, { useContext, useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Layout from '../components/Layout';
import Section from '../components/Section';
import LoadingIndicator from '../components/LoadingIndicator';
import VaccinationSearchForm, {
  IFormValues,
} from '../components/VaccinationSearchForm';
import useNotifier from '../hooks/useNotifier';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { TABLE_ROWS_DEFAULT } from '../constants';
import {
  VaccinationLocation,
  VaccinationPerson,
  Vaccine,
} from '../models/VaccinationModels';
import * as vaccinationService from '../services/vaccinationService';
import * as httpService from '../services/httpService';
import { VaccinationPersonSearchQuery } from '../models/VaccinationModels';
import VaccinationSearchPersonList from '../components/VaccinationSearchPersonList';
import VaccinationSearchDetails from '../components/VaccinationSearchDetails';
import { IVaccinationCommentStatus } from '../interfaces/vaccinationComment';
import { INationality } from '../interfaces/vaccinationSearch';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: -theme.spacing(3),
  },
}));

const VaccinationSearchPage: React.FC = () => {
  const { notifyError } = useNotifier();
  const [lang] = useContext(LangContext);
  const history = useHistory();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [vaccinationPersons, setVaccinationPersons] = useState<
    VaccinationPerson[] | null
  >(null);
  const [selectedVaccinationPerson, setSelectedVaccinationPerson] =
    useState<VaccinationPerson | null>(null);
  const [vaccines, setVaccines] = useState<Vaccine[] | null>(null);
  const [locations, setLocations] = useState<VaccinationLocation[] | null>(
    null
  );
  const [commentStatusList, setCommentStatusList] = useState<
    IVaccinationCommentStatus[] | null
  >(null);
  const [nationalities, setNationalities] = useState<INationality[] | null>(
    null
  );

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  useEffect(() => {
    const fetchSettings = async () => {
      const vaccinePromise = vaccinationService.getVaccines();
      const locationPromise = vaccinationService.getLocations();
      const vaccinationCommentStatusPromise =
        vaccinationService.getVaccinationCommentStatusList();
      const [
        vaccineResponse,
        locationResponse,
        vaccinationCommentStatusResponse,
      ] = await Promise.all([
        vaccinePromise,
        locationPromise,
        vaccinationCommentStatusPromise,
      ]);

      if (vaccineResponse.isOk) {
        setVaccines(vaccineResponse.data);
      } else {
        setVaccines([]);
      }

      if (locationResponse.isOk) {
        setLocations(locationResponse.data);
      } else {
        setLocations([]);
      }

      if (vaccinationCommentStatusResponse.isOk) {
        setCommentStatusList(vaccinationCommentStatusResponse.data);
      } else {
        setCommentStatusList([]);
      }
    };
    fetchSettings();
  }, []);

  useEffect(() => {
    const getNationalities = async () => {
      const response = await httpService.getCountryCodeList();
      setNationalities(
        response.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((c) => ({ label: c.name, value: c.code }))
      );
    };
    getNationalities();
  }, []);

  return (
    <Layout useDefaultSpacing title={translations.searchTitle[lang]}>
      <Section>
        <Grid container spacing={3}>
          <Grid item sm={12} md={4}>
            <VaccinationSearchForm
              onSubmit={searchForVaccinationPersons}
              onClear={clearSearch}
            />
          </Grid>
          <Grid item sm={12} md={8} style={{ flex: 1 }}>
            {renderResults()}
          </Grid>
        </Grid>
      </Section>
    </Layout>
  );

  function renderResults() {
    if (loading) {
      return (
        <Grid container justify="center" alignItems="center">
          <LoadingIndicator />
        </Grid>
      );
    }

    if (selectedVaccinationPerson) {
      return (
        <VaccinationSearchDetails
          vaccines={vaccines}
          locations={locations}
          nationalities={nationalities}
          sendCertificateEnabled={
            selectedVaccinationPerson.vaccinations.length > 0
          }
          commentStatusList={commentStatusList}
          vaccinationPerson={selectedVaccinationPerson}
          reloadSelectedPerson={reloadSelectedPerson}
          back={() => setSelectedVaccinationPerson(null)}
        />
      );
    }

    return vaccinationPersons ? (
      <div className={classes.container}>
        <VaccinationSearchPersonList
          members={vaccinationPersons}
          onClick={setSelectedVaccinationPerson}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          setCurrentPage={setCurrentPage}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    ) : null;
  }

  async function searchForVaccinationPersons(values: IFormValues) {
    setLoading(true);
    setSelectedVaccinationPerson(null);
    setCurrentPage(0);
    const query = new VaccinationPersonSearchQuery(values);

    const response = await vaccinationService.searchForVaccinationPerson(query);
    if (response.isOk) {
      setVaccinationPersons(response.data);
      setLoading(false);
      // removed code that handles 401 errors, api.ts now handles it
    } else if (response.statusCode === 413) {
      notifyError(translations.payloadToLarge[lang]);
      setLoading(false);
    } else {
      notifyError(translations.errorOccurred[lang]);
      setLoading(false);
    }
  }

  function clearSearch() {
    setLoading(false);
    setVaccinationPersons(null);
    setSelectedVaccinationPerson(null);
    setCurrentPage(0);
  }

  async function reloadSelectedPerson(barcode: string) {
    const response =
      await vaccinationService.getVaccinationPersonBySerialNumber(barcode);

    if (response.isOk) {
      setSelectedVaccinationPerson(response.data);
      if (vaccinationPersons) {
        const newVaccinationPersons = [...vaccinationPersons].map((p) => {
          if (p.serialNumber === response.data?.serialNumber) {
            return response.data;
          }
          return p;
        });
        setVaccinationPersons(
          newVaccinationPersons as VaccinationPerson[] | null
        );
      }
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }
};

export default VaccinationSearchPage;
