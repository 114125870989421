import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
  Grid,
  Typography,
  Checkbox,
} from '@material-ui/core';
import moment from 'moment';

import NoResults from './NoResults';
import {
  VaccinationPerson,
  VaccinationGroup,
  VaccinationComment,
} from '../models/VaccinationModels';
import {
  TABLE_ROWS_OPTIONS,
  DATE_FORMAT,
  DATE_AND_TIME_FORMAT,
} from '../constants';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import {
  compareSSN,
  getLastVaccineDate,
  getVaccineNames,
  getBirthYearBySSN,
} from '../utils';
import ChildVaccinationPositionTooltip from './Vaccination/ChildVaccination/ChildVaccinationPositionTooltip';

interface IProps {
  members: VaccinationPerson[];
  group?: VaccinationGroup;
  currentPage: number;
  rowsPerPage: number;
  setCurrentPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
  onClick?: (vaccinationPerson: VaccinationPerson) => void;
  onChangeSelectedMembers?: (memberList: VaccinationPerson[] | null) => void;
  membersCheckbox: string[];
  setMembersCheckbox?: (members: string[]) => void;
  onSetSortedMembers?: (memberList: VaccinationPerson[]) => void;
  displayBirthYear?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  vaccinationCount: {
    padding: theme.spacing(2),
  },
}));
const tableColumns: {
  [key: string]: {
    translation: { is: string; en: string };
    sort?: (a: VaccinationPerson, b: VaccinationPerson) => number;
    hide?: boolean;
  };
} = {
  name: {
    translation: translations.name,
    sort: (a: VaccinationPerson, b: VaccinationPerson) =>
      a.name.localeCompare(b.name),
  },
  ssn: {
    translation: translations.SSN,
    sort: (a: VaccinationPerson, b: VaccinationPerson) =>
      compareSSN(a.ssn, b.ssn),
  },
  birthYear: {
    translation: translations.yearOfBirth,
    sort: (a: VaccinationPerson, b: VaccinationPerson) => {
      if (getBirthYearBySSN(a.ssn) > getBirthYearBySSN(b.ssn)) {
        return 1;
      } else {
        return -1;
      }
    },
  },
  lastVaccination: {
    translation: translations.LatestDate,
    sort: (a: VaccinationPerson, b: VaccinationPerson) => {
      if (!getLastVaccineDate(a.vaccinations)) {
        return -1;
      }
      if (!getLastVaccineDate(b.vaccinations)) {
        return 1;
      }
      return moment(getLastVaccineDate(a.vaccinations)).isAfter(
        getLastVaccineDate(b.vaccinations)
      )
        ? 1
        : -1;
    },
  },
  vaccine: {
    translation: translations.vaccine,
    sort: (a: VaccinationPerson, b: VaccinationPerson) =>
      (getVaccineNames(a.vaccinations) || '').localeCompare(
        getVaccineNames(b.vaccinations) || ''
      ),
  },
  numberOfVaccinations: {
    translation: translations.numberOfVaccinations,
    sort: (a: VaccinationPerson, b: VaccinationPerson) => {
      return a.vaccinations.length - b.vaccinations.length;
    },
  },
  summoningDate: {
    translation: translations.summoningDate,
    sort: (a: VaccinationPerson, b: VaccinationPerson) => {
      if (!a.nextSummoning?.date) {
        return -1;
      }
      if (!b.nextSummoning?.date) {
        return 1;
      }
      return moment(a.summoningDate).isAfter(b.summoningDate) ? 1 : -1;
    },
  },
  childStatus: {
    translation: translations.childStance,
    sort: (a: VaccinationPerson, b: VaccinationPerson) => {
      if (!a?.allowVaccination && a?.allowVaccination !== 0) {
        return -1;
      }
      if (!b?.allowVaccination && b?.allowVaccination !== 0) {
        return 1;
      }
      if (a?.allowVaccination === 0) {
        return -1;
      }
      if (b?.allowVaccination === 0) {
        return 1;
      }
      return a.allowVaccination < b.allowVaccination ? 1 : -1;
    },
  },
  comment: {
    translation: translations.comment,
    sort: (a: VaccinationPerson, b: VaccinationPerson) =>
      getFormattedComment(a.vaccinationComment).localeCompare(
        getFormattedComment(b.vaccinationComment)
      ),
  },
};

const MAX_COMMENT_LENGTH = 30;

const VaccinationGroupMemberList: React.FC<IProps> = ({
  members,
  group,
  currentPage,
  rowsPerPage,
  setCurrentPage,
  setRowsPerPage,
  onClick,
  onChangeSelectedMembers,
  membersCheckbox,
  setMembersCheckbox,
  onSetSortedMembers,
  displayBirthYear,
}) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);

  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortedMembers, setSortedMembers] = useState<VaccinationPerson[]>([]);
  const startIndex = currentPage * rowsPerPage;

  useEffect(() => {
    if (sortKey && tableColumns[sortKey]) {
      const sorted = tableColumns[sortKey].sort
        ? [...members].sort(tableColumns[sortKey].sort)
        : [...members];
      setSortedMembers(sortDirection === 'asc' ? sorted : sorted.reverse());
    } else {
      setSortedMembers(members);
    }
  }, [members, sortKey, sortDirection]);

  useEffect(() => {
    if (membersCheckbox.length > 0 && onChangeSelectedMembers) {
      const sortedCheckedMembers = sortedMembers.filter((m) =>
        membersCheckbox.includes(m.ssn)
      );
      onChangeSelectedMembers(sortedCheckedMembers);
    } else {
      onChangeSelectedMembers && onChangeSelectedMembers(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedMembers, membersCheckbox]);

  useEffect(() => {
    if (onSetSortedMembers) {
      onSetSortedMembers(sortedMembers);
    }
  }, [sortedMembers, onSetSortedMembers]);
  const filteredColumns = Object.keys(tableColumns).filter(
    (key) => key !== 'birthYear' || displayBirthYear
  );

  return (
    <Paper elevation={2} className={classes.container}>
      {members.length ? (
        <React.Fragment>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {onChangeSelectedMembers && (
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={membersCheckbox.length > 0}
                        onChange={() => handleMainCheckboxChange()}
                      />
                    </TableCell>
                  )}
                  {filteredColumns.map((columnKey) => (
                    <TableCell
                      key={columnKey}
                      style={{
                        textAlign:
                          columnKey === 'childStatus' ? 'center' : 'left',
                      }}
                    >
                      {tableColumns[columnKey].sort ? (
                        <TableSortLabel
                          active={sortKey === columnKey}
                          direction={sortDirection}
                          onClick={() => onSortClick(columnKey)}
                        >
                          {tableColumns[columnKey].translation[lang]}
                        </TableSortLabel>
                      ) : (
                        tableColumns[columnKey].translation[lang]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedMembers
                  .slice(startIndex, startIndex + rowsPerPage)
                  .map((member) => (
                    <TableRow
                      hover={!!onClick}
                      key={member.serialNumber}
                      style={{ cursor: onClick ? 'pointer' : undefined }}
                      //onClick={() => onClick && onClick(member)}
                    >
                      {onChangeSelectedMembers && (
                        <TableCell>
                          <Checkbox
                            color="primary"
                            checked={membersCheckbox.includes(member.ssn)}
                            onChange={() => handleCheckboxChange(member.ssn)}
                          />
                        </TableCell>
                      )}
                      <TableCell onClick={() => onClick && onClick(member)}>
                        {member.name}
                      </TableCell>
                      <TableCell onClick={() => onClick && onClick(member)}>
                        {member.ssn}
                      </TableCell>
                      {displayBirthYear && (
                        <TableCell onClick={() => onClick && onClick(member)}>
                          {getBirthYearBySSN(member.ssn)}
                        </TableCell>
                      )}

                      <TableCell onClick={() => onClick && onClick(member)}>
                        {getLastVaccineDate(member.vaccinations)
                          ? moment(
                              getLastVaccineDate(member.vaccinations)
                            ).format(DATE_FORMAT)
                          : ''}
                      </TableCell>

                      <TableCell onClick={() => onClick && onClick(member)}>
                        {getVaccineNames(member.vaccinations)}
                      </TableCell>
                      <TableCell onClick={() => onClick && onClick(member)}>
                        {member.vaccinations ? member.vaccinations.length : '0'}
                      </TableCell>
                      <TableCell onClick={() => onClick && onClick(member)}>
                        {member.nextSummoning?.date
                          ? moment(member.nextSummoning.date).format(
                              DATE_AND_TIME_FORMAT
                            )
                          : ''}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <ChildVaccinationPositionTooltip
                          status={member.allowVaccination}
                        />
                      </TableCell>
                      <TableCell onClick={() => onClick && onClick(member)}>
                        {getFormattedComment(member.vaccinationComment)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <div className={classes.vaccinationCount}>
              {group && (
                <React.Fragment>
                  <Typography variant="body2">
                    {translations.firstVaccinationFinished[lang]}:
                    {`${
                      group.vaccinatedFirstCount + group.rejectedFirstCount
                    }(${group.rejectedFirstCount})`}
                  </Typography>
                  <Typography variant="body2">
                    {translations.secondVaccinationFinished[lang]}:
                    {`${
                      group.vaccinatedSecondCount + group.rejectedSecondCount
                    }(${group.rejectedSecondCount})`}
                  </Typography>
                </React.Fragment>
              )}
            </div>
            <TablePagination
              labelRowsPerPage={translations.numberOfRowsPerPage[lang]}
              rowsPerPageOptions={TABLE_ROWS_OPTIONS}
              component="div"
              count={members.length}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={(_, p) => setCurrentPage(p)}
              onChangeRowsPerPage={(e) => {
                setCurrentPage(0);
                setRowsPerPage(parseInt(e.target.value, 10));
              }}
            />
          </Grid>
        </React.Fragment>
      ) : (
        <NoResults message={translations.noVaccinationPersonFound[lang]} />
      )}
    </Paper>
  );

  function onSortClick(columnKey: string) {
    if (sortKey === columnKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(columnKey);
      setSortDirection('asc');
    }
  }

  function handleCheckboxChange(ssn: string) {
    if (setMembersCheckbox) {
      if (membersCheckbox.includes(ssn)) {
        setMembersCheckbox([...membersCheckbox].filter((m) => m !== ssn));
      } else {
        setMembersCheckbox([...membersCheckbox, ssn]);
      }
    }
  }

  function handleMainCheckboxChange() {
    if (setMembersCheckbox) {
      if (membersCheckbox.length > 0) {
        setMembersCheckbox([]);
      } else {
        setMembersCheckbox([...sortedMembers].map((m) => m.ssn));
      }
    }
  }
};

function getFormattedComment(vc: VaccinationComment) {
  if (vc.statusDescription) {
    return vc.statusDescription;
  }
  if (!vc.comment) {
    return '';
  }
  return vc.comment.length > MAX_COMMENT_LENGTH
    ? `${vc.comment.slice(0, MAX_COMMENT_LENGTH)}...`
    : vc.comment;
}

export default VaccinationGroupMemberList;
