import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Paper,
  TextField,
} from '@material-ui/core';
import { Formik, Form, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';
import Button from './Button';
import Section from './Section';
import ConfirmDialog from './ConfirmDialog';
import SymptomPassengerInfo from './SymptomPassengerInfo';
import NoResults from './NoResults';
import { IPassengerDetails } from '../interfaces/passenger';
import * as httpService from '../services/httpService';
import { Passenger } from '../models/Models';
import useNotifier from '../hooks/useNotifier';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { isReadyForSampling, disableSymptomSamplingNumber } from '../utils';

interface IProps {
  passenger: Passenger | null;
  setCurrentPassenger: (passenger: Passenger | null) => void;
  backDialogOpen: boolean;
  closeBackDialog: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paperLeft: {
      padding: theme.spacing(3),
      textAlign: 'center',
    },
    printIcon: {
      paddingRight: theme.spacing(1),
    },
    paper: {
      flex: 1,
      padding: theme.spacing(3),
      margin: theme.spacing(3),
      textAlign: 'center',
      maxWidth: theme.dimensions.boxWidth,
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(15),
      },
    },
  })
);

export interface IFormValues {
  samplingNumber: string;
}

type IFormik = FormikProps<IFormValues>;

const SymptomSampling: React.FC<IProps> = ({
  passenger,
  setCurrentPassenger,
  backDialogOpen,
  closeBackDialog,
}) => {
  const classes = useStyles({});
  const { notifySuccess, notifyError } = useNotifier();
  const [clearInputDialogOpen, setClearInputDialogOpen] = useState(false);
  const [noResult, setNoResult] = useState<string | null>(null);
  const childCount = 0;
  const [samplingNumber, setSamplingNumber] = useState('');
  const [validSerialNumber, setValidSerialnumber] = useState(false);
  const [
    loadingSamplingNumberChange,
    setLoadingSamplingNumberChange,
  ] = useState(false);
  const idInput = useRef<HTMLInputElement>(null);
  const [lang] = useContext(LangContext);
  const history = useHistory();

  useEffect(() => {
    if (!passenger) {
      focusIdInput();
    }
  }, [passenger]);

  const childDetails: IPassengerDetails[] = [
    {
      label: translations.lastName[lang],
      getVal(passenger: Passenger) {
        return passenger.lastName;
      },
    },
    {
      label: translations.firstNames[lang],
      getVal(passenger: Passenger) {
        return passenger.firstName;
      },
    },

    {
      label: translations.statusInTheProcess[lang],
      getVal(passenger: Passenger) {
        return passenger.borderControlStatus
          ? (passenger.borderControlStatus.description &&
              passenger.borderControlStatus.description[lang]) ||
              ''
          : '';
      },
    },
  ];

  const renderSearch = (formik: IFormik) => {
    return (
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5">
              {translations.inputSerialNumber[lang]}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              autoFocus
              autoComplete="off"
              id={'samplingNumber'}
              label={translations.serialNumber[lang]}
              variant="outlined"
              inputProps={{ ref: idInput }}
              value={formik.values.samplingNumber}
              onChange={formik.handleChange}
              disabled={!!passenger}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              type="submit"
              disabled={formik.isSubmitting || !formik.dirty}
            >
              {translations.searchTitle[lang]}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              onClick={(e) => {
                if (isReadyForSampling(passenger)) {
                  setClearInputDialogOpen(true);
                } else {
                  clearSearch(formik);
                }
              }}
            >
              {translations.clear[lang]}
            </Button>
          </Grid>
        </Grid>
      </Form>
    );
  };

  const renderDialogs = (formik: IFormik) => {
    return (
      <React.Fragment>
        <ConfirmDialog
          open={clearInputDialogOpen}
          title={translations.clearInputDialogTitle[lang]}
          text={translations.clearInputDialogText[lang]}
          onConfirm={() => {
            clearSearch(formik);
            setClearInputDialogOpen(false);
          }}
          onCancel={() => setClearInputDialogOpen(false)}
          confirmText={translations.clear[lang]}
        />
        <ConfirmDialog
          open={backDialogOpen}
          title={translations.backToMenu[lang]}
          text={translations.backDialogText[lang]}
          confirmText={translations.back[lang]}
          onConfirm={() => history.push('/home')}
          onCancel={closeBackDialog}
        />
      </React.Fragment>
    );
  };

  return (
    <Section>
      <div className={classes.root}>
        <Formik
          initialValues={{ samplingNumber: '' }}
          onSubmit={searchForPassenger}
        >
          {(formik) => (
            <Grid container spacing={3}>
              <Grid item sm={12} md={5} lg={4}>
                <Paper elevation={2} className={classes.paperLeft}>
                  {renderSearch(formik)}
                </Paper>
              </Grid>

              {passenger ? (
                <Grid item sm={12} md={7} lg={8}>
                  <SymptomPassengerInfo
                    passenger={passenger}
                    passengerDetails={childDetails}
                    samplingNumberValue={samplingNumber}
                    loading={loadingSamplingNumberChange}
                    onSubmit={() => changePassengersSamplingNumber(formik)}
                    changeSamplingNumber={changeSerialNumber}
                    validSamplingNumber={validSerialNumber}
                  />
                </Grid>
              ) : noResult ? (
                <Grid item sm={12} md={7} lg={8} style={{ flex: 1 }}>
                  <NoResults message={noResult} />
                </Grid>
              ) : null}

              {renderDialogs(formik)}
            </Grid>
          )}
        </Formik>
      </div>
    </Section>
  );

  async function searchForPassenger(values: IFormValues) {
    httpService
      .getPassengerByRegistrationId(values.samplingNumber)
      .then((response) => {
        if (response.isOk) {
          if (response.data?.isSymtomSampleRequest) {
            setCurrentPassenger(response.data);
            setNoResult(null);
            focusIdInput();

            if (
              disableSymptomSamplingNumber(response.data) &&
              response.data?.sampleId
            ) {
              setSamplingNumber(response.data?.sampleId);
            }
          } else {
            setCurrentPassenger(null);
            setNoResult(translations.passengerHasSymptomSample[lang]);
          }
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        }
      })
      .catch(() => {
        setCurrentPassenger(null);
        setNoResult(translations.noPassengerFound[lang]);
      });
  }

  function clearSearch(formik: IFormik) {
    setCurrentPassenger(null);
    setNoResult(null);
    setSamplingNumber('');
    setValidSerialnumber(false);
    focusIdInput();
    formik.handleReset();
  }

  function confirmSample(formik: IFormik) {
    httpService
      .markSampleTaken(passenger?.sampleId as string, childCount)
      .then((response) => {
        if (response.isOk) {
          notifySuccess(translations.samplingFinished[lang]);
          clearSearch(formik);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          notifyError(translations.operationFailed[lang]);
        }
      })
      .catch(() => {
        notifyError(translations.operationFailed[lang]);
      });
  }

  function changePassengersSamplingNumber(formik: IFormik) {
    setLoadingSamplingNumberChange(true);
    httpService
      .updatePrePrinted(passenger?.serialNumber as string, samplingNumber)
      .then((response) => {
        if (response.isOk) {
          confirmSample(formik);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          notifyError(response.data.errorMessage);
        }
        setLoadingSamplingNumberChange(false);
      })
      .catch(() => {
        notifyError(translations.operationFailed[lang]);
        setLoadingSamplingNumberChange(false);
      });
  }

  function focusIdInput() {
    if (idInput && idInput.current) {
      idInput.current.focus();
    }
  }

  function changeSerialNumber(samplingNumber: string) {
    setSamplingNumber(samplingNumber);
    if (samplingNumber.startsWith('EF')) {
      setValidSerialnumber(true);
    } else if (samplingNumber.length > 1) {
      setValidSerialnumber(false);
    } else {
      setValidSerialnumber(false);
    }
  }
};

export default SymptomSampling;
