import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export function AccessGroupTabPanel(props: any) {
  const { children, value, index, className } = props;

  return (
    <Fragment>
      {value === index && <div className={className}>{children}</div>}
    </Fragment>
  );
}
AccessGroupTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
};
