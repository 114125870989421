import React, { useContext, useState, useEffect } from 'react';
import { useTheme, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Layout from '../components/Layout';
import Section from '../components/Section';
import ConfirmDialog from '../components/ConfirmDialog';
import Dropdown from '../components/Dropdown';
import RiskCountriesMap from '../components/RiskCountriesMap';
import RiskCountriesList from '../components/RiskCountriesList';
import RiskCountriesStatusList from '../components/RiskCountriesStatusList';
import { LangContext } from '../context/LangContext';
import { parseTemplateString } from '../utils';
import translations from '../assets/json/translations.json';
import { IRiskCountry, RiskCountryStatus } from '../interfaces/riskCountry';
import useNotifier from '../hooks/useNotifier';
import * as httpService from '../services/httpService';

const HighRiskCountriesPage: React.FC = () => {
  const [lang] = useContext(LangContext);
  const theme = useTheme();
  const history = useHistory();
  const { notifySuccess, notifyError } = useNotifier();

  const [loadingCountries, setLoadingCountries] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [allCountries, setAllCountries] = useState<IRiskCountry[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<IRiskCountry | null>(
    null
  );
  const [selectedStatus, setSelectedStatus] = useState('');
  const [removeCountry, setRemoveCountry] = useState<IRiskCountry | null>(null);

  useEffect(() => {
    const loadCountries = async () => {
      setLoadingCountries(true);
      const response = await httpService.getHighRiskCountries();
      setAllCountries(response.data);
      setLoadingCountries(false);
    };
    loadCountries();
  }, []);

  useEffect(() => {
    setSelectedStatus(selectedCountry ? selectedCountry.status.toString() : '');
  }, [selectedCountry]);

  return (
    <Layout
      useDefaultSpacing
      title={translations.highRiskCountries[lang]}
      loading={loadingCountries}
    >
      <Section>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {renderCountryLists()}
          </Grid>
          <Grid item xs={12}>
            <RiskCountriesMap
              countries={allCountries}
              onClick={setSelectedCountry}
            />
          </Grid>
        </Grid>
      </Section>
      {renderMoveDialog()}
      {renderRemoveDialog()}
    </Layout>
  );

  function renderCountryLists() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <RiskCountriesList
            allCountries={allCountries}
            onClick={setSelectedCountry}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <RiskCountriesStatusList
            title={translations.yellowCountries[lang]}
            status={RiskCountryStatus.YELLOW}
            allCountries={allCountries}
            onClick={setSelectedCountry}
            onRemove={setRemoveCountry}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <RiskCountriesStatusList
            title={translations.redCountries[lang]}
            status={RiskCountryStatus.RED}
            allCountries={allCountries}
            onClick={setSelectedCountry}
            onRemove={setRemoveCountry}
          />
        </Grid>
      </Grid>
    );
  }

  function renderMoveDialog() {
    return selectedCountry ? (
      <ConfirmDialog
        open
        loading={loadingUpdate}
        disableConfirm={
          !selectedStatus ||
          selectedStatus === selectedCountry.status.toString()
        }
        title={translations.moveCountry[lang]}
        text={parseTemplateString(
          translations.moveCountryDialog[lang],
          selectedCountry.name
        )}
        onConfirm={async () => {
          await updateCountryStatus(selectedCountry, parseInt(selectedStatus));
          setSelectedCountry(null);
        }}
        onCancel={() => setSelectedCountry(null)}
      >
        <div style={{ marginTop: theme.spacing(3) }} />
        <Dropdown
          fullWidth
          id="countryStatuses"
          value={selectedStatus}
          label={translations.colorCode[lang]}
          items={[
            {
              value: RiskCountryStatus.WHITE.toString(),
              label: translations.noColorCode[lang],
            },
            {
              value: RiskCountryStatus.YELLOW.toString(),
              label: translations.yellow[lang],
            },
            {
              value: RiskCountryStatus.RED.toString(),
              label: translations.red[lang],
            },
          ]}
          onChange={(e) => setSelectedStatus(e.target.value as string)}
        />
      </ConfirmDialog>
    ) : null;
  }

  function renderRemoveDialog() {
    return removeCountry ? (
      <ConfirmDialog
        open
        loading={loadingUpdate}
        title={translations.removeCountry[lang]}
        text={parseTemplateString(
          translations.removeCountryDialog[lang],
          removeCountry.name,
          removeCountry.status === RiskCountryStatus.RED
            ? translations.redCountries[lang]
            : translations.yellowCountries[lang]
        )}
        onConfirm={async () => {
          await updateCountryStatus(removeCountry, RiskCountryStatus.WHITE);
          setRemoveCountry(null);
        }}
        onCancel={() => setRemoveCountry(null)}
      />
    ) : null;
  }

  async function updateCountryStatus(country: IRiskCountry, status: number) {
    setLoadingUpdate(true);
    const response = await httpService.updateRiskCountryStatus(
      country.code,
      status
    );

    if (response.isOk) {
      await reloadCountries();
      if (status === RiskCountryStatus.WHITE) {
        notifySuccess(
          parseTemplateString(translations.countryRemoved[lang], country.name)
        );
      } else {
        const newCategory =
          status === RiskCountryStatus.RED
            ? translations.redCountries[lang]
            : translations.yellowCountries[lang];
        notifySuccess(
          parseTemplateString(
            translations.countryMoved[lang],
            country.name,
            newCategory
          )
        );
      }
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.countryMovedError[lang]);
    }
    setLoadingUpdate(false);
  }

  async function reloadCountries() {
    const response = await httpService.getHighRiskCountries();
    setAllCountries(response.data);
  }
};

export default HighRiskCountriesPage;
