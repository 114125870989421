import React, { useContext, useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Grid,
  useTheme,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Warning';

import Button from './Button';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

interface IProps {
  open: boolean;
  title: string;
  text?: string;
  confirmText?: string;
  cancelText?: string;
  loading?: boolean;
  disableConfirm?: boolean;
  error?: boolean;
  leftButtonText?: string;
  leftButtonColor?: 'primary' | 'secondary' | 'error';
  extraButtonText?: string;
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
  onConfirm?: () => void;
  onCancel: () => void;
  onLeftButtonClick?: () => void;
  onExtraButtonClick?: () => void;
}

const ConfirmDialog: React.FC<IProps> = ({
  open,
  title,
  text,
  children,
  confirmText,
  cancelText,
  loading,
  error,
  leftButtonText,
  leftButtonColor = 'primary',
  extraButtonText,
  maxWidth = 'sm',
  onConfirm,
  onCancel,
  onLeftButtonClick,
  onExtraButtonClick,
  ...props
}) => {
  const [lang] = useContext(LangContext);
  const theme = useTheme();
  const [disableConfirm, setDisableConfirm] = useState(false);

  useEffect(() => {
    if (!open) {
      setDisableConfirm(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth={maxWidth}
      fullWidth
      onClick={() => disableConfirm && setDisableConfirm(false)}
    >
      <DialogTitle>
        <Grid container direction="row" alignItems="center">
          {error ? (
            <ErrorIcon
              fontSize="large"
              color="error"
              style={{ marginRight: theme.spacing(1) }}
            />
          ) : null}
          <span
            style={{
              transform: error ? 'translateY(2px)' : undefined,
            }}
          >
            {title}
          </span>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ minHeight: 80 }} dividers>
        {text && <DialogContentText>{text}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>{renderButtons()}</DialogActions>
    </Dialog>
  );

  function renderButtons() {
    return (
      <Grid container direction="row" alignItems="center" spacing={2}>
        {onLeftButtonClick && leftButtonText && (
          <Grid item>
            <Button
              padding={false}
              variant="outlined"
              error={leftButtonColor === 'error'}
              color={leftButtonColor === 'secondary' ? 'secondary' : 'primary'}
              onClick={onLeftButtonClick}
              disabled={loading}
            >
              {leftButtonText}
            </Button>
          </Grid>
        )}
        <Grid item style={{ flex: 1 }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-end"
            wrap="nowrap"
            spacing={1}
          >
            {loading && (
              <Grid item>
                <CircularProgress size={32} color="primary" />
              </Grid>
            )}
            {onConfirm && (
              <Grid item>
                <Button
                  padding={false}
                  color="primary"
                  variant="contained"
                  disabled={disableConfirm || props.disableConfirm || loading}
                  onClick={confirm}
                >
                  {confirmText || translations.confirm[lang]}
                </Button>
              </Grid>
            )}
            {onExtraButtonClick && extraButtonText && (
              <Grid item>
                <Button
                  padding={false}
                  variant="outlined"
                  onClick={onExtraButtonClick}
                  disabled={loading}
                >
                  {extraButtonText}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                padding={false}
                onClick={close}
              >
                {cancelText || translations.cancel[lang]}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function confirm() {
    if (onConfirm) {
      setDisableConfirm(true);
      onConfirm();
    }
  }

  function close() {
    onCancel();
    setDisableConfirm(false);
  }
};

export default ConfirmDialog;
