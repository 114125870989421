import api from '../api';
import { IHttpAnswer } from '../interfaces/httpAnswer';
import { IUpdateScheduleLocationSettings } from '../interfaces/schedule';
import {
  ScheduleLocation,
  UpdateScheduleLocationCutOffTimesModel,
} from '../models/Models';

const _controller = '/ScheduleLocation';

export async function getScheduleLocation(
  locationId: number
): Promise<IHttpAnswer<ScheduleLocation | null>> {
  const response = await api.get(
    `${_controller}/GetScheduleLocation?locationid=${locationId}`,
    true
  );
  if (response.isOk) {
    const data = response.data;
    return {
      data: new ScheduleLocation(data),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}
export async function getScheduleLocations(): Promise<
  IHttpAnswer<Array<ScheduleLocation | null>>
> {
  const response = await api.get(`${_controller}/GetScheduleLocations`, true);
  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((x: any) => new ScheduleLocation(x)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: [],
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}
export async function updateScheduleLocation(
  location: ScheduleLocation
): Promise<IHttpAnswer<ScheduleLocation | null>> {
  const response = await api.put(
    `${_controller}/UpdateScheduleLocation`,
    location,
    true
  );
  if (response.isOk) {
    const data = response.data;
    return {
      data: new ScheduleLocation(data),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}
export async function updateScheduleLocationCutOffTimes(
  payload: UpdateScheduleLocationCutOffTimesModel
): Promise<IHttpAnswer<ScheduleLocation | null>> {
  const response = await api.put(
    `${_controller}/UpdateScheduleLocationCutOffTimes`,
    payload,
    true
  );
  if (response.isOk) {
    const data = response.data;
    return {
      data: new ScheduleLocation(data),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

export async function updateScheduleLocationSettings(
  payload: IUpdateScheduleLocationSettings
): Promise<IHttpAnswer<boolean | null>> {
  const response = await api.put(
    `${_controller}/UpdateScheduleLocationSettings`,
    payload,
    true
  );
  if (response.isOk) {
    // const data = response.data;
    return {
      data: true,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}
