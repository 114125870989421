import React, { useContext, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Grid, TextField, useMediaQuery } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from './Button';
import ConfirmDialog from './ConfirmDialog';
import { LangContext } from '../context/LangContext';
import { ResidencePerson } from '../models/Models';
import { IUpdateQuarantinePassengerInfo } from '../interfaces/quarantinePassenger';
import translations from '../assets/json/translations.json';
import theme from '../theme';
import EditButton from './EditButton';

interface IProps {
  residencePerson: ResidencePerson;
  updateInfo: (info: IUpdateQuarantinePassengerInfo) => Promise<void>;
}

const schema = Yup.object().shape({
  hotelRoomNumber: Yup.string().required(),
  comment: Yup.string(),
  food: Yup.string(),
});

const EditQuarantinePassengerInfo: React.FC<IProps> = ({
  residencePerson,
  updateInfo,
}) => {
  const [lang] = useContext(LangContext);
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <React.Fragment>
      {!smDown && (
        <Button
          padding={false}
          onClick={() => setDialogOpen(true)}
          endIcon={<EditIcon />}
          variant="outlined"
        >
          {translations.edit[lang]}
        </Button>
      )}
      {smDown && <EditButton onClick={() => setDialogOpen(true)}></EditButton>}
      {renderDialog()}
    </React.Fragment>
  );

  function renderDialog() {
    return (
      <Formik
        onSubmit={(values) => onSubmit(values)}
        validationSchema={schema}
        enableReinitialize
        initialValues={{
          hotelRoomNumber: residencePerson.checkedInRoomNumber || '',
          comment: residencePerson.comment || '',
          food: residencePerson.food || '',
        }}
      >
        {({
          values,
          errors,
          submitCount,
          isSubmitting,
          isValid,
          dirty,
          handleChange,
          handleSubmit,
          handleReset,
        }) => (
          <ConfirmDialog
            open={dialogOpen}
            title={translations.edit[lang]}
            loading={isSubmitting}
            disableConfirm={isSubmitting || !isValid || !dirty}
            onConfirm={handleSubmit}
            onCancel={() => {
              setDialogOpen(false);
              handleReset();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  autoFocus
                  required
                  autoComplete="off"
                  id="hotelRoomNumber"
                  variant="outlined"
                  label={translations.hotelRoomNumber[lang]}
                  value={values.hotelRoomNumber}
                  error={!!errors.hotelRoomNumber && !!submitCount}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="comment"
                  variant="outlined"
                  label={translations.comment[lang]}
                  value={values.comment}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="food"
                  variant="outlined"
                  label={translations.food[lang]}
                  value={values.food}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </ConfirmDialog>
        )}
      </Formik>
    );
  }

  async function onSubmit(values: any) {
    await updateInfo({
      roomNumber: values.hotelRoomNumber,
      comment: values.comment,
      foodWishes: values.food,
    });
    setDialogOpen(false);
  }
};

export default EditQuarantinePassengerInfo;
