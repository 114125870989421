import IState from './IState';
import IStoreAction from './IStoreAction';
import { ConfirmQtFromAirlineKey } from './Constants';

function Reducer(state: IState, action: IStoreAction): IState {
  switch (action.type) {
    case ConfirmQtFromAirlineKey:
      localStorage.setItem(ConfirmQtFromAirlineKey, action.payload);
      return {
        ...state,
        confirmQtFromAirline: action.payload === 'true' ? true : false,
      };
    default:
      return state;
  }
}

export default Reducer;
