import api from '../api';
import { IHttpAnswer } from '../interfaces/httpAnswer';
import {
  LocationSchedule,
  UpdateTimeslot,
  UpdateTimeSlots,
  TimeSlotBooking,
  ScheduleUpdatedBy,
  ScheduleOverflowStatus,
} from '../models/Models';

const _controller = '/Timeslot';

export async function getTimeslots(
  locationId: number,
  dateFrom: string,
  dateTo: string
): Promise<IHttpAnswer<Array<LocationSchedule> | null>> {
  const response = await api.get(
    `${_controller}/GetTimeslotsBetweenDates?scheduleLocationId=${locationId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    true
  );
  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((row: any) => new LocationSchedule(row)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}
export async function getRecurringTimeslots(
  locationId: number,
  dateFrom: string,
  dateTo: string
): Promise<IHttpAnswer<Array<LocationSchedule> | null>> {
  const response = await api.get(
    `${_controller}/GetRecurringTimeslots?scheduleLocationId=${locationId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    true
  );
  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((row: any) => new LocationSchedule(row)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}
export async function getTimeSlotBookings(
  locationId: number,
  absoluteDate: string
): Promise<IHttpAnswer<Array<LocationSchedule> | null>> {
  const response = await api.get(
    `${_controller}/GetTimeSlotBookings?scheduleLocationId=${locationId}&absoluteDate=${absoluteDate}`,
    true
  );
  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((row: any) => new TimeSlotBooking(row)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

export async function updateTimeslots(
  updateTimeslot: UpdateTimeSlots
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `${_controller}/UpdateTimeSlots`,
    updateTimeslot,
    true
  );
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

export async function updateTimeSlot(
  updateTimeslot: UpdateTimeslot
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `${_controller}/UpdateTimeSlotsForDayOfWeek`,
    updateTimeslot,
    true
  );
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

export async function getAllowOverflow(
  locationId: number
): Promise<IHttpAnswer<ScheduleOverflowStatus | null>> {
  const response = await api.get(
    `${_controller}/GetOverflowEnabled?scheduleLocationId=${locationId}`,
    true
  );
  if (response.isOk) {
    const data = response.data;
    return {
      data: new ScheduleOverflowStatus(data),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

export async function getLastModified(
  locationId: number
): Promise<IHttpAnswer<ScheduleUpdatedBy | null>> {
  const response = await api.get(
    `${_controller}/GetLastModified?scheduleLocationId=${locationId}`,
    true
  );
  if (response.isOk) {
    const data = response.data;
    return {
      data: new ScheduleUpdatedBy(data),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

export async function updateOverflowStatus(
  locationId: number,
  heilsuveraOverflowEnabled: boolean
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `${_controller}/UpdateOverflowEnabled`,
    {
      isOverflowEnabled: true,
      isOverflowInHeilsuveraEnabled: heilsuveraOverflowEnabled,
      scheduleLocationId: locationId,
    },
    true
  );
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}
