import React from 'react';

import Layout from '../components/Layout';
import Home from '../components/Home';
import useNotifier from '../hooks/useNotifier';

const VaccinationHomePage: React.FC = () => {
  const { notifyError } = useNotifier();

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  return (
    <Layout>
      <Home samplingMenu={false} />
    </Layout>
  );
};

export default VaccinationHomePage;
