import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import { Formik, Form, FormikProps, FormikHelpers, FormikErrors } from 'formik';
import * as Yup from 'yup';
import Autocomplete, { IOption } from './Autocomplete';
import { LangContext } from '../context/LangContext';
import { accessControl, accessListType } from '../constants/enums';
import translations from '../assets/json/translations.json';
import Button from './Button';
import accessList from '../assets/json/accessList.json';
import { UserItem } from '../models/Models';
import * as vaccinationService from '../services/vaccinationService';
import { IVaccinationRegion } from '../interfaces/vaccinationRegion';
import * as httpService from '../services/httpService';
import { AccessGroupTabPanel } from './AccessGroupTabPanel';
import theme from '../theme';

interface IProps {
  onSubmitForm: (
    values: IFormValues,
    { resetForm }: FormikHelpers<IFormValues>
  ) => void;
  editUser?: UserItem | null;
  clearEditUser?: () => void;
}

export interface IFormValues {
  ssn: string;
  name: string;
  vaccinationAccess: IOption[];
  borderControlAccess: IOption[];
  vaccinationRegions: IOption[];
  residenceLocations: IOption[];
  samplingLocations: IOption[];
}

type IFormik = FormikProps<IFormValues>;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  buttonGrid: {
    textAlign: 'right',
  },
  buttonFormContainer: {
    marginLeft: theme.spacing(2),
  },
  dialogContent: {
    padding: '0px',
    overflow: 'hidden',
  },
}));

const UserForm: React.FC<IProps> = ({
  onSubmitForm,
  editUser,
  clearEditUser,
}) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);
  const adminAccessList = accessList.filter(
    (a) => a.id !== accessControl.SUPER_ADMIN
  );
  const vaccinationAccessListAdmin = adminAccessList.filter((a) =>
    a.accessGroup.includes(accessListType.VACCINATION)
  );
  const borderControlAccessListAdmin = adminAccessList.filter((a) =>
    a.accessGroup.includes(accessListType.BORDER_CONTROL)
  );
  const [showVaccinationRegions, setShowVaccinationRegions] = useState(false);
  const [showSamplingLocations, setShowSamplingLocations] = useState(false);
  const [requireSamplingAccess, setRequireSamplingAccess] = useState(false);
  const [requireVaccinationAccess, setRequireVaccinationAccess] =
    useState(false);
  const [openDialog] = useState(true);
  const [locations, setLocations] = useState<IOption[]>([]);
  const [vaccinationRegions, setVaccinationRegions] = useState(
    new Array<IVaccinationRegion>()
  );
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    let mounted = true;
    vaccinationService.getRegions().then((response) => {
      if (response.data && mounted) {
        setVaccinationRegions(response.data ?? new Array<IVaccinationRegion>());
      }
    });
    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    httpService.getLoginLocations().then((response) => {
      if (response.data && mounted) {
        const locationOptions: IOption[] = response.data.map((item) => {
          return { value: item.id.toString(), label: item.label };
        });
        setLocations(locationOptions);
      }
    });
    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (editUser) {
      if (editUser.borderControlAccess.length > 0) {
        setShowSamplingLocations(true);
      }
      if (editUser.vaccinationAccesses.length > 0) {
        setShowVaccinationRegions(true);
      }
      if (
        editUser.vaccinationRegions &&
        editUser.vaccinationRegions.length > 0
      ) {
        setRequireVaccinationAccess(true);
      }
      if (editUser.samplingLocations && editUser.samplingLocations.length > 0) {
        setRequireSamplingAccess(true);
      }
    }
  }, [editUser]);

  const schema = Yup.object().shape({
    ssn: Yup.string()
      .matches(/^[0-3]\d[01]\d{6}[09]$/)
      .required(),
    name: Yup.string().required(),
    vaccinationAccess: requireVaccinationAccess
      ? Yup.array().min(1)
      : Yup.array(),
    borderControlAccess: requireSamplingAccess
      ? Yup.array().min(1)
      : Yup.array(),
    vaccinationRegions: showVaccinationRegions
      ? Yup.array().of(Yup.object()).min(1)
      : Yup.array(),
    samplingLocations: showSamplingLocations
      ? Yup.array().of(Yup.object()).min(1)
      : Yup.array(),
  });

  if (editUser) {
    const borderControlUserAccess = editUser.borderControlAccess.map((access) =>
      access.id.toString()
    );
    const vaccinationUserAccess = editUser.vaccinationAccesses.map((access) =>
      access.id.toString()
    );

    const BorderControlAccessList = borderControlAccessListAdmin.map((a) => ({
      label: a.description[lang],
      value: a.id.toString(),
    }));
    const VaccinationAcessList = vaccinationAccessListAdmin.map((a) => ({
      label: a.description[lang],
      value: a.id.toString(),
    }));
    const userValues: IFormValues = {
      ssn: editUser.ssn,
      name: editUser.name,
      borderControlAccess: BorderControlAccessList.filter((a) =>
        borderControlUserAccess.includes(a.value)
      ),
      vaccinationAccess: VaccinationAcessList.filter((a) =>
        vaccinationUserAccess.includes(a.value)
      ),
      vaccinationRegions:
        editUser.vaccinationRegions?.map((r) => {
          return { value: r.id.toString(), label: r.name } as IOption;
        }) ?? [],
      residenceLocations:
        editUser.residenceLocations?.map((r) => {
          return { value: r.id.toString(), label: r.name } as IOption;
        }) ?? [],
      samplingLocations:
        editUser.samplingLocations?.map((r) => {
          return { value: r.id.toString(), label: r.name } as IOption;
        }) ?? [],
    };

    return (
      <Dialog
        open={openDialog}
        onClose={() => {
          clearEditUser && clearEditUser();
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{editUser.name}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {initializeFormik(userValues)}
        </DialogContent>
      </Dialog>
    );
  }

  return <React.Fragment>{initializeFormik(null)}</React.Fragment>;

  function initializeFormik(user: IFormValues | null) {
    return (
      <Formik
        enableReinitialize
        onSubmit={onSubmitForm}
        initialValues={
          user || {
            ssn: '',
            name: '',
            access: [],
            vaccinationRegions: [],
            residenceLocations: [],
            borderControlAccess: [],
            vaccinationAccess: [],
            samplingLocations: [],
          }
        }
        validationSchema={schema}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} className={classes.paper}>
              {renderForm(formik)}
              {renderButtons(formik)}
            </Grid>
          </Form>
        )}
      </Formik>
    );
  }

  function renderForm({
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    submitCount,
  }: IFormik) {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoComplete="off"
            id="ssn"
            label={translations.SSN[lang]}
            variant="outlined"
            value={values.ssn}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!!editUser}
            error={!!errors.ssn && !!submitCount}
            inputProps={{
              type: 'text',
              pattern: '[0-9]*',
              maxLength: '10',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoComplete="off"
            id="name"
            label={translations.name[lang]}
            variant="outlined"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.name && !!submitCount}
          />
        </Grid>
        <Grid item xs={12}>
          <Box style={{ width: '100%' }}>
            <Box
              style={{
                borderBottom: 1,
                borderColor: 'divider',
                paddingBottom: theme.spacing(2),
              }}
            >
              <Tabs
                value={getTabValue(errors, submitCount)}
                onChange={handleTabChange}
              >
                <Tab label={translations.accessGroupSampling[lang]} />
                <Tab label={translations.accessGroupVaccination[lang]} />
              </Tabs>
            </Box>
            <AccessGroupTabPanel
              value={getTabValue(errors, submitCount)}
              index={0}
            >
              <Grid item xs={12} style={{ marginBottom: theme.spacing(2) }}>
                <Autocomplete
                  fullWidth
                  id="borderControlAccess"
                  values={values.borderControlAccess}
                  label={translations.access[lang]}
                  items={borderControlAccessListAdmin.map((a) => ({
                    label: a.description[lang],
                    value: a.id.toString(),
                  }))}
                  onChange={(val: any) => {
                    setFieldValue('borderControlAccess', val);
                    if (val?.length > 0) {
                      setShowSamplingLocations(true);
                    } else {
                      setShowSamplingLocations(false);
                    }
                  }}
                  error={!!errors.borderControlAccess && !!submitCount}
                  checkBox
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  id="samplingLocations"
                  values={values.samplingLocations}
                  label={translations.location[lang]}
                  items={locations}
                  onChange={(val: any) => {
                    setFieldValue('samplingLocations', val);
                    if (val?.length > 0) {
                      setRequireSamplingAccess(true);
                    } else {
                      setRequireSamplingAccess(false);
                    }
                  }}
                  error={
                    !!errors.samplingLocations &&
                    !!submitCount &&
                    showSamplingLocations
                  }
                  checkBox
                />
              </Grid>
            </AccessGroupTabPanel>
            <AccessGroupTabPanel
              value={getTabValue(errors, submitCount)}
              index={1}
            >
              <Grid item xs={12} style={{ marginBottom: theme.spacing(2) }}>
                <Autocomplete
                  fullWidth
                  id="vaccinationAccess"
                  values={values.vaccinationAccess}
                  label={translations.access[lang]}
                  items={vaccinationAccessListAdmin.map((a) => ({
                    label: a.description[lang],
                    value: a.id.toString(),
                  }))}
                  onChange={(val: any) => {
                    setFieldValue('vaccinationAccess', val);
                    if (val?.length > 0) {
                      setShowVaccinationRegions(true);
                    } else {
                      setShowVaccinationRegions(false);
                    }
                  }}
                  error={!!errors.vaccinationAccess && !!submitCount}
                  checkBox
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  id="vaccinationRegions"
                  values={values.vaccinationRegions}
                  label={translations.region[lang]}
                  items={vaccinationRegions.map((a) => ({
                    label: a.name,
                    value: a.id.toString(),
                  }))}
                  onChange={(val: any) => {
                    setFieldValue('vaccinationRegions', val);
                    if (val?.length > 0) {
                      setRequireVaccinationAccess(true);
                    } else {
                      setRequireVaccinationAccess(false);
                    }
                  }}
                  error={
                    !!errors.vaccinationRegions &&
                    !!submitCount &&
                    showVaccinationRegions
                  }
                  checkBox
                />
              </Grid>
            </AccessGroupTabPanel>
          </Box>
        </Grid>
      </React.Fragment>
    );
  }

  function renderButtons(formik: IFormik) {
    if (editUser) {
      return (
        <Grid item xs={12} className={classes.buttonGrid}>
          <Button type="submit" disabled={!formik.dirty}>
            {translations.editUser[lang]}
          </Button>
          <Button
            className={classes.buttonFormContainer}
            onClick={() => {
              clearEditUser && clearEditUser();
            }}
            variant="outlined"
          >
            {translations.cancel[lang]}
          </Button>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} className={classes.buttonGrid}>
        <Button type="submit">{translations.createUser[lang]}</Button>
      </Grid>
    );
  }

  function getTabValue(errors: FormikErrors<IFormValues>, submitCount: number) {
    if (!!errors.samplingLocations && !!submitCount && showSamplingLocations) {
      return 0;
    } else if (
      !!errors.vaccinationRegions &&
      !!submitCount &&
      showVaccinationRegions
    ) {
      return 1;
    }
    return tabValue;
  }
};

export default UserForm;
