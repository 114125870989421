import React, {
  useRef,
  useContext,
  useState,
  useEffect,
  Fragment,
} from 'react';
import {
  makeStyles,
  Grid,
  Paper,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Formik, Form, FormikProps } from 'formik';
import moment, { Moment } from 'moment';
import * as Yup from 'yup';

import Button from './Button';
import DatePicker from './DatePicker';
import Dropdown from './Dropdown';
import CopyButton from './CopyButton';
import Print from './Print';
import Autocomplete, { IOption } from './Autocomplete';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import callingCodes from '../assets/json/callingCodes.json';
import rapidTestStatuses from '../assets/json/rapidTestStatuses.json';
import { SEXES, PASSENGER_STATUSES } from '../constants';
import { Passenger, NationalRegistryPerson } from '../models/Models';
import * as httpService from '../services/httpService';
import ConfirmDialog from './ConfirmDialog';
import {
  getDateOfBirthFromSsn,
  showResidenceInfo,
  splitFullName,
  replaceUnwantedCharsInSsn,
  canRegisterRapidTestResult,
} from '../utils';
import { borderControlStatus } from '../constants/enums';
import {
  lookupInNationalRegistry,
  checkIfLocationHasAnyTimeSlots,
} from '../services/httpService.v2';
import theme from '../theme';
import { UserContext } from '../context/UserContext';

interface IProps {
  onSubmit: (values: IFormValues) => Promise<string | null>;
  rapidQuery?: boolean;
}

export interface IFormValues {
  firstName: string;
  lastName: string;
  dateOfBirth: Moment | null;
  sex: string;
  countryOfDeparture: string;
  flightNumber: string;
  arrivalDate: Moment | null;
  departureFromIceland: Moment | null;
  callingCode: IOption | null;
  phoneNumber: string;
  email: string;
  ssn: string;
  testType: string;
  nationality: string;
  countryOfResidence: string;
  testLocation: string;
  residenceStreetName: string;
  residenceStreetNumber: string;
  residenceApartmentNumber: string;
  residenceAddressDetails: string;
  residencePostalCode: string;
  residenceCity: string;
  extraSamplingReqired: boolean;
  extraSamplingSelectedTestLocation: number;
  samplingAtTheAirport: string;
  icelandRelations: string;
  testResult?: string;
}

type IFormik = FormikProps<IFormValues>;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  clearButton: {
    marginLeft: theme.spacing(2),
  },
  success: {
    borderLeft: `4px solid ${theme.palette.success.main}`,
    paddingLeft: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
  },
  residenceInfo: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  residenceTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },
}));

const CHILDREN_CUTOFF = moment('31.12.2004', 'DD.MM.YYYY');

const CreatePassengerForm: React.FC<IProps> = ({ onSubmit, rapidQuery }) => {
  const classes = useStyles({});
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const lastNameInput = useRef<HTMLInputElement>(null);
  const [createAnotherPassenger, setCreateAnotherPassenger] =
    useState<boolean>(false);
  const [serialNumber, setSerialNumber] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [birthDateError, setBirthDateError] = useState(false);
  const [arrivalDateError, setArrivalDateError] = useState(false);
  const [currentPassenger, setCurrentPassenger] = useState<Passenger>(
    new Passenger({})
  );
  const [showConnectionToIceland, setShowConnectionToIceland] = useState(false);

  const [submitValues, setSubmitValues] = useState<IFormValues | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [testType, setTestType] = useState(
    rapidQuery ? borderControlStatus.REGISTERED_FOR_RAPID_TEST.toString() : ''
  );
  const [locationOptions, setLocationOptions] = useState<ILocationOption[]>([]);
  const [rapidLocationOptions, setRapidLocationOptions] = useState<
    ILocationOption[]
  >([]);
  const [nationalityOptions, setNationalityOptions] = useState<
    IOption[] | null
  >(null);
  const [callingCodeOptions, setCallingCodeOptions] = useState<IOption[]>([]);
  const [fifthDaySamplingDisabled, setFifthDaySamplingDisabled] = useState<
    boolean | null
  >(null);

  const disabled = loading || !!serialNumber;
  const symptomSampling =
    testType === borderControlStatus.REGISTERED_FOR_SYMPTOM_SAMPLING.toString();
  const rapidTest =
    testType === borderControlStatus.REGISTERED_FOR_RAPID_TEST.toString();
  const showCountryOfDeparture = !!testType && !symptomSampling && !rapidTest;
  const fifthDaySampling =
    testType === borderControlStatus.REGISTERED_FOR_5DAYS_SAMPLING.toString();
  const loadingCountries = nationalityOptions === null;
  const [ssnError, setSsnError] = useState(false);
  const [ssnHelperText, setSsnHelperText] = useState<string | undefined>(
    undefined
  );
  const [locationBookable, setLocationBookable] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<number | undefined>(
    undefined
  );
  interface ILocationOption extends IOption {
    loginLocations: any;
    hasSchedule: boolean;
  }
  useEffect(() => {
    const getIsDisabled = async () => {
      const { data: disabled } =
        await httpService.getIsFifthDaySamplingDisabled();
      setFifthDaySamplingDisabled(disabled);
    };
    const fetchLocations = async () => {
      const response = await httpService.getSymptomSamplingScheduleLocations();
      if (response.isOk) {
        const filtered = response.data.filter((x: any) => !x.bookableAntigen);
        const rapid = response.data.filter((x: any) => x.bookableAntigen);
        setLocationOptions(filtered);
        setRapidLocationOptions(rapid);
      }
    };
    getIsDisabled();
    fetchLocations();
  }, []);

  useEffect(() => {
    const getNationalities = async () => {
      const response = await httpService.getCountryCodeList();
      setNationalityOptions(
        response.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((c) => ({ label: c.name, value: c.code }))
      );
    };
    getNationalities();

    const cOptions = callingCodes
      .filter((c) => !!c.code)
      .map((c) => ({
        label: c.name + ` (+${c.code})`,
        value: c.code as string,
      }));
    setCallingCodeOptions(cOptions);
  }, []);

  return (
    <Formik
      onSubmit={openDialog}
      validationSchema={getSchema()}
      initialValues={getInitialValues()}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Paper elevation={2} className={classes.paper}>
            <Grid container spacing={2}>
              {renderFormFields(formik)}
              {renderButtons(formik)}
              {renderDialog()}
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );

  function renderFormFields(formik: IFormik) {
    const {
      values,
      errors,
      submitCount,
      handleChange,
      handleBlur,
      setFieldValue,
    } = formik;
    const loadingFifthDaySampling = fifthDaySamplingDisabled === null;

    function setDateOfBirth(e: moment.Moment | null) {
      setFieldValue('dateOfBirth', e ? moment(e) : null);
      const age = moment().startOf('year').diff(e?.startOf('year'), 'year');
      const isChild = age >= 6 && age <= 16;
      const isSampling =
        testType === borderControlStatus.REGISTERED_FOR_SAMPLING.toString();
      const isCertificate =
        testType === borderControlStatus.REGISTERED_WITH_CERTIFICATE.toString();

      if (isSampling && isChild) {
        setShowConnectionToIceland(true);
      } else if (isCertificate) {
        setShowConnectionToIceland(true);
      } else {
        setShowConnectionToIceland(false);
      }
    }

    async function lookupBySsn(
      ssn: string,
      setValue: (field: string, value: any) => void
    ) {
      if (ssn.length !== 10) {
        setValue('name', '');
        setValue('dateOfBirth', null);
        setValue('nationality', '');
        setValue('sex', '');
        setValue('callingCode', null);
        setValue('firstName', '');
        setValue('lastName', '');
        return;
      }
      const response = await lookupInNationalRegistry({ ssn: ssn });
      if (response.isOk) {
        setFormSsnError(false, '');
        const person = response.data as NationalRegistryPerson;
        setValue('dateOfBirth', getDateOfBirthFromSsn(ssn));
        const fullAndLastName = splitFullName(person.personName);
        if (fullAndLastName !== null) {
          setValue('firstName', fullAndLastName.firstName);
          setValue('lastName', fullAndLastName.lastName);
        }
        const iceland = nationalityOptions?.find((x) => x.value === 'IS');
        if (iceland) {
          setValue('nationality', iceland.value);
        }
        const isCallingCode = callingCodeOptions.find((x) => x.value === '354');
        if (isCallingCode) {
          setValue('callingCode', isCallingCode);
        }
      } else {
        const res = response.data as any;
        if (res.errorCode === 404) {
          setFormSsnError(true, translations.notFoundInNatReg[lang]);
        } else {
          setFormSsnError(true, res.errorMessage);
        }
      }
    }

    function setFormSsnError(isError: boolean, text?: string) {
      setSsnError(isError);
      setSsnHelperText(text);
    }
    async function checkIfLocationIsBookable(locationId: number) {
      setSelectedLocation(locationId);
      setLoading(true);
      const response = await checkIfLocationHasAnyTimeSlots(locationId);
      if (response.data) {
        setLocationBookable(true);
      } else {
        setLocationBookable(true);
      }
      setLoading(false);
    }

    return (
      <React.Fragment>
        <Grid item xs={12} md={12}>
          <FormControl variant="outlined" fullWidth required>
            <InputLabel>
              {loadingFifthDaySampling
                ? translations.loadingStatusOptions[lang]
                : translations.status[lang]}
            </InputLabel>
            <Select
              autoFocus
              name="testType"
              labelWidth={50}
              value={values.testType}
              onChange={(e) => {
                setTestTypeValue(e.target.value as string, setFieldValue);
              }}
              error={!!errors.testType && !!submitCount}
              disabled={disabled || loadingFifthDaySampling}
              MenuProps={{
                style: { maxHeight: 500 },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}
            >
              {PASSENGER_STATUSES.filter(
                (s) =>
                  !fifthDaySamplingDisabled ||
                  s.key !==
                    borderControlStatus.REGISTERED_FOR_5DAYS_SAMPLING.toString()
              ).map((s) => (
                <MenuItem
                  style={{
                    color: isTypeInUse(s.key)
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary,
                  }}
                  key={s.key}
                  value={s.key}
                >
                  {translations[s.translationKey][lang]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {(symptomSampling || rapidTest) && (
          <Grid item xs={12} md={12}>
            <Dropdown
              fullWidth
              required
              id="testLocation"
              value={values.testLocation}
              label={translations.testLocation[lang]}
              items={symptomSampling ? locationOptions : rapidLocationOptions}
              onChange={(e) => {
                handleChange(e);
                checkIfLocationIsBookable(e.target.value as number);
              }}
              error={!!errors.testLocation && !!submitCount}
            />
          </Grid>
        )}
        {!isLocationValid() && !loading && (
          <Grid item xs={12} md={12}>
            {translations.noSheduleFoundError[lang]}
          </Grid>
        )}
        {isLocationValid() && (
          <Fragment>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                autoComplete="off"
                id="ssn"
                variant="outlined"
                inputProps={{
                  type: 'text',
                  pattern: '[0-9+]*',
                  maxLength: 10,
                }}
                label={translations.ssn[lang]}
                value={values.ssn}
                error={(!!errors.ssn && !!submitCount) || ssnError}
                helperText={ssnHelperText}
                disabled={disabled}
                onChange={(e) => {
                  e.target.value = replaceUnwantedCharsInSsn(e.target.value);
                  if (e.target.validity.valid) {
                    lookupBySsn(e.target.value, setFieldValue);
                  }
                  if (e.target.value === '') {
                    setFormSsnError(false);
                  }
                  handleChange(e);
                }}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                autoComplete="off"
                required
                id="email"
                variant="outlined"
                type="email"
                label={translations.email[lang]}
                value={values.email}
                error={!!errors.email && !!submitCount}
                disabled={disabled}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                autoComplete="off"
                id="lastName"
                variant="outlined"
                label={translations.lastName[lang]}
                inputProps={{ ref: lastNameInput }}
                value={values.lastName}
                error={!!errors.lastName && !!submitCount}
                disabled={disabled || (values.ssn.length === 10 && !ssnError)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                autoComplete="off"
                id="firstName"
                variant="outlined"
                label={translations.firstNames[lang]}
                value={values.firstName}
                error={!!errors.firstName && !!submitCount}
                disabled={disabled || (values.ssn.length === 10 && !ssnError)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <DatePicker
                id="dateOfBirth"
                label={translations.dateOfBirth[lang]}
                value={values.dateOfBirth}
                muiProps={{
                  required: true,
                  disabled: disabled,
                  maxDate: getMaxDate(),
                  minDate: getMinDate(),
                  maxDateMessage: !!submitCount
                    ? symptomSampling || rapidTest
                      ? translations.maxDateForSymptomSampling[lang]
                      : fifthDaySampling
                      ? translations.fiveDaysSamplingRestriction[lang]
                      : translations.maxDateChild[lang]
                    : null,
                  minDateMessage: !!submitCount
                    ? fifthDaySampling
                      ? translations.fiveDaysSamplingRestriction[lang]
                      : null
                    : null,
                  invalidDateMessage: !!submitCount
                    ? translations.invalidDate[lang]
                    : null,
                  error:
                    (!!errors.dateOfBirth || birthDateError) && !!submitCount,
                  onError: (err: React.ReactNode) => setBirthDateError(!!err),
                }}
                onChange={(e) => setDateOfBirth(e)}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                fullWidth
                required
                id="callingCode"
                value={values.callingCode}
                label={translations.countryCode[lang]}
                items={callingCodeOptions}
                onChange={(val) => setFieldValue('callingCode', val)}
                error={!!errors.callingCode && !!submitCount}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                required
                autoComplete="off"
                id="phoneNumber"
                variant="outlined"
                inputProps={{
                  type: 'text',
                  pattern: '[0-9+]*',
                }}
                label={translations.phoneNumber[lang]}
                value={values.phoneNumber}
                error={!!errors.phoneNumber && !!submitCount}
                disabled={disabled}
                onChange={(e) =>
                  !e.target.validity.patternMismatch && handleChange(e)
                }
                onBlur={handleBlur}
              />
            </Grid>
          </Fragment>
        )}
        {rapidTest && canRegisterRapidTestResult(user) && (
          <Grid item xs={12} md={6}>
            <Dropdown
              fullWidth
              id="testResult"
              value={values.testResult}
              label={translations.testResult[lang]}
              items={rapidTestStatuses.map((item) => ({
                label: item.description[lang],
                value: item.id.toString(),
              }))}
              onChange={handleChange}
              disabled={disabled}
            />
          </Grid>
        )}
        {!symptomSampling && !rapidTest && (
          <>
            <Grid item xs={12} md={6}>
              <Dropdown
                fullWidth
                id="sex"
                value={values.sex}
                label={translations.sex[lang]}
                items={SEXES.map((s) => ({
                  label: translations[s.translationKey][lang],
                  value: s.key,
                }))}
                onChange={handleChange}
                error={!!errors.sex && !!submitCount}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Dropdown
                fullWidth
                id="nationality"
                value={values.nationality}
                label={
                  loadingCountries
                    ? translations.loadingCountries[lang]
                    : translations.nationality[lang]
                }
                items={nationalityOptions || []}
                onChange={handleChange}
                error={!!errors.nationality && !!submitCount}
                disabled={disabled || loadingCountries}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Dropdown
                fullWidth
                id="countryOfResidence"
                value={values.countryOfResidence}
                label={
                  loadingCountries
                    ? translations.loadingCountries[lang]
                    : translations.countryOfResidence[lang]
                }
                items={nationalityOptions || []}
                onChange={handleChange}
                error={!!errors.countryOfResidence && !!submitCount}
                disabled={disabled || loadingCountries}
              />
            </Grid>
          </>
        )}
        {!symptomSampling && !rapidTest && (
          <>
            {showCountryOfDeparture && (
              <Grid item xs={12} md={3}>
                <Dropdown
                  fullWidth
                  id="countryOfDeparture"
                  value={values.countryOfDeparture}
                  label={
                    loadingCountries
                      ? translations.loadingCountries[lang]
                      : translations.countryOfDeparture[lang]
                  }
                  items={nationalityOptions || []}
                  onChange={handleChange}
                  error={!!errors.countryOfDeparture && !!submitCount}
                  disabled={disabled || loadingCountries}
                />
              </Grid>
            )}
            <Grid item xs={12} md={showCountryOfDeparture ? 3 : 3}>
              <DatePicker
                id="arrivalDate"
                label={translations.arrivalDate[lang]}
                value={values.arrivalDate}
                muiProps={{
                  required: false,
                  disableToolbar: true,
                  disabled: disabled,
                  invalidDateMessage: !!submitCount
                    ? translations.invalidDate[lang]
                    : null,
                  error:
                    (!!errors.arrivalDate || arrivalDateError) && !!submitCount,
                  onError: (err: React.ReactNode) => setArrivalDateError(!!err),
                }}
                onChange={(e) =>
                  setFieldValue('arrivalDate', e ? moment(e) : null)
                }
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={showCountryOfDeparture ? 3 : 3}>
              <DatePicker
                id="departureFromIceland"
                label={translations.departureFromIceland[lang]}
                value={values.departureFromIceland}
                muiProps={{
                  disableToolbar: true,
                }}
                onChange={(e) =>
                  setFieldValue('departureFromIceland', e ? moment(e) : null)
                }
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={showCountryOfDeparture ? 3 : 6}>
              <TextField
                fullWidth
                id="flightNumber"
                autoComplete="off"
                variant="outlined"
                label={translations.flightNumber[lang]}
                value={values.flightNumber}
                error={!!errors.flightNumber && !!submitCount}
                disabled={disabled}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </>
        )}

        {showResidenceInfo(testType) && renderResidenceFields(formik)}
        {showConnectionToIceland && (
          <React.Fragment>
            <Grid item xs={12} sm={6} md={3}>
              <Dropdown
                fullWidth
                required
                id="icelandRelations"
                value={values.icelandRelations}
                label={translations.icelandRelations[lang]}
                items={[
                  {
                    label: translations.yes[lang],
                    value: 'true',
                  },
                  {
                    label: translations.no[lang],
                    value: 'false',
                  },
                ]}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    'extraSamplingReqired',
                    e.target.value === 'true' ? true : false
                  );
                }}
                error={!!errors.extraSamplingReqired && !!submitCount}
                disabled={disabled}
              />
            </Grid>
            {values.extraSamplingReqired && (
              <Grid item xs={12} sm={6} md={3}>
                <Dropdown
                  fullWidth
                  required
                  id="samplingAtTheAirport"
                  value={values.samplingAtTheAirport.toString()}
                  label={translations.samplingAtTheAirport[lang]}
                  items={[
                    {
                      label: translations.yes[lang],
                      value: 'true',
                    },
                    {
                      label: translations.no[lang],
                      value: 'false',
                    },
                  ]}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue(
                      'extraSamplingSelectedTestLocation',
                      e.target.value === 'true' ? 0 : 1
                    );
                  }}
                  error={!!errors.extraSamplingReqired && !!submitCount}
                  disabled={disabled}
                />
              </Grid>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  function renderButtons(formik: IFormik) {
    return (
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {renderStatusText()}
          <Grid item style={{ display: 'flex' }}>
            {(symptomSampling || rapidTest) && (
              <div style={{ marginTop: theme.spacing(1) }}>
                <FormControlLabel
                  label={translations.registerAnother[lang]}
                  control={
                    <Checkbox
                      id="createAnother"
                      color="primary"
                      checked={createAnotherPassenger}
                      onChange={() =>
                        setCreateAnotherPassenger(!createAnotherPassenger)
                      }
                    />
                  }
                />
              </div>
            )}

            <div style={{ flex: 1 }}>
              {serialNumber ? null : (
                <Button
                  variant="contained"
                  type="submit"
                  disabled={loading || !isLocationValid()}
                >
                  {translations.registerPerson[lang]}
                </Button>
              )}
              <Button
                variant="outlined"
                onClick={() => clearForm(formik, createAnotherPassenger)}
                className={classes.clearButton}
                disabled={loading}
              >
                {translations.clear[lang]}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderStatusText() {
    if (error) {
      return (
        <Grid item>
          <Typography color="textSecondary" className={classes.error}>
            {translations.registerPassengerError[lang]}
          </Typography>
        </Grid>
      );
    }
    if (serialNumber) {
      return (
        <Grid item>
          <div className={classes.success}>
            <Typography color="textSecondary">
              {translations.registerPassengerSuccess[lang]}
            </Typography>
            <span style={{ display: 'flex' }}>
              <Typography color="textSecondary">
                {translations.serialNumber[lang]}: {serialNumber}
              </Typography>
              <CopyButton text={serialNumber} />
              <Print passenger={currentPassenger} />
            </span>
          </div>
        </Grid>
      );
    }
    return <div />;
  }

  function renderResidenceFields({
    values,
    errors,
    submitCount,
    handleChange,
  }: IFormik) {
    return (
      <Grid item xs={12} className={classes.residenceInfo}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.residenceTitle}>
              {translations.residenceInfo[lang]}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              required
              id="residenceStreetName"
              variant="outlined"
              disabled={disabled}
              value={values.residenceStreetName}
              label={translations.residenceStreetName[lang]}
              error={!!errors.residenceStreetName && !!submitCount}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              required
              id="residenceStreetNumber"
              variant="outlined"
              disabled={disabled}
              value={values.residenceStreetNumber}
              label={translations.residenceStreetNumber[lang]}
              error={!!errors.residenceStreetNumber && !!submitCount}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              id="residenceApartmentNumber"
              variant="outlined"
              disabled={disabled}
              value={values.residenceApartmentNumber}
              label={translations.residenceApartmentNumber[lang]}
              error={!!errors.residenceApartmentNumber && !!submitCount}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              id="residenceAddressDetails"
              variant="outlined"
              disabled={disabled}
              value={values.residenceAddressDetails}
              label={translations.residenceAddressDetails[lang]}
              error={!!errors.residenceAddressDetails && !!submitCount}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              required
              id="residencePostalCode"
              variant="outlined"
              inputProps={{
                type: 'text',
                pattern: '[0-9]{0,3}',
              }}
              disabled={disabled}
              value={values.residencePostalCode}
              label={translations.residencePostalCode[lang]}
              error={!!errors.residencePostalCode && !!submitCount}
              onChange={(e) =>
                !e.target.validity.patternMismatch && handleChange(e)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              required
              id="residenceCity"
              variant="outlined"
              disabled={disabled}
              value={values.residenceCity}
              label={translations.residenceCity[lang]}
              error={!!errors.residenceCity && !!submitCount}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function getSchema() {
    return Yup.object().shape({
      testType: Yup.string().required(),
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      dateOfBirth: Yup.object().required(),
      sex:
        symptomSampling || rapidTest ? Yup.string() : Yup.string().required(),
      countryOfDeparture: showCountryOfDeparture
        ? Yup.string().required()
        : Yup.string(),
      arrivalDate: Yup.object().required(),
      email: Yup.string().email().required(),
      callingCode: Yup.object().required(),
      phoneNumber: Yup.string().required(),
      ssn: Yup.string()
        .length(10)
        .matches(/^[0-3]\d[01]\d{6}[09]$/),
      nationality:
        symptomSampling || rapidTest ? Yup.string() : Yup.string().required(),
      countryOfResidence:
        symptomSampling || rapidTest ? Yup.string() : Yup.string().required(),
      testLocation:
        symptomSampling || rapidTest ? Yup.string().required() : Yup.string(),
      residenceStreetName: showResidenceInfo(testType)
        ? Yup.string().required()
        : Yup.string(),
      residenceStreetNumber: showResidenceInfo(testType)
        ? Yup.string().required()
        : Yup.string(),
      residencePostalCode: showResidenceInfo(testType)
        ? Yup.string().required().length(3)
        : Yup.string(),
      residenceCity: showResidenceInfo(testType)
        ? Yup.string().required()
        : Yup.string(),
      extraSamplingSelectedTestLocation: showConnectionToIceland
        ? Yup.number().required()
        : Yup.number(),
      extraSamplingReqired: showConnectionToIceland
        ? Yup.boolean().required()
        : Yup.boolean(),
    });
  }

  function getMaxDate() {
    if (fifthDaySampling) {
      return moment().subtract(2, 'years');
    }
    return symptomSampling ||
      rapidTest ||
      testType === borderControlStatus.REGISTERED_FOR_SAMPLING.toString() ||
      testType === borderControlStatus.REGISTERED_WITH_CERTIFICATE.toString()
      ? moment(moment(), 'DD.MM.YYYY')
      : CHILDREN_CUTOFF;
  }

  function getMinDate() {
    return fifthDaySampling
      ? moment().subtract(16, 'years')
      : moment().subtract(200, 'years');
  }

  function getInitialValues(): IFormValues {
    return {
      testType: rapidQuery
        ? borderControlStatus.REGISTERED_FOR_RAPID_TEST.toString()
        : '',
      firstName: '',
      lastName: '',
      dateOfBirth: null,
      sex: '',
      countryOfDeparture: '',
      flightNumber: '',
      arrivalDate: moment(),
      departureFromIceland: null as Moment | null,
      callingCode: null,
      phoneNumber: '',
      email: '',
      ssn: '',
      nationality: '',
      countryOfResidence: '',
      testLocation: '',
      residenceStreetName: '',
      residenceStreetNumber: '',
      residenceApartmentNumber: '',
      residenceAddressDetails: '',
      residencePostalCode: '',
      residenceCity: '',
      extraSamplingReqired: false,
      extraSamplingSelectedTestLocation: 1,
      samplingAtTheAirport: '',
      icelandRelations: '',
      testResult: '',
    };
  }

  async function openDialog(values: IFormValues) {
    if (!birthDateError && !arrivalDateError && !ssnError) {
      setSubmitValues(values);
      setDialogOpen(true);
    }
  }

  async function createPassenger(values: IFormValues | null) {
    setLoading(true);
    if (values) {
      const newSerialNumber = await onSubmit(values);
      setSerialNumber(newSerialNumber);
      setError(!newSerialNumber);
      httpService
        .getPassengerByRegistrationId(newSerialNumber ?? '')
        .then((response) => {
          if (response.isOk) {
            const newPassenger = new Passenger({
              barCode: newSerialNumber,
              status: { id: response.data?.borderControlStatus.id },
            });
            setCurrentPassenger(newPassenger);
          }
        });
    }
    setDialogOpen(false);
    setLoading(false);
  }

  function clearForm(formik: IFormik, reUseLocation: boolean = false) {
    // preserve values
    const testLocation = formik.values?.testLocation;
    const testType = formik.values?.testType;

    formik.handleReset();
    focusLastNameInput();
    setSerialNumber(null);
    setError(false);
    setSsnError(false);
    setSsnHelperText('');

    // re use testLocation and testType if applicable
    if (reUseLocation) {
      formik.setFieldValue('testLocation', testLocation);
      formik.setFieldValue('testType', testType);
    }
  }

  function focusLastNameInput() {
    if (lastNameInput && lastNameInput.current) {
      lastNameInput.current.focus();
    }
  }

  function renderDialog() {
    const type = submitValues?.testType;
    const translationDialogKey = PASSENGER_STATUSES.find((s) => s.key === type);
    let dialogText = '';

    if (translationDialogKey) {
      dialogText =
        translations[translationDialogKey.translationDialogKey][lang];
    }

    return (
      <ConfirmDialog
        open={dialogOpen}
        loading={loading}
        title={translations.registerPerson[lang]}
        text={dialogText}
        onConfirm={() => createPassenger(submitValues)}
        onCancel={() => setDialogOpen(false)}
        confirmText={translations.confirm[lang]}
      ></ConfirmDialog>
    );
  }

  function setTestTypeValue(value: string, setFieldValue: any) {
    setFieldValue('testLocation', '');
    setTestType(value);
    setFieldValue('testType', value);
  }

  function isTypeInUse(key: string) {
    if (
      key === borderControlStatus.REGISTERED_FOR_SYMPTOM_SAMPLING.toString()
    ) {
      return true;
    }
    if (key === borderControlStatus.REGISTERED_FOR_RAPID_TEST.toString()) {
      return true;
    }
    return false;
  }
  function isLocationValid() {
    if ((symptomSampling || rapidTest) && selectedLocation !== undefined) {
      return locationBookable;
    } else {
      return true;
    }
  }
};

export default CreatePassengerForm;
