import React, { useContext, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Moment } from 'moment';
import moment from 'moment';

import VaccinationPersonGroupedInfo, {
  IButton,
} from './VaccinationPersonGroupedInfo';
import CopyButton from './CopyButton';
import GroupsInfo from './GroupsInfo';
import SendVaccinationCertificateDialog from './SendVaccinationCertificateDialog';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import {
  VaccinationLocation,
  VaccinationPerson,
  Vaccine,
} from '../models/VaccinationModels';
import {
  IVaccinationPersonDetails,
  IVaccinationPersonPhoneNumberUpdate,
} from '../interfaces/vaccinationPerson';
import VaccinationStatusTable, {
  IFormValues as IUpdateFormValues,
} from './VaccinationStatusTable';
import useNotifier from '../hooks/useNotifier';
import VaccinationComment from './VaccinationComment';
import {
  IVaccinationComment,
  IVaccinationCommentStatus,
} from '../interfaces/vaccinationComment';
import * as vaccinationService from '../services/vaccinationService';
import * as influenzaService from '../services/influenzaService';
import {
  IInfluenzaVaccinationUpdate,
  IVaccinationUpdate,
} from '../interfaces/vaccinationUpdate';
import {
  INationality,
  ISendCertificateValues,
} from '../interfaces/vaccinationSearch';
import { isAccessible } from '../utils';
import { UserContext } from '../context/UserContext';
import { accessControl, VaccineType } from '../constants/enums';
import { DATE_AND_TIME_FORMAT } from '../constants';
import EditPhoneNumber from './EditPhoneNumber';
import { lookupInNationalRegistry } from '../services/httpService.v2';
import { NationalRegistryPerson } from '../models/Models';

interface IProps {
  vaccines: Vaccine[] | null;
  locations: VaccinationLocation[] | null;
  nationalities: INationality[] | null;
  commentStatusList: IVaccinationCommentStatus[] | null;
  vaccinationPerson: VaccinationPerson;
  sendCertificateEnabled?: boolean;
  reloadSelectedPerson?: (barcode: string) => Promise<void>;
  back: () => void;
}

const VaccinationSearchDetails: React.FC<IProps> = ({
  vaccines,
  locations,
  nationalities,
  commentStatusList,
  vaccinationPerson,
  sendCertificateEnabled = false,
  reloadSelectedPerson,
  back,
}) => {
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const history = useHistory();
  const { notifyError, notifySuccess } = useNotifier();

  const [sendCertificateDialogOpen, setSendCertificateDialogOpen] =
    useState(false);
  const [sendCertificateError, setSendCertificateError] = useState(false);
  const [nameFromRegistry, setNameFromRegistry] = useState<string | null>(null);

  React.useEffect(() => {
    const fetchNameFromRegistry = async () => {
      const response = await lookupInNationalRegistry({
        ssn: vaccinationPerson.ssn,
      });
      if (response.isOk) {
        const person = response.data as NationalRegistryPerson;
        setNameFromRegistry(person.personName);
      }
    };
    fetchNameFromRegistry();
  }, [vaccinationPerson.ssn]);

  return (
    <>
      <VaccinationPersonGroupedInfo
        vaccinationPerson={vaccinationPerson}
        vaccinationPersonDetails={getMemberGroupedDetails()}
        buttons={getButtons()}
      />
      {sendCertificateEnabled && (
        <SendVaccinationCertificateDialog
          open={sendCertificateDialogOpen}
          vaccinationPerson={vaccinationPerson}
          nameFromRegistry={
            nameFromRegistry
              ? nameFromRegistry
              : translations.notFoundInNatReg[lang]
          }
          nationalities={nationalities}
          error={sendCertificateError}
          onSubmit={sendVaccinationCertificate}
          onCancel={() => setSendCertificateDialogOpen(false)}
        />
      )}
    </>
  );

  function getMemberGroupedDetails(): IVaccinationPersonDetails[][] {
    const details: IVaccinationPersonDetails[][] = [];

    const personDetails: IVaccinationPersonDetails[] = [
      {
        label: translations.name[lang],
        getVal(vaccinationPerson: VaccinationPerson) {
          return vaccinationPerson.name;
        },
      },
      {
        label: translations.SSN[lang],
        getVal(vaccinationPerson: VaccinationPerson) {
          return vaccinationPerson.ssn;
        },
      },
      {
        label: translations.phoneNumber[lang],
        render(vaccinationPerson: VaccinationPerson) {
          return (
            <EditPhoneNumber
              phoneNumber={vaccinationPerson.phoneNumber}
              canEdit
              submitChanges={updatePhoneNumber}
            />
          );
        },
      },
      {
        label: translations.serialNumber[lang],
        render(vaccinationPerson: VaccinationPerson) {
          return (
            <React.Fragment>
              <Typography
                style={{ display: 'inline-block' }}
                color="textSecondary"
              >
                {vaccinationPerson.serialNumber}
              </Typography>
              <CopyButton text={vaccinationPerson.serialNumber} />
            </React.Fragment>
          );
        },
      },
    ];

    if (
      !vaccinationPerson.secondVaccination.vaccinationDate &&
      vaccinationPerson.nextSummoning
    ) {
      personDetails.push({
        label: translations.summoningDate[lang],
        getVal(vaccinationPerson: VaccinationPerson) {
          return (
            `${moment(vaccinationPerson.nextSummoning?.date).format(
              DATE_AND_TIME_FORMAT
            )} - ${vaccinationPerson.nextSummoning?.location.name} ` || ''
          );
        },
      });
    }

    details.push(personDetails);

    details.push([
      {
        label: translations.comment[lang],
        render: (vaccinationPerson: VaccinationPerson) => (
          <VaccinationComment
            vaccinationComment={vaccinationPerson.vaccinationComment}
            canEdit={
              vaccinationPerson.groups && vaccinationPerson.groups.length > 0
            }
            commentStatusList={commentStatusList}
            updateComment={updateComment}
            deleteComment={deleteComment}
            locations={locations}
          />
        ),
      },
    ]);

    details.push([
      {
        label: translations.vaccinations[lang],
        render: (vaccinationPerson: VaccinationPerson) => (
          <VaccinationStatusTable
            vaccinationPerson={vaccinationPerson}
            vaccinations={vaccinationPerson.vaccinations}
            vaccines={
              vaccines?.filter((v) => v.vaccineType === VaccineType.COVID_19) ||
              []
            }
            locations={locations}
            canEdit={
              isAccessible(accessControl.VACCINATION_GROUP, user) ||
              isAccessible(accessControl.VACCINATION, user)
            }
            updateVaccination={updateVaccination}
            revokeVaccination={revokeVaccination}
          />
        ),
      },
    ]);

    details.push([
      {
        label: translations.influenzaVaccinations[lang],
        render: (vaccinationPerson: VaccinationPerson) => (
          <VaccinationStatusTable
            vaccinationPerson={vaccinationPerson}
            vaccinations={vaccinationPerson.influenzaVaccinatnions}
            vaccines={
              vaccines?.filter(
                (v) => v.vaccineType === VaccineType.INFLUENZA
              ) || []
            }
            locations={locations}
            canEdit={
              isAccessible(accessControl.VACCINATION_GROUP, user) ||
              isAccessible(accessControl.VACCINATION, user)
            }
            updateVaccination={updateInfluenzaVaccination}
            revokeVaccination={revokeInfluenzaVaccination}
          />
        ),
      },
    ]);

    details.push([
      {
        render: (vaccinationPerson: VaccinationPerson) => {
          return (
            <GroupsInfo
              groups={vaccinationPerson.groups}
              accessibleRegionIds={
                user?.vaccinationRegionModelList
                  ? user?.vaccinationRegionModelList.map((r: any) =>
                      r.id.toString()
                    )
                  : []
              }
              clickable={isAccessible(
                accessControl.VACCINATION_GROUP_MANAGEMENT,
                user
              )}
            />
          );
        },
      },
    ]);

    return details;
  }

  function getButtons() {
    const buttons: IButton[] = [];

    if (sendCertificateEnabled) {
      buttons.push({
        label: translations.sendCertificate[lang],
        variant: 'outlined',
        color: 'secondary',
        style: { marginRight: 'auto' },
        onClick: () => setSendCertificateDialogOpen(true),
      });
    }
    if (vaccinationPerson.secondVaccination.vaccinationDate) {
      buttons.push({
        label: translations.fullyVaccinated[lang],
        variant: 'outlined',
        disabled: true,
        onClick: () => {},
      });
    }
    buttons.push({
      label: translations.back[lang],
      variant: 'outlined',
      onClick: back,
    });

    return buttons;
  }

  async function updateVaccination(values: IUpdateFormValues) {
    if (vaccinationPerson.ssn && values.vaccine) {
      const params = {
        personSsn: vaccinationPerson.ssn,
        vaccinationId: values.id,
        vaccinationCodeId: parseInt(values.vaccine.value),
        locationId: parseInt(values.location),
        vaccinationDate: getDateTime(
          values.vaccinationDate,
          values.vaccinationTime
        ).toISOString(),
        isFirstVaccination: values.isFirstVaccination,
      } as IVaccinationUpdate;

      const response = await vaccinationService.updateVaccination(params);

      if (response.isOk) {
        reloadSelectedPerson &&
          (await reloadSelectedPerson(vaccinationPerson.serialNumber));
        notifySuccess(translations.vaccinationUpdated[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function updateInfluenzaVaccination(values: IUpdateFormValues) {
    if (vaccinationPerson?.serialNumber && values.vaccine) {
      const params = {
        vaccinationCodeId: parseInt(values.vaccine.value),
        locationId: parseInt(values.location),
        vaccinationDate: getDateTime(
          values.vaccinationDate,
          values.vaccinationTime
        ).toISOString(),
      } as IInfluenzaVaccinationUpdate;

      const response = await influenzaService.updateVaccination(
        params,
        values.id
      );

      if (response.isOk) {
        reloadSelectedPerson &&
          (await reloadSelectedPerson(vaccinationPerson.serialNumber));
        notifySuccess(translations.vaccinationUpdated[lang]);
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  function getDateTime(dateValue: Moment | null, timeValue: Moment | null) {
    var date = moment(dateValue);
    var time = moment(timeValue, 'HH:mm');
    date = date.hour(time.get('hour'));
    date = date.minute(time.get('minutes'));

    return date;
  }

  async function updateComment(
    statusId: number | null,
    comment: string,
    locationId: number
  ) {
    if (vaccinationPerson.vaccinationPersonId) {
      const params: IVaccinationComment = {
        comment: comment,
        statusId: statusId,
        groupId: null,
        vaccinationPersonId: vaccinationPerson.vaccinationPersonId,
        locationId,
      };
      const response =
        vaccinationPerson.vaccinationComment.id !== undefined
          ? await vaccinationService.updateVaccinationComment(
              vaccinationPerson.vaccinationComment.id,
              params
            )
          : await vaccinationService.addVaccinationComment(params);

      if (response.isOk) {
        reloadSelectedPerson &&
          (await reloadSelectedPerson(vaccinationPerson.serialNumber));
        notifySuccess(translations.commentAdded[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function updatePhoneNumber(newNumber: string) {
    if (vaccinationPerson.vaccinationPersonId) {
      const params: IVaccinationPersonPhoneNumberUpdate = {
        ssn: vaccinationPerson.ssn,
        newPhoneNumber: newNumber,
      };
      const response = await vaccinationService.updatePhoneNumber(params);

      if (response.isOk) {
        reloadSelectedPerson &&
          (await reloadSelectedPerson(vaccinationPerson.serialNumber));
        notifySuccess(translations.phoneNumberChanged[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function revokeVaccination(personSsn: string, vaccinationId: number) {
    if (vaccinationPerson) {
      const response = await vaccinationService.cancelVaccination(
        personSsn,
        vaccinationId
      );

      if (response.isOk) {
        reloadSelectedPerson &&
          (await reloadSelectedPerson(vaccinationPerson.serialNumber));
        notifySuccess(translations.vaccinationRevoked[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(response.data || translations.errorOccurred[lang]);
      }
    }
  }

  async function revokeInfluenzaVaccination(
    personSsn: string,
    vaccinationId: number
  ) {
    if (vaccinationPerson) {
      const response = await influenzaService.cancelVaccination(vaccinationId);

      if (response.isOk) {
        reloadSelectedPerson &&
          (await reloadSelectedPerson(vaccinationPerson.serialNumber));
        notifySuccess(translations.vaccinationRevoked[lang]);
      } else {
        notifyError(response.data || translations.errorOccurred[lang]);
      }
    }
  }

  async function deleteComment() {
    if (vaccinationPerson.vaccinationComment.id !== undefined) {
      const response = await vaccinationService.deleteVaccinationComment(
        vaccinationPerson.vaccinationComment.id
      );

      if (response.isOk) {
        reloadSelectedPerson &&
          (await reloadSelectedPerson(vaccinationPerson.serialNumber));
        notifySuccess(translations.commentDeleted[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    }
  }

  async function sendVaccinationCertificate(values: ISendCertificateValues) {
    setSendCertificateError(false);
    try {
      const response = await vaccinationService.sendVaccinationCertificateEmail(
        values
      );

      if (response.isOk) {
        notifySuccess(translations.sendVaccinationCertificateSuccess[lang]);
        setSendCertificateDialogOpen(false);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } catch {
      setSendCertificateError(true);
    }
  }
};

export default VaccinationSearchDetails;
