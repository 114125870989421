import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Typography,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  useTheme,
} from '@material-ui/core';

import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import Dropdown from './Dropdown';
import EditButton from './EditButton';
import Button from './Button';
import {
  VaccinationComment as VaccinationCommentModel,
  VaccinationLocation,
} from '../models/VaccinationModels';
import { IVaccinationCommentStatus } from '../interfaces/vaccinationComment';
import {
  OTHER_COMMENT_STATUS_ID,
  OTHER_COMMENT_STATUS_TEXT,
} from '../constants';
import * as localStorageKeys from '../constants/localStorageKeys';

interface IProps {
  vaccinationComment: VaccinationCommentModel;
  canEdit?: boolean;
  commentStatusList?: IVaccinationCommentStatus[] | null;
  locations: VaccinationLocation[] | null;
  updateComment?: (
    status: number | null,
    comment: string,
    locationId: number
  ) => Promise<void>;
  deleteComment?: () => Promise<void>;
}

const MAX_COMMENT_LENGTH = 150;

const VaccinationComment: React.FC<IProps> = ({
  vaccinationComment,
  canEdit = false,
  commentStatusList,
  locations,
  updateComment,
  deleteComment,
}) => {
  const [lang] = useContext(LangContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [commentText, setCommentText] = useState('');
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [locationId, setLocationId] = useState('');

  const { statusId, comment = '', statusDescription = '' } = vaccinationComment;
  const statusStr = statusId !== undefined ? statusId.toString() : '';
  const otherStatusSelected = selectedStatus === OTHER_COMMENT_STATUS_ID;
  const hasExistingComment = !!statusStr || !!comment;
  const canAddComment = !hasExistingComment && canEdit;
  const loadingStatusList = commentStatusList === null;
  const hasOtherStatusComment = !statusStr && !!comment;
  const statusHasChanged =
    statusStr !== selectedStatus &&
    !(otherStatusSelected && hasOtherStatusComment);
  const hasChanged =
    statusHasChanged ||
    commentText !== comment ||
    locationId !== vaccinationComment.locationId?.toString();

  const statusItems = (commentStatusList || []).map((s) => ({
    value: s.id.toString(),
    label: s.description,
  }));
  if (statusItems.length) {
    statusItems.push({
      value: OTHER_COMMENT_STATUS_ID,
      label: OTHER_COMMENT_STATUS_TEXT,
    });
  }

  useEffect(() => {
    setCommentText(comment);
    let currentStatus = statusId !== undefined ? statusId.toString() : '';
    if (!currentStatus && !!comment) {
      currentStatus = OTHER_COMMENT_STATUS_ID;
    }
    setSelectedStatus(currentStatus);
  }, [comment, statusId]);

  useEffect(() => {
    if (selectedStatus && inputRef && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.selectionStart = MAX_COMMENT_LENGTH;
    }
  }, [selectedStatus]);

  useEffect(() => {
    if (dialogOpen) {
      setTimeout(() => {
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
          inputRef.current.selectionStart = MAX_COMMENT_LENGTH;
        }
      }, 0);
    }
  }, [dialogOpen]);

  useEffect(() => {
    const savedLocationId = localStorage.getItem(
      localStorageKeys.VACCINATION_LOCATION_ID
    );
    if (
      vaccinationComment?.locationId &&
      locations?.find((item) => item.id === vaccinationComment.locationId)
    ) {
      setLocationId(vaccinationComment.locationId.toString());
    } else if (
      savedLocationId &&
      locations?.find((item) => item.id === Number(savedLocationId))
    ) {
      setLocationId(savedLocationId);
    } else if (locations?.length === 1) {
      setLocationId(locations[0].id.toString());
    }
  }, [vaccinationComment, locations]);

  return (
    <React.Fragment>
      <div
        onClick={canAddComment ? () => setDialogOpen(true) : undefined}
        style={{
          display: 'inline-block',
          cursor: canAddComment ? 'pointer' : undefined,
        }}
      >
        <Typography color="textSecondary" style={{ display: 'inline-block' }}>
          {getFormattedComment()}
        </Typography>
        {canEdit && <EditButton onClick={() => setDialogOpen(true)} />}
      </div>
      {canEdit && renderDialog()}
    </React.Fragment>
  );

  function renderDialog() {
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>{translations.addComment[lang]}</DialogTitle>
        <DialogContent style={{ minHeight: 80 }} dividers>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12} sm={12}>
              <Dropdown
                fullWidth
                id="commentStatus"
                value={selectedStatus}
                label={
                  loadingStatusList
                    ? translations.loadingCommentStatus[lang]
                    : translations.status[lang]
                }
                disabled={loadingStatusList}
                //error={!selectedStatus}
                items={statusItems}
                onChange={(e) => setSelectedStatus(e.target.value as string)}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                fullWidth
                id="location"
                value={locationId}
                label={translations.location[lang]}
                items={(locations || []).map((l) => ({
                  label: l.name,
                  value: l.id.toString(),
                }))}
                onChange={(e) => setLocationId(e.target.value as string)}
                // error={!updatedLocationId}
                disabled={!locations || locations.length <= 1}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                multiline
                autoComplete="off"
                id="commentText"
                variant="outlined"
                rows={4}
                error={otherStatusSelected && !commentText}
                inputRef={inputRef}
                inputProps={{ maxLength: MAX_COMMENT_LENGTH }}
                label={translations.comment[lang]}
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />
              <Typography
                color="textSecondary"
                variant="body2"
                style={{ marginTop: theme.spacing(1) }}
              >
                {commentText.length}/{MAX_COMMENT_LENGTH}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1} direction="row" alignItems="center">
            <Grid item style={{ flex: 1 }}>
              {hasExistingComment && (
                <Button
                  error
                  variant="contained"
                  padding={false}
                  disabled={loadingUpdate}
                  onClick={onDelete}
                >
                  {translations.delete[lang]}
                </Button>
              )}
            </Grid>
            {loadingUpdate && (
              <Grid item>
                <CircularProgress size={32} color="primary" />
              </Grid>
            )}
            <Grid item>
              <Button
                padding={false}
                onClick={onSubmit}
                color="primary"
                variant="contained"
                disabled={
                  loadingUpdate ||
                  !selectedStatus ||
                  !hasChanged ||
                  !locationId ||
                  (otherStatusSelected && !commentText)
                }
              >
                {translations.confirm[lang]}
              </Button>
            </Grid>
            <Grid item>
              <Button
                padding={false}
                color="primary"
                variant="outlined"
                onClick={() => setDialogOpen(false)}
              >
                {translations.cancel[lang]}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }

  function getFormattedComment() {
    if (!statusDescription && !comment) {
      return canEdit
        ? translations.addComment[lang]
        : translations.notRegistered[lang];
    }
    return `${statusDescription}${
      statusDescription && comment ? ' - ' : ''
    }${comment}`;
  }

  async function onSubmit() {
    setLoadingUpdate(true);
    if (selectedStatus && updateComment) {
      await updateComment(
        otherStatusSelected ? null : parseInt(selectedStatus),
        commentText,
        Number(locationId)
      );
    }
    setDialogOpen(false);
    setLoadingUpdate(false);
  }

  async function onDelete() {
    if (deleteComment) {
      setLoadingUpdate(true);
      await deleteComment();
      setDialogOpen(false);
      setLoadingUpdate(false);
    }
  }
};

export default VaccinationComment;
