import React from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

interface IProps {
  onClick: () => void;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(0),
    marginLeft: theme.spacing(1),
  },
  buttonIcon: {
    fontSize: 22,
  },
}));

const EditButton: React.FC<IProps> = ({ onClick, className }) => {
  const classes = useStyles();

  return (
    <IconButton color="primary" className={classes.button} onClick={onClick}>
      <EditIcon className={`${classes.buttonIcon} ${className}`} />
    </IconButton>
  );
};

export default EditButton;
