import React, { useState, useContext, useEffect } from 'react';
import { Grid, useTheme } from '@material-ui/core';

import ConfirmDialog from './ConfirmDialog';
import Dropdown from './Dropdown';
import Autocomplete, { IOption } from './Autocomplete';
import { LangContext } from '../context/LangContext';
import { UserContext } from '../context/UserContext';
import translations from '../assets/json/translations.json';
import { parseTemplateString } from '../utils';
import { VaccinationGroup } from '../models/VaccinationModels';
import * as vaccinationService from '../services/vaccinationService';

interface IProps {
  open: boolean;
  loading: boolean;
  groupId: string;
  groupCount: number;
  checkedCount: number;
  transferMembers: (toGroupId: string, copy: boolean) => Promise<void>;
  onCancel: () => void;
}

const TransferDialog: React.FC<IProps> = ({
  open,
  loading,
  groupId,
  groupCount,
  checkedCount,
  transferMembers,
  onCancel,
}) => {
  const theme = useTheme();
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);

  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<IOption | null>(null);
  const [copyOrMove, setCopyOrMove] = useState('');
  const [groups, setGroups] = useState<VaccinationGroup[] | null>(null);

  useEffect(() => {
    setSelectedGroup(null);
    const fetchGroups = async () => {
      if (selectedRegion) {
        const response = await vaccinationService.getGroups(selectedRegion);
        setGroups(response.isOk ? response.data : []);
      }
    };
    fetchGroups();
  }, [selectedRegion]);

  return (
    <ConfirmDialog
      open={open}
      loading={loading}
      title={translations.transfer[lang]}
      text={parseTemplateString(
        translations.transferDialogText[lang],
        checkedCount.toString(),
        groupCount.toString()
      )}
      disableConfirm={!selectedGroup || !copyOrMove}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <Grid
        container
        spacing={2}
        direction="column"
        style={{ marginTop: theme.spacing(2) }}
      >
        <Grid item>
          <Dropdown
            fullWidth
            required
            id="region"
            value={selectedRegion}
            label={translations.region[lang]}
            items={user.vaccinationRegionModelList.map((r: any) => ({
              label: r.description || '',
              value: r.id !== undefined ? r.id.toString() : '',
            }))}
            onChange={(e) => setSelectedRegion(e.target.value as string)}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            fullWidth
            required
            id="toGroup"
            disabled={!groups || !selectedRegion}
            value={selectedGroup}
            label={translations.moveTo[lang]}
            items={(groups || [])
              .filter((g) => g.id !== groupId)
              .map((g) => ({
                value: g.id,
                label: g.name,
              }))}
            onChange={(val) => setSelectedGroup(val as IOption)}
          />
        </Grid>
        <Grid item>
          <Dropdown
            fullWidth
            required
            id="copyOrMove"
            value={copyOrMove}
            label={translations.moveOrCopy[lang]}
            items={[
              { value: 'move', label: translations.move[lang] },
              { value: 'copy', label: translations.copy[lang] },
            ]}
            onChange={(e) => setCopyOrMove(e.target.value as string)}
          />
        </Grid>
      </Grid>
    </ConfirmDialog>
  );

  async function onConfirm() {
    if (selectedGroup && copyOrMove) {
      await transferMembers(selectedGroup.value, copyOrMove === 'copy');
    }
  }
};

export default TransferDialog;
