import React, { useState, useContext } from 'react';
import {
  makeStyles,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Paper,
} from '@material-ui/core';

import { ScheduleLocation } from '../../models/Models';
import EditButton from '../EditButton';
import UpdateFieldDialog from './UpdateFieldDialog';
import locationSettings from '../../assets/json/locationTextSettings.json';
import { LangContext } from '../../context/LangContext';
import Section from '../Section';
import { UserContext } from '../../context/UserContext';

interface IProps {
  location: any;
  scheduleLocation: ScheduleLocation;
  updateScheduleLocation: (location: ScheduleLocation) => void;
}
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  buttonGrid: {
    textAlign: 'right',
  },
  buttonFormContainer: {
    marginLeft: theme.spacing(2),
  },
  dialogContent: {
    padding: '0px',
    overflow: 'hidden',
  },
  descriptionTitle: {
    display: 'inline-block',
    fontWeight: 'bold',
    paddingRight: 10,
  },
  tabPanel: {
    marginBottom: 10,
  },
  container: {
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderBottomWidth: 0,
    borderRadius: 4,
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  edit: {
    '&:hover': {
      cursor: 'text',
    },
  },
  firstCell: {
    minWidth: '120px',
  },
  table: {
    backgroundColor: 'white',
  },
  editButton: {
    fontSize: '16px',
    marginBottom: '5px',
  },
}));

const LocationTemplateTexts: React.FC<IProps> = ({
  location,
  scheduleLocation,
  updateScheduleLocation,
}) => {
  const [user] = useContext(UserContext);
  const classes = useStyles();
  const [lang] = useContext(LangContext);

  const [updateField, setUpdateField] = useState({
    dialogOpen: false,
    value: '',
    title: '',
    label: '',
    fieldName: '',
    requiredParams: '',
  });

  const handleClickEditField = (
    value: string | null,
    title: string | null,
    label: string | null,
    fieldName: string | null
  ) => {
    setUpdateField({
      dialogOpen: true,
      value: value ? value : '',
      title: title ? title : '',
      label: label ? label : '',
      fieldName: fieldName ? fieldName : '',
      requiredParams: fieldName ? getRequiredParams(fieldName) : '',
    });
  };
  const getRequiredParams = (fieldName: string) => {
    var params = '{FORNAFN},{BARCODE}';
    if (fieldName === 'heilsuveraText' && !scheduleLocation?.bookableAntigen) {
      params = '{DAGS}';
    } else if (fieldName === 'fullTextIS' || fieldName === 'fullTextEN') {
      params = '';
    }
    return params;
  };

  const updateSheduleLocation = (newValue: string, fieldName: string) => {
    if (scheduleLocation) {
      let newScheduleLocation = new ScheduleLocation(scheduleLocation);
      if (
        fieldName === 'fullTextIS' ||
        fieldName === 'fullTextEN' ||
        fieldName === 'antigenEmailIS' ||
        fieldName === 'antigenEmailEN' ||
        fieldName === 'antigenHeilsuveraMessage' ||
        fieldName === 'antigenHeilsuveraMessageEN' ||
        fieldName === 'antigenSmsEN' ||
        fieldName === 'antigenSmsIS' ||
        fieldName === 'heilsuveraText' ||
        fieldName === 'smsText'
      ) {
        newScheduleLocation[fieldName] = newValue;
      }
      updateScheduleLocation(newScheduleLocation);
    }
  };

  const handleCloseDialog = (confirmed: boolean, newValue: string) => {
    if (confirmed && scheduleLocation) {
      updateSheduleLocation(newValue, updateField.fieldName);
    }
    setUpdateField({
      dialogOpen: false,
      value: '',
      title: '',
      label: '',
      fieldName: '',
      requiredParams: '',
    });
  };
  const getFieldValue = (fieldName: string) => {
    if (scheduleLocation) {
      if (
        fieldName === 'fullTextIS' ||
        fieldName === 'fullTextEN' ||
        fieldName === 'antigenEmailIS' ||
        fieldName === 'antigenEmailEN' ||
        fieldName === 'antigenHeilsuveraMessage' ||
        fieldName === 'antigenHeilsuveraMessageEN' ||
        fieldName === 'antigenSmsEN' ||
        fieldName === 'antigenSmsIS' ||
        fieldName === 'heilsuveraText' ||
        fieldName === 'smsText'
      ) {
        if (fieldName.includes('Email')) {
          return decodeURI(scheduleLocation[fieldName]);
        } else {
          return scheduleLocation[fieldName];
        }
      }
    }
    return null;
  };
  if (!location) {
    return <div></div>;
  } else if (scheduleLocation) {
    return (
      <Section>
        <Paper elevation={2}>
          <Grid item xs={12}>
            <TableContainer className={classes.container}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {locationSettings.locationSettings.tableColumns.map(
                      (column, index) => (
                        <TableCell
                          key={index}
                          className={index < 1 ? classes.firstCell : ''}
                        >
                          {column[lang]}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {locationSettings.locationSettings.tableRows
                    .filter((x) =>
                      scheduleLocation.bookableAntigen
                        ? x.bookableAntigen
                        : !x.bookableAntigen
                    )
                    .map((row, index) => (
                      <TableRow key={index} className={classes.edit}>
                        <TableCell>{row.description[lang]}</TableCell>
                        <TableCell>{row.type}</TableCell>
                        <TableCell>{row.language[lang]}</TableCell>
                        <TableCell
                          className={classes.edit}
                          onClick={() =>
                            handleClickEditField(
                              getFieldValue(row.fieldName),
                              row.description[lang],
                              row.description[lang],
                              row.fieldName
                            )
                          }
                        >
                          {getFieldValue(row.fieldName)}
                          <EditButton
                            onClick={() => {}}
                            className={classes.editButton}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {updateField.dialogOpen && (
            <UpdateFieldDialog
              value={updateField.value}
              open={updateField.dialogOpen}
              title={'Breyta texta'}
              label={updateField.label}
              fieldName={updateField.fieldName}
              maxLength={
                updateField.fieldName.toLowerCase().includes('sms')
                  ? 1024
                  : 5000
              }
              onClose={handleCloseDialog}
              locationDescripton={scheduleLocation.displayText}
              userName={user.name}
              requiredParams={updateField.requiredParams}
            />
          )}
        </Paper>
      </Section>
    );
  } else {
    return <CircularProgress />;
  }
};

export default LocationTemplateTexts;
