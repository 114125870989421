export interface IRiskCountry {
  code: string;
  name: string;
  status: number;
}

export enum RiskCountryStatus {
  WHITE = 1,
  YELLOW,
  RED,
}
