import React, { useContext, useState } from 'react';
import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Button from './Button';
import { UserContext } from '../context/UserContext';
import { LangContext } from '../context/LangContext';
import { AccessItem, Transaction } from '../models/Models';
import translations from '../assets/json/translations.json';
import useNotifier from '../hooks/useNotifier';
import { accessControl } from '../constants/enums';
import { PosContext } from '../context/PosContext';
import ConfirmDialog from './ConfirmDialog';
import posService from '../services/posService';
import { CefSharpShell } from '../helpers/CefSharpShell';
import { hasRapidTestAccess, hasSymptomSamplingAccess } from '../utils';

interface IProps {
  samplingMenu?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: `calc(100vh - ${theme.dimensions.navbarHeight}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      flex: 1,
      padding: theme.spacing(3),
      margin: theme.spacing(3),
      textAlign: 'center',
      maxWidth: theme.dimensions.boxWidth,
    },
    typographyTitle: {
      padding: theme.spacing(2),
    },
  })
);

const Home: React.FC<IProps> = ({ samplingMenu = true }) => {
  const { notifyError, notifySuccess } = useNotifier();
  const classes = useStyles({});
  const history = useHistory();
  const [user] = useContext(UserContext);
  const [lang] = useContext(LangContext);
  const [shouldHavePos] = useContext(PosContext);

  const [callOutPrice, setCallOutPrice] = useState(0);
  const [isBatchSending, setIsBatchSending] = useState(false);
  const [isPaymentSending, setIsPaymentSending] = useState(false);
  const [isBatchDialogOpen, setIsBatchDialogOpen] = useState(false);
  const [isCallOutDialogOpen, setIsCallOutDialogOpen] = useState(false);

  const shellHelper = new CefSharpShell();
  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  const isAccessible = (id: number) => {
    const item = user?.accessList?.find((item: AccessItem) => {
      return item.id === id;
    });
    return item !== undefined;
  };

  return (
    <div className={classes.root}>
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography className={classes.typographyTitle} variant="h4">
              {translations.actions[lang]}
            </Typography>
          </Grid>
          {samplingMenu ? renderSamplingItems() : renderVaccinationItems()}
        </Grid>
      </Paper>
    </div>
  );

  function renderSamplingItems() {
    return (
      <React.Fragment>
        {/* {isAccessible(accessControl.SAMPLING) && (
          <Grid item xs={12} sm={12}>
            <Button fullWidth onClick={() => history.push('/pcr-desk')}>
              {translations.pcrDesk[lang]}
            </Button>
          </Grid>
        )} */}
        {isAccessible(accessControl.SAMPLING) && (
          <Grid item xs={12} sm={12}>
            <Button fullWidth onClick={() => history.push('/sampling')}>
              {translations.sampling[lang]}
            </Button>
          </Grid>
        )}
        {isAccessible(accessControl.QUARANTINE_CONTROL) && (
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              onClick={() => history.push('/border-control-sampling')}
            >
              {translations.borderControlSampling[lang]}
            </Button>
          </Grid>
        )}
        {isAccessible(accessControl.SAMPLING) &&
          hasSymptomSamplingAccess(user) && (
            <Grid item xs={12} sm={12}>
              <Button
                fullWidth
                onClick={() => history.push('/symptom-sampling')}
              >
                {translations.symptomSampling[lang]}
              </Button>
            </Grid>
          )}
        {isAccessible(accessControl.SAMPLING) && hasRapidTestAccess(user) && (
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              onClick={() => history.push('/rapid-test-sampling')}
            >
              {translations.rapidTestSampling[lang]}
            </Button>
          </Grid>
        )}
        {isAccessible(accessControl.SEARCH) && (
          <Grid item xs={12} sm={12}>
            <Button fullWidth onClick={() => history.push('/search')}>
              {translations.searchTitle[lang]}
            </Button>
          </Grid>
        )}
        {/* isAccessible(accessControl.QUARANTINE_CONTROL) && (
          <Grid item xs={12} sm={12}>
            <Button fullWidth onClick={() => history.push('/border-control')}>
              {translations.quarantineControl[lang]}
            </Button>
          </Grid>
        ) */}
        {isAccessible(accessControl.SEARCH) && (
          <Grid item xs={12} sm={12}>
            <Button fullWidth onClick={() => history.push('/new-passenger')}>
              {translations.registerPerson[lang]}
            </Button>
          </Grid>
        )}
        {isAccessible(accessControl.SAMPLING) &&
          hasSymptomSamplingAccess(user) &&
          shellHelper.isShellMode && (
            <Grid item xs={12} sm={12}>
              <Button fullWidth onClick={() => history.push('/print-labels')}>
                {translations.printLabels[lang]}
              </Button>
            </Grid>
          )}
        {isAccessible(accessControl.QUARANTINE_HOTEL) && (
          <Grid item xs={12} sm={12}>
            <Button fullWidth onClick={() => history.push('/quarantine-house')}>
              {translations.quarantineHotel[lang]}
            </Button>
          </Grid>
        )}
        {isAccessible(accessControl.HIGH_RISK_COUNTRIES) && (
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              onClick={() => history.push('/high-risk-countries')}
            >
              {translations.highRiskCountries[lang]}
            </Button>
          </Grid>
        )}
        {(isAccessible(accessControl.SUPER_ADMIN) ||
          isAccessible(accessControl.TIME_COP)) && (
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              onClick={() =>
                history.push('/location-settings-menu/borderControl')
              }
            >
              {translations.scheduleLocationSettings[lang]}
            </Button>
          </Grid>
        )}
        {isAccessible(accessControl.CERTIFICATE_REVIEW) && (
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              onClick={() => history.push('/certificate-review')}
            >
              {translations.certificateReview[lang]}
            </Button>
          </Grid>
        )}
        {shouldHavePos && (
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              onClick={() => {
                posService.getCalloutPrice().then((response) => {
                  if (response.statusCode === 401) {
                    notifyError(translations.status401[lang]);
                    history.push('/login');
                  } else {
                    setCallOutPrice(response.data);
                    setIsCallOutDialogOpen(true);
                  }
                });
              }}
            >
              {translations.callOut[lang]}
            </Button>
          </Grid>
        )}
        {shouldHavePos && (
          <Grid item xs={12} sm={12}>
            <Button fullWidth onClick={() => setIsBatchDialogOpen(true)}>
              {translations.sendPosBatch[lang]}
            </Button>
          </Grid>
        )}
        <ConfirmDialog
          open={isCallOutDialogOpen}
          title={translations.callOut[lang]}
          text={`${translations.callOutDialog[lang]}, ${callOutPrice} kr.`}
          children={
            isPaymentSending && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography>{translations.paymentDialogText[lang]}</Typography>
                <CircularProgress color="secondary" />
              </div>
            )
          }
          onConfirm={() => {
            setIsPaymentSending(true);
            shellHelper.chargeCard(
              callOutPrice,
              (reply: any) => {
                const transaction = new Transaction(reply);
                transaction.amountPaid = callOutPrice;
                posService.markCalloutCharged(transaction);
                setIsCallOutDialogOpen(false);
                notifySuccess(reply.statusText);
                setIsPaymentSending(true);
              },
              (statusText) => {
                notifyError(statusText);
                setIsCallOutDialogOpen(false);
                setIsPaymentSending(false);
              }
            );
          }}
          onCancel={() => {
            setIsPaymentSending(false);
            setIsCallOutDialogOpen(false);
          }}
          confirmText={translations.payForSampling[lang]}
        />
        <ConfirmDialog
          open={isBatchDialogOpen}
          title={translations.sendPosBatch[lang]}
          text={`${translations.sendPosBatchDialog[lang]}`}
          children={
            isBatchSending && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography>{translations.sendingBatch[lang]}</Typography>
                <CircularProgress color="secondary" />
              </div>
            )
          }
          onConfirm={() => {
            setIsBatchSending(true);
            shellHelper.sendPOSBatch(
              (reply) => {
                notifySuccess(reply.statusText);
                setIsBatchDialogOpen(false);
                setIsBatchSending(false);
              },
              (reply) => {
                notifyError(reply.statusText);
                setIsBatchDialogOpen(false);
                setIsBatchSending(false);
              }
            );
          }}
          onCancel={() => {
            setIsBatchSending(false);
            setIsBatchDialogOpen(false);
          }}
          confirmText={translations.sendPosBatch[lang]}
        />
      </React.Fragment>
    );
  }

  function renderVaccinationItems() {
    return (
      <React.Fragment>
        {isAccessible(accessControl.VACCINATION) && (
          <Grid item xs={12} sm={12}>
            <Button fullWidth onClick={() => history.push('/vaccination')}>
              {translations.vaccination[lang]}
            </Button>
          </Grid>
        )}
        {/* <Grid item xs={12} sm={12}>
          <Button
            fullWidth
            onClick={() => history.push('/new-vaccination-person')}
          >
            {translations.registerToVaccination[lang]}
          </Button>
        </Grid> */}
        {isAccessible(accessControl.VACCINATION_GROUP) && (
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              onClick={() => history.push('/group-vaccination')}
            >
              {translations.groupVaccination[lang]}
            </Button>
          </Grid>
        )}
        {isAccessible(accessControl.VACCINATION_GROUP_MANAGEMENT) && (
          <Grid item xs={12} sm={12}>
            <Button fullWidth onClick={() => history.push('/groups')}>
              {translations.groupManagement[lang]}
            </Button>
          </Grid>
        )}
        {(isAccessible(accessControl.VACCINATION) ||
          isAccessible(accessControl.VACCINATION_GROUP_MANAGEMENT)) && (
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              onClick={() => history.push('/vaccination-overview')}
            >
              {translations.vaccinationOverview[lang]}
            </Button>
          </Grid>
        )}
        {isAccessible(accessControl.VACCINATION_SEARCH) && (
          <Grid item xs={12} sm={12}>
            <Button fullWidth onClick={() => history.push('/search')}>
              {translations.searchTitle[lang]}
            </Button>
          </Grid>
        )}
        {isAccessible(accessControl.VACCINATION_GROUP_MANAGEMENT) && (
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              onClick={() => history.push('/summon-across-groups')}
            >
              {translations.summonAcrossGroups[lang]}
            </Button>
          </Grid>
        )}
        {isAccessible(accessControl.VACCINATION_GROUP_MANAGEMENT) && (
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              onClick={() => history.push('/vaccination-waiting-list')}
            >
              {translations.registerForVaccine[lang]}
            </Button>
          </Grid>
        )}
        {isAccessible(accessControl.SUPER_ADMIN) && (
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              onClick={() =>
                history.push('/location-settings-menu/vaccination')
              }
            >
              {translations.scheduleLocationSettings[lang]}
            </Button>
          </Grid>
        )}
      </React.Fragment>
    );
  }
};

export default Home;
