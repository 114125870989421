import React, { useContext } from 'react';
import { WorldMap } from 'react-svg-worldmap';
import { useTheme, Grid } from '@material-ui/core';

import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { IRiskCountry, RiskCountryStatus } from '../interfaces/riskCountry';
import { getRiskCountryColorFromStatus } from '../utils';

interface IProps {
  countries: IRiskCountry[];
  onClick: (country: IRiskCountry) => void;
}

const RiskCountriesMap: React.FC<IProps> = ({ countries, onClick }) => {
  const [lang] = useContext(LangContext);
  const theme = useTheme();

  return (
    <Grid
      container
      direction="row"
      justify="center"
      style={{ backgroundColor: theme.palette.grey[200] }}
    >
      <Grid item>
        <WorldMap
          size="xxl"
          data={countries.map((c) => ({
            country: c.code,
            value: c.status,
          }))}
          backgroundColor={theme.palette.grey[200]}
          styleFunction={styleCountry}
          onClickFunction={(_e, name, code, value) => {
            onClick({
              name,
              code,
              status: parseInt(value),
            });
            return {};
          }}
          tooltipTextFunction={(name, _iso, value) =>
            `${name}: ${
              parseInt(value) === RiskCountryStatus.RED
                ? translations.red[lang].toUpperCase()
                : parseInt(value) === RiskCountryStatus.YELLOW
                ? translations.yellow[lang].toUpperCase()
                : translations.noColorCode[lang].toUpperCase()
            }`
          }
        />
      </Grid>
    </Grid>
  );

  function styleCountry(context: any) {
    return {
      fill: getRiskCountryColorFromStatus(context.countryValue),
      fillOpacity: 1,
      stroke: theme.palette.text.primary,
      strokeWidth: 1,
      strokeOpacity: 0.2,
      cursor: 'pointer',
    };
  }
};

export default RiskCountriesMap;
