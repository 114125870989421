import React, { useRef, useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  ListItem,
  ListItemText,
  ListSubheader,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { IRiskCountry } from '../interfaces/riskCountry';
import { getRiskCountryColorFromStatus } from '../utils';
import {
  RISK_COUNTRIES_LIST_ITEM_HEIGHT,
  RISK_COUNTRIES_LIST_HEIGHT,
} from '../constants';

interface IProps {
  title: string;
  status: number;
  allCountries: IRiskCountry[];
  onClick: (country: IRiskCountry) => void;
  onRemove: (country: IRiskCountry) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    boxSizing: 'border-box',
    overflow: 'auto',
  },
}));

const BORDER_TOP_WIDTH = 6;

const RiskCountriesStatusList: React.FC<IProps> = ({
  title,
  status,
  allCountries,
  onClick,
  onRemove,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const labelRef = useRef<HTMLDivElement>(null);
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [labelHeight, setLabelHeight] = useState(0);
  const filteredCountries = allCountries.filter((c) => c.status === status);
  const listHeightSm = RISK_COUNTRIES_LIST_ITEM_HEIGHT * 4;
  const listHeightLg =
    RISK_COUNTRIES_LIST_HEIGHT - labelHeight - BORDER_TOP_WIDTH;

  useEffect(() => {
    if (labelRef.current) {
      setLabelHeight(labelRef.current.offsetHeight);
    }
  }, [labelRef]);

  return (
    <Paper
      elevation={2}
      className={classes.container}
      style={{
        borderTop: `${BORDER_TOP_WIDTH}px solid ${getRiskCountryColorFromStatus(
          status
        )}`,
      }}
    >
      <div ref={labelRef}>
        <ListSubheader style={{ backgroundColor: 'white' }}>
          {title} ({filteredCountries.length})
        </ListSubheader>
      </div>
      <FixedSizeList
        width="100%"
        height={smDown ? listHeightSm : listHeightLg}
        itemSize={RISK_COUNTRIES_LIST_ITEM_HEIGHT}
        itemCount={filteredCountries.length}
      >
        {renderListItem}
      </FixedSizeList>
    </Paper>
  );

  function renderListItem(item: ListChildComponentProps) {
    const country = filteredCountries[item.index];

    return (
      <ListItem
        divider
        button
        disableRipple
        key={country.code}
        style={item.style}
        onClick={() => onClick(country)}
      >
        <ListItemText primary={country.name} />
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onRemove(country);
          }}
        >
          <CloseIcon />
        </IconButton>
      </ListItem>
    );
  }
};

export default RiskCountriesStatusList;
