import moment, { Moment } from 'moment';
import { VaccineType } from '../constants/enums';

export class VaccinationPerson {
  name: string;
  ssn: string;
  serialNumber: string;
  created: string;
  verified: boolean;
  phoneNumber: string;
  vaccinationPersonId?: number;
  vaccinationComment: VaccinationComment;
  groups?: VaccinationPersonGroup[];
  summoningDate?: string;
  summoningLocation?: VaccinationLocation;
  firstVaccination: Vaccination;
  secondVaccination: Vaccination;
  vaccinations: Vaccination[];
  influenzaVaccinatnions: Vaccination[];
  nextSummoning?: NextSummoning;
  vaccinationAge: boolean;
  numberOfVaccinations: number;
  onlymRNA: boolean;
  allowVaccination?: number;

  constructor(vaccinationPerson: any) {
    this.name = vaccinationPerson?.personName
      ? vaccinationPerson.personName
      : '';
    this.ssn = vaccinationPerson?.personSsn ? vaccinationPerson?.personSsn : '';
    this.serialNumber = vaccinationPerson?.barcode
      ? vaccinationPerson.barcode
      : '';
    this.created = vaccinationPerson?.created ? vaccinationPerson.created : '';
    this.verified =
      vaccinationPerson && vaccinationPerson.isValid === false ? false : true;
    this.phoneNumber = vaccinationPerson?.phonenumber
      ? vaccinationPerson.phonenumber
      : '';
    this.vaccinationPersonId = vaccinationPerson?.vaccinationPersonId
      ? vaccinationPerson.vaccinationPersonId
      : undefined;
    this.vaccinationComment = new VaccinationComment(
      vaccinationPerson.vaccinationComment || {}
    );
    this.groups = vaccinationPerson?.groups
      ? vaccinationPerson.groups.map((g: any) => new VaccinationPersonGroup(g))
      : undefined;
    this.summoningDate =
      vaccinationPerson?.nextSummoningForPerson?.nextSummoningDate;

    this.summoningLocation = vaccinationPerson?.firstSummoningLocation
      ? new VaccinationLocation(vaccinationPerson.firstSummoningLocation)
      : undefined;
    this.firstVaccination =
      vaccinationPerson.vaccinations?.filter((x: any) => x.type === VaccineType.COVID_19).length > 0
        ? new Vaccination(vaccinationPerson.vaccinations.filter((x: any) => x.type === VaccineType.COVID_19)[0])
        : new Vaccination(null);
    this.secondVaccination =
      vaccinationPerson.vaccinations?.filter((x: any) => x.type === VaccineType.COVID_19).length > 1
        ? new Vaccination(vaccinationPerson.vaccinations.filter((x: any) => x.type === VaccineType.COVID_19)[1])
        : new Vaccination(null);
    this.vaccinations = vaccinationPerson.vaccinations
      ? vaccinationPerson.vaccinations.filter((x: any) => x.type === VaccineType.COVID_19).map(
          (vaccination: any) =>
            new Vaccination({
              ...vaccination,
              isFirstVaccination:
                vaccination.vaccinationId === this.firstVaccination.id,
            })
        )
      : 
        [];
    this.influenzaVaccinatnions = vaccinationPerson.vaccinations ? vaccinationPerson.vaccinations.filter((x: any) => x.type === VaccineType.INFLUENZA).map((item: any) => new Vaccination(item)) : [];
    this.nextSummoning = vaccinationPerson?.nextSummoningForPerson
      ? new NextSummoning(vaccinationPerson.nextSummoningForPerson)
      : undefined;
    this.vaccinationAge = vaccinationPerson?.personSsn
      ? vaccinationPerson?.personSsn.substring(9) === '9' ||
        moment()
          .startOf('year')
          .subtract(4, 'years')
          .isAfter(
            moment(vaccinationPerson?.personSsn.substring(0, 6), 'DDMMYY')
          )
      : false;
    this.numberOfVaccinations = vaccinationPerson?.numberOfVaccinations
      ? vaccinationPerson.numberOfVaccinations
      : 0;
    this.onlymRNA = vaccinationPerson?.onlymRNA
      ? vaccinationPerson.onlymRNA
      : false;
    this.allowVaccination =
      vaccinationPerson?.allowVaccination ||
      vaccinationPerson?.allowVaccination === 0
        ? vaccinationPerson.allowVaccination
        : undefined;
  }
}

export class VaccinationPersonGroup {
  id: string;
  name: string;
  description: string;
  priority: number;
  regionId: string;
  type: number;

  constructor(group: any) {
    this.id = group?.id ? group.id.toString() : '';
    this.name = group?.name ? group.name : '';
    this.description = group?.description ? group.description : '';
    this.priority = group?.priority ? group.priority : 10;
    this.regionId = group?.regionId ? group.regionId.toString() : '';
    this.type = group?.type ? group.type : 0;
  }
}

export class VaccinationGroup extends VaccinationPersonGroup {
  totalCount: number;
  vaccinatedFirstCount: number;
  vaccinatedSecondCount: number;
  rejectedFirstCount: number;
  rejectedSecondCount: number;
  vaccinationRegion: VaccinationRegion;
  summonGroupDate?: string;
  smsText?: string;
  slotLenght?: string;
  slotCapacity?: string;
  summoningCount?: number;

  constructor(group: any) {
    super(group);
    this.totalCount = group?.totalCount ? group.totalCount : 0;
    this.vaccinatedFirstCount = group?.vaccinatedFirstCount
      ? group.vaccinatedFirstCount
      : 0;
    this.vaccinatedSecondCount = group?.vaccinatedSecondCount
      ? group.vaccinatedSecondCount
      : 0;
    this.rejectedFirstCount = group?.rejectedFirstCount
      ? group.rejectedFirstCount
      : 0;
    this.rejectedSecondCount = group?.rejectedSecondCount
      ? group.rejectedSecondCount
      : 0;
    this.vaccinationRegion = new VaccinationRegion(group.vaccinationRegion);
    this.summonGroupDate =
      moment(group?.nextPersonInGroupSummonDate).year() === 1
        ? undefined
        : group?.nextPersonInGroupSummonDate;
    this.smsText = group?.summoningSmsText ? group.summoningSmsText : undefined;
    this.slotLenght = group?.summoningSlotLenght
      ? group.summoningSlotLenght
      : undefined;
    this.slotCapacity = group?.summoningSlotCapacity
      ? group.summoningSlotCapacity
      : undefined;
    this.summoningCount = group?.summoningCount
      ? group.summoningCount
      : undefined;
  }
}

export class VaccinationRegion {
  id: string;
  name: string;

  constructor(region: any) {
    this.id = region?.id ? region.id.toString() : '';
    this.name = region?.name ? region.name : '';
  }
}

export class VaccinationGroupPersons {
  personSsn: string;
  personName: string;
  phonenumber: string;
  isValid: boolean;

  constructor(persons: VaccinationPerson) {
    this.personSsn = persons.ssn;
    this.personName = persons.name;
    this.phonenumber = persons.phoneNumber;
    this.isValid = persons.verified;
  }
}

export class Vaccine {
  id: number;
  atc: string;
  name: string;
  lotNumber: string;
  description?: string;
  daysBetweenVaccinations: number;
  boosterDaysBetweenVaccinations: number;
  boosterWarningDaysBetweenVaccinations: number;
  vaccineIsActive: boolean;
  vaccineType: VaccineType;
  numberOfPreviousVaccinationsRequired: number;

  constructor(vaccine: any) {
    this.id = vaccine?.vaccinationCodeId ? vaccine.vaccinationCodeId : 0;
    this.atc = vaccine?.atc ? vaccine.atc : '';
    this.name = vaccine?.vaccineName ? vaccine.vaccineName : '';
    this.lotNumber = vaccine?.lotNumber ? vaccine.lotNumber : '';
    this.description = vaccine?.description ? vaccine.description : undefined;
    this.daysBetweenVaccinations = vaccine?.daysBetweenVaccinations
      ? vaccine.daysBetweenVaccinations
      : 0;
    this.vaccineIsActive = !!vaccine?.vaccineIsActive;
    this.boosterDaysBetweenVaccinations =
      vaccine?.boosterDaysBetweenVaccinations
        ? vaccine.boosterDaysBetweenVaccinations
        : 0;
    this.boosterWarningDaysBetweenVaccinations =
      vaccine?.boosterWarningDaysBetweenVaccinations
        ? vaccine.boosterWarningDaysBetweenVaccinations
        : 0;
    this.vaccineType = vaccine?.type ? vaccine.type : VaccineType.COVID_19
    this.numberOfPreviousVaccinationsRequired = vaccine?.numberOfPreviousVaccinationsRequired ? vaccine.numberOfPreviousVaccinationsRequired : 0;
  }
}

export class VaccinationLocation {
  id: number;
  name: string;
  regionId: number;

  constructor(location: any) {
    this.id = location?.id ? location.id : 0;
    this.name = location?.name ? location.name : '';
    this.regionId = location?.regionId ? location.regionId : 0;
  }
}

export class VaccinationPersonSearchQuery {
  barCode?: string;
  name?: string;
  ssn?: string;
  dateOfBirth: string;

  constructor(form: any) {
    this.barCode = form.serialNumber || undefined;
    this.name = form.name || undefined;
    this.ssn = form.ssn || undefined;
    this.dateOfBirth = form.dateOfBirth ? form.dateOfBirth.toISOString() : null;
  }
}



export class Vaccination {
  id: number;
  codeId?: number;
  atc?: string;
  vaccineName?: string;
  lotNumber?: string;
  description?: string;
  daysBetweenVaccinations?: number;
  boosterDaysBetweenVaccinations?: number;
  boosterWarningDaysBetweenVaccinations?: number;
  vaccinationDate?: string;
  vaccinationLocationName?: string;
  isFirstVaccination?: boolean;
  type: VaccineType;
  readonly: boolean;

  constructor(vaccination: any) {
    this.id = vaccination?.vaccinationId ? vaccination.vaccinationId : 0;
    this.codeId = vaccination?.vaccinationCodeId
      ? vaccination.vaccinationCodeId
      : undefined;
    this.atc = vaccination?.atc ? vaccination.atc : undefined;
    this.vaccineName = vaccination?.vaccineName
      ? vaccination.vaccineName
      : undefined;
    this.lotNumber = vaccination?.lotNumber ? vaccination.lotNumber : undefined;
    this.description = vaccination?.description
      ? vaccination.description
      : undefined;
    this.daysBetweenVaccinations = vaccination?.daysBetweenVaccinations
      ? vaccination.daysBetweenVaccinations
      : undefined;
    this.boosterDaysBetweenVaccinations =
      vaccination?.boosterDaysBetweenVaccinations
        ? vaccination.boosterDaysBetweenVaccinations
        : undefined;
    this.boosterWarningDaysBetweenVaccinations =
      vaccination?.boosterWarningDaysBetweenVaccinations
        ? vaccination.boosterWarningDaysBetweenVaccinations
        : undefined;
    this.vaccinationDate = vaccination?.vaccinationDate
      ? vaccination.vaccinationDate
      : undefined;
    this.vaccinationLocationName = vaccination?.vaccinationLocationName
      ? vaccination.vaccinationLocationName
      : undefined;
    this.isFirstVaccination = vaccination?.isFirstVaccination;
    this.type = vaccination?.type ? vaccination.type : VaccineType.COVID_19;
    this.readonly = vaccination?.isReadOnly ? true : false; 
  }
}

export class VaccinationComment {
  id?: number;
  comment?: string;
  statusId?: number;
  statusDescription?: string;
  groupId?: number;
  groupName?: string;
  vaccinationPersonId?: number;
  locationId?: number;

  constructor(comment: any) {
    this.id = comment.id !== null ? comment.id : undefined;
    this.comment = comment.comment !== null ? comment.comment : undefined;
    this.statusId = comment.statusId !== null ? comment.statusId : undefined;
    this.statusDescription =
      comment.statusDescription !== null
        ? comment.statusDescription
        : undefined;
    this.groupId = comment.groupId !== null ? comment.groupId : undefined;
    this.groupName = comment.groupName !== null ? comment.groupName : undefined;
    this.vaccinationPersonId =
      comment.vaccinationPersonId !== null
        ? comment.vaccinationPersonId
        : undefined;
        this.locationId =
        comment.locationId !== null
          ? comment.locationId
          : undefined;
  }
}

export class VaccinationRegistrationRequest {
  id: number;
  ssn: string;
  email: string;
  personName: string;
  phoneNumber: string;
  phoneCode: string;
  created: string;
  previousVaccinationsCount: number;
  vaccinationCountMismatch: boolean;
  isPregnant: boolean;
  dateOfTwelveWeeks: string;
  hasAnAccuteAllergy: boolean;
  isUsingImmonusuppresiveMedication: boolean;
  vaccinationRegion: VaccinationRegistrationRegion;
  vaccinationLocation: VaccinationRegistrationLocation;
  previousClaimedVaccinations: WaitingListVaccination[];
  previousVerifiedVaccinations: WaitingListVaccination[];
  comments: string;
  nationality: string;
  registrationSource: string;
  registrationRequestStatusId?: number;
  dateOfCovidDiagnosis: Moment | null;

  constructor(item: any) {
    this.id = item?.id ? item.id : 0;
    this.ssn = item?.personSsn ? item.personSsn : '';
    this.email = item?.email ? item.email : '';
    this.personName = item?.personName ? item.personName : '';
    this.phoneNumber = item?.phoneNumber ? item.phoneNumber : '';
    this.phoneCode = item?.phoneCode ? item.phoneCode : '';
    this.created = item?.created ? item.created : '';
    this.previousVaccinationsCount = item?.vaccinationCount
      ? item.vaccinationCount
      : 0;
    this.vaccinationCountMismatch = item?.vaccinationCountMismatch
      ? true
      : false;
    this.isPregnant = item?.isPregnant ? true : false;
    this.dateOfTwelveWeeks = item?.dateOfTwelveWeeks
      ? item.dateOfTwelveWeeks
      : '';
    this.hasAnAccuteAllergy = item?.hasAnAccuteAllergy ? true : false;
    this.isUsingImmonusuppresiveMedication =
      item?.isUsingImmonusuppresiveMedication ? true : false;
    this.vaccinationRegion = new VaccinationRegistrationRegion(
      item.vaccinationLocation
    );
    this.vaccinationLocation = new VaccinationRegistrationLocation(
      item.vaccinationLocation
    );

    this.comments = item?.comments ? item.comments : '';
    this.nationality = item?.nationality ? item.nationality : '';
    this.registrationSource = item?.registrationSource
      ? item.registrationSource
      : '';
    this.registrationRequestStatusId = item?.registrationRequestStatus
      ? item.registrationRequestStatus.id
      : undefined;

    this.previousClaimedVaccinations = [];
    if (item.previousClaimedVaccinations) {
      item.previousClaimedVaccinations.forEach(
        (v: { vaccineTypeName: any; dateOfVaccination: any }) => {
          this.previousClaimedVaccinations.push({
            vaccineName: v.vaccineTypeName,
            vaccinationDate: v.dateOfVaccination,
          });
        }
      );
    }

    this.previousVerifiedVaccinations = [];
    if (item.previousVerifiedVaccinations) {
      item.previousVerifiedVaccinations.forEach(
        (v: { vaccineName: any; vaccinationDate: any }) => {
          this.previousVerifiedVaccinations.push({
            vaccineName: v.vaccineName,
            vaccinationDate: v.vaccinationDate,
          });
        }
      );
    }
    this.dateOfCovidDiagnosis = item.dateOfCovidDiagnosis;
  }
}

export class WaitingListVaccination {
  vaccineName: string;
  vaccinationDate?: string;

  constructor(vaccination: any) {
    this.vaccineName = vaccination?.vaccineTypeName
      ? vaccination.vaccineTypeName
      : '';
    this.vaccinationDate = vaccination?.vaccinationDate
      ? vaccination.vaccinationDate
      : undefined;
  }
}

export class VaccinationRegistrationRegion {
  id: string;
  name: string;

  constructor(region: any) {
    this.id = region?.vaccinationRegionId
      ? region.vaccinationRegionId.toString()
      : '';
    this.name = region?.vaccinationRegionName
      ? region.vaccinationRegionName
      : '';
  }
}

export class VaccinationRegistrationLocation {
  id: string;
  name: string;

  constructor(region: any) {
    this.id = region?.id ? region.id.toString() : '';
    this.name = region?.name ? region.name : '';
  }
}

export class NextSummoning {
  date: string;
  location: VaccinationLocation;

  constructor(nextSummoning: any) {
    this.date = nextSummoning?.nextSummoningDate
      ? nextSummoning.nextSummoningDate
      : '';
    this.location = new VaccinationLocation(
      nextSummoning.nextSummoningLocation
    );
  }
}

export class LocationSummary {
  id: string;
  name: string;
  summoned: number;
  vaccinated: number;
  totalVaccinated: number;
  totalCovidVaccinated: number;
  totalInfluenzaVaccinated: number;
  totalVaccinatedPersons: number; 

  constructor(summary: any) {
    this.id = summary.locationId ? summary.locationId : 0;
    this.name = summary?.locationName ? summary.locationName : '';
    this.summoned = summary?.summoned ? summary.summoned : 0;
    this.vaccinated = summary?.vaccinated ? summary.vaccinated : 0;
    this.totalVaccinated = summary?.totalVaccinated
      ? summary.totalVaccinated
      : 0;
    this.totalCovidVaccinated = summary?.totalCovidVaccinated ? summary.totalCovidVaccinated : 0;
    this.totalInfluenzaVaccinated = summary?.totalInfluenzaVaccinated ? summary.totalInfluenzaVaccinated : 0;
    this.totalVaccinatedPersons = summary?.totalVaccinatedPersons ? summary.totalVaccinatedPersons : 0;
  }
}

export class SummaryTimeSlot {
  time: string;
  summoned: number;
  vaccinated: number;

  constructor(slot: any) {
    this.time = slot.slotTime ? slot.slotTime : '';
    this.summoned = slot?.summoned ? slot.summoned : 0;
    this.vaccinated = slot?.vaccinated ? slot.vaccinated : 0;
  }
}

export class ChildInfo {
  allowVaccination: number;
  decisionMakerId?: number;
  decisionMakerSsn: string;
  decisionMakerName: string;
  decisionTaken?: string;
  custodians: ChildCustodians[];

  constructor(child: any) {
    this.allowVaccination = child?.allowVaccination
      ? child.allowVaccination
      : 0;
    this.decisionMakerId = child?.decisionMakerId
      ? child.decisionMakerId
      : undefined;
    this.decisionMakerSsn = child?.decisionMakerSsn
      ? child.decisionMakerSsn
      : '';
    this.decisionMakerName = child?.decisionMakerName
      ? child.decisionMakerName
      : '';
    this.decisionTaken = child?.decisionTaken ? child.decisionTaken : undefined;
    this.custodians = child?.custodians.map(
      (item: any) => new ChildCustodians(item)
    );
  }
}

export class ChildCustodians {
  id: number;
  custodianType: number;
  name: string;
  ssn: string;
  dateOfBirth?: string;
  phoneNumber: string;
  allowVaccination: number;
  decisionTaken?: string;
  comment?: string;

  constructor(custodian: any) {
    this.id = custodian?.id ? custodian.id : 0;
    this.custodianType = custodian?.custodianType ? custodian.custodianType : 0;
    this.name = custodian?.name ? custodian.name : '';
    this.ssn = custodian?.ssn ? custodian.ssn : '';
    this.comment = custodian?.comment ? custodian.comment : '';
    this.dateOfBirth = custodian?.dateOfBirth
      ? custodian.dateOfBirth
      : undefined;
    this.phoneNumber = custodian?.phoneNumber ? custodian.phoneNumber : '';
    this.allowVaccination = custodian?.allowVaccination
      ? custodian.allowVaccination
      : 0;
    this.decisionTaken = custodian?.vaccinationDecisionTaken
      ? custodian.vaccinationDecisionTaken
      : undefined;
  }
}
