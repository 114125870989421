import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
  makeStyles,
  useTheme,
  useMediaQuery,
  Paper,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment, { Moment } from 'moment';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Dropdown from '../components/Dropdown';
import Button from '../components/Button';
import DatePicker from '../components/DatePicker';
import LoadingIndicator from '../components/LoadingIndicator';
import NoResults from '../components/NoResults';
import useNotifier from '../hooks/useNotifier';
import { LangContext } from '../context/LangContext';
import { UserContext } from '../context/UserContext';
import { LocationSummary, SummaryTimeSlot } from '../models/VaccinationModels';
import translations from '../assets/json/translations.json';
import * as vaccinationService from '../services/vaccinationService';
import { TABLE_ROWS_OPTIONS, TABLE_ROWS_DEFAULT } from '../constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  table: {
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  tableContent: {
    minHeight: 80,
    maxHeight: 500,
  },
}));

const tableColumns: {
  [key: string]: {
    translation: { is: string; en: string };
    sort?: (a: LocationSummary, b: LocationSummary) => number;
  };
} = {
  location: {
    translation: translations.location,
    sort: (a: LocationSummary, b: LocationSummary) =>
      a.name.localeCompare(b.name),
  },
  vaccinated: {
    translation: translations.vaccinated,
    sort: (a: LocationSummary, b: LocationSummary) =>
      a.vaccinated - b.vaccinated,
  },
  summoned: {
    translation: translations.summoned,
    sort: (a: LocationSummary, b: LocationSummary) => a.summoned - b.summoned,
  },
  totalVaccinatedPersons: {
    translation: translations.vaccinatedTotal,
    sort: (a: LocationSummary, b: LocationSummary) =>
      a.totalVaccinatedPersons - b.totalVaccinatedPersons,
  },
  covidAndInfluenzaVaccinations: {
    translation: translations.covidAndInfluenzaVaccinations,
  },
};

const VaccinationOverviewPage: React.FC = () => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const history = useHistory();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const { notifyError } = useNotifier();
  const getOverviewCallback = useCallback(getOverview, []);
  const getDetailsCallback = useCallback(getLocationDetails, []);

  const [currentLocation, setCurrentLocation] =
    useState<LocationSummary | null>(null);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [loadingOverview, setLoadingOverview] = useState(false);
  const [locations, setLocations] = useState<LocationSummary[] | null>(null);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortedLocations, setSortedLocations] = useState<LocationSummary[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [timeSlots, setTimeSlots] = useState<SummaryTimeSlot[]>([]);
  const hasOnlyOneRegion = user?.vaccinationRegionModelList?.length === 1;

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  useEffect(() => {
    const oneRegion = user?.vaccinationRegionModelList?.length === 1;
    const initialRegion = oneRegion
      ? user?.vaccinationRegionModelList[0].id.toString()
      : '';
    const initialDate = moment();

    setSelectedRegion(initialRegion);
    setSelectedDate(initialDate);
    initialRegion && getOverviewCallback(initialRegion, initialDate);
  }, [user, getOverviewCallback]);

  useEffect(() => {
    if (currentLocation && selectedDate) {
      getDetailsCallback(currentLocation.id, selectedDate);
    }
  }, [currentLocation, selectedDate, getDetailsCallback]);

  useEffect(() => {
    if (locations && sortKey && tableColumns[sortKey]) {
      const sorted = tableColumns[sortKey].sort
        ? [...locations].sort(tableColumns[sortKey].sort)
        : [...locations];
      setSortedLocations(sortDirection === 'asc' ? sorted : sorted.reverse());
    } else {
      setSortedLocations(locations || []);
    }
  }, [locations, sortKey, sortDirection]);

  return (
    <Layout useDefaultSpacing title={translations.vaccinationOverview[lang]}>
      <Section>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={5} lg={4}>
            {renderFilters()}
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={8}>
            {renderResults()}
          </Grid>
        </Grid>
      </Section>
      {renderDetailsDialog()}
    </Layout>
  );

  function renderFilters() {
    return (
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Dropdown
              fullWidth
              id="region"
              disabled={hasOnlyOneRegion || loadingOverview}
              label={translations.region[lang]}
              value={selectedRegion}
              items={user.vaccinationRegionModelList.map((r: any) => ({
                label: r.description || '',
                value: r.id !== undefined ? r.id.toString() : '',
              }))}
              onChange={(e) => {
                const newRegion = e.target.value as string;
                if (newRegion && selectedDate && newRegion !== selectedRegion) {
                  getOverview(newRegion, selectedDate);
                }
                setSelectedRegion(newRegion);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              id="date"
              muiProps={{ disabled: loadingOverview }}
              label={translations.date[lang]}
              value={selectedDate}
              onChange={(e) => {
                const newDate = e ? moment(e) : null;
                if (
                  selectedRegion &&
                  newDate &&
                  !newDate.isSame(selectedDate, 'day')
                ) {
                  getOverview(selectedRegion, newDate);
                }
                setSelectedDate(newDate);
              }}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Button fullWidth variant="outlined" onClick={resetFilters}>
              {translations.reset[lang]}
            </Button>
            </Grid> */}
        </Grid>
      </Paper>
    );
  }

  function renderResults() {
    if (loadingOverview) {
      return (
        <Grid container justify="center" alignItems="center">
          <LoadingIndicator />
        </Grid>
      );
    }

    if (!locations) {
      return null;
    }

    if (!locations.length) {
      return (
        <NoResults message={translations.vaccinationOverviewNoResults[lang]} />
      );
    }

    return renderOverview();
  }

  function renderOverview() {
    return (
      <Paper elevation={2} className={classes.table}>
        <React.Fragment>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {Object.keys(tableColumns).map((columnKey) => (
                    <TableCell key={columnKey}>
                      {tableColumns[columnKey].sort ? (
                        <TableSortLabel
                          active={sortKey === columnKey}
                          direction={sortDirection}
                          onClick={() => onSortClick(columnKey)}
                        >
                          {tableColumns[columnKey].translation[lang]}
                        </TableSortLabel>
                      ) : (
                        tableColumns[columnKey].translation[lang]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedLocations.map((location) => (
                  <TableRow
                    hover
                    key={location.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setCurrentLocation(location)}
                  >
                    <TableCell>{location.name}</TableCell>
                    <TableCell>{location.vaccinated}</TableCell>
                    <TableCell>{location.summoned}</TableCell>
                    <TableCell>{location.totalVaccinatedPersons}</TableCell>
                    <TableCell>{`${location.totalCovidVaccinated}/${location.totalInfluenzaVaccinated}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={translations.numberOfRowsPerPage[lang]}
            rowsPerPageOptions={TABLE_ROWS_OPTIONS}
            component="div"
            count={sortedLocations.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={(_, p) => setCurrentPage(p)}
            onChangeRowsPerPage={(e) => {
              setCurrentPage(0);
              setRowsPerPage(parseInt(e.target.value, 10));
            }}
          />
        </React.Fragment>
      </Paper>
    );
  }

  function renderDetailsDialog() {
    if (!currentLocation) {
      return null;
    }

    return (
      <Dialog
        open
        fullWidth
        maxWidth="sm"
        fullScreen={xsDown}
        onClose={() => setCurrentLocation(null)}
      >
        <DialogTitle>{currentLocation.name}</DialogTitle>
        <DialogContent dividers className={classes.tableContent}>
          {loadingDetails ? (
            <Grid container justify="center" alignItems="center">
              <LoadingIndicator />
            </Grid>
          ) : !timeSlots.length ? (
            <Typography color="textSecondary">
              {translations.noTimeSlots[lang]}
            </Typography>
          ) : (
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{translations.time[lang]}</TableCell>
                    <TableCell align="center">
                      {translations.vaccinated[lang]}
                    </TableCell>
                    <TableCell align="center">
                      {translations.summoned[lang]}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timeSlots.map((slot) => (
                    <TableRow key={slot.time}>
                      <TableCell>{slot.time}</TableCell>
                      <TableCell align="center">{slot.vaccinated}</TableCell>
                      <TableCell align="center">{slot.summoned}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setCurrentLocation(null)}
          >
            {translations.close[lang]}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  async function getOverview(regionId: string, date: Moment) {
    setLoadingOverview(true);
    const response = await vaccinationService.getVaccinationSummary(
      regionId,
      date.toISOString()
    );

    if (response.isOk) {
      setLocations(response.data);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.errorOccurred[lang]);
      setLocations(null);
    }
    setLoadingOverview(false);
  }

  async function getLocationDetails(locationId: string, date: Moment) {
    setLoadingDetails(true);
    const response = await vaccinationService.getVaccinationSummaryDetails(
      locationId,
      date.toISOString()
    );

    if (response.isOk) {
      setTimeSlots(response.data);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.errorOccurred[lang]);
      setTimeSlots([]);
    }
    setLoadingDetails(false);
  }

  /* function resetFilters() {
    const initialRegion = hasOnlyOneRegion
      ? user?.vaccinationRegionModelList[0].id.toString()
      : '';
    const initialDate = moment();

    setSelectedRegion(initialRegion);
    setSelectedDate(initialDate);
    setLoadingOverview(false);
    setLocations(null);
    setCurrentLocation(null);
    setLoadingDetails(false);
    setTimeSlots([]);
    setCurrentPage(0);
    initialRegion && getOverviewCallback(initialRegion, initialDate);
  } */

  function onSortClick(columnKey: string) {
    if (sortKey === columnKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(columnKey);
      setSortDirection('asc');
    }
  }
};

export default VaccinationOverviewPage;
