import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Paper,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { Formik, Form, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';
import moment, { Moment } from 'moment';

import Button from './Button';
import Section from './Section';
import ConfirmDialog from './ConfirmDialog';
import NoResults from './NoResults';
import LoadingIndicator from './LoadingIndicator';
import GroupsInfo from './GroupsInfo';
import VaccinationComment from './VaccinationComment';
import EditPhoneNumber from './EditPhoneNumber';
import VaccinationSettings, {
  IProps as IVaccinationSettingsProps,
} from './VaccinationSettings';
import VaccinationConditions, {
  IProps as IVaccinationConditionProps,
} from './VaccinationConditions';
import * as vaccinationService from '../services/vaccinationService';
import * as influenzaService from '../services/influenzaService';
import { VaccinationPerson, ChildInfo } from '../models/VaccinationModels';
import useNotifier from '../hooks/useNotifier';
import { LangContext } from '../context/LangContext';
import { UserContext } from '../context/UserContext';
import translations from '../assets/json/translations.json';
import {
  IVaccinationPersonDetails,
  IVaccinationPersonPhoneNumberUpdate,
} from '../interfaces/vaccinationPerson';
import {
  getBirthYearBySSN,
  isAccessible,
  parseTemplateString,
  shouldShowErrorForBoosterVaccine,
  shouldShowWarningForBooster,
  validateBirthDateForChildVaccination,
  shouldShowWarningForInfluenza,
} from '../utils';
import VaccinationStatusTable, {
  IFormValues as IUpdateFormValues,
} from './VaccinationStatusTable';
import {
  accessControl,
  childVaccinationCustodianPosition,
  typeOfVaccination,
  VaccineType,
} from '../constants/enums';
import {
  IInfluenzaVaccinationUpdate,
  IVaccinationUpdate,
} from '../interfaces/vaccinationUpdate';
import VaccinationPersonGroupedInfo, {
  IButton,
} from './VaccinationPersonGroupedInfo';
import { DATE_AND_TIME_FORMAT } from '../constants';
import {
  IVaccinationComment,
  IVaccinationCommentStatus,
} from '../interfaces/vaccinationComment';
import ChildVaccinationPosition from './Vaccination/ChildVaccination/ChildVaccinationPosition';
import ChildCustodianPosition from './Vaccination/ChildVaccination/ChildCustodianPosition';
import RadioButton from './RadioButton';
import { IUpdateVaccinationGuardianDecision } from '../models/Models';
import { ICreateGuardian } from '../interfaces/vaccinationChild';
import { IVaccinate } from '../interfaces/vaccinate';

interface IProps {
  vaccinationSettings: IVaccinationSettingsProps;
  vaccinationConditions: IVaccinationConditionProps;
  commentStatusList: IVaccinationCommentStatus[] | null;
}

export interface IFormValues {
  vaccine: string;
  location: string;
  vaccinationDate: Moment | null;
  vaccinationTime: Moment | null;
  isFirstVaccination: boolean;
  serialNumber: string | null;
}

type IFormik = FormikProps<IFormValues>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paperLeft: {
      textAlign: 'center',
      padding: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    settings: {
      marginBottom: theme.spacing(3),
    },
  })
);

const Vaccination: React.FC<IProps> = ({
  vaccinationSettings,
  vaccinationConditions,
  commentStatusList,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const { notifySuccess, notifyError, notifyWarning } = useNotifier();
  const serialNumberInput = useRef<HTMLInputElement>(null);

  const [vaccinationPerson, setVaccinationPerson] =
    useState<VaccinationPerson | null>(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [clearDialogOpen, setClearDialogOpen] = useState(false);
  const [vaccineMismatchDialogOpen, setVaccineMismatchDialogOpen] =
    useState(false);
  const [mismatchDialogOpen, setMismatchDialogOpen] = useState(false);
  const [settingsErrorDialogOpen, setSettingsErrorDialogOpen] = useState(false);
  const [warningVaccinationDialogOpen, setWarningVaccinationDialogOpen] =
    useState(false);
  const [boosterVaccineErrorDialogOpen, setBoosterVaccineErrorDialogOpen] =
    useState(false);
  const [
    influenzaVaccinatnionErrorDialogOpen,
    setInfluenzaVaccinatnionErrorDialogOpen,
  ] = useState(false);
  const [isChild, setIsChild] = useState(false);
  const [selectedMemberChildDetails, setSelectedMemberChildDetails] =
    useState<ChildInfo | null>(null);
  const [childGuardian, setChildGuardian] = useState<string | null>(null);
  const [vaccinationTypeCovid, setVaccinationTypeCovid] = useState(false);
  const [vaccinationTypeInfluenza, setVaccinationTypeInfluenza] =
    useState(false);

  useEffect(() => {
    if (!vaccinationPerson) {
      focusSerialNumberInput();
    }
  }, [vaccinationPerson]);

  useEffect(() => {
    if (vaccinationPerson && vaccinationSettings) {
      setVaccinationTypeCovid(
        vaccinationSettings.selectedTypeOfVaccination ===
        typeOfVaccination.COVID_19 ||
        vaccinationSettings.selectedTypeOfVaccination ===
        typeOfVaccination.COVID_19_AND_INFLUENZA
      );
      setVaccinationTypeInfluenza(
        vaccinationSettings.selectedTypeOfVaccination ===
        typeOfVaccination.INFLUENZA ||
        vaccinationSettings.selectedTypeOfVaccination ===
        typeOfVaccination.COVID_19_AND_INFLUENZA
      );
    }
  }, [vaccinationPerson, vaccinationSettings]);

  useEffect(() => {
    const fetchChildInfo = async (vaccinationPerson: VaccinationPerson) => {
      const response = await vaccinationService.getChildInfo(
        vaccinationPerson.vaccinationPersonId || 0
      );
      if (response.isOk) {
        setSelectedMemberChildDetails(response.data);
      }
    };
    if (isChild && vaccinationPerson) {
      fetchChildInfo(vaccinationPerson);
    }
  }, [vaccinationPerson, isChild]);

  useEffect(() => {
    if (
      vaccinationPerson /* &&
        !vaccinationPerson.secondVaccination.vaccinationDate) ||
      (vaccinationPerson &&
        vaccinationSettings.selectedVaccine?.atc === 'J07BX0305' */
    ) {
      const mismatches = getMismatchWarnings(
        vaccinationPerson,
        vaccinationSettings,
        vaccinationConditions,
        vaccinationTypeCovid
      );
      if (Object.values(mismatches).includes(true)) {
        setMismatchDialogOpen(true);
      }
    }

    if (
      vaccinationPerson &&
      validateBirthDateForChildVaccination(vaccinationPerson.ssn)
    ) {
      setIsChild(true);
    }
  }, [
    vaccinationPerson,
    vaccinationSettings,
    vaccinationConditions,
    vaccinationTypeCovid,
    vaccinationTypeInfluenza,
  ]);

  return (
    <Section>
      <div className={classes.root}>
        <Formik
          initialValues={
            {
              serialNumber: '',
              vaccine: '',
              location: '',
              vaccinationDate: null,
              vaccinationTime: null,
              isFirstVaccination: false,
            } as IFormValues
          }
          onSubmit={(values) => searchForVaccinationPerson(values)}
        >
          {(formik) => {
            const { buttons, canVaccinate } = getButtons(formik);

            return (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={5} lg={4}>
                  <Paper elevation={2} className={classes.paperLeft}>
                    {renderSearch(formik, canVaccinate)}
                  </Paper>
                  <div className={classes.settings}>
                    <VaccinationSettings {...vaccinationSettings} />
                  </div>
                  <VaccinationConditions {...vaccinationConditions} />
                </Grid>
                {loadingSearch ? (
                  <Grid item xs={12} sm={12} md={7} lg={8}>
                    <Grid container justify="center" alignItems="center">
                      <LoadingIndicator />
                    </Grid>
                  </Grid>
                ) : vaccinationPerson ? (
                  <Grid item xs={12} sm={12} md={7} lg={8}>
                    <VaccinationPersonGroupedInfo
                      vaccinationPerson={vaccinationPerson}
                      vaccinationPersonDetails={getVaccinationPersonDetails(
                        formik.values
                      )}
                      vaccinationPersonChildInfo={
                        selectedMemberChildDetails || undefined
                      }
                      childDetails={getChildDetails()}
                      buttons={buttons}
                      vaccinationTypeElement={renderTypeOfVaccination()}
                    />
                  </Grid>
                ) : noResult ? (
                  <Grid item xs={12} sm={12} md={7} lg={8}>
                    <NoResults
                      message={translations.noVaccinationPersonFound[lang]}
                    />
                  </Grid>
                ) : null}
                {renderDialogs(formik)}
              </Grid>
            );
          }}
        </Formik>
      </div>
    </Section>
  );
  function getChildDetails() {
    return [
      {
        label: translations.custodianPosition[lang],
        render: (childInfo: ChildInfo) => {
          return (
            <ChildVaccinationPosition status={childInfo.allowVaccination} />
          );
        },
      },
      {
        render: (childInfo: ChildInfo) => {
          return (
            <ChildCustodianPosition
              custodians={childInfo.custodians}
              updateChildGuardianDecision={updateChildGuardianDecision}
              addGuardian={addChildGuardian}
              canEdit={true}
            />
          );
        },
      },
      {
        label: translations.guardianAccompanie[lang],
        render: (childInfo: ChildInfo) => {
          if (childInfo.custodians.length) {
            return (
              <RadioButton
                disabled={!validateCanSelectGuardian()}
                value={childGuardian}
                options={childInfo.custodians.map((item) => {
                  return { value: item.id.toString(), label: item.name };
                })}
                onChange={(event) => setChildGuardian(event.target.value)}
              />
            );
          }
          return '';
        },
      },
    ];
  }
  function validateChildVaccination() {
    return childGuardian && validateCanSelectGuardian();
  }
  function validateCanSelectGuardian() {
    return (
      isChild &&
      selectedMemberChildDetails?.allowVaccination ===
      childVaccinationCustodianPosition.YES
    );
  }
  function renderSearch(formik: IFormik, canVaccinate: boolean) {
    return (
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5">
              {translations.inputSerialNumberOrSSN[lang]}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              autoFocus
              autoComplete="off"
              id="serialNumber"
              label={translations.serialNumberOrSSN[lang]}
              variant="outlined"
              inputProps={{ ref: serialNumberInput }}
              value={formik.values.serialNumber}
              onChange={formik.handleChange}
              disabled={!!vaccinationPerson}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              type="submit"
              disabled={formik.isSubmitting || !formik.dirty}
            >
              {translations.search[lang]}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              onClick={
                canVaccinate
                  ? () => setClearDialogOpen(true)
                  : () => clearSearch(formik)
              }
            >
              {translations.clear[lang]}
            </Button>
          </Grid>
        </Grid>
      </Form>
    );
  }

  function renderDialogs(formik: IFormik) {
    if (vaccinationPerson) {
      const mismatches = getMismatchWarnings(
        vaccinationPerson,
        vaccinationSettings,
        vaccinationConditions,
        vaccinationTypeCovid
      );
      return (
        <React.Fragment>
          <ConfirmDialog
            open={clearDialogOpen}
            title={translations.clear[lang]}
            text={translations.clearInputDialogTextVaccination[lang]}
            confirmText={translations.clear[lang]}
            onConfirm={() => {
              clearSearch(formik);
              setClearDialogOpen(false);
            }}
            onCancel={() => setClearDialogOpen(false)}
          />
          <ConfirmDialog
            error
            open={vaccineMismatchDialogOpen}
            title={translations.vaccineMismatch[lang]}
            text={translations.vaccineMismatchText[lang]}
            onCancel={() => setVaccineMismatchDialogOpen(false)}
            cancelText={translations.ok[lang]}
          />
          <ConfirmDialog
            error
            open={
              mismatchDialogOpen && Object.values(mismatches).includes(true)
            }
            title={translations.warning[lang]}
            onCancel={() => setMismatchDialogOpen(false)}
            cancelText={translations.ok[lang]}
          >
            {renderMismatchDialogContent()}
          </ConfirmDialog>
          <ConfirmDialog
            error
            open={settingsErrorDialogOpen}
            title={translations.settings[lang]}
            text={translations.settingsErrorDialog[lang]}
            onCancel={() => setSettingsErrorDialogOpen(false)}
            cancelText={translations.ok[lang]}
          />
          <ConfirmDialog
            error
            open={warningVaccinationDialogOpen}
            title={translations.vaccinationWarningDialogTitle[lang]}
            text={translations.vaccinationWarningDialogText[lang]}
            onCancel={() => setWarningVaccinationDialogOpen(false)}
            onConfirm={() => {
              setWarningVaccinationDialogOpen(false);
              finishVaccination(formik, true);
            }}
            cancelText={translations.cancel[lang]}
          />
          <ConfirmDialog
            error
            open={boosterVaccineErrorDialogOpen}
            title={translations.vaccinationBoosterErrorDialogTitle[lang]}
            text={parseTemplateString(
              translations.vaccinationBoosterErrorDialogText[lang],
              vaccinationSettings?.selectedVaccine?.numberOfPreviousVaccinationsRequired.toString() ||
              '2'
            )}
            onCancel={() => setBoosterVaccineErrorDialogOpen(false)}
            cancelText={translations.ok[lang]}
          />
          <ConfirmDialog
            error
            open={influenzaVaccinatnionErrorDialogOpen}
            title={translations.influenzaVaccinationErrorDialogTitle[lang]}
            text={translations.influenzaVaccinationMismatch[lang]}
            onCancel={() => setInfluenzaVaccinatnionErrorDialogOpen(false)}
            cancelText={translations.ok[lang]}
          />
        </React.Fragment>
      );
    }
  }

  function getVaccinationPersonDetails(
    values: IFormValues
  ): IVaccinationPersonDetails[][] {
    const details: IVaccinationPersonDetails[][] = [];

    const personDetails: IVaccinationPersonDetails[] = [
      {
        label: translations.name[lang],
        getVal(vaccinationPerson: VaccinationPerson) {
          return vaccinationPerson.name;
        },
      },
      {
        label: translations.SSN[lang],
        getVal(vaccinationPerson: VaccinationPerson) {
          return vaccinationPerson.ssn;
        },
      },
      {
        label: translations.phoneNumber[lang],
        render(vaccinationPerson: VaccinationPerson) {
          return (
            <EditPhoneNumber
              canEdit
              phoneNumber={vaccinationPerson.phoneNumber}
              submitChanges={updatePhoneNumber}
            />
          );
        },
      },
      {
        label: translations.serialNumber[lang],
        getVal(vaccinationPerson: VaccinationPerson) {
          return vaccinationPerson.serialNumber;
        },
      },
    ];

    if (vaccinationPerson?.nextSummoning) {
      personDetails.push({
        label: translations.summoningDate[lang],
        getVal(vaccinationPerson: VaccinationPerson) {
          return (
            `${moment(vaccinationPerson.nextSummoning?.date).format(
              DATE_AND_TIME_FORMAT
            )} - ${vaccinationPerson.nextSummoning?.location.name} ` || ''
          );
        },
      });
    }

    details.push(personDetails);
    if (!isChild) {
      details.push([
        {
          label: translations.comment[lang],
          render: (vaccinationPerson: VaccinationPerson) => (
            <VaccinationComment
              canEdit
              vaccinationComment={vaccinationPerson.vaccinationComment}
              commentStatusList={commentStatusList}
              updateComment={updateComment}
              deleteComment={deleteComment}
              locations={vaccinationSettings.locations}
            />
          ),
        },
      ]);
    }

    details.push([
      {
        label: translations.covid19Vaccinations[lang],
        render: (vaccinationPerson: VaccinationPerson) => (
          <VaccinationStatusTable
            vaccinationPerson={vaccinationPerson}
            vaccinations={vaccinationPerson.vaccinations}
            vaccines={
              vaccinationSettings.vaccines?.filter(
                (vaccine) => vaccine.vaccineType === VaccineType.COVID_19
              ) || []
            }
            locations={vaccinationSettings.locations}
            canEdit={
              isAccessible(accessControl.VACCINATION_GROUP, user) ||
              isAccessible(accessControl.VACCINATION, user)
            }
            updateVaccination={updateVaccination}
            revokeVaccination={revokeVaccination}
          />
        ),
      },
    ]);

    details.push([
      {
        label: translations.influenzaVaccinations[lang],
        render: (vaccinationPerson: VaccinationPerson) => (
          <VaccinationStatusTable
            vaccinationPerson={vaccinationPerson}
            vaccinations={vaccinationPerson.influenzaVaccinatnions}
            vaccines={
              vaccinationSettings.vaccines?.filter(
                (vaccine) => vaccine.vaccineType === VaccineType.INFLUENZA
              ) || []
            }
            locations={vaccinationSettings.locations}
            canEdit={
              isAccessible(accessControl.VACCINATION_GROUP, user) ||
              isAccessible(accessControl.VACCINATION, user)
            }
            updateVaccination={updateInfluenzaVaccination}
            revokeVaccination={revokeInfluenzaVaccination}
          />
        ),
      },
    ]);

    if (vaccinationPerson?.groups) {
      details.push([
        {
          // label: translations.groupsLabel[lang],
          render: (vaccinationPerson: VaccinationPerson) => {
            return <GroupsInfo groups={vaccinationPerson.groups} />;
          },
        },
      ]);
    }

    return details;
  }

  async function updateVaccination(values: IUpdateFormValues) {
    if (vaccinationPerson?.ssn && values.vaccine) {
      const params = {
        personSsn: vaccinationPerson?.ssn,
        vaccinationId: values.id,
        vaccinationCodeId: parseInt(values.vaccine.value),
        locationId: parseInt(values.location),
        vaccinationDate: getDateTime(
          values.vaccinationDate,
          values.vaccinationTime
        ).toISOString(),
        isFirstVaccination: values.isFirstVaccination,
      } as IVaccinationUpdate;

      const response = await vaccinationService.updateVaccination(params);

      if (response.isOk) {
        searchForVaccinationPerson &&
          (await searchForVaccinationPerson({
            serialNumber: vaccinationPerson.serialNumber,
          } as IFormValues));
        notifySuccess(translations.vaccinationUpdated[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function updateInfluenzaVaccination(values: IUpdateFormValues) {
    if (vaccinationPerson?.serialNumber && values.vaccine) {
      const params = {
        vaccinationCodeId: parseInt(values.vaccine.value),
        locationId: parseInt(values.location),
        vaccinationDate: getDateTime(
          values.vaccinationDate,
          values.vaccinationTime
        ).toISOString(),
      } as IInfluenzaVaccinationUpdate;

      const response = await influenzaService.updateVaccination(
        params,
        values.id
      );

      if (response.isOk) {
        searchForVaccinationPerson &&
          (await searchForVaccinationPerson({
            serialNumber: vaccinationPerson.serialNumber,
          } as IFormValues));
        notifySuccess(translations.vaccinationUpdated[lang]);
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  function getDateTime(dateValue: Moment | null, timeValue: Moment | null) {
    var date = moment(dateValue);
    var time = moment(timeValue, 'HH:mm');
    date = date.hour(time.get('hour'));
    date = date.minute(time.get('minutes'));

    return date;
  }

  function getButtons(formik: IFormik) {
    const buttons: IButton[] = [];
    let canVaccinate = false;

    const getDaysUntilText = (previousDate: string, daysBetween: number) => {
      var daysUntil = moment(previousDate)
        .add(daysBetween, 'days')
        .startOf('day')
        .diff(moment(), 'days');
      const daysUntilStr = (daysUntil + 1).toString();
      if (daysUntilStr[daysUntilStr.length - 1] === '1') {
        return translations.vaccinationTimeNotPassedSingular[lang].replace(
          '$0',
          daysUntilStr
        );
      } else {
        return translations.vaccinationTimeNotPassedPlural[lang].replace(
          '$0',
          daysUntilStr
        );
      }
    };
    const canVaccinatePerson = (vaccinationPerson: VaccinationPerson) => {
      // persons should always be in a group before vaccinating
      return (
        vaccinationPerson.groups !== undefined &&
        vaccinationPerson?.groups.length > 0 &&
        ((isChild && validateChildVaccination()) || !isChild)
      );
    };

    if (vaccinationTypeCovid) {
      if (vaccinationPerson) {
        const vaccinateButton: IButton = {
          label: vaccinationTypeInfluenza
            ? translations.vaccinateC19AndInfluenza[lang]
            : translations.vaccinateC19[lang],
          disabled: loadingUpdate || !canVaccinatePerson(vaccinationPerson),
          onClick: () => finishVaccination(formik),
        };

        if (!vaccinationPerson.firstVaccination.vaccinationDate) {
          buttons.push(vaccinateButton);
          canVaccinate = true;
        } else {
          if (!vaccinationPerson.secondVaccination.vaccinationDate) {
            const daysBetween =
              vaccinationPerson.firstVaccination.daysBetweenVaccinations || 0;
            const secondVaccinationDate = moment(
              vaccinationPerson.firstVaccination.vaccinationDate
            )
              .add(daysBetween, 'days')
              .startOf('day');
            const secondVaccinationValid = moment().isAfter(
              secondVaccinationDate
            );
            canVaccinate = secondVaccinationValid;
            if (secondVaccinationValid) {
              buttons.push(vaccinateButton);
            } else {
              buttons.push({
                label: `${getDaysUntilText(
                  vaccinationPerson.firstVaccination.vaccinationDate,
                  daysBetween
                )}`,
                disabled: true,
                onClick: () => { },
              });
            }
          } else {
            const lastVaccination =
              vaccinationPerson.vaccinations[
              vaccinationPerson.vaccinations.length - 1
              ];
            const daysBetween =
              lastVaccination.boosterDaysBetweenVaccinations ||
              lastVaccination.daysBetweenVaccinations ||
              0;
            const nextVaccinationDate = moment(lastVaccination.vaccinationDate)
              .add(daysBetween, 'days')
              .startOf('day');
            const nextVaccinationValid = moment().isAfter(nextVaccinationDate);
            canVaccinate = nextVaccinationValid;
            if (nextVaccinationValid) {
              buttons.push(vaccinateButton);
            } else {
              if (lastVaccination.vaccinationDate) {
                buttons.push({
                  label: `${getDaysUntilText(
                    lastVaccination.vaccinationDate,
                    daysBetween
                  )}`,
                  disabled: true,
                  onClick: () => { },
                });
              }
            }
          }
        }
      }
    }

    if (vaccinationTypeInfluenza && !vaccinationTypeCovid) {
      buttons.push({
        label: translations.vaccinateInfluenza[lang],
        disabled: loadingUpdate,
        onClick: () => finishVaccination(formik),
      });
    }

    if (!vaccinationTypeInfluenza && !vaccinationTypeCovid) {
      buttons.push({
        label: translations.vaccinate[lang],
        disabled: true,
        onClick: () => finishVaccination(formik),
      });
    }

    return { buttons, canVaccinate };
  }
  async function searchForVaccinationPerson(
    values: IFormValues,
    disableLoading: boolean = false
  ) {
    !disableLoading && setLoadingSearch(true);
    const query = values.serialNumber || '';
    //const ssnPattern = new RegExp(/^[0-9]{10}$/);
    let response = null;

    if (query.length > 0) {
      // BarCode, only barcode start at letter
      if (isNaN(parseInt(query[0]))) {
        response = await vaccinationService.getVaccinationPersonBySerialNumber(
          query
        );
      }
      // kt or fake kt
      else {
        response = await vaccinationService.getVaccinationPersonBySSN(query);
      }

      if (response.isOk) {
        setVaccinationPerson(response.data);
        !disableLoading && setLoadingSearch(false);
        setNoResult(!!response.data);
        if (
          response.data?.groups === null ||
          response.data?.groups === undefined ||
          response.data.groups.length < 1
        ) {
          notifyWarning(translations.vaccinationWarningNoGroups[lang]);
        }
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        response.statusCode !== 404 &&
          notifyError(translations.errorOccurred[lang]);
        setVaccinationPerson(null);
        !disableLoading && setLoadingSearch(false);
        setNoResult(true);
      }
    }
  }

  async function finishVaccination(
    formik: IFormik,
    skipWarningDialog?: boolean
  ) {
    const { selectedVaccine, selectedInfluenzaVaccine, selectedLocation } =
      vaccinationSettings;
    if (vaccinationPerson) {
      if (
        !skipWarningDialog &&
        shouldShowWarningForBooster(vaccinationPerson) &&
        vaccinationTypeCovid
      ) {
        setWarningVaccinationDialogOpen(true);
      } else if (
        (!selectedVaccine && vaccinationTypeCovid) ||
        (!selectedInfluenzaVaccine && vaccinationTypeInfluenza) ||
        !selectedLocation
      ) {
        setSettingsErrorDialogOpen(true);
      } else if (
        vaccinationTypeCovid &&
        shouldShowErrorForBoosterVaccine(
          selectedVaccine,
          vaccinationPerson.vaccinations
        )
      ) {
        setBoosterVaccineErrorDialogOpen(true);
      } else if (
        vaccinationTypeInfluenza &&
        shouldShowWarningForInfluenza(vaccinationPerson)
      ) {
        setInfluenzaVaccinatnionErrorDialogOpen(true);
      } else {
        setLoadingUpdate(true);

        const covidPayload: IVaccinate = {
          personSsn: vaccinationPerson.ssn,
          vaccinationCodeId: selectedVaccine?.id || 0,
          locationId: selectedLocation.id,
          isFirstVaccination: !vaccinationPerson.vaccinations.length,
        };
        const influenzaPayload: IVaccinate = {
          personSsn: vaccinationPerson.ssn,
          vaccinationCodeId: selectedInfluenzaVaccine?.id || 0,
          locationId: selectedLocation.id,
        };

        if (vaccinationTypeCovid && !vaccinationTypeInfluenza) {
          const covidResponse = await covidVaccination(covidPayload);
          if (covidResponse.isOk) {
            notifySuccess(translations.vaccinationFinished[lang]);
            clearSearch(formik);
          } else {
            notifyError(covidResponse.data || translations.errorOccurred[lang]);
            setLoadingUpdate(false);
          }
        }
        if (vaccinationTypeCovid && vaccinationTypeInfluenza) {
          const covidResponse = await covidVaccination(covidPayload);
          const influenzaResponse = await influenzaVaccination(
            influenzaPayload
          );

          if (covidResponse.isOk && influenzaResponse.isOk) {
            notifySuccess(translations.vaccinationFinished[lang]);
            clearSearch(formik);
          } else {
            notifyError(translations.errorOccurred[lang]);
            setLoadingUpdate(false);
          }
        }
        if (!vaccinationTypeCovid && vaccinationTypeInfluenza) {
          const influenzaResponse = await influenzaVaccination(
            influenzaPayload
          );

          if (influenzaResponse.isOk) {
            notifySuccess(translations.vaccinationFinished[lang]);
            clearSearch(formik);
          } else {
            notifyError(translations.errorOccurred[lang]);
            setLoadingUpdate(false);
          }
        }
      }
    }
  }

  async function covidVaccination(payload: IVaccinate) {
    const response = await vaccinationService.vaccinate(payload);
    return response;
  }

  async function influenzaVaccination(payload: IVaccinate) {
    const response = await influenzaService.vaccinate(payload);
    return response;
  }

  function renderMismatchDialogContent() {
    if (!vaccinationPerson) {
      return null;
    }

    const {
      vaccineMismatchIsAllowed,
      locationMismatch,
      dateOfBirthMismatch,
      summoningMismatch,
      groupsMismatch,
      childVaccinationMismatch,
      nonChildVaccinationMismatch,
    } = getMismatchWarnings(
      vaccinationPerson,
      vaccinationSettings,
      vaccinationConditions,
      vaccinationTypeCovid
    );

    return (
      <Grid container spacing={3}>
        {vaccineMismatchIsAllowed && (
          <Grid item xs={12}>
            <Typography>
              {translations.vaccineMismatchTextIsAllowed[lang]}
            </Typography>
          </Grid>
        )}
        {locationMismatch && (
          <Grid item xs={12}>
            <Typography>{`${translations.locationMismatchText[lang]}: ${vaccinationPerson.nextSummoning?.location.name} - ${vaccinationSettings.selectedLocation?.name}`}</Typography>
          </Grid>
        )}
        {dateOfBirthMismatch && (
          <Grid item xs={12}>
            <Typography>{translations.dateOfBirthMismatch[lang]}</Typography>
          </Grid>
        )}
        {summoningMismatch && (
          <Grid item xs={12}>
            <Typography>{translations.summoningMismatch[lang]}</Typography>
          </Grid>
        )}
        {groupsMismatch && (
          <Grid item xs={12}>
            <Typography>{translations.groupsMismatch[lang]}</Typography>
          </Grid>
        )}
        {childVaccinationMismatch && (
          <Grid item xs={12}>
            <Typography>
              {translations.vaccineMismatchChildText[lang]}
            </Typography>
          </Grid>
        )}
        {nonChildVaccinationMismatch && (
          <Grid item xs={12}>
            <Typography>
              {translations.vaccineMismatchNonChildText[lang]}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  async function revokeVaccination(personSsn: string, vaccinationId: number) {
    if (vaccinationPerson) {
      const response = await vaccinationService.cancelVaccination(
        personSsn,
        vaccinationId
      );

      if (response.isOk) {
        searchForVaccinationPerson &&
          (await searchForVaccinationPerson({
            serialNumber: vaccinationPerson.serialNumber,
          } as IFormValues));
        notifySuccess(translations.vaccinationRevoked[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(response.data || translations.errorOccurred[lang]);
      }
    }
  }

  async function revokeInfluenzaVaccination(
    personSsn: string,
    vaccinationId: number
  ) {
    if (vaccinationPerson) {
      const response = await influenzaService.cancelVaccination(vaccinationId);

      if (response.isOk) {
        searchForVaccinationPerson &&
          (await searchForVaccinationPerson({
            serialNumber: vaccinationPerson.serialNumber,
          } as IFormValues));
        notifySuccess(translations.vaccinationRevoked[lang]);
      } else {
        notifyError(response.data || translations.errorOccurred[lang]);
      }
    }
  }

  function clearSearch(formik: IFormik) {
    setVaccinationPerson(null);
    setLoadingSearch(false);
    setLoadingUpdate(false);
    setNoResult(false);
    focusSerialNumberInput();
    formik.handleReset();
    setIsChild(false);
    setChildGuardian(null);
    setSelectedMemberChildDetails(null);
  }

  function focusSerialNumberInput() {
    if (serialNumberInput && serialNumberInput.current) {
      serialNumberInput.current.focus();
    }
  }

  async function updateChildGuardianDecision(
    allowVaccinationStatus: number,
    comment: string,
    childGuardianId: number
  ) {
    if (
      vaccinationPerson &&
      vaccinationPerson.vaccinationPersonId !== undefined
    ) {
      const params: IUpdateVaccinationGuardianDecision = {
        allowVaccination: allowVaccinationStatus,
        comment: comment,
      };
      const response = await vaccinationService.updateGuardianDecision(
        childGuardianId,
        params
      );

      if (response.isOk) {
        searchForVaccinationPerson &&
          (await searchForVaccinationPerson({
            serialNumber: vaccinationPerson.serialNumber,
          } as IFormValues));
        notifySuccess(translations.commentAdded[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function addChildGuardian(
    ssn: string,
    name: string,
    dateOfBirth: string,
    allowVaccinationStatus: number,
    comment: string
  ) {
    if (
      vaccinationPerson &&
      vaccinationPerson.vaccinationPersonId !== undefined
    ) {
      const params: ICreateGuardian = {
        vaccinationPersonId: vaccinationPerson.vaccinationPersonId,
        name: name,
        ssn: ssn ? ssn : undefined,
        dateOfBirth: dateOfBirth ? dateOfBirth : undefined,
        allowVaccination: allowVaccinationStatus,
        comment: comment,
      };
      const response = await vaccinationService.addGuardianToPerson(params);

      if (response.isOk) {
        searchForVaccinationPerson &&
          (await searchForVaccinationPerson({
            serialNumber: vaccinationPerson.serialNumber,
          } as IFormValues));
        notifySuccess(translations.guardianAdded[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function updateComment(
    statusId: number | null,
    comment: string,
    locationId: number
  ) {
    if (
      vaccinationPerson &&
      vaccinationPerson.vaccinationPersonId !== undefined
    ) {
      const params: IVaccinationComment = {
        comment: comment,
        statusId: statusId,
        groupId: null,
        vaccinationPersonId: vaccinationPerson.vaccinationPersonId,
        locationId,
      };
      const response =
        vaccinationPerson.vaccinationComment.id !== undefined
          ? await vaccinationService.updateVaccinationComment(
            vaccinationPerson.vaccinationComment.id,
            params
          )
          : await vaccinationService.addVaccinationComment(params);

      if (response.isOk) {
        searchForVaccinationPerson &&
          (await searchForVaccinationPerson({
            serialNumber: vaccinationPerson.serialNumber,
          } as IFormValues));
        notifySuccess(translations.commentAdded[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function deleteComment() {
    if (
      vaccinationPerson &&
      vaccinationPerson.vaccinationComment.id !== undefined
    ) {
      const response = await vaccinationService.deleteVaccinationComment(
        vaccinationPerson.vaccinationComment.id
      );

      if (response.isOk) {
        searchForVaccinationPerson &&
          (await searchForVaccinationPerson({
            serialNumber: vaccinationPerson.serialNumber,
          } as IFormValues));
        notifySuccess(translations.commentDeleted[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function updatePhoneNumber(newNumber: string) {
    if (vaccinationPerson?.vaccinationPersonId) {
      const params: IVaccinationPersonPhoneNumberUpdate = {
        ssn: vaccinationPerson.ssn,
        newPhoneNumber: newNumber,
      };
      const response = await vaccinationService.updatePhoneNumber(params);

      if (response.isOk) {
        searchForVaccinationPerson &&
          (await searchForVaccinationPerson({
            serialNumber: vaccinationPerson.serialNumber,
          } as IFormValues));
        notifySuccess(translations.phoneNumberChanged[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  function renderTypeOfVaccination() {
    return (
      <FormControl>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={vaccinationTypeCovid}
                onChange={() => setVaccinationTypeCovid(!vaccinationTypeCovid)}
                color="primary"
              />
            }
            label="COVID-19"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={vaccinationTypeInfluenza}
                onChange={() =>
                  setVaccinationTypeInfluenza(!vaccinationTypeInfluenza)
                }
                color="primary"
              />
            }
            label="Influenza"
          />
        </FormGroup>
      </FormControl>
    );
  }
};

function getMismatchWarnings(
  vaccinationPerson: VaccinationPerson,
  vaccinationSettings: IVaccinationSettingsProps,
  vaccinationConditions: IVaccinationConditionProps,
  vaccinationTypeCovid: boolean
) {
  let vaccineMismatchIsAllowed = false;
  let firstOrSecondMismatch = false;
  let locationMismatch = false;
  let dateOfBirthMismatch = false;
  let summoningMismatch = false;
  let groupsMismatch = false;
  let childVaccinationMismatch = false;
  let nonChildVaccinationMismatch = false;

  if (
    vaccinationTypeCovid &&
    vaccinationPerson.firstVaccination.vaccinationDate &&
    vaccinationSettings.selectedVaccine?.atc !==
    vaccinationPerson.firstVaccination.atc &&
    !vaccinationPerson.secondVaccination.vaccinationDate
  ) {
    vaccineMismatchIsAllowed = true;
  }

  if (
    !vaccinationPerson.secondVaccination.vaccinationDate &&
    vaccinationSettings.selectedLocation &&
    vaccinationPerson.nextSummoning &&
    vaccinationSettings.selectedLocation.id !==
    vaccinationPerson.nextSummoning.location.id
  ) {
    locationMismatch = true;
  }

  if (
    !vaccinationPerson.firstVaccination.vaccinationDate ||
    !vaccinationConditions.selectedAlwaysAllowSecond
  ) {
    const yearOfBirth = getBirthYearBySSN(vaccinationPerson.ssn);
    const fromYear = vaccinationConditions.selectedYearFrom
      ? parseInt(vaccinationConditions.selectedYearFrom)
      : null;
    const toYear = vaccinationConditions.selectedYearTo
      ? parseInt(vaccinationConditions.selectedYearTo)
      : null;
    if (
      (fromYear && yearOfBirth < fromYear) ||
      (toYear && yearOfBirth > toYear)
    ) {
      dateOfBirthMismatch = true;
    }

    const summoningDate = vaccinationPerson.nextSummoning
      ? moment(vaccinationPerson.nextSummoning.date)
      : null;
    const summoningFrom = vaccinationConditions.selectedSummoningFrom
      ? vaccinationConditions.selectedSummoningFrom.startOf('day')
      : null;
    const summoningTo = vaccinationConditions.selectedSummoningTo
      ? vaccinationConditions.selectedSummoningTo.endOf('day')
      : null;
    const summoningConditionSet = Boolean(summoningFrom || summoningTo);
    if (
      (!summoningDate && summoningConditionSet) ||
      (summoningFrom && summoningDate?.isBefore(summoningFrom)) ||
      (summoningTo && summoningDate?.isAfter(summoningTo))
    ) {
      summoningMismatch = true;
    }

    const vpGroups = (vaccinationPerson.groups || []).map((g) => g.id);
    const selectedGroups = (vaccinationConditions.selectedGroups || []).map(
      (g) => g.id
    );
    const selectedVpGroups = selectedGroups.filter((g) => vpGroups.includes(g));
    if (!!selectedGroups.length && !selectedVpGroups.length) {
      groupsMismatch = true;
    }
  }
  if (
    (vaccinationSettings.selectedVaccine?.atc !== 'J07BX0305' &&
      vaccinationSettings.selectedVaccine?.atc !== 'J07BN010710') &&
    validateBirthDateForChildVaccination(vaccinationPerson.ssn)
  ) {
    childVaccinationMismatch = true;
  }

  if (
    (vaccinationSettings.selectedVaccine?.atc === 'J07BX0305' ||
      vaccinationSettings.selectedVaccine?.atc === 'J07BN010710') &&
    !validateBirthDateForChildVaccination(vaccinationPerson.ssn)
  ) {
    nonChildVaccinationMismatch = true;
  }


  return {
    vaccineMismatchIsAllowed,
    firstOrSecondMismatch,
    locationMismatch,
    dateOfBirthMismatch,
    summoningMismatch,
    groupsMismatch,
    childVaccinationMismatch,
    nonChildVaccinationMismatch,
  };
}

export default Vaccination;
