import React, { useContext, useState, useEffect } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Grid, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from './Button';
import ConfirmDialog from './ConfirmDialog';
import Autocomplete, { IOption } from './Autocomplete';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import * as vaccinationService from '../services/vaccinationService';
import { getYesNoOptions } from '../utils';
import AutocompleteFlag, { ICountryItem } from '../components/AutocompleteFlag';
import { VaccinationRegistrationRequest } from '../models/VaccinationModels';
import countryCodes from '../assets/json/callingCodes2.json';
import Dropdown from './Dropdown';
import { IUpdateRegistrationForVaccination } from '../interfaces/RegisterForVaccination';
import DatePicker from '../components/DatePicker';
import moment from 'moment';

interface IProps {
  person: VaccinationRegistrationRequest;
  updateInfo: (info: IUpdateRegistrationForVaccination) => Promise<void>;
}

const schema = Yup.object().shape({
  phoneNumber: Yup.string().required(),
  callingCode: Yup.object().required(),
  isPregnant: Yup.boolean().required(),
  location: Yup.object().required(),
  email: Yup.string().email(),
});

const EditWaitingListDetails: React.FC<IProps> = ({ person, updateInfo }) => {
  const [lang] = useContext(LangContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [callingCodes, setCallingCodes] = useState<ICountryItem[]>([]);
  const [locationsOptions, setLocationsOptions] = useState<IOption[]>([]);
  const sortedCountryCodes = countryCodes.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  useEffect(() => {
    const setDialCodes = () => {
      const callCodes: ICountryItem[] = sortedCountryCodes.map((c) => ({
        name: c.name + ` (+${c.dial_code})`,
        code: c.dial_code as string,
        status: 0,
        flag: c.code as string,
      }));
      setCallingCodes(callCodes);
    };

    setDialCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getVaccinationLocation = async () => {
      const res = await vaccinationService.getLocations();
      setLocationsOptions(
        res.data.map((x) => ({
          label: x.name,
          value: x.id.toString(),
        }))
      );
    };
    getVaccinationLocation();
  }, []);

  return (
    <React.Fragment>
      <Button
        onClick={() => setDialogOpen(true)}
        endIcon={<EditIcon />}
        variant="outlined"
        color="secondary"
      >
        {translations.edit[lang]}
      </Button>
      {renderDialog()}
    </React.Fragment>
  );

  function renderDialog() {
    return (
      <Formik
        onSubmit={(values) => {
          updateInfo({
            id: person.id,
            phoneCode: values.callingCode ? values.callingCode.code : '',
            phoneNumber: values.phoneNumber,
            isPregnant: values.isPregnant as boolean,
            vaccinationLocation: values.location ? values.location.value : '',
            email: values.email,
            comments: values.comments,
            dateOfCovidDiagnosis: values.dateOfCovidDiagnosis
              ? values.dateOfCovidDiagnosis.toISOString()
              : null,
            hasHadCovidDiseaseBefore: values.dateOfCovidDiagnosis
              ? true
              : false,
          });
          setDialogOpen(false);
        }}
        validationSchema={schema}
        enableReinitialize
        initialValues={{
          callingCode: callingCodes.find(
            (item) => item.code === person.phoneCode
          ) as ICountryItem | null,
          phoneNumber: person.phoneNumber,
          isPregnant: person.isPregnant,
          location: locationsOptions.find(
            (item) => item.value === person.vaccinationLocation.id.toString()
          ) as IOption | null,
          email: person.email,
          comments: person.comments,
          dateOfCovidDiagnosis: person.dateOfCovidDiagnosis
            ? moment(person.dateOfCovidDiagnosis)
            : null,
        }}
      >
        {({
          values,
          errors,
          submitCount,
          isSubmitting,
          dirty,
          handleChange,
          handleSubmit,
          handleBlur,
          handleReset,
          setFieldValue,
          isValid,
        }) => (
          <ConfirmDialog
            open={dialogOpen}
            title={translations.edit[lang]}
            loading={isSubmitting}
            disableConfirm={isSubmitting || !dirty || !isValid}
            onConfirm={handleSubmit}
            onCancel={() => {
              setDialogOpen(false);
              handleReset();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <AutocompleteFlag
                  fullWidth
                  id="callingCode"
                  required
                  value={values.callingCode}
                  label={translations.countryCode[lang]}
                  items={callingCodes || []}
                  onChange={(event, val) => {
                    handleChange(event);
                    setFieldValue('callingCode', val);
                  }}
                  error={!!errors.callingCode && !!submitCount}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  id="phoneNumber"
                  variant="outlined"
                  inputProps={{
                    type: 'text',
                    pattern: '[0-9+]*',
                    maxLength: 15,
                    form: { autoComplete: 'off' },
                  }}
                  label={translations.phoneNumber[lang]}
                  value={values.phoneNumber}
                  error={!!errors.phoneNumber && !!submitCount}
                  onChange={(e) =>
                    !e.target.validity.patternMismatch && handleChange(e)
                  }
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Dropdown
                  fullWidth
                  required
                  id="isPregnant"
                  value={getStringFromNullableBoolean(values.isPregnant)}
                  label={translations.pregnant[lang]}
                  items={getYesNoOptions(lang)}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue(
                      'isPregnant',
                      e.target.value === 'true' ? true : false
                    );
                  }}
                  error={!!errors.isPregnant && !!submitCount}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  fullWidth
                  required
                  id="location"
                  value={values.location}
                  label={translations.location[lang]}
                  items={locationsOptions || []}
                  onChange={(val) => setFieldValue('location', val)}
                  error={!!errors.location && !!submitCount}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="email"
                  variant="outlined"
                  label={translations.email[lang]}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.email && !!submitCount}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="comments"
                  variant="outlined"
                  label={translations.comments[lang]}
                  value={values.comments}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  id="dateOfCovidDiagnosis"
                  label={translations.dateOfCovidDiagnosisShort[lang]}
                  value={values.dateOfCovidDiagnosis}
                  onChange={(e) =>
                    setFieldValue('dateOfCovidDiagnosis', e ? moment(e) : null)
                  }
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
          </ConfirmDialog>
        )}
      </Formik>
    );
  }

  function getStringFromNullableBoolean(bool: boolean | null) {
    if (bool === null) {
      return '';
    }
    if (bool) {
      return 'true';
    } else {
      return 'false';
    }
  }
};

export default EditWaitingListDetails;
