import React, { useContext, useState } from 'react';

import Layout from '../components/Layout';

import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import useNotifier from '../hooks/useNotifier';
import { ExcelResidencePerson, ResidencePerson } from '../models/Models';
import { parseTemplateString, toISOStringTimezone } from '../utils';
import Section from '../components/Section';
import QuarantinePassengerListFilterOld, {
  IFormValues,
} from '../components/QuarantinePassengerListFilterOld';
import { TABLE_ROWS_DEFAULT } from '../constants';
import {
  getResidenceListBySearch,
  markResidencesAsExported,
} from '../services/httpService';
import { IGetResidenceListBySearchQuery } from '../interfaces/passenger';
import QuarantinePassengerList from '../components/QuarantinePassengerList';
import { Grid } from '@material-ui/core';
import Button from '../components/Button';
import theme from '../theme';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const QuarantinePassengerListOldPage: React.FC = () => {
  const { notifyError, notifySuccess } = useNotifier();
  const [lang] = useContext(LangContext);
  const history = useHistory();

  const [filteredPassengers, setFilteredPassengers] = useState<
    ResidencePerson[] | null
  >(null);
  const [checkedGroupMembers, setCheckedGroupMembers] = useState<
    ResidencePerson[] | null
  >(null);
  const [membersCheckbox, setMembersCheckbox] = useState<number[]>([]);
  const [, setSortedMembers] = useState<ResidencePerson[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [currentPage, setCurrentPage] = useState(0);
  const [tableRows, setTableRows] = useState(new Array<HTMLTableRowElement>());

  const tableColumns: {
    [key: string]: {
      translation: { is: string; en: string };
      sort?: (a: ResidencePerson, b: ResidencePerson) => number;
    };
  } = {
    name: {
      translation: translations.name,
      sort: (a: ResidencePerson, b: ResidencePerson) =>
        a.name.localeCompare(b.name),
    },
    ssn: {
      translation: translations.dateOfBirth,
    },
    nationality: {
      translation: translations.nationality,
      sort: (a: ResidencePerson, b: ResidencePerson) =>
        a.nationality.localeCompare(b.nationality),
    },
    countryOfDeparture: {
      translation: translations.countryOfDeparture,
      sort: (a: ResidencePerson, b: ResidencePerson) =>
        a.countryOfDeparture.localeCompare(b.countryOfDeparture),
    },
    flightNumber: {
      translation: translations.flightNumber,
      sort: (a: ResidencePerson, b: ResidencePerson) =>
        a.flightNumber.localeCompare(b.flightNumber),
    },
    arrivalDate: {
      translation: translations.arrivalDate,
      sort: (a: ResidencePerson, b: ResidencePerson) => {
        if (!a.dateOfArrival) {
          return -1;
        }
        if (!b.dateOfArrival) {
          return 1;
        }
        return moment(a.dateOfArrival).isAfter(b.dateOfArrival) ? 1 : -1;
      },
    },
  };

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  return (
    <Layout
      useDefaultSpacing
      title={translations.passengerList[lang]}
      backButton
      backButtonClick={() => history.push('/quarantine-house')}
    >
      <Section>
        {filteredPassengers && filteredPassengers.length > 0 && renderButtons()}
        <QuarantinePassengerListFilterOld onSubmit={onSearch} onClear={clear} />
        {filteredPassengers && (
          <QuarantinePassengerList
            members={filteredPassengers}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={setRowsPerPage}
            onChangeSelectedMembers={onChangeSelectedMembers}
            membersCheckbox={membersCheckbox}
            setMembersCheckbox={setMembersCheckbox}
            onSetSortedMembers={setSortedMembers}
            tableColumns={tableColumns}
            tableColumnsKeys={Object.keys(tableColumns).map(
              (columnKey) => columnKey
            )}
          />
        )}
      </Section>
    </Layout>
  );

  function renderButtons() {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}
        style={{ marginBottom: theme.spacing(2) }}
      >
        <Grid item>
          <Button
            padding={false}
            color="primary"
            onClick={() => markAsExported()}
          >
            {checkedGroupMembers && filteredPassengers
              ? parseTemplateString(
                  translations.markPartialListAsExported[lang],
                  checkedGroupMembers.length.toString(),
                  filteredPassengers.length.toString()
                )
              : translations.markListAsExported[lang]}
          </Button>

          <Button
            padding={false}
            color="primary"
            variant="outlined"
            onClick={() => {
              copyTable();
            }}
            style={{ marginLeft: theme.spacing(2) }}
          >
            {checkedGroupMembers && filteredPassengers
              ? parseTemplateString(
                  translations.copyPartialpersonsFromList[lang],
                  checkedGroupMembers.length.toString(),
                  filteredPassengers.length.toString()
                )
              : translations.copyEntireList[lang]}
          </Button>
        </Grid>
      </Grid>
    );
  }

  async function onSearch(values: IFormValues) {
    if (values.arrivalsFrom && values.arrivalsTo) {
      const query: IGetResidenceListBySearchQuery = {
        fromDate: toISOStringTimezone(values.arrivalsFrom),
        toDate: toISOStringTimezone(values.arrivalsTo),
        includeExported: !values.hidePreviusCopied,
        includeDeleted: false,
      };
      const response = await getResidenceListBySearch(query);
      if (response.isOk) {
        if (response.data) {
          setFilteredPassengers(response.data);
        } else {
          setFilteredPassengers([]);
        }
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      }
    }
  }

  function createRow(person: ResidencePerson) {
    const tr = document.createElement('tr');
    const excelPerson = new ExcelResidencePerson(person);
    const obj = { ...excelPerson } as any;
    Object.keys(obj).forEach((key) => {
      const td = document.createElement('td');
      td.innerText = obj[key]?.toString();
      tr.appendChild(td);
    });
    tableRows.push(tr);
  }

  function copyTable() {
    setTableRows(new Array<HTMLTableRowElement>());
    if (checkedGroupMembers && checkedGroupMembers?.length > 0) {
      checkedGroupMembers?.forEach(createRow);
    } else {
      filteredPassengers?.forEach(createRow);
    }
    const table = document.createElement('table');
    table.style.fontSize = '11pt';
    const body = document.createElement('body');
    tableRows?.forEach((row) => body.appendChild(row));
    table.appendChild(body);

    //Add table to DOM before copy
    document.body.appendChild(table);

    let range = document.createRange();
    let sel = window.getSelection();
    try {
      sel?.removeAllRanges();
      range.selectNodeContents(table);
      sel?.addRange(range);
      document.execCommand('copy');
      document.body.removeChild(table);
      notifySuccess(translations.listCopied[lang]);
    } catch (e) {
      notifyError(e);
    }
  }

  function onChangeSelectedMembers(memberlist: ResidencePerson[] | null) {
    setCheckedGroupMembers(memberlist);
  }
  function clear() {
    setFilteredPassengers(null);
    setSortedMembers([]);
    setCheckedGroupMembers(null);
    setMembersCheckbox([]);
  }

  async function markAsExported() {
    if (filteredPassengers) {
      let ids = [];
      if (membersCheckbox.length > 0) {
        ids = [...membersCheckbox];
      } else {
        ids = [...filteredPassengers].map((item) => item.id);
      }

      const response = await markResidencesAsExported(ids);
      if (response.isOk) {
        if (response.data) {
          notifySuccess(translations.markAsExportedSuccess[lang]);
          setFilteredPassengers(null);
          clear();
        }
      } else if (response.statusCode === 401) {
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    }
  }
};
export default QuarantinePassengerListOldPage;
