import React from 'react';
import cx from 'classnames';
import { makeStyles, Typography, Grid } from '@material-ui/core';

import Navbar from './Navbar';
import TitleArea, { IButton } from './TitleArea';
import LoadingIndicator from './LoadingIndicator';
import Section from './Section';

interface IProps {
  loading?: boolean;
  error?: string;
  loggedIn?: boolean;
  title?: string;
  subtitles?: string[];
  backButton?: boolean;
  backButtonClick?: () => void;
  otherButton?: IButton;
  contentClassName?: string;
  useDefaultSpacing?: boolean;
  showQuestAirlineSwitch?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
  },
  content: {
    flex: 1,
  },
  defaultSpacing: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(0, 2),
    },
  },
  loadingContainer: {
    height: `calc(100vh - ${theme.dimensions.navbarHeight}px)`,
  },
}));

const Layout: React.FC<IProps> = ({
  children,
  loading,
  error,
  loggedIn = true,
  title,
  subtitles,
  backButton,
  backButtonClick,
  otherButton,
  contentClassName,
  useDefaultSpacing,
  showQuestAirlineSwitch = false,
}) => {
  const classes = useStyles({});
  const isLoading = loading && !error;

  return (
    <div className={classes.root}>
      <Navbar loggedIn={loggedIn} />
      <section
        className={cx(
          classes.content,
          { [classes.defaultSpacing]: useDefaultSpacing && !isLoading },
          contentClassName
        )}
      >
        {renderContent()}
      </section>
    </div>
  );

  function renderContent() {
    if (error) {
      return (
        <Section>
          <Typography variant="h2">{error}</Typography>
        </Section>
      );
    }

    if (loading) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.loadingContainer}
        >
          <LoadingIndicator />
        </Grid>
      );
    }

    return (
      <React.Fragment>
        {title && (
          <TitleArea
            title={title}
            subtitles={subtitles}
            backButton={backButton}
            backButtonClick={backButtonClick}
            otherButton={otherButton}
            showQuestAirlineSwitch={showQuestAirlineSwitch}
          />
        )}
        {children}
      </React.Fragment>
    );
  }
};

export default Layout;
