import React, {
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  makeStyles,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  useTheme,
} from '@material-ui/core';
import debounce from 'lodash.debounce';

import Button from './Button';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { VaccinationPerson } from '../models/VaccinationModels';

interface IProps {
  members: VaccinationPerson[];
  selectedMember: VaccinationPerson | null;
  filterMembers: (members: VaccinationPerson[]) => void;
  setSelectedMember: (member: VaccinationPerson) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  checkContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  check: {
    marginRight: theme.spacing(4),
  },
}));

const GroupVaccinationMemberFilter: React.FC<IProps> = ({
  members,
  selectedMember,
  filterMembers,
  setSelectedMember,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [lang] = useContext(LangContext);
  const serialNumberRef = useRef<HTMLInputElement>();

  const [serialNumberInput, setSerialNumberInput] = useState('');
  const [nameInput, setNameInput] = useState('');
  const [nameInputFilter, setNameInputFilter] = useState('');
  const [hideFirstVaccination, setHideFirstVaccination] = useState(false);
  const [hideSecondVaccination, setHideSecondVaccination] = useState(false);
  const debouncedNameUpdate = useCallback(
    debounce(setNameInputFilter, 500),
    []
  );

  useEffect(() => {
    const filtered = members.filter(
      (member) =>
        filterBySerialNumber(member.serialNumber, serialNumberInput) &&
        filterByName(member.name, nameInputFilter) &&
        (!hideFirstVaccination ||
          !member.firstVaccination.vaccinationLocationName) &&
        (!hideSecondVaccination || !member.secondVaccination.vaccinationDate)
    );
    filterMembers(filtered);

    if (
      filtered.length === 1 &&
      serialNumberInput === filtered[0].serialNumber
    ) {
      setSelectedMember(filtered[0]);
    }
  }, [
    members,
    filterMembers,
    setSelectedMember,
    serialNumberInput,
    nameInputFilter,
    hideFirstVaccination,
    hideSecondVaccination,
  ]);

  useEffect(() => {
    const focusInput = () => {
      if (serialNumberRef && serialNumberRef.current) {
        serialNumberRef.current.focus();
      }
    };

    if (!selectedMember) {
      setSerialNumberInput('');
      focusInput();
    }
  }, [selectedMember]);

  return (
    <Grid>
      <Paper elevation={2} className={classes.container}>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ marginBottom: theme.spacing(1) }}
        >
          <Grid item>
            <Typography variant="h6">
              {translations.filterHeader[lang]}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {renderFilterInputs()}
          {renderFilterActions()}
        </Grid>
      </Paper>
    </Grid>
  );

  function renderFilterInputs() {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            autoFocus
            autoComplete="off"
            id="serialNumber"
            variant="outlined"
            inputRef={serialNumberRef}
            label={translations.serialNumber[lang]}
            value={serialNumberInput}
            onChange={(e) => setSerialNumberInput(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            autoComplete="off"
            id="name"
            variant="outlined"
            label={translations.name[lang]}
            value={nameInput}
            onChange={(e) => {
              setNameInput(e.target.value);
              debouncedNameUpdate(e.target.value);
            }}
          />
        </Grid>
      </React.Fragment>
    );
  }

  function renderFilterActions() {
    return (
      <Grid item xs={12} md={12}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <div className={classes.checkContainer}>
            <FormControlLabel
              className={classes.check}
              label={translations.hideFirstVaccination[lang]}
              control={
                <Checkbox
                  id="firstVaccination"
                  color="primary"
                  checked={hideFirstVaccination}
                  onChange={() =>
                    setHideFirstVaccination(!hideFirstVaccination)
                  }
                />
              }
            />
            <FormControlLabel
              className={classes.check}
              label={translations.hideSecondVaccination[lang]}
              control={
                <Checkbox
                  id="secondVaccination"
                  color="primary"
                  checked={hideSecondVaccination}
                  onChange={() =>
                    setHideSecondVaccination(!hideSecondVaccination)
                  }
                />
              }
            />
          </div>
          <Button
            variant="outlined"
            onClick={clearFilters}
            style={{ marginLeft: 'auto' }}
          >
            {translations.clear[lang]}
          </Button>
        </Grid>
      </Grid>
    );
  }

  function clearFilters() {
    setSerialNumberInput('');
    setNameInput('');
    setNameInputFilter('');
    setHideFirstVaccination(false);
    setHideSecondVaccination(false);
    focusSerialNumberInput();
  }

  function focusSerialNumberInput() {
    if (serialNumberRef && serialNumberRef.current) {
      serialNumberRef.current.focus();
    }
  }
};

function filterBySerialNumber(serialNumber: string, query: string) {
  return !query || serialNumber === query;
}

function filterByName(fullName: string, query: string) {
  const names = fullName.toLowerCase().split(' ');
  const queryLowerCase = query.toLowerCase();
  names.push(fullName.toLowerCase());

  for (const name of names) {
    if (name.startsWith(queryLowerCase)) {
      return true;
    }
  }
  return false;
}

export default GroupVaccinationMemberFilter;
