import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  Grid,
  TextField,
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button as MuiButton,
  ButtonGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import * as Yup from 'yup';
import { KeyboardTimePicker } from '@material-ui/pickers';

import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { Form, Formik, FormikProps } from 'formik';

import Button from './Button';
import { Moment } from 'moment';
import DatePicker from './DatePicker';
import moment from 'moment';
import { DATE_AND_TIME_FORMAT, DATE_FORMAT } from '../constants';
import { IVaccinationGroupDetails } from '../interfaces/vaccinationGroup';
import {
  IVaccinationSummonAcrossGroups,
  IVaccinationSummonGroup,
} from '../interfaces/vaccinationSummonGroup';
import * as vaccinationService from '../services/vaccinationService';
import useNotifier from '../hooks/useNotifier';
import { useHistory } from 'react-router-dom';
import theme from '../theme';
import {
  VaccinationLocation,
  VaccinationPerson,
} from '../models/VaccinationModels';
import Dropdown from './Dropdown';
import { capitalize, toISOStringTimezone } from '../utils';

interface IProps {
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  group: IVaccinationGroupDetails | null;
  groupMembers: VaccinationPerson[] | null;
  selectedMembers: VaccinationPerson[] | null;
  loactions: VaccinationLocation[] | null;
}

export interface IDialogFormValues {
  summoningDate: Moment | null;
  summoningTime: Moment | null;
  includeSummoningdate: boolean;
  slotLenght: string;
  slotCapacity: string;
  locationOption: string;
  locationDetails: string;
  customText: string;
}

type IFormik = FormikProps<IDialogFormValues>;
type ISummoningType = 'details' | 'schedule';

const useStyles = makeStyles(() => ({
  descriptionTitle: {
    display: 'inline-block',
    fontWeight: 'bold',
    paddingRight: 10,
  },
  descriptionDetails: {
    display: 'inline-block',
  },
  table: {
    maxHeight: 400,
    '& th': {
      fontWeight: 600,
      fontSize: 15,
      paddingTop: 0,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  tabs: {
    width: '100%',
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const SendVaccinationGroupScheduleDialog: React.FC<IProps> = ({
  open,
  onCancel,
  onSuccess,
  group,
  groupMembers,
  selectedMembers,
  loactions,
}) => {
  const { notifyError, notifySuccess } = useNotifier();
  const [lang] = useContext(LangContext);
  const history = useHistory();
  const classes = useStyles();
  const idInput = useRef<HTMLInputElement>(null);
  const [disableConfirm, setDisableConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [summoningType, setSummoningType] = useState<ISummoningType>('details');
  const [locationOptions, setLocationOptions] = useState<
    VaccinationLocation[] | null
  >(null);
  const [summoningPersons, setSummoningPersons] = useState<VaccinationPerson[]>(
    groupMembers || []
  );

  const isSummoningDetails = summoningType === 'details';

  useEffect(() => {
    if (!open) {
      setDisableConfirm(false);
    }
    if (open) {
      setTimeout(() => {
        focusidInput();
      }, 200);
    }
  }, [open]);

  useEffect(() => {
    if (selectedMembers) {
      setSummoningPersons(selectedMembers);
    }
  }, [selectedMembers]);

  useEffect(() => {
    if (groupMembers) {
      setSummoningPersons(groupMembers);
    }
  }, [groupMembers]);

  useEffect(() => {
    if (loactions) {
      setLocationOptions(loactions);
    }
  }, [loactions]);

  const schema = Yup.object().shape({
    summoningDate: Yup.mixed().test(
      'valid-date',
      'Please enter a valid date',
      (val) => {
        if (val === null) {
          return true;
        }
        return moment(val, 'HH:mm').isValid();
      }
    ),

    summoningTime: Yup.mixed().test(
      'valid-date',
      'Please enter a valid date',
      (val) => {
        if (val === null) {
          return true;
        }
        return moment(val, 'HH:mm').isValid();
      }
    ),
    locationOption: Yup.string().required(),
  });

  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <Formik
        onSubmit={(values) => onSubmit(values)}
        validationSchema={schema}
        initialValues={getInitalValues()}
        enableReinitialize
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <DialogTitle>
              {!submitFlag
                ? translations.summonGroupForVaccination[lang]
                : translations.confirmSummon[lang]}
            </DialogTitle>
            <DialogContent style={{ minHeight: 80 }} dividers>
              <Grid container spacing={2}>
                {submitFlag && (
                  <React.Fragment>
                    <ButtonGroup
                      disableElevation
                      color="primary"
                      className={classes.tabs}
                    >
                      <MuiButton
                        style={{ flex: 1 }}
                        variant={isSummoningDetails ? 'contained' : 'outlined'}
                        onClick={() => setSummoningType('details')}
                      >
                        {translations.aboutSummoning[lang]}
                      </MuiButton>
                      <MuiButton
                        style={{ flex: 1 }}
                        variant={isSummoningDetails ? 'outlined' : 'contained'}
                        onClick={() => setSummoningType('schedule')}
                      >
                        {translations.summoningSchedule[lang]}
                      </MuiButton>
                    </ButtonGroup>
                    {isSummoningDetails &&
                      renderSummary(createSummary(formik.values))}
                    {!isSummoningDetails &&
                      renderSummoningSchedule(formik.values)}
                  </React.Fragment>
                )}
                {!submitFlag && renderFormFields(formik)}
              </Grid>
            </DialogContent>
            <DialogActions>
              {loading && <CircularProgress size={32} color="primary" />}
              {renderButtons()}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );

  function close() {
    setSubmitFlag(false);
    onCancel();
  }

  function renderFormFields({
    values,
    errors,
    submitCount,
    handleChange,
    handleBlur,
    setFieldValue,
  }: IFormik) {
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <DatePicker
            required={!values.includeSummoningdate}
            autoFocus
            id="summoningDate"
            label={translations.dateOfVaccination[lang]}
            value={values.summoningDate}
            muiProps={{ disablePast: true }}
            onChange={(e) =>
              setFieldValue('summoningDate', e ? moment(e) : null)
            }
            onBlur={handleBlur}
            inputProps={{ ref: idInput }}
            error={!!errors.summoningDate && !!submitCount}
            disabled={!!values.includeSummoningdate}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyboardTimePicker
            required={!values.includeSummoningdate}
            fullWidth
            autoComplete="off"
            id="summoningTime"
            label={translations.timeOfVaccination[lang]}
            variant="inline"
            ampm={false}
            inputVariant="outlined"
            value={values.summoningTime}
            mask="__:__"
            placeholder="__:__"
            helperText={''}
            onChange={(e) =>
              setFieldValue('summoningTime', e ? moment(e) : null)
            }
            onBlur={handleBlur}
            error={!!errors.summoningTime && !!submitCount}
            disabled={!!values.includeSummoningdate}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            autoComplete="off"
            id="slotLenght"
            label={translations.slotDuration[lang]}
            variant="outlined"
            inputProps={{
              type: 'text',
              pattern: '[0-9+]*',
            }}
            value={values.slotLenght}
            onChange={(e) => e.target.validity.valid && handleChange(e)}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            autoComplete="off"
            id="slotCapacity"
            label={translations.numberOfPersonsPerSlot[lang]}
            variant="outlined"
            inputProps={{
              type: 'text',
              pattern: '[0-9+]*',
            }}
            value={values.slotCapacity}
            onChange={(e) => e.target.validity.valid && handleChange(e)}
            onBlur={handleBlur}
          />
        </Grid>
        {locationOptions && (
          <Grid item xs={12}>
            <Dropdown
              fullWidth
              required
              id="locationOption"
              value={values.locationOption}
              label={translations.location[lang]}
              items={locationOptions.map((opt) => ({
                value: opt.id.toString(),
                label: opt.name,
              }))}
              onChange={handleChange}
              //disabled={!locations || locations.length === 1}
              error={!!errors.locationOption && !!submitCount}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoComplete="off"
            id="locationDetails"
            label={translations.moreAboutLocation[lang]}
            variant="outlined"
            value={values.locationDetails}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            autoComplete="off"
            id="customText"
            label={translations.messageToRecipients[lang]}
            variant="outlined"
            value={values.customText}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            style={{ marginRight: theme.spacing(4) }}
            label={translations.sendSummoningWithoutDateAndTime[lang]}
            control={
              <Checkbox
                id="includeSummoningdate"
                color="primary"
                checked={values.includeSummoningdate}
                onChange={(e) =>
                  onChangeIncludeSummoningdate(
                    e.target.checked as boolean,
                    setFieldValue
                  )
                }
              />
            }
          />
        </Grid>
        {showSummaryInForm(values) &&
          renderSummary([
            {
              label: translations.vaccinationEnds[lang],
              value: values.includeSummoningdate
                ? calculateSummoningEndDate(
                    getDateTime(values.summoningDate, values.summoningTime),
                    values.slotLenght,
                    values.slotCapacity
                  )
                : '',
            },
          ])}
      </React.Fragment>
    );
  }

  function renderButtons() {
    if (submitFlag) {
      return (
        <React.Fragment>
          <Button
            variant="contained"
            padding={false}
            type="submit"
            disabled={disableConfirm}
          >
            {translations.confirm[lang]}
          </Button>

          <Button
            onClick={() => setSubmitFlag(false)}
            variant="outlined"
            padding={false}
          >
            {translations.back[lang]}
          </Button>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Button variant="contained" padding={false} type="submit">
          {translations.continue[lang]}
        </Button>

        <Button onClick={close} variant="outlined" padding={false}>
          {translations.cancel[lang]}
        </Button>
      </React.Fragment>
    );
  }

  function renderSummary(summary: any) {
    return (
      <React.Fragment>
        {summary.map((s: any, index: number) => (
          <Grid item xs={12} key={index}>
            <Typography className={classes.descriptionTitle}>
              {s.label}:
            </Typography>

            <Typography
              className={classes.descriptionDetails}
              color="textSecondary"
            >
              {s.value}
            </Typography>
          </Grid>
        ))}
      </React.Fragment>
    );
  }

  function createSummary(values: IDialogFormValues) {
    const dateInForm = !values.includeSummoningdate;
    const summary = [
      {
        label: translations.numberOfPersonsInSummoning[lang],
        value: summoningPersons.length.toString(),
      },
      {
        label: translations.vaccinationBegins[lang],
        value: dateInForm
          ? moment(
              getDateTime(values.summoningDate, values.summoningTime)
            ).format(DATE_AND_TIME_FORMAT)
          : '',
      },
      {
        label: translations.vaccinationEnds[lang],
        value: dateInForm
          ? calculateSummoningEndDate(
              getDateTime(values.summoningDate, values.summoningTime),
              values.slotLenght,
              values.slotCapacity
            )
          : '',
      },
      {
        label: translations.location[lang],
        value: `${getLocationOptionName(values.locationOption)}. ${capitalize(
          values.locationDetails
        )}`,
      },
      {
        label: translations.messageToRecipients[lang],
        value: values.customText,
      },
      {
        label: translations.numberOfPersonsPerSlot[lang],
        value: values.slotCapacity,
      },
      {
        label: translations.slotDuration[lang],
        value: values.slotLenght,
      },
      {
        label: translations.textToRecipients[lang],
        value: `Sóttvarnalæknir: [NAFN_MÓTTAKANDA] hér er strikamerki fyrir bólusetningu${
          dateInForm
            ? ' ' +
              moment(values.summoningDate).format(DATE_FORMAT) +
              ' klukkan [TÍMI].'
            : '.'
        }  ${getSmsText(values)}`,
      },
    ];
    if (group) {
      summary.unshift({
        label: translations.name[lang],
        value: group ? group.name : '',
      });
    }
    return summary;
  }

  function getDateTime(dateValue: Moment | null, timeValue: Moment | null) {
    var date = moment(dateValue);
    var time = moment(timeValue, 'HH:mm');
    date = date.hour(time.get('hour'));
    date = date.minute(time.get('minutes'));
    return date;
  }

  function onSubmit(values: IDialogFormValues) {
    const hasSummoningDate = !values.includeSummoningdate;
    if (submitFlag && group) {
      const summonGroup: IVaccinationSummonGroup = {
        groupId: parseInt(group.id),
        smsText: getSmsText(values),
        heilsuveraText: getSmsText(values),
        summoningDate: hasSummoningDate
          ? toISOStringTimezone(
              getDateTime(values.summoningDate, values.summoningTime)
            )
          : undefined,
        slotLenght: values.slotLenght ? parseInt(values.slotLenght) : 0,
        slotCapacity: values.slotCapacity ? parseInt(values.slotCapacity) : 1,
        isFirstVaccinationSummoning: true,
        selectedPersonIds: selectedMembers
          ? selectedMembers.map((m) => m.vaccinationPersonId as number)
          : undefined,
        locationId: parseInt(values.locationOption),
      };
      setLoading(true);
      setDisableConfirm(true);
      onSummonGroup(summonGroup);
    } else if (submitFlag) {
      const summonList: IVaccinationSummonAcrossGroups = {
        smsText: getSmsText(values),
        heilsuveraText: getSmsText(values),
        summoningDate: hasSummoningDate
          ? toISOStringTimezone(
              getDateTime(values.summoningDate, values.summoningTime)
            )
          : undefined,
        slotLenght: values.slotLenght ? parseInt(values.slotLenght) : 0,
        slotCapacity: values.slotCapacity ? parseInt(values.slotCapacity) : 1,
        isFirstVaccinationSummoning: true,
        selectedPersonIds: selectedMembers
          ? selectedMembers.map((m) => m.vaccinationPersonId as number)
          : groupMembers
          ? groupMembers.map((m) => m.vaccinationPersonId as number)
          : [],
        locationId: parseInt(values.locationOption),
      };
      setLoading(true);
      setDisableConfirm(true);
      onSummonAcrossGroups(summonList);
    } else {
      setSubmitFlag(true);
    }
  }

  function onSummonGroup(group: IVaccinationSummonGroup) {
    vaccinationService
      .summonAGroup(group)
      .then((response) => {
        setLoading(false);
        setDisableConfirm(false);
        if (response.isOk) {
          notifySuccess(translations.selectionHasBeenSummoned[lang]);
          close();
          onSuccess();
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          notifyError(translations.errorOccurred[lang]);
        }
      })
      .catch(() => {
        setLoading(false);
        setDisableConfirm(false);
        notifyError(translations.errorOccurred[lang]);
      });
  }

  function onSummonAcrossGroups(list: IVaccinationSummonAcrossGroups) {
    vaccinationService
      .summonAcrossGroups(list)
      .then((response) => {
        setLoading(false);
        setDisableConfirm(false);
        if (response.isOk) {
          notifySuccess(translations.selectionHasBeenSummoned[lang]);
          close();
          onSuccess();
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          notifyError(translations.errorOccurred[lang]);
        }
      })
      .catch(() => {
        setLoading(false);
        setDisableConfirm(false);
        notifyError(translations.errorOccurred[lang]);
      });
  }

  function renderSummoningSchedule(values: IDialogFormValues) {
    return (
      <Grid item xs={12}>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.name[lang]}</TableCell>
                <TableCell align="center">{translations.SSN[lang]}</TableCell>
                <TableCell align="center">
                  {translations.summoning[lang]}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {summoningPersons.map((member, index) => (
                <TableRow hover key={member.ssn}>
                  <TableCell>{member.name}</TableCell>
                  <TableCell align="center">{member.ssn}</TableCell>
                  <TableCell align="center">
                    {!values.includeSummoningdate
                      ? calculateSummoningScheduleDate(
                          getDateTime(
                            values.summoningDate,
                            values.summoningTime
                          ),
                          values.slotLenght,
                          values.slotCapacity,
                          index
                        )
                      : ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  }

  function getInitalValues() {
    return {
      summoningDate: null as Moment | null,
      summoningTime: null as Moment | null,
      includeSummoningdate: false,
      slotLenght: '',
      slotCapacity: '',
      locationOption:
        (locationOptions?.length === 1 && locationOptions[0].id.toString()) ||
        '',
      locationDetails: '',
      customText: '',
    };
  }

  function focusidInput() {
    if (idInput && idInput.current) {
      idInput.current.focus();
    }
  }

  function calculateSummoningEndDate(
    startDate: Moment,
    slotDuration: string,
    slotCapacity: string
  ) {
    if (slotCapacity && slotDuration) {
      const groupCount = summoningPersons.length;
      const slotCount = groupCount / parseFloat(slotCapacity);
      const addMinutes = Math.ceil(slotCount) * parseInt(slotDuration);
      return moment(startDate)
        .add(addMinutes, 'minutes')
        .format(DATE_AND_TIME_FORMAT);
    }
    return translations.vaccinationEndsNotDefined[lang];
  }

  function calculateSummoningScheduleDate(
    startDate: Moment,
    slotDuration: string,
    slotCapacity: string,
    index: number
  ) {
    const capacity = parseInt(slotCapacity);
    const duration = parseInt(slotDuration);

    if (slotCapacity && slotDuration) {
      const addMinutes = Math.floor(index / capacity) * duration;
      return moment(startDate)
        .add(addMinutes, 'minutes')
        .format(DATE_AND_TIME_FORMAT);
    }
    return translations.vaccinationEndsNotDefined[lang];
  }

  function showSummaryInForm(values: IDialogFormValues) {
    if (
      values.slotCapacity &&
      values.slotLenght &&
      values.summoningDate &&
      values.summoningTime &&
      groupMembers
    ) {
      return true;
    }
    return false;
  }

  function getLocationOptionName(locationOption: string) {
    if (locationOption) {
      const selectedLocation = locationOptions?.find(
        (l) => l.id === parseInt(locationOption)
      );
      if (selectedLocation) {
        return selectedLocation.name;
      }
    }
    return '';
  }

  function getSmsText(values: IDialogFormValues) {
    return `Staðsetning: ${getLocationOptionName(values.locationOption)}. ${
      values.locationDetails && capitalize(values.locationDetails) + '.'
    } ${capitalize(values.customText)}`;
  }

  function onChangeIncludeSummoningdate(val: boolean, setFieldValue: any) {
    setFieldValue('includeSummoningdate', val);
    if (val) {
      setFieldValue('summoningDate', null);
      setFieldValue('summoningTime', null);
    }
  }
};

export default SendVaccinationGroupScheduleDialog;
