import React, { useContext, useState, useRef } from 'react';
import {
  TextField,
  makeStyles,
  useTheme,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Error';
import ErrorIcon from '@material-ui/icons/AddCircle';
import LinearProgress from '@material-ui/core/LinearProgress';

import ConfirmDialog from './ConfirmDialog';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import * as httpService from '../services/httpService';
import { Passenger } from '../models/Models';
import {
  IQrCodeCertificate,
  IVaccination,
  IValidationError,
} from '../interfaces/qrCertificate';
import useNotifier from '../hooks/useNotifier';
import { DATE_FORMAT } from '../constants';
import { actionStatuses, qrCodeErrorSeverity } from '../constants/enums';
import { UserContext } from '../context/UserContext';
import { isNotPartialCertificateLocation } from '../utils';

interface IProps {
  open: boolean;
  passenger: Passenger;
  onCancel: () => void;
  onAcceptCertificate: (certificate: IQrCodeCertificate) => Promise<void>;
  onAcceptPartialCertificate: (passenger: Passenger) => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  hideInput: {
    opacity: 0,
    height: 0,
  },
  infoLabel: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  infoValue: {
    color: theme.palette.text.secondary,
  },
  table: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderBottomWidth: 0,
    borderRadius: 4,
    marginTop: theme.spacing(2),
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  icon: {
    position: 'absolute',
    top: '-1px',
  },
  errors: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const QRCodeDialog: React.FC<IProps> = ({
  open,
  passenger,
  onCancel,
  onAcceptCertificate,
  onAcceptPartialCertificate,
}) => {
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { notifyError } = useNotifier();
  const qrInput = useRef<HTMLInputElement>(null);

  const [qrCodeInput, setQrCodeInput] = useState('');
  const [verifyingQrCode, setVerifyingQrCode] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [certificate, setCertificate] =
    useState<IQrCodeCertificate | null>(null);

  let hasError = getErrorStatus();

  return (
    <>
      <ConfirmDialog
        open={open}
        loading={loadingAccept}
        maxWidth={certificate ? 'md' : 'sm'}
        title={
          certificate
            ? translations.certificate[lang]
            : translations.qrCode[lang]
        }
        text={
          !certificate
            ? verifyingQrCode
              ? translations.processingQrCode[lang]
              : translations.scanQrCodeDialogText[lang]
            : undefined
        }
        confirmText={translations.acceptQrCode[lang]}
        onConfirm={certificate ? acceptCertificate : undefined}
        cancelText={translations.close[lang]}
        onCancel={onCancel}
        leftButtonText={translations.clear[lang]}
        onLeftButtonClick={clearDialog}
        disableConfirm={hasError}
        extraButtonText={
          showPartialCertificateButton()
            ? translations.confirmBySampling[lang]
            : undefined
        }
        onExtraButtonClick={
          showPartialCertificateButton()
            ? () => acceptPartialCertificate()
            : undefined
        }
      >
        {certificate ? renderCertificate() : renderQrInput()}
      </ConfirmDialog>
    </>
  );

  function renderCertificate() {
    if (!certificate) {
      return null;
    }

    const certificateName = `${certificate.certificate.subject.firstName} ${certificate.certificate.subject.lastName}`;
    const registeredName = `${passenger.firstName} ${passenger.lastName}`;
    const certificateDateOfBirth = moment(
      certificate.certificate.subject.dateOfBirth
    );
    const registeredDateOfBirth = moment(passenger.dateOfBirth);

    return (
      <Grid container spacing={2} style={{ marginBottom: theme.spacing(1) }}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              {renderInfoItem(
                translations.certificateSystem[lang],
                certificate.certifcateSystem
              )}
            </Grid>
            <Grid item>
              {renderInfoItem(
                translations.certificateType[lang],
                certificate.certificateType
              )}
            </Grid>
            <Grid item>
              {renderInfoItem(
                translations.issuer[lang],
                `${certificate.certificate.issuerInfo.name} (${certificate.certificate.issuerInfo.country})`
              )}
            </Grid>
            <Grid item>
              {renderInfoItem(
                translations.validBetween[lang],
                `${moment(certificate.certificate.issueAt).format(
                  DATE_FORMAT
                )} - ${moment(certificate.certificate.expires).format(
                  DATE_FORMAT
                )}`
              )}
            </Grid>
            {certificate.certificate.pcrNegativeTest && (
              <>
                <Grid item>
                  {renderInfoItem(
                    translations.pcrNegativeTestType[lang],
                    certificate.certificate.pcrNegativeTest.testType
                  )}
                </Grid>
                <Grid item>
                  {renderInfoItem(
                    translations.pcrNegativeTestResult[lang],
                    `${
                      certificate.certificate.pcrNegativeTest.result
                    } (${moment(
                      certificate.certificate.pcrNegativeTest.dateOfTest
                    ).format(DATE_FORMAT)})`
                  )}
                </Grid>
              </>
            )}
            {certificate.certificate.antibodiesTest && (
              <Grid item>
                {renderInfoItem(
                  translations.antibodiesTest[lang],
                  `${certificate.certificate.antibodiesTest.testType} (${moment(
                    certificate.certificate.antibodiesTest.dateOfTest
                  ).format(DATE_FORMAT)})`
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              {renderInfoItem(
                translations.nameOnCertificate[lang],
                certificateName
              )}
            </Grid>
            <Grid item>
              {renderInfoItem(
                translations.registeredName[lang],
                registeredName
              )}
            </Grid>
            <Grid item>
              {renderInfoItem(
                translations.dateOfBirthOnCertificate[lang],
                certificateDateOfBirth.format(DATE_FORMAT)
              )}
            </Grid>
            <Grid item>
              {renderInfoItem(
                translations.registeredDateOfBirth[lang],
                registeredDateOfBirth.format(DATE_FORMAT)
              )}
            </Grid>
          </Grid>
        </Grid>
        {!!certificate.certificate.vaccinations?.length && (
          <Grid item xs={12}>
            {renderVaccinations(certificate.certificate.vaccinations)}
          </Grid>
        )}
        {!!certificate.certificate.validationErrors?.length && (
          <Grid item xs={12}>
            <div className={classes.errors}>
              <Grid container spacing={2} direction="column">
                {certificate.certificate.validationErrors
                  .sort((a, b) => a.severity - b.severity)
                  .map((error, index) => (
                    <Grid item key={index}>
                      {renderErrorItem(error)}
                    </Grid>
                  ))}
              </Grid>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }

  function renderInfoItem(
    label: string,
    value: string,
    status?: 'success' | 'warning'
  ) {
    return (
      <Typography style={{ position: 'relative' }}>
        {status === 'success' ? (
          <SuccessIcon
            className={classes.icon}
            style={{ color: theme.palette.success.main }}
          />
        ) : status === 'warning' ? (
          <WarningIcon
            className={classes.icon}
            style={{ color: theme.palette.warning.light }}
          />
        ) : null}
        <span
          className={classes.infoLabel}
          style={{ marginLeft: status ? 30 : 0 }}
        >
          {label}:
        </span>
        <span className={classes.infoValue}>{value}</span>
      </Typography>
    );
  }

  function renderErrorItem(error: IValidationError) {
    return (
      <Typography style={{ position: 'relative' }} color="textPrimary">
        {error.severity === qrCodeErrorSeverity.ERROR ||
        error.severity === qrCodeErrorSeverity.INVALID ? (
          <ErrorIcon
            className={classes.icon}
            style={{
              color: theme.palette.error.main,
              transform: 'rotate(45deg)',
            }}
          />
        ) : error.severity === qrCodeErrorSeverity.WARNING ? (
          <WarningIcon
            className={classes.icon}
            style={{ color: theme.palette.warning.light }}
          />
        ) : (
          <InfoIcon
            className={classes.icon}
            style={{ color: theme.palette.primary.main }}
          />
        )}
        <span style={{ marginLeft: 35 }}>{error.message}</span>
      </Typography>
    );
  }

  function renderVaccinations(vaccinations: IVaccination[]) {
    return (
      <>
        <Typography className={classes.infoLabel}>
          {translations.vaccinations[lang]}:
        </Typography>
        <TableContainer className={classes.table}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{translations.vaccine[lang]}</TableCell>
                <TableCell>{translations.manufacturer[lang]}</TableCell>
                <TableCell>{translations.doses[lang]}</TableCell>
                <TableCell>{translations.date[lang]}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vaccinations.map((vaccination, index) => (
                <TableRow key={index}>
                  <TableCell>{vaccination.vaccineName}</TableCell>
                  <TableCell>{vaccination.manufacturer}</TableCell>
                  <TableCell>{vaccination.doseNumber}</TableCell>
                  <TableCell>
                    {moment(vaccination.vaccinationDate).format(DATE_FORMAT)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  function renderQrInput() {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          verifyQrCode();
        }}
      >
        <TextField
          autoFocus
          fullWidth
          id="qrCode"
          autoComplete="off"
          variant="outlined"
          value={qrCodeInput}
          label={translations.qrCode[lang]}
          className={classes.hideInput}
          inputProps={{ ref: qrInput }}
          onChange={(e) => {
            !verifyingQrCode && setVerifyingQrCode(true);
            setQrCodeInput(e.target.value);
          }}
          onBlur={focusQrInput}
        />
        {verifyingQrCode && <LinearProgress color="secondary" />}
      </form>
    );
  }

  function showPartialCertificateButton() {
    if (hasError) {
      const daysError =
        certificate?.certificate?.validationErrors &&
        certificate?.certificate?.validationErrors.find(
          (error) =>
            error.message === '14 days required from the last vaccine date'
        );

      if (daysError) {
        return true;
      }
    }

    return false;
  }

  function clearDialog() {
    setQrCodeInput('');
    setCertificate(null);
    setVerifyingQrCode(false);
    setLoadingAccept(false);
  }

  function focusQrInput() {
    if (qrInput && qrInput.current) {
      qrInput.current.focus();
    }
  }

  function getErrorStatus() {
    let certificateError = false;
    if (certificate?.certificate.validationErrors) {
      certificate.certificate.validationErrors.forEach((error) => {
        if (
          error.severity === qrCodeErrorSeverity.ERROR ||
          error.severity === qrCodeErrorSeverity.INVALID
        ) {
          certificateError = true;
        }
      });
    }
    return certificateError;
  }

  async function verifyQrCode() {
    const qrCodeVal = btoa(qrCodeInput);
    setQrCodeInput('');
    setVerifyingQrCode(true);
    if (passenger?.flowStatusesActive?.id === actionStatuses.NEG_PCR) {
      await verifyQrPCRCertificate(qrCodeVal);
    } else {
      await verifyQrCodeCertificate(qrCodeVal);
    }
  }

  async function verifyQrCodeCertificate(qrCodeVal: string) {
    const response = await httpService.verifyQrCodeCertificate(
      passenger.serialNumber,
      qrCodeVal
    );

    if (response.isOk) {
      if (isNotPartialCertificateLocation(user) && response.data) {
        const validationErrors =
          response.data?.certificate.validationErrors &&
          response.data.certificate.validationErrors.filter(
            (error) =>
              error.message !== '14 days required from the last vaccine date'
          );
        let newCertificate = { ...response.data };
        newCertificate.certificate.validationErrors = validationErrors;
        setCertificate(newCertificate);
        setVerifyingQrCode(false);
      } else {
        setCertificate(response.data);
        setVerifyingQrCode(false);
      }
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.unableToProcessQrCode[lang]);
      setCertificate(null);
      setVerifyingQrCode(false);
    }
  }

  async function verifyQrPCRCertificate(qrCodeVal: string) {
    const response = await httpService.verifyQrPCRCertificate(
      passenger.serialNumber,
      qrCodeVal
    );

    if (response.isOk) {
      setCertificate(response.data);
      setVerifyingQrCode(false);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.unableToProcessQrCode[lang]);
      setCertificate(null);
      setVerifyingQrCode(false);
    }
  }

  async function acceptCertificate() {
    if (certificate) {
      setLoadingAccept(true);
      await onAcceptCertificate(certificate);
      //setLoadingAccept(false);
      clearDialog()
    }
  }

  async function acceptPartialCertificate() {
    if (certificate) {
      setLoadingAccept(true);
      await onAcceptPartialCertificate(passenger);
      //setLoadingAccept(false);
      clearDialog()
    }
  }
};

export default QRCodeDialog;
