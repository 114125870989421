import React, { useState, useContext, useRef, useEffect } from 'react';
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import Button from './Button';
import ConfirmDialog from './ConfirmDialog';
import { UserItem } from '../models/Models';
import { TABLE_ROWS_OPTIONS, TABLE_ROWS_DEFAULT } from '../constants';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import * as vaccinationService from '../services/vaccinationService';
import * as httpService from '../services/httpService';
import Autocomplete, { IOption } from './Autocomplete';
import { accessListType } from '../constants/enums';

interface IProps {
  users: UserItem[];
  deleteSelectedUser: (ssn: string) => void;
  editSelectedUser: (ssn: UserItem) => void;
  accessGroup?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  searchContainer: {
    padding: theme.spacing(3, 2, 1, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchBox: {
    marginRight: theme.spacing(2),
  },
  deleteButton: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    minWidth: 90,
  },
  editButton: {
    margin: theme.spacing(1),
    minWidth: 90,
  },
  noUsersFound: {
    padding: theme.spacing(2.5),
    paddingBottom: theme.spacing(4),
  },
}));

const UserList: React.FC<IProps> = ({
  users,
  deleteSelectedUser,
  editSelectedUser,
  accessGroup,
}) => {
  const classes = useStyles({});
  const [lang] = useContext(LangContext);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const searchInput = useRef<HTMLInputElement>(null);

  const [searchText, setSearchText] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<UserItem[]>([]);
  const [deleteUser, setDeleteUser] = useState<UserItem | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [vaccinationRegions, setVaccinationRegions] = useState(
    new Array<IOption>()
  );
  const [selectedVaccinationRegion, setSelectedVaccinationRegion] =
    useState<IOption | null>(null);
  const [locations, setLocations] = useState<IOption[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<IOption | null>(
    null
  );

  const startIndex = currentPage * rowsPerPage;

  useEffect(() => {
    vaccinationService.getRegions().then((response) => {
      if (response.data) {
        const regionOptions: IOption[] = response.data.map((item) => {
          return { value: item.id.toString(), label: item.name };
        });
        setVaccinationRegions(regionOptions);
      }
    });
    httpService.getLoginLocations().then((response) => {
      if (response.data) {
        const locationOptions: IOption[] = response.data.map((item) => {
          return { value: item.id, label: item.label };
        });
        setLocations(locationOptions);
      }
    });
  }, [users]);

  useEffect(() => {
    let filteredData = users.filter((user) =>
      user.name.toLowerCase().includes(searchText.toLowerCase())
    );
    if (selectedVaccinationRegion) {
      filteredData = filteredData.filter((item) =>
        item.vaccinationRegions
          ?.map((region) => region.id)
          .includes(Number(selectedVaccinationRegion.value))
      );
    }
    if (selectedLocation) {
      filteredData = filteredData.filter((item) =>
        item.samplingLocations
          ?.map((location) => location.id)
          .includes(Number(selectedLocation.value))
      );
    }
    setFilteredUsers(filteredData);
  }, [users, searchText, selectedVaccinationRegion, selectedLocation]);
  return (
    <div className={classes.container}>
      <div className={classes.searchContainer}>
        <Grid container spacing={2} alignItems="center">
          <Grid
            item
            xs={smDown ? 12 : undefined}
            sm={smDown ? 6 : undefined}
            style={{ flex: !smDown ? 1 : undefined }}
          >
            <TextField
              fullWidth
              autoFocus
              autoComplete="off"
              id="search"
              label={translations.searchByName[lang]}
              variant="outlined"
              inputProps={{ ref: searchInput }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className={classes.searchBox}
            />
          </Grid>
          {accessGroup && accessGroup.includes(accessListType.VACCINATION) && (
            <Grid
              item
              xs={smDown ? 12 : undefined}
              sm={smDown ? 6 : undefined}
              style={{ flex: !smDown ? 1 : undefined }}
            >
              <Autocomplete
                fullWidth
                id="vaccinationRegions"
                value={selectedVaccinationRegion}
                label={translations.vaccinationRegions[lang]}
                items={vaccinationRegions}
                onChange={(val) => {
                  setSelectedVaccinationRegion(val as IOption);
                }}
              />
            </Grid>
          )}
          {accessGroup && accessGroup.includes(accessListType.BORDER_CONTROL) && (
            <Grid
              item
              xs={smDown ? 12 : undefined}
              sm={smDown ? 6 : undefined}
              style={{ flex: !smDown ? 1 : undefined }}
            >
              <Autocomplete
                fullWidth
                id="Locations"
                value={selectedLocation}
                label={translations.location[lang]}
                items={locations}
                onChange={(val) => {
                  setSelectedLocation(val as IOption);
                }}
              />
            </Grid>
          )}

          <Grid item xs={smDown ? 12 : undefined} sm={smDown ? 3 : undefined}>
            <Button variant="outlined" onClick={clearSearch}>
              {translations.clear[lang]}
            </Button>
          </Grid>
        </Grid>
      </div>
      {filteredUsers.length ? (
        <React.Fragment>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{translations.name[lang]}</TableCell>
                  <TableCell>{translations.SSN[lang]}</TableCell>
                  <TableCell>{translations.access[lang]}</TableCell>
                  {accessGroup &&
                    accessGroup.includes(accessListType.VACCINATION) && (
                      <TableCell>
                        {translations.vaccinationRegions[lang]}
                      </TableCell>
                    )}
                  {accessGroup &&
                    accessGroup.includes(accessListType.BORDER_CONTROL) && (
                      <TableCell>
                        {translations.samplingLocation[lang]}
                      </TableCell>
                    )}
                  <TableCell style={{ width: 220 }} align="center">
                    {translations.deleteUser[lang]}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers
                  .slice(startIndex, startIndex + rowsPerPage)
                  .map((user) => (
                    <TableRow key={user.ssn}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.ssn}</TableCell>
                      <TableCell>
                        {user.access.map((a) => a.description[lang]).join(', ')}
                      </TableCell>
                      {accessGroup &&
                        accessGroup.includes(accessListType.VACCINATION) && (
                          <TableCell>
                            {user.vaccinationRegions
                              ?.map((a) => a.name.split(' ')[0].trim())
                              .join(', ')}
                          </TableCell>
                        )}
                      {accessGroup &&
                        accessGroup.includes(accessListType.BORDER_CONTROL) && (
                          <TableCell>
                            {user.samplingLocations
                              ?.map((a) => a.name.split(' ')[0].trim())
                              .join(', ')}
                          </TableCell>
                        )}
                      <TableCell align="center">
                        <Button
                          onClick={() => editSelectedUser(user)}
                          padding={false}
                          className={classes.editButton}
                        >
                          {translations.edit[lang]}
                        </Button>
                        <Button
                          onClick={() => setDeleteUser(user)}
                          padding={false}
                          className={classes.deleteButton}
                        >
                          {translations.delete[lang]}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={translations.numberOfRowsPerPage[lang]}
            rowsPerPageOptions={TABLE_ROWS_OPTIONS}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={(_, p) => setCurrentPage(p)}
            onChangeRowsPerPage={(e) => {
              setCurrentPage(0);
              setRowsPerPage(parseInt(e.target.value, 10));
            }}
          />
        </React.Fragment>
      ) : (
        <Typography className={classes.noUsersFound} color="textSecondary">
          {translations.noUsersFound[lang]}
        </Typography>
      )}
      {deleteUser && (
        <ConfirmDialog
          open={!!deleteUser}
          title={translations.deleteUser[lang]}
          text={`${translations.deleteUserConfirmation[lang]} ${deleteUser.name}?`}
          onConfirm={() => {
            // Delete user
            deleteSelectedUser(deleteUser.ssn);
            setDeleteUser(null);
          }}
          onCancel={() => setDeleteUser(null)}
          confirmText={translations.delete[lang]}
        />
      )}
    </div>
  );

  function clearSearch() {
    setSearchText('');
    setSelectedVaccinationRegion(null);
    setSelectedLocation(null);
    focusSearchInput();
  }

  function focusSearchInput() {
    if (searchInput && searchInput.current) {
      searchInput.current.focus();
    }
  }
};

export default UserList;
