import { makeStyles, Theme, createStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import Layout from '../components/Layout';
import Schedule from '../components/sampling/schedule/Schedule';
import Section from '../components/Section';
import * as httpservice from '../services/httpService.v2';

import { ILocationOption } from './LocationSettingsPage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    defaultSpacing: {
      marginTop: theme.spacing(2),

      padding: theme.spacing(0, 3),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(1),

        padding: theme.spacing(0, 2),
      },
    },
  })
);

const SchedulePage: React.FC<RouteComponentProps<{ locationId: string }>> = ({
  match,
}) => {
  const classes = useStyles();

  const [location, setLocation] = useState<ILocationOption | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const response = await httpservice.getSymptomSamplingScheduleLocations();
      if (response.isOk) {
        const selectedLocation = response.data.find(
          (item: any) => item.value === Number(match.params.locationId)
        );
        if (selectedLocation) {
          setLoading(false);
          setLocation(selectedLocation);
        }
      }
    }
    fetchData();
  }, [match.params.locationId]);
  return (
    <Layout>
      <div className={classes.defaultSpacing}>
        <Section loading={loading}>
          {location && <Schedule location={location}></Schedule>}
        </Section>
      </div>
    </Layout>
  );
};
export default SchedulePage;
