import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Button from '../components/Button';
import useNotifier from '../hooks/useNotifier';
import LoadingIndicator from '../components/LoadingIndicator';
import VaccinationGroupMemberList from '../components/VaccinationGroupMemberList';
import SummonAcrossGroupsFilter, {
  IFormValues,
} from '../components/SummonAcrossGroupsFilter';

import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import {
  VaccinationLocation,
  VaccinationPerson,
  Vaccine,
} from '../models/VaccinationModels';
import { TABLE_ROWS_DEFAULT } from '../constants';
import * as vaccinationService from '../services/vaccinationService';
import { parseTemplateString } from '../utils';
import SendVaccinationGroupScheduleDialog from '../components/SendVaccinationGroupScheduleDialog';
import { IVaccinationCommentStatus } from '../interfaces/vaccinationComment';
import { IOption } from '../components/Autocomplete';
import { IVaccinationCandidateSearchQuery } from '../interfaces/vaccinationCandidate';
import VaccinationSearchDetails from '../components/VaccinationSearchDetails';
import {
  childVaccinationCustodianPosition,
  VaccineType,
} from '../constants/enums';

const SummonAcrossGroupsPage: React.FC<
  RouteComponentProps<{ groupId: string }>
> = ({ match }) => {
  const history = useHistory();
  const theme = useTheme();
  const [lang] = useContext(LangContext);
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { notifyError } = useNotifier();

  const [loadingMembers, setLoadingMembers] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [filteredPersons, setFilteredPersons] = useState<
    VaccinationPerson[] | null
  >(null);
  const [allPersons, setAllPersons] = useState<VaccinationPerson[] | null>(
    null
  );

  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);

  const [checkedGroupMembers, setCheckedGroupMembers] = useState<
    VaccinationPerson[] | null
  >(null);
  const [vaccines, setVaccines] = useState<IOption[]>([]);
  const [vaccineOptions, setVaccinesOptions] = useState<Vaccine[]>([]);
  const [locations, setLocations] = useState<VaccinationLocation[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<
    VaccinationLocation[]
  >([]);
  const [comments, setComments] = useState<IVaccinationCommentStatus[]>([]);
  const [filterPersonsOpen, setFilterPersonsOpen] = useState(true);
  const [sortedMembers, setSortedMembers] = useState<VaccinationPerson[]>([]);
  const [numberOfPersonsOpen, setNumberOfPersonsOpen] = useState(false);
  const [numberOfPersons, setNumberOfPersons] = useState('');
  const [membersCheckbox, setMembersCheckbox] = useState<string[]>([]);
  const [hideActiveSummonigs, setHideActiveSummonigs] = useState(false);
  const [hideRejectStatus, setHideRejectStatus] = useState(false);
  const [hideAntibodiesStatus, setHideAntibodiesStatus] = useState(false);
  const [hideDeceasedStatus, setHideDeceasedStatus] = useState(false);
  const [showApprovedChildren, setShowApprovedChildren] = useState(false);
  const [selectedPerson, setSelectedPerson] =
    useState<VaccinationPerson | null>(null);

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  useEffect(() => {
    const fetchSettings = async () => {
      const vaccinePromise = vaccinationService.getVaccines();
      const locationPromise = vaccinationService.getLocations();
      const commentPromise =
        vaccinationService.getVaccinationCommentStatusList();
      const [vaccineResponse, locationResponse, commentResponse] =
        await Promise.all([vaccinePromise, locationPromise, commentPromise]);

      if (vaccineResponse.isOk) {
        const covidVaccines = vaccineResponse.data.filter(
          (item) => item.vaccineType === VaccineType.COVID_19
        );
        const options = covidVaccines.map((o) => {
          return { label: o.name, value: o.atc };
        });
        const uniqOptions = options.filter(
          (option, index, self) =>
            index ===
            self.findIndex(
              (t) => t.label === option.label && t.value === option.value
            )
        );

        setVaccines(uniqOptions);
        setVaccinesOptions(covidVaccines);
      } else {
        setVaccines([]);
        setVaccinesOptions([]);
      }

      if (locationResponse.isOk) {
        setLocations(locationResponse.data);
      } else {
        setLocations([]);
      }

      if (commentResponse.isOk) {
        setComments(commentResponse.data);
      } else {
        setComments([]);
      }
    };
    fetchSettings();
  }, []);

  useEffect(() => {
    setCurrentPage(0);
  }, [filteredPersons]);

  return (
    <Layout useDefaultSpacing title={translations.summonAcrossGroups[lang]}>
      <Section>
        {filteredPersons && renderButtons()}
        {renderAccordion()}
        {loadingMembers ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: theme.spacing(2) }}
          >
            <LoadingIndicator />
          </Grid>
        ) : (
          (filteredPersons && !selectedPerson && (
            <VaccinationGroupMemberList
              members={filteredPersons}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setRowsPerPage}
              onChangeSelectedMembers={onChangeSelectedMembers}
              membersCheckbox={membersCheckbox}
              setMembersCheckbox={setMembersCheckbox}
              onSetSortedMembers={setSortedMembers}
              onClick={setSelectedPerson}
              displayBirthYear={true}
            />
          )) ||
          (selectedPerson && (
            <div style={{ marginTop: theme.spacing(2) }}>
              <VaccinationSearchDetails
                vaccines={vaccineOptions}
                locations={locations}
                nationalities={null}
                commentStatusList={comments}
                vaccinationPerson={selectedPerson}
                reloadSelectedPerson={reloadSelectedPerson}
                back={() => setSelectedPerson(null)}
              />
            </div>
          ))
        )}
        {renderDialogs()}
      </Section>
    </Layout>
  );

  function renderButtons() {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}
        style={{ marginBottom: theme.spacing(2) }}
      >
        <Grid item>
          <Button
            padding={false}
            color="secondary"
            onClick={() => setScheduleDialogOpen(true)}
          >
            {checkedGroupMembers && filteredPersons
              ? parseTemplateString(
                  translations.summonPartialListForVaccination[lang],
                  checkedGroupMembers.length.toString(),
                  filteredPersons.length.toString()
                )
              : translations.summonPersons[lang]}
          </Button>
        </Grid>
      </Grid>
    );
  }

  function renderAccordion() {
    return (
      <div>
        <Accordion
          expanded={filterPersonsOpen}
          onChange={(event, expanded) => setFilterPersonsOpen(expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              {translations.filterPersons[lang]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SummonAcrossGroupsFilter
              vaccines={vaccines}
              locations={locations}
              comments={comments}
              onSubmit={onSubmit}
              onClear={() => {
                setFilteredPersons(null);
                setAllPersons(null);
              }}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={!(filteredPersons && filteredPersons.length > 0)}
          expanded={numberOfPersonsOpen}
          onChange={(event, expanded) => setNumberOfPersonsOpen(expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              {translations.selectPersonsFromList[lang]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{renderSelectPersonsFromList()}</AccordionDetails>
        </Accordion>
      </div>
    );
  }

  function renderDialogs() {
    return (
      <React.Fragment>
        <SendVaccinationGroupScheduleDialog
          open={scheduleDialogOpen}
          onCancel={() => setScheduleDialogOpen(false)}
          onSuccess={() => onSuccess()}
          group={null}
          groupMembers={filteredPersons}
          selectedMembers={checkedGroupMembers}
          loactions={selectedLocation}
        ></SendVaccinationGroupScheduleDialog>
      </React.Fragment>
    );
  }

  function onFilterChange(
    summoningFilter: boolean,
    approvedChildren: boolean,
    rejectFilter: boolean,
    antibodiesFilter: boolean,
    deceasedFilter: boolean
  ) {
    setHideActiveSummonigs(summoningFilter);
    setShowApprovedChildren(approvedChildren);
    setHideRejectStatus(rejectFilter);
    setHideAntibodiesStatus(antibodiesFilter);
    setHideDeceasedStatus(deceasedFilter);

    const allPersonsArr = [...(allPersons || [])];
    const filteredOutPersonsSsn: { [ssn: string]: boolean } = {};

    allPersonsArr.forEach((p) => {
      // Comment statuses of Declined/Antibodies/Deceased
      const rejectStatus =
        p.vaccinationComment.statusId && p.vaccinationComment.statusId === 1;
      const antibodiesStatus =
        p.vaccinationComment.statusId && p.vaccinationComment.statusId === 3;
      const deceasedStatus =
        p.vaccinationComment.statusId && p.vaccinationComment.statusId === 4;

      if (
        (summoningFilter && p.summoningDate) ||
        (rejectFilter && rejectStatus) ||
        (antibodiesFilter && antibodiesStatus) ||
        (deceasedFilter && deceasedStatus) ||
        (approvedChildren &&
          p?.allowVaccination !== childVaccinationCustodianPosition.YES)
      ) {
        filteredOutPersonsSsn[p.ssn] = true;
      }
    });
    const newFilteredPersons = allPersonsArr.filter(
      (p) => !filteredOutPersonsSsn[p.ssn]
    );
    setFilteredPersons(newFilteredPersons);

    if (membersCheckbox.length > 0) {
      const newMembersCheckbox = [...membersCheckbox].filter(
        (p) => !filteredOutPersonsSsn[p]
      );
      setMembersCheckbox(newMembersCheckbox);
    }
  }

  async function onSuccess() {
    setMembersCheckbox([]);
    setAllPersons(null);
    setFilteredPersons(null);
    setSortedMembers([]);
    setFilterPersonsOpen(true);
    setNumberOfPersonsOpen(false);
  }

  function onSubmitNumberOfPersons(event: any) {
    event.preventDefault();
    if (sortedMembers && numberOfPersons) {
      const selectedMembers = [...sortedMembers].filter(
        (member, index) => index < parseInt(numberOfPersons)
      );

      setMembersCheckbox([...selectedMembers].map((m) => m.ssn));
      onChangeSelectedMembers(selectedMembers);
    }
  }

  function onChangeSelectedMembers(memberlist: VaccinationPerson[] | null) {
    setCheckedGroupMembers(memberlist);
  }

  async function onSubmit(values: IFormValues, selectedGroups: IOption[]) {
    setSelectedLocation(
      [...locations].filter((l) => l.regionId.toString() === values.region)
    );
    setLoadingMembers(true);

    const payload: IVaccinationCandidateSearchQuery = {
      numberOfPreviousVaccinations: Number(values.numberOfVaccinations),
      regionId: parseInt(values.region),
      vaccineAtc: values.vaccine
        ? values.vaccine.map((item) => item.value)
        : undefined,
      locationIds:
        values.location && values.location.length > 0
          ? values.location.map((item) => parseInt(item.value))
          : undefined,
      groupIds:
        selectedGroups.length > 0
          ? selectedGroups.map((g) => parseInt(g.value))
          : undefined,
      lastVaccinationFrom: values.vaccinationFrom
        ? values.vaccinationFrom.toISOString()
        : undefined,
      lastVaccinationTo: values.vaccinationTo
        ? values.vaccinationTo.toISOString()
        : undefined,
      yearFrom: values.yearFrom ? Number(values.yearFrom.value) : null,
      yearTo: values.yearTo ? Number(values.yearTo.value) : null,
    };
    const response = await vaccinationService.getVaccinationCandidates(payload);
    onResponse(response);
  }

  function onResponse(response: any) {
    if (response.isOk) {
      setLoadingMembers(false);
      setFilterPersonsOpen(false);
      setMembersCheckbox([]);
      if (response.data) {
        setFilteredPersons([...response.data]);
        setAllPersons([...response.data]);
        if ([...response.data].length > 0) {
          setNumberOfPersonsOpen(true);
        }
      } else {
        setFilteredPersons([]);
        setAllPersons([]);
      }
    } else if (response.statusCode === 401) {
      history.push('/login');
    } else {
      setFilteredPersons(null);
      setAllPersons(null);
      notifyError(translations.errorOccurred[lang]);
    }
    setLoadingMembers(false);
  }

  async function reloadSelectedPerson(barcode: string) {
    const response =
      await vaccinationService.getVaccinationPersonBySerialNumber(barcode);

    if (response.isOk) {
      setSelectedPerson(response.data);
      if (filteredPersons && allPersons) {
        const newFilteredPersons = [...filteredPersons].map((p) => {
          if (p.serialNumber === response.data?.serialNumber) {
            return response.data;
          }
          return p;
        });
        const newAllPersons = [...allPersons].map((p) => {
          if (p.serialNumber === response.data?.serialNumber) {
            return response.data;
          }
          return p;
        });
        setFilteredPersons(newFilteredPersons as VaccinationPerson[] | null);
        setAllPersons(newAllPersons as VaccinationPerson[] | null);
      }
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  function renderSelectPersonsFromList() {
    return (
      <form style={{ width: '100%' }} onSubmit={onSubmitNumberOfPersons}>
        <Grid
          container
          spacing={smDown ? 2 : 3}
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={12}>
            <Grid container direction="row" spacing={1}>
              <Grid item style={{ flex: 1 }}>
                <TextField
                  fullWidth
                  autoFocus
                  autoComplete="off"
                  id="name"
                  variant="outlined"
                  inputProps={{
                    type: 'text',
                    pattern: '[0-9+]*',
                  }}
                  label={translations.numberOfPersonsLabel[lang]}
                  value={numberOfPersons}
                  onChange={(e) => {
                    e.target.validity.valid &&
                      setNumberOfPersons(e.target.value);
                  }}
                />
              </Grid>
              <Grid item>
                <Button disabled={!numberOfPersons} type="submit">
                  {translations.select[lang]}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            /* xs={smDown ? 12 : undefined} */
          >
            <Grid container spacing={1} direction="row">
              <Grid item>
                <FormControlLabel
                  label={translations.onlyShowApprovedChildren[lang]}
                  control={
                    <Checkbox
                      id="showApprovedChildren"
                      color="primary"
                      checked={showApprovedChildren}
                      onChange={() =>
                        onFilterChange(
                          hideActiveSummonigs,
                          !showApprovedChildren,
                          hideRejectStatus,
                          hideAntibodiesStatus,
                          hideDeceasedStatus
                        )
                      }
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label={translations.hideActiveSummonings[lang]}
                  control={
                    <Checkbox
                      id="secondVaccination"
                      color="primary"
                      checked={hideActiveSummonigs}
                      onChange={() =>
                        onFilterChange(
                          !hideActiveSummonigs,
                          showApprovedChildren,
                          hideRejectStatus,
                          hideAntibodiesStatus,
                          hideDeceasedStatus
                        )
                      }
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label={translations.hideRejected[lang]}
                  control={
                    <Checkbox
                      id="hideRejected"
                      color="primary"
                      checked={hideRejectStatus}
                      onChange={() =>
                        onFilterChange(
                          hideActiveSummonigs,
                          showApprovedChildren,
                          !hideRejectStatus,
                          hideAntibodiesStatus,
                          hideDeceasedStatus
                        )
                      }
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label={translations.hideAntibodies[lang]}
                  control={
                    <Checkbox
                      id="hideAntibodies"
                      color="primary"
                      checked={hideAntibodiesStatus}
                      onChange={() =>
                        onFilterChange(
                          hideActiveSummonigs,
                          showApprovedChildren,
                          hideRejectStatus,
                          !hideAntibodiesStatus,
                          hideDeceasedStatus
                        )
                      }
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label={translations.hideDeceased[lang]}
                  control={
                    <Checkbox
                      id="hideDeceased"
                      color="primary"
                      checked={hideDeceasedStatus}
                      onChange={() =>
                        onFilterChange(
                          hideActiveSummonigs,
                          showApprovedChildren,
                          hideRejectStatus,
                          hideAntibodiesStatus,
                          !hideDeceasedStatus
                        )
                      }
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
};

export default SummonAcrossGroupsPage;
