import React, { useContext, useEffect, useState } from 'react';

import { TextField, Grid } from '@material-ui/core';

import { LangContext } from '../context/LangContext';
import ConfirmDialog from './ConfirmDialog';
import translations from '../assets/json/translations.json';

import { FormikProps, Formik, Form } from 'formik';
import { Moment } from 'moment';
import moment from 'moment';
import DatePicker from './DatePicker';
import Dropdown from './Dropdown';
import * as Yup from 'yup';

interface IProps {
  open: boolean;
  onClose: () => void;
  onSendCertificate: (
    certificateData: IForeignCertificateFormValues,
    onSuccess: () => void
  ) => Promise<void>;
}

export interface IForeignCertificateFormValues {
  dateOfBirth: Moment | null;
  dateOfSample: Moment | null;
  typeOfTest: string;
  firstName: string;
  lastName: string;
  ssn: string;
  issueNumber: string;
  passportNumber: string;
  email: string;
  testingMethodAndDevice: string;
  laboratory: string;
}

type IFormik = FormikProps<IForeignCertificateFormValues>;

const schema = Yup.object().shape({
  dateOfBirth: Yup.object().required(),
  dateOfSample: Yup.object().required(),
  typeOfTest: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().required(),
  testingMethodAndDevice: Yup.string().required(),
  laboratory: Yup.string().required(),
  issueNumber: Yup.string().required(),
});

const CreateForeignCertificate: React.FC<IProps> = ({
  open,
  onClose,
  onSendCertificate,
}) => {
  const [lang] = useContext(LangContext);
  const [openDialog, setOpenDialog] = useState(false);

  const typeOfTestOptions = [
    { label: translations.positiveCertificate[lang], value: '1' },
    { label: translations.positiveRapidTest[lang], value: '2' },
  ];

  useEffect(() => {
    if (open) {
      setOpenDialog(open);
    }
  }, [open]);

  return (
    <React.Fragment>
      <Formik
        onSubmit={(values, { resetForm }) => sendEmail(values, resetForm)}
        validationSchema={schema}
        initialValues={{
          dateOfBirth: null as Moment | null,
          dateOfSample: null as Moment | null,
          typeOfTest: '',
          firstName: '',
          lastName: '',
          ssn: '',
          issueNumber: ``,
          passportNumber: '',
          email: '',
          testingMethodAndDevice: '',
          laboratory: '',
        }}
        enableReinitialize
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>{renderDialog(formik)}</Form>
        )}
      </Formik>
    </React.Fragment>
  );

  function renderDialog(formik: IFormik) {
    return (
      <>
        <ConfirmDialog
          open={openDialog}
          title={translations.emailDialogTitle[lang]}
          confirmText={translations.sendToSamplingText1[lang]}
          onConfirm={formik.handleSubmit}
          onCancel={() => onCloseDialog()}
        >
          {renderDialogContent(formik)}
        </ConfirmDialog>
      </>
    );
  }

  function renderDialogContent({
    values,
    errors,
    submitCount,
    setFieldValue,
    handleChange,
    handleBlur,
  }: IFormik) {
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <DatePicker
            required
            id="dateOfBirth"
            label={translations.dateOfBirth[lang]}
            value={values.dateOfBirth}
            onChange={(e) => setFieldValue('dateOfBirth', e ? moment(e) : null)}
            onBlur={handleBlur}
            error={!!errors.dateOfBirth && !!submitCount}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            autoComplete="off"
            id="ssn"
            label={translations.ssn[lang]}
            variant="outlined"
            value={values.ssn}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            autoComplete="off"
            id="firstName"
            label={translations.firstNames[lang]}
            variant="outlined"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.firstName && !!submitCount}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            autoComplete="off"
            id="lastName"
            label={translations.lastName[lang]}
            variant="outlined"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.lastName && !!submitCount}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            autoComplete="off"
            id="passportNumber"
            label={translations.passportNumber[lang]}
            variant="outlined"
            value={values.passportNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            autoComplete="off"
            id="email"
            label={translations.email[lang]}
            variant="outlined"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.email && !!submitCount}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            required
            id="dateOfSample"
            label={translations.dateOfSample[lang]}
            value={values.dateOfSample}
            onChange={(e) =>
              setFieldValue('dateOfSample', e ? moment(e) : null)
            }
            onBlur={handleBlur}
            error={!!errors.dateOfSample && !!submitCount}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            fullWidth
            required
            id="typeOfTest"
            label={translations.typeOfTest[lang]}
            value={values.typeOfTest}
            items={typeOfTestOptions}
            onChange={(e) => setFieldValue('typeOfTest', e.target.value)}
            error={!!errors.typeOfTest && !!submitCount}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            autoComplete="off"
            id="issueNumber"
            label={translations.issueNumberEL[lang]}
            variant="outlined"
            value={values.issueNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.issueNumber && !!submitCount}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            multiline
            rows={3}
            autoComplete="off"
            id="testingMethodAndDevice"
            label={translations.testingMethodAndDevice[lang]}
            variant="outlined"
            value={values.testingMethodAndDevice}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.testingMethodAndDevice && !!submitCount}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            multiline
            rows={3}
            autoComplete="off"
            id="laboratory"
            label={translations.laboratory[lang]}
            variant="outlined"
            value={values.laboratory}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.laboratory && !!submitCount}
          />
        </Grid>
      </Grid>
    );
  }

  async function sendEmail(
    values: IForeignCertificateFormValues,
    resetForm: any
  ) {
    await onSendCertificate(values, () => onCloseDialog());
    resetForm();
  }

  function onCloseDialog() {
    setOpenDialog(false);
    onClose && onClose();
  }
};

export default CreateForeignCertificate;
