import React, {
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import { Grid, TextField, useTheme, useMediaQuery } from '@material-ui/core';
import debounce from 'lodash.debounce';
import fuzzysort from 'fuzzysort';

import Button from './Button';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { ResidencePerson } from '../models/Models';

interface IProps {
  passengers: ResidencePerson[];
  filterPassengers: (passengers: ResidencePerson[]) => void;
}

const QuarantinePassengersFilter: React.FC<IProps> = ({
  passengers,
  filterPassengers,
}) => {
  const [lang] = useContext(LangContext);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const searchRef = useRef<HTMLInputElement>();

  const [nameInput, setNameInput] = useState('');
  const [flightNumberInput, setFlightNumberInput] = useState('');
  const [nameInputFilter, setNameInputFilter] = useState('');
  const [flightNumberFilter, setFlightNumberFilter] = useState('');
  const debouncedNameUpdate = useCallback(
    debounce(setNameInputFilter, 500),
    []
  );
  const debouncedFlightNumberUpdate = useCallback(
    debounce(setFlightNumberFilter, 500),
    []
  );

  useEffect(() => {
    const nameSearchResults = nameInputFilter
      ? fuzzysort
          .go(nameInputFilter, passengers, { keys: ['name'] })
          .map((s) => s.obj)
      : passengers;
    const flightNumberSearchResults = flightNumberFilter
      ? fuzzysort
          .go(flightNumberFilter, nameSearchResults, {
            keys: ['flightNumber'],
          })
          .map((s) => s.obj)
      : nameSearchResults;

    filterPassengers(flightNumberSearchResults);
  }, [passengers, filterPassengers, nameInputFilter, flightNumberFilter]);

  return (
    <Grid style={{ width: '100%' }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: theme.spacing(1) }}
      ></Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid
          item
          xs={smDown ? 12 : undefined}
          sm={smDown ? 12 : undefined}
          style={{ flex: !smDown ? 1 : undefined }}
        >
          <TextField
            fullWidth
            autoFocus
            autoComplete="off"
            id="name"
            variant="outlined"
            inputRef={searchRef}
            label={translations.name[lang]}
            value={nameInput}
            onChange={(e) => {
              setNameInput(e.target.value);
              debouncedNameUpdate(e.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          xs={smDown ? 12 : undefined}
          sm={smDown ? 9 : undefined}
          style={{ flex: !smDown ? 1 : undefined }}
        >
          <TextField
            fullWidth
            autoComplete="off"
            id="flightNumber"
            variant="outlined"
            //inputRef={searchRef}
            label={translations.flightNumber[lang]}
            value={flightNumberInput}
            onChange={(e) => {
              setFlightNumberInput(e.target.value);
              debouncedFlightNumberUpdate(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={smDown ? 12 : undefined} sm={smDown ? 3 : undefined}>
          <Button fullWidth={smDown} variant="outlined" onClick={clearFilters}>
            {translations.clear[lang]}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  function clearFilters() {
    setNameInput('');
    setNameInputFilter('');
    setFlightNumberInput('');
    setFlightNumberFilter('');
    filterPassengers(passengers);
    focusSearchInput();
  }

  function focusSearchInput() {
    if (searchRef && searchRef.current) {
      searchRef.current.focus();
    }
  }
};

export default QuarantinePassengersFilter;
