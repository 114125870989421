import React, {
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  Grid,
  TextField,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import debounce from 'lodash.debounce';
import fuzzysort from 'fuzzysort';
// import moment from 'moment';

import Button from './Button';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { ResidencePerson } from '../models/Models';
import { observationStatus } from '../constants/enums';

interface IProps {
  passengers: ResidencePerson[];
  filterPassengers: (passengers: ResidencePerson[]) => void;
}

const QuarantineGuestsFilter: React.FC<IProps> = ({
  passengers,
  filterPassengers,
}) => {
  const [lang] = useContext(LangContext);
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const searchRef = useRef<HTMLInputElement>();

  const [nameInput, setNameInput] = useState('');
  const [hotelRoomNumberInput, setHotelRoomNumberInput] = useState('');
  const [nameInputFilter, setNameInputFilter] = useState('');
  const [hotelRoomNumberFilter, setHotelRoomNumberFilter] = useState('');
  const [readyForCheckoutFilter, setReadyForCheckoutFilter] = useState(false);
  const debouncedNameUpdate = useCallback(
    debounce(setNameInputFilter, 500),
    []
  );
  const debouncedHotelRoomNumberUpdate = useCallback(
    debounce(setHotelRoomNumberFilter, 500),
    []
  );

  useEffect(() => {
    const nameSearchResults = nameInputFilter
      ? fuzzysort
          .go(nameInputFilter, passengers, { keys: ['name'] })
          .map((s) => s.obj)
      : passengers;
    const flightNumberSearchResults = hotelRoomNumberFilter
      ? fuzzysort
          .go(hotelRoomNumberFilter, nameSearchResults, {
            keys: ['checkedInRoomNumber'],
          })
          .map((s) => s.obj)
      : nameSearchResults;
    const filteredResults = readyForCheckoutFilter
      ? flightNumberSearchResults.filter(
          (r) => r.observationStatus?.id === observationStatus.NEGATIVE
        )
      : flightNumberSearchResults;

    filterPassengers(filteredResults);
  }, [
    passengers,
    filterPassengers,
    nameInputFilter,
    hotelRoomNumberFilter,
    readyForCheckoutFilter,
  ]);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={2}
      style={{ marginTop: theme.spacing(1) }}
    >
      <Grid
        item
        xs={smDown ? 12 : undefined}
        sm={smDown ? 6 : undefined}
        style={{ flex: !smDown ? 1 : undefined }}
      >
        <TextField
          fullWidth
          autoFocus
          autoComplete="off"
          id="name"
          variant="outlined"
          inputRef={searchRef}
          label={translations.name[lang]}
          value={nameInput}
          onChange={(e) => {
            setNameInput(e.target.value);
            debouncedNameUpdate(e.target.value);
          }}
        />
      </Grid>
      <Grid
        item
        xs={smDown ? 12 : undefined}
        sm={smDown ? 6 : undefined}
        style={{ flex: !smDown ? 1 : undefined }}
      >
        <TextField
          fullWidth
          autoComplete="off"
          id="flightNumber"
          variant="outlined"
          label={translations.hotelRoomNumber[lang]}
          value={hotelRoomNumberInput}
          onChange={(e) => {
            setHotelRoomNumberInput(e.target.value);
            debouncedHotelRoomNumberUpdate(e.target.value);
          }}
        />
      </Grid>
      <Grid
        item
        xs={xsDown ? 12 : undefined}
        style={{
          marginLeft: theme.spacing(1),
          flex: smDown && !xsDown ? 1 : undefined,
        }}
      >
        <FormControlLabel
          label={translations.readyForCheckout[lang]}
          control={
            <Checkbox
              id="readyForCheckout"
              color="primary"
              checked={readyForCheckoutFilter}
              onChange={() =>
                setReadyForCheckoutFilter(!readyForCheckoutFilter)
              }
            />
          }
        />
      </Grid>
      <Grid item xs={xsDown ? 12 : undefined}>
        <Button fullWidth={smDown} variant="outlined" onClick={clearFilters}>
          {translations.clear[lang]}
        </Button>
      </Grid>
    </Grid>
  );

  function clearFilters() {
    setNameInput('');
    setNameInputFilter('');
    setHotelRoomNumberInput('');
    setHotelRoomNumberFilter('');
    setReadyForCheckoutFilter(false);
    filterPassengers(passengers);
    focusSearchInput();
  }

  function focusSearchInput() {
    if (searchRef && searchRef.current) {
      searchRef.current.focus();
    }
  }
};

export default QuarantineGuestsFilter;
