import React, { useContext, useState } from 'react';

import Layout from '../components/Layout';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { useHistory } from 'react-router';
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import Button from '../components/Button';
import { UserContext } from '../context/UserContext';
import { Autocomplete } from '@material-ui/lab';

const QuarantineHousePage: React.FC = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      paper: {
        flex: 1,
        padding: theme.spacing(3),
        margin: theme.spacing(3),
        textAlign: 'center',
        maxWidth: theme.dimensions.boxWidth,
      },
      typographyTitle: {
        padding: theme.spacing(2),
      },
    })
  );
  const [user] = useContext(UserContext);

  const [lang] = useContext(LangContext);
  const history = useHistory();
  const classes = useStyles({});
  const [residenceLocation, setResidenceLocation] = useState(
    user?.residenceLocationListModel[0]
  );

  return (
    <Layout
      useDefaultSpacing
      title={translations.quarantineHotel[lang]}
      //backButtonClick={() => history.goBack()}
    >
      <div className={classes.root}>
        <Paper elevation={2} className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                id="combo-box-demo"
                options={user?.residenceLocationListModel}
                getOptionLabel={(option: any) => option.description}
                defaultValue={user?.residenceLocationListModel[0]}
                disabled={user?.residenceLocationListModel?.length === 1}
                onChange={(event, newValue) => {
                  setResidenceLocation(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translations.chooseQuarantineHotel[lang]}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.typographyTitle} variant="h4">
                {translations.actions[lang]}
              </Typography>
            </Grid>
            <React.Fragment>
              <Grid item xs={12} sm={12}>
                <Button
                  fullWidth
                  onClick={() =>
                    history.push(
                      `/quarantine-passenger-list-old/${residenceLocation.id}`
                    )
                  }
                >
                  {translations.passengerList[lang]}
                </Button>
              </Grid>
              {/* <Grid item xs={12} sm={12}>
                <Button
                  fullWidth
                  onClick={() =>
                    history.push(
                      `/quarantine-passenger-list/${residenceLocation.id}`
                    )
                  }
                >
                  {translations.passengerList[lang]}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  fullWidth
                  onClick={() =>
                    history.push(
                      `/quarantine-residences/${residenceLocation.id}`
                    )
                  }
                >
                  {translations.guests[lang]}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  fullWidth
                  onClick={() => history.push('/quarantine-house/search')}
                >
                  {translations.searchTitle[lang]}
                </Button>
              </Grid> */}
            </React.Fragment>
          </Grid>
        </Paper>
      </div>
    </Layout>
  );
};
export default QuarantineHousePage;
