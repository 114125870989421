import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
  Grid,
  Checkbox,
  useTheme,
} from '@material-ui/core';
import moment from 'moment';

import NoResults from './NoResults';
import {
  TABLE_ROWS_OPTIONS,
  DATE_FORMAT,
  DATE_AND_TIME_FORMAT,
} from '../constants';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { ResidencePerson } from '../models/Models';

interface IProps {
  members: ResidencePerson[];
  currentPage: number;
  rowsPerPage: number;
  setCurrentPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
  onClick?: (passenger: ResidencePerson) => void;
  onChangeSelectedMembers?: (memberList: ResidencePerson[] | null) => void;
  membersCheckbox?: number[];
  setMembersCheckbox?: (members: number[]) => void;
  onSetSortedMembers?: (memberList: ResidencePerson[]) => void;
  tableColumns: {
    [key: string]: {
      translation: { is: string; en: string };
      sort?: (a: ResidencePerson, b: ResidencePerson) => number;
    };
  };
  tableColumnsKeys: string[];
  topMargin?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  vaccinationCount: {
    padding: theme.spacing(2),
  },
}));

const QuarantinePassengerList: React.FC<IProps> = ({
  members,
  currentPage,
  rowsPerPage,
  setCurrentPage,
  setRowsPerPage,
  onClick,
  onChangeSelectedMembers,
  membersCheckbox = [],
  setMembersCheckbox,
  onSetSortedMembers,
  tableColumns,
  tableColumnsKeys,
  topMargin = true,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [lang] = useContext(LangContext);

  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortedMembers, setSortedMembers] = useState<ResidencePerson[]>([]);
  const startIndex = currentPage * rowsPerPage;

  useEffect(() => {
    if (sortKey && tableColumns[sortKey]) {
      const sorted = tableColumns[sortKey].sort
        ? [...members].sort(tableColumns[sortKey].sort)
        : [...members];
      setSortedMembers(sortDirection === 'asc' ? sorted : sorted.reverse());
    } else {
      setSortedMembers(members);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members, sortKey, sortDirection]);

  useEffect(() => {
    if (membersCheckbox.length > 0 && onChangeSelectedMembers) {
      const sortedCheckedMembers = sortedMembers.filter((m) =>
        membersCheckbox.includes(m.id)
      );
      onChangeSelectedMembers(sortedCheckedMembers);
    } else {
      onChangeSelectedMembers && onChangeSelectedMembers(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedMembers, membersCheckbox]);

  useEffect(() => {
    if (onSetSortedMembers) {
      onSetSortedMembers(sortedMembers);
    }
  }, [sortedMembers, onSetSortedMembers]);

  return (
    <Paper
      elevation={2}
      className={classes.container}
      style={{ marginTop: topMargin ? theme.spacing(3) : undefined }}
    >
      {members.length ? (
        <React.Fragment>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {onChangeSelectedMembers && (
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={membersCheckbox.length > 0}
                        onChange={() => handleMainCheckboxChange()}
                      />
                    </TableCell>
                  )}
                  {Object.keys(tableColumns).map((columnKey) => (
                    <TableCell key={columnKey}>
                      {tableColumns[columnKey].sort ? (
                        <TableSortLabel
                          active={sortKey === columnKey}
                          direction={sortDirection}
                          onClick={() => onSortClick(columnKey)}
                        >
                          {tableColumns[columnKey].translation[lang]}
                        </TableSortLabel>
                      ) : (
                        tableColumns[columnKey].translation[lang]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedMembers
                  .slice(startIndex, startIndex + rowsPerPage)
                  .map((member) => (
                    <TableRow
                      hover={!!onClick}
                      key={member.serialNumber}
                      style={{ cursor: onClick ? 'pointer' : undefined }}
                    >
                      {onChangeSelectedMembers && (
                        <TableCell>
                          <Checkbox
                            color="primary"
                            checked={membersCheckbox.includes(member.id)}
                            onChange={() => handleCheckboxChange(member.id)}
                          />
                        </TableCell>
                      )}

                      {tableColumnsKeys.includes('name') && (
                        <TableCell onClick={() => onClick && onClick(member)}>
                          {member.name}
                        </TableCell>
                      )}
                      {tableColumnsKeys.includes('ssn') && (
                        <TableCell onClick={() => onClick && onClick(member)}>
                          {moment(member.dateOfBirth).format(DATE_FORMAT)}
                        </TableCell>
                      )}

                      {tableColumnsKeys.includes('nationality') && (
                        <TableCell onClick={() => onClick && onClick(member)}>
                          {member.nationality}
                        </TableCell>
                      )}
                      {tableColumnsKeys.includes('countryOfDeparture') && (
                        <TableCell onClick={() => onClick && onClick(member)}>
                          {member.countryOfDeparture}
                        </TableCell>
                      )}
                      {tableColumnsKeys.includes('flightNumber') && (
                        <TableCell onClick={() => onClick && onClick(member)}>
                          {member.flightNumber}
                        </TableCell>
                      )}
                      {tableColumnsKeys.includes('arrivalDate') && (
                        <TableCell onClick={() => onClick && onClick(member)}>
                          {moment(member.dateOfArrival).format(
                            DATE_AND_TIME_FORMAT
                          )}
                        </TableCell>
                      )}
                      {tableColumnsKeys.includes('hotelRoomNumber') && (
                        <TableCell onClick={() => onClick && onClick(member)}>
                          {member.checkedInRoomNumber}
                        </TableCell>
                      )}
                      {tableColumnsKeys.includes('dischargeDate') && (
                        <TableCell onClick={() => onClick && onClick(member)}>
                          {member.estimatedDischargeDate
                            ? moment(member.estimatedDischargeDate).format(
                                DATE_AND_TIME_FORMAT
                              )
                            : ''}
                        </TableCell>
                      )}
                      {tableColumnsKeys.includes('comment') && (
                        <TableCell onClick={() => onClick && onClick(member)}>
                          {member.comment}
                        </TableCell>
                      )}
                      {tableColumnsKeys.includes('food') && (
                        <TableCell onClick={() => onClick && onClick(member)}>
                          {member.food}
                        </TableCell>
                      )}
                      {tableColumnsKeys.includes('secondSampling') && (
                        <TableCell onClick={() => onClick && onClick(member)}>
                          {member?.observationStatus?.description[lang] || ''}
                        </TableCell>
                      )}
                      {tableColumnsKeys.includes('status') && (
                        <TableCell onClick={() => onClick && onClick(member)}>
                          {member?.residenceStatus?.description[lang] || ''}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <TablePagination
              labelRowsPerPage={translations.numberOfRowsPerPage[lang]}
              rowsPerPageOptions={TABLE_ROWS_OPTIONS}
              component="div"
              count={members.length}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={(_, p) => setCurrentPage(p)}
              onChangeRowsPerPage={(e) => {
                setCurrentPage(0);
                setRowsPerPage(parseInt(e.target.value, 10));
              }}
            />
          </Grid>
        </React.Fragment>
      ) : (
        <NoResults message={translations.noVaccinationPersonFound[lang]} />
      )}
    </Paper>
  );

  function onSortClick(columnKey: string) {
    if (sortKey === columnKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(columnKey);
      setSortDirection('asc');
    }
  }

  function handleCheckboxChange(id: number) {
    if (setMembersCheckbox) {
      if (membersCheckbox.includes(id)) {
        setMembersCheckbox([...membersCheckbox].filter((m) => m !== id));
      } else {
        setMembersCheckbox([...membersCheckbox, id]);
      }
    }
  }

  function handleMainCheckboxChange() {
    if (setMembersCheckbox) {
      if (membersCheckbox.length > 0) {
        setMembersCheckbox([]);
      } else {
        setMembersCheckbox([...sortedMembers].map((m) => m.id));
      }
    }
  }
};

export default QuarantinePassengerList;
