import React, { useContext, useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { Form, Formik, FormikProps } from 'formik';
import { Grid, makeStyles, Paper, TextField } from '@material-ui/core';
import { Moment } from 'moment';
import * as Yup from 'yup';
import Section from '../components/Section';
import Dropdown from '../components/Dropdown';
import { SEXES } from '../constants';
import moment from 'moment';
import useNotifier from '../hooks/useNotifier';
import DatePicker from '../components/DatePicker';
import Button from '../components/Button';
import Autocomplete, { IOption } from '../components/Autocomplete';
import AutocompleteFlag, { ICountryItem } from '../components/AutocompleteFlag';
import * as vaccinationService from '../services/vaccinationService';
import countryCodes from '../assets/json/callingCodes2.json';
import { lookupVaccinationPersons2 } from '../services/vaccinationService';
import { useHistory } from 'react-router-dom';
import { IRegisterForVaccination } from '../interfaces/RegisterForVaccination';
import { getDateOfBirthFromSsn, getYesNoOptions } from '../utils';
import { VaccinationPerson } from '../models/VaccinationModels';
import { ErrorInfo } from '../models/Models';

export interface IFormValues {
  name: string;
  dateOfBirth: Moment | null;
  sex: string;
  phoneNumber: string;
  ssn: string;
  nationality: ICountryItem | null;
  callingCode: ICountryItem | null;
  isPregnant: boolean | null;
  hasAnAccuteAllergy: boolean | null;
  isUsingImmonusuppresiveMedication: boolean | null;
  location: IOption | null;
  comments: string;
  email: string;
  dateOfCovidDiagnosis: Moment | null;
  dateOfTwelveWeeks: Moment | null;
}

export type IFormik = FormikProps<IFormValues>;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  clearButton: {
    marginLeft: theme.spacing(2),
  },
  success: {
    borderLeft: `4px solid ${theme.palette.success.main}`,
    paddingLeft: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
  },
  residenceInfo: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  residenceTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },
}));

const RegisterForVaccine: React.FC = () => {
  const classes = useStyles({});
  const { notifyError, notifySuccess } = useNotifier();
  const [countryList, setCountryList] = useState<ICountryItem[] | null>(null);
  const history = useHistory();
  const [lang] = useContext(LangContext);
  const [callingCodes, setCallingCodes] = useState<ICountryItem[]>([]);
  const [locationsOptions, setLocationsOptions] = useState<IOption[]>([]);
  const [hideSex, setHideSex] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ssnError, setSsnError] = useState(false);
  const [ssnHelperText, setSsnHelperText] = useState<string | undefined>(
    undefined
  );

  const sortedCountryCodes = countryCodes.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  useEffect(() => {
    const setCountyCodes = () => {
      const countryList: ICountryItem[] = sortedCountryCodes.map((a) => ({
        name: a.name,
        code: a.code,
        status: 0,
        flag: a.code,
      }));
      correctFlags(countryList);
      setCountryList(countryList);
    };
    const setDialCodes = () => {
      const callCodes: ICountryItem[] = sortedCountryCodes.map((c) => ({
        name: c.name + ` (+${c.dial_code})`,
        code: c.dial_code as string,
        status: 0,
        flag: c.code as string,
      }));
      setCallingCodes(callCodes);
    };
    setCountyCodes();
    setDialCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getVaccinationLocation = async () => {
      const res = await vaccinationService.getLocations();
      setLocationsOptions(
        res.data.map((x) => ({
          label: x.name,
          value: x.id.toString(),
        }))
      );
    };
    getVaccinationLocation();
  }, []);

  function getSchema() {
    return Yup.object().shape({
      name: Yup.string().required(),
      dateOfBirth: Yup.object().required(),
      sex: hideSex ? Yup.string() : Yup.string().required(),
      phoneNumber: Yup.string().required(),
      ssn: Yup.string().matches(/^[0-3]\d[01]\d{6}[09]$/),
      nationality: Yup.object().required(),
      callingCode: Yup.object().required(),
      isPregnant: Yup.boolean().required(),
      hasAnAccuteAllergy: Yup.boolean().required(),
      isUsingImmonusuppresiveMedication: Yup.boolean().required(),
      location: Yup.object().required(),
      email: Yup.string().email(),
    });
  }

  function getInitialValues(): IFormValues {
    return {
      name: '',
      dateOfBirth: null,
      sex: '',
      phoneNumber: '',
      ssn: '',
      nationality: null as ICountryItem | null,
      callingCode: null as ICountryItem | null,
      isPregnant: null,
      hasAnAccuteAllergy: null,
      isUsingImmonusuppresiveMedication: null,
      location: null as IOption | null,
      email: '',
      comments: '',
      dateOfCovidDiagnosis: null,
      dateOfTwelveWeeks: null,
    };
  }

  function getGenderFromSex(sex: string) {
    if (sex === 'male') return 'M';
    if (sex === 'female') return 'F';
    if (sex === 'other') return 'X';
    return null;
  }

  function correctFlags(countryList: ICountryItem[]) {
    countryList.forEach((item) => {
      if (item.code === 'EX') {
        item.flag = 'ES';
      }
      if (item.code === 'EY') {
        item.flag = 'ES';
      }
      if (item.code === 'KO') {
        item.flag = 'XK';
      }
    });
  }

  function getStringFromNullableBoolean(bool: boolean | null) {
    if (bool === null) {
      return '';
    }
    if (bool) {
      return 'true';
    } else {
      return 'false';
    }
  }

  async function handleSubmitInner(values: IFormValues, resetForm: any) {
    const req: IRegisterForVaccination = {
      ssn: values.ssn,
      dateOfBirth: values.dateOfBirth ? values.dateOfBirth.toISOString() : null,
      fullName: values.name.trim(),
      gender: getGenderFromSex(values.sex),
      phoneCode: values.callingCode ? values.callingCode.code : null,
      phoneNumber: values.phoneNumber,
      nationality: values.nationality ? values.nationality.code : '',
      isPregnant: values.isPregnant as boolean,
      hasAnAccuteAllergy: values.hasAnAccuteAllergy as boolean,
      isUsingImmonusuppresiveMedication:
        values.isUsingImmonusuppresiveMedication as boolean,
      vaccinationLocation: values.location ? values.location.value : '',
      comments: values.comments,
      email: values.email,
      dateOfCovidDiagnosis: values.dateOfCovidDiagnosis
        ? values.dateOfCovidDiagnosis.toISOString()
        : null,
      hasHadCovidDiseaseBefore: values.dateOfCovidDiagnosis ? true : false,
      dateOfTwelveWeeks: values.dateOfTwelveWeeks
        ? values.dateOfTwelveWeeks.toISOString()
        : null,
    };

    const res = await vaccinationService.registerForVaccination(req);
    if (res.isOk) {
      resetForm({});
      notifySuccess(translations.registrationSuccess[lang]);
    } else {
      notifyError(translations.registrationFail[lang]);
    }
  }

  async function handleSubmit(values: IFormValues, { resetForm }: any) {
    if (!ssnError) {
      setLoading(true);
      try {
        await handleSubmitInner(values, resetForm);
      } catch (err) {
        setLoading(false);
      }
      setLoading(false);
    }
  }

  function clearForm({ handleReset }: IFormik) {
    handleReset();
    setHideSex(false);
    setFormSsnError(false);
  }

  function renderButtons(formik: IFormik) {
    return (
      <Grid item xs={12}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <React.Fragment>
            <Button variant="contained" type="submit" disabled={loading}>
              {translations.finishRegistration[lang]}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                clearForm(formik);
              }}
              className={classes.clearButton}
              disabled={loading}
            >
              {translations.clear[lang]}
            </Button>
          </React.Fragment>
        </Grid>
      </Grid>
    );
  }

  function setFormSsnError(isError: boolean, text?: string) {
    setSsnError(isError);
    setSsnHelperText(text);
  }

  async function lookupBySsn(
    ssn: string,
    setValue: (field: string, value: any) => void
  ) {
    if (ssn.length !== 10) {
      setValue('name', '');
      setValue('dateOfBirth', null);
      setValue('nationality', null);
      setValue('sex', '');
      setHideSex(false);
      return;
    }
    const response = await lookupVaccinationPersons2([{ ssn: ssn }]);
    if (response.isOk) {
      const person = response.data as VaccinationPerson;
      const name = person.name;
      setValue('name', name);
      setValue('dateOfBirth', getDateOfBirthFromSsn(ssn));
      setHideSex(true);
      const iceland = countryList?.find((x) => x.code.toLowerCase() === 'is');
      if (iceland) {
        setValue('nationality', iceland);
      }
      setFormSsnError(false);
    } else {
      const res = response.data as ErrorInfo;
      if (res.errorCode === 404) {
        setFormSsnError(true, translations.notFoundInNatReg[lang]);
      } else {
        setFormSsnError(true, res.errorMessage);
      }
    }
  }

  function renderForm({
    values,
    errors,
    submitCount,
    handleChange,
    handleBlur,
    setFieldValue,
  }: IFormik) {
    return (
      <React.Fragment>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            autoFocus
            autoComplete="off"
            id="ssn"
            variant="outlined"
            inputProps={{
              type: 'text',
              pattern: '[0-9+]*',
              maxLength: 10,
            }}
            label={translations.ssn[lang]}
            value={values.ssn}
            error={(!!errors.ssn && !!submitCount) || ssnError}
            helperText={ssnHelperText}
            onChange={(e) => {
              if (e.target.validity.valid) {
                lookupBySsn(e.target.value, setFieldValue);
              }
              if (e.target.value === '') {
                setFormSsnError(false);
              }
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            id="dateOfBirth"
            label={translations.dateOfBirth[lang] + ' *'}
            value={values.dateOfBirth}
            onChange={(e) => setFieldValue('dateOfBirth', e ? moment(e) : null)}
            onBlur={handleBlur}
            muiProps={{
              disabled: false,
              invalidDateMessage: !!submitCount
                ? translations.invalidDate[lang]
                : null,
              error: !!errors.dateOfBirth && !!submitCount,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            autoComplete="off"
            id="name"
            variant="outlined"
            label={translations.name[lang] + ' *'}
            value={values.name}
            error={!!errors.name && !!submitCount}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            autoComplete="off"
            id="email"
            variant="outlined"
            label={translations.email[lang]}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.email && !!submitCount}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            fullWidth
            disabled={hideSex}
            id="sex"
            value={values.sex}
            label={
              hideSex ? translations.sex[lang] : translations.sex[lang] + ' *'
            }
            items={SEXES.map((s) => ({
              label: translations[s.translationKey][lang],
              value: s.key,
            }))}
            onChange={handleChange}
            error={!!errors.sex && !!submitCount}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutocompleteFlag
            fullWidth
            id="nationality"
            value={values.nationality}
            label={
              (countryList
                ? translations.nationality[lang]
                : translations.loadingCountries[lang]) + ' *'
            }
            items={countryList || []}
            onChange={(event, val) => {
              handleChange(event);
              setFieldValue('nationality', val);
            }}
            error={!!errors.nationality && !!submitCount}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutocompleteFlag
            fullWidth
            id="callingCode"
            value={values.callingCode}
            label={translations.countryCode[lang] + ' *'}
            items={callingCodes || []}
            onChange={(event, val) => {
              handleChange(event);
              setFieldValue('callingCode', val);
            }}
            error={!!errors.callingCode && !!submitCount}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="phoneNumber"
            variant="outlined"
            inputProps={{
              type: 'text',
              pattern: '[0-9+]*',
              maxLength: 15,
              form: { autoComplete: 'off' },
            }}
            label={translations.phoneNumber[lang] + ' *'}
            value={values.phoneNumber}
            error={!!errors.phoneNumber && !!submitCount}
            onChange={(e) =>
              !e.target.validity.patternMismatch && handleChange(e)
            }
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            fullWidth
            id="isPregnant"
            value={getStringFromNullableBoolean(values.isPregnant)}
            label={translations.pregnant[lang] + ' *'}
            items={getYesNoOptions(lang)}
            onChange={(e) => {
              handleChange(e);
              setFieldValue(
                'isPregnant',
                e.target.value === 'true' ? true : false
              );
            }}
            error={!!errors.isPregnant && !!submitCount}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            required
            disabled={!values.isPregnant}
            id="dateOfTwelveWeeks"
            label={translations.dateOfTwelveWeeks[lang]}
            value={values.dateOfTwelveWeeks}
            onChange={(e) =>
              setFieldValue('dateOfTwelveWeeks', e ? moment(e) : null)
            }
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            fullWidth
            id="hasAnAccuteAllergy"
            value={getStringFromNullableBoolean(values.hasAnAccuteAllergy)}
            label={translations.accuteAllergy[lang] + ' *'}
            items={getYesNoOptions(lang)}
            onChange={(e) => {
              handleChange(e);
              setFieldValue(
                'hasAnAccuteAllergy',
                e.target.value === 'true' ? true : false
              );
            }}
            error={!!errors.hasAnAccuteAllergy && !!submitCount}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            fullWidth
            id="isUsingImmonusuppresiveMedication"
            value={getStringFromNullableBoolean(
              values.isUsingImmonusuppresiveMedication
            )}
            label={translations.isUsingImmonusuppresiveMedication[lang] + ' *'}
            items={getYesNoOptions(lang)}
            onChange={(e) => {
              handleChange(e);
              setFieldValue(
                'isUsingImmonusuppresiveMedication',
                e.target.value === 'true' ? true : false
              );
            }}
            error={!!errors.isUsingImmonusuppresiveMedication && !!submitCount}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            fullWidth
            id="location"
            value={values.location}
            label={translations.location[lang] + ' *'}
            items={locationsOptions || []}
            onChange={(val) => setFieldValue('location', val)}
            error={!!errors.location && !!submitCount}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            autoComplete="off"
            id="email"
            variant="outlined"
            label={translations.email[lang]}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.email && !!submitCount}
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <DatePicker
            id="dateOfCovidDiagnosis"
            label={translations.dateOfCovidDiagnosis[lang]}
            value={values.dateOfCovidDiagnosis}
            onChange={(e) =>
              setFieldValue('dateOfCovidDiagnosis', e ? moment(e) : null)
            }
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            autoComplete="off"
            id="comments"
            variant="outlined"
            label={translations.comments[lang]}
            value={values.comments}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <Layout
      useDefaultSpacing
      title={translations.registerToWaitingList[lang]}
      otherButton={{
        label: translations.registerForVaccine[lang],
        onClick: () => history.push('/vaccination-waiting-list'),
      }}
    >
      <Section>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={getSchema()}
          initialValues={getInitialValues()}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={2}>
                  {renderForm(formik)}
                  {renderButtons(formik)}
                </Grid>
              </Paper>
            </Form>
          )}
        </Formik>
      </Section>
    </Layout>
  );
};
export default RegisterForVaccine;
