import React, { useContext, useState, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import Layout from '../components/Layout';
import Section from '../components/Section';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import ScheduleLocationForm from '../components/sampling/schedule/ScheduleLocationForm';
import ScheduleSettingsForm, {
  IFormValues,
} from '../components/sampling/schedule/ScheduleSettingsForm';
import { IOption } from '../components/Autocomplete';
import * as httpservice from '../services/httpService.v2';
import { UserContext } from '../context/UserContext';
import LocationTemplateTexts from '../components/location/LocationTemplateTexts';
import { Moment } from 'moment';
import { ScheduleLocation } from '../models/Models';
import moment from 'moment';
import * as scheduleLocationService from '../services/scheduleLocationService';
import theme from '../theme';
import * as localStorageKeys from '../constants/localStorageKeys';
import { useHistory } from 'react-router';
import useNotifier from '../hooks/useNotifier';
import { IUpdateScheduleLocationSettings } from '../interfaces/schedule';

export interface ILocationOption extends IOption {
  loginLocations: any;
}

export interface IHelpItem {
  title: {
    is: string;
    en: string;
  };
  component: JSX.Element;
}

const useStyles = makeStyles(() => ({
  typography: {
    marginBottom: theme.spacing(2),
  },
}));

const LocationSettingsPage: React.FC = () => {
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();
  const { notifyError, notifySuccess } = useNotifier();

  const [location, setLocation] = useState<ILocationOption | null>(null);
  const [locationOptions, setLocationOptions] = useState<ILocationOption[]>([]);
  const [scheduleLocation, setScheduleLocation] =
    useState<ScheduleLocation | null>(null);
  const [cutoffTime, setCutoffTime] = useState<Moment | null>(null);
  const [cutoffTimeWeekends, setCutoffTimeWeekends] = useState<Moment | null>(
    null
  );
  const [daysBetweenSamplings, setDaysBetweenSamplings] = useState('');
  const [allowOverflow, setAllowOverflow] = useState(true);
  const [showInHeilsuvera, setShowInHeilsuvera] = useState(true);
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await httpservice.getSymptomSamplingScheduleLocations();
      if (response.isOk) {
        setLocationOptions(response.data);
        const storedId =
          localStorage.getItem(`${localStorageKeys.SCHEDULE_LOCATION_ID}`) ||
          '';
        if (storedId) {
          const storedLocation = response.data.find(
            (item: any) => item.value === Number(storedId)
          );
          if (storedLocation) {
            setLocation(storedLocation);
          }
        }
      }
    }
    fetchData();
  }, [user]);

  useEffect(() => {
    /*  async function fetchLocationData(locationId: number) {
      const response = await scheduleLocationService.getScheduleLocation(
        locationId
      );
      const locationData = response.data;
      if (response.isOk && locationData) {
        setScheduleLocation(locationData);
        mapCutOffTimesFromScheduleLocation(locationData);
        setAllowOverflow(locationData.isOverflowInHeilsuveraEnabled);
        setShowInHeilsuvera(locationData.showInHeilsuvera);
        if (locationData?.daysBetweenBookings) {
          setDaysBetweenSamplings(locationData.daysBetweenBookings.toString());
        }
      }
    } */

    if (location?.value) {
      fetchLocationData(Number(location.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Layout
      useDefaultSpacing
      title={translations.schedule[lang]}
      backButton={false}
      otherButton={{
        label: translations.backToSettings[lang],
        onClick: () => history.push('/location-settings-menu/borderControl'),
      }}
    >
      <Section>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12}>
            <ScheduleLocationForm
              location={location}
              locationOptions={locationOptions}
              changeLocation={onchangeLocation}
              openSettings={setOpenSettingsDialog}
            />
          </Grid>
          {location && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.typography}>
                  {translations.texts[lang]}
                </Typography>
                {scheduleLocation && (
                  <LocationTemplateTexts
                    location={location}
                    scheduleLocation={scheduleLocation}
                    updateScheduleLocation={updateScheduleLocation}
                  />
                )}
              </Grid>
              <ScheduleSettingsForm
                open={openSettingsDialog}
                allowOverflowHeilsuvera={allowOverflow}
                showInHeilsuvera={showInHeilsuvera}
                cutoffTime={cutoffTime}
                cutoffTimeWeekends={cutoffTimeWeekends}
                daysBetweenSamplings={daysBetweenSamplings}
                onChange={saveSettingsChanges}
                onClose={setOpenSettingsDialog}
              />
            </>
          )}
        </Grid>
      </Section>
    </Layout>
  );

  async function fetchLocationData(locationId: number) {
    const response = await scheduleLocationService.getScheduleLocation(
      locationId
    );
    const locationData = response.data;
    if (response.isOk && locationData) {
      setScheduleLocation(locationData);
      mapCutOffTimesFromScheduleLocation(locationData);
      setAllowOverflow(locationData.isOverflowInHeilsuveraEnabled);
      setShowInHeilsuvera(locationData.showInHeilsuvera);
      if (locationData?.daysBetweenBookings) {
        setDaysBetweenSamplings(locationData.daysBetweenBookings.toString());
      }
    }
  }

  function onchangeLocation(val: any) {
    if (val) {
      setLocation({
        value: val.value,
        label: val.label,
        loginLocations: val.loginLocations,
      });
      localStorage.setItem(
        `${localStorageKeys.SCHEDULE_LOCATION_ID}`,
        val.value
      );
    } else {
      setLocation(null);
    }
  }

  async function mapCutOffTimesFromScheduleLocation(
    locationData: ScheduleLocation
  ) {
    setCutoffTimeWeekends(null);
    setCutoffTime(null);
    if (
      locationData.scheduleWeekendCutoffTimeHours !== undefined &&
      locationData.scheduleWeekendCutoffTimeMinutes !== undefined
    ) {
      setCutoffTimeWeekends(
        moment(
          `${locationData.scheduleWeekendCutoffTimeHours}:${locationData.scheduleWeekendCutoffTimeMinutes}`,
          'H:mm'
        )
      );
    }
    if (
      locationData.scheduleWeekendCutoffTimeHours !== undefined &&
      locationData.scheduleWeekendCutoffTimeMinutes !== undefined
    ) {
      setCutoffTime(
        moment(
          `${locationData.scheduleCutoffTimeHours}:${locationData.scheduleCutoffTimeMinutes}`,
          'H:mm'
        )
      );
    }
  }

  async function saveSettingsChanges(values: IFormValues, resetForm: any) {
    const payload: IUpdateScheduleLocationSettings = {
      scheduleLocationId: location?.value ? Number(location.value) : 0,
      cutOffTimeWeekdays: values?.cutoffTime
        ? values.cutoffTime.toISOString()
        : undefined,
      cutOffTimeWeekends: values?.cutoffTimeWeekends
        ? values.cutoffTimeWeekends.toISOString()
        : undefined,
      isOverflowInHeilsuverAllowed: values.allowOverflowHeilsuvera,
      daysBetweenBookings: values?.daysBetweenSamplings
        ? Number(values.daysBetweenSamplings)
        : undefined,
      showInHeilsuvera: values.showInHeilsuvera,
    };
    const response =
      await scheduleLocationService.updateScheduleLocationSettings(payload);
    if (response.isOk) {
      notifySuccess(translations.changesSaved[lang]);
      setOpenSettingsDialog(false);
      if (location?.value) {
        fetchLocationData(Number(location.value));
      }
      resetForm();
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function updateScheduleLocation(location: ScheduleLocation) {
    var data = await scheduleLocationService.updateScheduleLocation(location);
    if (data.isOk) {
      notifySuccess(translations.textUpdated[lang]);
      setScheduleLocation(data.data);
    } else {
      notifyError(translations.textUpdateError[lang]);
    }
  }
};

export default LocationSettingsPage;
