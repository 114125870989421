import { useSnackbar } from 'notistack'
import React from 'react'



const InnerSnackbarUtilsConfigurator = (props) => {
  props.setUseSnackbarRef(useSnackbar())
  return null;
}

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp
}

export const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
}

export const snackActions = {
  success(msg, autoHideDuration) {
    this.toast(msg, 'success', autoHideDuration)
  },
  warning(msg, autoHideDuration) {
    this.toast(msg, 'warning', autoHideDuration)
  },
  info(msg, autoHideDuration) {
    this.toast(msg, 'info', autoHideDuration)
  },
  error(msg, autoHideDuration) {
    this.toast(msg, 'error', autoHideDuration)
  },
  toast(msg, variant = 'default', autoHideDuration) {
    useSnackbarRef.enqueueSnackbar(msg, { variant: variant, autoHideDuration: autoHideDuration })
  }
}