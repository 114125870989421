import { useSnackbar } from 'notistack';

function useNotifier() {
  const { enqueueSnackbar } = useSnackbar();

  return {
    notifySuccess: (message: string) =>
      enqueueSnackbar(message, { variant: 'success' }),
    notifyError: (message: string) =>
      enqueueSnackbar(message, { variant: 'error', autoHideDuration: 4000 }),
    notifyWarning: (message: string) =>
      enqueueSnackbar(message, { variant: 'warning', autoHideDuration: 4000 }),
  };
}

export default useNotifier;
