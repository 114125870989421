import React, { useContext, useEffect } from 'react';
import ReactFlow, {
  ArrowHeadType,
  useZoomPanHelper,
} from 'react-flow-renderer';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { Typography } from '@material-ui/core';

import theme from '../theme';

interface IProps {
  flowType: 'certificate' | 'sampling' | 'transit' | 'quarantine';
}

const FlowCharts: React.FC<IProps> = ({ flowType }) => {
  const [lang] = useContext(LangContext);
  const { setCenter } = useZoomPanHelper();

  useEffect(() => {
    setCenter(590, 400, 0.7);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowType]);

  const initialNode = {
    background: theme.palette.primary.dark,
    border: `1px solid ${theme.palette.primary.dark}`,
    color: 'white',
  };

  const regularNode = {
    background: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
    color: 'white',
  };
  const edgeLabel = {
    fontSize: 12,
    fontFamily: theme.typography.fontFamily,
  };

  return renderFlowChart();

  function fetchElements() {
    if (flowType === 'certificate') {
      return getCertificateElements();
    }
    if (flowType === 'sampling') {
      return getSamplingElements();
    }
    if (flowType === 'transit') {
      return getTransitElements();
    }
    return getQuarantineElements();
  }

  function renderFlowChart() {
    return (
      <ReactFlow
        elements={fetchElements()}
        nodesDraggable={false}
        nodesConnectable={false}
        defaultZoom={0.7}
        defaultPosition={[0, 0]}
        style={{
          height: window.innerHeight - 300,
          width: '100%',
          background: 'white',
        }}
      />
    );
  }

  function getCertificateElements() {
    return [
      {
        id: 'certificate-1',
        type: 'input',
        data: {
          label: <Typography>{translations.certificate[lang]}</Typography>,
        },
        position: { x: 400, y: 5 },
        style: initialNode,
      },
      {
        id: 'certificate-2',
        data: {
          label: (
            <Typography>{translations.acceptCertificate[lang]}?</Typography>
          ),
        },
        position: { x: 400, y: 100 },
      },
      {
        id: 'certificate-3',
        data: {
          label: (
            <Typography>{translations.typeOfCertificate[lang]}?</Typography>
          ),
        },
        position: { x: 400, y: 200 },
      },
      {
        id: 'certificate-4',
        data: {
          label: (
            <Typography>{translations.positiveCertificate[lang]}</Typography>
          ),
        },
        position: { x: 10, y: 350 },
        style: regularNode,
      },
      {
        id: 'certificate-5',
        data: {
          label: (
            <Typography>{translations.antibodyCertificate[lang]}</Typography>
          ),
        },
        position: { x: 200, y: 350 },
        style: regularNode,
      },
      {
        id: 'certificate-6',
        data: {
          label: (
            <Typography>{translations.vaccinationCertificate[lang]}</Typography>
          ),
        },
        position: { x: 400, y: 350 },
        style: regularNode,
      },
      {
        id: 'certificate-7',
        data: {
          label: <Typography>{translations.otherCertificate[lang]}</Typography>,
        },
        position: { x: 600, y: 350 },
        style: regularNode,
      },
      {
        id: 'certificate-8',
        data: {
          label: (
            <Typography>{translations.vaccinationCertificate[lang]}</Typography>
          ),
        },
        position: { x: 800, y: 350 },
        style: regularNode,
      },
      {
        id: 'certificate-9',
        data: {
          label: <Typography>{translations.negativePCR[lang]}?</Typography>,
        },
        position: { x: 400, y: 650 },
      },
      {
        id: 'certificate-10',
        data: {
          label: (
            <Typography>{translations.relationsWithIceland[lang]}?</Typography>
          ),
        },
        position: { x: 400, y: 500 },
      },
      {
        id: 'certificate-11',
        data: {
          label: (
            <Typography>{translations.confirmRegistration[lang]}?</Typography>
          ),
        },
        position: { x: 400, y: 1000 },
      },
      {
        id: 'certificate-12',
        data: {
          label: (
            <Typography>{translations.registrationConfirmed[lang]}</Typography>
          ),
        },
        position: { x: 400, y: 1150 },
        style: regularNode,
      },
      {
        id: 'certificate-13',
        data: {
          label: <Typography>{translations.negativePCR[lang]}?</Typography>,
        },
        position: { x: 1000, y: 475 },
      },
      {
        id: 'certificate-14',
        data: {
          label: <Typography>{translations.fineAndFlagged[lang]}</Typography>,
        },
        position: { x: 775, y: 575 },
        style: regularNode,
      },
      {
        id: 'certificate-15',
        data: {
          label: (
            <Typography>{translations.samplingAtTheAirport[lang]}?</Typography>
          ),
        },
        position: { x: 700, y: 675 },
      },
      {
        id: 'certificate-16',
        data: {
          label: (
            <Typography>{translations.samplingAtTheAirport[lang]}</Typography>
          ),
        },
        position: { x: 600, y: 850 },
        style: regularNode,
      },
      {
        id: 'certificate-17',
        data: {
          label: (
            <Typography>{translations.rapidTestAtTheClinic[lang]}</Typography>
          ),
        },
        position: { x: 800, y: 850 },
        style: regularNode,
      },
      {
        id: 'certificate-18',
        data: {
          label: (
            <Typography>{translations.PCRTestAtTheAirport[lang]}</Typography>
          ),
        },
        position: { x: 1050, y: 850 },
        style: regularNode,
      },

      {
        id: 'certificate-19',
        data: {
          label: (
            <Typography>{`${translations.if[lang]} ${translations.rapidTestAtTheClinic[lang]}`}</Typography>
          ),
        },
        position: { x: 400, y: 1250 },
      },
      {
        id: 'certificate-20',
        data: {
          label: <Typography>{translations.AINCodeCreated[lang]}</Typography>,
        },
        position: { x: 700, y: 1400 },
        style: regularNode,
      },
      {
        id: 'certificate-21',
        data: {
          label: <Typography>{translations.changeStatus[lang]}?</Typography>,
        },
        position: { x: 700, y: 100 },
      },
      {
        id: 'certificate-22',
        data: {
          label: <Typography>{translations.sampling[lang]}</Typography>,
        },
        position: { x: 1000, y: 50 },
        style: initialNode,
      },
      {
        id: 'certificate-23',
        data: {
          label: <Typography>{translations.quarantine[lang]}</Typography>,
        },
        position: { x: 1000, y: 125 },
        style: initialNode,
      },
      {
        id: 'certificate-24',
        data: {
          label: <Typography>{translations.transit[lang]}</Typography>,
        },
        position: { x: 1000, y: 200 },
        style: initialNode,
      },

      {
        id: 'edge-1',
        source: 'certificate-1',
        target: 'certificate-2',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-2',
        source: 'certificate-2',
        target: 'certificate-3',
        label: translations.yes[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-3',
        source: 'certificate-3',
        target: 'certificate-4',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-4',
        source: 'certificate-3',
        target: 'certificate-5',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-5',
        source: 'certificate-3',
        target: 'certificate-6',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-6',
        source: 'certificate-3',
        target: 'certificate-7',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-7',
        source: 'certificate-3',
        target: 'certificate-8',
        label: translations.confirmBySampling[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-8',
        source: 'certificate-4',
        target: 'certificate-11',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-9',
        source: 'certificate-5',
        target: 'certificate-10',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-10',
        source: 'certificate-6',
        target: 'certificate-10',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-11',
        source: 'certificate-7',
        target: 'certificate-10',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-12',
        source: 'certificate-9',
        target: 'certificate-11',
        label: translations.confirmed[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-13',
        source: 'certificate-10',
        target: 'certificate-15',
        label: translations.yes[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-14',
        source: 'certificate-11',
        target: 'certificate-12',
        label: translations.yes[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-15',
        source: 'certificate-13',
        target: 'certificate-14',
        label: translations.no[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-16',
        source: 'certificate-9',
        target: 'certificate-14',
        label: translations.declined[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-17',
        source: 'certificate-8',
        target: 'certificate-13',

        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-18',
        source: 'certificate-10',
        target: 'certificate-9',
        label: translations.no[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-19',
        source: 'certificate-15',
        target: 'certificate-16',
        label: translations.yes[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-20',
        source: 'certificate-15',
        target: 'certificate-17',
        label: translations.no[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-21',
        source: 'certificate-14',
        target: 'certificate-18',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-22',
        source: 'certificate-16',
        target: 'certificate-11',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-23',
        source: 'certificate-17',
        target: 'certificate-11',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-24',
        source: 'certificate-18',
        target: 'certificate-11',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-25',
        source: 'certificate-13',
        target: 'certificate-18',
        label: translations.yes[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-26',
        source: 'certificate-12',
        target: 'certificate-19',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-27',
        source: 'certificate-19',
        target: 'certificate-20',
        label: translations.yes[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-28',
        source: 'certificate-2',
        target: 'certificate-21',
        label: translations.no[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-29',
        source: 'certificate-21',
        target: 'certificate-22',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-30',
        source: 'certificate-21',
        target: 'certificate-23',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-31',
        source: 'certificate-21',
        target: 'certificate-24',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
    ];
  }

  function getSamplingElements() {
    return [
      {
        id: 'sampling-1',
        type: 'input',
        data: {
          label: <Typography>{translations.sampling[lang]}</Typography>,
        },
        position: { x: 500, y: 0 },
        style: initialNode,
      },
      {
        id: 'sampling-2',
        data: {
          label: (
            <Typography>{translations.confirmRegistration[lang]}?</Typography>
          ),
        },
        position: { x: 500, y: 550 },
      },
      {
        id: 'sampling-3',
        data: {
          label: (
            <Typography>{translations.registrationConfirmed[lang]}</Typography>
          ),
        },
        position: { x: 500, y: 700 },
        style: regularNode,
      },
      {
        id: 'sampling-4',
        data: {
          label: <Typography>{translations.negativePCR[lang]}?</Typography>,
        },
        position: { x: 350, y: 200 },
      },
      {
        id: 'sampling-5',
        data: {
          label: <Typography>{translations.fineAndFlagged[lang]}</Typography>,
        },
        position: { x: 200, y: 325 },
        style: regularNode,
      },
      {
        id: 'sampling-6',
        type: 'input',
        data: {
          label: <Typography>{translations.certificate[lang]}</Typography>,
        },
        position: { x: 0, y: 200 },
        style: initialNode,
      },
      {
        id: 'sampling-7',
        data: {
          label: (
            <Typography>{translations.samplingAtTheAirport[lang]}</Typography>
          ),
        },
        position: { x: 500, y: 450 },
        style: regularNode,
      },
      {
        id: 'sampling-8',
        data: {
          label: (
            <Typography>{translations.relationsWithIceland[lang]}?</Typography>
          ),
        },
        position: { x: 500, y: 100 },
      },
      {
        id: 'samplingEdge-1',
        source: 'sampling-1',
        target: 'sampling-8',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'samplingEdge-2',
        source: 'sampling-4',
        target: 'sampling-5',
        label: translations.no[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'samplingEdge-3',
        source: 'sampling-4',
        target: 'sampling-7',
        label: translations.yes[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'samplingEdge-4',
        source: 'sampling-5',
        target: 'sampling-7',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'samplingEdge-5',
        source: 'sampling-2',
        target: 'sampling-3',
        label: translations.yes[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'samplingEdge-6',
        source: 'sampling-4',
        target: 'sampling-6',
        label: translations.otherCertificate[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'samplingEdge-7',
        source: 'sampling-7',
        target: 'sampling-2',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'samplingEdge-8',
        source: 'sampling-8',
        target: 'sampling-4',
        label: translations.no[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'samplingEdge-9',
        source: 'sampling-8',
        target: 'sampling-7',
        label: translations.yes[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
    ];
  }

  function getTransitElements() {
    return [
      {
        id: 'transit-1',
        type: 'input',
        data: {
          label: <Typography>{translations.transit[lang]}</Typography>,
        },
        position: { x: 500, y: 0 },
        style: initialNode,
      },
      {
        id: 'transit-2',
        data: {
          label: (
            <Typography>{translations.confirmRegistration[lang]}?</Typography>
          ),
        },
        position: { x: 350, y: 450 },
      },
      {
        id: 'transit-3',
        data: {
          label: (
            <Typography>{translations.registrationConfirmed[lang]}</Typography>
          ),
        },
        position: { x: 500, y: 600 },
        style: regularNode,
      },
      {
        id: 'transit-7',
        data: {
          label: (
            <Typography>{translations.registeredInTransit[lang]}</Typography>
          ),
        },
        position: { x: 500, y: 100 },
        style: regularNode,
      },
      {
        id: 'transit-8',
        data: {
          label: <Typography>{translations.confirmTransit[lang]}?</Typography>,
        },
        position: { x: 500, y: 250 },
      },
      {
        id: 'transit-9',
        data: {
          label: <Typography>{translations.changeStatus[lang]}?</Typography>,
        },
        position: { x: 650, y: 450 },
      },
      {
        id: 'transit-10',
        type: 'input',
        data: {
          label: <Typography>{translations.sampling[lang]}</Typography>,
        },
        position: { x: 925, y: 375 },
        style: initialNode,
      },
      {
        id: 'transit-11',
        type: 'input',
        data: {
          label: <Typography>{translations.quarantine[lang]}</Typography>,
        },
        position: { x: 925, y: 450 },
        style: initialNode,
      },
      {
        id: 'transit-12',
        type: 'input',
        data: {
          label: <Typography>{translations.certificate[lang]}</Typography>,
        },
        position: { x: 925, y: 525 },
        style: initialNode,
      },

      {
        id: 'transitEdge-1',
        source: 'transit-1',
        target: 'transit-7',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },

      {
        id: 'transitEdge-5',
        source: 'transit-2',
        target: 'transit-3',
        label: translations.yes[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'transitEdge-7',
        source: 'transit-7',
        target: 'transit-8',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'transitEdge-8',
        source: 'transit-8',
        target: 'transit-2',
        label: translations.yes[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'transitEdge-9',
        source: 'transit-8',
        target: 'transit-9',
        label: translations.no[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'transitEdge-10',
        source: 'transit-9',
        target: 'transit-10',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'transitEdge-11',
        source: 'transit-9',
        target: 'transit-11',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'transitEdge-12',
        source: 'transit-9',
        target: 'transit-12',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
    ];
  }

  function getQuarantineElements() {
    return [
      {
        id: 'quarantine-1',
        type: 'input',
        data: {
          label: <Typography>{translations.quarantine[lang]}</Typography>,
        },
        position: { x: 500, y: 0 },
        style: initialNode,
      },
      {
        id: 'quarantine-2',
        data: {
          label: (
            <Typography>{translations.confirmRegistration[lang]}?</Typography>
          ),
        },
        position: { x: 350, y: 550 },
      },
      {
        id: 'quarantine-3',
        data: {
          label: (
            <Typography>{translations.registrationConfirmed[lang]}</Typography>
          ),
        },
        position: { x: 500, y: 700 },
        style: regularNode,
      },
      {
        id: 'quarantine-4',
        data: {
          label: <Typography>{translations.negativePCR[lang]}?</Typography>,
        },
        position: { x: 500, y: 100 },
      },
      {
        id: 'quarantine-5',
        data: {
          label: <Typography>{translations.fineAndFlagged[lang]}</Typography>,
        },
        position: { x: 250, y: 200 },
        style: regularNode,
      },
      {
        id: 'quarantine-6',
        data: {
          label: <Typography>{translations.certificate[lang]}</Typography>,
        },
        position: { x: 100, y: 75 },
        style: initialNode,
      },
      {
        id: 'quarantine-7',
        data: {
          label: (
            <Typography>{translations.registeredInQuarantine[lang]}</Typography>
          ),
        },
        position: { x: 500, y: 300 },
        style: regularNode,
      },
      {
        id: 'quarantine-8',
        data: {
          label: (
            <Typography>{translations.confirmQuarantine[lang]}?</Typography>
          ),
        },
        position: { x: 500, y: 400 },
      },
      {
        id: 'quarantine-9',
        data: {
          label: <Typography>{translations.changeStatus[lang]}?</Typography>,
        },
        position: { x: 650, y: 550 },
      },
      {
        id: 'quarantine-10',
        type: 'input',
        data: {
          label: <Typography>{translations.sampling[lang]}</Typography>,
        },
        position: { x: 925, y: 475 },
        style: initialNode,
      },
      {
        id: 'quarantine-11',
        type: 'input',
        data: {
          label: <Typography>{translations.transit[lang]}</Typography>,
        },
        position: { x: 925, y: 550 },
        style: initialNode,
      },
      {
        id: 'quarantine-12',
        type: 'input',
        data: {
          label: <Typography>{translations.certificate[lang]}</Typography>,
        },
        position: { x: 925, y: 625 },
        style: initialNode,
      },

      {
        id: 'edge-1',
        source: 'quarantine-1',
        target: 'quarantine-4',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-2',
        source: 'quarantine-4',
        target: 'quarantine-5',
        label: translations.no[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-3',
        source: 'quarantine-4',
        target: 'quarantine-7',
        label: translations.yes[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-4',
        source: 'quarantine-5',
        target: 'quarantine-7',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-5',
        source: 'quarantine-2',
        target: 'quarantine-3',
        label: translations.yes[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-6',
        source: 'quarantine-4',
        target: 'quarantine-6',
        label: translations.otherCertificate[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-7',
        source: 'quarantine-7',
        target: 'quarantine-8',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-8',
        source: 'quarantine-8',
        target: 'quarantine-2',
        label: translations.yes[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-9',
        source: 'quarantine-8',
        target: 'quarantine-9',
        label: translations.no[lang],
        labelStyle: edgeLabel,
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-10',
        source: 'quarantine-9',
        target: 'quarantine-10',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-11',
        source: 'quarantine-9',
        target: 'quarantine-11',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
      {
        id: 'edge-12',
        source: 'quarantine-9',
        target: 'quarantine-12',
        //type: 'step',
        arrowHeadType: 'arrowclosed' as ArrowHeadType,
      },
    ];
  }
};

export default FlowCharts;
