import React, { useContext } from 'react';
import {
  Typography,
  makeStyles,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Error';

import {
  IPassengerDetailsNew as IPassengerDetails,
  IUpdatePassengerInfo,
} from '../interfaces/passenger';
import { Passenger } from '../models/Models';
import EditPassengerInfo from './EditPassengerInfo';
import ResidenceInfo from './ResidenceInfo';
import PassengerInfoComment from './PassengerInfoComment';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

interface IProps {
  passenger: Passenger;
  personalDetails: IPassengerDetails[];
  otherDetails: IPassengerDetails[];
  extraSamplingDetails: IPassengerDetails[];
  displayResidences?: boolean;
  displayComment?: boolean;
  displayQuarantineHotel?: boolean;
  reloadPassenger: () => Promise<void>;
  updateInfo?: (
    info: IUpdatePassengerInfo,
    onSuccess: () => void
  ) => Promise<void>;
  updateComment?: (comment: string) => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(5),
  },
  topItems: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  titleDivider: {
    display: 'inline-block',
    margin: theme.spacing(0, 1),
  },
  titleSerialNumber: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
  otherDetails: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
  },
  descriptionTitle: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  descriptionDetails: {
    color: theme.palette.text.secondary,
  },
  warningIcon: {
    position: 'absolute',
    top: '-2px',
    color: theme.palette.warning.light,
  },
}));

const PassengerInfo: React.FC<IProps> = ({
  passenger,
  personalDetails,
  otherDetails,
  extraSamplingDetails,
  displayResidences = true,
  displayComment = true,
  displayQuarantineHotel = true,
  reloadPassenger,
  updateInfo,
  updateComment,
}) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const mdOnly = useMediaQuery(theme.breakpoints.only('md'));
  const mdOrXs = mdOnly || xsDown;
  const samplingFinished = !!passenger.sampleTaken;

  return (
    <Paper elevation={2} className={classes.container}>
      {renderTopItems()}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {renderPersonalDetails()}
        </Grid>
        <Grid item xs={12}>
          {renderOtherDetails()}
        </Grid>
        {displayComment && (
          <Grid item xs={12}>
            {renderInfoItem({
              label: translations.comment[lang],
              renderInline: () => (
                <PassengerInfoComment
                  passenger={passenger}
                  editable={!samplingFinished}
                  updateComment={updateComment}
                />
              ),
            })}
          </Grid>
        )}
        {displayResidences && (
          <Grid item xs={12}>
            {renderInfoItem({
              label: translations.residences[lang],
              warning:
                !passenger.residences.length &&
                (!passenger.flowStatusesCompleted ||
                  passenger.flowStatusesCompleted.length < 1),
              hideLabel: mdOnly,
              render: () => (
                <ResidenceInfo
                  serialNumber={passenger.serialNumber}
                  residences={passenger.residences}
                  reloadPassenger={reloadPassenger}
                  editable={!samplingFinished}
                  compact={mdOnly}
                  topMargin={!mdOnly}
                />
              ),
            })}
          </Grid>
        )}
      </Grid>
    </Paper>
  );

  function renderTopItems() {
    return (
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={1}
        className={classes.topItems}
      >
        <Grid item>
          {!mdOrXs && (
            <>
              <Typography variant="h5" style={{ display: 'inline-block' }}>
                {translations.passengerInfo[lang]}
              </Typography>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.titleDivider}
              >
                {' - '}
              </Typography>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.titleSerialNumber}
              >
                {passenger.serialNumber}
              </Typography>
            </>
          )}
        </Grid>
        {updateInfo && (
          <Grid item>
            <EditPassengerInfo passenger={passenger} updateInfo={updateInfo} />
          </Grid>
        )}
      </Grid>
    );
  }

  function renderPersonalDetails() {
    const splitList = Math.ceil(personalDetails.length / 2);

    return (
      <Grid container spacing={xsDown ? 1 : 3} direction="row">
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            {personalDetails.slice(0, splitList).map((item, index) => (
              <Grid item xs={12} key={index}>
                {renderInfoItem(item)}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            {personalDetails.slice(splitList).map((item, index) => (
              <Grid item xs={12} key={index}>
                {renderInfoItem(item)}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderOtherDetails() {
    return (
      <div className={classes.otherDetails}>
        <Grid container spacing={xsDown ? 1 : 3} direction="row">
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              {otherDetails.map((item, index) => (
                <Grid item xs={12} key={index}>
                  {renderInfoItem(item)}
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              {extraSamplingDetails.map((item, index) => (
                <Grid item xs={12} key={index}>
                  {renderInfoItem(item)}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  function renderInfoItem({
    label,
    warning = false,
    hideLabel = false,
    getVal = () => '',
    render,
    renderInline,
    hide,
  }: IPassengerDetails) {
    if (hide && hide(passenger)) {
      return null;
    }

    const warningIconMargin = warning ? 30 : 0;

    if (!render) {
      return (
        <Typography style={{ position: 'relative' }}>
          {warning && <WarningIcon className={classes.warningIcon} />}
          <span
            className={classes.descriptionTitle}
            style={{ marginLeft: warningIconMargin }}
          >
            {label}:
          </span>
          <span className={classes.descriptionDetails}>
            {renderInline ? renderInline(passenger) : getVal(passenger)}
          </span>
        </Typography>
      );
    }

    return (
      <div style={{ position: 'relative' }}>
        {warning && <WarningIcon className={classes.warningIcon} />}
        {!hideLabel ? (
          <Typography
            className={classes.descriptionTitle}
            style={{ display: 'inline-block', marginLeft: warningIconMargin }}
          >
            {label}:
          </Typography>
        ) : (
          <span style={{ marginLeft: warningIconMargin }} />
        )}
        {render(passenger)}
      </div>
    );
  }

  /* function renderLinkedSampling() {
    if (!linkedSampling) {
      return null;
    }

    return (
      <Link href="#" onClick={linkedSampling.onClick}>
        <Typography variant="subtitle1">
          {linkedSampling.isFirst
            ? 'View second sampling'
            : 'View first sampling'}
        </Typography>
      </Link>
    );
  } */
};

export default PassengerInfo;
