import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import moment from 'moment';

import NoResults from './NoResults';
import { VaccinationGroup } from '../models/VaccinationModels';
import { TABLE_ROWS_OPTIONS, DATE_FORMAT } from '../constants';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

interface IProps {
  groups: VaccinationGroup[];
  currentPage: number;
  rowsPerPage: number;
  regionSelected: boolean;
  setCurrentPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
  onClick?: (group: VaccinationGroup) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
}));

const tableColumns: {
  [key: string]: {
    translation: { is: string; en: string };
    sort?: (a: VaccinationGroup, b: VaccinationGroup) => number;
  };
} = {
  name: {
    translation: translations.group,
    sort: (a: VaccinationGroup, b: VaccinationGroup) =>
      a.name.localeCompare(b.name),
  },
  description: {
    translation: translations.description,
    sort: (a: VaccinationGroup, b: VaccinationGroup) =>
      a.description.localeCompare(b.description),
  },
  region: {
    translation: translations.region,
    sort: (a: VaccinationGroup, b: VaccinationGroup) =>
      a.vaccinationRegion.name.localeCompare(b.vaccinationRegion.name),
  },
  summoned: {
    translation: translations.groupSummoned,
    sort: (a: VaccinationGroup, b: VaccinationGroup) => {
      if (!a.summonGroupDate) {
        return -1;
      }
      if (!b.summonGroupDate) {
        return 1;
      }
      return moment(a.summonGroupDate).isAfter(b.summonGroupDate) ? 1 : -1;
    },
  },
  priority: {
    translation: translations.priority,
    sort: (a: VaccinationGroup, b: VaccinationGroup) => a.priority - b.priority,
  },
  members: {
    translation: translations.members,
    sort: (a: VaccinationGroup, b: VaccinationGroup) =>
      a.totalCount - b.totalCount,
  },
  firstVaccination: {
    translation: translations.firstVaccinationFinished,
    sort: (a: VaccinationGroup, b: VaccinationGroup) => {
      /* const aTotal = a.vaccinatedFirstCount;
      const bTotal = b.vaccinatedFirstCount;
      return aTotal === bTotal
        ? a.vaccinatedFirstCount - b.vaccinatedFirstCount
        : aTotal - bTotal; */
      return a.vaccinatedFirstCount - b.vaccinatedFirstCount;
    },
  },
  secondVaccination: {
    translation: translations.secondVaccinationFinished,
    sort: (a: VaccinationGroup, b: VaccinationGroup) => {
      /* const aTotal = a.vaccinatedSecondCount;
      const bTotal = b.vaccinatedSecondCount;
      return aTotal === bTotal
        ? a.vaccinatedSecondCount - b.vaccinatedSecondCount
        : aTotal - bTotal; */
      return a.vaccinatedSecondCount - b.vaccinatedSecondCount;
    },
  },
};

const VaccinationGroupList: React.FC<IProps> = ({
  groups,
  currentPage,
  rowsPerPage,
  regionSelected,
  setCurrentPage,
  setRowsPerPage,
  onClick,
}) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);

  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortedGroups, setSortedGroups] = useState<VaccinationGroup[]>([]);
  const startIndex = currentPage * rowsPerPage;

  useEffect(() => {
    if (sortKey && tableColumns[sortKey]) {
      const sorted = tableColumns[sortKey].sort
        ? [...groups].sort(tableColumns[sortKey].sort)
        : [...groups];
      setSortedGroups(sortDirection === 'asc' ? sorted : sorted.reverse());
    } else {
      setSortedGroups(groups);
    }
  }, [groups, sortKey, sortDirection]);

  return (
    <Paper elevation={2} className={classes.container}>
      {groups.length ? (
        <React.Fragment>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {Object.keys(tableColumns)
                    .filter(
                      (key) =>
                        (key !== 'region' && regionSelected) ||
                        (key !== 'summoned' && !regionSelected)
                    )
                    .map((columnKey) => (
                      <TableCell key={columnKey}>
                        {tableColumns[columnKey].sort ? (
                          <TableSortLabel
                            active={sortKey === columnKey}
                            direction={sortDirection}
                            onClick={() => onSortClick(columnKey)}
                          >
                            {tableColumns[columnKey].translation[lang]}
                          </TableSortLabel>
                        ) : (
                            tableColumns[columnKey].translation[lang]
                          )}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedGroups
                  .slice(startIndex, startIndex + rowsPerPage)
                  .map((group) => (
                    <TableRow
                      hover={!!onClick}
                      key={group.id}
                      style={{ cursor: onClick ? 'pointer' : undefined }}
                      onClick={() => onClick && onClick(group)}
                    >
                      <TableCell>{group.name}</TableCell>
                      <TableCell>{group.description}</TableCell>
                      {regionSelected ? (
                        <TableCell>
                          {group.summonGroupDate
                            ? moment(group.summonGroupDate).format(DATE_FORMAT)
                            : ''}
                        </TableCell>
                      ) : (
                          <TableCell>{group.vaccinationRegion.name}</TableCell>
                        )}
                      <TableCell>{group.priority}</TableCell>
                      <TableCell>{group.totalCount}</TableCell>
                      <TableCell>
                        {`${group.vaccinatedFirstCount + group.rejectedFirstCount}(${group.rejectedFirstCount})`}
                      </TableCell>
                      <TableCell>
                        {`${group.vaccinatedSecondCount + group.rejectedSecondCount}(${group.rejectedSecondCount})`}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={translations.numberOfRowsPerPage[lang]}
            rowsPerPageOptions={TABLE_ROWS_OPTIONS}
            component="div"
            count={groups.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={(_, p) => setCurrentPage(p)}
            onChangeRowsPerPage={(e) => {
              setCurrentPage(0);
              setRowsPerPage(parseInt(e.target.value, 10));
            }}
          />
        </React.Fragment>
      ) : (
          <NoResults message={translations.noGroupsFound[lang]} />
        )}
    </Paper>
  );

  function onSortClick(columnKey: string) {
    if (sortKey === columnKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(columnKey);
      setSortDirection('asc');
    }
  }
};

export default VaccinationGroupList;
