import React, { useRef, useContext, useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import cx from 'classnames';
import { Formik, Form, FormikProps } from 'formik';
import moment, { Moment } from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Button from './Button';
import DatePicker from './DatePicker';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import AutocompleteFlag, { ICountryItem } from './AutocompleteFlag';
import countryCodes from '../assets/json/callingCodes2.json';
import { handleSsnInput } from '../utils';

interface IProps {
  onSubmit: (values: IFormValues) => void;
  onClear: () => void;
  collapsable?: boolean;
  isSuperSearch?: boolean;
}

export interface IFormValues {
  sampleId: string;
  firstName: string;
  lastName: string;
  flightNumber: string;
  callingCode: ICountryItem | null;
  phoneNumber: string;
  email: string;
  ssn: string;
  dateOfBirth: Moment | null;
  arrivalsFromDate: Moment | null;
  arrivalsToDate: Moment | null;
  samplingNumber: string;
}

type IFormik = FormikProps<IFormValues>;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  expandIcon: {
    color: theme.palette.primary.main,
    transform: 'rotate( 0deg)',
    transition: 'transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  expandedIcon: {
    color: theme.palette.primary.main,
    transform: 'rotate( 180deg)',
    transition: 'transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  expandTitle: {
    color: theme.palette.primary.main,
  },
}));

const SearchForm: React.FC<IProps> = ({ onSubmit, onClear, isSuperSearch }) => {
  const classes = useStyles({});
  const idInput = useRef<HTMLInputElement>(null);
  const [callingCodes, setCallingCodes] = useState<ICountryItem[]>([]);
  const [showAllForm, setShowAllForm] = useState(false);
  const [lang] = useContext(LangContext);
  const sortedCountryCodes = countryCodes.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  useEffect(() => {
    const setDialCodes = () => {
      const callCodes: ICountryItem[] = sortedCountryCodes.map((c) => ({
        name: c.name + ` (+${c.dial_code})`,
        code: c.dial_code as string,
        status: 0,
        flag: c.code as string,
      }));
      setCallingCodes(callCodes);
    };

    setDialCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        sampleId: '',
        firstName: '',
        lastName: '',
        flightNumber: '',
        callingCode: null as ICountryItem | null,
        phoneNumber: '',
        email: '',
        ssn: '',
        dateOfBirth: null as Moment | null,
        arrivalsFromDate: moment().subtract(1, 'days') as Moment | null,
        arrivalsToDate: moment().add(1, 'days') as Moment | null,
        samplingNumber: '',
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Paper elevation={2} className={classes.paper}>
            <Grid container spacing={2}>
              {renderFormFields(formik)}
              {renderButtons(formik)}
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );

  function renderFormFields({
    values,
    handleChange,
    handleBlur,
    setFieldValue,
  }: IFormik) {
    return (
      <React.Fragment>
        {isSuperSearch && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              autoFocus
              autoComplete="off"
              id="samplingNumber"
              label={translations.samplingNumber[lang]}
              variant="outlined"
              value={values.samplingNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoComplete="off"
            id="sampleId"
            label={translations.serialNumber[lang]}
            variant="outlined"
            inputProps={{ ref: idInput }}
            value={values.sampleId}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={6}>
          <TextField
            fullWidth
            id="ssn"
            autoComplete="off"
            label={translations.SSN[lang]}
            variant="outlined"
            value={values.ssn}
            onChange={(e) => handleSsnInput(e, handleChange)}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={6}>
          <DatePicker
            id="dateOfBirth"
            label={translations.dateOfBirth[lang]}
            value={values.dateOfBirth}
            muiProps={{ disableFuture: true }}
            onChange={(e) => setFieldValue('dateOfBirth', e ? moment(e) : null)}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <DatePicker
            id="arrivalsFromDate"
            label={translations.arrivalsFrom[lang]}
            value={values.arrivalsFromDate}
            muiProps={{
              disableToolbar: true,
              maxDate: values.arrivalsToDate || undefined,
            }}
            onChange={(e) =>
              setFieldValue('arrivalsFromDate', e ? moment(e) : null)
            }
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <DatePicker
            id="arrivalsToDate"
            label={translations.arrivalsTo[lang]}
            value={values.arrivalsToDate}
            muiProps={{
              disableToolbar: true,
              minDate: values.arrivalsFromDate || undefined,
            }}
            onChange={(e) =>
              setFieldValue('arrivalsToDate', e ? moment(e) : null)
            }
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <div
            onClick={() => setShowAllForm(!showAllForm)}
            style={{ cursor: 'pointer' }}
          >
            <Grid container alignItems="center" justify="flex-end" spacing={2}>
              <Grid item>
                <Typography className={classes.expandTitle}>
                  {translations.advancedSearch[lang].toUpperCase()}:
                </Typography>
              </Grid>
              <Grid>
                <ExpandMoreIcon
                  className={cx(classes.expandIcon, {
                    [classes.expandedIcon]: showAllForm,
                  })}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        {showAllForm && (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                autoComplete="off"
                id="lastName"
                label={translations.lastName[lang]}
                variant="outlined"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                autoComplete="off"
                id="firstName"
                label={translations.firstNames[lang]}
                variant="outlined"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                autoComplete="off"
                id="email"
                label={translations.email[lang]}
                variant="outlined"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AutocompleteFlag
                fullWidth
                id="callingCode"
                value={values.callingCode}
                label={translations.countryCode[lang]}
                items={callingCodes || []}
                onChange={(event, val) => {
                  handleChange(event);
                  setFieldValue('callingCode', val);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                id="phoneNumber"
                variant="outlined"
                inputProps={{
                  type: 'text',
                  pattern: '[0-9+]*',
                  maxLength: 15,
                  form: { autoComplete: 'off' },
                }}
                label={translations.phoneNumber[lang]}
                value={values.phoneNumber}
                onChange={(e) =>
                  !e.target.validity.patternMismatch && handleChange(e)
                }
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="flightNumber"
                autoComplete="off"
                label={translations.searchFlightNumber[lang]}
                variant="outlined"
                value={values.flightNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </>
        )}
      </React.Fragment>
    );
  }

  function renderButtons({ values, handleReset, isSubmitting }: IFormik) {
    const clearForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleReset(e);
      focusIdInput();
      onClear();
    };

    return (
      <React.Fragment>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            disabled={isSubmitting}
          >
            {translations.search[lang]}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button fullWidth variant="outlined" onClick={clearForm}>
            {translations.clear[lang]}
          </Button>
        </Grid>
      </React.Fragment>
    );
  }

  function focusIdInput() {
    if (idInput && idInput.current) {
      idInput.current.focus();
    }
  }
};

export default SearchForm;
