import React, { useContext, useState } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { useHistory } from 'react-router-dom';
import Button from './Button';
import { VaccinationRegistrationRequest } from '../models/VaccinationModels';
import { IVaccinationRegistrationRequestDetails } from '../interfaces/vaccinationWaitingList';
import WaitingListVaccinationStatusTable from './WaitingListVaccinationStatusTable';
import EditWaitingListDetails from './EditWaitingListDetails';
import moment from 'moment';
import theme from '../theme';
import callingCodes from '../assets/json/callingCodes2.json';
import { IUpdateRegistrationForVaccination } from '../interfaces/RegisterForVaccination';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ConfirmDialog from '../components/ConfirmDialog';
import * as vaccinationService from '../services/vaccinationService';
import { DATE_FORMAT } from '../constants';

interface IProps {
  selectedPerson: VaccinationRegistrationRequest;
  back: () => void;
  updateVaccinationRegistrationRequest: (
    info: IUpdateRegistrationForVaccination
  ) => Promise<void>;
  vaccinationRegistrationRequestDeleted: (id: number) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  descriptionTitle: {
    display: 'inline-block',
    fontWeight: 'bold',
    paddingRight: 10,
  },
  descriptionDetails: {
    display: 'inline-block',
  },
  buttonGrid: {
    textAlign: 'right',
    marginTop: theme.spacing(3),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  topItems: {
    marginBottom: theme.spacing(1),
  },
  editButton: {
    textAlign: 'right',
  },
  emptyText: {
    display: 'inline-block',
  },
}));

const VaccinationWaitingListDetails: React.FC<IProps> = ({
  selectedPerson,
  back,
  updateVaccinationRegistrationRequest,
  vaccinationRegistrationRequestDeleted,
}) => {
  const history = useHistory();
  const [lang] = useContext(LangContext);
  const classes = useStyles();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  return (
    <Paper elevation={2} className={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {getMemberGroupedDetails().map((item, index) =>
              renderGroupedInfo(item, index)
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginTop: theme.spacing(2) }}
      >
        <Grid item>
          <Button
            onClick={() => setIsDeleteDialogOpen(true)}
            variant="outlined"
            error
            disabled={isDeleteButtonDisabled(selectedPerson)}
            style={{ marginRight: theme.spacing(2) }}
          >
            {translations.delete[lang]}
          </Button>
          {renderDeleteDialog()}
        </Grid>

        <Grid item>
          <EditWaitingListDetails
            person={selectedPerson}
            updateInfo={updateVaccinationRegistrationRequest}
          />
          <Button
            onClick={() => back()}
            variant="outlined"
            style={{ marginLeft: theme.spacing(2) }}
          >
            {translations.back[lang]}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );

  function renderRegistrationSource(registrationSource: string) {
    if (registrationSource === 'internal-web') {
      return (
        <div style={{ display: 'inline-block' }}>
          <Grid container spacing={1} direction="row" alignItems="center">
            <Grid item>
              <Typography>
                {translations.registrationSourceInternal[lang]}
              </Typography>
            </Grid>
            <Grid item>
              <FiberManualRecordIcon color="primary"></FiberManualRecordIcon>
            </Grid>
          </Grid>
        </div>
      );
    }
    if (registrationSource === 'external-web') {
      return (
        <div style={{ display: 'inline-block' }}>
          <Grid container spacing={1} direction="row" alignItems="center">
            <Grid item>
              <Typography>
                {translations.registrationSourceExternal[lang]}
              </Typography>
            </Grid>
            <Grid item>
              <FiberManualRecordIcon color="secondary"></FiberManualRecordIcon>
            </Grid>
          </Grid>
        </div>
      );
    }
  }

  function getMemberGroupedDetails(): IVaccinationRegistrationRequestDetails[][] {
    const personDetails: IVaccinationRegistrationRequestDetails[][] = [
      [
        {
          label: translations.name[lang],
          getVal(residencePerson: VaccinationRegistrationRequest) {
            return residencePerson.personName;
          },
        },
        {
          label: translations.SSN[lang],
          getVal(residencePerson: VaccinationRegistrationRequest) {
            return residencePerson.ssn;
          },
        },
        {
          label: translations.nationality[lang],
          render: (residencePerson: VaccinationRegistrationRequest) =>
            renderNationality(residencePerson.nationality),
        },
        {
          label: translations.email[lang],
          getVal(residencePerson: VaccinationRegistrationRequest) {
            return residencePerson.email;
          },
        },
        {
          label: translations.phoneNumber[lang],
          getVal(residencePerson: VaccinationRegistrationRequest) {
            return `+${residencePerson.phoneCode} ${residencePerson.phoneNumber}`;
          },
        },
        {
          label: translations.hasCovidDiagnosis[lang],
          getVal(residencePerson: VaccinationRegistrationRequest) {
            return residencePerson.dateOfCovidDiagnosis
              ? `${moment(residencePerson.dateOfCovidDiagnosis).format(
                  DATE_FORMAT
                )}`
              : translations.never[lang];
          },
        },
        {
          label: translations.pregnant[lang],
          getVal(residencePerson: VaccinationRegistrationRequest) {
            return residencePerson.isPregnant
              ? translations.yes[lang]
              : translations.no[lang];
          },
          extraPadding: true,
        },

        {
          label: translations.twelveWeeksPregnant[lang],
          getVal(residencePerson: VaccinationRegistrationRequest) {
            return moment().isAfter(moment(residencePerson.dateOfTwelveWeeks))
              ? `${translations.yes[lang]} ${moment(
                  residencePerson.dateOfTwelveWeeks
                ).format(DATE_FORMAT)}`
              : `${translations.no[lang]} ${moment(
                  residencePerson.dateOfTwelveWeeks
                ).format(DATE_FORMAT)}`;
          },
          hide(residencePerson: VaccinationRegistrationRequest) {
            return !residencePerson.isPregnant;
          },
        },
        {
          label: translations.accuteAllergy[lang],
          getVal(residencePerson: VaccinationRegistrationRequest) {
            return residencePerson.hasAnAccuteAllergy
              ? translations.yes[lang]
              : translations.no[lang];
          },
        },
        {
          label: translations.isUsingImmonusuppresiveMedication[lang],
          getVal(residencePerson: VaccinationRegistrationRequest) {
            return residencePerson.isUsingImmonusuppresiveMedication
              ? translations.yes[lang]
              : translations.no[lang];
          },
        },
        {
          label: translations.region[lang],
          getVal(residencePerson: VaccinationRegistrationRequest) {
            return residencePerson.vaccinationRegion.name;
          },
        },
        {
          label: translations.location[lang],
          getVal(residencePerson: VaccinationRegistrationRequest) {
            return residencePerson.vaccinationLocation.name;
          },
        },
        {
          label: translations.comment[lang],
          getVal(residencePerson: VaccinationRegistrationRequest) {
            return residencePerson.comments;
          },
        },
        {
          label: translations.registered2[lang],
          render: (residencePerson: VaccinationRegistrationRequest) =>
            renderRegistrationSource(residencePerson.registrationSource),
        },
      ],
      [
        {
          // Óstaðfestar bólusetningar
          label: translations.previousClaimedVaccinations[lang],
          render: (vaccinationPerson: VaccinationRegistrationRequest) => (
            <WaitingListVaccinationStatusTable
              vaccinations={vaccinationPerson.previousClaimedVaccinations}
            />
          ),
        },
        {
          // Staðfestar bólusetningar
          label: translations.previousVerifiedVaccinations[lang],
          extraPadding: true,
          render: (vaccinationPerson: VaccinationRegistrationRequest) => (
            <WaitingListVaccinationStatusTable
              vaccinations={vaccinationPerson.previousVerifiedVaccinations}
            />
          ),
        },
      ],
    ];

    return personDetails;
  }

  function renderGroupedInfo(
    groupeInfo: IVaccinationRegistrationRequestDetails[],
    index: number
  ) {
    return (
      <Grid item xs={12} sm={12} md={6} key={index}>
        <Grid container spacing={0}>
          {groupeInfo.map((item, index) => renderInfoItem(item, index))}
        </Grid>
      </Grid>
    );
  }

  function renderNationality(nationalityCode: string) {
    if (nationalityCode) {
      const nat = callingCodes.find(
        (x) => x.code.toLowerCase() === nationalityCode.toLowerCase()
      );
      if (nat) {
        return (
          <div style={{ display: 'inline-block' }}>
            <Grid container spacing={1} direction="row" alignItems="center">
              <Grid item>
                <img
                  className="MuiPaper-elevation3"
                  loading="lazy"
                  width="20"
                  style={{ marginTop: 5 }}
                  src={require(`../assets/flags/${nat.code.toLowerCase()}.svg`)}
                  alt=""
                />
              </Grid>
              <Grid item>
                <Typography>{nat.name}</Typography>
              </Grid>
            </Grid>
          </div>
        );
      }
    }
    return '';
  }

  function renderInfoItem(
    {
      label,
      render,
      getVal = () => '',
      hide,
      extraPadding,
    }: IVaccinationRegistrationRequestDetails,
    index: number
  ) {
    return hide && hide(selectedPerson) ? null : (
      <Grid
        item
        xs={12}
        key={index}
        style={
          extraPadding
            ? { padding: 2, paddingTop: theme.spacing(3) }
            : { padding: 2 }
        }
      >
        <Typography className={classes.descriptionTitle}>{label}:</Typography>
        {render ? (
          render(selectedPerson)
        ) : (
          <Typography
            className={classes.descriptionDetails}
            color="textSecondary"
          >
            {getVal(selectedPerson)}
          </Typography>
        )}
      </Grid>
    );
  }

  function isDeleteButtonDisabled(person: VaccinationRegistrationRequest) {
    // registrationRequestStatusId
    // New = 0,
    // Processed = 1,
    // Rejected = 2,
    // Deleted = 3
    // Delete only possible when new
    if (person.registrationRequestStatusId === 0) {
      return false;
    } else {
      return true;
    }
  }

  function renderDeleteDialog() {
    return (
      <ConfirmDialog
        open={isDeleteDialogOpen}
        title={translations.DeleteRegistration[lang]}
        text={translations.DeleteThisRegistration[lang]}
        onConfirm={() => deleteVaccinationRegistrationRequest(selectedPerson)}
        onCancel={() => setIsDeleteDialogOpen(false)}
        confirmText={translations.yes[lang]}
      />
    );
  }

  async function deleteVaccinationRegistrationRequest(
    person: VaccinationRegistrationRequest
  ) {
    const response =
      await vaccinationService.updateVaccinationRegistrationRequestStatus(
        person.id,
        3 // Deleted = 3
      );
    if (response.isOk) {
      vaccinationRegistrationRequestDeleted(person.id);
      setIsDeleteDialogOpen(false);
      back();
    }
    if (response.statusCode === 401) {
      history.push('/login');
    }
  }
};

export default VaccinationWaitingListDetails;
