import React, { useContext } from 'react';
import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ScheduleIcon from '@material-ui/icons/Schedule';
import moment from 'moment';

import { BorderControlStatus } from '../models/Models';
import { DATE_FORMAT, TIME_FORMAT, DATE_AND_TIME_FORMAT } from '../constants';
import { borderControlStatus } from '../constants/enums';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

interface IProps {
  status: Partial<BorderControlStatus>;
  sampleTaken?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  checkIcon: {
    color: theme.palette.success.main,
    position: 'absolute',
  },
  timeIcon: {
    color: theme.palette.text.secondary,
    margin: '0px 2px 0px 4px',
  },
  statusText: {
    paddingLeft: 28,
  },
}));

const SamplingStatus: React.FC<IProps> = ({ status, sampleTaken }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [lang] = useContext(LangContext);
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const statusText = status
    ? (status.description && status.description[lang]) || ''
    : '';

  if (
    !sampleTaken ||
    !(
      status.id === borderControlStatus.SAMPLING_COMPLETED ||
      status.id === borderControlStatus.CHECKED_OUT
    )
  ) {
    return (
      <Typography className={classes.container} color="textSecondary">
        {statusText}
      </Typography>
    );
  }

  const sampleTime = moment(sampleTaken);

  if (status.id === borderControlStatus.CHECKED_OUT) {
    return (
      <Typography className={classes.container} color="textSecondary">
        {statusText} ({translations.samplingCompleted[lang]}:{' '}
        {sampleTime.format(DATE_AND_TIME_FORMAT)})
      </Typography>
    );
  }

  if (xsDown) {
    return (
      <div className={classes.container}>
        <CheckCircleIcon className={classes.checkIcon} />
        <Typography color="textSecondary" className={classes.statusText}>
          {statusText} {sampleTime.format(DATE_AND_TIME_FORMAT)}
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <CheckCircleIcon className={classes.checkIcon} />
      <Typography color="textSecondary" className={classes.statusText}>
        {statusText} {sampleTime.format(DATE_FORMAT)}
      </Typography>
      <ScheduleIcon fontSize="small" className={classes.timeIcon} />
      <Typography color="textSecondary">
        {sampleTime.format(TIME_FORMAT)}
      </Typography>
    </div>
  );
};

export default SamplingStatus;
