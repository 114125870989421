import React, { useContext, useState, useEffect } from 'react';
import { Typography, makeStyles, Grid, Paper } from '@material-ui/core';

import { Vaccine, VaccinationLocation } from '../models/VaccinationModels';
import Button from './Button';
import ConfirmDialog from './ConfirmDialog';
import Dropdown from './Dropdown';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { getFormattedVaccineName } from '../utils';
import * as localStorageKeys from '../constants/localStorageKeys';

import RadioButton from './RadioButton';
import { typeOfVaccination } from '../constants/enums';
import AutocompleteGroup, { IGroupOption } from './AutocompleteGroup';

export interface IProps {
  vaccines: Vaccine[] | null;
  influenzaVaccines: Vaccine[] | null;
  locations: VaccinationLocation[] | null;
  selectedVaccine: Vaccine | null;
  selectedLocation: VaccinationLocation | null;
  selectedTypeOfVaccination: string;
  selectedInfluenzaVaccine: Vaccine | null;
  setSelectedVaccine: (vaccine: Vaccine | null) => void;
  setSelectedLocation: (location: VaccinationLocation) => void;
  setSelectedTypeOfVaccination: (type: string) => void;
  setSelectedInfluenzaVaccine: (vaccine: Vaccine | null) => void;
  hideInfluenza?: boolean;
}

const useStyles = makeStyles((theme) => ({
  options: {
    padding: theme.spacing(3),
  },
  optionsTitle: {
    marginBottom: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.main,
  },
  descriptionTitle: {
    fontWeight: 'bold',
    paddingRight: 10,
  },
  descriptionDetails: {
    color: theme.palette.text.secondary,
  },
  editButton: {
    marginLeft: theme.spacing(2),
  },
}));

const VaccinationSettings: React.FC<IProps> = ({
  vaccines,
  influenzaVaccines,
  locations,
  selectedVaccine,
  selectedLocation,
  selectedTypeOfVaccination,
  selectedInfluenzaVaccine,
  setSelectedVaccine,
  setSelectedLocation,
  setSelectedTypeOfVaccination,
  setSelectedInfluenzaVaccine,
  hideInfluenza,
}) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [updatedVaccineId, setUpdatedVaccineId] = useState<IGroupOption | null>(
    null
  );

  const [updateInfluenzaVaccineId, setUpdateInfluenzaVaccineId] =
    useState<IGroupOption | null>(null);
  const [updatedLocationId, setUpdatedLocationId] = useState('');
  // const [updatedFirstOrSecond] = useState('doesNotApply');

  const [updatedTypeOfVaccination, setUpdatedTypeOfVaccination] = useState('');

  const loading = vaccines === null || locations === null;
  const filteredVaccines = vaccines
    ? vaccines.filter((v) => v.vaccineIsActive)
    : [];

  const filteredInfluenzaVaccines = influenzaVaccines
    ? influenzaVaccines.filter((v) => v.vaccineIsActive)
    : [];

  useEffect(() => {
    if (
      !loading &&
      !selectedTypeOfVaccination &&
      (!selectedInfluenzaVaccine || !selectedVaccine)
    ) {
      setEditDialogOpen(true);
    }
  }, [
    loading,
    selectedTypeOfVaccination,
    selectedInfluenzaVaccine,
    selectedVaccine,
  ]);

  useEffect(() => {
    setUpdatedVaccineId(
      selectedVaccine
        ? {
            label: getFormattedVaccineName(selectedVaccine),
            value: selectedVaccine.id.toString(),
            filter: selectedVaccine.name,
          }
        : null
    );
    setUpdatedLocationId(
      selectedLocation ? selectedLocation.id.toString() : ''
    );
    setUpdateInfluenzaVaccineId(
      selectedInfluenzaVaccine
        ? {
            label: getFormattedVaccineName(selectedInfluenzaVaccine),
            value: selectedInfluenzaVaccine.id.toString(),
            filter: selectedInfluenzaVaccine.name,
          }
        : null
    );
    setUpdatedTypeOfVaccination(
      selectedTypeOfVaccination ? selectedTypeOfVaccination : ''
    );
  }, [
    selectedVaccine,
    selectedLocation,
    selectedInfluenzaVaccine,
    selectedTypeOfVaccination,
    editDialogOpen,
  ]);

  return (
    <Paper elevation={2} className={classes.options}>
      <Grid container direction="row" alignItems="flex-start">
        <div style={{ flex: 1 }}>
          {loading ? (
            <Typography
              className={classes.descriptionDetails}
              color="textSecondary"
            >
              {translations.loadingSettings[lang]}
            </Typography>
          ) : (
            <Grid container direction="column" spacing={1}>
              {!hideInfluenza && (
                <Grid item>
                  {renderInfoItem(
                    translations.typeOfVaccination[lang],
                    selectedTypeOfVaccination === typeOfVaccination.COVID_19
                      ? 'COVID-19'
                      : selectedTypeOfVaccination ===
                        typeOfVaccination.INFLUENZA
                      ? translations.influenza[lang]
                      : selectedTypeOfVaccination ===
                        typeOfVaccination.COVID_19_AND_INFLUENZA
                      ? translations.covidInfluenza[lang]
                      : null
                  )}
                </Grid>
              )}

              <Grid item>
                {renderInfoItem(
                  translations.covid19Vaccine[lang],
                  selectedVaccine
                    ? getFormattedVaccineName(selectedVaccine)
                    : null
                )}
              </Grid>

              {!hideInfluenza && (
                <Grid item>
                  {renderInfoItem(
                    translations.influenzaVaccine[lang],
                    selectedInfluenzaVaccine
                      ? getFormattedVaccineName(selectedInfluenzaVaccine)
                      : null
                  )}
                </Grid>
              )}

              <Grid item>
                {renderInfoItem(
                  translations.location[lang],
                  selectedLocation ? selectedLocation.name : null
                )}
              </Grid>
            </Grid>
          )}
        </div>
        <Button
          padding={false}
          color="secondary"
          disabled={loading}
          onClick={() => setEditDialogOpen(true)}
          className={classes.editButton}
        >
          {translations.edit[lang]}
        </Button>
      </Grid>
      {renderDialog()}
    </Paper>
  );

  function renderInfoItem(title: string, text: string | null) {
    return (
      <Typography>
        <span className={classes.descriptionTitle}>{title}:</span>
        {text ? (
          <span className={classes.descriptionDetails}>{text}</span>
        ) : (
          <span className={classes.errorText}>
            {translations.notRegistered[lang]}
          </span>
        )}
      </Typography>
    );
  }

  function renderDialog() {
    return (
      <ConfirmDialog
        open={editDialogOpen}
        title={translations.settings[lang]}
        disableConfirm={
          !updatedLocationId ||
          !updatedTypeOfVaccination ||
          (!updatedVaccineId &&
            !(updatedTypeOfVaccination === typeOfVaccination.INFLUENZA)) ||
          (!updateInfluenzaVaccineId &&
            !(updatedTypeOfVaccination === typeOfVaccination.COVID_19)) ||
          (updatedVaccineId?.value === selectedVaccine?.id.toString() &&
            updatedLocationId === selectedLocation?.id.toString() &&
            updateInfluenzaVaccineId?.value ===
              selectedInfluenzaVaccine?.id.toString() &&
            updatedTypeOfVaccination === selectedTypeOfVaccination)
        }
        onConfirm={updateSettings}
        onCancel={() => setEditDialogOpen(false)}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <RadioButton
              row
              label={translations.typeOfVaccination[lang]}
              value={updatedTypeOfVaccination}
              error={!updatedTypeOfVaccination}
              options={[
                {
                  label: 'COVID-19',
                  value: typeOfVaccination.COVID_19,
                },
                {
                  label: translations.influenza[lang],
                  value: typeOfVaccination.INFLUENZA,
                },
                {
                  label: translations.covidInfluenza[lang],
                  value: typeOfVaccination.COVID_19_AND_INFLUENZA,
                },
              ]}
              onChange={(e) =>
                setUpdatedTypeOfVaccination(e.target.value as string)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteGroup
              fullWidth
              label={translations.covid19Vaccine[lang]}
              id="vaccine"
              value={updatedVaccineId}
              items={filteredVaccines
                .map((v) => ({
                  label: getFormattedVaccineName(v),
                  value: v.id.toString(),
                  filter: v.name,
                }))
                .sort((a, b) => a.filter.localeCompare(b.filter))}
              /* disabled={
                updatedTypeOfVaccination === typeOfVaccination.INFLUENZA
              } */
              error={
                !updatedVaccineId &&
                !(updatedTypeOfVaccination === typeOfVaccination.INFLUENZA)
              }
              onChange={(val) =>
                setUpdatedVaccineId(val as IGroupOption | null)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteGroup
              fullWidth
              label={translations.influenzaVaccine[lang]}
              id="influenzaVaccine"
              value={updateInfluenzaVaccineId}
              items={filteredInfluenzaVaccines
                .map((v) => ({
                  label: getFormattedVaccineName(v),
                  value: v.id.toString(),
                  filter: v.name,
                }))
                .sort((a, b) => a.filter.localeCompare(b.filter))}
              // disabled={updatedTypeOfVaccination === typeOfVaccination.COVID_19}
              error={
                !updateInfluenzaVaccineId &&
                !(updatedTypeOfVaccination === typeOfVaccination.COVID_19)
              }
              onChange={(val) =>
                setUpdateInfluenzaVaccineId(val as IGroupOption | null)
              }
            />
          </Grid>
          <Grid item>
            <Dropdown
              fullWidth
              id="location"
              value={updatedLocationId}
              label={translations.location[lang]}
              items={(locations || []).map((l) => ({
                label: l.name,
                value: l.id.toString(),
              }))}
              onChange={(e) => setUpdatedLocationId(e.target.value as string)}
              error={!updatedLocationId}
              disabled={!locations || locations.length <= 1}
            />
          </Grid>
        </Grid>
      </ConfirmDialog>
    );
  }

  function updateSettings() {
    const updatedVaccine = filteredVaccines?.find(
      (v) => v.id.toString() === updatedVaccineId?.value
    );
    const updatedInfluenzaVaccine = filteredInfluenzaVaccines?.find(
      (v) => v.id.toString() === updateInfluenzaVaccineId?.value
    );
    const updatedLocation = locations?.find(
      (l) => l.id.toString() === updatedLocationId
    );

    if (updatedVaccine && updatedVaccineId) {
      setSelectedVaccine(updatedVaccine);
      localStorage.setItem(localStorageKeys.VACCINE_ID, updatedVaccineId.value);
    }
    if (updatedLocation) {
      setSelectedLocation(updatedLocation);
      localStorage.setItem(
        localStorageKeys.VACCINATION_LOCATION_ID,
        updatedLocationId
      );
    }
    if (updateInfluenzaVaccineId && updatedInfluenzaVaccine) {
      setSelectedInfluenzaVaccine(updatedInfluenzaVaccine);
      localStorage.setItem(
        localStorageKeys.INFLUENZA_VACCINE_ID,
        updateInfluenzaVaccineId.value
      );
    }
    if (updatedTypeOfVaccination) {
      setSelectedTypeOfVaccination(updatedTypeOfVaccination);
      localStorage.setItem(
        localStorageKeys.TYPE_OF_VACCINATION,
        updatedTypeOfVaccination
      );
    }

    setEditDialogOpen(false);
  }
};

export default VaccinationSettings;
