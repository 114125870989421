import React, {
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  Paper,
  Grid,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';
import debounce from 'lodash.debounce';
import moment, { Moment } from 'moment';
import { Formik, Form } from 'formik';
import ArrowIcon from '@material-ui/icons/ArrowForward';

import Button from './Button';
import Autocomplete, { IOption } from './Autocomplete';
import DatePicker from './DatePicker';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import {
  VaccinationPerson,
  VaccinationComment,
} from '../models/VaccinationModels';
import {
  OTHER_COMMENT_STATUS_ID,
  OTHER_COMMENT_STATUS_TEXT,
} from '../constants';
import { toISOStringTimezone } from '../utils';
import * as localStorageKeys from '../constants/localStorageKeys';

interface IProps {
  members: VaccinationPerson[];
  filterMembers: (members: VaccinationPerson[]) => void;
  selectRows: (numberOfRows: number) => void;
}

const VACCINATION_STATUS_NEITHER_KEY = 'neither';
const VACCINATION_STATUS_FIRST_KEY = 'first';
const VACCINATION_STATUS_BOTH_KEY = 'both';
const VACCINATION_STATUS_OPTIONS: {
  [key: string]:
    | 'vaccinationNotStarted'
    | 'vaccinationOnlyFirstFinished'
    | 'vaccinationSecondFinished';
} = {
  [VACCINATION_STATUS_NEITHER_KEY]: 'vaccinationNotStarted',
  [VACCINATION_STATUS_FIRST_KEY]: 'vaccinationOnlyFirstFinished',
  [VACCINATION_STATUS_BOTH_KEY]: 'vaccinationSecondFinished',
};

const VaccinationGroupMemberFilter: React.FC<IProps> = ({
  members,
  filterMembers,
  selectRows,
}) => {
  const theme = useTheme();
  const [lang] = useContext(LangContext);
  const nameRef = useRef<HTMLInputElement>();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [nameInput, setNameInput] = useState('');
  const [nameInputFilter, setNameInputFilter] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [vaccineOptions, setVaccineOptions] = useState<{
    [atc: string]: string;
  }>({});
  const [selectedVaccines, setSelectedVaccines] = useState<string[]>([]);
  const [commentOptions, setCommentOptions] = useState<{
    [id: string]: string;
  }>({});
  const [selectedComments, setSelectedComments] = useState<string[]>([]);
  const [selectedFromDate, setSelectedFromDate] = useState<Moment | null>(null);
  const [selectedToDate, setSelectedToDate] = useState<Moment | null>(null);
  const [locationOptions, setLocationOptions] = useState<{
    [id: string]: string;
  }>({});
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [filteredCount, setFilteredCount] = useState(0);
  const debouncedNameUpdate = useCallback(
    debounce(setNameInputFilter, 500),
    []
  );

  useEffect(() => {
    const filtered = members.filter(
      (member) =>
        filterByName(member.name, nameInputFilter) &&
        filterByStatus(member, selectedStatus) &&
        filterByVaccine(member.firstVaccination.atc, selectedVaccines) &&
        filterByComment(member.vaccinationComment, selectedComments) &&
        filterByFirstVaccinationDate(
          member.firstVaccination.vaccinationDate,
          selectedFromDate,
          selectedToDate
        ) &&
        filterByFirstVaccinationLocation(
          member.firstVaccination.vaccinationLocationName,
          selectedLocations
        )
    );
    filterMembers(filtered);
    setFilteredCount(filtered.length);
  }, [
    members,
    filterMembers,
    nameInputFilter,
    selectedStatus,
    selectedVaccines,
    selectedComments,
    selectedFromDate,
    selectedToDate,
    selectedLocations,
  ]);

  useEffect(() => {
    const vaccines: { [atc: string]: string } = {};
    members.forEach((member) => {
      const vaccineAtc = member.firstVaccination.atc || '';
      if (
        vaccineAtc &&
        member.firstVaccination.vaccineName &&
        !vaccines[vaccineAtc]
      ) {
        vaccines[vaccineAtc] = member.firstVaccination.vaccineName;
      }
    });
    setVaccineOptions(vaccines);

    const comments: { [id: string]: string } = {};
    members.forEach((member) => {
      const statusId =
        member.vaccinationComment.statusId !== undefined
          ? member.vaccinationComment.statusId.toString()
          : '';
      if (statusId) {
        if (
          member.vaccinationComment.statusDescription &&
          !comments[statusId]
        ) {
          comments[statusId] = member.vaccinationComment.statusDescription;
        }
      } else if (
        member.vaccinationComment &&
        !comments[OTHER_COMMENT_STATUS_ID]
      ) {
        comments[OTHER_COMMENT_STATUS_ID] = OTHER_COMMENT_STATUS_TEXT;
      }
    });
    setCommentOptions(comments);

    const locations: { [id: string]: string } = {};
    members.forEach((member) => {
      const locationName = member.firstVaccination.vaccinationLocationName;
      if (locationName && !locations[locationName]) {
        locations[locationName] = locationName;
      }
    });
    setLocationOptions(locations);
    setOptionsLoaded(true);
  }, [members]);

  useEffect(() => {
    if (optionsLoaded) {
      const savedStatus: string[] = JSON.parse(
        localStorage.getItem(localStorageKeys.GROUP_MEMBER_FILTER_STATUS) ||
          '[]'
      );
      const savedVaccines: string[] = JSON.parse(
        localStorage.getItem(localStorageKeys.GROUP_MEMBER_FILTER_VACCINES) ||
          '[]'
      );
      const savedComments: string[] = JSON.parse(
        localStorage.getItem(localStorageKeys.GROUP_MEMBER_FILTER_COMMENTS) ||
          '[]'
      );
      const savedFrom = localStorage.getItem(
        localStorageKeys.GROUP_MEMBER_FILTER_FROM
      );
      const savedTo = localStorage.getItem(
        localStorageKeys.GROUP_MEMBER_FILTER_TO
      );
      const savedLocations: string[] = JSON.parse(
        localStorage.getItem(localStorageKeys.GROUP_MEMBER_FILTER_LOCATIONS) ||
          '[]'
      );

      setSelectedStatus(
        savedStatus.filter((key) => !!VACCINATION_STATUS_OPTIONS[key])
      );
      setSelectedVaccines(savedVaccines.filter((atc) => !!vaccineOptions[atc]));
      setSelectedComments(savedComments.filter((id) => !!commentOptions[id]));
      setSelectedFromDate(savedFrom ? moment(savedFrom) : null);
      setSelectedToDate(savedTo ? moment(savedTo) : null);
      setSelectedLocations(
        savedLocations.filter((id) => !!locationOptions[id])
      );
      setFiltersLoaded(true);
    }
  }, [optionsLoaded, vaccineOptions, commentOptions, locationOptions]);

  useEffect(() => {
    if (filtersLoaded) {
      localStorage.setItem(
        localStorageKeys.GROUP_MEMBER_FILTER_STATUS,
        JSON.stringify(selectedStatus)
      );
      localStorage.setItem(
        localStorageKeys.GROUP_MEMBER_FILTER_VACCINES,
        JSON.stringify(selectedVaccines)
      );
      localStorage.setItem(
        localStorageKeys.GROUP_MEMBER_FILTER_COMMENTS,
        JSON.stringify(selectedComments)
      );
      localStorage.setItem(
        localStorageKeys.GROUP_MEMBER_FILTER_FROM,
        selectedFromDate ? toISOStringTimezone(selectedFromDate) : ''
      );
      localStorage.setItem(
        localStorageKeys.GROUP_MEMBER_FILTER_TO,
        selectedToDate ? toISOStringTimezone(selectedToDate) : ''
      );
      localStorage.setItem(
        localStorageKeys.GROUP_MEMBER_FILTER_LOCATIONS,
        JSON.stringify(selectedLocations)
      );
    }
  }, [
    filtersLoaded,
    selectedStatus,
    selectedVaccines,
    selectedComments,
    selectedFromDate,
    selectedToDate,
    selectedLocations,
  ]);

  return (
    <Grid>
      <Paper elevation={2} style={{ padding: theme.spacing(2) }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ marginBottom: theme.spacing(1) }}
        >
          <Grid item>
            <Typography variant="h6">
              {translations.filterHeader[lang]}
            </Typography>
          </Grid>
        </Grid>
        {renderFilters()}
      </Paper>
    </Grid>
  );

  function renderFilters() {
    return (
      <Formik
        initialValues={{ selectRows: '' }}
        onSubmit={(values) => submitRows(values.selectRows)}
      >
        {({ values, handleChange, handleSubmit, handleReset }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={3}>
              <TextField
                fullWidth
                autoFocus
                autoComplete="off"
                id="name"
                variant="outlined"
                inputRef={nameRef}
                label={translations.name[lang]}
                value={nameInput}
                onChange={(e) => {
                  setNameInput(e.target.value);
                  debouncedNameUpdate(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                fullWidth
                checkBox
                id="vaccinationStatus"
                label={translations.vaccinationStatus[lang]}
                onChange={(selectedItems: any) =>
                  setSelectedStatus(
                    selectedItems.map((item: IOption) => item.value)
                  )
                }
                values={selectedStatus.map((key) => ({
                  value: key,
                  label: translations[VACCINATION_STATUS_OPTIONS[key]][lang],
                }))}
                items={Object.keys(VACCINATION_STATUS_OPTIONS).map((key) => ({
                  value: key,
                  label: translations[VACCINATION_STATUS_OPTIONS[key]][lang],
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                fullWidth
                checkBox
                id="vaccine"
                label={translations.vaccine[lang]}
                onChange={(selectedItems: any) =>
                  setSelectedVaccines(
                    selectedItems.map((item: IOption) => item.value)
                  )
                }
                values={selectedVaccines.map((atc) => ({
                  value: atc,
                  label: vaccineOptions[atc],
                }))}
                items={Object.keys(vaccineOptions).map((atc) => ({
                  value: atc,
                  label: vaccineOptions[atc],
                }))}
                disabled={!Object.keys(vaccineOptions).length}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                fullWidth
                checkBox
                id="comment"
                label={translations.comment[lang]}
                onChange={(selectedItems: any) =>
                  setSelectedComments(
                    selectedItems.map((item: IOption) => item.value)
                  )
                }
                values={selectedComments.map((id) => ({
                  value: id,
                  label: commentOptions[id],
                }))}
                items={Object.keys(commentOptions).map((id) => ({
                  value: id,
                  label: commentOptions[id],
                }))}
                disabled={!Object.keys(commentOptions).length}
              />
            </Grid>
            <Grid
              item
              xs={mdDown ? 12 : undefined}
              sm={mdDown ? 6 : undefined}
              md={mdDown ? 4 : undefined}
              style={{ flex: !mdDown ? 1 : undefined }}
            >
              <DatePicker
                id="firstVaccinationFrom"
                label={translations.firstVaccinationFrom[lang]}
                value={selectedFromDate}
                muiProps={{
                  disableToolbar: true,
                  maxDate: selectedToDate || undefined,
                }}
                onChange={(e) => setSelectedFromDate(e ? moment(e) : null)}
              />
            </Grid>
            <Grid
              item
              xs={mdDown ? 12 : undefined}
              sm={mdDown ? 6 : undefined}
              md={mdDown ? 4 : undefined}
              style={{ flex: !mdDown ? 1 : undefined }}
            >
              <DatePicker
                id="firstVaccinationTo"
                label={translations.firstVaccinationTo[lang]}
                value={selectedToDate}
                muiProps={{
                  disableToolbar: true,
                  minDate: selectedFromDate || undefined,
                }}
                onChange={(e) => setSelectedToDate(e ? moment(e) : null)}
              />
            </Grid>
            <Grid
              item
              xs={smDown ? 12 : undefined}
              sm={smDown ? 6 : undefined}
              style={{ flex: !smDown ? 1 : undefined }}
            >
              <Autocomplete
                fullWidth
                checkBox
                id="firstVaccinationLocation"
                label={translations.firstVaccinationLocation[lang]}
                onChange={(selectedItems: any) =>
                  setSelectedLocations(
                    selectedItems.map((item: IOption) => item.value)
                  )
                }
                values={selectedLocations.map((id) => ({
                  value: id,
                  label: locationOptions[id],
                }))}
                items={Object.keys(locationOptions).map((id) => ({
                  value: id,
                  label: locationOptions[id],
                }))}
                disabled={!Object.keys(locationOptions).length}
              />
            </Grid>
            <Grid
              item
              xs={xsDown ? 12 : undefined}
              style={{ flex: !xsDown ? 1 : undefined }}
            >
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  !!values.selectRows && handleSubmit(e);
                }}
              >
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="selectRows"
                  variant="outlined"
                  label={translations.selectFromList[lang]}
                  value={values.selectRows}
                  inputProps={{
                    type: 'number',
                    min: 1,
                    max: filteredCount,
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        color="primary"
                        disabled={!values.selectRows}
                        onClick={() => submitRows(values.selectRows)}
                      >
                        <ArrowIcon />
                      </IconButton>
                    ),
                  }}
                  onChange={(e) => e.target.validity.valid && handleChange(e)}
                />
              </Form>
            </Grid>
            <Grid item xs={xsDown ? 12 : undefined}>
              <Button
                fullWidth={mdDown}
                variant="outlined"
                onClick={() => {
                  clearFilters();
                  handleReset();
                }}
              >
                {translations.clear[lang]}
              </Button>
            </Grid>
          </Grid>
        )}
      </Formik>
    );
  }

  function clearFilters() {
    setNameInput('');
    setNameInputFilter('');
    setSelectedStatus([]);
    setSelectedVaccines([]);
    setSelectedComments([]);
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setSelectedLocations([]);
    selectRows(0);
    focusNameInput();
  }

  function focusNameInput() {
    if (nameRef && nameRef.current) {
      nameRef.current.focus();
    }
  }

  function submitRows(numberOfRowsStr: string) {
    const numberOfRows = parseInt(numberOfRowsStr);
    selectRows(numberOfRows);
  }
};

function filterByName(fullName: string, query: string) {
  const names = fullName.toLowerCase().split(' ');
  const queryLowerCase = query.toLowerCase();
  names.push(fullName.toLowerCase());

  for (const name of names) {
    if (name.startsWith(queryLowerCase)) {
      return true;
    }
  }
  return false;
}

function filterByStatus(
  { firstVaccination, secondVaccination }: VaccinationPerson,
  status: string[]
) {
  if (!status.length) {
    return true;
  }
  if (!firstVaccination.vaccinationDate) {
    return status.includes(VACCINATION_STATUS_NEITHER_KEY);
  }
  if (!secondVaccination.vaccinationDate) {
    return status.includes(VACCINATION_STATUS_FIRST_KEY);
  }
  return status.includes(VACCINATION_STATUS_BOTH_KEY);
}

function filterByVaccine(vaccineAtc: string | undefined, vaccines: string[]) {
  if (!vaccines.length) {
    return true;
  }
  return !!vaccineAtc && vaccines.includes(vaccineAtc);
}

function filterByComment(vc: VaccinationComment, comments: string[]) {
  if (!comments.length) {
    return true;
  }

  const statusId = vc.statusId !== undefined ? vc.statusId.toString() : '';
  if (statusId) {
    return comments.includes(statusId);
  }
  return vc.comment && comments.includes(OTHER_COMMENT_STATUS_ID);
}

function filterByFirstVaccinationDate(
  vaccinationDate: string | undefined,
  from: Moment | null,
  to: Moment | null
) {
  if (!from && !to) {
    return true;
  }
  if (!vaccinationDate) {
    return false;
  }
  return (
    (!from || moment(vaccinationDate).isSameOrAfter(from, 'day')) &&
    (!to || moment(vaccinationDate).isSameOrBefore(to, 'day'))
  );
}

function filterByFirstVaccinationLocation(
  vaccinationLocation: string | undefined,
  selected: string[]
) {
  if (!selected.length) {
    return true;
  }
  if (!vaccinationLocation) {
    return false;
  }
  return selected.includes(vaccinationLocation);
}

export default VaccinationGroupMemberFilter;
