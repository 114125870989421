import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Layout from '../components/Layout';
import Section from '../components/Section';
import useNotifier from '../hooks/useNotifier';
import LoadingIndicator from '../components/LoadingIndicator';
import VaccinationGroupMemberList from '../components/VaccinationGroupMemberList';
import GroupVaccinationMemberFilter from '../components/GroupVaccinationMemberFilter';
import VaccinationGroupMemberDetails from '../components/VaccinationGroupMemberDetails';
// import { IFirstOrSecondVaccination } from '../components/VaccinationSettings';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import {
  VaccinationPerson,
  Vaccine,
  VaccinationLocation,
} from '../models/VaccinationModels';
import { TABLE_ROWS_DEFAULT } from '../constants';
import * as vaccinationService from '../services/vaccinationService';
import { IVaccinationGroupDetails } from '../interfaces/vaccinationGroup';
import { IVaccinationCommentStatus } from '../interfaces/vaccinationComment';
import * as localStorageKeys from '../constants/localStorageKeys';
import {
  typeOfVaccination,
  vaccinationGroupType,
  VaccineType,
} from '../constants/enums';

const GroupVaccinationDetailsPage: React.FC<
  RouteComponentProps<{ groupId: string }>
> = ({ match }) => {
  const history = useHistory();
  const [lang] = useContext(LangContext);
  const { notifyError } = useNotifier();

  const [group, setGroup] = useState<IVaccinationGroupDetails | null>(null);
  const [loadingGroup, setLoadingGroup] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [filteredMembers, setFilteredMembers] = useState<VaccinationPerson[]>(
    []
  );
  const [selectedMember, setSelectedMember] =
    useState<VaccinationPerson | null>(null);
  const [vaccines, setVaccines] = useState<Vaccine[] | null>(null);
  const [influenzaVaccines, setInfluenzaVaccines] = useState<Vaccine[] | null>(
    null
  );
  const [locations, setLocations] = useState<VaccinationLocation[] | null>(
    null
  );
  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine | null>(null);
  const [selectedLocation, setSelectedLocation] =
    useState<VaccinationLocation | null>(null);
  const [selectedInfluenzaVaccine, setSelectedInfluenzaVaccine] =
    useState<Vaccine | null>(null);
  const [selectedTypeOfVaccination, setSelectedTypeOfVaccination] =
    useState('');
  const [commentStatusList, setCommentStatusList] = useState<
    IVaccinationCommentStatus[] | null
  >(null);

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  useEffect(() => {
    const fetchGroup = async () => {
      if (match.params.groupId) {
        const response = await vaccinationService.getPersonsInGroup(
          match.params.groupId
        );
        if (response.isOk) {
          setGroup(response.data);
        } else if (response.statusCode === 401) {
          history.push('/login');
        }
        setLoadingGroup(false);
      } else {
        setLoadingGroup(false);
      }
    };
    fetchGroup();
  }, [match.params.groupId, history]);

  useEffect(() => {
    setCurrentPage(0);
  }, [filteredMembers]);

  useEffect(() => {
    const fetchSettings = async () => {
      const vaccinePromise = vaccinationService.getVaccines();
      const locationPromise = vaccinationService.getLocations();
      const [vaccineResponse, locationResponse] = await Promise.all([
        vaccinePromise,
        locationPromise,
      ]);

      if (vaccineResponse.isOk) {
        const covidVaccines = vaccineResponse.data.filter(
          (item) => item.vaccineType === VaccineType.COVID_19
        );
        const newInfluenzaVaccines = vaccineResponse.data.filter(
          (item) => item.vaccineType === VaccineType.INFLUENZA
        );

        setVaccines(covidVaccines);
        setInfluenzaVaccines(newInfluenzaVaccines);

        const savedVaccineId = localStorage.getItem(
          localStorageKeys.VACCINE_ID
        );
        const savedVaccine = covidVaccines.find(
          (v) => v.id.toString() === savedVaccineId
        );
        if (savedVaccine) {
          setSelectedVaccine(savedVaccine);
        } else if (covidVaccines.length === 1) {
          setSelectedVaccine(covidVaccines[0]);
        }

        const savedInfluenzaVaccineId = localStorage.getItem(
          localStorageKeys.INFLUENZA_VACCINE_ID
        );
        const savedInfluenzaVaccine = newInfluenzaVaccines.find(
          (v) => v.id.toString() === savedInfluenzaVaccineId
        );

        if (savedInfluenzaVaccine) {
          setSelectedInfluenzaVaccine(savedInfluenzaVaccine);
        }
      } else {
        setVaccines([]);
      }

      if (locationResponse.isOk) {
        setLocations(locationResponse.data);
        const savedLocationId = localStorage.getItem(
          localStorageKeys.VACCINATION_LOCATION_ID
        );
        const savedLocation = locationResponse.data.find(
          (l) => l.id.toString() === savedLocationId
        );
        if (savedLocation) {
          setSelectedLocation(savedLocation);
        } else if (locationResponse.data.length === 1) {
          setSelectedLocation(locationResponse.data[0]);
        }
      } else {
        setLocations([]);
      }

      const savedVaccinationType = localStorage.getItem(
        localStorageKeys.TYPE_OF_VACCINATION
      );
      if (
        savedVaccinationType === typeOfVaccination.COVID_19 ||
        savedVaccinationType === typeOfVaccination.INFLUENZA ||
        savedVaccinationType === typeOfVaccination.COVID_19_AND_INFLUENZA
      ) {
        setSelectedTypeOfVaccination(savedVaccinationType);
      }
    };
    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchCommentStatusList = async () => {
      const { data: statusList } =
        await vaccinationService.getVaccinationCommentStatusList();
      setCommentStatusList(statusList);
    };
    fetchCommentStatusList();
  }, []);

  return (
    <Layout
      useDefaultSpacing
      title={getTitle()}
      subtitles={
        group
          ? [
              group.description,
              `${translations.members[lang]}: ${group.members.length}`,
            ]
          : undefined
      }
      otherButton={{
        label: translations.backToGroups[lang],
        onClick: () => history.push('/group-vaccination'),
      }}
    >
      {loadingGroup ? (
        <Grid container justify="center" alignItems="center">
          <LoadingIndicator />
        </Grid>
      ) : (
        <Section>
          {selectedMember && (
            <VaccinationGroupMemberDetails
              hasVaccinationAccess
              member={selectedMember}
              groupId={group?.id}
              childGroup={group?.type === vaccinationGroupType.CHILD_GROUP}
              commentStatusList={commentStatusList}
              back={() => setSelectedMember(null)}
              reloadGroup={reloadGroup}
              vaccinationSettings={{
                vaccines,
                influenzaVaccines,
                locations,
                selectedVaccine,
                selectedLocation,
                selectedTypeOfVaccination,
                selectedInfluenzaVaccine,
                setSelectedVaccine,
                setSelectedLocation,
                setSelectedTypeOfVaccination,
                setSelectedInfluenzaVaccine,
              }}
            />
          )}
          {group && (
            <div style={{ display: selectedMember ? 'none' : undefined }}>
              <GroupVaccinationMemberFilter
                members={group.members}
                filterMembers={setFilteredMembers}
                selectedMember={selectedMember}
                setSelectedMember={setSelectedMember}
              />
              <VaccinationGroupMemberList
                members={filteredMembers}
                group={group}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
                onClick={setSelectedMember}
                membersCheckbox={[]}
              />
            </div>
          )}
        </Section>
      )}
    </Layout>
  );

  function getTitle() {
    if (loadingGroup) {
      return undefined;
    }
    return group ? group.name : translations.groupNotFound[lang];
  }

  async function reloadGroup() {
    if (match.params.groupId) {
      const response = await vaccinationService.getPersonsInGroup(
        match.params.groupId
      );
      if (response.isOk) {
        setGroup(response.data);
        if (selectedMember && response.data) {
          const updatedMember = response.data.members.find(
            (m) => m.serialNumber === selectedMember.serialNumber
          );
          setSelectedMember(updatedMember || null);
        }
      } else if (response.statusCode === 401) {
        history.push('/login');
      }
    }
  }
};

export default GroupVaccinationDetailsPage;
