import React, { useContext, useState } from 'react';
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
  TextField,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import moment, { Moment } from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/AddCircle';

import { Residence } from '../models/Models';
import { DATE_FORMAT } from '../constants';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import EditButton from './EditButton';
import ConfirmDialog from './ConfirmDialog';
import DatePicker from './DatePicker';
import * as httpService from '../services/httpService';
import useNotifier from '../hooks/useNotifier';
import { toISOStringTimezone } from '../utils';

interface IProps {
  serialNumber: string;
  residences: Residence[];
  editable?: boolean;
  compact?: boolean;
  topMargin?: boolean;
  reloadPassenger: () => Promise<void>;
}

interface IFormValues {
  streetName: string;
  streetNumber: string;
  apartmentNumber: string;
  addressDetails: string;
  postalCode: string;
  city: string;
  fromDate: Moment | null;
  toDate: Moment | null;
}

const schema = Yup.object().shape({
  streetName: Yup.string().required(),
  streetNumber: Yup.string().required(),
  postalCode: Yup.string().required().length(3),
  city: Yup.string().required(),
  fromDate: Yup.object().required(),
  toDate: Yup.object().required(),
});

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderBottomWidth: 0,
    borderRadius: 4,
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  emptyContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  buttonContainer: {
    position: 'absolute',
    left: '100%',
    marginLeft: theme.spacing(1),
  },
}));

const ResidenceInfo: React.FC<IProps> = ({
  serialNumber,
  residences,
  editable = true,
  compact = false,
  topMargin = true,
  reloadPassenger,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [lang] = useContext(LangContext);
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const { notifyError, notifySuccess } = useNotifier();

  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [addModalOpen, setAddModalOpen] = useState(false);

  if (!residences.length) {
    return (
      <div className={classes.emptyContainer}>
        <Typography color="textSecondary">
          {translations.noResidence[lang]}
        </Typography>
        {editable && (
          <div className={classes.buttonContainer}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => setAddModalOpen(true)}
            >
              <AddIcon fontSize="default" style={{ transform: 'scale(1.2)' }} />
            </IconButton>
          </div>
        )}
        {renderEditDialog()}
      </div>
    );
  }

  return (
    <TableContainer
      className={classes.container}
      style={{ marginTop: topMargin ? theme.spacing(2) : 0 }}
    >
      <Table aria-label="simple table" size={compact ? 'small' : undefined}>
        <TableHead>
          <TableRow>
            <TableCell>{translations.address[lang]}</TableCell>
            {!xsDown && (
              <>
                <TableCell>{translations.hotel[lang]}</TableCell>
                <TableCell>{translations.residencePostalCode[lang]}</TableCell>
                <TableCell>{translations.from[lang]}</TableCell>
                <TableCell>{translations.to[lang]}</TableCell>
              </>
            )}
            {editable && <TableCell width={50} />}
          </TableRow>
        </TableHead>
        <TableBody>
          {residences
            .sort(
              (a, b) =>
                getTimestamp(a.timeOfStayFrom) - getTimestamp(b.timeOfStayFrom)
            )
            .map((res, index) => (
              <TableRow key={index}>
                <TableCell>
                  {res.addressStreet} {res.addressNo}
                </TableCell>
                {!xsDown && (
                  <>
                    <TableCell>{res.nameOfHotel || '-'}</TableCell>
                    <TableCell>
                      {res.postalCode
                        ? `${res.postalCode}${!compact ? ` ${res.city}` : ''}`
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {moment(res.timeOfStayFrom).format(DATE_FORMAT)}
                    </TableCell>
                    <TableCell>
                      {moment(res.timeOfStayTo).format(DATE_FORMAT)}
                    </TableCell>
                  </>
                )}
                {editable && (
                  <TableCell>
                    <EditButton onClick={() => setEditIndex(index)} />
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {renderEditDialog()}
    </TableContainer>
  );

  function renderEditDialog() {
    if (!editable || (editIndex === null && !addModalOpen)) {
      return null;
    }

    const currentResidence = editIndex !== null ? residences[editIndex] : null;

    return (
      <Formik
        onSubmit={updateResidence}
        validationSchema={schema}
        enableReinitialize
        initialValues={{
          streetName: currentResidence?.addressStreet || '',
          streetNumber: currentResidence?.addressNo || '',
          apartmentNumber: currentResidence?.addressApartment || '',
          addressDetails: currentResidence?.nameOfHotel || '',
          postalCode: currentResidence?.postalCode || '',
          city: currentResidence?.city || '',
          fromDate: currentResidence?.timeOfStayFrom
            ? moment(currentResidence.timeOfStayFrom)
            : null,
          toDate: currentResidence?.timeOfStayTo
            ? moment(currentResidence.timeOfStayTo)
            : null,
        }}
      >
        {({
          values,
          errors,
          submitCount,
          isSubmitting,
          isValid,
          dirty,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <ConfirmDialog
            open
            title={
              editIndex !== null
                ? translations.editResidence[lang]
                : translations.addResidence[lang]
            }
            loading={isSubmitting}
            disableConfirm={isSubmitting || !isValid || !dirty}
            onConfirm={handleSubmit}
            onCancel={() => {
              setEditIndex(null);
              setAddModalOpen(false);
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  id="streetName"
                  variant="outlined"
                  value={values.streetName}
                  label={translations.residenceStreetName[lang]}
                  error={!!errors.streetName && !!submitCount}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="streetNumber"
                  variant="outlined"
                  value={values.streetNumber}
                  label={translations.residenceStreetNumber[lang]}
                  error={!!errors.streetNumber && !!submitCount}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="apartmentNumber"
                  variant="outlined"
                  value={values.apartmentNumber}
                  label={translations.residenceApartmentNumber[lang]}
                  error={!!errors.apartmentNumber && !!submitCount}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  id="addressDetails"
                  variant="outlined"
                  value={values.addressDetails}
                  label={translations.residenceAddressDetails[lang]}
                  error={!!errors.addressDetails && !!submitCount}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="postalCode"
                  variant="outlined"
                  inputProps={{
                    type: 'text',
                    pattern: '[0-9]{0,3}',
                  }}
                  value={values.postalCode}
                  label={translations.residencePostalCode[lang]}
                  error={!!errors.postalCode && !!values.postalCode}
                  onChange={(e) =>
                    !e.target.validity.patternMismatch && handleChange(e)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="city"
                  variant="outlined"
                  value={values.city}
                  label={translations.residenceCity[lang]}
                  error={!!errors.city && !!submitCount}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  id="fromDate"
                  required
                  label={translations.from[lang]}
                  value={values.fromDate}
                  muiProps={{
                    disableToolbar: true,
                    maxDate: values.toDate || undefined,
                  }}
                  onChange={(e) =>
                    setFieldValue('fromDate', e ? moment(e) : null)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  id="toDate"
                  required
                  label={translations.to[lang]}
                  value={values.toDate}
                  muiProps={{
                    disableToolbar: true,
                    minDate: values.fromDate || undefined,
                  }}
                  onChange={(e) =>
                    setFieldValue('toDate', e ? moment(e) : null)
                  }
                />
              </Grid>
            </Grid>
          </ConfirmDialog>
        )}
      </Formik>
    );
  }

  function getTimestamp(date: string) {
    return moment(date).valueOf();
  }

  async function updateResidence(values: IFormValues) {
    const current =
      editIndex !== null && residences[editIndex] ? residences[editIndex] : {};
    const response = await httpService.updateResidence(serialNumber, {
      ...current,
      addressStreet: values.streetName,
      addressNo: values.streetNumber,
      addressApartment: values.apartmentNumber,
      city: values.city,
      nameOfHotel: values.addressDetails,
      postalCode: values.postalCode,
      timeOfStayFrom: values.fromDate
        ? toISOStringTimezone(values.fromDate)
        : undefined,
      timeOfStayTo: values.toDate
        ? toISOStringTimezone(values.toDate)
        : undefined,
    });

    if (response.isOk) {
      await reloadPassenger();
      notifySuccess(translations.residenceUpdated[lang]);
      setEditIndex(null);
      setAddModalOpen(false);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }
};

export default ResidenceInfo;
