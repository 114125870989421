export const borderControlStatus = {
  REGISTERED_IN_QUARANTINE: 0,
  NEGATIVE_TRANSMISSION_CERTIFICATE: 2,
  REGISTERED_FOR_SAMPLING: 3,
  CHILD_UNVERIFIED: 4,
  EXEMPT_FROM_SAMPLING: 5,
  CHILD_VERIFIED: 6,
  WAITING_FOR_SAMPLING: 7,
  SAMPLING_COMPLETED: 8,
  QUARANTINE_APPROVED: 9,
  EXEMPTION_CONFIRMED: 10,
  REGISTERED_TO_TRANSIT: 11,
  REGISTERED_WITH_CERTIFICATE: 12,
  TRANSIT_CONFIRMED: 13,
  REGISTERED_FOR_5DAYS_SAMPLING: 15,
  REGISTERED_FOR_5DAYS_SAMPLING_APPROVED: 16,
  VERIFIED_VACCINATION_CERTIFICATE: 17,
  ANTIBODY_ASSAY_CERTIFICATE: 18,
  VERIFIED_OTHER_CERTIFICATES_DOCUMENTS: 19,
  PCR_CERTIFICATE_SAMPLING: 20,
  CHECKED_OUT: 21,
  CERTIFICATE_ACCEPTED_BY_AIRLINE: 23,
  REGISTERED_WITH_CERTIFICATE_ELECTRONIC: 24,
  PRE_BORDER_CERTIFICATE_REVIEW_PENDING: 25,
  PRE_BORDER_CERTIFICATE_REVIEW_APPROVED: 26,
  PRE_BORDER_CERTIFICATE_REVIEW_REJECTED: 27,
  REGISTERED_FOR_SAMPLING_PAYMENT_COMPLETED: 1000,
  REGISTERED_FOR_SAMPLING_PAYMENT_NOT_REQUIRED: 1001,
  REGISTERED_FOR_SAMPLING_PAYMENT_NEEDED: 1002,
  REGISTERED_FOR_RAPID_TEST: 1003,
  REGISTERED_FOR_SYMPTOM_SAMPLING: 2000,
};

export const preScreeningStatus = {
  NEGATIVE_PCR_TEST_NOT_PROCESSED: 0,
  NEGATIVE_PCR_TEST_ACCEPTED: 1,
  NEGATIVE_PCR_TEST_REJECTED: 2,
  NEGATIVE_PCR_TEST_NOT_PROVIDED: 3,
  NEGATIVE_PCR_TEST_OVERRULED: 4,
  NEGATIVE_PCR_TEST_NOT_NEEDED_BECAUSE_CHILD: 1000,
  NEGATIVE_PCR_TEST_NOT_NEEDED_BECAUSE_CERTIFICATE: 1001,
};

export const accessControl = {
  SAMPLING: 1,
  CERTIFICATE: 2,
  SEARCH: 3,
  QUARANTINE_CONTROL: 4,
  SUPER_ADMIN: 5,
  SECOND_SAMPLING: 7,
  SUPER_SEARCH: 8,
  VACCINATION: 10,
  VACCINATION_GROUP: 11,
  VACCINATION_GROUP_MANAGEMENT: 12,
  VACCINATION_SEARCH: 13,
  QUARANTINE_HOTEL: 14,
  HIGH_RISK_COUNTRIES: 15,
  TIME_COP: 18,
  CERTIFICATE_REVIEW: 19,
  CREATE_CERTIFICATE: 23,
};

export const accessListType = {
  BORDER_CONTROL: 'BorderControlAccess',
  VACCINATION: 'VaccinationUserAccess',
};

export const observationStatus = {
  IN_PROGRESS: 0,
  NEGATIVE: 1,
  POSITIVE: 2,
  INCCONCCLUSIVE: 4,
};

export const residenceStatus = {
  CREATED: 0,
  IN_TRANSIT: 1,
  CHECKED_IN: 2,
  CHECKED_OUT: 3,
};

export const countryStatuses = {
  QUARANTINE_HOTEL_NOT_NEEDED: -1,
  LOW_RISK_QUARANTINE_AT_HOME: 0,
  MEDIUM_RISK_QUARANTINE_AT_HOTEL: 1,
  MEDIUM_RISK_QUARANTINE_AT_HOME: 2,
  HIGH_RISK_QUARANTINE_AT_HOTEL: 3,
  QUARANTINE_HOTEL_EXEMPTION_ACCEPTED: 4,
  QUARANTINE_HOTEL_EXEMPTION_REJECTED: 5,
  SENT_TO_QUARANTINE_HOTEL: 6,
};

export const vaccinesAtc = {
  PFIZER: 'J07BX0301',
  MODERNA: 'J07BX0302',
  ASTRA_ZENECA: 'J07BX0303',
  JANSSEN: 'J07BX0304',
};

export const certificateType = {
  ANTIBODY: 'Recovery certificate',
  VACCINATION: 'Vaccination',
  PCR: 'Negative Testresult',
  UNITED: 'United Airlines Precheck',
};
export const qrCodeErrorSeverity = {
  INFO: 0,
  WARNING: 1,
  INVALID: 2,
  ERROR: 3,
};

export const actionStatuses = {
  NEG_PCR: 1,
  NEG_PCR_REJECTED: 2,
  REGISTERED_WITH_CERTIFICATE: 3,
  FINISH_SAMPLING: 4,
  TRANSIT: 5,
  REGISTERED_TO_QUARANTINE: 6,
  CONFIRM_REGISTRATION: 7,
  PROCESS_COMPLETED: 8,
  CONFIRM_REGISTRATION_QUARANTINE: 9,
  CONFIRM_REGISTRATION_SAMPLING: 10,
  CONFIRM_REGISTRATION_CERTIFICATE: 11,
  CONFIRM_REGISTRATION_TRANSIT: 12,
  TAKE_SAMPLE_WITH_VALID_CERTIFICATE: 13,
  CHECKOUT_CERTIFICATE_AND_SAMPLE: 14,
  CHECKOUT_CERTIFICATE_AND_SAMPLE_ELSEWHERE: 15,
  TAKE_SAMPLE_WITH_CHILD: 16,
  CHECKOUT_CHILD_AND_SAMPLE: 17,
  CHECKOUT_CHILD_AND_SAMPLE_ELSEWHERE: 18,
  CHECKOUT_CHILD_NO_ACTION: 19,
  CHECKOUT_CERTIFICATE_WITH_PCR_POSITIVE: 20,
  TAKE_PCR_SAMPLE_NO_PCR_NEGATIVE_CERTIFICATE: 21,
  CHECKOUT_ELECTRONIC_ID_PCR_POSITIVE_CERTIFICATE: 22,
  CHECKOUT_ELECTRONIC_ID_VACCINATION_CERTIFICATE: 23,
  CHECKOUT_ELECTRONIC_ID_VACCINATION_CERTIFICATE_AND_SAMPLE: 24,
  CHECKOUT_ELECTRONIC_ID_VACCINATION_CERTIFICATE_AND_SAMPLE_ELSEWHERE: 25,
  TAKEN_SAMPLE_WITH_VALID_ELECTRONIC_ID_CERTIFICATE: 26,
  INVALID_ELECTRONIC_ID_CERTIFICATE_TAKE_SAMPLE: 27,
  ELECTRONIC_ID_CERTIFICATE_CONFIRM_NEG_PCR: 28,
  TAKE_SAMPLE_WITH_VALID_ELECTRONIC_ID_POSITIVE_CERTIFICATE: 29,
  CHECKOUT_ELECTRONIC_ID_POSITIVE_CERTIFICATE_AND_SAMPLE_ELSEWHERE: 30,
  CHECKOUT_ELECTRONIC_ID_POSITIVE_CERTIFICATE_AND_SAMPLE_TAKEN: 31,
  NEG_PCR_PRE_BORDER_CERTIFICATE: 32,
  TAKE_SAMPLE_PRE_BORDER_CERTIFICATE: 33,
  CHECKOUT_PRE_BORDER_CERTIFICATE: 34,
  CHECKOUT_PRE_BORDER_CERTIFICATE_AND_SAMPLING_ELSEWHERE: 35,
  CHECKOUT_PRE_BORDER_CERTIFICATE_AND_SAMPLE_TAKEN: 36,
  CHECKOUT_PRE_BORDER_CERTIFICATE_PCR_POSITIVE_CERTIFICATE: 37,
  PRE_BORDER_CERTIFICATE_UNRESOLVED: 38,
  PRE_BORDER_CERTIFICATE_REJECTED: 39,
};

export const actionStatusesCompleted = {
  NEG_PCR_CONFIRMED: 1,
  NEG_PCR_REJECTED: 2,
  NEG_PCR_OVERRIDDEN: 3,
  CERTIFICATE_ACCEPTED: 4,
  CERTIFICATE_REJECTED_REGISTERED_TO_SAMPLING: 6,
  CERTIFICATE_REJECTED_REGISTERED_TO_QUARANTINE: 7,
  SAMPLING_COMPLETED: 8,
  REGISTERED_TO_QUARANTINE: 9,
  REGISTERED_TO_QUARANTINE_REJECTED: 10,
  REGISTERED_TO_QUARANTINE_ACCEPTED: 11,
  REGISTERED_IN_TRANSIT: 12,
  REGISTERED_IN_TRANSIT_ACCEPTED: 13,
  TRANSIT_REJECTED_REGISTERED_WITH_CERTIFICATE: 14,
  TRANSIT_REJECTED_REGISTERED_TO_SAMPLING: 15,
  TRANSIT_REJECTED_REGISTERED_TO_QUARANTINE: 16,
  REGISTRATION_COMPLETED: 17,
  STATUS_CHANGED_TO_REGISTERED_WITH_CERTIFICATE: 18,
  CERTIFICATE_ACCEPTED_VACCINATION: 19,
  CERTIFICATE_ACCEPTED_ANTIBODIES: 20,
  CERTIFICATE_ACCEPTED_OTHER_DOCUMENTS: 21,
  CERTIFICATE_ACCEPTED_POSITIVE_PREVIOUS: 22,
  CERTIFICATE_ACCEPTED_WITH_SAMPLING: 23,
  CERTIFICATE_ACCEPTED_BY_AIRLINE: 24,
  SAMPLE_TAKEN_DOUBLE_SAMPLING: 25,
  SAMPLE_TAKEN_NO_PCR_NEGATIVE: 26,
  SAMPLE_TAKEN_CHILD: 27,
  SAMPLE_TAKEN_PARTIAL_VACCINATION_CERTIFICATE: 28,
  SAMPLE_TAKEN_VALID_CERTIFICATE_BUT_SAMPLE_STILL_REQUIRED: 29,
  SAMPLE_TAKEN_VALID_ELECTRONICCERTIFICATE_BUT_SAMPLE_STILL_REQUIRED: 30,
  CHECKOUT_VALID_ELECTRONIC_ID_PCR_POSITIVE_CERTIFICATE: 31,
  CHECKOUT_VALID_ELECTRONIC_ID_VACCINATION_CERTIFICATE: 32,
  SAMPLE_TAKEN_DOUBLE_SAMPLING_INVALID_ELECTRONIC_ID_CERTIFICATE: 33,
  SAMPLE_TAKEN_DOUBLE_VALID_ELECTRONIC_ID_POSITIVE_CERTIFICATE_BUT_SAMPLING_STILL_REQUIRED: 34,
};

export const reviewCertificateStatuses = {
  UNRESOLVE: 0,
  APPROVE: 1,
  REJECT: 2,
};

export const reviewFileStatuses = {
  NOT_CERTIFICATE: 0,
  VALID: 1,
  WARNING: 2,
  ERROR: 3,
};

export const reviewFileTypeStatuses = {
  VACCINATION: 1,
  PASSPORT: 2,
  NEGATIVE_PCR: 3,
  PREVIOUS_INFECTION: 4,
};

export const vaccinationGroupType = {
  NONE: 0,
  CHILD_GROUP: 1,
};

export const childVaccinationCustodianPosition = {
  UNKNOWN: 0,
  YES: 1,
  NO: 2,
  NOT_NOW: 3,
};

export const ChildCustodianType = {
  GUARDIAN: 1,
  ESCORT: 2,
};

export const typeOfVaccination = {
  COVID_19: "COVID-19",
  INFLUENZA: "influenza",
  COVID_19_AND_INFLUENZA: "COVID-19+influenza"
};

export enum VaccineType {
  COVID_19 = 1,
  INFLUENZA = 2,
};
