import React, { useContext } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { LangContext } from '../../context/LangContext';
import { ReviewStatus } from '../../models/CertificateReviewModels';
import { reviewCertificateStatuses } from '../../constants/enums';

interface IProps {
  status: ReviewStatus;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    marginRight: theme.spacing(0.5),
  },
  checkIcon: {
    color: theme.palette.success.main,
    position: 'absolute',
  },
  errorIcon: {
    color: theme.palette.error.main,
    position: 'absolute',
  },

  statusText: {
    paddingLeft: 28,
  },
}));

const DisplayReviewStatus: React.FC<IProps> = ({ status }) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);

  const statusText = status
    ? (status.description && status.description[lang]) || ''
    : '';

  if (status.id === reviewCertificateStatuses.APPROVE) {
    return (
      <>
        <Typography
          component={'span'}
          className={classes.container}
          color="textSecondary"
        >
          {statusText}
        </Typography>
        <CheckCircleIcon className={classes.checkIcon} />
      </>
    );
  }

  if (status.id === reviewCertificateStatuses.REJECT) {
    return (
      <>
        <Typography
          component={'span'}
          className={classes.container}
          color="textSecondary"
        >
          {statusText}
        </Typography>
        <CancelIcon className={classes.errorIcon} />
      </>
    );
  }

  return (
    <>
      <Typography
        component={'span'}
        className={classes.container}
        color="textSecondary"
      >
        {statusText}
      </Typography>
    </>
  );
};

export default DisplayReviewStatus;
