import React, { useContext, useState, useEffect } from 'react';
import { makeStyles, IconButton, Grid, Typography } from '@material-ui/core';
import { Transaction } from '../models/Models';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import ConfirmDialog from './ConfirmDialog';

import { getPassengersPayments } from '../services/httpService';

interface IProps {
  barCode: string;
}

export interface IKey {
  string:
    | 'transactionNo'
    | 'authorizationNo'
    | 'amountPaid'
    | 'maskedCardNo'
    | 'cardType';
}

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(0),
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
  },
  buttonIcon: {
    fontSize: 23,
  },
  descriptionTitle: {
    display: 'inline-block',
    fontWeight: 'bold',
  },
  descriptionDetails: {
    display: 'inline-block',
    paddingLeft: 10,
  },
}));

const PaymentInfoButton: React.FC<IProps> = ({ barCode }) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);
  const [skipPaymentDialogOpen, setSkipPaymentDialogOpen] = useState(false);
  const [payments, setPayments] = useState<Transaction[] | null>(null);
  const getKeyValue =
    <U extends keyof T, T extends object>(key: U) =>
    (obj: T) =>
      obj[key];

  useEffect(() => {
    const ac = new AbortController();
    getPassengersPayments(barCode)
      .then((response) => {
        if (response.isOk) {
          if (response && response.data && response.data.length > 0) {
            setPayments(response.data);
          } else {
            setPayments(null);
          }
        } else {
          ac.abort();
        }
      })
      .catch(() => {});
  }, [barCode]);

  if (!payments) {
    return null;
  }

  return (
    <React.Fragment>
      <IconButton
        className={classes.button}
        color="primary"
        onClick={() => setSkipPaymentDialogOpen(true)}
      >
        <CreditCardIcon className={classes.buttonIcon} />
      </IconButton>
      {renderDialogs()}
    </React.Fragment>
  );

  function renderDialogs() {
    return (
      <ConfirmDialog
        open={skipPaymentDialogOpen}
        title={translations.paymentInfo[lang]}
        onConfirm={() => setSkipPaymentDialogOpen(false)}
        onCancel={() => setSkipPaymentDialogOpen(false)}
        confirmText={translations.ok[lang]}
      >
        {payments && payments.map(renderItems)}
      </ConfirmDialog>
    );
  }

  function renderItems(payments: Transaction, index: number) {
    return (
      <Grid container spacing={2} key={index}>
        {Object.keys(payments).map((key, index) => {
          return (
            <Grid item xs={12} key={index}>
              <Typography className={classes.descriptionTitle}>
                {translations[key as IKey['string']][lang]}:
              </Typography>
              <Typography
                className={classes.descriptionDetails}
                color="textSecondary"
              >
                {getKeyValue<keyof Transaction, Transaction>(
                  key as IKey['string']
                )(payments)}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  }
};

export default PaymentInfoButton;
