import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Grid,
  Checkbox,
  FormControlLabel,
  useTheme,
  TextField,
  Radio,
} from '@material-ui/core';
import { Formik } from 'formik';
import moment from 'moment';

import Layout from '../components/Layout';
import Section from '../components/Section';
import SamplingForm, { IFormValues, IFormik } from '../components/SamplingForm';
import PassengerInfo from '../components/PassengerInfoNew';
import LoadingIndicator from '../components/LoadingIndicator';
import NoResults from '../components/NoResults';
import ConfirmDialog from '../components/ConfirmDialog';
import Stepper from '../components/Stepper';
import QRCodeDialog from '../components/QRCodeDialog';
import * as httpService from '../services/httpService';
import * as httpServiceV2 from '../services/httpService.v2';
import { Passenger } from '../models/Models';
import {
  IPassengerDetails,
  IUpdatePassengerInfo,
} from '../interfaces/passenger';
import useNotifier from '../hooks/useNotifier';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { useHistory } from 'react-router-dom';
import {
  actionStatuses,
  borderControlStatus,
  certificateType,
  countryStatuses,
} from '../constants/enums';
import ChangeStatusDialog from '../components/ChangeStatusDialog';
import {
  sendToQuarantine,
  sendToSampling,
  getPhoneNumberString,
  isCheckoutReady,
  canSearchForPassenger,
  isSamplingReadyFlowStatus,
} from '../utils';
import { UserContext } from '../context/UserContext';
import { DATE_FORMAT } from '../constants';
import { CefSharpShell } from '../helpers/CefSharpShell';
import { IButton } from '../components/PassengerInfo';
import { IQrCodeCertificate } from '../interfaces/qrCertificate';
import { IHttpAnswer } from '../interfaces/httpAnswer';
import BcsCheckoutDialog from '../components/BcsCheckoutDialog';

type IPCRRejectReason = 'not_valid' | 'not_provided' | 'other_certificate';

const BorderControlSamplingPage: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const shellHelper = new CefSharpShell();
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const { notifySuccess, notifyError } = useNotifier();
  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  const [loading, setLoading] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [childCount, setChildCount] = useState(0);
  const [skipPayment, setSkipPayment] = useState(false);
  const [certificateCheckbox, setCertificateCheckbox] = useState({
    antibody: false,
    negative: false,
    vaccination: false,
    other: false,
  });
  const [checkoutCheckbox /* , setCheckoutCheckbox */] = useState({
    accept: false,
    reject: false,
  });
  const [confirmQuarantineDialogOpen, setConfirmQuarantineDialogOpen] =
    useState(false);
  const [confirmExemptDialogOpen, setConfirmExemptDialogOpen] = useState(false);
  const [confirm5daysDialogOpen, setConfirm5daysDialogOpen] = useState(false);
  const [
    overruleNegPCRCertificateDialogOpen,
    setOverruleNegPCRCertificateDialogOpen,
  ] = useState(false);
  const [
    confirmCertificateDialogOpen,
    setConfirmCertificateDialogOpenDialogOpen,
  ] = useState(false);
  const [declineExemptDialogOpen, setDeclineExemptDialogOpen] = useState(false);
  const [checkoutDialogOpen, setCheckoutDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [checkoutNotFinishedDialogOpen, setCheckoutNotFinishedDialogOpen] =
    useState(false);
  const [statusDialogTitle, setStatusDialogTitle] = useState('');
  const [statusDialogText, setStatusDialogText] = useState('');
  const [currentPassenger, setCurrentPassenger] = useState<Passenger | null>(
    null
  );
  const [
    sendToQuarantineHotelCheckbox /* , setSendToQuarantineHotelCheckbox */,
  ] = useState(false);
  const [commentInput, setCommentInput] = useState('');
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [rejectPCRTestDialogOpen, setRejectPCRTestDialogOpen] = useState(false);
  const [rejectPCRTestReason, setRejectPCRTestReason] =
    useState<IPCRRejectReason | null>(null);
  const [declineTransitDialogOpen, setDeclineTransitDialogOpen] =
    useState(false);
  const [finishSamplingDialogOpen, setFinishSamplingDialogOpen] =
    useState(false);
  const [qrCodeDialogOpen, setQrCodeDialogOpen] = useState(false);

  useEffect(() => {
    if (!currentPassenger) {
      focusInput();
    }
  }, [currentPassenger, user]);

  useEffect(() => {
    if (confirmCertificateDialogOpen) {
      setCertificateCheckbox({
        antibody: false,
        negative: false,
        vaccination: false,
        other: false,
      });
    }
  }, [confirmCertificateDialogOpen]);

  useEffect(() => {
    if (!declineExemptDialogOpen) {
      setSkipPayment(false);
    }
  }, [declineExemptDialogOpen]);

  return (
    <Formik initialValues={{ serialNumber: '' }} onSubmit={searchForPassenger}>
      {(formik) => (
        <React.Fragment>
          <Layout
            useDefaultSpacing
            title={translations.borderControlSampling[lang]}
            showQuestAirlineSwitch={user.location === 'KEF'}
          >
            <Section>
              <Grid container spacing={3}>
                <Grid item sm={12} md={5} lg={4}>
                  <SamplingForm
                    formik={formik}
                    inputRef={inputRef}
                    disabled={!!currentPassenger}
                    onClear={() => {
                      if (
                        currentPassenger &&
                        currentPassenger.borderControlStatus.id !==
                          borderControlStatus.CHECKED_OUT
                      ) {
                        setCheckoutNotFinishedDialogOpen(true);
                      } else {
                        clearSearch(formik);
                      }
                    }}
                  />
                  {currentPassenger && (
                    <div style={{ marginTop: theme.spacing(2) }}>
                      <Stepper
                        buttons={getButtons(formik)}
                        flowStatusCompleted={
                          currentPassenger.flowStatusesCompleted || []
                        }
                        flowStatusActive={currentPassenger.flowStatusesActive}
                        loading={loadingUpdate}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item sm={12} md={7} lg={8} style={{ flex: 1 }}>
                  {renderResults(formik)}
                </Grid>
              </Grid>
            </Section>
          </Layout>
          {currentPassenger && renderDialogs(formik)}
        </React.Fragment>
      )}
    </Formik>
  );

  function renderResults(formik: IFormik) {
    if (loading) {
      return (
        <Grid container justify="center" alignItems="center">
          <LoadingIndicator />
        </Grid>
      );
    }

    if (noResult) {
      return <NoResults message={translations.noPassengerFound[lang]} />;
    }

    if (currentPassenger) {
      return (
        <PassengerInfo
          passenger={currentPassenger}
          personalDetails={getPersonalDetails()}
          otherDetails={getOtherDetails()}
          extraSamplingDetails={getExtraSamplingDetals()}
          reloadPassenger={() => reloadPassenger(currentPassenger)}
          updateInfo={(info, onSuccess) =>
            updatePassengerInfo(currentPassenger, info, onSuccess)
          }
          updateComment={(comment) =>
            saveTravelDetails(currentPassenger, comment)
          }
        />
      );
    }

    return null;
  }

  function getButtons(formik: IFormik) {
    if (!currentPassenger) {
      return null;
    }

    const quarantineStatus =
      currentPassenger.flowStatusesActive &&
      currentPassenger.flowStatusesActive.id ===
        actionStatuses.REGISTERED_TO_QUARANTINE;

    const excludedStatus =
      currentPassenger.borderControlStatus &&
      currentPassenger.borderControlStatus.id ===
        borderControlStatus.EXEMPT_FROM_SAMPLING;

    const sampling = isSamplingReadyFlowStatus(currentPassenger);

    const registeredWithCertificate =
      currentPassenger.flowStatusesActive &&
      currentPassenger.flowStatusesActive.id ===
        actionStatuses.REGISTERED_WITH_CERTIFICATE;

    const transit =
      currentPassenger.flowStatusesActive &&
      currentPassenger.flowStatusesActive.id === actionStatuses.TRANSIT;

    const checkoutReady = isCheckoutReady(currentPassenger);

    const rejectedNegPCR =
      currentPassenger.flowStatusesActive &&
      currentPassenger.flowStatusesActive.id ===
        actionStatuses.NEG_PCR_REJECTED;

    const negPCR =
      (currentPassenger.flowStatusesActive &&
        currentPassenger.flowStatusesActive.id === actionStatuses.NEG_PCR) ||
      (currentPassenger.flowStatusesActive &&
        currentPassenger.flowStatusesActive.id ===
          actionStatuses.ELECTRONIC_ID_CERTIFICATE_CONFIRM_NEG_PCR) ||
      (currentPassenger.flowStatusesActive &&
        currentPassenger.flowStatusesActive.id ===
          actionStatuses.NEG_PCR_PRE_BORDER_CERTIFICATE);

    const registeredToFiveDays =
      currentPassenger.borderControlStatus &&
      currentPassenger.borderControlStatus.id ===
        borderControlStatus.REGISTERED_FOR_5DAYS_SAMPLING;

    const goToNextStep =
      (currentPassenger.flowStatusesActive &&
        currentPassenger.flowStatusesActive.id ===
          actionStatuses.PRE_BORDER_CERTIFICATE_UNRESOLVED) ||
      (currentPassenger.flowStatusesActive &&
        currentPassenger.flowStatusesActive.id ===
          actionStatuses.PRE_BORDER_CERTIFICATE_REJECTED);

    let buttons: IButton[] = [];

    buttons = buttons.concat(
      negPCR
        ? [
            {
              label: translations.accept[lang],
              disabled: loadingUpdate,
              onClick: () => acceptNegativePCRTest(currentPassenger, formik),
            },
            {
              label: translations.reject[lang],
              variant: 'outlined',
              disabled: loadingUpdate,
              onClick: () => {
                setRejectPCRTestReason(null);
                setRejectPCRTestDialogOpen(true);
              },
            },
            {
              label: translations.qrCode[lang],
              color: 'secondary',
              disabled: loadingUpdate,
              onClick: () => setQrCodeDialogOpen(true),
            },
          ]
        : quarantineStatus
        ? [
            {
              label: translations.confirmQuarantine[lang],
              onClick: () => setConfirmQuarantineDialogOpen(true),
            },
            {
              label: translations.declineQuarantine[lang],
              onClick: () => onDeclineQuarantine(),
              variant: 'outlined',
            },
          ]
        : excludedStatus
        ? [
            {
              label: translations.confirmExemptPassenger[lang],
              onClick: () => setConfirmExemptDialogOpen(true),
            },
            {
              label: translations.sendToSampling[lang],
              variant: 'outlined',
              onClick: () => setDeclineExemptDialogOpen(true),
            },
          ]
        : rejectedNegPCR
        ? [
            {
              label: translations.continueButton[lang],
              onClick: () => setOverruleNegPCRCertificateDialogOpen(true),
            },
            {
              label: translations.changeStatus[lang],
              onClick: () => onDeclineSampling(),
              variant: 'outlined',
            },
          ]
        : sampling
        ? [
            {
              label: translations.print[lang],
              disabled: loadingUpdate,
              onClick: () => setFinishSamplingDialogOpen(true),
            },
          ]
        : transit
        ? [
            {
              label: translations.confirmTransit[lang],
              disabled: loadingUpdate,
              onClick: () => confirmTransit(currentPassenger, formik),
            },
            {
              label: translations.declineTransit[lang],
              onClick: () => onDeclineTransit(),
              variant: 'outlined',
            },
          ]
        : registeredWithCertificate
        ? [
            {
              label: translations.confirmCertificate[lang],
              onClick: () => setConfirmCertificateDialogOpenDialogOpen(true),
            },
            {
              label: translations.declineCertificate[lang],
              onClick: () => onDeclineCertificate(),
              variant: 'outlined',
            },
            {
              label: translations.qrCode[lang],
              color: 'secondary',
              disabled: loadingUpdate,
              onClick: () => setQrCodeDialogOpen(true),
            },
          ]
        : registeredToFiveDays
        ? [
            {
              label: translations.confirmFiveDaysSampling[lang],
              onClick: () => setConfirm5daysDialogOpen(true),
            },
            {
              label: translations.changeStatus[lang],
              onClick: () => onDecline5Days(),
              variant: 'outlined',
            },
          ]
        : checkoutReady
        ? [
            {
              label: translations.confirmRegistration[lang],
              onClick: () => setCheckoutDialogOpen(true),
            },
          ]
        : goToNextStep
        ? [
            {
              label: translations.continue[lang],
              onClick: () => setPreCheckedToManual(),
            },
          ]
        : []
    );

    return buttons;
  }

  function renderDialogs(formik: IFormik) {
    if (!currentPassenger) {
      return null;
    }

    return (
      <React.Fragment>
        <ChangeStatusDialog
          open={statusDialogOpen}
          title={statusDialogTitle}
          text={statusDialogText}
          statusItems={[
            {
              statusIds: [
                borderControlStatus.REGISTERED_FOR_SAMPLING,
                borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_NOT_REQUIRED,
                borderControlStatus.REGISTERED_FOR_5DAYS_SAMPLING,
              ],
              onConfirm: () => {
                onSendToSampling(currentPassenger);
              },
            },
            {
              statusIds: [borderControlStatus.REGISTERED_WITH_CERTIFICATE],
              onConfirm: () => {
                onSendToCertificate(currentPassenger);
              },
            },
            {
              statusIds: [borderControlStatus.REGISTERED_TO_TRANSIT],
              onConfirm: () => {
                onSendToTransit(currentPassenger);
              },
            },
            {
              statusIds: [
                borderControlStatus.REGISTERED_IN_QUARANTINE,
                borderControlStatus.REGISTERED_FOR_5DAYS_SAMPLING,
              ],
              onConfirm: () => onSendToQuarantine(currentPassenger),
            },
          ]}
          onCancel={() => setStatusDialogOpen(false)}
          passenger={currentPassenger}
        />
        <ConfirmDialog
          open={confirmCertificateDialogOpen}
          title={translations.confirmCertificate[lang]}
          text={translations.typeOfCertificate[lang]}
          onConfirm={() => confirmCerticicate(currentPassenger)}
          onCancel={() => setConfirmCertificateDialogOpenDialogOpen(false)}
          confirmText={translations.confirmCertificate[lang]}
          disableConfirm={!Object.values(certificateCheckbox).includes(true)}
          extraButtonText={
            certificateCheckbox.vaccination
              ? translations.confirmBySampling[lang]
              : undefined
          }
          onExtraButtonClick={
            certificateCheckbox.vaccination
              ? () => confirmPartialCertificateVaccination(currentPassenger)
              : undefined
          }
        >
          {renderConfirmCertificateCheckbox()}
        </ConfirmDialog>
        <ConfirmDialog
          open={declineExemptDialogOpen}
          title={translations.declineExemptPassenger[lang]}
          text={`${translations.sendToSamplingText1[lang]} ${currentPassenger.lastName}, ${currentPassenger.firstName} ${translations.sendToSamplingText2[lang]}`}
          onConfirm={() => declineExempt(currentPassenger, formik)}
          onCancel={() => setDeclineExemptDialogOpen(false)}
          confirmText={translations.sendToSampling[lang]}
        >
          {renderSkipPaymentCheckbox()}
        </ConfirmDialog>
        <ConfirmDialog
          open={confirmQuarantineDialogOpen}
          title={translations.confirmQuarantine[lang]}
          text={`${translations.confirmQuarantineDialog[lang]} ${currentPassenger.lastName}, ${currentPassenger.firstName} ?`}
          onConfirm={() => confirmQuarantine(currentPassenger, formik)}
          onCancel={() => setConfirmQuarantineDialogOpen(false)}
          confirmText={translations.confirmQuarantine[lang]}
        />
        <ConfirmDialog
          open={confirmExemptDialogOpen}
          title={translations.confirmExempt[lang]}
          text={`${translations.confirmExemptDialog[lang]} ${currentPassenger.lastName}, ${currentPassenger.firstName} ?`}
          onConfirm={() => confirmExempt(currentPassenger, formik)}
          onCancel={() => setConfirmExemptDialogOpen(false)}
          confirmText={translations.confirmExempt[lang]}
        />
        <ConfirmDialog
          open={confirm5daysDialogOpen}
          title={translations.confirmFiveDaysSampling[lang]}
          text={translations.dialogTitleconfirmFiveDaysSampling[lang]}
          onConfirm={() => confirm5Days(currentPassenger, formik)}
          onCancel={() => {
            setConfirm5daysDialogOpen(false);
          }}
          confirmText={translations.confirm[lang]}
        />
        <ConfirmDialog
          open={overruleNegPCRCertificateDialogOpen}
          title={translations.revokeRejection[lang]}
          text={translations.revokeRejectionDialogText[lang]}
          onConfirm={() =>
            onOverruleNegPCRCertificateRejected(currentPassenger, formik)
          }
          onCancel={() => setOverruleNegPCRCertificateDialogOpen(false)}
          confirmText={translations.confirm[lang]}
        />
        <BcsCheckoutDialog
          open={checkoutDialogOpen}
          onConfirm={(airlineSurveillanceStatus) => {
            checkoutPassenger(
              currentPassenger,
              formik,
              airlineSurveillanceStatus
            );
          }}
          onCancel={() => setCheckoutDialogOpen(false)}
        ></BcsCheckoutDialog>
        <ConfirmDialog
          open={checkoutNotFinishedDialogOpen}
          title={translations.checkoutNotFinished[lang]}
          text={translations.checkoutNotFinishedDialog[lang]}
          onConfirm={() => {
            clearSearch(formik);
            setCheckoutNotFinishedDialogOpen(false);
          }}
          onCancel={() => setCheckoutNotFinishedDialogOpen(false)}
          confirmText={translations.clear[lang]}
        />
        <ConfirmDialog
          loading={loadingUpdate}
          open={declineTransitDialogOpen}
          title={translations.declineTransit[lang]}
          text={translations.declineTransitDialog[lang]}
          onConfirm={() => declineTransit(currentPassenger, formik)}
          onCancel={() => setDeclineTransitDialogOpen(false)}
        />
        <ConfirmDialog
          loading={loadingUpdate}
          open={rejectPCRTestDialogOpen}
          disableConfirm={!rejectPCRTestReason}
          title={translations.rejectNegativePCRTest[lang]}
          text={translations.rejectNegativePCRTestDialogText[lang]}
          onConfirm={() => {
            if (currentPassenger && rejectPCRTestReason) {
              rejectPCRTestReason === 'not_valid'
                ? rejectNegativePCRTest(currentPassenger, formik)
                : rejectPCRTestReason === 'not_provided'
                ? notProvidedNegativePCRTest(currentPassenger, formik)
                : onSendToCertificate(currentPassenger);
            }
          }}
          onCancel={() => setRejectPCRTestDialogOpen(false)}
        >
          {renderRejectNegPCRDialog()}
        </ConfirmDialog>
        <ConfirmDialog
          loading={loadingUpdate}
          open={finishSamplingDialogOpen}
          title={translations.printLabel[lang]}
          text={translations.printLabelWarning[lang]}
          onConfirm={() => confirmSample(formik)}
          onCancel={() => setFinishSamplingDialogOpen(false)}
        />
        <QRCodeDialog
          open={qrCodeDialogOpen}
          passenger={currentPassenger}
          onCancel={() => setQrCodeDialogOpen(false)}
          onAcceptCertificate={onAcceptQRCodeCertificate}
          onAcceptPartialCertificate={confirmPartialCertificateVaccination}
        />
      </React.Fragment>
    );
  }

  function renderSkipPaymentCheckbox() {
    return (
      <FormControlLabel
        label={translations.paymentNotRequired[lang]}
        style={{ color: theme.palette.text.secondary }}
        control={
          <Checkbox
            color="primary"
            checked={skipPayment}
            onChange={(_, val) => setSkipPayment(val)}
          />
        }
      />
    );
  }

  function renderConfirmCertificateCheckbox() {
    return (
      <React.Fragment>
        <FormControlLabel
          label={translations.antibodyCertificate[lang]}
          style={{ color: theme.palette.text.secondary, display: 'flex' }}
          control={
            <Radio
              color="primary"
              checked={certificateCheckbox.antibody}
              onChange={(_, val) =>
                setCertificateCheckbox({
                  antibody: true,
                  negative: false,
                  vaccination: false,
                  other: false,
                })
              }
            />
          }
        />
        <FormControlLabel
          label={translations.positiveCertificate[lang]}
          style={{ color: theme.palette.text.secondary, display: 'flex' }}
          control={
            <Radio
              color="primary"
              checked={certificateCheckbox.negative}
              onChange={(_, val) =>
                setCertificateCheckbox({
                  antibody: false,
                  negative: true,
                  vaccination: false,
                  other: false,
                })
              }
            />
          }
        />
        <FormControlLabel
          label={translations.vaccinationCertificate[lang]}
          style={{ color: theme.palette.text.secondary, display: 'flex' }}
          control={
            <Radio
              color="primary"
              checked={certificateCheckbox.vaccination}
              onChange={(_, val) =>
                setCertificateCheckbox({
                  antibody: false,
                  negative: false,
                  vaccination: true,
                  other: false,
                })
              }
            />
          }
        />
        <FormControlLabel
          label={translations.otherCertificatesDocuments[lang]}
          style={{ color: theme.palette.text.secondary, display: 'flex' }}
          control={
            <Radio
              color="primary"
              checked={certificateCheckbox.other}
              onChange={(_, val) =>
                setCertificateCheckbox({
                  antibody: false,
                  negative: false,
                  vaccination: false,
                  other: true,
                })
              }
            />
          }
        />
      </React.Fragment>
    );
  }

  function renderRejectNegPCRDialog() {
    return (
      <>
        <FormControlLabel
          label={translations.rejectPCRNotValid[lang]}
          style={{ color: theme.palette.text.secondary, display: 'flex' }}
          control={
            <Radio
              color="primary"
              checked={rejectPCRTestReason === 'not_valid'}
              onChange={(_, val) =>
                setRejectPCRTestReason(val ? 'not_valid' : null)
              }
            />
          }
        />
        <FormControlLabel
          label={translations.rejectPCRNotProvided[lang]}
          style={{ color: theme.palette.text.secondary, display: 'flex' }}
          control={
            <Radio
              color="primary"
              checked={rejectPCRTestReason === 'not_provided'}
              onChange={(_, val) =>
                setRejectPCRTestReason(val ? 'not_provided' : null)
              }
            />
          }
        />
        <FormControlLabel
          label={translations.otherCertificate[lang]}
          style={{ color: theme.palette.text.secondary, display: 'flex' }}
          control={
            <Radio
              color="primary"
              checked={rejectPCRTestReason === 'other_certificate'}
              onChange={(_, val) =>
                setRejectPCRTestReason(val ? 'other_certificate' : null)
              }
            />
          }
        />
        {rejectPCRTestReason === 'not_valid' && (
          <TextField
            fullWidth
            autoFocus
            required
            autoComplete="off"
            id="commentInput"
            variant="outlined"
            placeholder={translations.resonForRejection[lang]}
            multiline
            rows={3}
            value={commentInput}
            onChange={(e) => setCommentInput(e.target.value)}
          />
        )}
      </>
    );
  }

  function onDecline5Days() {
    setStatusDialogOpen(true);
    setStatusDialogTitle(translations.changeStatus[lang]);
    setStatusDialogText('');
  }

  function onDeclineQuarantine() {
    setStatusDialogOpen(true);
    setStatusDialogTitle(translations.declineQuarantine[lang]);
    setStatusDialogText(translations.changeStatusDialogTextQuarantine[lang]);
  }

  function onDeclineCertificate() {
    setStatusDialogOpen(true);
    setStatusDialogTitle(translations.declineCertificate[lang]);
    setStatusDialogText(translations.changeStatusDialogTextCertificate[lang]);
  }

  function onDeclineTransit() {
    setStatusDialogOpen(true);
    setStatusDialogTitle(translations.declineTransit[lang]);
    setStatusDialogText(translations.declineTransitDialog[lang]);
  }

  function onDeclineSampling() {
    setStatusDialogOpen(true);
    setStatusDialogTitle('');
    setStatusDialogText('');
  }

  async function searchForPassenger(values: IFormValues) {
    if (
      canSearchForPassenger(user, values.serialNumber, notifyError, lang, false)
    ) {
      return;
    }
    setLoading(true);
    try {
      const result = await httpService.getPassengerByRegistrationId(
        values.serialNumber
      );
      if (result.isOk) {
        setCurrentPassenger(result.data);
        setChildCount(result.data?.numberOfChildren ?? 0);
        setNoResult(false);
      } else if (result.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      }
    } catch {
      setCurrentPassenger(null);
      setNoResult(true);
    }
    setLoading(false);
  }

  async function reloadPassenger(passenger: Passenger) {
    try {
      const response = await httpService.getPassengerByRegistrationId(
        passenger.serialNumber
      );
      if (response.isOk) {
        setCurrentPassenger(response.data);
      } else {
        notifyError(translations.operationFailed[lang]);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function confirmQuarantine(passenger: Passenger, formik: IFormik) {
    try {
      const response = await httpService.confirmQuarantine(
        passenger.serialNumber,
        childCount
      );
      if (response.isOk) {
        await reloadPassenger(passenger);
        notifySuccess(translations.quarantineConfirmed[lang]);
        setConfirmQuarantineDialogOpen(false);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function confirmTransit(passenger: Passenger, formik: IFormik) {
    try {
      const response = await httpService.confirmTransit(passenger.serialNumber);
      if (response.isOk) {
        await reloadPassenger(passenger);
        notifySuccess(translations.transitConfirmed[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function confirm5Days(passenger: Passenger, formik: IFormik) {
    try {
      const response = await httpService.confirmFifthDayTesting(
        passenger.serialNumber
      );
      if (response.isOk) {
        await reloadPassenger(passenger);
        notifySuccess(translations.fiveDaysSamplingAccepted[lang]);
        setConfirm5daysDialogOpen(false);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function confirmCerticicate(passenger: Passenger) {
    if (certificateCheckbox.antibody) {
      try {
        const response = await httpService.acceptAntibodyCertificate(
          passenger.serialNumber
        );
        if (response.isOk) {
          await reloadPassenger(passenger);
          notifySuccess(translations.antibodyCertificateConfirmed[lang]);
          setConfirmCertificateDialogOpenDialogOpen(false);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        }
      } catch {
        notifyError(translations.operationFailed[lang]);
      }
    } else if (certificateCheckbox.negative) {
      try {
        const response = await httpService.acceptPCRPositivePreviousCertificate(
          passenger.serialNumber
        );
        if (response.isOk) {
          await reloadPassenger(passenger);
          notifySuccess(translations.positiveCertificateConfirmed[lang]);
          setConfirmCertificateDialogOpenDialogOpen(false);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        }
      } catch {
        notifyError(translations.operationFailed[lang]);
      }
    } else if (certificateCheckbox.vaccination) {
      try {
        const response = await httpService.acceptVaccinationCertificate(
          passenger.serialNumber
        );
        if (response.isOk) {
          await reloadPassenger(passenger);
          notifySuccess(translations.vaccinationCertificateConfirmed[lang]);
          setConfirmCertificateDialogOpenDialogOpen(false);
          setQrCodeDialogOpen(false);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        }
      } catch {
        notifyError(translations.operationFailed[lang]);
      }
    } else {
      try {
        const response = await httpService.acceptOtherCertificateOrDocuemnts(
          passenger.serialNumber
        );
        if (response.isOk) {
          await reloadPassenger(passenger);
          notifySuccess(translations.otherCertificateConfirmed[lang]);
          setConfirmCertificateDialogOpenDialogOpen(false);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        }
      } catch {
        notifyError(translations.operationFailed[lang]);
      }
    }
  }
  async function confirmPartialCertificateVaccination(passenger: Passenger) {
    try {
      const response = await httpService.acceptPartialCertificateVaccination(
        passenger.serialNumber,
        passenger.numberOfChildren
      );
      if (response.isOk) {
        await reloadPassenger(passenger);
        notifySuccess(translations.vaccinationCertificateConfirmed[lang]);
        setConfirmCertificateDialogOpenDialogOpen(false);
        setQrCodeDialogOpen(false);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function onSendToQuarantine(passenger: Passenger) {
    try {
      const response = await sendToQuarantine(passenger);
      if (response?.isOk) {
        notifySuccess(translations.passengerSentToQuarantine[lang]);
        setStatusDialogOpen(false);
        await reloadPassenger(passenger);
      } else {
        notifyError(translations.operationFailed[lang]);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function onSendToSampling(passenger: Passenger) {
    try {
      const response = await sendToSampling(passenger, true);
      if (response?.isOk) {
        notifySuccess(translations.sendToSamplingConfirmation[lang]);
        setStatusDialogOpen(false);
        await reloadPassenger(passenger);
      } else {
        notifyError(translations.operationFailed[lang]);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function onSendToTransit(passenger: Passenger) {
    try {
      const response = await httpService.changeToTransit(
        passenger.serialNumber
      );
      if (response?.isOk) {
        notifySuccess(translations.passengerSentToTransit[lang]);
        setStatusDialogOpen(false);
        await reloadPassenger(passenger);
      } else {
        notifyError(translations.operationFailed[lang]);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function onSendToCertificate(passenger: Passenger) {
    try {
      const response = await httpService.changeToRegisteredWithCertificate(
        passenger.serialNumber
      );
      if (response?.isOk) {
        notifySuccess(translations.passengerSentToCertificate[lang]);
        setStatusDialogOpen(false);
        setRejectPCRTestDialogOpen(false);
        await reloadPassenger(passenger);
      } else {
        notifyError(translations.operationFailed[lang]);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  function confirmExempt(passenger: Passenger, formik: IFormik) {
    httpService
      .confirmExemptPassenger(passenger?.serialNumber as string, childCount)
      .then((response) => {
        if (response.isOk) {
          reloadPassenger(passenger);
          notifySuccess(translations.exemptConfirmed[lang]);
          setConfirmExemptDialogOpen(false);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        }
      })
      .catch(() => {
        notifyError(translations.operationFailed[lang]);
      });
  }

  function declineExempt(passenger: Passenger, formik: IFormik) {
    httpService
      .declineExemptPassenger(passenger?.serialNumber as string, skipPayment)
      .then((response) => {
        if (response.isOk) {
          reloadPassenger(passenger);
          notifySuccess(translations.exemptDeclined[lang]);
          setDeclineExemptDialogOpen(false);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        }
      })
      .catch(() => {
        notifyError(translations.operationFailed[lang]);
      });
  }

  async function saveTravelDetails(passenger: Passenger, commentText: string) {
    const response = await httpService.updateTravelDetails(
      passenger.serialNumber,
      commentText
    );

    if (response.isOk) {
      await reloadPassenger(passenger);
      notifySuccess(translations.commentSaved[lang]);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function onOverruleNegPCRCertificateRejected(
    passenger: Passenger,
    formik: IFormik
  ) {
    try {
      const response = await httpService.overruleNegPCRCertificateRejected(
        passenger.serialNumber
      );
      if (response.isOk) {
        notifySuccess(translations.revokeRejectionSuccess[lang]);
        setOverruleNegPCRCertificateDialogOpen(false);
        await reloadPassenger(passenger);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function rejectNegativePCRTest(passenger: Passenger, formik: IFormik) {
    setLoadingUpdate(true);
    const response = await httpService.rejectNegPCRCertificate(
      passenger.serialNumber,
      commentInput
    );
    if (response.isOk) {
      if (currentPassenger) {
        await reloadPassenger(currentPassenger);
      }
      notifySuccess(translations.negativePCRTestRejected[lang]);
      setRejectPCRTestDialogOpen(false);
      setLoadingUpdate(false);
      setCommentInput('');
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.operationFailed[lang]);
      setLoadingUpdate(false);
    }
  }

  async function notProvidedNegativePCRTest(
    passenger: Passenger,
    formik: IFormik
  ) {
    setLoadingUpdate(true);
    const response = await httpService.notProvidedNegPCRCertificate(
      passenger.serialNumber
    );
    if (response.isOk) {
      if (currentPassenger) {
        await reloadPassenger(currentPassenger);
      }
      notifySuccess(translations.negativePCRTestRejected[lang]);
      setRejectPCRTestDialogOpen(false);
      setLoadingUpdate(false);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.operationFailed[lang]);
      setLoadingUpdate(false);
    }
  }

  async function declineTransit(passenger: Passenger | null, formik: IFormik) {
    setLoadingUpdate(true);
    try {
      const response = await httpService.declineTransit(
        passenger?.serialNumber as string,
        borderControlStatus.REGISTERED_FOR_SAMPLING
      );
      if (response.isOk) {
        if (currentPassenger) {
          await reloadPassenger(currentPassenger);
        }
        notifySuccess(translations.sendToSamplingConfirmation[lang]);
        setDeclineTransitDialogOpen(false);
        setLoadingUpdate(false);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.operationFailed[lang]);
        setLoadingUpdate(false);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
      setLoadingUpdate(false);
    }
  }

  async function updatePassengerInfo(
    passenger: Passenger,
    info: IUpdatePassengerInfo,
    onSuccess: () => void
  ) {
    const response = await httpService.updatePassengerInfo(
      passenger.serialNumber,
      info
    );

    if (response.isOk) {
      await reloadPassenger(passenger);
      notifySuccess(translations.passengerInfoUpdated[lang]);
      onSuccess();
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  /* async function setQuarantineHouseStatus(
    passenger: Passenger,
    registered: boolean,
    onSuccess: () => void
  ) {
    const response = await httpService.setQuarantineHouseStatus(
      passenger.serialNumber,
      registered
    );

    if (response.isOk) {
      await reloadPassenger(passenger);
      notifySuccess(
        registered
          ? translations.sentToQuarantineHouse[lang]
          : translations.removedFromQuarantineHouse[lang]
      );
      onSuccess();
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  } */

  async function checkoutPassenger(
    passenger: Passenger,
    formik: IFormik,
    airlineSurveillanceStatus?: number
  ) {
    const response = await httpService.checkoutPassenger(
      passenger.serialNumber,
      sendToQuarantineCheck(passenger),
      sendToQuarantineHotelCheckbox ? commentInput : undefined,
      airlineSurveillanceStatus
    );

    if (response.isOk) {
      notifySuccess(translations.checkoutPassengerSuccess[lang]);
      setCheckoutDialogOpen(false);
      clearSearch(formik);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function acceptNegativePCRTest(passenger: Passenger, formik: IFormik) {
    setLoadingUpdate(true);
    const response = await httpService.acceptNegPCRCertificate(
      passenger.serialNumber
    );
    if (response.isOk) {
      if (currentPassenger) {
        await reloadPassenger(currentPassenger);
      }
      notifySuccess(translations.negativePCRTestAccepted[lang]);
      setLoadingUpdate(false);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.operationFailed[lang]);
      setLoadingUpdate(false);
    }
  }

  async function confirmSample(formik: IFormik) {
    setLoadingUpdate(true);
    const response = await httpService.markSampleTaken(
      currentPassenger?.sampleId as string,
      childCount
    );

    if (response.isOk) {
      if (currentPassenger) {
        await reloadPassenger(currentPassenger);
      }
      shellHelper.printLabel(currentPassenger);
      notifySuccess(translations.samplingFinished[lang]);
      setFinishSamplingDialogOpen(false);
      setLoadingUpdate(false);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.operationFailed[lang]);
      setFinishSamplingDialogOpen(false);
      setLoadingUpdate(false);
    }
  }

  async function setPreCheckedToManual() {
    setLoadingUpdate(true);
    const response =
      await httpServiceV2.setPreCheckedPassengerToManualConfirmationPath(
        currentPassenger?.serialNumber as string
      );

    if (response.isOk) {
      if (currentPassenger) {
        await reloadPassenger(currentPassenger);
      }
      //notifySuccess(translations.success[lang]);
      setLoadingUpdate(false);
    } else {
      notifyError(translations.operationFailed[lang]);
      setLoadingUpdate(false);
    }
  }

  /* function negativePCRTestCheck(
    passenger: Passenger,
    formik: IFormik
  ): IButton[] {
    const registeredInTransit =
      passenger.borderControlStatus &&
      passenger.borderControlStatus.id ===
        borderControlStatus.REGISTERED_TO_TRANSIT;

    if (
      isNegPCRTestMandatory(user, passenger) &&
      passenger.preScreeningStatus.id ===
        preScreeningStatus.NEGATIVE_PCR_TEST_NOT_PROCESSED
    ) {
      return [
        {
          label: translations.accept[lang],
          disabled: loadingUpdate,
          onClick: () => acceptNegativePCRTest(passenger, formik),
        },
        {
          label: translations.reject[lang],
          variant: 'outlined',
          disabled: loadingUpdate,
          onClick: () => {
            setRejectPCRTestReason(null);
            setRejectPCRTestDialogOpen(true);
          },
        },
        
      ];
    }

    if (registeredInTransit) {
      return [
        {
          label: translations.confirmTransit[lang],
          disabled: loadingUpdate,
          onClick: () => confirmTransit(passenger, formik),
        },
        {
          label: translations.declineTransit[lang],
          onClick: () => onDeclineTransit(),
          variant: 'outlined',
        },
      ];
    }

    return [
      {
        label: translations.print[lang],
        disabled:
          (isNegPCRTestMandatory(user, passenger) && pcrRejected) ||
          loadingUpdate,
        onClick: () => setFinishSamplingDialogOpen(true),
      },
    ];
  } */

  function sendToQuarantineCheck(passenger: Passenger) {
    if (
      Object.values(checkoutCheckbox).includes(true) &&
      checkoutCheckbox.accept
    ) {
      return false;
    }
    if (
      Object.values(checkoutCheckbox).includes(true) &&
      checkoutCheckbox.reject
    ) {
      return true;
    }
    if (sendToQuarantineHotelCheckbox) {
      return true;
    }
    if (
      currentPassenger?.countryStatus?.id ===
        countryStatuses.HIGH_RISK_QUARANTINE_AT_HOTEL ||
      currentPassenger?.countryStatus?.id ===
        countryStatuses.MEDIUM_RISK_QUARANTINE_AT_HOTEL
    ) {
      return true;
    }
    return undefined;
  }

  async function onAcceptQRCodeCertificate(certificate: IQrCodeCertificate) {
    try {
      let response: IHttpAnswer<boolean> | null = null;

      if (
        certificate.certifcateSystem === certificateType.UNITED &&
        currentPassenger
      ) {
        response = await httpService.acceptPreApprovedCertificate(
          currentPassenger.serialNumber
        );
        if (response.isOk) {
          await reloadPassenger(currentPassenger);
          notifySuccess(translations.vaccinationCertificateConfirmed[lang]);
          setQrCodeDialogOpen(false);
        }
      } else if (
        certificate.certificateType === certificateType.VACCINATION &&
        currentPassenger
      ) {
        response = await httpService.acceptVaccinationCertificate(
          currentPassenger.serialNumber
        );
        if (response.isOk) {
          await reloadPassenger(currentPassenger);
          notifySuccess(translations.vaccinationCertificateConfirmed[lang]);
          setQrCodeDialogOpen(false);
        }
      } else if (
        certificate.certificateType === certificateType.ANTIBODY &&
        currentPassenger
      ) {
        response = await httpService.acceptAntibodyCertificate(
          currentPassenger.serialNumber
        );
        if (response.isOk) {
          await reloadPassenger(currentPassenger);
          notifySuccess(translations.antibodyCertificateConfirmed[lang]);
          setQrCodeDialogOpen(false);
        }
      } else if (
        certificate.certificateType === certificateType.PCR &&
        currentPassenger
      ) {
        response = await httpService.acceptNegPCRCertificate(
          currentPassenger.serialNumber
        );
        if (response.isOk) {
          await reloadPassenger(currentPassenger);
          notifySuccess(translations.negativePCRTestAccepted[lang]);
          setQrCodeDialogOpen(false);
        }
      }

      if (response) {
        if (response.isOk) {
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          notifyError(translations.operationFailed[lang]);
        }
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  function clearSearch({ handleReset }: IFormik) {
    setLoading(false);
    setNoResult(false);
    setCurrentPassenger(null);
    setChildCount(0);
    handleReset();
    focusInput();
  }

  function focusInput() {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }

  function getPersonalDetails(): IPassengerDetails[] {
    return [
      {
        label: translations.lastName[lang],
        getVal: (passenger: Passenger) => passenger.lastName,
      },
      {
        label: translations.firstNames[lang],
        getVal: (passenger: Passenger) => passenger.firstName,
      },
      {
        label: translations.dateOfBirth[lang],
        getVal: (passenger: Passenger) =>
          passenger.dateOfBirth
            ? moment(passenger.dateOfBirth).format(DATE_FORMAT)
            : translations.notRegistered[lang],
      },
      {
        label: translations.phoneNumber[lang],
        getVal: getPhoneNumberString,
      },
      {
        label: translations.email[lang],
        getVal: (passenger: Passenger) => passenger.email || '',
      },
      {
        label: translations.numberOfChildren[lang],
        getVal: (passenger: Passenger) => passenger.numberOfChildren.toString(),
      },
    ];
  }

  function getOtherDetails(): IPassengerDetails[] {
    return [
      {
        label: translations.countryOfDeparture[lang],
        getVal: (passenger: Passenger) =>
          passenger.countryOfDeparture || translations.notRegistered[lang],
      },

      {
        label: translations.departureFromIceland[lang],
        getVal: (passenger: Passenger) =>
          passenger.departureFromIceland
            ? moment(passenger.departureFromIceland).format(DATE_FORMAT)
            : translations.notRegistered[lang],
      },
    ];
  }

  function getExtraSamplingDetals(): IPassengerDetails[] {
    return [
      {
        label: translations.icelandRelationsShort[lang],
        getVal: (passenger: Passenger) =>
          passenger.extraSamplingReqired
            ? translations.yes[lang]
            : translations.no[lang],
      },
      {
        label: translations.samplingAtTheAirport[lang],
        getVal: (passenger: Passenger) =>
          passenger.extraSamplingSelectedTestLocation === 0
            ? translations.yes[lang]
            : translations.no[lang],
      },
    ];
  }
};

export default BorderControlSamplingPage;
