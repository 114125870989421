import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
import {
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { Passenger } from '../models/Models';
import { isRapidBarcode } from '../utils';

var Barcode = require('react-barcode');

interface IProps {
  passenger: Passenger;
  disabled?: boolean;
  disabledText?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: `calc(100vh - ${theme.dimensions.navbarHeight}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      textAlign: 'center',
      marginTop: 100,
    },
    text: {
      padding: theme.spacing(2),
    },
    button: {
      padding: theme.spacing(0),
      marginLeft: theme.spacing(1),
    },
    disabled: {
      cursor: 'default',
    },
    buttonIcon: {
      fontSize: 22,
    },
  })
);

const Print: React.FC<IProps> = ({ passenger, disabled, disabledText }) => {
  const classes = useStyles({});
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <React.Fragment>
      <div style={{ display: 'none' }}>
        <div ref={componentRef} className={classes.paper}>
          {renderText(passenger)}
          <Barcode height={50} width={1} value={passenger.serialNumber} />
        </div>
      </div>
      {!disabled && (
        <ReactToPrint
          trigger={() => (
            <IconButton className={classes.button} color="primary">
              <PrintIcon />
            </IconButton>
          )}
          content={() => componentRef.current}
        />
      )}

      {disabled && disabledText && (
        <Tooltip
          title={<Typography variant="subtitle1">{disabledText}</Typography>}
        >
          <IconButton className={`${classes.button} ${classes.disabled}`}>
            <PrintIcon />
          </IconButton>
        </Tooltip>
      )}
      {disabled && !disabledText && (
        <IconButton className={`${classes.button} ${classes.disabled}`}>
          <PrintIcon />
        </IconButton>
      )}
    </React.Fragment>
  );

  function renderText(passenger: Passenger) {
    if (isRapidBarcode(passenger?.serialNumber)) {
      return (
        <Typography variant="h6" className={classes.text}>
          RAPID TEST
        </Typography>
      );
    }

    return (
      <Typography variant="h6" className={classes.text}>
        {'         '}
      </Typography>
    );
  }
};

export default Print;
