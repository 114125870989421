import React, { useContext, useState } from 'react';
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Typography,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
// import { saveAs } from 'file-saver';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import { Qrcode } from 'mdi-material-ui';

import { LangContext } from '../../context/LangContext';
import translations from '../../assets/json/translations.json';
import { PassengerFiles } from '../../models/CertificateReviewModels';
import theme from '../../theme';
import LoadingIndicator from '../LoadingIndicator';
import * as certificateReviewService from '../../services/certificateReviewService';
import { qrCodeErrorSeverity, reviewFileStatuses } from '../../constants/enums';
import { DATE_FORMAT } from '../../constants';
import { Passenger } from '../../models/Models';
import { getFileValidationErrors } from '../../utils';
import DisplayQRCodeDialog from './DisplayQRCodeDialog';

interface IProps {
  files: PassengerFiles[];
  loading?: boolean;
  passenger: Passenger;
}

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderBottomWidth: 0,
    borderRadius: 4,
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  downloadIcon: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(-1),
  },
  circleIcon: {
    color: theme.palette.success.main,
    marginBottom: theme.spacing(-1),
  },
  warningIcon: {
    color: theme.palette.warning.light,
    marginBottom: theme.spacing(-1),
  },
  cancelIcon: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(-1),
  },
  infoIcon: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(-1),
  },
}));

const ReviewFileList: React.FC<IProps> = ({ files, loading, passenger }) => {
  const classes = useStyles({});
  const [lang] = useContext(LangContext);
  const [reviewQRCodeOfFile, setReviewQRCodeOfFile] =
    useState<PassengerFiles | null>(null);

  return (
    <div className={classes.container}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{translations.certificateType[lang]}</TableCell>
              <TableCell
                style={{
                  textAlign: 'center',
                }}
              >
                {translations.preCheck[lang]}
              </TableCell>
              <TableCell>{translations.certificateInfo[lang]}</TableCell>
              <TableCell
                style={{
                  textAlign: 'center',
                }}
              >
                {translations.viewQRCode[lang]}
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'center',
                }}
              >
                {translations.viewDocument[lang]}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              files.map((file) => (
                <TableRow
                  hover
                  key={file.id}
                  style={{ cursor: 'pointer' }}
                  //onClick={() => fetchFile(file.id, file.fileName)}
                >
                  <TableCell onClick={() => fetchFile(file.id, file.fileName)}>
                    {file.fileType.description[lang]}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: 'center',
                    }}
                    onClick={() => fetchFile(file.id, file.fileName)}
                  >
                    {renderFileIcons(file)}
                  </TableCell>
                  <TableCell onClick={() => fetchFile(file.id, file.fileName)}>
                    {getCertificateInfo(file)}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: 'center',
                      padding: theme.spacing(1),
                    }}
                  >
                    {file.fileStatus !== reviewFileStatuses.NOT_CERTIFICATE && (
                      <Qrcode
                        className={classes.downloadIcon}
                        onClick={() => {
                          setReviewQRCodeOfFile(file);
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: 'center',
                      padding: theme.spacing(1),
                    }}
                    onClick={() => fetchFile(file.id, file.fileName)}
                  >
                    <SearchIcon
                      fontSize={'default'}
                      className={classes.downloadIcon}
                      onClick={() => fetchFile(file.id, file.fileName)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {loading && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: theme.spacing(2) }}
          >
            <LoadingIndicator />
          </Grid>
        )}
      </TableContainer>
      <DisplayQRCodeDialog
        open={!!reviewQRCodeOfFile}
        passenger={passenger}
        onCancel={() => setReviewQRCodeOfFile(null)}
        file={reviewQRCodeOfFile}
      />
    </div>
  );

  async function fetchFile(id: number | undefined, name: string | undefined) {
    if (id === undefined) {
      return;
    }
    const response = await certificateReviewService.DownloadFileById(id);
    if (response.isOk && response.data) {
      const url = URL.createObjectURL(response.data);
      window.open(url, 'test');
      URL.revokeObjectURL(url);
      //saveAs(response.data, name);
    }
  }

  function getPreValidationStatus(file: PassengerFiles) {
    const validationSeverity = file?.validationErrors?.map(
      (item) => item.severity
    );

    if (
      validationSeverity?.includes(qrCodeErrorSeverity.ERROR) ||
      validationSeverity?.includes(qrCodeErrorSeverity.INVALID)
    ) {
      return <CancelIcon className={classes.cancelIcon} />;
    }
    if (validationSeverity?.includes(qrCodeErrorSeverity.WARNING)) {
      return <WarningIcon className={classes.warningIcon} />;
    }
    if (validationSeverity?.includes(qrCodeErrorSeverity.INFO)) {
      return <InfoIcon className={classes.infoIcon} />;
    }

    return <CheckCircleIcon className={classes.circleIcon} />;
  }

  function getCertificateInfo(file: PassengerFiles) {
    if (file.fileStatus !== reviewFileStatuses.NOT_CERTIFICATE) {
      return `${file.firstName} ${file.lastName} ${
        file.dateOfBirth && moment(file.dateOfBirth).format(DATE_FORMAT)
      }`;
    }
  }

  function renderFileIcons(file: PassengerFiles) {
    const frontEndErrors = getFileValidationErrors(file);

    if (frontEndErrors) {
      return (
        <Tooltip
          title={<Typography variant="subtitle1">{frontEndErrors}</Typography>}
        >
          {getPreValidationStatus(file)}
        </Tooltip>
      );
    }
    return getPreValidationStatus(file);
  }
};

export default ReviewFileList;
