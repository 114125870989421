import React, { useContext, useState, useEffect } from 'react';
import {
  Typography,
  makeStyles,
  Grid,
  Paper,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import moment, { Moment } from 'moment';

import Button from './Button';
import DatePicker from './DatePicker';
import Dropdown from './Dropdown';
import Autocomplete, { IOption } from './Autocomplete';
import ConfirmDialog from './ConfirmDialog';
import { LangContext } from '../context/LangContext';
import { UserContext } from '../context/UserContext';
import translations from '../assets/json/translations.json';
import * as localStorageKeys from '../constants/localStorageKeys';
import { parseTemplateString, getYears } from '../utils';
import { DATE_FORMAT } from '../constants';
import { VaccinationGroup } from '../models/VaccinationModels';

export interface IProps {
  groups: VaccinationGroup[] | null;
  selectedYearFrom: string | null;
  selectedYearTo: string | null;
  selectedSummoningFrom: Moment | null;
  selectedSummoningTo: Moment | null;
  selectedGroups: VaccinationGroup[] | null;
  selectedAlwaysAllowSecond: boolean;
  setSelectedYearFrom: (year: string | null) => void;
  setSelectedYearTo: (year: string | null) => void;
  setSelectedSummoningFrom: (date: Moment | null) => void;
  setSelectedSummoningTo: (date: Moment | null) => void;
  setSelectedGroups: (groups: VaccinationGroup[] | null) => void;
  setSelectedAlwaysAllowSecond: (allow: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  options: {
    padding: theme.spacing(3),
  },
  descriptionTitle: {
    fontWeight: 'bold',
    paddingRight: 10,
  },
  descriptionDetails: {
    color: theme.palette.text.secondary,
  },
  editButton: {
    marginLeft: theme.spacing(2),
  },
  groupsTitle: {
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: theme.spacing(2),
  },
}));

const VaccinationConditions: React.FC<IProps> = ({
  groups,
  selectedYearFrom,
  selectedYearTo,
  selectedSummoningFrom,
  selectedSummoningTo,
  selectedGroups,
  selectedAlwaysAllowSecond = false,
  setSelectedYearFrom,
  setSelectedYearTo,
  setSelectedSummoningFrom,
  setSelectedSummoningTo,
  setSelectedGroups,
  setSelectedAlwaysAllowSecond,
}) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [updatedYearFrom, setUpdatedYearFrom] = useState<IOption | null>(null);
  const [updatedYearTo, setUpdatedYearTo] = useState<IOption | null>(null);
  const [updatedSummoningFrom, setUpdatedSummoningFrom] =
    useState<Moment | null>(null);
  const [updatedSummoningTo, setUpdatedSummoningTo] = useState<Moment | null>(
    null
  );
  const [region, setRegion] = useState('');
  const [regionGroups, setRegionGroups] = useState<IOption[]>([]);
  const [updatedGroups, setUpdatedGroups] = useState<IOption[]>([]);
  const [updatedAlwaysAllowSecond, setUpdatedAlwaysAllowSecond] =
    useState(false);
  const hasOnlyOneRegion = user?.vaccinationRegionModelList?.length === 1;

  useEffect(() => {
    setUpdatedYearFrom(
      selectedYearFrom
        ? { value: selectedYearFrom, label: selectedYearFrom }
        : null
    );
    setUpdatedYearTo(
      selectedYearTo ? { value: selectedYearTo, label: selectedYearTo } : null
    );
    setUpdatedSummoningFrom(selectedSummoningFrom);
    setUpdatedSummoningTo(selectedSummoningTo);
    setUpdatedGroups(
      selectedGroups
        ? selectedGroups.map((g) => ({ value: g.id, label: g.name }))
        : []
    );
    setUpdatedAlwaysAllowSecond(!!selectedAlwaysAllowSecond);

    const oneRegion = user?.vaccinationRegionModelList?.length === 1;
    setRegion(
      oneRegion ? user?.vaccinationRegionModelList[0].id.toString() : ''
    );
  }, [
    selectedYearFrom,
    selectedYearTo,
    selectedSummoningFrom,
    selectedSummoningTo,
    selectedGroups,
    selectedAlwaysAllowSecond,
    editDialogOpen,
    user,
  ]);

  useEffect(() => {
    const updatedIds = updatedGroups.map((g) => g.value);
    setRegionGroups([
      ...updatedGroups,
      ...(groups || [])
        .filter(
          (g) => g.vaccinationRegion.id === region && !updatedIds.includes(g.id)
        )
        .map((g) => ({ value: g.id, label: g.name })),
    ]);
  }, [groups, region, updatedGroups]);

  return (
    <Paper elevation={2} className={classes.options}>
      <Grid container direction="row" alignItems="flex-start">
        <div style={{ flex: 1 }}>
          {groups === null ? (
            <Typography
              color="textSecondary"
              className={classes.descriptionDetails}
            >
              {translations.loadingConditions[lang]}
            </Typography>
          ) : (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                {renderInfoItem(
                  translations.yearOfBirth[lang],
                  getFormattedYears()
                )}
              </Grid>
              <Grid item>
                {renderInfoItem(
                  translations.summoning[lang],
                  getFormattedSummoning()
                )}
              </Grid>
              <Grid item>
                {renderInfoItem(
                  translations.groupsLabel[lang],
                  selectedGroups
                    ? selectedGroups.map((g) => g.name).join(', ')
                    : null
                )}
              </Grid>
              <Grid item>
                {renderInfoItem(
                  translations.alwaysAllowSecondVaccinationShort[lang],
                  selectedAlwaysAllowSecond
                    ? translations.yes[lang]
                    : translations.no[lang]
                )}
              </Grid>
            </Grid>
          )}
        </div>
        <Button
          padding={false}
          color="secondary"
          onClick={() => setEditDialogOpen(true)}
          className={classes.editButton}
        >
          {translations.edit[lang]}
        </Button>
      </Grid>
      {renderDialog()}
    </Paper>
  );

  function renderInfoItem(title: string, text: string | null) {
    return (
      <Typography>
        <span className={classes.descriptionTitle}>{title}:</span>
        <span className={classes.descriptionDetails}>
          {text || translations.notRegistered[lang]}
        </span>
      </Typography>
    );
  }

  function renderDialog() {
    return (
      <ConfirmDialog
        title={translations.conditions[lang]}
        open={editDialogOpen}
        onConfirm={updateConditions}
        onCancel={() => setEditDialogOpen(false)}
        leftButtonText={translations.clear[lang]}
        onLeftButtonClick={clearInput}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              fullWidth
              id="yearFrom"
              value={updatedYearFrom}
              label={translations.birthYearFrom[lang]}
              items={getYears({
                max: updatedYearTo ? updatedYearTo.value : undefined,
              })}
              onChange={(val) => setUpdatedYearFrom(val as IOption)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              fullWidth
              id="yearTo"
              value={updatedYearTo}
              label={translations.birthYearTo[lang]}
              items={getYears({
                min: updatedYearFrom ? updatedYearFrom.value : undefined,
              })}
              onChange={(val) => setUpdatedYearTo(val as IOption)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              id="summoningFrom"
              label={translations.summoningFrom[lang]}
              value={updatedSummoningFrom}
              onChange={(e) => setUpdatedSummoningFrom(e ? moment(e) : null)}
              muiProps={{
                maxDate: updatedSummoningTo || undefined,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              id="summoningTo"
              label={translations.summoningTo[lang]}
              value={updatedSummoningTo}
              onChange={(e) => setUpdatedSummoningTo(e ? moment(e) : null)}
              muiProps={{
                minDate: updatedSummoningFrom || undefined,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.groupsTitle}>
              {translations.groupsLabel[lang]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              fullWidth
              id="regions"
              value={region}
              label={translations.region[lang]}
              items={user.vaccinationRegionModelList.map((r: any) => ({
                label: r.description || '',
                value: r.id !== undefined ? r.id.toString() : '',
              }))}
              onChange={(e) => setRegion(e.target.value as string)}
              disabled={hasOnlyOneRegion}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              id="groups"
              values={updatedGroups}
              label={translations.groupsLabel[lang]}
              items={regionGroups}
              onChange={(e) => setUpdatedGroups(e as IOption[])}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={translations.alwaysAllowSecondVaccination[lang]}
              control={
                <Checkbox
                  color="primary"
                  checked={updatedAlwaysAllowSecond}
                  onChange={(e) =>
                    setUpdatedAlwaysAllowSecond(e.target.checked)
                  }
                />
              }
            />
          </Grid>
        </Grid>
      </ConfirmDialog>
    );
  }

  function getFormattedYears() {
    if (!selectedYearFrom && !selectedYearTo) {
      return null;
    }
    if (selectedYearFrom && selectedYearTo) {
      return `${selectedYearFrom} - ${selectedYearTo}`;
    }
    if (selectedYearFrom) {
      return parseTemplateString(
        translations.bornInYearOrLater[lang],
        selectedYearFrom
      );
    }
    return parseTemplateString(
      translations.bornInYearOrBefore[lang],
      selectedYearTo || ''
    );
  }

  function getFormattedSummoning() {
    if (!selectedSummoningFrom && !selectedSummoningTo) {
      return null;
    }
    if (selectedSummoningFrom && selectedSummoningTo) {
      return `${selectedSummoningFrom.format(
        DATE_FORMAT
      )} - ${selectedSummoningTo.format(DATE_FORMAT)}`;
    }
    if (selectedSummoningFrom) {
      return parseTemplateString(
        translations.summoningLater[lang],
        selectedSummoningFrom.format(DATE_FORMAT)
      );
    }
    return parseTemplateString(
      translations.summoningBefore[lang],
      selectedSummoningTo ? selectedSummoningTo.format(DATE_FORMAT) : ''
    );
  }

  function clearInput() {
    setUpdatedYearFrom(null);
    setUpdatedYearTo(null);
    setUpdatedSummoningFrom(null);
    setUpdatedSummoningTo(null);
    setRegion('');
    setUpdatedGroups([]);
    setUpdatedAlwaysAllowSecond(false);
  }

  function updateConditions() {
    const updatedYearFromValue =
      updatedYearFrom && !isNaN(parseInt(updatedYearFrom.value))
        ? updatedYearFrom.value
        : '';
    setSelectedYearFrom(updatedYearFromValue);
    localStorage.setItem(
      localStorageKeys.VACCINATION_CONDITIONS_YEAR_FROM,
      updatedYearFromValue
    );

    const updatedYearToValue =
      updatedYearTo && !isNaN(parseInt(updatedYearTo.value))
        ? updatedYearTo.value
        : '';
    setSelectedYearTo(updatedYearToValue);
    localStorage.setItem(
      localStorageKeys.VACCINATION_CONDITIONS_YEAR_TO,
      updatedYearToValue
    );

    const updatedSummoningFromValue = updatedSummoningFrom
      ? updatedSummoningFrom.toISOString()
      : '';
    setSelectedSummoningFrom(updatedSummoningFrom);
    localStorage.setItem(
      localStorageKeys.VACCINATION_CONDITIONS_SUMMONING_FROM,
      updatedSummoningFromValue
    );

    const updatedSummoningToValue = updatedSummoningTo
      ? updatedSummoningTo.toISOString()
      : '';
    setSelectedSummoningTo(updatedSummoningTo);
    localStorage.setItem(
      localStorageKeys.VACCINATION_CONDITIONS_SUMMONING_TO,
      updatedSummoningToValue
    );

    const updatedGroupIds = updatedGroups.map((g) => g.value);
    const updatedGroupObjects = (groups || []).filter((g) =>
      updatedGroupIds.includes(g.id)
    );
    setSelectedGroups(updatedGroupObjects);
    localStorage.setItem(
      localStorageKeys.VACCINATION_CONDITIONS_GROUPS,
      JSON.stringify(updatedGroupObjects.map((g) => g.id))
    );

    setSelectedAlwaysAllowSecond(updatedAlwaysAllowSecond);
    localStorage.setItem(
      localStorageKeys.VACCINATION_CONDITIONS_ALWAYS_ALLOW_SECOND,
      JSON.stringify(updatedAlwaysAllowSecond)
    );

    setEditDialogOpen(false);
  }
};

export default VaccinationConditions;
