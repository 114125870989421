import {
  VaccinationGroup,
  VaccinationGroupPersons,
  VaccinationPerson,
  Vaccine,
  VaccinationLocation,
  VaccinationPersonSearchQuery,
  LocationSummary,
  SummaryTimeSlot,
  VaccinationRegistrationRequest,
  ChildInfo,
  VaccinationPersonGroup,
} from '../models/VaccinationModels';
import { IHttpAnswer } from '../interfaces/httpAnswer';
import { IVaccinationAddPerson } from '../interfaces/vaccinationAddPerson';
import {
  IBoosterVaccinationCandidateSearchQuery,
  IFirstVaccinationCandidateSearchQuery,
  ISecondVaccinationCandidateSearchQuery,
  IVaccinationCandidateSearchQuery,
} from '../interfaces/vaccinationCandidate';
import {
  IVaccinationGroup,
  IVaccinationGroupDetails,
} from '../interfaces/vaccinationGroup';
import {
  IVaccinationGroupPersonList,
  IVaccinationPerson,
  IVaccinationPersonPhoneNumberUpdate,
} from '../interfaces/vaccinationPerson';
import { IVaccinationRegion } from '../interfaces/vaccinationRegion';
import { IVaccinationGroupPersons } from '../interfaces/vaccinationGroupPersons';
import {
  IVaccinationSummonAcrossGroups,
  IVaccinationSummonGroup,
} from '../interfaces/vaccinationSummonGroup';
import { IVaccinate } from '../interfaces/vaccinate';
import {
  IVaccinationComment,
  IVaccinationCommentStatus,
} from '../interfaces/vaccinationComment';
import { IVaccinationUpdate } from '../interfaces/vaccinationUpdate';
import {
  ICreateVaccinationCode,
  IUpdateVaccinationCode,
} from '../interfaces/vaccinationCodes';
import { ISendCertificateValues } from '../interfaces/vaccinationSearch';
import api from '../api';
import { ISendWaitingListValues } from '../interfaces/vaccinationWaitingList';

import {
  IRegisterForVaccination,
  IUpdateRegistrationForVaccination,
} from '../interfaces/RegisterForVaccination';
import {
  ErrorInfo,
  IUpdateVaccinationGuardianDecision,
} from '../models/Models';
import { ICreateGuardian } from '../interfaces/vaccinationChild';

async function lookupVaccinationPersons(
  lookup: IVaccinationGroupPersonList[]
): Promise<IHttpAnswer<Array<VaccinationPerson> | null>> {
  const response = await api.post(
    `/Vaccination/LookupVaccinationPersons`,
    lookup
  );
  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((row: any) => new VaccinationPerson(row)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function lookupVaccinationPersons2(
  lookup: IVaccinationGroupPersonList[]
): Promise<IHttpAnswer<VaccinationPerson | ErrorInfo>> {
  const response = await api.post(
    `/Vaccination/LookupVaccinationPersons`,
    lookup
  );

  if (response.isOk && response.data.length > 0) {
    const data = response.data[0];

    if (data.isValid) {
      return {
        data: new VaccinationPerson(data),
        isOk: response.isOk,
        statusCode: response.statusCode,
      };
    } else {
      return {
        data: new ErrorInfo(404, data.errorMessage),
        isOk: false,
        statusCode: 404,
      };
    }
  }

  return {
    data: new ErrorInfo(response.statusCode, 'Something went wrong'),
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function removeDuplicatesVaccinationPersons(
  lookup: VaccinationGroupPersons[]
): Promise<IHttpAnswer<Array<VaccinationPerson> | null>> {
  const response = await api.post(
    `/Vaccination/CleanupVaccinationPersonsDuplicates`,
    lookup
  );
  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((row: any) => new VaccinationPerson(row)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function addPersonsToGroup(
  groupId: number,
  persons: Array<IVaccinationAddPerson>
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Vaccination/AddPersonsToGroup?groupId=${groupId}`,
    persons
  );
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getGroups(
  regionId?: string
): Promise<IHttpAnswer<Array<VaccinationGroup> | null>> {
  const response = await api.get(
    `/Vaccination/GetGroups${regionId ? `?regionId=${regionId}` : ''}`
  );
  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((g: any) => new VaccinationGroup(g)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getPersonsInGroup(
  groupId: string
): Promise<IHttpAnswer<IVaccinationGroupDetails | null>> {
  const response = await api.get(
    `/Vaccination/GetPersonsInGroup?groupId=${groupId}`
  );

  if (response.isOk) {
    const data = response.data;
    return {
      data: {
        ...new VaccinationGroup(data.group),
        members: data.persons.map((p: any) => new VaccinationPerson(p)),
      },
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function addGroup(
  group: IVaccinationGroup
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(`/Vaccination/AddPersonsToGroup`, group);
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function addGroupWithPersons(
  groupPersons: IVaccinationGroupPersons
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Vaccination/AddGroupWithPersons`,
    groupPersons
  );
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function removePersonFromGroup(
  groupId: number,
  ssn: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.delete(
    `/Vaccination/RemovePersonFromGroup/${groupId}`,
    ssn
  ); // todo check delete with payload
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function removePersonsFromGroup(
  groupId: string,
  ssnList: string[]
): Promise<IHttpAnswer<boolean>> {
  const response = await api.delete(
    `/Vaccination/RemovePersonsFromGroup?groupid=${groupId}`,
    ssnList
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function replacePersonsInGroup(
  groupId: number,
  persons: VaccinationGroupPersons[]
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Vaccination/ReplacePersonsInGroup?groupId=${groupId}`,
    persons
  );
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function updateGroup(
  groupId: number,
  groupPersons: IVaccinationGroupPersons
): Promise<IHttpAnswer<boolean>> {
  const response = await api.put(
    `/Vaccination/UpdateGroup?groupId=${groupId}`,
    groupPersons
  );
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getPersonInfo(
  ssn: string
): Promise<IHttpAnswer<IVaccinationPerson>> {
  const response = await api.get(`/Vaccination/GetPersonInfo/${ssn}`);
  return {
    data: await response.data,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getRegions(): Promise<
  IHttpAnswer<Array<IVaccinationRegion> | null>
> {
  const response = await api.get(`/Vaccination/GetRegions`);
  if (response.isOk) {
    return {
      data: await response.data,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getVaccines(): Promise<IHttpAnswer<Vaccine[]>> {
  const response = await api.get(`/Vaccination/GetVaccinationCodes`);

  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((d: any) => new Vaccine(d)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: [],
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getLocations(): Promise<IHttpAnswer<VaccinationLocation[]>> {
  const response = await api.get(`/Vaccination/GetLocations`);

  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((d: any) => new VaccinationLocation(d)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: [],
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function sendBarcodeSmsToGroup(
  groupId: number
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Vaccination/SendBarcodeSmsToGroup`,
    groupId
  );
  return {
    data: await response.data,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function deleteGroup(groupId: string): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Vaccination/RemoveGroup?groupId=${groupId}`,
    null
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function vaccinate(
  vaccinationInfo: IVaccinate
): Promise<IHttpAnswer<string>> {
  // use api.fetch instead since we are converting erros into just text
  const response = await api.fetch(
    `/Vaccination/Vaccinate`,
    'POST',
    vaccinationInfo
  );

  if (response.ok) {
    return {
      data: '',
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  const error = await response.text();
  return {
    data: error,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function summonAGroup(
  summonGroup: IVaccinationSummonGroup
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(`/Vaccination/SummonAGroup`, summonGroup);

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function summonAcrossGroups(
  summonPersons: IVaccinationSummonAcrossGroups
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(`/Vaccination/SummonPersons`, summonPersons);
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function cancelVaccination(
  personSsn: string,
  vaccinationId: number
): Promise<IHttpAnswer<string>> {
  const response = await api.fetch('/Vaccination/CancelVaccination', 'POST', {
    personSsn,
    vaccinationId,
  });
  if (response.ok) {
    return {
      data: '',
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  const error = await response.text();
  return {
    data: error,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function getVaccinationPersonBySerialNumber(
  serialNumber: string
): Promise<IHttpAnswer<VaccinationPerson | null>> {
  const response = await api.get(
    `/Vaccination/GetPersonByBarcode?barcode=${serialNumber}`
  );

  if (response.isOk) {
    const data = response.data;
    return {
      data: new VaccinationPerson(data),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getVaccinationPersonBySSN(
  ssn: string
): Promise<IHttpAnswer<VaccinationPerson | null>> {
  const response = await api.get(`/Vaccination/GetPersonBySsn?ssn=${ssn}`);

  if (response.isOk) {
    const data = response.data;
    return {
      data: new VaccinationPerson(data),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function searchForVaccinationPerson(
  query: VaccinationPersonSearchQuery
): Promise<IHttpAnswer<VaccinationPerson[] | null>> {
  // currently the only service that uses the autoErrorHandler in the api wrapper
  const response = await api.post(
    `/Vaccination/SearchForVaccinationPerson`,
    query,
    true
  );

  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((p: any) => new VaccinationPerson(p)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function addVaccinationComment(
  commentInfo: IVaccinationComment
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(`/Vaccination/AddComment`, commentInfo);

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function updateVaccinationComment(
  commentId: number,
  commentInfo: IVaccinationComment
): Promise<IHttpAnswer<boolean>> {
  const response = await api.put(
    `/Vaccination/UpdateComment/${commentId}`,
    commentInfo
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function deleteVaccinationComment(
  commentId: number
): Promise<IHttpAnswer<boolean>> {
  const response = await api.delete(`/Vaccination/DeleteComment/${commentId}`);

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getVaccinationCommentStatusList(): Promise<
  IHttpAnswer<IVaccinationCommentStatus[]>
> {
  const response = await api.get(
    `/Vaccination/GetVaccinationCommentStatusList`
  );

  if (response.isOk) {
    const data = response.data;
    return {
      data,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: [],
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function updateVaccination(
  vaccinationInfo: IVaccinationUpdate
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Vaccination/UpdateVaccination`,
    vaccinationInfo
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getVaccinationSummary(
  regionId: string,
  date: string
): Promise<IHttpAnswer<LocationSummary[]>> {
  const response = await api.get(
    `/Vaccination/GetVaccinationSummary?vaccinationRegionId=${regionId}&date=${date}`
  );

  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((d: any) => new LocationSummary(d)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: [],
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getVaccinationSummaryDetails(
  locationId: string,
  date: string
): Promise<IHttpAnswer<SummaryTimeSlot[]>> {
  const response = await api.get(
    `/Vaccination/GetVaccinationSummaryDetails?locationId=${locationId}&date=${date}`
  );

  if (response.isOk) {
    const data = response.statusCode !== 204 ? await response.data : [];
    return {
      data: data.map((d: any) => new SummaryTimeSlot(d)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: [],
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function updatePhoneNumber(
  newPhoneInfo: IVaccinationPersonPhoneNumberUpdate
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Vaccination/UpdatePersonsPhoneNumber`,
    newPhoneInfo
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function movePersonsBetweenGroups(
  fromGroupId: string,
  toGroupId: string,
  ssnList: string[],
  copy: boolean
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Vaccination/MovePersonsBetweenGroups?fromGroupId=${fromGroupId}&toGroupId=${toGroupId}&copy=${copy}`,
    ssnList
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function createVaccinationCode(
  vaccinationLot: ICreateVaccinationCode
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Vaccination/CreateVaccinationCode`,
    vaccinationLot
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function updateVaccinationCode(
  vaccineId: string,
  vaccinationLot: IUpdateVaccinationCode
): Promise<IHttpAnswer<boolean>> {
  const response = await api.put(
    `/Vaccination/UpdateVaccinationCode/${vaccineId}`,
    vaccinationLot
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getFirstVaccinationCandidates(
  query: IFirstVaccinationCandidateSearchQuery
): Promise<IHttpAnswer<VaccinationPerson[] | null>> {
  const response = await api.post(
    `/Vaccination/SearchForFirstVaccinationCandidates`,
    query
  );

  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((p: any) => new VaccinationPerson(p)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getSecondVaccinationCandidates(
  query: ISecondVaccinationCandidateSearchQuery
): Promise<IHttpAnswer<VaccinationPerson[] | null>> {
  const response = await api.post(
    `/Vaccination/SearchForSecondVaccinationCandidates`,
    query
  );

  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((p: any) => new VaccinationPerson(p)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getBoosterVaccinationCandidates(
  query: IBoosterVaccinationCandidateSearchQuery
): Promise<IHttpAnswer<VaccinationPerson[] | null>> {
  const response = await api.post(
    `/Vaccination/SearchForBoosterVaccinationCandidates`,
    query
  );

  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((p: any) => new VaccinationPerson(p)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getVaccinationCandidates(
  query: IVaccinationCandidateSearchQuery
): Promise<IHttpAnswer<VaccinationPerson[] | null>> {
  const response = await api.post(
    `/Vaccination/GenericSearchForVaccinationCandidates`,
    query
  );

  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((p: any) => new VaccinationPerson(p)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function sendVaccinationCertificateEmail(
  values: ISendCertificateValues
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Vaccination/SendVaccinationCertificateToEmail`,
    values
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getVaccinationRegistrationRequests(
  values: ISendWaitingListValues
): Promise<IHttpAnswer<Array<VaccinationRegistrationRequest> | null>> {
  const response = await api.post(
    `/Vaccination/GetVaccinationRegistrationRequests`,
    values
  );

  if (response.isOk) {
    const dataList = await response.data;
    return {
      data: dataList.map((g: any) => new VaccinationRegistrationRequest(g)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: [],
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getVaccinationRegistrationRequest(
  id: number
): Promise<IHttpAnswer<VaccinationRegistrationRequest | null>> {
  const response = await api.get(
    `/Vaccination/GetVaccinationRegistrationRequest?vaccinationRegistrationRequestId=${id}`
  );
  if (response.isOk) {
    const data = await response.data;
    return {
      data: new VaccinationRegistrationRequest(data),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function registerForVaccination(
  values: IRegisterForVaccination
): Promise<IHttpAnswer<Boolean>> {
  const response = await api.post(
    `/Vaccination/RegisterForVaccination`,
    values
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function updateVaccinationRegistrationRequest(
  info: IUpdateRegistrationForVaccination
): Promise<IHttpAnswer<Boolean>> {
  const response = await api.put(
    `/Vaccination/UpdateVaccinationRegistrationRequest`,
    info
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function updateVaccinationRegistrationRequestStatus(
  registrationRequestId: number,
  registrationRequestStatus: 0 | 1 | 2 | 3
): Promise<IHttpAnswer<Boolean>> {
  // New = 0,
  // Processed = 1,
  // Rejected = 2,
  // Deleted = 3
  const response = await api.put(
    `/Vaccination/UpdateVaccinationRegistrationRequestStatus?registrationRequestId=${registrationRequestId}&registrationRequestStatus=${registrationRequestStatus}`,
    null
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getChildInfo(
  id: number
): Promise<IHttpAnswer<ChildInfo | null>> {
  const response = await api.get(`/Vaccination/GetChildInfo/${id}`);
  if (response.isOk) {
    const data = await response.data;
    return {
      data: new ChildInfo(data),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function updateGuardianDecision(
  id: number,
  decision: IUpdateVaccinationGuardianDecision
): Promise<IHttpAnswer<boolean>> {
  const response = await api.put(
    `/Vaccination/UpdateVaccinationChildGuardianDecision/${id}`,
    decision
  );
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function addGuardianToPerson(
  guardian: ICreateGuardian
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post('/Vaccination/AddGuardianToPerson', guardian);
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getAllGroupsByPersonId(
  personId: number
): Promise<IHttpAnswer<VaccinationPersonGroup[] | null>> {
  const response = await api.get(
    `/Vaccination/GetAllGroupsByPersonId/${personId}`
  );
  if (response.isOk) {
    return {
      data:
        response.data && response.data.length
          ? response.data.map((item: any) => new VaccinationPersonGroup(item))
          : [],
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

export {
  lookupVaccinationPersons,
  removeDuplicatesVaccinationPersons,
  addPersonsToGroup,
  getPersonsInGroup,
  getGroups,
  addGroup,
  removePersonFromGroup,
  removePersonsFromGroup,
  replacePersonsInGroup,
  getPersonInfo,
  getRegions,
  getVaccines,
  getLocations,
  sendBarcodeSmsToGroup,
  addGroupWithPersons,
  deleteGroup,
  updateGroup,
  vaccinate,
  summonAGroup,
  summonAcrossGroups,
  cancelVaccination,
  searchForVaccinationPerson,
  getVaccinationPersonBySerialNumber,
  getVaccinationPersonBySSN,
  addVaccinationComment,
  updateVaccinationComment,
  deleteVaccinationComment,
  getVaccinationCommentStatusList,
  updateVaccination,
  getVaccinationSummary,
  getVaccinationSummaryDetails,
  updatePhoneNumber,
  movePersonsBetweenGroups,
  createVaccinationCode,
  updateVaccinationCode,
  getFirstVaccinationCandidates,
  getSecondVaccinationCandidates,
  getBoosterVaccinationCandidates,
  getVaccinationCandidates,
  sendVaccinationCertificateEmail,
  getVaccinationRegistrationRequests,
  registerForVaccination,
  lookupVaccinationPersons2,
  updateVaccinationRegistrationRequest,
  getVaccinationRegistrationRequest,
  updateVaccinationRegistrationRequestStatus,
  getChildInfo,
  updateGuardianDecision,
  addGuardianToPerson,
  getAllGroupsByPersonId,
};
