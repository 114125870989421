import React, { useState, useEffect } from 'react';
import { MuiThemeProvider, useMediaQuery } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Routes from './Routes';
import theme from './theme';
import { UserContext } from './context/UserContext';
import { LangContext, ILanguage } from './context/LangContext';
import { PosContext } from './context/PosContext';
import { CefSharpShell } from './helpers/CefSharpShell';
import * as localStorageKeys from './constants/localStorageKeys';
import { SnackbarUtilsConfigurator } from './utils/SnackbarUtilsConfigurator';
import { ReactFlowProvider } from 'react-flow-renderer';

const App: React.FC = () => {
  const [userContext, setUserContext] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  if (
    userContext === null &&
    localStorage.getItem(localStorageKeys.USER) &&
    localStorage.getItem(localStorageKeys.USER) !== 'null'
  ) {
    const user = localStorage.getItem(localStorageKeys.USER) ?? '';
    const userMapped = JSON.parse(user);
    setUserContext(userMapped);
  }

  const [langContext, setLangContext] = useState<ILanguage>('is');
  const [posContext, setPosContext] = useState<boolean>(false);

  useEffect(() => {
    const language = localStorage.getItem(localStorageKeys.LANGUAGE);
    setLangContext(language === 'en' ? 'en' : 'is');
    new CefSharpShell().isPOSConfigured(setPosContext);
  }, []);

  return (
    <PosContext.Provider value={[posContext, setPosContext]}>
      <UserContext.Provider value={[userContext, setUserContext]}>
        <LangContext.Provider value={[langContext, setLangContext]}>
          <MuiThemeProvider theme={theme}>
            <ReactFlowProvider>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <SnackbarProvider
                  maxSnack={1}
                  autoHideDuration={2000}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  style={{
                    paddingTop: !isMobile ? theme.dimensions.navbarHeight : 0,
                  }}
                >
                  <SnackbarUtilsConfigurator />
                  <Routes />
                </SnackbarProvider>
              </MuiPickersUtilsProvider>
            </ReactFlowProvider>
          </MuiThemeProvider>
        </LangContext.Provider>
      </UserContext.Provider>
    </PosContext.Provider>
  );
};

export default App;
