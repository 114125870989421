import React, { useContext, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import EditButton from './EditButton';
import { Form, Formik, FormikProps } from 'formik';
import Button from './Button';
import theme from '../theme';

interface IProps {
  phoneNumber?: string;
  canEdit?: boolean;
  submitChanges: (newNumber: string) => Promise<void>;
}

export interface IFormValues {
  phoneNumber: string;
}

type IFormik = FormikProps<IFormValues>;

const EditPhoneNumber: React.FC<IProps> = ({
  phoneNumber,
  canEdit,
  submitChanges,
}) => {
  const [lang] = useContext(LangContext);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  return (
    <React.Fragment>
      <div
        onClick={() => setDialogOpen(true)}
        style={{
          display: 'inline-block',
          cursor: canEdit ? 'pointer' : 'text',
        }}
      >
        <Typography style={{ display: 'inline-block' }} color="textSecondary">
          {phoneNumber || translations.notRegistered[lang]}
        </Typography>
        {canEdit && (
          <div
            style={{
              display: 'inline-block',
              transform: 'translate(-2px, -2px)',
            }}
          >
            <EditButton onClick={() => setDialogOpen(true)} />
          </div>
        )}
      </div>
      {canEdit && renderDialogs()}
    </React.Fragment>
  );

  function renderDialogs() {
    return (
      <React.Fragment>
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <Formik
            onSubmit={(values) => onSubmit(values)}
            initialValues={getInitalValues()}
            enableReinitialize
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <DialogTitle>
                  {translations.changePhoneNumber[lang]}
                </DialogTitle>
                <DialogContent style={{ minHeight: 80 }} dividers>
                  {renderFormFields(formik)}
                </DialogContent>
                <DialogActions>
                  {loadingUpdate && (
                    <CircularProgress size={32} color="primary" />
                  )}
                  {renderButtons(formik)}
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </React.Fragment>
    );
  }

  function renderFormFields({ values, handleChange, handleBlur }: IFormik) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            autoFocus
            autoComplete="off"
            id="phoneNumber"
            label={translations.phoneNumber[lang]}
            variant="outlined"
            value={values.phoneNumber}
            inputProps={{
              type: 'text',
              pattern: '[0-9+]*',
            }}
            onChange={(e) => e.target.validity.valid && handleChange(e)}
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
    );
  }

  function renderButtons({ dirty }: IFormik) {
    return (
      <React.Fragment>
        <Button
          variant="contained"
          padding={false}
          type="submit"
          disabled={!dirty}
        >
          {translations.confirm[lang]}
        </Button>

        <Button
          onClick={() => setDialogOpen(false)}
          variant="outlined"
          padding={false}
          style={{ marginLeft: theme.spacing(1) }}
        >
          {translations.cancel[lang]}
        </Button>
      </React.Fragment>
    );
  }

  function getInitalValues() {
    return {
      phoneNumber: phoneNumber || '',
    };
  }

  async function onSubmit(values: IFormValues) {
    setLoadingUpdate(true);
    await submitChanges(values.phoneNumber);
    setDialogOpen(false);
    setLoadingUpdate(false);
  }
};

export default EditPhoneNumber;
