import React, { createContext, useReducer } from 'react';
import Reducer from './Reducer';
import { ConfirmQtFromAirlineKey } from './Constants';

let confirmQtFromAirline = false;
const confirmQtFromAirlineLocal = localStorage.getItem(ConfirmQtFromAirlineKey);
if (confirmQtFromAirlineLocal === 'true') {
  confirmQtFromAirline = true;
}

const initialState: any = {
  confirmQtFromAirline: confirmQtFromAirline,
};

const Store: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
