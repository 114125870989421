import moment, { Moment } from 'moment';
import { Passenger } from './Models';
import reviewCertificateStatuses from '../assets/json/CertificateReview/reviewCertificateStatuses.json';
import rejectReviewCertificateStatuses from '../assets/json/CertificateReview/rejectReviewCertificateStatuses.json';
import fileTypeStatuses from '../assets/json/CertificateReview/reviewFileTypeStatuses.json';
import { getFileTypeText } from '../utils';

export enum reviewStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
}

export class SearchReview {
  pageNumber: number = 1;
  pageSize: number = 50;
  searchText: string | undefined;
  reviewStatus: number[] | undefined;
  arrivalDateFrom: string | undefined;
  arrivalDateTo: string | undefined;
}

export class SearchReviewResult {
  pageSize: number;
  pageNumber: number;
  count: number;
  items: Array<PassengerReviewListItem>;

  constructor(i: any) {
    this.pageSize = i.pageSize ? i.pageSize : 0;
    this.pageNumber = i.pageNumber ? i.pageNumber : 1;
    this.count = i.count ? i.count : 0;
    this.items =
      i.items && i.items.length > 0
        ? i.items.map((item: any) => new PassengerReviewListItem(item))
        : [];
  }
}

export class PassengerReviewListItem {
  id: number | undefined;
  name: string | undefined;
  registerDate: string | undefined;
  arrivalDate: string | undefined;
  nationality: string | undefined;
  flightNumber: string | undefined;
  estimatedDateTime?: string;
  scheduledDateTime?: string;
  reviewStatus: ReviewStatus;

  constructor(i: any) {
    this.id = i.id;
    this.name = i.name;
    this.registerDate = i.registerDate;
    this.arrivalDate = i.arrivalDate;
    this.nationality = i.nationality;
    this.flightNumber = i.flightNumber;
    this.estimatedDateTime = i.estimatedDateTime;
    this.scheduledDateTime = i.scheduledDateTime;
    this.reviewStatus = new ReviewStatus(i.reviewStatus);
  }
}

export class PassengerReview {
  id: number | undefined;
  barcode: string | undefined;
  reviewStatus: ReviewStatus;
  rejectedStatus: RejectedStatus;
  rejectedComment: string | undefined;
  created: Moment | undefined;
  passenger: Passenger;
  emailsReceived: number;
  openByName: string | undefined;
  openBySsn: string | undefined;
  openedTime: string | undefined;

  constructor(i: any) {
    this.id = i.id;
    this.barcode = i.barCode;
    this.reviewStatus = new ReviewStatus(i.reviewStatus);
    this.rejectedStatus = new RejectedStatus(i.rejectedStatus);
    this.rejectedComment = i.rejectedComment;
    this.created = moment(i.created);
    this.passenger = new Passenger(i.passenger);
    this.emailsReceived = i.numberOfSendEmails || 0;
    this.openByName = i.openByName;
    this.openBySsn = i.openBySsn;
    this.openedTime = i.opened;
  }
}

export class PassengerFiles {
  id: number | undefined;
  barcode: number | undefined;
  isCertificateValid: boolean | undefined;
  certificateType: string | undefined;
  validationErrors: ValidationErrors[] | undefined;
  fileName: string | undefined;
  fileContentType: string | undefined;
  created: string | undefined;
  fileSize: number;
  fileStatus: number;
  fileType: FileTypeStatus;
  dateOfBirth: string | undefined;
  firstName: string;
  lastName: string;
  issueDate: string | undefined;

  constructor(i: any) {
    this.id = i.id;
    this.barcode = i.barcode;
    this.isCertificateValid = i.isCertificateValid;
    this.certificateType = i.certificateType;
    this.validationErrors = i?.validationErrors
      ? i.validationErrors.map((error: any) => new ValidationErrors(error))
      : undefined;
    this.fileName = i.fileName;
    this.fileContentType =
      i?.fileContentType && getFileTypeText(i.fileContentType);
    this.created = i.created;
    this.fileSize = i.sizeMB || 0;
    this.fileStatus = i.fileStatus || 0;
    this.fileType = new FileTypeStatus(i.fileType);
    this.dateOfBirth = i.certificateDateOfBirth;
    this.firstName = i.certificateFirstName || '';
    this.lastName = i.certificateLastName || '';
    this.issueDate = i.certificateIssueAt;
  }
}

export class ReviewStatus {
  id: number;
  description: { is: string; en: string };

  constructor(status: number) {
    this.id = status || 0;
    const reviewStatus = reviewCertificateStatuses.find(
      (s) => s.id === this.id
    );
    this.description = reviewStatus?.description || { is: '', en: '' };
  }
}

export class RejectedStatus {
  id: number;
  description: { is: string; en: string };

  constructor(status: number) {
    this.id = status || 0;
    const rejectStatus = rejectReviewCertificateStatuses.find(
      (s) => s.id === this.id
    );
    this.description = rejectStatus?.description || { is: '', en: '' };
  }
}

export class FileTypeStatus {
  id: number;
  description: { is: string; en: string };

  constructor(status: number) {
    this.id = status || 0;
    const fileStatus = fileTypeStatuses.find((s) => s.id === this.id);
    this.description = fileStatus?.description || { is: '', en: '' };
  }
}

export class NextReviewId {
  id?: number;

  constructor(item: any) {
    this.id = item?.nextRewiewId || undefined;
  }
}

export class ValidationErrors {
  severity: number;
  description: string;

  constructor(error: any) {
    this.severity = error.severity || 0;
    this.description = error.message || '';
  }
}
