import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Grid, useTheme } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Button from '../components/Button';
import ConfirmDialog from '../components/ConfirmDialog';
import useNotifier from '../hooks/useNotifier';
import LoadingIndicator from '../components/LoadingIndicator';
import VaccinationGroupMemberList from '../components/VaccinationGroupMemberList';
import VaccinationGroupMemberFilter from '../components/VaccinationGroupMemberFilter';
import VaccinationGroupMemberDetails from '../components/VaccinationGroupMemberDetails';
import TransferDialog from '../components/TransferDialog';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import {
  VaccinationLocation,
  VaccinationPerson,
  Vaccine,
} from '../models/VaccinationModels';
import { TABLE_ROWS_DEFAULT } from '../constants';
import * as vaccinationService from '../services/vaccinationService';
import { IVaccinationGroupDetails } from '../interfaces/vaccinationGroup';
import { IVaccinationCommentStatus } from '../interfaces/vaccinationComment';
import { parseTemplateString } from '../utils';
import SendVaccinationGroupScheduleDialog from '../components/SendVaccinationGroupScheduleDialog';
import VaccinationGroupSummoningInfoDialog from '../components/VaccinationGroupSummoningInfoDialog';
import * as localStorageKeys from '../constants/localStorageKeys';
import { typeOfVaccination, VaccineType } from '../constants/enums';

const VaccinationGroupDetailsPage: React.FC<
  RouteComponentProps<{ groupId: string }>
> = ({ match }) => {
  const history = useHistory();
  const theme = useTheme();
  const [lang] = useContext(LangContext);
  const { notifyError, notifySuccess } = useNotifier();

  const [group, setGroup] = useState<IVaccinationGroupDetails | null>(null);
  const [loadingGroup, setLoadingGroup] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [filteredMembers, setFilteredMembers] = useState<VaccinationPerson[]>(
    []
  );
  const [sortedMembers, setSortedMembers] = useState<VaccinationPerson[]>([]);
  const [selectedMember, setSelectedMember] =
    useState<VaccinationPerson | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [viewSummoningDialog, setViewSummoningDialog] = useState(false);
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [checkedGroupMembers, setCheckedGroupMembers] = useState<
    VaccinationPerson[] | null
  >(null);
  const [vaccines, setVaccines] = useState<Vaccine[] | null>(null);
  const [locations, setLocations] = useState<VaccinationLocation[] | null>(
    null
  );
  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine | null>(null);
  const [selectedLocation, setSelectedLocation] =
    useState<VaccinationLocation | null>(null);
  const [selectedInfluenzaVaccine, setSelectedInfluenzaVaccine] =
    useState<Vaccine | null>(null);
  const [influenzaVaccines, setInfluenzaVaccines] = useState<Vaccine[] | null>(
    null
  );
  const [selectedTypeOfVaccination, setSelectedTypeOfVaccination] =
    useState('');
  const [commentStatusList, setCommentStatusList] = useState<
    IVaccinationCommentStatus[] | null
  >(null);
  const [membersCheckbox, setMembersCheckbox] = useState<string[]>([]);

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  useEffect(() => {
    const fetchGroup = async () => {
      if (match.params.groupId) {
        const response = await vaccinationService.getPersonsInGroup(
          match.params.groupId
        );
        if (response.isOk) {
          setGroup(response.data);
          setLoadingGroup(false);
        } else if (response.statusCode === 401) {
          history.push('/login');
        }
        setLoadingGroup(false);
      } else {
        setLoadingGroup(false);
      }
    };
    fetchGroup();
  }, [match.params.groupId, history]);

  useEffect(() => {
    setCurrentPage(0);
  }, [filteredMembers]);

  useEffect(() => {
    const fetchSettings = async () => {
      const vaccinePromise = vaccinationService.getVaccines();
      const locationPromise = vaccinationService.getLocations();
      const [vaccineResponse, locationResponse] = await Promise.all([
        vaccinePromise,
        locationPromise,
      ]);

      if (vaccineResponse.isOk) {
        const covidVaccines = vaccineResponse.data.filter(
          (item) => item.vaccineType === VaccineType.COVID_19
        );
        const newInfluenzaVaccines = vaccineResponse.data.filter(
          (item) => item.vaccineType === VaccineType.INFLUENZA
        );

        setVaccines(covidVaccines);
        setInfluenzaVaccines(newInfluenzaVaccines);

        const savedVaccineId = localStorage.getItem(
          localStorageKeys.VACCINE_ID
        );
        const savedVaccine = covidVaccines.find(
          (v) => v.id.toString() === savedVaccineId
        );
        if (savedVaccine) {
          setSelectedVaccine(savedVaccine);
        } else if (covidVaccines.length === 1) {
          setSelectedVaccine(covidVaccines[0]);
        }

        const savedInfluenzaVaccineId = localStorage.getItem(
          localStorageKeys.INFLUENZA_VACCINE_ID
        );
        const savedInfluenzaVaccine = newInfluenzaVaccines.find(
          (v) => v.id.toString() === savedInfluenzaVaccineId
        );

        if (savedInfluenzaVaccine) {
          setSelectedInfluenzaVaccine(savedInfluenzaVaccine);
        }
      } else {
        setVaccines([]);
      }

      if (locationResponse.isOk) {
        setLocations(locationResponse.data);
        const savedLocationId = localStorage.getItem(
          localStorageKeys.VACCINATION_LOCATION_ID
        );
        const savedLocation = locationResponse.data.find(
          (l) => l.id.toString() === savedLocationId
        );
        if (savedLocation) {
          setSelectedLocation(savedLocation);
        } else if (locationResponse.data.length === 1) {
          setSelectedLocation(locationResponse.data[0]);
        }
      } else {
        setLocations([]);
      }

      const savedVaccinationType = localStorage.getItem(
        localStorageKeys.TYPE_OF_VACCINATION
      );
      if (
        savedVaccinationType === typeOfVaccination.COVID_19 ||
        savedVaccinationType === typeOfVaccination.INFLUENZA ||
        savedVaccinationType === typeOfVaccination.COVID_19_AND_INFLUENZA
      ) {
        setSelectedTypeOfVaccination(savedVaccinationType);
      }
    };
    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchCommentStatusList = async () => {
      const { data: statusList } =
        await vaccinationService.getVaccinationCommentStatusList();
      setCommentStatusList(statusList);
    };
    fetchCommentStatusList();
  }, []);

  return (
    <Layout
      useDefaultSpacing
      title={getTitle()}
      subtitles={
        group
          ? [
              group.description,
              `${translations.members[lang]}: ${group.members.length}`,
            ]
          : undefined
      }
      otherButton={{
        label: translations.backToGroups[lang],
        onClick: () => history.push('/groups'),
      }}
    >
      {loadingGroup ? (
        <Grid container justify="center" alignItems="center">
          <LoadingIndicator />
        </Grid>
      ) : (
        <Section>
          {selectedMember && (
            <VaccinationGroupMemberDetails
              hideSettings
              member={selectedMember}
              groupId={group?.id}
              commentStatusList={commentStatusList}
              hasVaccinationAccess={false}
              back={() => setSelectedMember(null)}
              reloadGroup={reloadGroup}
              vaccinationSettings={{
                vaccines,
                influenzaVaccines,
                locations,
                selectedVaccine,
                selectedLocation,
                selectedInfluenzaVaccine,
                selectedTypeOfVaccination,
                setSelectedVaccine,
                setSelectedLocation,
                setSelectedInfluenzaVaccine,
                setSelectedTypeOfVaccination,
              }}
            />
          )}
          {group && (
            <div style={{ display: selectedMember ? 'none' : undefined }}>
              {renderButtons(group)}
              <VaccinationGroupMemberFilter
                members={group.members}
                filterMembers={setFilteredMembers}
                selectRows={selectRows}
              />
              <VaccinationGroupMemberList
                members={filteredMembers}
                group={group}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
                onClick={setSelectedMember}
                onChangeSelectedMembers={onChangeSelectedMembers}
                onSetSortedMembers={setSortedMembers}
                membersCheckbox={membersCheckbox}
                setMembersCheckbox={setMembersCheckbox}
              />
            </div>
          )}
          {renderDialogs()}
        </Section>
      )}
    </Layout>
  );

  function getTitle() {
    if (loadingGroup) {
      return undefined;
    }
    return group ? group.name : translations.groupNotFound[lang];
  }

  function renderButtons(groupDetails: IVaccinationGroupDetails) {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}
        style={{ marginBottom: theme.spacing(2) }}
      >
        <Grid item>
          {checkedGroupMembers && group ? (
            <Button padding={false} onClick={() => setTransferDialogOpen(true)}>
              {parseTemplateString(
                translations.transferPartial[lang],
                checkedGroupMembers.length.toString(),
                group.members.length.toString()
              )}
            </Button>
          ) : (
            <Button
              padding={false}
              onClick={() => history.push(`/groups/${groupDetails.id}/edit`)}
            >
              {translations.editGroup[lang]}
            </Button>
          )}
          {checkedGroupMembers && group ? (
            <Button
              error
              padding={false}
              style={{ marginLeft: theme.spacing(2) }}
              onClick={() => setRemoveDialogOpen(true)}
            >
              {parseTemplateString(
                translations.removeFromGroupPartial[lang],
                checkedGroupMembers.length.toString(),
                group.members.length.toString()
              )}
            </Button>
          ) : (
            <Button
              error
              padding={false}
              style={{ marginLeft: theme.spacing(2) }}
              onClick={() => setDeleteDialogOpen(true)}
            >
              {translations.deleteGroup[lang]}
            </Button>
          )}
        </Grid>
        <Grid item>
          <Button
            padding={false}
            color="secondary"
            onClick={() => setScheduleDialogOpen(true)}
          >
            {checkedGroupMembers && group
              ? parseTemplateString(
                  translations.summonPartialGroupForVaccination[lang],
                  checkedGroupMembers.length.toString(),
                  group.members.length.toString()
                )
              : translations.summonGroupForVaccination[lang]}
          </Button>
          {group?.summonGroupDate && (
            <Button
              onClick={() => setViewSummoningDialog(true)}
              padding={false}
              style={{ marginLeft: theme.spacing(2) }}
            >
              {translations.viewSummoning[lang]}
              <ScheduleIcon style={{ marginLeft: theme.spacing(1) }} />
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }

  async function deleteGroup() {
    if (group) {
      setLoadingDelete(true);
      const response = await vaccinationService.deleteGroup(group.id);
      setLoadingDelete(false);

      if (response.isOk) {
        notifySuccess(translations.groupDeleted[lang]);
        history.push('/groups');
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    }
  }

  async function removeFromGroup() {
    if (group && checkedGroupMembers) {
      setLoadingRemove(true);
      const response = await vaccinationService.removePersonsFromGroup(
        group.id,
        checkedGroupMembers.map((m) => m.ssn)
      );

      if (response.isOk) {
        await onSuccess();
        notifySuccess(translations.removedFromGroup[lang]);
        setRemoveDialogOpen(false);
        setLoadingRemove(false);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
        setLoadingRemove(false);
      }
    }
  }

  async function transferMembers(toGroupId: string, copy: boolean) {
    if (group && checkedGroupMembers) {
      setLoadingTransfer(true);
      const response = await vaccinationService.movePersonsBetweenGroups(
        group.id,
        toGroupId,
        checkedGroupMembers.map((m) => m.ssn),
        copy
      );

      if (response.isOk) {
        await onSuccess();
        notifySuccess(translations.transferred[lang]);
        setTransferDialogOpen(false);
        setLoadingTransfer(false);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
        setLoadingTransfer(false);
      }
    }
  }

  function renderDialogs() {
    return (
      <React.Fragment>
        <ConfirmDialog
          error
          open={deleteDialogOpen}
          loading={loadingDelete}
          title={translations.deleteGroup[lang]}
          text={translations.deleteGroupDialog[lang]}
          onConfirm={deleteGroup}
          onCancel={() => setDeleteDialogOpen(false)}
        />
        <ConfirmDialog
          error
          open={removeDialogOpen}
          loading={loadingRemove}
          title={translations.removeFromGroup[lang]}
          text={
            checkedGroupMembers && group
              ? parseTemplateString(
                  translations.removeFromGroupDialogText[lang],
                  checkedGroupMembers.length.toString(),
                  group.members.length.toString()
                )
              : ''
          }
          onConfirm={removeFromGroup}
          onCancel={() => setRemoveDialogOpen(false)}
        />
        <SendVaccinationGroupScheduleDialog
          open={scheduleDialogOpen}
          onCancel={() => setScheduleDialogOpen(false)}
          onSuccess={() => onSuccess()}
          group={group}
          groupMembers={group?.members || null}
          selectedMembers={checkedGroupMembers}
          loactions={locations}
        ></SendVaccinationGroupScheduleDialog>
        <VaccinationGroupSummoningInfoDialog
          open={viewSummoningDialog}
          onCancel={() => setViewSummoningDialog(false)}
          group={group}
        ></VaccinationGroupSummoningInfoDialog>
        <TransferDialog
          open={transferDialogOpen}
          loading={loadingTransfer}
          groupId={group ? group.id : ''}
          groupCount={group ? group.members.length : 0}
          checkedCount={checkedGroupMembers ? checkedGroupMembers.length : 0}
          transferMembers={transferMembers}
          onCancel={() => setTransferDialogOpen(false)}
        />
      </React.Fragment>
    );
  }

  async function onSuccess() {
    setLoadingGroup(true);
    if (match.params.groupId) {
      const response = await vaccinationService.getPersonsInGroup(
        match.params.groupId
      );
      if (response.isOk) {
        setGroup(response.data);
        setMembersCheckbox([]);
      } else if (response.statusCode === 401) {
        history.push('/login');
      }
      setLoadingGroup(false);
    } else {
      setLoadingGroup(false);
    }
  }

  function onChangeSelectedMembers(memberlist: VaccinationPerson[] | null) {
    setCheckedGroupMembers(memberlist);
  }

  async function reloadGroup() {
    if (match.params.groupId) {
      const response = await vaccinationService.getPersonsInGroup(
        match.params.groupId
      );
      if (response.isOk) {
        setGroup(response.data);
        if (selectedMember && response.data) {
          const updatedMember = response.data.members.find(
            (m) => m.serialNumber === selectedMember.serialNumber
          );
          setSelectedMember(updatedMember || null);
        }
      } else if (response.statusCode === 401) {
        history.push('/login');
      }
    }
  }

  function selectRows(numberOfRows: number) {
    const selected: VaccinationPerson[] = [];
    for (let row = 0; row < numberOfRows; row++) {
      selected.push(sortedMembers[row]);
    }
    setCheckedGroupMembers(selected);
    setMembersCheckbox(selected.map((s) => s.ssn));
  }
};

export default VaccinationGroupDetailsPage;
