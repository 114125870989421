import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
  Checkbox,
  Typography,
  Grid,
} from '@material-ui/core';
import moment from 'moment';
import WarningIcon from '@material-ui/icons/ErrorOutline';

import NoResults from './NoResults';
import { VaccinationRegistrationRequest } from '../models/VaccinationModels';
import { TABLE_ROWS_OPTIONS, DATE_FORMAT } from '../constants';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

interface IProps {
  list: VaccinationRegistrationRequest[];
  currentPage: number;
  rowsPerPage: number;
  regionSelected: boolean;
  setCurrentPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
  onClick?: (item: VaccinationRegistrationRequest) => void;
  onChangeSelectedMembers?: (
    memberList: VaccinationRegistrationRequest[] | null
  ) => void;
  membersCheckbox: number[];
  setMembersCheckbox?: (members: number[]) => void;
  onSetSortedMembers?: (memberList: VaccinationRegistrationRequest[]) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  warningIcon: {
    color: theme.palette.secondary.main,
    height: 18,
    marginTop: 3,
  },
}));

const tableColumns: {
  [key: string]: {
    translation: { is: string; en: string };
    sort?: (
      a: VaccinationRegistrationRequest,
      b: VaccinationRegistrationRequest
    ) => number;
  };
} = {
  personName: {
    translation: translations.name,
    sort: (
      a: VaccinationRegistrationRequest,
      b: VaccinationRegistrationRequest
    ) => a.personName.localeCompare(b.personName),
  },
  created: {
    translation: translations.created,
    sort: (
      a: VaccinationRegistrationRequest,
      b: VaccinationRegistrationRequest
    ) => {
      if (!a.created) {
        return -1;
      }
      if (!b.created) {
        return 1;
      }
      return moment(a.created).isAfter(b.created) ? 1 : -1;
    },
  },
  previousVaccinationsCount: {
    translation: translations.nrOfVaccine,
    sort: (
      a: VaccinationRegistrationRequest,
      b: VaccinationRegistrationRequest
    ) => a.previousVaccinationsCount - b.previousVaccinationsCount,
  },
  isPregnant: {
    translation: translations.pregnant,
    sort: (
      a: VaccinationRegistrationRequest,
      b: VaccinationRegistrationRequest
    ) => (a.isPregnant ? 1 : -1),
  },
  hasAnAccuteAllergy: {
    translation: translations.accuteAllergy,
    sort: (
      a: VaccinationRegistrationRequest,
      b: VaccinationRegistrationRequest
    ) => (a.hasAnAccuteAllergy ? 1 : -1),
  },
  isUsingImmonusuppresiveMedication: {
    translation: translations.isUsingImmonusuppresiveMedication,
    sort: (
      a: VaccinationRegistrationRequest,
      b: VaccinationRegistrationRequest
    ) => (a.isUsingImmonusuppresiveMedication ? 1 : -1),
  },
  dateOfCovidDiagnosis: {
    translation: translations.dateOfCovidDiagnosis,
    sort: (
      a: VaccinationRegistrationRequest,
      b: VaccinationRegistrationRequest
    )  => {
      if (!a.dateOfCovidDiagnosis) {
        return -1;
      }
      if (!b.dateOfCovidDiagnosis) {
        return 1;
      }
      return moment(a.dateOfCovidDiagnosis).isAfter(b.dateOfCovidDiagnosis) ? 1 : -1;
    },
    
  },
  region: {
    translation: translations.region,
    sort: (
      a: VaccinationRegistrationRequest,
      b: VaccinationRegistrationRequest
    ) => a.vaccinationRegion.name.localeCompare(b.vaccinationRegion.name),
  },
  location: {
    translation: translations.location,
    sort: (
      a: VaccinationRegistrationRequest,
      b: VaccinationRegistrationRequest
    ) => a.vaccinationLocation.name.localeCompare(b.vaccinationLocation.name),
  },
};

const VaccinationWatingList: React.FC<IProps> = ({
  list,
  currentPage,
  rowsPerPage,
  regionSelected,
  setCurrentPage,
  setRowsPerPage,
  onClick,
  onChangeSelectedMembers,
  membersCheckbox,
  setMembersCheckbox,
  onSetSortedMembers,
}) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);

  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortedList, setSortedMembers] = useState<
    VaccinationRegistrationRequest[]
  >([]);
  const startIndex = currentPage * rowsPerPage;

  useEffect(() => {
    if (sortKey && tableColumns[sortKey]) {
      const sorted = tableColumns[sortKey].sort
        ? [...list].sort(tableColumns[sortKey].sort)
        : [...list];
      setSortedMembers(sortDirection === 'asc' ? sorted : sorted.reverse());
    } else {
      setSortedMembers(list);
    }
  }, [list, sortKey, sortDirection]);

  useEffect(() => {
    if (membersCheckbox.length > 0 && onChangeSelectedMembers) {
      const sortedCheckedMembers = sortedList.filter((m) =>
        membersCheckbox.includes(m.id)
      );
      onChangeSelectedMembers(sortedCheckedMembers);
    } else {
      onChangeSelectedMembers && onChangeSelectedMembers(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedList, membersCheckbox]);

  useEffect(() => {
    if (onSetSortedMembers) {
      onSetSortedMembers(sortedList);
    }
  }, [sortedList, onSetSortedMembers]);

  return (
    <Paper elevation={2} className={classes.container}>
      {list.length ? (
        <React.Fragment>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {onChangeSelectedMembers && (
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={membersCheckbox.length > 0}
                        onChange={() => handleMainCheckboxChange()}
                      />
                    </TableCell>
                  )}
                  {Object.keys(tableColumns)
                    .filter(
                      (key) =>
                        (key !== 'region' && regionSelected) ||
                        (key !== 'location' && !regionSelected)
                    )
                    .map((columnKey) => (
                      <TableCell key={columnKey}>
                        {tableColumns[columnKey].sort ? (
                          <TableSortLabel
                            active={sortKey === columnKey}
                            direction={sortDirection}
                            onClick={() => onSortClick(columnKey)}
                          >
                            {tableColumns[columnKey].translation[lang]}
                          </TableSortLabel>
                        ) : (
                          tableColumns[columnKey].translation[lang]
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedList
                  .slice(startIndex, startIndex + rowsPerPage)
                  .map((item) => (
                    <TableRow
                      hover={!!onClick}
                      key={item.id}
                      style={{ cursor: onClick ? 'pointer' : undefined }}
                    >
                      {onChangeSelectedMembers && (
                        <TableCell>
                          <Checkbox
                            color="primary"
                            checked={membersCheckbox.includes(item.id)}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                        </TableCell>
                      )}
                      <TableCell onClick={() => onClick && onClick(item)}>
                        {item.personName}
                      </TableCell>
                      <TableCell onClick={() => onClick && onClick(item)}>
                        {item.created
                          ? moment(item.created).format(DATE_FORMAT)
                          : ''}
                      </TableCell>
                      <TableCell onClick={() => onClick && onClick(item)}>
                        {
                          <Grid container alignItems="center">
                            <Grid item>
                              <Typography>
                                {item.previousVaccinationsCount}
                              </Typography>
                            </Grid>
                            {item.vaccinationCountMismatch && (
                              <Grid item>
                                <WarningIcon className={classes.warningIcon} />
                              </Grid>
                            )}
                          </Grid>
                        }
                      </TableCell>
                      <TableCell onClick={() => onClick && onClick(item)}>
                        {item.isPregnant
                          ? translations.yes[lang]
                          : translations.no[lang]}
                      </TableCell>
                      <TableCell onClick={() => onClick && onClick(item)}>
                        {item.hasAnAccuteAllergy
                          ? translations.yes[lang]
                          : translations.no[lang]}
                      </TableCell>
                      <TableCell onClick={() => onClick && onClick(item)}>
                        {item.isUsingImmonusuppresiveMedication
                          ? translations.yes[lang]
                          : translations.no[lang]}
                      </TableCell>
                      <TableCell onClick={() => onClick && onClick(item)}>
                        {item.dateOfCovidDiagnosis
                          ? moment(item.dateOfCovidDiagnosis).format(DATE_FORMAT)
                          : ''
                        }
                      </TableCell>
                      {!regionSelected && (
                        <TableCell onClick={() => onClick && onClick(item)}>
                          {item.vaccinationRegion.name}
                        </TableCell>
                      )}
                      {regionSelected && (
                        <TableCell onClick={() => onClick && onClick(item)}>
                          {item.vaccinationLocation.name}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={translations.numberOfRowsPerPage[lang]}
            rowsPerPageOptions={TABLE_ROWS_OPTIONS}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={(_, p) => setCurrentPage(p)}
            onChangeRowsPerPage={(e) => {
              setCurrentPage(0);
              setRowsPerPage(parseInt(e.target.value, 10));
            }}
          />
        </React.Fragment>
      ) : (
        <NoResults message={translations.noVaccinationPersonFound[lang]} />
      )}
    </Paper>
  );

  function onSortClick(columnKey: string) {
    if (sortKey === columnKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(columnKey);
      setSortDirection('asc');
    }
  }

  function handleMainCheckboxChange() {
    if (setMembersCheckbox) {
      if (membersCheckbox.length > 0) {
        setMembersCheckbox([]);
      } else {
        setMembersCheckbox([...sortedList].map((m) => m.id));
      }
    }
  }

  function handleCheckboxChange(id: number) {
    if (setMembersCheckbox) {
      if (membersCheckbox.includes(id)) {
        setMembersCheckbox([...membersCheckbox].filter((m) => m !== id));
      } else {
        setMembersCheckbox([...membersCheckbox, id]);
      }
    }
  }
};

export default VaccinationWatingList;
