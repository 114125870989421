import React, { useContext } from 'react';
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import NoResults from '../NoResults';
import { Passenger } from '../../models/Models';
import { DATE_FORMAT, TABLE_ROWS_OPTIONS, TIME_FORMAT } from '../../constants';
import { LangContext } from '../../context/LangContext';
import translations from '../../assets/json/translations.json';
import { PassengerReviewListItem } from '../../models/CertificateReviewModels';

interface IProps {
  passengers: PassengerReviewListItem[];
  currentPage: number;
  rowsPerPage: number;
  count: number;
  setCurrentPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
  onClick?: (passenger: Passenger) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
}));

const ReviewCertificateList: React.FC<IProps> = ({
  passengers,
  currentPage,
  rowsPerPage,
  count,
  setCurrentPage,
  setRowsPerPage,
  onClick,
}) => {
  const classes = useStyles({});
  const [lang] = useContext(LangContext);
  const history = useHistory();

  return (
    <Paper elevation={2} className={classes.container}>
      {passengers.length ? (
        <React.Fragment>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{translations.name[lang]}</TableCell>
                  <TableCell>{translations.status[lang]}</TableCell>
                  <TableCell>{translations.flightNumber[lang]}</TableCell>
                  <TableCell>{translations.dateOfRegistration[lang]}</TableCell>
                  <TableCell>{translations.arrivalDate[lang]}</TableCell>
                  <TableCell>
                    {translations.estimatedArrivalTime[lang]}
                  </TableCell>
                  <TableCell>{translations.nationality[lang]}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {passengers.map((passenger) => (
                  <TableRow
                    hover
                    key={passenger.id}
                    style={{ cursor: onClick ? 'pointer' : undefined }}
                    onClick={() =>
                      history.push(`/certificate-review/${passenger.id}`)
                    }
                  >
                    <TableCell>{passenger.name}</TableCell>
                    <TableCell>
                      {passenger.reviewStatus.description[lang]}
                    </TableCell>
                    <TableCell>{passenger.flightNumber}</TableCell>
                    <TableCell>
                      {passenger.registerDate &&
                        moment(passenger.registerDate).format(DATE_FORMAT)}
                    </TableCell>
                    <TableCell>
                      {passenger.arrivalDate &&
                        moment(passenger.arrivalDate).format(DATE_FORMAT)}
                    </TableCell>
                    <TableCell>
                      {(passenger.estimatedDateTime &&
                        moment(passenger.estimatedDateTime).format(
                          TIME_FORMAT
                        )) ||
                        (passenger.scheduledDateTime &&
                          moment(passenger.scheduledDateTime).format(
                            TIME_FORMAT
                          ))}
                    </TableCell>
                    <TableCell>{passenger.nationality}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={translations.numberOfRowsPerPage[lang]}
            rowsPerPageOptions={TABLE_ROWS_OPTIONS}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={(_, p) => setCurrentPage(p)}
            onChangeRowsPerPage={(e) => {
              setCurrentPage(0);
              setRowsPerPage(parseInt(e.target.value, 10));
            }}
          />
        </React.Fragment>
      ) : (
        <NoResults message={translations.noPassengersFound[lang]} />
      )}
    </Paper>
  );
};

export default ReviewCertificateList;
