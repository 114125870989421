import moment, { Moment } from 'moment';

import { Passenger, User, AccessItem, Location } from './models/Models';
import { Vaccination, Vaccine } from './models/VaccinationModels';
import {
  accessControl,
  actionStatuses,
  actionStatusesCompleted,
  borderControlStatus,
  countryStatuses,
  qrCodeErrorSeverity,
  vaccinesAtc,
} from './constants/enums';
import { LOCATION_OPTIONS } from './constants';
import symptomSamplingExclude from './assets/json/symptomSamplingExclude.json';
import departureBarcodeExclude from './assets/json/departureBarcodeExclude.json';
import * as httpService from './services/httpService';
import * as localStorageKeys from './constants/localStorageKeys';
import { RiskCountryStatus } from './interfaces/riskCountry';
import theme from './theme';
import { SnackbarKey } from 'notistack';
import { ILanguage } from './context/LangContext';
import translations from './assets/json/translations.json';
import { PassengerFiles } from './models/CertificateReviewModels';

export function isReadyForSampling(passenger: Passenger | null) {
  if (passenger) {
    const id = passenger.borderControlStatus.id;
    if (
      id === borderControlStatus.REGISTERED_FOR_SAMPLING ||
      id === borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_COMPLETED ||
      id === borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_NOT_REQUIRED ||
      id === borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_NEEDED
    ) {
      return true;
    }
  }
  return false;
}

export function certicateAccepted(passenger: Passenger | null) {
  if (passenger) {
    const id = passenger.borderControlStatus.id;
    if (
      id === borderControlStatus.NEGATIVE_TRANSMISSION_CERTIFICATE ||
      id === borderControlStatus.ANTIBODY_ASSAY_CERTIFICATE ||
      id === borderControlStatus.VERIFIED_OTHER_CERTIFICATES_DOCUMENTS ||
      id === borderControlStatus.VERIFIED_VACCINATION_CERTIFICATE
    ) {
      return true;
    }
  }
  return false;
}

export function isCheckoutReady(passenger: Passenger | null) {
  if (passenger && passenger.flowStatusesActive) {
    const id = passenger.flowStatusesActive.id;
    if (
      id === actionStatuses.CONFIRM_REGISTRATION ||
      id === actionStatuses.CONFIRM_REGISTRATION_QUARANTINE ||
      id === actionStatuses.CONFIRM_REGISTRATION_SAMPLING ||
      id === actionStatuses.CONFIRM_REGISTRATION_CERTIFICATE ||
      id === actionStatuses.CONFIRM_REGISTRATION_TRANSIT ||
      id === actionStatuses.CHECKOUT_CERTIFICATE_AND_SAMPLE ||
      id === actionStatuses.CHECKOUT_CERTIFICATE_AND_SAMPLE_ELSEWHERE ||
      id === actionStatuses.CHECKOUT_CHILD_AND_SAMPLE ||
      id === actionStatuses.CHECKOUT_CHILD_AND_SAMPLE_ELSEWHERE ||
      id === actionStatuses.CHECKOUT_CHILD_NO_ACTION ||
      id === actionStatuses.CHECKOUT_CERTIFICATE_WITH_PCR_POSITIVE ||
      id === actionStatuses.CHECKOUT_ELECTRONIC_ID_PCR_POSITIVE_CERTIFICATE ||
      id === actionStatuses.CHECKOUT_ELECTRONIC_ID_VACCINATION_CERTIFICATE ||
      id ===
      actionStatuses.CHECKOUT_ELECTRONIC_ID_VACCINATION_CERTIFICATE_AND_SAMPLE ||
      id ===
      actionStatuses.CHECKOUT_ELECTRONIC_ID_VACCINATION_CERTIFICATE_AND_SAMPLE_ELSEWHERE ||
      id ===
      actionStatuses.CHECKOUT_ELECTRONIC_ID_POSITIVE_CERTIFICATE_AND_SAMPLE_ELSEWHERE ||
      id ===
      actionStatuses.CHECKOUT_ELECTRONIC_ID_POSITIVE_CERTIFICATE_AND_SAMPLE_TAKEN ||
      id === actionStatuses.CHECKOUT_PRE_BORDER_CERTIFICATE ||
      id ===
      actionStatuses.CHECKOUT_PRE_BORDER_CERTIFICATE_AND_SAMPLING_ELSEWHERE ||
      id === actionStatuses.CHECKOUT_PRE_BORDER_CERTIFICATE_AND_SAMPLE_TAKEN ||
      id ===
      actionStatuses.CHECKOUT_PRE_BORDER_CERTIFICATE_PCR_POSITIVE_CERTIFICATE
    ) {
      return true;
    }
  }
  return false;
}

export function isSamplingReadyFlowStatus(passenger: Passenger | null) {
  if (passenger && passenger.flowStatusesActive) {
    const id = passenger.flowStatusesActive.id;
    if (
      id === actionStatuses.FINISH_SAMPLING ||
      id === actionStatuses.TAKE_SAMPLE_WITH_VALID_CERTIFICATE ||
      id === actionStatuses.TAKE_SAMPLE_WITH_CHILD ||
      id === actionStatuses.TAKE_PCR_SAMPLE_NO_PCR_NEGATIVE_CERTIFICATE ||
      id === actionStatuses.TAKEN_SAMPLE_WITH_VALID_ELECTRONIC_ID_CERTIFICATE ||
      id === actionStatuses.INVALID_ELECTRONIC_ID_CERTIFICATE_TAKE_SAMPLE ||
      id ===
      actionStatuses.TAKE_SAMPLE_WITH_VALID_ELECTRONIC_ID_POSITIVE_CERTIFICATE ||
      id === actionStatuses.TAKE_SAMPLE_PRE_BORDER_CERTIFICATE
    ) {
      return true;
    }
  }
  return false;
}

export function showSymptomSamplingNumber(passenger: Passenger | null) {
  if (passenger) {
    const id = passenger.borderControlStatus.id;
    if (
      id === borderControlStatus.SAMPLING_COMPLETED ||
      id === borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_NOT_REQUIRED
    ) {
      return true;
    }
  }
  return false;
}

export function disableSymptomSamplingNumber(passenger: Passenger | null) {
  if (passenger) {
    const id = passenger.borderControlStatus.id;
    if (id === borderControlStatus.SAMPLING_COMPLETED) {
      return true;
    }
  }
  return false;
}

export const hasUserAccess = (id: number, user: User) => {
  const item = user?.accessList?.find((item: AccessItem) => {
    return item.id === id;
  });
  return item !== undefined;
};

export function sleep(time: number = 1000) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function parseTemplateString(str: string, ...args: string[]) {
  args.forEach((arg) => (str = str.replace(/\$\{.*?\}/, arg)));
  return str.replace(/\$\{.*?\}/g, '');
}

export function getLocationLabel(locationKey: string, locations: Location[]) {
  const result = locations.find((l) => l.key === locationKey);
  if (result) {
    return result.label;
  }
  return null;
}

export function showResidenceInfo(testType: string) {
  return (
    !!testType &&
    testType !==
    borderControlStatus.REGISTERED_FOR_SYMPTOM_SAMPLING.toString() &&
    testType !== borderControlStatus.REGISTERED_FOR_RAPID_TEST.toString()
  );
}

export function hasSymptomSamplingAccess(user: User) {
  return (
    user && user.location && !symptomSamplingExclude.includes(user.location)
  );
}

export function hasRapidTestAccess(user: User) {
  return user && user.location && user.isRapidTestLocation;
}

export function hasAllowSampleTypeConversion(user: User) {
  return user && user.location && user.allowSampleTypeConversion;
}

export function isDepartureExcluded(user: User) {
  return (
    user && user.location && departureBarcodeExclude.includes(user.location)
  );
}

export function isNotPartialCertificateLocation(user: User) {
  return user && user.location === 'SEY';
}

export function isRapidBarcode(barCode: string) {
  return (
    barCode?.toLowerCase().substring(0, 3) === 'ant' ||
    barCode?.toLowerCase().substring(0, 3) === 'ain' ||
    barCode?.toLowerCase().substring(0, 3) === 'asp' ||
    barCode?.toLowerCase().substring(0, 3) === 'and'
  );
}

export function isRapidTest(samlingNumber: string) {
  return samlingNumber?.toLowerCase().substring(0, 1) === 'a';
}

export function canChangeRapidTestResult(
  sampleTaken?: string,
  observationStatus?: number
) {
  if (!sampleTaken || !observationStatus) {
    return true;
  } else {
    if (moment(sampleTaken).add(5, 'minutes').isAfter(moment())) {
      return true;
    }
    return false;
  }
}

export function canRegisterRapidTestResult(user: any) {
  return (
    isAccessible(accessControl.SAMPLING, user) ||
    isAccessible(accessControl.QUARANTINE_CONTROL, user)
  );
}

export function canSearchForPassenger(
  user: User,
  barCode: string,
  notifyError: (message: string) => SnackbarKey,
  lang: ILanguage,
  isRapidTest: boolean
) {
  const rapidBarcode = isRapidBarcode(barCode);
  const isDepartureBarCode = barCode?.toLowerCase().substring(0, 3) === 'dep';

  if (user.location === 'AEY') {
    return false;
  }

  if (isRapidTest && !rapidBarcode) {
    notifyError(translations.rapidBarcodeNotCorrect[lang]);
    return true;
  } else if (!isRapidTest && rapidBarcode) {
    notifyError(translations.notRapidTestLocation[lang]);
    return true;
  } else if (isDepartureBarCode && isDepartureExcluded(user)) {
    notifyError(translations.notDepartureLocation[lang]);
    return true;
  } else {
    return false;
  }
}

export function updateObservationIdentifierCheck(
  user: User,
  barCode: string,
  sampleId: string
) {
  const isRapidBarcode = barCode?.toLowerCase().substring(0, 3) === 'ain';
  const isSampleIdTA = sampleId?.toLowerCase().substring(0, 1) === 'a';
  const allowSampleTypeConversion = hasAllowSampleTypeConversion(user);

  if (allowSampleTypeConversion && isRapidBarcode && isSampleIdTA) {
    return true;
  } else {
    return false;
  }
}

export function canSearchForPassengerRapidCode(user: User) {
  const allowSampleTypeConversion = hasAllowSampleTypeConversion(user);

  if (allowSampleTypeConversion) {
    return true;
  } else {
    return false;
  }
}

export function getLocationOptions() {
  var locations: Location[] = JSON.parse(
    localStorage.getItem(localStorageKeys.LOCATION_OPTIONS) ?? '[]'
  );
  // For legacy purposes, remove later
  if (!locations.length) {
    locations = LOCATION_OPTIONS.map((l) => ({
      key: l.value,
      label: l.label,
      id: l.id,
    }));
  }
  return locations;
}

export async function sendToSampling(
  passenger: Passenger,
  isBorderControl: boolean
) {
  const status = passenger.borderControlStatus.id;
  const barCode = passenger.serialNumber;

  if (status !== undefined) {
    if (status === borderControlStatus.REGISTERED_IN_QUARANTINE) {
      return await httpService.declineQuarantine(barCode);
    } else if (status === borderControlStatus.REGISTERED_TO_TRANSIT) {
      return await httpService.declineTransit(
        barCode,
        borderControlStatus.REGISTERED_FOR_SAMPLING
      );
    } else if (status === borderControlStatus.REGISTERED_WITH_CERTIFICATE) {
      return await httpService.declineCertificate(
        barCode,
        borderControlStatus.REGISTERED_FOR_SAMPLING,
        isBorderControl
      );
    }
  }
}

export async function sendToQuarantine(passenger: Passenger) {
  const status = passenger.borderControlStatus.id;
  const barCode = passenger.serialNumber;

  if (status !== undefined) {
    if (
      status ===
      borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_NOT_REQUIRED
    ) {
      return await httpService.unpaidSampleNeededToQuarantine(barCode);
    } else if (status === borderControlStatus.REGISTERED_TO_TRANSIT) {
      return await httpService.declineTransit(
        barCode,
        borderControlStatus.REGISTERED_IN_QUARANTINE
      );
    } else if (status === borderControlStatus.REGISTERED_WITH_CERTIFICATE) {
      return await httpService.declineCertificate(
        barCode,
        borderControlStatus.REGISTERED_IN_QUARANTINE
      );
    }
  }
}

export function hasVaccinationGroupOrManagementAccess(accessList: any) {
  return (accessList as Array<any>).some(
    (e) =>
      e.value === accessControl.VACCINATION_GROUP.toString() ||
      e.value === accessControl.VACCINATION_GROUP_MANAGEMENT.toString()
  );
}

export function hasNeitherVaccinationGroupOrManagementAccess(accessList: any) {
  const hasAccess =
    (accessList as Array<any>).filter(
      (a) =>
        a.value === accessControl.VACCINATION_GROUP.toString() ||
        a.value === accessControl.VACCINATION_GROUP_MANAGEMENT.toString()
    ).length === 0;
  return hasAccess;
}

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getFormattedVaccineName(vaccine: Vaccine) {
  if (!vaccine.name) {
    return '';
  }

  let vaccineName = vaccine.name;
  vaccineName += vaccine.lotNumber ? `. ${vaccine.lotNumber}` : '';
  vaccineName += vaccine.description ? `. ${vaccine.description}` : '';
  return vaccineName;
}

export function getFormattedVaccineNameFromVaccination(vaccine: Vaccination) {
  if (!vaccine.vaccineName) {
    return '';
  }

  let vaccineName = vaccine.vaccineName;
  vaccineName += vaccine.lotNumber ? `. ${vaccine.lotNumber}` : '';
  vaccineName += vaccine.description ? `. ${vaccine.description}` : '';
  return vaccineName;
}

export function compareSSN(a: string, b: string) {
  const pattern = new RegExp(/^[0-9]{10}$/);
  if (!pattern.test(a)) {
    return -1;
  }
  if (!pattern.test(b)) {
    return 1;
  }

  const centuryA = !parseInt(a[9]) ? 21 : 20;
  const centuryB = !parseInt(b[9]) ? 21 : 20;
  if (centuryA !== centuryB) {
    return centuryA > centuryB ? -1 : 1;
  }

  const yearA = parseInt(a.substr(4, 2));
  const yearB = parseInt(b.substr(4, 2));
  if (yearA !== yearB) {
    return yearA > yearB ? -1 : 1;
  }

  const monthA = parseInt(a.substr(2, 2));
  const monthB = parseInt(b.substr(2, 2));
  if (monthA !== monthB) {
    return monthA > monthB ? -1 : 1;
  }

  const dayA = parseInt(a.substr(0, 2));
  const dayB = parseInt(b.substr(0, 2));
  return dayA === dayB ? 0 : dayA > dayB ? -1 : 1;
}

export function compareFlowDate(a: any, b: any) {
  if (!a.created) {
    return -1;
  }
  if (!b.created) {
    return 1;
  }
  return moment(a.created).isAfter(b.created) ? 1 : -1;
}

export function isAccessible(id: number, user: any) {
  const item = user?.accessList?.find((item: AccessItem) => {
    return item.id === id;
  });
  return item !== undefined;
}

export function getBirthYearBySSN(ssn: string) {
  const pattern = new RegExp(/^[0-9]{10}$/);
  if (!pattern.test(ssn)) {
    return 0;
  }

  const century = !parseInt(ssn[9]) ? 2000 : 1900;
  const year = parseInt(ssn.substr(4, 2));
  return century + year;
}

export function clearSavedFilters() {
  localStorage.removeItem(localStorageKeys.GROUP_MEMBER_FILTER_STATUS);
  localStorage.removeItem(localStorageKeys.GROUP_MEMBER_FILTER_VACCINES);
  localStorage.removeItem(localStorageKeys.GROUP_MEMBER_FILTER_COMMENTS);
  localStorage.removeItem(
    `${localStorageKeys.GROUP_VACCINATION_FILTER_KEY}${localStorageKeys.VACCINATION_GROUP_FILTER_SEARCH}`
  );
  localStorage.removeItem(
    `${localStorageKeys.GROUP_VACCINATION_FILTER_KEY}${localStorageKeys.VACCINATION_GROUP_FILTER_REGION}`
  );
  localStorage.removeItem(
    `${localStorageKeys.VACCINATION_GROUPS_FILTER_KEY}${localStorageKeys.VACCINATION_GROUP_FILTER_SEARCH}`
  );
  localStorage.removeItem(
    `${localStorageKeys.VACCINATION_GROUPS_FILTER_KEY}${localStorageKeys.VACCINATION_GROUP_FILTER_REGION}`
  );
}

export function clearSavedConditions() {
  localStorage.removeItem(localStorageKeys.VACCINATION_CONDITIONS_YEAR_FROM);
  localStorage.removeItem(localStorageKeys.VACCINATION_CONDITIONS_YEAR_TO);
  localStorage.removeItem(
    localStorageKeys.VACCINATION_CONDITIONS_SUMMONING_FROM
  );
  localStorage.removeItem(localStorageKeys.VACCINATION_CONDITIONS_SUMMONING_TO);
  localStorage.removeItem(localStorageKeys.VACCINATION_CONDITIONS_GROUPS);
  localStorage.removeItem(
    localStorageKeys.VACCINATION_CONDITIONS_ALWAYS_ALLOW_SECOND
  );
}

export function clearLocalStorage() {
  clearSavedFilters();
  clearSavedConditions();
  localStorage.removeItem(localStorageKeys.VACCINE_ID);
  localStorage.removeItem(localStorageKeys.TYPE_OF_VACCINATION);
  localStorage.removeItem(localStorageKeys.INFLUENZA_VACCINE_ID);
}

export function isSecondSampling(passenger: Passenger | null) {
  return Boolean(
    passenger && passenger.linkedSampling && !passenger.linkedSampling.isFirst
  );
}

export function showPCRStatus(user: any, passenger: Passenger | null) {
  if (!passenger) {
    return false;
  }

  const validBorderControlStatus =
    isReadyForSampling(passenger) ||
    passenger.borderControlStatus.id ===
    borderControlStatus.SAMPLING_COMPLETED ||
    passenger.borderControlStatus.id ===
    borderControlStatus.REGISTERED_TO_TRANSIT ||
    passenger.borderControlStatus.id === borderControlStatus.TRANSIT_CONFIRMED;

  return (
    validBorderControlStatus &&
    !!user.isNegPCRMandatory &&
    !isSecondSampling(passenger)
  );
}

export function showCountryStatus(user: any, passenger: Passenger | null) {
  if (!passenger) {
    return false;
  }

  return !!user.isNegPCRMandatory;
}

export function getPhoneNumberString(passenger: Passenger) {
  const phoneNumbers = passenger.phoneNumbers;
  if (phoneNumbers) {
    const mainNumber = phoneNumbers.filter((number) => number.isMainNumber);
    if (mainNumber.length > 0) {
      return `${mainNumber[0].code ? '+' + mainNumber[0].code : ''} ${mainNumber[0].number
        }`;
    } else if (phoneNumbers.length > 0) {
      return `${phoneNumbers[0].code ? '+' + phoneNumbers[0].code : ''} ${phoneNumbers[0].number
        }`;
    }
  }
  return '';
}

export function toISOStringTimezone(date: Moment) {
  const isoDate = date.toISOString(true);
  if (isoDate.includes('+')) {
    return isoDate.split('+')[0];
  }
  return isoDate;
}

export function isNegPCRTestMandatory(user: any, passenger: Passenger) {
  const isNeeded = !!user.isNegPCRMandatory && !isSecondSampling(passenger);
  const hasSkippedStatus =
    passenger.skipSecondTestAndPcrBecauseOfAge ||
    passenger.skipSecondTestAndPcrBecauseOfValidCertificate;
  return isNeeded && !hasSkippedStatus;
}

export function hasQuarantineHouseAccess(accessList: any) {
  return (accessList as Array<any>).some(
    (e) => e.value === accessControl.QUARANTINE_HOTEL.toString()
  );
}

export function getRiskCountryColorFromStatus(status: number) {
  return status === RiskCountryStatus.RED
    ? theme.palette.error.light
    : status === RiskCountryStatus.YELLOW
      ? theme.palette.warning.light
      : 'white';
}

export function checkIfRequireQuarantineResidency(status: number) {
  if (
    status === countryStatuses.MEDIUM_RISK_QUARANTINE_AT_HOTEL ||
    status === countryStatuses.HIGH_RISK_QUARANTINE_AT_HOTEL ||
    status === countryStatuses.QUARANTINE_HOTEL_EXEMPTION_REJECTED ||
    status === countryStatuses.SENT_TO_QUARANTINE_HOTEL
  ) {
    return true;
  }
  return false;
}

export function getLastVaccineDate(vaccinations: Vaccination[]) {
  /* function sortVaccines(a: Vaccination, b: Vaccination) {
    if (!a.vaccinationDate) {
      return -1;
    }
    if (!b.vaccinationDate) {
      return 1;
    }
    return moment(a.vaccinationDate).isAfter(b.vaccinationDate) ? 1 : -1;
  } */
  if (vaccinations.length > 0) {
    //const sortedVaccines = vaccinations.sort(sortVaccines);
    const latestVaccine = [...vaccinations].pop();

    return latestVaccine?.vaccinationDate || '';
  } else {
    return '';
  }
}

export function getVaccineNames(vaccinations: Vaccination[]) {
  if (vaccinations.length === 1) {
    const vaccineName = vaccinations[0].vaccineName;
    return vaccineName ? vaccineName.split(/,| /)[0] : '';
  } else if (vaccinations.length > 1) {
    const vaccineNames = [...vaccinations].map((item) =>
      item.vaccineName?.substring(0, 3)
    );

    return vaccineNames.join(', ');
  } else {
    return '';
  }
}

export function canEditPassengerInfo(passenger: Passenger) {
  const completedFlowsatusesIds = passenger.flowStatusesCompleted?.map(
    (item) => item.id
  );
  if (
    completedFlowsatusesIds?.includes(
      actionStatusesCompleted.REGISTRATION_COMPLETED
    ) ||
    passenger.sampleTaken
  ) {
    return false;
  }
  return true;
}

export function getYesNoOptions(lang: ILanguage) {
  return [
    {
      label: translations.yes[lang],
      value: 'true',
    },
    {
      label: translations.no[lang],
      value: 'false',
    },
  ];
}

export function getYesNoNeitherOptions(lang: ILanguage) {
  return [
    {
      label: translations.notSelected[lang],
      value: 'neither',
    },
    {
      label: translations.yes[lang],
      value: 'true',
    },
    {
      label: translations.no[lang],
      value: 'false',
    },
  ];
}
export function hasAccessToLoginLocation(user: any, locationId: string) {
  if (user && user.samplingLocations) {
    return user.samplingLocations.find(
      (x: any) => x.id.toString() === locationId.toString()
    );
  }
  return false;
}

export function getFileTypeText(file: string) {
  const splitString = file.split('/');
  if (splitString.length > 1) {
    return splitString[1];
  }

  return file;
}

export function shouldShowWarningForBooster(person: any) {
  var nextVaccinationValid = true;
  // if this person already has vaccinations and has atleast 2 vaccinations
  if (person.vaccinations && person.vaccinations.length > 1) {
    var lastVaccination = person.vaccinations[person.vaccinations.length - 1];
    var daysBetween =
      lastVaccination.boosterWarningDaysBetweenVaccinations || 0;
    const nextVaccinationDate = moment(lastVaccination.vaccinationDate)
      .add(daysBetween, 'days')
      .startOf('day');
    nextVaccinationValid = moment().isAfter(nextVaccinationDate);
  }
  return !nextVaccinationValid;
}

export function shouldShowWarningForInfluenza(person: any) {
  var nextVaccinationValid = true;
  // if this person already has vaccinations and has atleast 1 vaccinations
  if (person.influenzaVaccinatnions && person.influenzaVaccinatnions.length > 0) {
    var lastVaccination = person.influenzaVaccinatnions[person.influenzaVaccinatnions.length - 1];
    var daysBetween =
      lastVaccination.boosterWarningDaysBetweenVaccinations || 0;
    const nextVaccinationDate = moment(lastVaccination.vaccinationDate)
      .add(daysBetween, 'days')
      .startOf('day');
    nextVaccinationValid = moment().isAfter(nextVaccinationDate);
  }
  return !nextVaccinationValid;
}

export function shouldShowErrorForBoosterVaccine(selectedVaccine: Vaccine | null, vaccinations: Vaccination[]) {
  if (selectedVaccine) {
    if (vaccinations.length >= selectedVaccine.numberOfPreviousVaccinationsRequired) {
      return false
    }
    else if (selectedVaccine.numberOfPreviousVaccinationsRequired === 2 && vaccinations.length === 1 && vaccinations[0].atc === vaccinesAtc.JANSSEN) {
      return false
    }
  }
  return true;
}

export function getFileValidationErrors(file: PassengerFiles) {
  if (file?.validationErrors && file.validationErrors.length > 0) {
    const errors = file.validationErrors?.sort(
      (a, b) => a.severity - b.severity
    );
    const errorTypes = errors.map((item) => item.severity);

    if (errorTypes?.includes(qrCodeErrorSeverity.ERROR)) {
      const errorsDecription = errors
        .filter((item) => item.severity === qrCodeErrorSeverity.ERROR)
        .map((error) => error.description);
      return errorsDecription.join(', ');
    }
    if (errorTypes?.includes(qrCodeErrorSeverity.INVALID)) {
      const errorsDecription = errors
        .filter((item) => item.severity === qrCodeErrorSeverity.INVALID)
        .map((error) => error.description);
      return errorsDecription.join(', ');
    }
    if (errorTypes?.includes(qrCodeErrorSeverity.WARNING)) {
      const errorsDecription = errors
        .filter((item) => item.severity === qrCodeErrorSeverity.WARNING)
        .map((error) => error.description);
      return errorsDecription.join(', ');
    }
    if (errorTypes?.includes(qrCodeErrorSeverity.INFO)) {
      const errorsDecription = errors
        .filter((item) => item.severity === qrCodeErrorSeverity.INFO)
        .map((error) => error.description);
      return errorsDecription.join(', ');
    }
  }
  return '';
}
export function splitFullName(fullName: string) {
  if (fullName && fullName.length > 0) {
    var names = fullName.split(' ');

    var lastName = names.pop();
    var firstName = '';
    if (lastName) {
      firstName = fullName.replace(lastName, '').trim();
    }
    return { firstName: firstName, lastName: lastName };
  } else {
    return null;
  }
}
export function getCenturyFromSsn(ssn: string) {
  const lastDigit = ssn.slice(9, 10);
  const isBornAfter2000 = lastDigit === '0';
  return isBornAfter2000 ? '20' : '19';
}
export function getDateOfBirthFromSsn(ssn: string) {
  const century = getCenturyFromSsn(ssn);
  return moment(
    `${ssn.slice(0, 2)}.${ssn.slice(2, 4)}.${century}${ssn.slice(4, 6)}`,
    'DD.MM.YYYY'
  );
}
const YEAR_OPTIONS = 100;
export function getYears({ min, max }: { min?: string; max?: string }) {
  const currentYear = moment().year();
  const years = [];
  const minVal = min && !isNaN(parseInt(min)) ? parseInt(min) : undefined;
  const maxVal = max && !isNaN(parseInt(max)) ? parseInt(max) : undefined;

  for (let i = 0; i < YEAR_OPTIONS; i++) {
    const yearVal = currentYear - i;
    if ((!minVal || yearVal >= minVal) && (!maxVal || yearVal <= maxVal)) {
      const yearStr = yearVal.toString();
      years.push({ value: yearStr, label: yearStr });
    }
  }
  return years;
}
export function isPrintingSamplingTicketAllowed(
  sampleTaken: string | undefined
) {
  return !sampleTaken;
}
export function handleSsnInput(
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  handleChange: (e: React.ChangeEvent<any>) => void
) {
  e.target.value = replaceUnwantedCharsInSsn(e.target.value);
  return handleChange(e);
}

export function replaceUnwantedCharsInSsn(ssn: string) {
  return ssn.replace(/[^0-9.]/g, '').trim();
}

export function validateBirthDateForChildVaccination(ssn: string) {
  var birthDate = getDateOfBirthFromSsn(ssn);
  var years = moment().diff(birthDate.toISOString(), 'years', false);
  return years === 5 || (years > 5 && years < 12);
}
