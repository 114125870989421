import React, { useContext } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

import { Context } from '../store/Store';
import { ConfirmQtFromAirlineKey } from '../store/Constants';

const ConfirmQtFromAirlineSwitch: React.FC = () => {
  const [lang] = useContext(LangContext);
  const [state, dispatch] = useContext(Context);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const payload = event.target.checked ? 'true' : 'false';
    dispatch({ type: ConfirmQtFromAirlineKey, payload: payload });
  }

  return (
    <FormControlLabel
      control={
        <Switch
          color="primary"
          checked={state.confirmQtFromAirline}
          onChange={(val) => handleChange(val)}
        />
      }
      label={translations.confirmQuFromAirline[lang]}
    />
  );
};

export default ConfirmQtFromAirlineSwitch;
