import React, { useContext, useEffect } from 'react';
import {
  Grid,
  useTheme,
  useMediaQuery,
  Paper,
  TextField,
} from '@material-ui/core';
import moment, { Moment } from 'moment';
import { Form, Formik, FormikProps } from 'formik';

import Button from '../Button';
import DatePicker from '../DatePicker';
import { ILanguage, LangContext } from '../../context/LangContext';
import translations from '../../assets/json/translations.json';
import reviewCertificateStatuses from '../../assets/json/CertificateReview/reviewCertificateStatuses.json';
import Autocomplete, { IOption } from '../Autocomplete';

interface IProps {
  onSubmit: (values: IFormValues, initialLoad?: boolean) => void;
  onClear: () => void;
  loadForm: IFormValues;
  formCleared: boolean;
}

export interface IFormValues {
  search: string;
  arrivalFrom: Moment | null;
  arrivalTo: Moment | null;
  reviewStatus: IOption | null;
}

type IFormik = FormikProps<IFormValues>;

const ReviewCertificateFilter: React.FC<IProps> = ({
  onSubmit,
  onClear,
  loadForm,
  formCleared,
}) => {
  const theme = useTheme();
  const [lang] = useContext(LangContext);
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (loadForm && !formCleared) {
      onSubmit(loadForm, true);
    }
    /* eslint-disable */
  }, [loadForm]);

  return (
    <Formik
      onSubmit={(values) => onSubmit(values)}
      initialValues={getInitalValues()}
      enableReinitialize
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>{renderFilters(formik)}</Form>
      )}
    </Formik>
  );

  function renderFilters(formik: IFormik) {
    const { values, handleChange, handleBlur, setFieldValue, isSubmitting } =
      formik;
    return (
      <Paper
        elevation={2}
        style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label={translations.searchTitle[lang]}
              autoFocus
              autoComplete="off"
              id="search"
              variant="outlined"
              value={values.search}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              fullWidth
              id="reviewStatus"
              label={translations.reviewStatus[lang]}
              onChange={(val) =>
                setFieldValue('reviewStatus', val as IOption | null)
              }
              value={values.reviewStatus}
              items={getReviewStatusOptions(lang)}
            />
          </Grid>

          <Grid
            item
            xs={smDown ? 12 : undefined}
            sm={smDown ? 6 : undefined}
            md={smDown ? 3 : undefined}
            style={{ flex: !smDown ? 1 : undefined }}
          >
            <DatePicker
              id="arrivalFrom"
              label={translations.arrivalsFrom[lang]}
              value={values.arrivalFrom}
              muiProps={{
                disableToolbar: true,
                maxDate: values.arrivalTo || undefined,
              }}
              onChange={(e) =>
                setFieldValue('arrivalFrom', e ? moment(e) : null)
              }
              onBlur={handleBlur}
            />
          </Grid>

          <Grid
            item
            xs={smDown ? 12 : undefined}
            sm={smDown ? 6 : undefined}
            md={smDown ? 3 : undefined}
            style={{ flex: !smDown ? 1 : undefined }}
          >
            <DatePicker
              id="arrivalTo"
              label={translations.arrivalsTo[lang]}
              value={values.arrivalTo}
              muiProps={{
                disableToolbar: true,
                minDate: values.arrivalFrom || undefined,
              }}
              onChange={(e) => setFieldValue('arrivalTo', e ? moment(e) : null)}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={smDown ? 12 : undefined}>
            <Button fullWidth={smDown} type="submit" disabled={isSubmitting}>
              {translations.search[lang]}
            </Button>
          </Grid>
          <Grid item xs={smDown ? 12 : undefined}>
            <Button
              fullWidth={smDown}
              variant="outlined"
              onClick={() => {
                clearForm();
              }}
            >
              {translations.clear[lang]}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  function clearForm() {
    onClear();
  }

  function getInitalValues() {
    return loadForm;
  }

  function getReviewStatusOptions(lang: ILanguage) {
    const statusOptions = reviewCertificateStatuses.map((item) => {
      return {
        label: item.description[lang],
        value: item.id.toString(),
      };
    });
    return statusOptions;
  }
};

export default ReviewCertificateFilter;
