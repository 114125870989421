import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Typography,
  Stepper as MuiStepper,
  Step,
  StepContent,
  StepLabel,
  Grid,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import Button from './Button';
import translations from '../assets/json/translations.json';
import { LangContext } from '../context/LangContext';
import { UserContext } from '../context/UserContext';
import { IButton } from './PassengerInfo';
import {
  FlowStatus,
  FlowStatusActive,
  FlowStatusCompleted,
} from '../models/Models';
import moment from 'moment';
import { DATE_AND_TIME_FORMAT } from '../constants';
import * as httpService from '../services/httpService.v2';
interface IProps {
  buttons?: IButton[] | null;
  flowStatusActive?: FlowStatusActive;
  flowStatusCompleted: FlowStatusCompleted[];
  loading?: boolean;
  paper?: boolean;
  barcode?: string;
  showUsersChange?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  titleContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  labelActive: {
    color: theme.palette.success.main,
  },
  dateContainer: {
    fontSize: 12,
    marginLeft: 8,
    marginTop: -2,
  },
}));

const Stepper: React.FC<IProps> = ({
  buttons,
  flowStatusActive,
  flowStatusCompleted,
  loading,
  paper = true,
  barcode,
  showUsersChange = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const mdOnly = useMediaQuery(theme.breakpoints.only('md'));
  const mdOrXs = mdOnly || xsDown;

  const [activeStep, setActiveStep] = useState(0);
  const [flowStatuses, setFlowstatuses] = useState<FlowStatus[]>([]);
  const [renderUserChange, setRenderUserChange] = useState(false);

  useEffect(() => {
    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowStatusActive, flowStatusCompleted]);

  async function setup() {
    if (showUsersChange && barcode) {
      await setupShowUserChange();
    } else {
      stepperLogic(flowStatusCompleted, flowStatusActive);
    }
  }

  async function setupShowUserChange() {
    let isSuperUser = false;
    if (user && user.accessList) {
      const access = user.accessList.find((x: any) => x.id === 5);
      if (access) {
        isSuperUser = true;
      }
      let fs = [];
      if (isSuperUser) {
        fs = await fetchDetailFlowStatus();
        if (fs) {
          setRenderUserChange(true);
        }
      } else {
        fs = flowStatusCompleted;
      }
      stepperLogic(fs, flowStatusActive);
    }
  }

  async function fetchDetailFlowStatus() {
    if (barcode) {
      var res = await httpService.getPassengerDetailFlowStatusByBarCode(
        barcode as string
      );
      if (res.isOk && res.data.length > 0) {
        return res.data;
      }
    }
    return [];
  }

  function stepperLogic(fs: FlowStatus[], fsa: FlowStatusActive | undefined) {
    if (fsa && fs) {
      const newFlowStatuses = [...fs, fsa].map((item) => new FlowStatus(item));
      setFlowstatuses(newFlowStatuses);
      setActiveStep(fs.length);
    } else if (fsa) {
      setFlowstatuses([new FlowStatus(fsa)]);
    } else if (fs) {
      setFlowstatuses(fs);
      setActiveStep(fs.length);
    }
  }

  const content = (
    <MuiStepper
      activeStep={activeStep}
      orientation="vertical"
      style={{ padding: 0 }}
    >
      {flowStatuses.map((item, index) => (
        <Step
          key={index}
          /*  disabled={item.skipped || !item.finished}
            completed={item.skipped || !item.finished ? false : true} */
        >
          <StepLabel>
            <Typography style={{ fontWeight: 'bold' }}>
              {item.description[lang]}
            </Typography>
            {item.createdDate && (
              <Typography
                color="textSecondary"
                className={classes.dateContainer}
              >
                {` ${moment(item.createdDate).format(DATE_AND_TIME_FORMAT)}`}
                {renderUserChange && getChangeBy(item)}
              </Typography>
            )}
          </StepLabel>
          <StepContent>
            {item.descriptionDetails && (
              <div className={classes.actionsContainer}>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: 14, marginTop: -4 }}
                >
                  {item.descriptionDetails[lang]}
                </Typography>
              </div>
            )}
            {buttons && index === activeStep && (
              <Grid container spacing={2} justify="flex-start">
                {buttons && buttons.map(renderButton)}
              </Grid>
            )}
          </StepContent>
        </Step>
      ))}
    </MuiStepper>
  );

  return paper ? (
    <Paper elevation={2} className={classes.container}>
      <div className={classes.titleContainer}>
        {!mdOrXs && (
          <Typography variant="h5">
            {translations.statusInTheProcess[lang]}
          </Typography>
        )}
      </div>
      {content}
    </Paper>
  ) : (
    content
  );

  function renderButton(button: IButton, index: number) {
    const buttonsCount = (buttons || []).length;
    const fullWidth = !!(buttonsCount % 2) && index === buttonsCount - 1;

    return (
      <Grid
        item
        key={index}
        xs={12}
        sm={fullWidth ? 12 : 6}
        style={button.style}
      >
        <Button
          fullWidth
          padding={false}
          onClick={button.onClick}
          variant={button.variant || 'contained'}
          error={button.error}
          color={button.color || 'primary'}
          disabled={button.disabled || loading}
        >
          {button.icon && button.icon()}
          {button.label}
        </Button>
      </Grid>
    );
  }

  function getChangeBy(fs: FlowStatus) {
    let changeBy = '';
    if (fs.changeByName) {
      changeBy += `${translations.changeBy[lang]} ${fs.changeByName}`;
    }
    if (fs.changeBySsn) {
      changeBy += ` - ${fs.changeBySsn}`;
    }
    if (changeBy) {
      changeBy = ` - ${changeBy}`;
    }
    return changeBy;
  }
};

export default Stepper;
