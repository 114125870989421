import React, { useContext } from 'react';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import translations from '../assets/json/translations.json'
import { LangContext } from '../context/LangContext';

interface IProps {
  message?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    height: 50,
  },
}));

const NoResults: React.FC<IProps> = ({ message }) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);

  return (
    <Paper elevation={2} className={classes.container}>
      <Typography color="textSecondary">{message || translations.noResults[lang]}</Typography>
    </Paper>
  );
};

export default NoResults;
