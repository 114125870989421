import React, { useContext, useState, useEffect } from 'react';
import {
  Typography,
  makeStyles,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
  TextField,
  FormControlLabel,
  Radio,
  lighten,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Error';
import WarningIconTriangle from '@material-ui/icons/Warning';

import { IPassengerDetailsNew as IPassengerDetails } from '../../interfaces/passenger';
import { Passenger } from '../../models/Models';

import { ILanguage, LangContext } from '../../context/LangContext';
import translations from '../../assets/json/translations.json';
import {
  PassengerFiles,
  PassengerReview,
} from '../../models/CertificateReviewModels';
import ReviewFileList from './ReviewFileList';
import Button from '../Button';

import ConfirmDialog from '../ConfirmDialog';
import Dropdown from '../Dropdown';
import { IPassengerReviewDetails } from '../../interfaces/passengerReview';
import {
  reviewCertificateStatuses,
  reviewFileTypeStatuses,
} from '../../constants/enums';
import reviewStatuses from '../../assets/json/CertificateReview/reviewCertificateStatuses.json';
import rejectStatuses from '../../assets/json/CertificateReview/rejectReviewCertificateStatuses.json';
import { useHistory } from 'react-router-dom';
import { TIME_FORMAT } from '../../constants';
import moment from 'moment';

interface IProps {
  passenger: Passenger;
  passengerReview: PassengerReview;
  personalDetails: IPassengerDetails[];
  otherDetails: IPassengerDetails[];
  extraSamplingDetails: IPassengerDetails[];
  reviewDetails: IPassengerReviewDetails[];
  files: PassengerFiles[];
  loadingFiles: boolean;
  changeStatusReview: (
    reviewCertificateStatus: number,
    rejectStatus?: string,
    rejectComment?: string,
    passengerBorderStatus?: number
  ) => Promise<void>;
  getNextAvailablePassenger: () => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(5),
  },
  topItems: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  titleDivider: {
    display: 'inline-block',
    margin: theme.spacing(0, 1),
  },
  titleSerialNumber: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
  otherDetails: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
  },
  descriptionTitle: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  descriptionDetails: {
    color: theme.palette.text.secondary,
  },
  warningIcon: {
    position: 'absolute',
    top: '-2px',
    color: theme.palette.warning.light,
  },
  warningIconTriangle: {
    color: theme.palette.warning.light,
    marginBottom: theme.spacing(-0.5),
    marginRight: theme.spacing(1),
  },
  warningContainer: {
    backgroundColor: lighten(theme.palette.warning.light, 0.8),
    borderRadius: 4,
    color: theme.palette.text.secondary,
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
  },
}));

const MAX_COMMENT_LENGTH = 100;
const approveCertificate = 26;
const approveRecoveryCertificate = 28;

const ReviewCertificateDetails: React.FC<IProps> = ({
  passenger,
  passengerReview,
  personalDetails,
  otherDetails,
  extraSamplingDetails,
  reviewDetails,
  files,
  loadingFiles,
  changeStatusReview,
  getNextAvailablePassenger,
}) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);
  const history = useHistory();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const [openRejectReviewDialog, setOpenRejectReviewDialog] = useState(false);
  const [openApproveReviewDialog, setOpenApproveReviewDialog] = useState(false);
  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);
  const [rejectionStatus, setRejectionStatus] = useState('');
  const [rejectionText, setRejectionText] = useState('');

  const [recoveryCertificateStatus, setRecoveryCertificateStatus] = useState<
    number | null
  >(null);

  const hasRecoveryCertificate = files.find(
    (item) => item.fileType.id === reviewFileTypeStatuses.PREVIOUS_INFECTION
  );

  const filterReviewStatus = reviewStatuses.find(
    (item) =>
      item.id !== passengerReview.reviewStatus.id &&
      item.id !== reviewCertificateStatuses.UNRESOLVE
  );
  const [selectedStatusIndex, setSelectedStatusIndex] = useState<number | null>(
    filterReviewStatus?.id || null
  );

  useEffect(() => {
    if (hasRecoveryCertificate?.issueDate) {
      const hasExpired = moment().isAfter(
        moment(hasRecoveryCertificate.issueDate).add(180, 'days')
      );
      if (hasExpired) {
        setRecoveryCertificateStatus(approveCertificate);
      } else {
        setRecoveryCertificateStatus(approveRecoveryCertificate);
      }
    }
  }, [hasRecoveryCertificate]);

  return (
    <Paper elevation={2} className={classes.container}>
      <Grid container spacing={2}>
        {passengerReview.openedTime && getPassengerReviewWarning()}
        <Grid item xs={12}>
          {renderPersonalDetails()}
        </Grid>
        <Grid item xs={12}>
          {renderOtherDetails()}
        </Grid>
        <Grid item xs={12}>
          {renderReviewDetails()}
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.descriptionTitle}>
            {translations.files[lang]}:
          </Typography>
          <ReviewFileList
            files={files}
            loading={loadingFiles}
            passenger={passenger}
          />
        </Grid>
        <Grid item xs={12}>
          {renderButtons()}
        </Grid>
      </Grid>
      {renderDialogs()}
    </Paper>
  );

  function renderPersonalDetails() {
    const splitList = Math.ceil(personalDetails.length / 2);

    return (
      <Grid container spacing={xsDown ? 1 : 3} direction="row">
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            {personalDetails.slice(0, splitList).map((item, index) => (
              <Grid item xs={12} key={index}>
                {renderInfoItem(item)}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            {personalDetails.slice(splitList).map((item, index) => (
              <Grid item xs={12} key={index}>
                {renderInfoItem(item)}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderOtherDetails() {
    return (
      <div className={classes.otherDetails}>
        <Grid container spacing={xsDown ? 1 : 3} direction="row">
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              {otherDetails.map((item, index) => (
                <Grid item xs={12} key={index}>
                  {renderInfoItem(item)}
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              {extraSamplingDetails.map((item, index) => (
                <Grid item xs={12} key={index}>
                  {renderInfoItem(item)}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  function renderReviewDetails() {
    return (
      <Grid container spacing={1} direction="row">
        {reviewDetails.map((item: any, index) => (
          <Grid item xs={12} sm={6} key={index}>
            {!(item.hide && item.hide(passengerReview)) && (
              <Typography style={{ position: 'relative' }}>
                <span className={classes.descriptionTitle}>{item.label}:</span>
                <span className={classes.descriptionDetails}>
                  {item?.render && item.render(passengerReview)}
                  {item?.getVal && item.getVal(passengerReview)}
                </span>
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
    );
  }

  function renderInfoItem({
    label,
    warning = false,
    hideLabel = false,
    getVal = () => '',
    render,
    renderInline,
    hide,
  }: IPassengerDetails) {
    if (hide && hide(passenger)) {
      return null;
    }

    const warningIconMargin = warning ? 30 : 0;

    if (!render) {
      return (
        <Typography style={{ position: 'relative' }}>
          {warning && <WarningIcon className={classes.warningIcon} />}
          <span
            className={classes.descriptionTitle}
            style={{ marginLeft: warningIconMargin }}
          >
            {label}:
          </span>
          <span className={classes.descriptionDetails}>
            {renderInline ? renderInline(passenger) : getVal(passenger)}
          </span>
        </Typography>
      );
    }

    return (
      <div style={{ position: 'relative' }}>
        {warning && <WarningIcon className={classes.warningIcon} />}
        {!hideLabel ? (
          <Typography
            className={classes.descriptionTitle}
            style={{ display: 'inline-block', marginLeft: warningIconMargin }}
          >
            {label}:
          </Typography>
        ) : (
          <span style={{ marginLeft: warningIconMargin }} />
        )}
        {render(passenger)}
      </div>
    );
  }

  function renderButtons() {
    if (
      passengerReview.reviewStatus.id === reviewCertificateStatuses.UNRESOLVE
    ) {
      return (
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button
              onClick={() => setOpenApproveReviewDialog(true)}
              variant="contained"
            >
              {translations.approve[lang]}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => setOpenRejectReviewDialog(true)}
              variant="contained"
              color="secondary"
            >
              {translations.reject[lang]}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => history.push('/certificate-review')}
              variant="outlined"
            >
              {translations.back[lang]}
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={2} justify="flex-end">
        <Grid item>
          <Button
            onClick={() => setOpenChangeStatusDialog(true)}
            variant="outlined"
          >
            {translations.changeStatus[lang]}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => history.push('/certificate-review')}
            variant="outlined"
          >
            {translations.back[lang]}
          </Button>
        </Grid>
      </Grid>
    );
  }

  function renderDialogs() {
    return (
      <>
        <ConfirmDialog
          open={openRejectReviewDialog}
          title={translations.rejectReview[lang]}
          onCancel={() => clearDialogs()}
          onConfirm={() =>
            changeStatus(
              reviewCertificateStatuses.REJECT,
              rejectionStatus,
              rejectionText
            )
          }
          confirmText={translations.reject[lang]}
          disableConfirm={!rejectionStatus}
        >
          {getRejectionDialogContent()}
        </ConfirmDialog>
        <ConfirmDialog
          open={openApproveReviewDialog}
          title={translations.approveReview[lang]}
          onCancel={() => clearDialogs()}
          onConfirm={() =>
            changeStatus(
              reviewCertificateStatuses.APPROVE,
              undefined,
              undefined,
              recoveryCertificateStatus || approveCertificate
            )
          }
          confirmText={translations.approve[lang]}
          text={translations.approveReviewText[lang]}
          disableConfirm={hasRecoveryCertificate && !recoveryCertificateStatus}
        >
          {getApproveStatusDialogContent()}
        </ConfirmDialog>
        <ConfirmDialog
          open={openChangeStatusDialog}
          title={translations.changeStatus[lang]}
          onCancel={() => clearDialogs()}
          onConfirm={() =>
            changeStatus(
              selectedStatusIndex || 0,
              rejectionStatus,
              rejectionText
            )
          }
          disableConfirm={disableChangeStatusDialog()}
          text={translations.changeReviewStatusText[lang]}
        >
          {getChangeStatusDialogContent()}
        </ConfirmDialog>
      </>
    );
  }

  function getRejectionStatusOptions(lang: ILanguage) {
    const rejectReviewStatuses = rejectStatuses.map((item) => {
      return {
        label: item.description[lang],
        value: item.id.toString(),
      };
    });
    return rejectReviewStatuses;
  }

  function getRejectionDialogContent() {
    return (
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <Dropdown
            required
            fullWidth
            id="rejectionStatus"
            value={rejectionStatus}
            label={translations.reasonForRejection[lang]}
            error={!rejectionStatus && !!rejectionText}
            items={getRejectionStatusOptions(lang)}
            onChange={(e) => setRejectionStatus(e.target.value as string)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            autoComplete="off"
            id="rejectionText"
            variant="outlined"
            rows={2}
            inputProps={{ maxLength: MAX_COMMENT_LENGTH }}
            label={translations.comment[lang]}
            value={rejectionText}
            onChange={(e) => setRejectionText(e.target.value)}
          />
          <Typography
            color="textSecondary"
            variant="body2"
            style={{ marginTop: theme.spacing(1) }}
          >
            {rejectionText.length}/{MAX_COMMENT_LENGTH}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  function getChangeStatusDialogContent() {
    if (filterReviewStatus && openChangeStatusDialog) {
      return (
        <>
          <Grid
            container
            spacing={0}
            direction="column"
            style={{ marginBottom: theme.spacing(0.5) }}
          >
            <Grid item xs={12}>
              <FormControlLabel
                label={filterReviewStatus.description[lang]}
                onClick={() => setSelectedStatusIndex(filterReviewStatus.id)}
                control={<Radio color="primary" checked={true} />}
              />
            </Grid>
          </Grid>
          {filterReviewStatus.id === reviewCertificateStatuses.REJECT &&
            getRejectionDialogContent()}
        </>
      );
    }
    return null;
  }

  function getPassengerReviewWarning() {
    return (
      <Grid item xs={12} className={classes.warningContainer}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <WarningIconTriangle className={classes.warningIconTriangle} />
            <Typography style={{ display: 'inline-block' }}>
              {`${passengerReview.openByName?.trim()} (${
                passengerReview.openBySsn
              }) ${translations.hasBeenWorkingOn[lang]} ${moment(
                passengerReview.openedTime
              ).format(TIME_FORMAT)}`}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              padding={false}
              style={{ backgroundColor: theme.palette.warning.light }}
              onClick={() => getNextAvailablePassenger()}
            >
              {translations.nextAvailable[lang]}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function getApproveStatusDialogContent() {
    if (hasRecoveryCertificate && !hasRecoveryCertificate.issueDate) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          style={{ marginBottom: theme.spacing(0.5) }}
        >
          <Grid item xs={12}>
            <FormControlLabel
              label={translations.recoveryCertificateOlderThan[lang]}
              onClick={() => setRecoveryCertificateStatus(approveCertificate)}
              control={
                <Radio
                  color="primary"
                  checked={approveCertificate === recoveryCertificateStatus}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={translations.recoveryCertificateYoungerThan[lang]}
              onClick={() =>
                setRecoveryCertificateStatus(approveRecoveryCertificate)
              }
              control={
                <Radio
                  color="primary"
                  checked={
                    approveRecoveryCertificate === recoveryCertificateStatus
                  }
                />
              }
            />
          </Grid>
        </Grid>
      );
    }
  }

  function disableChangeStatusDialog() {
    if (selectedStatusIndex === reviewCertificateStatuses.REJECT) {
      if (rejectionStatus) {
        return false;
      }
    }
    if (selectedStatusIndex === reviewCertificateStatuses.APPROVE) {
      return false;
    }
    return true;
  }

  async function changeStatus(
    reviewCertificateStatus: number,
    rejectStatus?: string,
    rejectComment?: string,
    passengerBorderStatus?: number
  ) {
    await changeStatusReview(
      reviewCertificateStatus,
      rejectStatus,
      rejectComment,
      passengerBorderStatus
    );
    clearDialogs();
  }

  function clearDialogs() {
    setOpenApproveReviewDialog(false);
    setOpenChangeStatusDialog(false);
    setOpenRejectReviewDialog(false);
    setRejectionStatus('');
    setRejectionText('');
    setSelectedStatusIndex(null);
  }
};

export default ReviewCertificateDetails;
