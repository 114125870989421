import React, { useState, useContext, useEffect } from 'react';
import { Grid, Typography, makeStyles, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import Layout from '../components/Layout';
import Section from '../components/Section';
import SamplingStatus from '../components/SamplingStatus';
import SearchForm, { IFormValues } from '../components/SearchForm';
import PassengerList from '../components/PassengerList';
import PassengerInfo, { IButton } from '../components/PassengerInfo';
import LoadingIndicator from '../components/LoadingIndicator';
import ResidenceInfo from '../components/ResidenceInfo';
import CopyButton from '../components/CopyButton';
import PaymentButton from '../components/PaymentInfoButton';
import ConfirmDialog from '../components/ConfirmDialog';
import ChangeStatusDialog from '../components/ChangeStatusDialog';
import PreScreeningStatus from '../components/PreScreeningStatus';
import SendResultEmail from '../components/SendResultEmail';
import CreateCertificate, {
  ICertificateFormValues,
} from '../components/CreateCertificate';
import * as httpService from '../services/httpService.v2';
import posService from '../services/posService';
import {
  EuDccCertificate,
  Passenger,
  SearchQuery,
  Transaction,
} from '../models/Models';
import {
  IPassengerDetails,
  IUpdatePassengerInfo,
} from '../interfaces/passenger';
import useNotifier from '../hooks/useNotifier';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { DATE_FORMAT, TABLE_ROWS_DEFAULT } from '../constants';
import { borderControlStatus, accessControl } from '../constants/enums';
import Print from '../components/Print';
import { CefSharpShell } from '../helpers/CefSharpShell';
import { PosContext } from '../context/PosContext';
import {
  hasUserAccess,
  sendToSampling as sendToSamplingUtil,
  sendToQuarantine as sendToQuarantineUtil,
  showPCRStatus,
  getPhoneNumberString,
  isRapidTest,
  isAccessible,
} from '../utils';
import { UserContext } from '../context/UserContext';
import Stepper from '../components/Stepper';
import CreateForeignCertificate, {
  IForeignCertificateFormValues,
} from '../components/CreateForeignCertificate';
import * as euDccService from '../services/euDccService';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    flex: 1,
    padding: theme.spacing(3),
    margin: theme.spacing(3),
    textAlign: 'center',
    maxWidth: theme.dimensions.boxWidth,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(15),
    },
  },
  emptyText: {
    display: 'inline-block',
  },
  buttonIcon: {
    fontSize: 23,
  },
  button: {
    padding: theme.spacing(0),
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
  },
}));

const SearchPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const barCodeId = match.params.id;
  const [shouldHavePos] = useContext(PosContext);
  const shellHelper = new CefSharpShell();
  const [user] = useContext(UserContext);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [isPosConnected, setIsPosConnected] = useState<boolean | null>(false);
  const classes = useStyles();
  const history = useHistory();
  const [lang] = useContext(LangContext);
  const { notifyError, notifySuccess } = useNotifier();

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [passengers, setPassengersList] = useState<Passenger[] | null>(null);
  const [selectedPassenger, setSelectedPassenger] = useState<Passenger | null>(
    null
  );
  const [skipPaymentDialogOpen, setSkipPaymentDialogOpen] = useState(false);
  const [sendToSecondSamplingDialogOpen, setSendToSecondSamplingDialogOpen] =
    useState(false);
  const [changeStatusDialogOpen, setChangeStatusDialogOpen] = useState(false);
  const [stepperDialogOpen, setStepperDialogOpen] = useState(false);
  const [allowSecondSampling, setAllowSecondSampling] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [createCertificateDialog, setCreateCertificateDialog] = useState(false);

  useEffect(isSecondSamplingCandidate, [selectedPassenger]);

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  useEffect(() => {
    if (!selectedPassenger && barCodeId) {
      var query = {
        sampleId: barCodeId,
        firstName: '',
        lastName: '',
        flightNumber: '',
        callingCode: null,
        phoneNumber: '',
        email: '',
        ssn: '',
        dateOfBirth: null,
        arrivalsFromDate: null,
        arrivalsToDate: null,
        samplingNumber: '',
      };
      searchForPassengers(query, true);
    } else if (!barCodeId && selectedPassenger) {
      setSelectedPassenger(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barCodeId, selectedPassenger]);

  return (
    <Layout
      useDefaultSpacing
      title={translations.searchTitle[lang]}
      otherButton={
        isAccessible(accessControl.CREATE_CERTIFICATE, user)
          ? {
              label: translations.registerForeignInfection[lang],
              onClick: () => setCreateCertificateDialog(true),
              color: 'secondary',
            }
          : undefined
      }
    >
      <Section>
        <Grid container spacing={3}>
          <Grid item sm={12} md={4}>
            <SearchForm
              onSubmit={searchForPassengersWrapper}
              onClear={clearSearch}
              collapsable
              isSuperSearch={hasUserAccess(accessControl.SUPER_SEARCH, user)}
            />
          </Grid>
          <Grid item sm={12} md={8} style={{ flex: 1 }}>
            {renderResults()}
          </Grid>
        </Grid>
      </Section>
      {renderDialogs()}

      {isAccessible(accessControl.CREATE_CERTIFICATE, user) && (
        <CreateForeignCertificate
          open={createCertificateDialog}
          onSendCertificate={sendForeignCertificateViaEmail}
          onClose={() => setCreateCertificateDialog(false)}
        />
      )}
    </Layout>
  );
  function handleSetPassenger(passenger: Passenger | null) {
    if (passenger === null) {
      // dont push to history unless we were working with barcodeId before
      if (barCodeId) {
        history.push('/search/');
      }
    } else {
      history.push(`/search/${passenger.serialNumber}`);
    }
    setSelectedPassenger(passenger);
  }

  function renderResults() {
    if (loading) {
      return (
        <Grid container justify="center" alignItems="center">
          <LoadingIndicator />
        </Grid>
      );
    }

    if (selectedPassenger) {
      const buttons: IButton[] = [
        {
          variant: 'outlined',
          label: translations.back[lang],
          onClick: () => handleSetPassenger(null),
        },
      ];

      if (
        selectedPassenger.borderControlStatus.id ===
          borderControlStatus.REGISTERED_IN_QUARANTINE ||
        selectedPassenger.borderControlStatus.id ===
          borderControlStatus.REGISTERED_WITH_CERTIFICATE ||
        selectedPassenger.borderControlStatus.id ===
          borderControlStatus.REGISTERED_TO_TRANSIT ||
        selectedPassenger.borderControlStatus.id ===
          borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_NOT_REQUIRED ||
        selectedPassenger.borderControlStatus.id ===
          borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_COMPLETED
      ) {
        buttons.unshift({
          variant: 'outlined',
          label: translations.changeStatus[lang],
          onClick: () => setChangeStatusDialogOpen(true),
        });
      } else if (
        selectedPassenger.borderControlStatus.id ===
        borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_NEEDED
      ) {
        buttons.unshift({
          error: true,
          label: translations.skipPayment[lang],
          onClick: () => setSkipPaymentDialogOpen(true),
        });
        if (isPosConnected) {
          buttons.unshift({
            variant: 'contained',
            label: translations.payForSampling[lang],
            onClick: () => chargeForSampling(selectedPassenger),
          });
        }
      } else if (
        hasUserAccess(accessControl.SECOND_SAMPLING, user) &&
        selectedPassenger.isSecondTestCandidate &&
        allowSecondSampling
      ) {
        buttons.unshift({
          label: translations.sendToSecondSampling[lang],
          onClick: () => setSendToSecondSamplingDialogOpen(true),
        });
      }

      return paymentLoading ? (
        <div className={classes.root}>
          <Grid container spacing={2} className={classes.paper}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h4">
                {translations.paymentDialogText[lang]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <LoadingIndicator />
            </Grid>
          </Grid>
        </div>
      ) : (
        <PassengerInfo
          passenger={selectedPassenger}
          groupedDetails={getPassengerDetails()}
          isPosConnected={isPosConnected}
          buttons={buttons}
          updateInfo={(info, onSuccess) =>
            updatePassengerInfo(selectedPassenger, info, onSuccess)
          }
          linkedSampling={
            selectedPassenger.linkedSampling
              ? {
                  isFirst: selectedPassenger.linkedSampling.isFirst,
                  onClick: () =>
                    selectedPassenger.linkedSampling?.linkedBarcode &&
                    findLinkedPassenger(
                      selectedPassenger.linkedSampling.linkedBarcode
                    ),
                }
              : undefined
          }
        />
      );
    }

    return passengers ? (
      <PassengerList
        passengers={passengers}
        onClick={handleSetPassenger}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        setCurrentPage={setCurrentPage}
        setRowsPerPage={setRowsPerPage}
      />
    ) : null;
  }

  function renderDialogs() {
    return selectedPassenger ? (
      <React.Fragment>
        <ConfirmDialog
          open={skipPaymentDialogOpen}
          title={translations.skipPayment[lang]}
          text={`${translations.skipPaymentConfirmation[lang]}: ${selectedPassenger?.lastName}, ${selectedPassenger?.firstName}?`}
          onConfirm={() => skipPayment(selectedPassenger)}
          onCancel={() => setSkipPaymentDialogOpen(false)}
          confirmText={translations.skipPayment[lang]}
        />
        <ConfirmDialog
          open={sendToSecondSamplingDialogOpen}
          title={translations.sendToSecondSampling[lang]}
          text={`${translations.sendToSecondSamplingText1[lang]} ${selectedPassenger.lastName}, ${selectedPassenger.firstName} ${translations.sendToSecondSamplingText2[lang]}?`}
          onConfirm={() => sendToSecondTest(selectedPassenger)}
          onCancel={() => setSendToSecondSamplingDialogOpen(false)}
          confirmText={translations.sendToSecondSampling[lang]}
          loading={dialogLoading}
        />
        <ChangeStatusDialog
          open={changeStatusDialogOpen}
          passenger={selectedPassenger}
          statusItems={[
            {
              statusIds: [
                borderControlStatus.REGISTERED_FOR_SAMPLING,
                borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_NOT_REQUIRED,
                borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_COMPLETED,
                borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_NEEDED,
              ],
              onConfirm: sendToSampling,
            },
            {
              statusIds: [borderControlStatus.REGISTERED_IN_QUARANTINE],
              onConfirm: sendToQuarantine,
            },
            {
              statusIds: [borderControlStatus.REGISTERED_WITH_CERTIFICATE],
              onConfirm: changeToCertificate,
            },
            {
              statusIds: [borderControlStatus.REGISTERED_TO_TRANSIT],
              onConfirm: changeToTransit,
            },
          ]}
          onCancel={() => setChangeStatusDialogOpen(false)}
        />
        <ConfirmDialog
          open={stepperDialogOpen}
          title={translations.statusInTheProcess[lang]}
          onCancel={() => setStepperDialogOpen(false)}
          cancelText={translations.close[lang]}
        >
          <Stepper
            paper={false}
            flowStatusCompleted={selectedPassenger.flowStatusesCompleted || []}
            barcode={selectedPassenger.serialNumber}
            showUsersChange={true}
          />
        </ConfirmDialog>
      </React.Fragment>
    ) : null;
  }
  async function searchForPassengersWrapper(values: IFormValues) {
    if (barCodeId) {
      history.push('/search/');
    }
    return searchForPassengers(values);
  }

  async function searchForPassengers(
    values: IFormValues,
    searchForSingleResult: boolean = false
  ) {
    setLoading(true);
    setSelectedPassenger(null);
    setCurrentPage(0);
    setIsPosConnected(false);

    const query = new SearchQuery(values);
    const response = await httpService.searchPassenger(query);
    if (response.isOk) {
      if (shouldHavePos) {
        shellHelper.checkPosConnection(setIsPosConnected, notifyError);
      }
      if (searchForSingleResult && response.data && response.data.length > 0) {
        setSelectedPassenger(response.data[0]);
      } else {
        setPassengersList(response.data);
      }
      setLoading(false);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else if (response.statusCode === 413) {
      notifyError(translations.searchTooBig[lang]);
      setLoading(false);
    }
  }

  function isSecondSamplingCandidate() {
    const isAllowed = hasUserAccess(accessControl.SECOND_SAMPLING, user);
    if (selectedPassenger && isAllowed) {
      httpService
        .checkIfSecondTestIsAllowed(selectedPassenger.serialNumber)
        .then((response) => {
          if (response.isOk) {
            if (!isRapidTest) {
              setAllowSecondSampling(response.data);
            }
          } else {
            notifyError(translations.operationFailed[lang]);
          }
        })
        .catch(() => {});
    }
  }

  async function updatePassengerInfo(
    passenger: Passenger,
    info: IUpdatePassengerInfo,
    onSuccess: () => void
  ) {
    const response = await httpService.updatePassengerInfo(
      passenger.serialNumber,
      info
    );

    if (response.isOk) {
      await reloadPassenger(passenger);
      notifySuccess(translations.passengerInfoUpdated[lang]);
      onSuccess();
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function updateRapidTestStatus(
    passenger: Passenger,
    newStatus: number,
    onSuccess: () => void
  ) {
    const response = await httpService.postSamplingResult(
      passenger.serialNumber,
      newStatus
    );

    if (response.isOk) {
      await reloadPassenger(passenger);
      notifySuccess(translations.passengerInfoUpdated[lang]);
      onSuccess();
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function onRevokeCertificate(
    certificate: EuDccCertificate,
    invalidationReason: string,
    onSuccess: () => void
  ) {
    const response = await euDccService.revokeCertificate(
      certificate,
      invalidationReason
    );

    if (response.isOk) {
      //await reloadPassenger(passenger);
      notifySuccess(translations.revokeCertificateSuccess[lang]);
      onSuccess();
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function sendCertificateViaEmail(
    certificateData: ICertificateFormValues,
    onSuccess: () => void
  ) {
    const payload = {
      dateOfBirth: certificateData?.dateOfBirth
        ? certificateData.dateOfBirth.toISOString()
        : '',
      dateOfSample: certificateData?.dateOfSample
        ? certificateData.dateOfSample.toISOString()
        : '',
      dateOfRecovery: certificateData?.dateOfRecovery
        ? certificateData.dateOfRecovery.toISOString()
        : undefined,
      isPcr: certificateData?.isPcr ? certificateData.isPcr : false,
      firstName: certificateData?.firstName
        ? certificateData.firstName
        : undefined,
      lastName: certificateData?.lastName
        ? certificateData.lastName
        : undefined,
      overrideNationalRegistry: certificateData?.overrideNationalRegistry
        ? certificateData.overrideNationalRegistry
        : false,
      ssn: certificateData?.ssn ? certificateData.ssn : undefined,
      observationIdentifier: certificateData?.observationIdentifier
        ? certificateData.observationIdentifier
        : undefined,
      observationOrigin: certificateData?.observationOrigin
        ? certificateData.observationOrigin
        : undefined,
      passportNumber: certificateData?.passportNumber
        ? certificateData.passportNumber
        : undefined,
      email: certificateData.email,
      isPositive: certificateData.isPositive,
    };
    const response = await httpService.sendCustomCertificate(payload);

    if (response.isOk) {
      // await reloadPassenger(passenger);
      notifySuccess(translations.emailSentDialogSuccess[lang]);
      onSuccess();
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function sendForeignCertificateViaEmail(
    certificateData: IForeignCertificateFormValues,
    onSuccess: () => void
  ) {
    const payload = {
      dateOfBirth: certificateData?.dateOfBirth
        ? certificateData.dateOfBirth.toISOString()
        : '',
      dateOfSample: certificateData?.dateOfSample
        ? certificateData.dateOfSample.toISOString()
        : '',

      isPcr: Number(certificateData.typeOfTest) === 2 ? false : true,
      firstName: certificateData.firstName,
      lastName: certificateData.lastName,
      overrideNationalRegistry: false,
      ssn: certificateData?.ssn ? certificateData.ssn : undefined,
      observationIdentifier: certificateData.issueNumber,
      observationOrigin: undefined,
      passportNumber: certificateData?.passportNumber
        ? certificateData.passportNumber
        : undefined,
      email: certificateData.email,
      isPositive: true,
      researchLocation: certificateData.laboratory,
      researchType: certificateData.testingMethodAndDevice,
    };
    const response = await httpService.sendCustomCertificate(payload);

    if (response.isOk) {
      notifySuccess(translations.emailSentDialogSuccess[lang]);
      onSuccess();
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  function clearSearch() {
    setLoading(false);
    setPassengersList(null);
    handleSetPassenger(null);
    setCurrentPage(0);
  }

  async function sendToSampling(passenger: Passenger) {
    try {
      const response = await sendToSamplingUtil(passenger, false);
      if (response?.isOk) {
        notifySuccess(translations.sendToSamplingConfirmation[lang]);
        setChangeStatusDialogOpen(false);
        await reloadPassenger(passenger);
      } else if (response?.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.operationFailed[lang]);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function skipPayment(passenger: Passenger) {
    try {
      const response = await httpService.skipPaymentForPassenger(
        passenger.serialNumber
      );
      if (response.isOk) {
        notifySuccess(translations.paymentSkipped[lang]);
        setSkipPaymentDialogOpen(false);
        await reloadPassenger(passenger);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.operationFailed[lang]);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function reloadPassenger(passenger: Passenger) {
    try {
      const response = await httpService.getPassengerByRegistrationId(
        passenger.serialNumber
      );
      if (response.isOk) {
        setSelectedPassenger(response.data);
        const passengerList = passengers ? [...passengers] : [];
        const passengerIndex = passengerList.findIndex(
          (p) => p.serialNumber === response.data?.serialNumber
        );
        if (passengerIndex > -1 && response.data) {
          passengerList[passengerIndex] = response.data;
          setPassengersList(passengerList);
        }
      } else {
        notifyError(translations.operationFailed[lang]);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function findLinkedPassenger(serialNumber: string) {
    try {
      const response = await httpService.getPassengerByRegistrationId(
        serialNumber
      );
      if (response.isOk) {
        setSelectedPassenger(response.data);
      } else {
        notifyError(translations.operationFailed[lang]);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function sendToQuarantine(passenger: Passenger) {
    try {
      const response = await sendToQuarantineUtil(passenger);
      if (response?.isOk) {
        notifySuccess(translations.passengerSentToQuarantine[lang]);
        setChangeStatusDialogOpen(false);
        await reloadPassenger(passenger);
      } else {
        notifyError(translations.operationFailed[lang]);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function sendToSecondTest(passenger: Passenger) {
    try {
      setDialogLoading(true);
      const response = await httpService.sendToSecondSampling(
        passenger.serialNumber
      );
      if (response.isOk) {
        notifySuccess(translations.sendToSecondSamplingSuccess[lang]);
        setSendToSecondSamplingDialogOpen(false);
        setDialogLoading(false);
        await reloadPassenger(passenger);
      } else {
        setDialogLoading(false);
        notifyError(translations.operationFailed[lang]);
      }
    } catch {
      setDialogLoading(false);
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function changeToTransit(passenger: Passenger) {
    try {
      const response = await httpService.changeToTransit(
        passenger.serialNumber
      );
      if (response.isOk) {
        notifySuccess(translations.passengerSentToTransit[lang]);
        setChangeStatusDialogOpen(false);
        await reloadPassenger(passenger);
      } else if (response?.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.operationFailed[lang]);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  async function changeToCertificate(passenger: Passenger) {
    try {
      const response = await httpService.changeToRegisteredWithCertificate(
        passenger.serialNumber
      );
      if (response.isOk) {
        notifySuccess(translations.passengerSentToCertificate[lang]);
        setChangeStatusDialogOpen(false);
        await reloadPassenger(passenger);
      } else if (response?.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.operationFailed[lang]);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
  }

  function chargeForSampling(passenger: Passenger) {
    setPaymentLoading(true);
    posService.getSamplingPrice().then((response) => {
      if (response.isOk) {
        const amount = response.data;
        shellHelper.chargeCard(
          amount,
          (reply: any) => {
            reply.amountPaid = amount;
            const transaction = new Transaction(reply);
            posService
              .markPassengerCharged(passenger?.serialNumber ?? '', transaction)
              .then(() => {
                reloadPassenger(passenger);
                setPaymentLoading(false);
                notifySuccess(reply.statusText);
              });
          },
          (statusText) => {
            setPaymentLoading(false);
            notifyError(statusText);
          }
        );
      }
    });
  }

  function getPassengerDetails(): IPassengerDetails[][] {
    const personalDetails: IPassengerDetails[] = [
      {
        label: translations.lastName[lang],
        getVal(passenger: Passenger) {
          return passenger.lastName;
        },
      },
      {
        label: translations.firstNames[lang],
        getVal(passenger: Passenger) {
          return passenger.firstName;
        },
      },
      {
        label: translations.dateOfBirth[lang],
        getVal(passenger: Passenger) {
          return passenger.dateOfBirth
            ? moment(passenger.dateOfBirth).format(DATE_FORMAT)
            : translations.notRegistered[lang];
        },
      },
      {
        label: translations.nationality[lang],
        getVal: (passenger: Passenger) => passenger.nationality,
      },
      {
        label: translations.countryOfResidence[lang],
        getVal: (passenger: Passenger) => passenger.countryOfResidence,
      },
    ];

    const stepperButtonVisible = Boolean(
      selectedPassenger &&
        selectedPassenger.flowStatusesCompleted &&
        selectedPassenger.flowStatusesCompleted.length > 0
    );
    const samplingDetails: IPassengerDetails[] = [
      {
        label: translations.statusInTheProcess[lang],
        render(passenger: Passenger) {
          return (
            <>
              <SamplingStatus
                status={passenger.borderControlStatus}
                sampleTaken={passenger.sampleTaken}
              />
              <PaymentButton barCode={passenger.serialNumber} />
              {stepperButtonVisible && (
                <IconButton
                  color="primary"
                  className={classes.button}
                  onClick={() => setStepperDialogOpen(true)}
                >
                  <SearchIcon className={classes.buttonIcon} />
                </IconButton>
              )}
            </>
          );
        },
      },
      {
        label: translations.serialNumber[lang],
        render(passenger: Passenger) {
          return (
            <React.Fragment>
              <Typography className={classes.emptyText} color="textSecondary">
                {passenger.serialNumber}
              </Typography>
              <CopyButton text={passenger.serialNumber} />
              <Print passenger={passenger} />
            </React.Fragment>
          );
        },
      },
      {
        label: translations.samplingNumber[lang],
        getVal(passenger: Passenger) {
          return passenger.sampleId || translations.samplingNumberMissing[lang];
        },
      },
      {
        label: translations.PCRStatus[lang],
        render: (passenger: Passenger) => (
          <PreScreeningStatus status={passenger.preScreeningStatus} />
        ),
        hide: (passenger: Passenger) => !showPCRStatus(user, passenger),
      },
      {
        label: translations.countryOfDeparture[lang],
        getVal(passenger: Passenger) {
          return (
            passenger.countryOfDeparture || translations.notRegistered[lang]
          );
        },
      },
      {
        label: translations.departureFromIceland[lang],
        getVal(passenger: Passenger) {
          return passenger.departureFromIceland
            ? moment(passenger.departureFromIceland).format(DATE_FORMAT)
            : translations.notRegistered[lang];
        },
      },
      {
        label: translations.arrivalDate[lang],
        getVal(passenger: Passenger) {
          return moment(passenger.dateOfArrival).format(DATE_FORMAT);
        },
      },
      {
        label: translations.icelandRelationsShort[lang],
        getVal: (passenger: Passenger) =>
          passenger.extraSamplingReqired
            ? translations.yes[lang]
            : translations.no[lang],
      },
      {
        label: translations.samplingAtTheAirport[lang],
        getVal: (passenger: Passenger) =>
          passenger.extraSamplingSelectedTestLocation === 0
            ? translations.yes[lang]
            : translations.no[lang],
      },
    ];

    if (hasUserAccess(accessControl.SUPER_SEARCH, user)) {
      personalDetails.splice(3, 0, {
        label: translations.phoneNumber[lang],
        getVal: getPhoneNumberString,
      });
      if (
        selectedPassenger &&
        selectedPassenger.sampleTakenLocation &&
        selectedPassenger.borderControlStatus.id ===
          borderControlStatus.SAMPLING_COMPLETED
      ) {
        samplingDetails.splice(1, 0, {
          label: translations.samplingLocation[lang],
          getVal: (passenger: Passenger) => passenger.sampleTakenLocation || '',
        });
      }
      if (
        selectedPassenger &&
        (selectedPassenger.observationStatus ||
          isRapidTest(selectedPassenger?.sampleId)) &&
        !hasUserAccess(accessControl.CREATE_CERTIFICATE, user)
      ) {
        samplingDetails.splice(
          selectedPassenger.borderControlStatus.id ===
            borderControlStatus.SAMPLING_COMPLETED
            ? 2
            : 1,
          0,
          {
            label: translations.result[lang],
            render(passenger: Passenger) {
              return (
                <React.Fragment>
                  <SendResultEmail
                    passenger={passenger}
                    updateRapidTestStatus={updateRapidTestStatus}
                  />
                </React.Fragment>
              );
            },
          }
        );
      }
      if (hasUserAccess(accessControl.CREATE_CERTIFICATE, user)) {
        samplingDetails.splice(2, 0, {
          label: translations.result[lang],
          render(passenger: Passenger) {
            return (
              <React.Fragment>
                <CreateCertificate
                  passenger={passenger}
                  onSendCertificate={sendCertificateViaEmail}
                  updateRapidTestStatus={updateRapidTestStatus}
                  onRevokeCertificate={onRevokeCertificate}
                />
              </React.Fragment>
            );
          },
        });
      }
    }

    const residences: IPassengerDetails[] = [
      {
        label: translations.residences[lang],
        render: (passenger: Passenger) => (
          <ResidenceInfo
            serialNumber={passenger.serialNumber}
            residences={passenger.residences}
            reloadPassenger={() => reloadPassenger(passenger)}
          />
        ),
        hide: (passenger: Passenger) =>
          passenger.borderControlStatus.id ===
          borderControlStatus.REGISTERED_FOR_SYMPTOM_SAMPLING,
      },
    ];

    return [personalDetails, samplingDetails, residences];
  }
};

export default SearchPage;
