export const USER = 'user';
export const LANGUAGE = 'language';
export const LOCATION = 'location';
export const LOCATION_DESCRIPTION = 'locationDescription';
export const LOCATION_OPTIONS = 'locationOptions';
export const LOGIN_TYPE = 'loginType';
export const VACCINE_ID = 'vaccineId';
export const INFLUENZA_VACCINE_ID = 'influenzaVaccineId';
export const SCHEDULE_LOCATION = 'scheduleLocation';
export const TYPE_OF_VACCINATION = 'typeOfVaccination';
export const GROUP_MEMBER_FILTER_STATUS = 'groupMemberFilterStatus';
export const GROUP_MEMBER_FILTER_VACCINES = 'groupMemberFilterVaccines';
export const GROUP_MEMBER_FILTER_COMMENTS = 'groupMemberFilterComments';
export const GROUP_MEMBER_FILTER_FROM = 'groupMemberFilterFrom';
export const GROUP_MEMBER_FILTER_TO = 'groupMemberFilterTo';
export const GROUP_MEMBER_FILTER_LOCATIONS = 'groupMemberFilterLocations';
export const VACCINATION_LOCATION_ID = 'vaccinationLocationId';
export const FIRST_OR_SECOND_VACCINATION = 'firstOrSecondVaccination';
export const ALLOW_MORE_THAN_TWO_VACCINATIONS = 'allowMoreThanTwoVaccinations';
export const VACCINATION_GROUP_FILTER_SEARCH = 'FilterSearch';
export const VACCINATION_GROUP_FILTER_REGION = 'FilterRegion';
export const GROUP_VACCINATION_FILTER_KEY = 'groupVaccination';
export const VACCINATION_GROUPS_FILTER_KEY = 'vaccinationGroups';
export const VACCINATION_CONDITIONS_YEAR_FROM = 'vaccinationConditionsYearFrom';
export const VACCINATION_CONDITIONS_YEAR_TO = 'vaccinationConditionsYearTo';
export const VACCINATION_CONDITIONS_SUMMONING_FROM =
  'vaccinationConditionsSummoningFrom';
export const VACCINATION_CONDITIONS_SUMMONING_TO =
  'vaccinationConditionsSummoningTo';
export const VACCINATION_CONDITIONS_GROUPS = 'vaccinationConditionsGroups';
export const VACCINATION_CONDITIONS_ALWAYS_ALLOW_SECOND =
  'vaccinationConditionsAlwaysAllowSecond';
export const VACCINATION_REQUEST_FILTER_SEARCH =
  'VaccinationRequestFilterSearch';
export const VACCINATION_REQUEST_FILTER_REGION =
  'VaccinationRequestFilterRegion';
export const VACCINATION_REQUEST_FILTER_NUMBER_OF_VACCINATIONS =
  'VaccinationRequestFilterNumberOfVaccinations';
export const VACCINATION_REQUEST_FILTER_PREGNANT =
  'VaccinationRequestFilterPregnant';
export const VACCINATION_REQUEST_FILTER_ACCUTE_ALLERGY =
  'VaccinationRequestFilteAccuteAllergy';
export const VACCINATION_REQUEST_FILTER_USING_MEDICATION =
  'VaccinationRequestFilterUsingMedication';
export const CERTIFICATE_REVIEW_FILTER_FORM = 'CertificateReviewFilterForm';
export const CERTIFICATE_REVIEW_PAGE_SIZE = 'CertificateReviewPageSize';
export const CERTIFICATE_REVIEW_PAGE_NUMBER = 'CertificateReviewPageNumber';
export const SCHEDULE_LOCATION_ID = 'ScheduleLocationId';
