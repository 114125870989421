import React from 'react';
import { Moment } from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { DATE_FORMAT } from '../constants';

interface IProps {
  id: string;
  value: Moment | null;
  label: string;
  onChange: (val: Moment | null) => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  muiProps?: object;
  inputProps?: object;
  required?: boolean;
  autoFocus?: boolean;
  error?: boolean;
  disabled?: boolean;
}

const DatePicker: React.FC<IProps> = ({
  id,
  value,
  label,
  onChange,
  onBlur,
  muiProps,
  inputProps,
  required,
  autoFocus,
  error,
  disabled,
}) => {
  return (
    <KeyboardDatePicker
      required={required}
      autoFocus={autoFocus}
      autoOk
      fullWidth
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      autoComplete="off"
      variant="inline"
      inputVariant="outlined"
      error={error}
      disabled={disabled}
      format={DATE_FORMAT}
      invalidDateMessage={null}
      minDateMessage={null}
      maxDateMessage={null}
      {...muiProps}
      {...inputProps}
      PopoverProps={{
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
    />
  );
};

export default DatePicker;
