import React, { useContext, useState } from 'react';
import { useTheme } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import Button from './Button';
import ConfirmDialog from './ConfirmDialog';
import { LangContext } from '../context/LangContext';
import { Passenger } from '../models/Models';
import translations from '../assets/json/translations.json';

interface IProps {
  passenger: Passenger;
  setQuarantineHouseStatus: (
    registered: boolean,
    onSuccess: () => void
  ) => Promise<void>;
}

const SendToQuarantineHouse: React.FC<IProps> = ({
  passenger,
  setQuarantineHouseStatus,
}) => {
  const [lang] = useContext(LangContext);
  const theme = useTheme();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const registered = passenger.requireQuarantineResidency;

  return (
    <React.Fragment>
      <Button
        padding={false}
        disableElevation
        variant={registered ? 'contained' : 'outlined'}
        endIcon={registered ? <CheckBoxIcon /> : <CheckBoxOutlineIcon />}
        onClick={() => setConfirmDialogOpen(true)}
        style={
          registered
            ? {
                backgroundColor: theme.palette.warning.light,
                color: 'black',
              }
            : undefined
        }
      >
        {translations.quarantineHotel[lang]}
      </Button>
      <ConfirmDialog
        open={confirmDialogOpen}
        title={
          registered
            ? translations.removeFromQuarantineHouse[lang]
            : translations.sendToQuarantineHouse[lang]
        }
        text={
          registered
            ? translations.removeFromQuarantineHouseDialog[lang]
            : translations.sendToQuarantineHouseDialog[lang]
        }
        loading={loading}
        onConfirm={onSubmit}
        onCancel={() => setConfirmDialogOpen(false)}
      />
    </React.Fragment>
  );

  async function onSubmit() {
    setLoading(true);
    await setQuarantineHouseStatus(!registered, () =>
      setConfirmDialogOpen(false)
    );
    setLoading(false);
  }
};

export default SendToQuarantineHouse;
