import { borderControlStatus } from './enums';

export const DEFAULT_PALETTE_PRIMARY = '#006dc2';
export const DEFAULT_PALETTE_SECONDARY = '#ff7a4c';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_AND_TIME_FORMAT = 'DD.MM.YYYY - HH:mm';
export const TABLE_ROWS_OPTIONS = [10, 25, 50, 100];
export const TABLE_ROWS_DEFAULT = 25;

export const LOCATION_OPTIONS = [
  { label: 'Keflavík', value: 'KEF', id: '1' },
  { label: 'Reykjavík', value: 'REY', id: '2' },
  { label: 'Akureyri', value: 'AEY', id: '3' },
  { label: 'Egilsstaðir', value: 'EGI', id: '4' },
  { label: 'Seyðisfjörður', value: 'SEY', id: '5' },
  { label: 'Ísafjörður', value: 'ÍSA', id: '6' },
  { label: 'Vestmannaeyjar', value: 'VES', id: '7' },
  { label: 'Selfoss', value: 'SEL', id: '8' },
  { label: 'Stykkishólmur', value: 'STY', id: '9' },
  { label: 'Borgarnes', value: 'BOR', id: '10' },
  { label: 'Orkuhúsið', value: 'ORK', id: '11' },
  { label: 'Suðurnes', value: 'SUÐ', id: '12' },
  { label: 'Höfn', value: 'HÖF', id: '13' },
  { label: 'Akranes', value: 'AKR', id: '14' },
];

export const TEST_LOCATIONS: {
  key: string;
  translationKey: string;
}[] = [
  { key: '2', translationKey: 'Suðurlandsbraut' },
  { key: '3', translationKey: 'Akureyri' },
  { key: '4', translationKey: 'Suðurnes' },
  { key: '5', translationKey: 'Ísafjörður' },
  { key: '6', translationKey: 'Patreksfjörður' },
  { key: '7', translationKey: 'Selfoss' },
  { key: '8', translationKey: 'Höfn' },
  { key: '9', translationKey: 'Vestmannaeyjar' },
  { key: '10', translationKey: 'Akranes' },
  { key: '11', translationKey: 'Borgarnes' },
  { key: '12', translationKey: 'Sauðárkrókur' },
  { key: '13', translationKey: 'Húsavík' },
  { key: '15', translationKey: 'Austurland' },
  { key: '18', translationKey: 'Flúðir' },
  { key: '19', translationKey: 'Landspítalinn' },
];

export const SEXES: {
  key: string;
  translationKey: 'male' | 'female' | 'other';
}[] = [
  { key: 'male', translationKey: 'male' },
  { key: 'female', translationKey: 'female' },
  { key: 'other', translationKey: 'other' },
];

export const PRIORITY: {
  key: string;
  value: string;
}[] = [
  { key: '1', value: 'Starfsfólk bráðamóttöku og gjörgæslu' },
  {
    key: '2',
    value:
      'Starfsfólk covid deilda sjúkrahúsa, starfsfólk í sýnatöku, og starfsfólk hjúkrunar- og dvalarheimila',
  },
  { key: '3', value: 'Einstaklingar á hjúkrunarheimilum' },
  {
    key: '4',
    value:
      'Sjúkraflutningamenn, útkallslögregla, slökkviliðið, landhelgisgæsla og fangaverðir',
  },
  { key: '5', value: 'Allir heilbrigðisstarfsmenn sem sinna klínísku starfi' },
  { key: '6', value: 'Almenningur > 60 ára' },
  { key: '7', value: 'Einstaklingar með undirliggjandi sjúkdóm' },
  {
    key: '8',
    value:
      'Starfsmenn leik-, grunn- og framhaldsskóla + starfsmenn félag- og velferðarþjonustu.',
  },
  { key: '9', value: 'Einstaklingar í viðkvæmri félagslegri stöðu' },
  { key: '10', value: 'Allur almenningur' },
];

export const GROUP_COLUMNS: {
  key: string;
  translationKey: 'kennitala' | 'name' | 'phoneNumber' | 'foundInNatReg';
}[] = [
  { key: 'ssn', translationKey: 'kennitala' },
  { key: 'name', translationKey: 'name' },
  { key: 'phoneNumber', translationKey: 'phoneNumber' },
  { key: 'verified', translationKey: 'foundInNatReg' },
];

export const PASSENGER_STATUSES: {
  key: string;
  translationKey:
    | 'pcrTest'
    | 'quarantine'
    | 'certificate'
    | 'transit'
    | 'symptomSampling'
    | '5daysPcrTest'
    | 'rapidTest';
  translationDialogKey:
    | 'registerPassengerInPcr'
    | 'registerPassengerInQuarantine'
    | 'registerPassengerInCertificate'
    | 'registerPassengerInTransit'
    | 'registerPassengerInSymptomSampling'
    | 'registerPassengerIn5daysPcr'
    | 'registerPassengerInRapidTest';
}[] = [
  {
    key: borderControlStatus.REGISTERED_FOR_SYMPTOM_SAMPLING.toString(),
    translationKey: 'symptomSampling',
    translationDialogKey: 'registerPassengerInSymptomSampling',
  },
  {
    key: borderControlStatus.REGISTERED_FOR_RAPID_TEST.toString(),
    translationKey: 'rapidTest',
    translationDialogKey: 'registerPassengerInRapidTest',
  },
  {
    key: borderControlStatus.REGISTERED_FOR_5DAYS_SAMPLING.toString(),
    translationKey: '5daysPcrTest',
    translationDialogKey: 'registerPassengerIn5daysPcr',
  },
  {
    key: borderControlStatus.REGISTERED_FOR_SAMPLING.toString(),
    translationKey: 'pcrTest',
    translationDialogKey: 'registerPassengerInPcr',
  },
  {
    key: borderControlStatus.REGISTERED_IN_QUARANTINE.toString(),
    translationKey: 'quarantine',
    translationDialogKey: 'registerPassengerInQuarantine',
  },
  {
    key: borderControlStatus.REGISTERED_WITH_CERTIFICATE.toString(),
    translationKey: 'certificate',
    translationDialogKey: 'registerPassengerInCertificate',
  },
  {
    key: borderControlStatus.REGISTERED_TO_TRANSIT.toString(),
    translationKey: 'transit',
    translationDialogKey: 'registerPassengerInTransit',
  },
];

export const OTHER_COMMENT_STATUS_ID = 'other';
export const OTHER_COMMENT_STATUS_TEXT = 'Annað';

export const RISK_COUNTRIES_LIST_ITEM_HEIGHT = 48;
export const RISK_COUNTRIES_LIST_HEIGHT = 415;
