import React, { useContext, useState, useEffect } from 'react';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import moment, { Moment } from 'moment';

import DatePicker from './DatePicker';
import Dropdown from './Dropdown';
import LoadingIndicator from './LoadingIndicator';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import * as httpService from '../services/httpService';
import { SamplingSchedule } from '../models/Models';
import useNotifier from '../hooks/useNotifier';
import * as localStorageKeys from '../constants/localStorageKeys';

interface IProps {
  open: boolean;
  locationId?: number;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  dropdownContainer: {
    padding: theme.spacing(0, 2, 1, 2),
  },
  datePickerContainer: {
    padding: theme.spacing(1, 2, 2, 2),
  },
  loadingContainer: {
    height: '100%',
    minHeight: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  table: {
    '& th': {
      fontWeight: 600,
      fontSize: 15,
      paddingTop: 0,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  totalContainer: {
    marginTop: theme.spacing(2),
  },
}));

const ScheduleDialog: React.FC<IProps> = ({ open, locationId, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [lang] = useContext(LangContext);
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const { notifyError } = useNotifier();

  const [scheduleLocations, setScheduleLocations] = useState<Array<{
    value: string;
    label: string;
  }> | null>(null);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [schedule, setSchedule] = useState<SamplingSchedule | null>(null);
  const [scheduleDate, setScheduleDate] = useState<Moment | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSchedule = async () => {
      setLoading(true);
      if (scheduleDate && scheduleDate.isValid() && selectedLocation) {
        const res = await httpService.getSymptomSamplingSchedule(
          scheduleDate.toISOString(),
          selectedLocation
        );
        setSchedule(res.isOk ? res.data : null);
        !res.isOk && notifyError(translations.scheduleError[lang]);
      }
      setLoading(false);
    };
    if (scheduleLocations) {
      fetchSchedule();
    }

    /* eslint-disable */
  }, [scheduleDate, selectedLocation]);

  useEffect(() => {
    if (open) {
      const fetchScheduleLocations = async () => {
        setLoading(true);

        const res = await httpService.getSymptomSamplingScheduleLocations();
        setScheduleLocations(res.isOk ? res.data : null);
        !res.isOk && notifyError(translations.scheduleError[lang]);

        setLoading(false);
      };
      fetchScheduleLocations();
      if (locationId) {
        setSelectedLocation(locationId.toString() || '');
        setTimeout(() => setScheduleDate(moment()), 100);
      } else {
        const savedLocation = localStorage.getItem(
          localStorageKeys.SCHEDULE_LOCATION
        );
        setSelectedLocation(savedLocation || '');
        setTimeout(() => setScheduleDate(moment()), 100);
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={xsDown}
    >
      <DialogTitle>{translations.schedule[lang]}</DialogTitle>
      {scheduleLocations && (
        <div className={classes.dropdownContainer}>
          <Dropdown
            fullWidth
            value={selectedLocation}
            label={translations.location[lang]}
            items={scheduleLocations}
            onChange={handleLocationChange}
          />
        </div>
      )}
      <div className={classes.datePickerContainer}>
        <DatePicker
          id="scheduleDate"
          label={translations.date[lang]}
          value={scheduleDate}
          onChange={(e) => setScheduleDate(e ? moment(e) : null)}
        />
      </div>

      <DialogContent dividers style={{ minHeight: 80 }}>
        {renderResults()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {translations.close[lang]}
        </Button>
      </DialogActions>
    </Dialog>
  );

  function renderResults() {
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <LoadingIndicator />
        </div>
      );
    }

    if (!schedule || !schedule.timeSlots.length) {
      return (
        <Typography color="textSecondary">
          {translations.noSchedule[lang]}
        </Typography>
      );
    }

    let registeredTotal = 0;
    let finishedTotal = 0;
    schedule.timeSlots.forEach((slot) => {
      registeredTotal += slot.totalNumberOfArrivals;
      finishedTotal += slot.finishedSampling;
    });

    return (
      <React.Fragment>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.time[lang]}</TableCell>
                <TableCell align="center">
                  {translations.registered[lang]}
                </TableCell>
                <TableCell align="center">
                  {translations.finished[lang]}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schedule.timeSlots.map((slot) => (
                <TableRow key={slot.timeSlot}>
                  <TableCell>{slot.timeSlot}</TableCell>
                  <TableCell align="center">
                    {slot.totalNumberOfArrivals}
                  </TableCell>
                  <TableCell align="center">{slot.finishedSampling}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div className={classes.totalContainer}>
          <Typography color="textSecondary">
            {translations.registeredTotal[lang]}: {registeredTotal}
          </Typography>
          <Typography color="textSecondary">
            {translations.finishedTotal[lang]}: {finishedTotal}
          </Typography>
        </div>
      </React.Fragment>
    );
  }
  function handleLocationChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    setSelectedLocation(event.target.value as string);
    localStorage.setItem(
      localStorageKeys.SCHEDULE_LOCATION,
      event.target.value as string
    );
  }
};

export default ScheduleDialog;
