import React, { useRef, useContext } from 'react';
import { makeStyles, Grid, Paper, TextField } from '@material-ui/core';
import { Formik, Form, FormikProps } from 'formik';
import moment, { Moment } from 'moment';

import Button from './Button';
import DatePicker from './DatePicker';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { handleSsnInput } from '../utils';

interface IProps {
  onSubmit: (values: IFormValues) => void;
  onClear: () => void;
}

export interface IFormValues {
  serialNumber: string;
  name: string;
  ssn: string;
  dateOfBirth: Moment | null;
}

type IFormik = FormikProps<IFormValues>;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}));

const VaccinationSearchForm: React.FC<IProps> = ({ onSubmit, onClear }) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);
  const serialNumberInput = useRef<HTMLInputElement>(null);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        serialNumber: '',
        name: '',
        ssn: '',
        dateOfBirth: null as Moment | null,
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Paper elevation={2} className={classes.paper}>
            <Grid container spacing={2}>
              {renderFormFields(formik)}
              {renderButtons(formik)}
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );

  function renderFormFields({
    values,
    handleChange,
    handleBlur,
    setFieldValue,
  }: IFormik) {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoFocus
            autoComplete="off"
            id="serialNumber"
            label={translations.serialNumber[lang]}
            variant="outlined"
            inputProps={{ ref: serialNumberInput }}
            value={values.serialNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoComplete="off"
            id="name"
            label={translations.name[lang]}
            variant="outlined"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoComplete="off"
            id="ssn"
            label={translations.SSN[lang]}
            variant="outlined"
            value={values.ssn}
            onChange={(e) => handleSsnInput(e, handleChange)}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            id="dateOfBirth"
            label={translations.dateOfBirth[lang]}
            value={values.dateOfBirth}
            muiProps={{ disableFuture: true }}
            onChange={(e) => setFieldValue('dateOfBirth', e ? moment(e) : null)}
            onBlur={handleBlur}
          />
        </Grid>
      </React.Fragment>
    );
  }

  function renderButtons({ handleReset, isSubmitting, dirty }: IFormik) {
    const clearForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleReset(e);
      focusSerialNumberInput();
      onClear();
    };

    return (
      <React.Fragment>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            disabled={isSubmitting || !dirty}
          >
            {translations.search[lang]}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button fullWidth variant="outlined" onClick={clearForm}>
            {translations.clear[lang]}
          </Button>
        </Grid>
      </React.Fragment>
    );
  }

  function focusSerialNumberInput() {
    if (serialNumberInput && serialNumberInput.current) {
      serialNumberInput.current.focus();
    }
  }
};

export default VaccinationSearchForm;
