import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Layout from '../components/Layout';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import useNotifier from '../hooks/useNotifier';
import {
  CheckInModel,
  ExcelResidencePerson,
  ResidencePerson,
} from '../models/Models';
import { parseTemplateString, toISOStringTimezone } from '../utils';
import Section from '../components/Section';
import QuarantinePassengerListFilter, {
  IFormValues,
} from '../components/QuarantinePassengerListFilter';
import QuarantinePassengersFilter from '../components/QuarantinePassengersFilter';
import { TABLE_ROWS_DEFAULT } from '../constants';
import {
  getResidenceListBySearch,
  checkInResidences,
  getResidenceLocations,
} from '../services/httpService';
import { IGetResidenceListBySearchQuery } from '../interfaces/passenger';
import QuarantinePassengerList from '../components/QuarantinePassengerList';
import Button from '../components/Button';
import theme from '../theme';
import moment, { Moment } from 'moment';
import LoadingIndicator from '../components/LoadingIndicator';
import CheckinPassengersDialog, {
  IFormDialogValues,
} from '../components/CheckinPassengersDialog';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { IResidenceLocation } from '../interfaces/residenceLocation';

const QuarantinePassengerListPage: React.FC<
  RouteComponentProps<{ locationId: string }>
> = ({ match }) => {
  const { notifyError, notifySuccess } = useNotifier();
  const [lang] = useContext(LangContext);
  const history = useHistory();

  const [filteredPassengers, setFilteredPassengers] = useState<
    ResidencePerson[] | null
  >(null);
  const [allPassengers, setAllPassengers] = useState<ResidencePerson[] | null>(
    null
  );
  const [checkedGroupMembers, setCheckedGroupMembers] = useState<
    ResidencePerson[] | null
  >(null);

  const [membersCheckbox, setMembersCheckbox] = useState<number[]>([]);
  const [, setSortedMembers] = useState<ResidencePerson[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [currentPage, setCurrentPage] = useState(0);
  const [tableRows, setTableRows] = useState(new Array<HTMLTableRowElement>());
  const [fetchPersonsOpen, setFetchPersonsOpen] = useState(false);
  const [filterPersonsOpen, setFilterPersonsOpen] = useState(false);
  const [loadingPassengers, setLoadingPassengers] = useState(true);
  const [loadingcheckin, setLoadingCheckin] = useState(false);
  const [checkinDialogOpen, setCheckinDialogOpen] = useState(false);
  const [location, setLocation] = useState<IResidenceLocation | null>(null);

  const [
    submittedSearchQuery,
    setSubmittedSearchQuery,
  ] = useState<IFormValues | null>(null);

  const tableColumns: {
    [key: string]: {
      translation: { is: string; en: string };
      sort?: (a: ResidencePerson, b: ResidencePerson) => number;
    };
  } = {
    name: {
      translation: translations.name,
      sort: (a: ResidencePerson, b: ResidencePerson) =>
        a.name.localeCompare(b.name),
    },
    ssn: {
      translation: translations.dateOfBirth,
    },
    nationality: {
      translation: translations.nationality,
      sort: (a: ResidencePerson, b: ResidencePerson) =>
        a.nationality.localeCompare(b.nationality),
    },
    countryOfDeparture: {
      translation: translations.countryOfDeparture,
      sort: (a: ResidencePerson, b: ResidencePerson) =>
        a.countryOfDeparture.localeCompare(b.countryOfDeparture),
    },
    flightNumber: {
      translation: translations.flightNumber,
      sort: (a: ResidencePerson, b: ResidencePerson) =>
        a.flightNumber.localeCompare(b.flightNumber),
    },
    arrivalDate: {
      translation: translations.arrivalDate,
      sort: (a: ResidencePerson, b: ResidencePerson) => {
        if (!a.dateOfArrival) {
          return -1;
        }
        if (!b.dateOfArrival) {
          return 1;
        }
        return moment(a.dateOfArrival).isAfter(b.dateOfArrival) ? 1 : -1;
      },
    },
  };

  useEffect(() => {
    const values: IFormValues = {
      arrivalsFrom: moment() as Moment | null,
      arrivalsTo: moment() as Moment | null,
    };
    onSearch(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchLocations = async () => {
      const locationPromise = getResidenceLocations();

      const [locationResponse] = await Promise.all([locationPromise]);

      if (locationResponse.isOk && locationResponse.data) {
        const selectedLocation = locationResponse.data.find(
          (item) => item.id.toString() === match.params.locationId
        );
        if (selectedLocation) {
          setLocation(selectedLocation);
        }
      } else {
        setLocation(null);
      }
    };
    fetchLocations();
  }, [match.params.locationId]);

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  return (
    <Layout
      useDefaultSpacing
      title={translations.passengerList[lang]}
      backButton
      backButtonClick={() => history.push('/quarantine-house')}
    >
      <Section>
        {renderButtons()}

        {renderAccordion()}
        {filteredPassengers && !loadingPassengers && (
          <QuarantinePassengerList
            members={filteredPassengers}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={setRowsPerPage}
            onChangeSelectedMembers={onChangeSelectedMembers}
            membersCheckbox={membersCheckbox}
            setMembersCheckbox={setMembersCheckbox}
            onSetSortedMembers={setSortedMembers}
            tableColumns={tableColumns}
            tableColumnsKeys={Object.keys(tableColumns).map(
              (columnKey) => columnKey
            )}
          />
        )}
        {loadingPassengers && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: theme.spacing(2) }}
          >
            <LoadingIndicator />
          </Grid>
        )}
      </Section>
      {checkedGroupMembers && checkedGroupMembers.length > 0 && renderDialog()}
    </Layout>
  );

  function renderButtons() {
    if (allPassengers && allPassengers.length > 0 && checkedGroupMembers) {
      return (
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}
          style={{ marginBottom: theme.spacing(2) }}
        >
          <Grid item>
            <Button
              padding={false}
              color="primary"
              onClick={() => {
                setCheckinDialogOpen(true);
              }}
            >
              {parseTemplateString(
                translations.checkinPartialpersonsFromList[lang],
                checkedGroupMembers.length.toString(),
                allPassengers.length.toString()
              )}
            </Button>

            <Button
              padding={false}
              color="primary"
              variant="outlined"
              onClick={() => {
                copyTable();
              }}
              style={{ marginLeft: theme.spacing(2) }}
              startIcon={<FileCopyIcon>send</FileCopyIcon>}
            >
              {parseTemplateString(
                translations.copyPartialpersonsFromList[lang],
                checkedGroupMembers.length.toString(),
                allPassengers.length.toString()
              )}
            </Button>
          </Grid>
        </Grid>
      );
    }
  }

  function renderAccordion() {
    return (
      <div>
        <Accordion
          expanded={fetchPersonsOpen}
          onChange={(event, expanded) => setFetchPersonsOpen(expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              {translations.selectArrivalDate[lang]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <QuarantinePassengerListFilter
              onSubmit={onSearch}
              onClear={clear}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={!allPassengers || allPassengers.length === 0}
          expanded={filterPersonsOpen}
          onChange={(event, expanded) => setFilterPersonsOpen(expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              {translations.selectPassengersFromList[lang]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {allPassengers && allPassengers.length > 0 && (
              <QuarantinePassengersFilter
                passengers={allPassengers || []}
                filterPassengers={setFilteredPassengers}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }

  function renderDialog() {
    return (
      <CheckinPassengersDialog
        open={checkinDialogOpen}
        title={
          (location && `${translations.checkInAt[lang]} ${location.name}`) ||
          translations.checkIn[lang]
        }
        onConfirm={onCheckinPassengers}
        confirmText={translations.checkInSubmit[lang]}
        onCancel={() => setCheckinDialogOpen(false)}
        loading={loadingcheckin}
        text={translations.confirmCheckinOfPassengers[lang]}
        checkedGroupMembers={checkedGroupMembers || []}
      ></CheckinPassengersDialog>
    );
  }

  async function onSearch(values: IFormValues) {
    setLoadingPassengers(true);
    setSubmittedSearchQuery(values);
    if (values.arrivalsFrom && values.arrivalsTo) {
      const query: IGetResidenceListBySearchQuery = {
        fromDate: toISOStringTimezone(values.arrivalsFrom),
        toDate: toISOStringTimezone(values.arrivalsTo),
        includeExported: true,
        includeDeleted: false,
      };
      const response = await getResidenceListBySearch(query);
      if (response.isOk) {
        setLoadingPassengers(false);
        if (response.data) {
          setFilteredPassengers(response.data);
          setAllPassengers(response.data);
          setFetchPersonsOpen(false);
          setFilterPersonsOpen(true);
        } else {
          setFilteredPassengers([]);
          setAllPassengers([]);
          setFetchPersonsOpen(false);
        }
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
        setLoadingPassengers(true);
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    }
  }

  async function onCheckinPassengers(values: IFormDialogValues) {
    setLoadingCheckin(true);
    const response = await checkInResidences(
      match.params.locationId,
      values.hotelRoom,
      values.passengers.map((item) => new CheckInModel(item))
    );
    if (response.isOk) {
      notifySuccess('success');
      setLoadingCheckin(false);
      setCheckinDialogOpen(false);
      if (submittedSearchQuery) {
        onSearch(submittedSearchQuery);
        setCheckedGroupMembers(null);
        setMembersCheckbox([]);
      }
    } else {
      setLoadingCheckin(false);
      if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    }
  }

  function createRow(person: ResidencePerson) {
    const tr = document.createElement('tr');
    const excelPerson = new ExcelResidencePerson(person);
    const obj = { ...excelPerson } as any;
    Object.keys(obj).forEach((key) => {
      const td = document.createElement('td');
      td.innerText = obj[key]?.toString();
      tr.appendChild(td);
    });
    tableRows.push(tr);
  }

  function copyTable() {
    setTableRows(new Array<HTMLTableRowElement>());
    if (checkedGroupMembers && checkedGroupMembers?.length > 0) {
      checkedGroupMembers?.forEach(createRow);
    } else {
      filteredPassengers?.forEach(createRow);
    }
    const table = document.createElement('table');
    table.style.fontSize = '11pt';
    const body = document.createElement('body');
    tableRows?.forEach((row) => body.appendChild(row));
    table.appendChild(body);

    //Add table to DOM before copy
    document.body.appendChild(table);

    let range = document.createRange();
    let sel = window.getSelection();
    try {
      sel?.removeAllRanges();
      range.selectNodeContents(table);
      sel?.addRange(range);
      document.execCommand('copy');
      document.body.removeChild(table);
      notifySuccess(translations.listCopied[lang]);
    } catch (e) {
      notifyError(e);
    }
  }

  function onChangeSelectedMembers(memberlist: ResidencePerson[] | null) {
    setCheckedGroupMembers(memberlist);
  }

  function clear() {
    setFilteredPassengers(null);
    setAllPassengers(null);
    setSortedMembers([]);
    setCheckedGroupMembers(null);
    setMembersCheckbox([]);
  }
};
export default QuarantinePassengerListPage;
