import React, { useContext, useState, useEffect, useCallback } from 'react';
import { AppBar, Tabs, Tab, Typography, Box, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import useNotifier from '../hooks/useNotifier';
import Section from '../components/Section';
import UserForm, { IFormValues } from '../components/UserForm';
//import UserFormAccessRestricted from '../components/UserFormAccessRestricted'; to be used when we want to restrict locations on users
import { getAllUsers } from '../services/httpService';
import { UserItem } from '../models/Models';
import UserList from '../components/UserList';
import { deleteSelectedUser } from '../services/httpService';
import { FormikHelpers } from 'formik';
import { CreateUser } from '../models/Models';
import { createUser, editUser } from '../services/httpService';
import Layout from '../components/Layout';
import { accessListType } from '../constants/enums';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const UserControlPage: React.FC = () => {
  const history = useHistory();
  //const [user, setUser] = useState<IFormValues | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [users, setUsers] = useState<UserItem[] | null>(null);
  const [editSelectedUser, setEditSelectUser] = useState<UserItem | null>(null);
  const { notifyError, notifySuccess } = useNotifier();
  const getUsers = useCallback(() => {
    getAllUsers()
      .then((response) => {
        if (response.isOk) {
          setUsers(response.data);
        } else if (response.statusCode === 401) {
          history.push('/login');
        }
      })
      .catch((e) => {
        setUsers([]);
      });
  }, [history]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    setEditSelectUser(null);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={0}>{children}</Box>}
      </Typography>
    );
  }

  const [lang] = useContext(LangContext);
  return (
    <Layout useDefaultSpacing title={translations.accessControl[lang]}>
      <Section>
        <Paper elevation={2}>
          <AppBar position="static">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="fullWidth"
              scrollButtons="auto"
            >
              <Tab label={translations.createUser[lang]} />
              <Tab label={translations.searchForUser[lang]} />
            </Tabs>
          </AppBar>
          <TabPanel value={tabValue} index={0}>
            <UserForm onSubmitForm={submitCreateUser} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {users && (
              <UserList
                users={users}
                deleteSelectedUser={deleteUser}
                editSelectedUser={setEditUser}
                accessGroup={`${accessListType.VACCINATION},${accessListType.BORDER_CONTROL}`}
              />
            )}
            {editSelectedUser && (
              <UserForm
                editUser={editSelectedUser}
                onSubmitForm={submitEditUser}
                clearEditUser={() => setEditSelectUser(null)}
              ></UserForm>
            )}
          </TabPanel>
        </Paper>
      </Section>
    </Layout>
  );

  function deleteUser(ssn: string) {
    deleteSelectedUser(ssn)
      .then((response) => {
        if (response.isOk) {
          notifySuccess(translations.userDeleted[lang]);
          getUsers();
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
        }
      })
      .catch((e) => {
        notifyError(translations.status401[lang]);
      });
  }

  function submitCreateUser(
    values: IFormValues,
    { resetForm }: FormikHelpers<IFormValues>
  ) {
    createUser(values.ssn, new CreateUser(values))
      .then((response) => {
        if (response.isOk) {
          notifySuccess(translations.createUserSuccess[lang]);
          getUsers();
          resetForm();
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
        } else if (response.statusCode === 409) {
          notifyError(translations.userAlreadyExists[lang]);
        }
      })
      .catch((e) => {
        notifyError(translations.status401[lang]);
      });
  }

  function submitEditUser(
    values: IFormValues,
    { resetForm }: FormikHelpers<IFormValues>
  ) {
    var updatedUser = new CreateUser(values);
    if (updatedUser.userAccesses.length < 1) {
      notifyError(translations.invalidUserAccess[lang]);
      return;
    }
    // the api will handle setting superadmin on the user if needed
    editUser(values.ssn, updatedUser)
      .then((response) => {
        if (response.isOk) {
          notifySuccess(translations.editUserSuccess[lang]);
          getUsers();
          resetForm();
          setEditSelectUser(null);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
        } else {
          notifyError(translations.errorOccurred[lang]);
        }
      })
      .catch((e) => {
        notifyError(translations.errorOccurred[lang]);
      });
  }

  function setEditUser(user: UserItem) {
    setEditSelectUser(user);
  }
};
export default UserControlPage;
