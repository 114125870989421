import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../components/Layout';
import useNotifier from '../hooks/useNotifier';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import CreateVaccinationGroup from '../components/CreateVaccinationGroup';

const AddVaccinationGroupPage: React.FC = () => {
  const history = useHistory();
  const [lang] = useContext(LangContext);
  const { notifyError } = useNotifier();

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  return (
    <Layout
      useDefaultSpacing
      title={translations.createNewGroup[lang]}
      otherButton={{
        label: translations.backToGroups[lang],
        onClick: () => history.push('/groups'),
      }}
    >
      <CreateVaccinationGroup></CreateVaccinationGroup>
    </Layout>
  );
};

export default AddVaccinationGroupPage;
