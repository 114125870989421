import {
  Grid,
  makeStyles,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  Tooltip,
  FormControl,
} from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import React, { useContext, useRef, useState } from 'react';
import * as Yup from 'yup';
import { LangContext } from '../../../context/LangContext';
import translations from '../../../assets/json/translations.json';
import { CalendarEvent } from './Schedule';
import moment from 'moment';
import ConfirmDialog from '../../ConfirmDialog';
import {
  calculateMaxPerSlot,
  getWeekDayNameInSecondDegree,
  calculateFreeSlots,
  numberWithCommas,
} from './ScheduleUtils';

interface IProps {
  open: boolean;
  error?: boolean;
  loading?: boolean;
  currentEvent: CalendarEvent | null;
  recurringEnabled: boolean;
  editEvent: boolean;
  currentBookingCount: number;
  currentBookingCountForDay: number;
  showBookings: boolean;
  onCancel: () => void;
  onDeleteEvent: () => void;
  onCreateEvent: (
    maxInSlot: number,
    isRecurring: boolean,
    isHoliday: boolean
  ) => void;
  onUpdateEvent: (
    eventId: string,
    maxInSlot: number,
    isRecurring: boolean,
    isHoliday: boolean
  ) => void;
}

export interface IFormDialogValues {
  maxInSlot: string;
  isRecurring: boolean;
  isNonRecurring: boolean;
  isHoliday: boolean;
}

const SHORT_DATE_FORMAT = 'dddd';
const HOUR_MINUTES = 'HH:mm';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  infoItem: {
    marginBottom: theme.spacing(2),
  },
  descriptionTitle: {
    display: 'inline-block',
    fontWeight: 'bold',
    paddingRight: 10,
  },
  descriptionDetails: {
    display: 'inline-block',
  },
  buttonGrid: {
    textAlign: 'right',
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  topItems: {
    marginBottom: theme.spacing(1),
  },
  warning: { height: '120px' },
  deleteButton: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    minWidth: 90,
  },
}));

export interface IFormValues {
  maxInSlot: string;
  isRecurring: boolean;
  isNonRecurring: boolean;
  isHoliday: boolean;
}

export type IFormik = FormikProps<IFormValues>;

const ScheduleDialog: React.FC<IProps> = ({
  open,
  currentEvent,
  editEvent,
  onCreateEvent,
  onUpdateEvent,
  onDeleteEvent,
  onCancel,
  recurringEnabled,
  currentBookingCount,
  currentBookingCountForDay,
  showBookings,
}) => {
  const [lang] = useContext(LangContext);
  const [maxInSlotRequired, setMaxInSlotRequired] = useState(true);
  const schema = Yup.object().shape({
    maxInSlot: maxInSlotRequired
      ? Yup.number().required().min(1).max(400000)
      : Yup.number(),
    isRecurring: Yup.bool().required(),
    isHoliday: Yup.bool().required(),
  });
  const maxInSlot = useRef<HTMLInputElement>(null);
  const classes = useStyles({});

  return (
    <Formik
      onSubmit={(values, { resetForm }) => {
        const maxInSlot = Number(values.maxInSlot);
        const isRecurring = values.isRecurring;
        const isHoliday = values.isHoliday;
        if (editEvent && currentEvent) {
          onUpdateEvent(currentEvent.id, maxInSlot, isRecurring, isHoliday);
        } else {
          onCreateEvent(maxInSlot, isRecurring, isHoliday);
        }
        resetForm();
      }}
      validationSchema={schema}
      enableReinitialize
      initialValues={getInitalValues(
        currentEvent?.maxInSlot,
        currentEvent?.isRecurring,
        currentEvent?.isHoliday
      )}
    >
      {(formik) => (
        <ConfirmDialog
          open={open}
          maxWidth="md"
          title={
            editEvent
              ? translations.editTimeSlot[lang]
              : translations.createTimeSlot[lang]
          }
          onConfirm={formik.handleSubmit}
          onCancel={() => handleClose(formik)}
          onLeftButtonClick={editEvent ? onDeleteEvent : undefined}
          leftButtonColor="error"
          leftButtonText={translations.delete[lang]}
        >
          <Grid container>
            <React.Fragment>
              <Grid item className={classes.topItems} xs={12} md={6}>
                {formik.values.isRecurring &&
                  renderEventItem(
                    translations.weekday[lang],
                    currentEvent?.dayOfTheWeek === 7
                      ? moment().weekday(0).format()
                      : moment()
                          .weekday(currentEvent?.dayOfTheWeek ?? 0)
                          .format(),
                    SHORT_DATE_FORMAT
                  )}
                {!formik.values.isRecurring &&
                  renderEventItem(
                    translations.date[lang],
                    moment(currentEvent?.start).format(),
                    'DD.MMM.YYYY'
                  )}
                {renderEventItem(
                  translations.timeFrom[lang],
                  currentEvent?.start,
                  HOUR_MINUTES
                )}
                {renderEventItem(
                  translations.timeTo[lang],
                  currentEvent?.end,
                  HOUR_MINUTES
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  disabled={formik.values.isHoliday}
                  fullWidth
                  autoFocus
                  required
                  autoComplete="off"
                  id="maxInSlot"
                  variant="outlined"
                  label={translations.maxPerSlot[lang]}
                  inputProps={{ ref: maxInSlot }}
                  value={formik.values.maxInSlot}
                  error={!!formik.errors.maxInSlot && !!formik.submitCount}
                  onChange={(ev) => {
                    formik.handleChange(ev);
                  }}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => e.key === 'Enter' && formik.handleSubmit()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <RadioGroup
                    aria-label="gender"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value=""
                      control={
                        <Radio
                          id="isRecurring"
                          color="primary"
                          disabled={!recurringEnabled}
                          checked={formik.values.isRecurring}
                          onChange={(e) => {
                            var isChecked = e.target.checked as boolean;
                            formik.setFieldValue('isRecurring', isChecked);
                            if (isChecked) {
                              formik.setFieldValue('isNonRecurring', false);
                              formik.setFieldValue('isHoliday', false);
                              setMaxInSlotRequired(true);
                            } else {
                              setMaxInSlotRequired(false);
                            }
                          }}
                        />
                      }
                      label={`${
                        translations.all[lang]
                      } ${getWeekDayNameInSecondDegree(
                        currentEvent?.dayOfTheWeek,
                        lang
                      )}`}
                    />
                    <FormControlLabel
                      value=""
                      control={
                        <Radio
                          id="isHoliday"
                          color="primary"
                          checked={formik.values.isHoliday}
                          onChange={(e) => {
                            var isChecked = e.target.checked as boolean;
                            formik.setFieldValue('isHoliday', isChecked);
                            if (isChecked) {
                              formik.setFieldValue('isRecurring', false);
                              formik.setFieldValue('isNonRecurring', false);
                              setMaxInSlotRequired(false);
                            } else {
                              setMaxInSlotRequired(true);
                            }
                          }}
                        />
                      }
                      label={`${translations.mark[lang]} ${moment(
                        currentEvent?.start
                      ).format('DD.MMM.YYYY')} ${translations.asHoliday[lang]}`}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {showBookings && (
                  <Typography>
                    {translations.bookings[lang]} :{' '}
                    {numberWithCommas(currentBookingCount)} /{' '}
                    {numberWithCommas(
                      calculateMaxPerSlot(
                        currentEvent?.startStr ?? (currentEvent?.start || ''),
                        currentEvent?.endStr ?? (currentEvent?.end || ''),
                        Number(formik.values.maxInSlot),
                        !currentEvent?.isRecurring ? currentBookingCount : 0
                      )
                    )}
                  </Typography>
                )}
                <Typography>
                  {translations.maxCalculated[lang]} :{' '}
                  {numberWithCommas(
                    calculateMaxPerSlot(
                      currentEvent?.startStr ?? (currentEvent?.start || ''),
                      currentEvent?.endStr ?? (currentEvent?.end || ''),
                      Number(formik.values.maxInSlot),
                      !currentEvent?.isRecurring ? currentBookingCount : 0
                    )
                  )}
                </Typography>
                {formik.values.isHoliday && showBookings && (
                  <Typography>{translations.freeSlots[lang]} 0</Typography>
                )}
                {!formik.values.isHoliday && showBookings && (
                  <Typography>
                    {translations.freeSlots[lang]} : &nbsp;
                    {numberWithCommas(
                      calculateFreeSlots(
                        currentBookingCount,
                        Number(formik.values.maxInSlot),
                        currentEvent?.startStr ?? (currentEvent?.start || ''),
                        currentEvent?.endStr ?? (currentEvent?.end || ''),
                        formik.values.isRecurring
                      )
                    )}
                  </Typography>
                )}
                {currentBookingCountForDay > 0 && formik.values.isHoliday && (
                  <div className={classes.warning}>
                    <Tooltip
                      open={true}
                      title={
                        <Typography>
                          <b>
                            {currentBookingCountForDay}{' '}
                            {translations.scheduleWarningHoliday[lang]}
                          </b>
                        </Typography>
                      }
                    >
                      <Typography></Typography>
                    </Tooltip>
                  </div>
                )}
              </Grid>
            </React.Fragment>
          </Grid>
        </ConfirmDialog>
      )}
    </Formik>
  );

  function getInitalValues(
    maxInSlot?: number,
    isRecurring?: boolean,
    isHoliday?: boolean
  ) {
    const isRecurringValue =
      isRecurring && !isHoliday && recurringEnabled ? true : false;
    return {
      maxInSlot: maxInSlot?.toString() ?? '',
      isRecurring: isRecurringValue,
      isNonRecurring: !isRecurringValue && !isHoliday ? true : false,
      isHoliday: isHoliday ?? false,
    };
  }

  function handleClose(formik: IFormik) {
    onCancel();
    formik.handleReset();
  }

  function renderEventItem(
    title: string,
    description?: string,
    timeFormat?: string
  ) {
    return (
      <div>
        <Typography className={classes.descriptionTitle}>{title}:</Typography>
        <Typography className={classes.descriptionDetails}>
          {moment(description).format(timeFormat)}
        </Typography>
      </div>
    );
  }
};

export default ScheduleDialog;
