import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {
  DEFAULT_PALETTE_PRIMARY,
  DEFAULT_PALETTE_SECONDARY,
} from './constants';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    dimensions: {
      maxContentWidth: number;
      navbarHeight: number;
      boxWidth: number;
    };
    customPalette: {
      dark: string;
      greyBorder: string;
    };
  }
  interface ThemeOptions {
    dimensions?: {
      maxContentWidth: number;
      navbarHeight: number;
      boxWidth: number;
    };
    customPalette: {
      dark: string;
      greyBorder: string;
    };
  }
}

const defaultPalette = {
  primary: {
    main: DEFAULT_PALETTE_PRIMARY,
  },
  secondary: {
    main: DEFAULT_PALETTE_SECONDARY,
  },
  background: {
    default: '#f4f4f7',
  },
  text: {
    primary: '#212121',
    secondary: '#616161',
  },
};

const defaultTheme = createMuiTheme();

export default createMuiTheme({
  palette: {
    ...defaultPalette,
  },
  customPalette: {
    dark: '#263343',
    greyBorder: '#c4c4c4',
  },
  typography: {
    fontFamily: ['Helvetica'].join(','),
    h1: {
      fontSize: 45,
      fontWeight: 400,
      color: defaultPalette.text.primary,
      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: 35,
      },
    },
    h2: {
      fontSize: 45,
      color: defaultPalette.text.primary,
    },
    h3: {
      fontSize: 40,
      color: defaultPalette.text.primary,
    },
    h4: {
      fontSize: 35,
      color: defaultPalette.text.primary,
      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: 25,
      },
    },
    h5: {
      color: defaultPalette.text.primary,
    },
    h6: {
      color: defaultPalette.text.primary,
    },
  },
  dimensions: {
    maxContentWidth: 1440,
    navbarHeight: 64,
    boxWidth: 444,
  },

  /* overrides: {
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: defaultTheme.palette.success.main,
        },
      },
      active: {},
      completed: {},
    },
  }, */
});
