import React, { useContext, useState } from 'react';
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
  Collapse,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { VaccinationPersonGroup } from '../models/VaccinationModels';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import useNotifier from '../hooks/useNotifier';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cx from 'classnames';

interface IProps {
  groups?: VaccinationPersonGroup[];
  accessibleRegionIds?: string[];
  clickable?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderBottomWidth: 0,
    borderRadius: 4,
    marginTop: theme.spacing(1),
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  emptyText: {
    display: 'inline-block',
  },
  expandIcon: {
    color: theme.palette.primary.main,
    transform: 'rotate( 0deg)',
    transition: 'transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  expandedIcon: {
    color: theme.palette.primary.main,
    transform: 'rotate( 180deg)',
    transition: 'transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  expandTitle: {
    color: theme.palette.primary.main,
  },
}));

const GroupsInfo: React.FC<IProps> = ({
  groups,
  accessibleRegionIds,
  clickable = false,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [lang] = useContext(LangContext);
  const { notifyError } = useNotifier();
  const [showTable, setShowTable] = useState(false);

  if (!groups?.length) {
    return (
      <Typography className={classes.emptyText} color="textSecondary">
        {translations.noGroups[lang]}
      </Typography>
    );
  }

  return (
    <>
      <div
        onClick={() => setShowTable(!showTable)}
        style={{ cursor: 'pointer', display: 'inline-block' }}
      >
        <Grid container alignItems="center" justify="flex-start" spacing={1}>
          <Grid item>
            <Typography className={classes.expandTitle}>
              {translations.selectGroups[lang]}:
            </Typography>
          </Grid>
          <Grid>
            <ExpandMoreIcon
              className={cx(classes.expandIcon, {
                [classes.expandedIcon]: showTable,
              })}
            />
          </Grid>
        </Grid>
      </div>
      {<Collapse in={showTable}>{renderTable()}</Collapse>}
    </>
  );

  function renderTable() {
    return (
      <TableContainer className={classes.container}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{translations.group[lang]}</TableCell>
              <TableCell>{translations.description[lang]}</TableCell>
              <TableCell>{translations.priority[lang]}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{groups?.map(renderRow)}</TableBody>
        </Table>
      </TableContainer>
    );
  }

  function renderRow(group: VaccinationPersonGroup) {
    const hasGroupAccess = !!accessibleRegionIds?.includes(group.regionId);
    const cursor = clickable
      ? hasGroupAccess
        ? 'pointer'
        : 'not-allowed'
      : undefined;
    const onClick = clickable
      ? hasGroupAccess
        ? () => history.push(`/groups/${group.id}`)
        : () => notifyError(translations.groupAccessDenied[lang])
      : undefined;

    return (
      <TableRow
        key={group.id}
        hover={clickable && hasGroupAccess}
        style={{ cursor }}
        onClick={onClick}
      >
        <TableCell>{group.name}</TableCell>
        <TableCell>{group.description}</TableCell>
        <TableCell>{group.priority}</TableCell>
      </TableRow>
    );
  }
};

export default GroupsInfo;
