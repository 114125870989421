import React, { useContext, useEffect, useState } from 'react';
import FullCalendar, { CssDimValue } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { createStyles, Grid, Theme, Typography } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import ScheduleIcon from '@material-ui/icons/Schedule';
import isLocale from '@fullcalendar/core/locales/is';
import enLocale from '@fullcalendar/core/locales/en-gb';
import moment from 'moment';
import 'moment/locale/is';
import 'moment/locale/en-gb';
import { LangContext } from '../../../context/LangContext';
import * as calendarService from '../../../services/calendarService';
import Section from '../../Section';
import theme from '../../../theme';
import { createEventId } from '../../../pages/event-utils';
import ScheduleDialog from './ScheduleDialog';
import ViewScheduleDialog from '../../ScheduleDialog';
import {
  UpdateTimeSlots,
  LocationSchedule,
  ScheduleUpdatedBy,
} from '../../../models/Models';
import useNotifier from '../../../hooks/useNotifier';
import translations from '../../../assets/json/translations.json';
import Button from '../../Button';
import { EventApi } from '@fullcalendar/core';
import ConfirmDialog from '../../ConfirmDialog';
import { EVENT_DATE_FORMAT } from '../../../store/Constants';
import { calculateMaxPerSlot, numberWithCommas } from './ScheduleUtils';

import { makeStyles } from '@material-ui/styles';
import { DATE_FORMAT } from '../../../constants';
import { useHistory } from 'react-router';
import LoadingIndicator from '../../LoadingIndicator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      backgroundColor: 'white',
    },
    buttons: {
      '&>.fc .fc-monthBtn-button': {
        backgroundColor: 'red',
      },
    },
    calendarContainer: {
      position: 'relative',
      fontFamily: theme.typography.fontFamily,
      '&>.fc .fc-disabledWeekBtn-button': {
        backgroundColor: 'rgb(114, 126, 139)',
      },
      '&>.fc .fc-disabledMonthBtn-button': {
        backgroundColor: 'rgb(114, 126, 139)',
      },
      '&>.fc .fc-customPrevBtn-button': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '&>.fc .fc-customNextBtn-button': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '&>.fc .fc-customTitle-button, &>.fc .fc-customTitle-button:active': {
        backgroundColor: '#f4f4f7',
        color: theme.palette.text.primary,
        cursor: 'default',
        fontSize: '1.75em',
        fontWeight: 'bold',
        border: 'none',
        '&:focus': {
          boxShadow: 'none',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '1em',
        },
      },
    },
    switchContainer: {
      position: 'absolute',
      top: 16,
      right: 0,
      [theme.breakpoints.down('sm')]: {
        top: 0,
      },
      [theme.breakpoints.down('xs')]: {
        position: 'relative',
        textAlign: 'right',
        marginBottom: theme.spacing(2),
      },
    },
    locationLabelContainer: {
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
      top: 50,
      marginLeft: 42,
      [theme.breakpoints.down('sm')]: {
        top: 30,
      },
      [theme.breakpoints.down('xs')]: {
        top: 86,
      },
    },
    locationLabel: {
      fontWeight: 'bold',
      fontSize: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    syncIcon: {
      float: 'right',
      transform: 'rotate(90deg)',
      marginTop: '-23px',
      fontSize: '20px',
      marginRight: '5px',
    },
  })
);
interface IProps {
  maxInSlot?: number;
  location: any;
}
export interface CalendarEvent {
  id: string;
  title: string;
  start?: string;
  end?: string;
  maxInSlot?: number;
  dayOfTheWeek?: number;
  absoluteDate?: string;
  oldDayOfTheWeek?: number;
  endStr?: string;
  startStr?: string;
  isHoliday: boolean;
  isRecurring: boolean;
  bookingCount?: number;
  color: string;
}

export interface CalendarApi {
  getEvents: () => Array<SelectInfo>;
  unselect: () => void;
  addEvent: (calendarEvent: CalendarEvent) => void;
  removeAllEvents: () => void;
  scrollToTime: (time: string) => void;
  changeView: (viewName: string) => void;
  next: () => void;
  prev: () => void;
}

export interface EventInfo {
  event?: SelectInfo;
  timeText?: string;
}

export interface View {
  calendar: CalendarApi;
}

export class ExtendedProp {
  maxInSlot?: number;
  dayOfTheWeek?: number;
  isHoliday: boolean;
  isRecurring: boolean;
  bookingCount: number;

  constructor(extendedProp: any) {
    this.maxInSlot = extendedProp.maxInSlot;
    this.dayOfTheWeek = extendedProp.dayOfTheWeek;
    this.isHoliday = extendedProp.isHoliday;
    this.isRecurring = extendedProp.isRecurring;
    this.bookingCount = extendedProp.bookingCount;
  }
}

export interface SelectInfo {
  id: number;
  view: View;
  title: string;
  startStr: string;
  endStr: string;
  extendedProps?: ExtendedProp;
  revert: () => void;
  event?: EventApi;
  isHoliday: boolean;
  isRecurring: boolean;
}

export interface DragDropInfo {
  view: View;
  event: SelectInfo;
}

const EVENT_DATE_ONLY_FORMAT = 'YYYY-MM-DD';
const WINDOW_AVAILABLE_SPACE = 150;

const Schedule: React.FC<IProps> = ({ location }) => {
  const [lang] = useContext(LangContext);
  const history = useHistory();

  const [calendar, setCalendar] = useState<Array<CalendarEvent> | undefined>(
    undefined
  );
  const [recurringCalendar, setRecurringCalendar] = useState<
    Array<CalendarEvent> | undefined
  >(undefined);
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [currentEvent, setCurrentEvent] = useState<CalendarEvent | null>(null);
  const [selectInfo, setSelectInfo] = useState<SelectInfo | null>(null);
  const { notifyError, notifySuccess } = useNotifier();
  const [calendarApi, setCalendarApi] = useState<CalendarApi | null>(null);
  const [windowHeight, setWindowHeight] = useState<Number>(getWindowHeight());
  const [editEvent, setEditEvent] = useState(false);
  const [calendarUpdated, setCalendarUpdated] = useState(false);
  const [calendarUpdatedDialog, setCalendarUpdatedDialog] = useState(false);
  const [displayUpdateWarningDialog, setDisplayUpdateWarningDialog] =
    useState(false);
  const [calendarUpdatedDialogDirection, setCalendarUpdatedDialogDirection] =
    useState('');

  const [currentNumberOfBookings, setCurrentNumberOfBookings] = useState(0);
  const [currentBookingCountForDay, setCurrentBookingCountForDay] = useState(0);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState<string>(
    moment().startOf('week').format(EVENT_DATE_ONLY_FORMAT)
  );
  const [endDate, setEndDate] = useState<string>(
    moment().startOf('week').format(EVENT_DATE_ONLY_FORMAT)
  );
  const [rawTimeSlots, setRawTimeSlots] = useState<LocationSchedule[] | null>(
    null
  );
  const [lastModifiedBy, setLastModifiedBy] =
    useState<ScheduleUpdatedBy | null>(null);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);

  function getWindowHeight() {
    return window.innerHeight - WINDOW_AVAILABLE_SPACE;
  }

  function clearEvents() {
    selectInfo?.view?.calendar?.removeAllEvents();
  }

  useEffect(() => {
    const getLastModified = async (locationId: number) => {
      const response = await calendarService.getLastModified(locationId);
      if (response.isOk && response.data) {
        setLastModifiedBy(response.data);
      }
    };

    const locationId = Number(location?.value);

    if (isNaN(locationId) && !startDate && !endDate) return;
    getCalendar(locationId, true);

    getRecurringCalendar(locationId);
    getLastModified(locationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, lang, startDate, endDate]);

  async function getRecurringCalendar(locationId: number) {
    calendarService
      .getRecurringTimeslots(locationId, startDate, endDate)
      .then((calendar) => {
        const events = new Array<CalendarEvent>();
        calendar?.data
          ?.map((c) => {
            return {
              dayOfTheWeek: c.dayOfTheWeek,
              absoluteDate: c.absoluteDate,
              isHoliday: c.isHoliday,
              isRecurring: c.isRecurring,
              bookingCount: c.bookingCount,
              timeSlots: c?.timeSlots?.map((t) => {
                return {
                  fromTime: t.fromTime,
                  toTime: t.toTime,
                  maxInSlot: t.maxInSlot,
                };
              }),
            };
          })
          .map((d) => {
            d?.timeSlots.forEach((currSlot) => {
              const fromHours = currSlot.fromTime.hours;
              const fromMinutes = currSlot.fromTime.minutes;
              const toHours = currSlot.toTime.hours;
              const toMinutes = currSlot.toTime.minutes;
              const maxInSlot = currSlot.maxInSlot;
              const absoluteDate = d.absoluteDate;
              moment.updateLocale('is', {
                week: {
                  dow: 0,
                },
              });
              let momentFromDate = moment(absoluteDate)
                .startOf('day')
                .weekday(d?.dayOfTheWeek)
                .add(fromHours, 'hours')
                .add(fromMinutes, 'minutes')
                .format(EVENT_DATE_FORMAT);
              let momentToDate = moment(absoluteDate)
                .startOf('day')
                .weekday(d?.dayOfTheWeek)
                .add(toHours, 'hours')
                .add(toMinutes, 'minutes')
                .format(EVENT_DATE_FORMAT);

              events?.push({
                id: createEventId(),
                title: location?.label.split('-')[1]?.trim() ?? '',
                start: momentFromDate,
                end: momentToDate,
                dayOfTheWeek: d.dayOfTheWeek,
                maxInSlot: maxInSlot,
                isHoliday: d.isHoliday,
                isRecurring: d.isRecurring,
                bookingCount: d.bookingCount,
                color: getColor(d.isHoliday, d.isRecurring),
              });
            });
          });
        setRecurringCalendar(events);
      });
  }

  async function getCalendar(locationId: number, firstLoad: boolean) {
    calendarService
      .getTimeslots(locationId, startDate, endDate)
      .then((calendar) => {
        setRawTimeSlots(calendar.data);
        const events = new Array<CalendarEvent>();
        calendar?.data
          ?.map((c) => {
            return {
              dayOfTheWeek: c.dayOfTheWeek,
              absoluteDate: c.absoluteDate,
              isHoliday: c.isHoliday,
              isRecurring: c.isRecurring,
              bookingCount: c.bookingCount,
              timeSlots: c?.timeSlots?.map((t) => {
                return {
                  fromTime: t.fromTime,
                  toTime: t.toTime,
                  maxInSlot: t.maxInSlot,
                };
              }),
            };
          })
          .map((d) => {
            d?.timeSlots.forEach((currSlot) => {
              const fromHours = currSlot.fromTime.hours;
              const fromMinutes = currSlot.fromTime.minutes;
              const toHours = currSlot.toTime.hours;
              const toMinutes = currSlot.toTime.minutes;
              const maxInSlot = currSlot.maxInSlot;
              const absoluteDate = d.absoluteDate;
              moment.updateLocale('is', {
                week: {
                  dow: 0,
                },
              });
              let momentFromDate = moment(absoluteDate)
                .startOf('day')
                .weekday(d?.dayOfTheWeek)
                .add(fromHours, 'hours')
                .add(fromMinutes, 'minutes')
                .format(EVENT_DATE_FORMAT);
              let momentToDate = moment(absoluteDate)
                .startOf('day')
                .weekday(d?.dayOfTheWeek)
                .add(toHours, 'hours')
                .add(toMinutes, 'minutes')
                .format(EVENT_DATE_FORMAT);

              events?.push({
                id: createEventId(),
                title: location?.label.split('-')[1]?.trim() ?? '',
                start: momentFromDate,
                end: momentToDate,
                dayOfTheWeek: d.dayOfTheWeek,
                maxInSlot: maxInSlot,
                isHoliday: d.isHoliday,
                isRecurring: d.isRecurring,
                bookingCount: d.bookingCount,
                color: getColor(d.isHoliday, d.isRecurring),
              });
            });
          });

        setCurrentEvent(null);
        clearEvents();
        setCalendarUpdated(false);
        setCalendar(events);
        setLoading(false);
        if (firstLoad) {
          scrollToFirstSlot();
        }
      });
  }

  function scrollToFirstSlot() {
    let scrollSlot = moment().format('HH:mm');
    if (calendarApi) {
      const nonHolidays = calendarApi
        ?.getEvents()
        .filter((x) => !x.extendedProps?.isHoliday);
      const event = nonHolidays.sort((ev1, ev2) => {
        const val = moment(ev1?.startStr).isAfter(moment(ev2?.startStr));
        return val === true ? 1 : -1;
      })[0];
      scrollSlot = moment(event?.startStr).format('HH:mm');
    }
    calendarApi?.scrollToTime(scrollSlot);
  }

  const customButtons = {
    customNextBtn: {
      icon: 'fa fc-icon-chevron-right',
      click: function () {
        if (calendarUpdated) {
          setCalendarUpdatedDialogDirection('next');
          setCalendarUpdatedDialog(true);
        } else {
          calendarApi?.next();
        }
      },
    },
    customPrevBtn: {
      icon: 'fa fc-icon-chevron-left',
      click: function () {
        if (calendarUpdated) {
          setCalendarUpdatedDialogDirection('prev');
          setCalendarUpdatedDialog(true);
        } else {
          calendarApi?.prev();
        }
      },
    },
    customTitle: {
      text: `${moment(startDate).locale(lang).format('DD.MMM')} - ${moment(
        endDate
      )
        .locale(lang)
        .format('DD.MMM')}, ${moment(startDate).locale(lang).format('YYYY')}`,
    },
  };

  if (loading) {
    return (
      <Section>
        <Grid container justify="center" alignItems="center">
          <LoadingIndicator />
        </Grid>
      </Section>
    );
  }
  return (
    <Section>
      {location && calendar && (
        <>
          <div className={classes.calendarContainer}>
            <div className={classes.locationLabelContainer}>
              <Typography className={classes.locationLabel}>
                {location?.label || ''}
              </Typography>
            </div>
            {renderOverflowSwitches()}
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              dayHeaderContent={(arg) => {
                return moment(arg.date).locale(lang).format('ddd DoMMM');
              }}
              headerToolbar={{
                right: '',
                left: 'customPrevBtn,customNextBtn',
                center: 'customTitle',
              }}
              droppable={false}
              customButtons={customButtons}
              viewClassNames={classes.table}
              initialView={'timeGridWeek'}
              unselectAuto={false}
              events={calendar}
              allDaySlot={false}
              allDayContent={false}
              selectOverlap={false}
              height={windowHeight as CssDimValue}
              stickyHeaderDates={false}
              locale={lang === 'is' ? isLocale : enLocale}
              editable={!isNaN(Number(location?.value)) && !loading}
              selectable={!isNaN(Number(location?.value)) && !loading}
              selectMirror={true}
              dayMaxEvents={false}
              weekends={true}
              expandRows={true}
              handleWindowResize={true}
              initialDate={moment().format(EVENT_DATE_ONLY_FORMAT)}
              eventResize={handleEventEdit}
              select={handleDateSelect as any}
              eventContent={renderEventContent}
              eventClick={handleEventEdit}
              slotDuration={'00:15:00'}
              eventStartEditable={false}
              slotLabelFormat={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              }}
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              }}
              windowResize={() => {
                const windowHeight = getWindowHeight();
                setWindowHeight(windowHeight);
              }}
              eventOverlap={false}
              datesSet={(info: any) => {
                if (info.startStr !== startDate) {
                  setStartDate(
                    moment(info.startStr).format(EVENT_DATE_ONLY_FORMAT)
                  );
                }
                if (info.endStr !== endDate) {
                  setEndDate(
                    moment(info.endStr)
                      .add('days')
                      .format(EVENT_DATE_ONLY_FORMAT)
                  );
                }
                const selInfo = info as SelectInfo;
                const calendarApi = selInfo.view.calendar;
                setCalendarApi(calendarApi);
              }}
            />
          </div>
          <Grid
            container
            justify={'space-between'}
            style={{ marginTop: theme.spacing(2) }}
          >
            <Grid item>
              {lastModifiedBy &&
                lastModifiedBy?.modifiedBy &&
                lastModifiedBy.modifiedDate && (
                  <Typography>{`${translations.lastChangeBy[lang]}: ${
                    lastModifiedBy.modifiedBy
                  } - ${moment(lastModifiedBy.modifiedDate).format(
                    DATE_FORMAT
                  )}`}</Typography>
                )}
            </Grid>
            <Grid item>
              <Button
                padding={false}
                disabled={!calendarUpdated}
                onClick={() => saveChanges()}
              >
                {translations.saveChanges[lang]}
              </Button>
            </Grid>
          </Grid>
          {renderDialogs()}
        </>
      )}
    </Section>
  );

  function getSelectedDay(selectInfo: any) {
    if (selectInfo === undefined || selectInfo === null) {
      return 0;
    }
    if (selectInfo?.event) {
      return Number(
        moment(selectInfo?.event?.endStr, EVENT_DATE_ONLY_FORMAT).format('d')
      );
    }
    return Number(
      moment(selectInfo?.endStr, EVENT_DATE_ONLY_FORMAT).format('d')
    );
  }

  async function deleteEvent() {
    const calendarApi = selectInfo?.view.calendar;
    selectInfo?.event?.remove();
    if (!selectInfo?.event?.extendedProps?.isRecurring) {
      let nonRecurringEventsSameDay = calendarApi
        ?.getEvents()
        ?.filter(
          (x) =>
            moment(x.startStr).format(EVENT_DATE_ONLY_FORMAT) ===
            moment(selectInfo?.event?.start).format(EVENT_DATE_ONLY_FORMAT)
        );
      // check if this is the last nonRecurring event on the day we are removing, if so load the recurring schedule
      if (!nonRecurringEventsSameDay || nonRecurringEventsSameDay.length < 1) {
        let recurringEventsSameDay = recurringCalendar?.filter(
          (x) =>
            moment(x.start).format(EVENT_DATE_ONLY_FORMAT) ===
            moment(selectInfo?.event?.start).format(EVENT_DATE_ONLY_FORMAT)
        );
        if (recurringEventsSameDay && recurringEventsSameDay.length > 0) {
          recurringEventsSameDay.forEach((ev) => {
            calendarApi?.addEvent(ev);
          });
        }
      }
    }
    setShowDialog(false);

    checkForUpdates();
  }

  function cancelCreateEvent() {
    const calendarApi = selectInfo?.view?.calendar;

    calendarApi?.unselect();
    if (typeof selectInfo?.revert === 'function') {
      selectInfo.revert();
    }
    setShowDialog(false);
  }

  function getEventTitle() {
    const label = location?.label.split('-')[1] ?? '';
    return label !== '' ? label.trim() : '';
  }

  function handleDateSelect(selectInfo: SelectInfo) {
    setSelectInfo(selectInfo);

    const newEvent: CalendarEvent = {
      id: createEventId(),
      title: getEventTitle(),
      start: selectInfo.startStr,
      end: selectInfo.endStr,
      dayOfTheWeek: getWeekDay(selectInfo?.endStr),
      isHoliday: selectInfo.extendedProps
        ? selectInfo.extendedProps.isHoliday
        : false,
      isRecurring: selectInfo.extendedProps
        ? selectInfo.extendedProps.isRecurring
        : false,
      bookingCount: selectInfo.extendedProps
        ? selectInfo.extendedProps.bookingCount
        : 0,
      color: getColorWithSelectInfo(selectInfo),
    };
    if (!selectInfo.extendedProps && isRecurringEnabled(selectInfo.startStr)) {
      newEvent.isRecurring = true; // default to true
    }
    setNumberOfBookings(selectInfo.startStr, selectInfo.endStr);
    setCurrentEvent(newEvent);
    setEditEvent(false);
    setShowDialog(true);
  }

  function updateEvent(
    eventId: string,
    maxInSlot: number,
    isRecurring: boolean,
    isHoliday: boolean
  ) {
    const calendarApi = selectInfo?.view.calendar;
    const event = calendarApi
      ?.getEvents()
      .find((x) => x.id.toString() === eventId);

    const dayOfTheWeek = getSelectedDay(event);
    if (event) {
      let newEvent = {
        id: createEventId(),
        title: getEventTitle(),
        start: event.startStr,
        end: event.endStr,
        dayOfTheWeek: dayOfTheWeek,
        maxInSlot: maxInSlot,
        isHoliday: isHoliday,
        isRecurring: isRecurring,

        color: getColor(isHoliday, isRecurring),
      };
      if (!isRecurring && selectInfo) {
        removeRecurringEventsForDay(selectInfo, isHoliday, eventId);
        if (isHoliday && selectInfo && selectInfo.event) {
          newEvent = createHolidayEvent(
            selectInfo?.event?.startStr,
            dayOfTheWeek
          );
        }
      }
      selectInfo?.event?.remove();
      calendarApi?.addEvent(newEvent);
    }

    setShowDialog(false);
    checkForUpdates();
  }

  function createEvent(
    maxInSlot: number,
    isRecurring: boolean,
    isHoliday: boolean
  ) {
    const calendarApi = selectInfo?.view.calendar;
    const dayOfTheWeek = getSelectedDay((selectInfo as EventInfo) ?? undefined);
    if (isHoliday && selectInfo) {
      calendarApi?.addEvent(
        createHolidayEvent(selectInfo?.startStr, dayOfTheWeek)
      );
    } else {
      calendarApi?.addEvent({
        id: createEventId(),
        title: getEventTitle(),
        start: selectInfo?.startStr,
        end: selectInfo?.endStr,
        dayOfTheWeek: dayOfTheWeek,
        maxInSlot: maxInSlot,
        isHoliday: isHoliday,
        isRecurring: isRecurring,
        color: getColor(isHoliday, isRecurring),
      });
    }
    // if we are adding a nonRecurring event to a date, all recurring events from that date must be removed
    // if we are adding a holiday event to a date all other events will be removed
    if (!isRecurring) {
      let recurringEvents = calendarApi?.getEvents()?.filter(
        (x) =>
          moment(x.startStr).format(EVENT_DATE_ONLY_FORMAT) ===
            moment(selectInfo?.startStr).format(EVENT_DATE_ONLY_FORMAT) &&
          x.extendedProps?.isRecurring // remove recurring events or just all events if its a
      );
      let nonHolidayEvents = calendarApi
        ?.getEvents()
        ?.filter(
          (x) =>
            moment(x.startStr).format(EVENT_DATE_ONLY_FORMAT) ===
              moment(selectInfo?.startStr).format(EVENT_DATE_ONLY_FORMAT) &&
            !x.extendedProps?.isHoliday
        );
      if (!isHoliday) {
        // recreate the recurring events for the day as nonrecurring
        if (recurringEvents) {
          recurringEvents.forEach((element) => {
            createEventExtended(
              element.startStr,
              element.endStr,
              false,
              false,
              element.extendedProps?.maxInSlot
                ? element.extendedProps?.maxInSlot
                : 0,
              element.extendedProps?.bookingCount
                ? element.extendedProps?.bookingCount
                : 0
            );
          });
          removeEvents(recurringEvents);
        }
      } else {
        removeEvents(nonHolidayEvents);
      }
    }

    calendarApi?.unselect();
    setShowDialog(false);
    checkForUpdates();
  }
  function getSelectedWholeDay(selectInfo: SelectInfo) {
    if (selectInfo) {
      return moment(selectInfo?.event?.start).format(EVENT_DATE_ONLY_FORMAT);
    } else {
      return '';
    }
  }
  function removeRecurringEventsForDay(
    selectInfo: SelectInfo,
    isHoliday: boolean,
    excludedEventId: string
  ) {
    const currentDate = getSelectedWholeDay(selectInfo);
    if (currentDate && currentDate !== '') {
      let recurringEvents = calendarApi?.getEvents()?.filter(
        (x) =>
          moment(x.startStr).format(EVENT_DATE_ONLY_FORMAT) ===
            getSelectedWholeDay(selectInfo) &&
          x.extendedProps?.isRecurring &&
          (x.id.toString() !== excludedEventId || isHoliday) // find recurring events on same day
      );
      if (!isHoliday && recurringEvents !== undefined) {
        // recreate the events as nonRecurring
        recurringEvents.forEach((element) => {
          createEventExtended(
            element.startStr,
            element.endStr,
            false,
            false,
            element.extendedProps?.maxInSlot
              ? element.extendedProps?.maxInSlot
              : 0,
            element.extendedProps?.bookingCount
              ? element.extendedProps?.bookingCount
              : 0
          );
        });
      }
      removeEvents(recurringEvents);
    }
  }
  function removeEvents(events: Array<SelectInfo> | undefined) {
    if (events && events.length > 0) {
      for (let index = 0; index < events.length; index++) {
        const eventInfo = events[index] as any;
        eventInfo.remove();
      }
    }
  }
  function createEventExtended(
    startStr: string,
    endStr: string,
    isRecurring: boolean,
    isHoliday: boolean,
    maxInSlot: number,
    bookingCount: number
  ) {
    const weekDay = getWeekDay(startStr);
    let event = {
      id: createEventId(),
      title: getEventTitle(),
      start: startStr,
      end: endStr,
      dayOfTheWeek: weekDay,
      maxInSlot: maxInSlot,
      isHoliday: isHoliday,
      isRecurring: isRecurring,
      bookingCount: bookingCount,
      color: getColor(isHoliday, isRecurring),
    };
    calendarApi?.addEvent(event);
  }
  function createHolidayEvent(startStr: string, weekDay: number) {
    let momentFromDate = moment(startStr)
      .startOf('day')
      .weekday(weekDay)
      .add(0, 'hours')
      .add(0, 'minutes')
      .format(EVENT_DATE_FORMAT);

    let momentToDate = moment(startStr)
      .startOf('day')
      .weekday(weekDay)
      .add(23, 'hours')
      .add(59, 'minutes')
      .format(EVENT_DATE_FORMAT);

    let event = {
      id: createEventId(),
      title: getEventTitle(),
      start: momentFromDate,
      end: momentToDate,
      dayOfTheWeek: weekDay,
      maxInSlot: 0,
      isHoliday: true,
      isRecurring: false,
      bookingCount: 0,
      color: getColor(true, false),
    };
    return event;
  }

  function getWeekDay(day: string) {
    const weekDay = moment(day, EVENT_DATE_ONLY_FORMAT).weekday();
    return weekDay === 0 ? 7 : weekDay;
  }
  async function handleEventEdit(selectInfo: any) {
    const oldDayOfTheWeek = selectInfo?.event?.extendedProps?.dayOfTheWeek;
    const newDayOfTheWeek = getWeekDay(selectInfo?.event?.startStr);
    setSelectInfo(selectInfo);
    const event: CalendarEvent = {
      id: selectInfo?.event?.id,
      startStr: selectInfo?.event?.startStr,
      endStr: selectInfo?.event?.endStr,
      title: selectInfo?.event?.title,
      end: selectInfo?.event?.end,
      start: selectInfo?.event?.start,
      maxInSlot: selectInfo?.event?.extendedProps?.maxInSlot,
      dayOfTheWeek: newDayOfTheWeek,
      oldDayOfTheWeek: oldDayOfTheWeek,
      isHoliday:
        selectInfo && selectInfo.event && selectInfo.event.extendedProps
          ? selectInfo.event.extendedProps.isHoliday
          : false,
      isRecurring:
        selectInfo && selectInfo.event && selectInfo.event.extendedProps
          ? selectInfo.event.extendedProps.isRecurring
          : false,
      bookingCount:
        selectInfo && selectInfo.event && selectInfo.event.extendedProps
          ? selectInfo.event.extendedProps.bookingCount
          : 0,
      color: getColorWithSelectInfo(selectInfo),
    };
    setNumberOfBookings(selectInfo?.event?.startStr, selectInfo?.event?.endStr);
    setCurrentEvent(event);
    setEditEvent(true);
    setShowDialog(true);
  }
  function setNumberOfBookings(startStr: string, endStr: string) {
    const numberOfBookings = calcHowManyBookedInSlot(startStr, endStr);
    const numberOfBookingsForTheDay = calcHowManyBookedPerDay(startStr);
    if (numberOfBookings === undefined) {
      setCurrentNumberOfBookings(0);
    } else {
      setCurrentNumberOfBookings(numberOfBookings);
    }
    if (numberOfBookingsForTheDay === undefined) {
      setCurrentBookingCountForDay(0);
    } else {
      setCurrentBookingCountForDay(numberOfBookingsForTheDay);
    }
  }
  function getColorWithSelectInfo(selectInfo: any) {
    if (selectInfo.event && selectInfo.extendedProps) {
      return getColor(
        selectInfo.event.extendedProps.isHoliday,
        selectInfo.event.extendedProps.isRecurring
      );
    } else {
      return getColor(false, true); // default
    }
  }
  function getColor(isHoliday: boolean, isRecurring: boolean) {
    if (isHoliday) {
      return theme.palette.secondary.main;
    } else if (!isRecurring) {
      return theme.palette.primary.dark;
    } else {
      return theme.palette.primary.main;
    }
  }
  async function saveChanges() {
    if (calendarUpdated) {
      saveCalendarChanges();
    }
  }

  async function saveCalendarChanges(forceEmptyCalendar: boolean = false) {
    const calendarApi = selectInfo?.view.calendar;

    const allEvents = calendarApi?.getEvents() || [];

    const recurringEvents = allEvents?.filter(
      (x) => x.extendedProps?.isRecurring
    );
    const nonRecurringEvents = allEvents?.filter(
      (x) => !x.extendedProps?.isRecurring
    );

    const updateSchedule = new UpdateTimeSlots(
      recurringEvents?.map((ev) => {
        return {
          id: ev.id.toString(),
          title: ev?.title,
          dayOfTheWeek: ev?.extendedProps?.dayOfTheWeek,
          endStr: ev.endStr,
          startStr: ev.startStr,
          maxInSlot: ev?.extendedProps?.maxInSlot,
          isHoliday: false,
          isRecurring: true,
          color: '',
        };
      }),
      nonRecurringEvents?.map((ev) => {
        return {
          id: ev.id.toString(),
          title: ev?.title,
          dayOfTheWeek: ev?.extendedProps?.dayOfTheWeek,
          endStr: ev.endStr,
          startStr: ev.startStr,
          maxInSlot: ev?.extendedProps?.maxInSlot,
          isHoliday: ev?.extendedProps?.isHoliday ?? false,
          isRecurring: false,
          absoluteDate: moment(ev.event?.start).format(EVENT_DATE_ONLY_FORMAT),
          color: '',
        };
      }),
      location?.value || '',
      [0, 1, 2, 3, 4, 5, 6],
      startDate,
      endDate,
      forceEmptyCalendar
    );

    if (recurringEvents.length < 1 && !forceEmptyCalendar) {
      setDisplayUpdateWarningDialog(true);
    } else {
      const response = await calendarService.updateTimeslots(updateSchedule);
      if (response.isOk) {
        notifySuccess(translations.changesSaved[lang]);
        setCalendarUpdated(false);
        const locationId = Number(location?.value);
        if (!isNaN(locationId)) {
          getCalendar(locationId, false);
          getRecurringCalendar(locationId);
        }
      } else {
        notifyError(translations.saveChangesError[lang]);
      }
    }
  }

  function confirmUpdateWarningDialog() {
    setDisplayUpdateWarningDialog(false);
    saveCalendarChanges(true);
  }

  function confirmCalendarUpdateDialog() {
    setCalendarUpdatedDialog(false);
    if (calendarUpdatedDialogDirection === 'next') {
      calendarApi?.next();
    } else if (calendarUpdatedDialogDirection === 'prev') {
      calendarApi?.prev();
    }
  }

  function checkForUpdates() {
    const calendarApi = selectInfo?.view.calendar;
    const allEvents = calendarApi?.getEvents() || [];
    const allEventsCalendar = allEvents
      .map((ev) => {
        return {
          title: ev?.title,
          start: moment(ev.startStr).format(EVENT_DATE_FORMAT),
          end: moment(ev.endStr).format(EVENT_DATE_FORMAT),
          dayOfTheWeek: ev?.extendedProps?.dayOfTheWeek,
          maxInSlot: ev?.extendedProps?.maxInSlot,
        };
      })
      .sort((a: any, b: any) => {
        return a.start?.localeCompare(b.start as string);
      });

    const newCalendar = calendar
      ?.map((ev) => {
        return {
          title: ev?.title,
          start: ev.start,
          end: ev.end,
          dayOfTheWeek: ev.dayOfTheWeek,
          maxInSlot: ev.maxInSlot,
        };
      })
      .sort((a: any, b: any) => {
        return a.start?.localeCompare(b.start as string);
      });

    setCalendarUpdated(
      !(JSON.stringify(allEventsCalendar) === JSON.stringify(newCalendar))
    );
  }

  function renderEventContent(eventInfo: any) {
    var timeText = eventInfo.timeText;
    if (eventInfo.event && eventInfo.event.startStr && eventInfo.event.endStr) {
      timeText = `${moment(eventInfo.event.startStr).format(
        'HH:mm'
      )} - ${moment(eventInfo.event.endStr).format('HH:mm')}`;
    }

    return (
      <>
        {!!eventInfo.event?.extendedProps?.isHoliday && (
          <Typography component={'div'}>
            <b>{translations.holiday[lang]}</b>
            <span>&nbsp;</span>
            <i>
              {/* {eventInfo?.event?.title} */}
              <div>
                <Typography component={'div'} variant={'subtitle2'}>
                  {translations.allDay[lang]}
                </Typography>
              </div>
            </i>
          </Typography>
        )}

        {!eventInfo.event?.extendedProps?.isHoliday && (
          <Typography component={'div'}>
            <b>{timeText}</b>
            <span>&nbsp;</span>
            <i>
              {/* {eventInfo?.event?.title} */}
              {!!eventInfo.event?.extendedProps?.maxInSlot &&
                !eventInfo.event?.extendedProps?.isHoliday && (
                  <div>
                    {eventInfo.event?.extendedProps?.isRecurring && (
                      <SyncIcon className={classes.syncIcon} />
                    )}

                    <Typography component={'div'} variant={'subtitle2'}>
                      {`${translations.bookings[lang]}: ${
                        eventInfo?.event?.extendedProps?.bookingCount || 0
                      } / ${numberWithCommas(
                        calculateMaxPerSlot(
                          eventInfo?.event?.startStr,
                          eventInfo?.event?.endStr,
                          eventInfo?.event?.extendedProps?.maxInSlot
                        )
                      )}`}
                    </Typography>
                    <Typography component={'div'} variant={'subtitle2'}>
                      {eventInfo?.event?.extendedProps?.maxInSlot}{' '}
                      {translations.per15min[lang]}
                    </Typography>
                  </div>
                )}
            </i>
          </Typography>
        )}
      </>
    );
  }
  // might be useful later
  /*function calcHowManyFreeSlots(
    startStr: string,
    endStr: string,
    isRecurring: boolean,
    maxInSlot: number
  ) {
    var howManyBooked = calcHowManyBookedInSlot(
      startStr,
      endStr,
      true,
      isRecurring
    );
    var totalMax = calculateMaxPerSlot(startStr, endStr, maxInSlot);
    if (howManyBooked !== undefined && totalMax !== undefined) {
      return Number(totalMax) - howManyBooked;
    } else {
      return totalMax;
    }
  }*/
  function calcHowManyBookedPerDay(startStr: string) {
    if (rawTimeSlots && rawTimeSlots !== null) {
      var day = rawTimeSlots.find(
        (x) =>
          moment(x.absoluteDate).format('YYYY-MM-DD') ===
          moment(startStr).format('YYYY-MM-DD')
      );
      var count = 0;
      if (day) {
        count = day?.bookingCount;
      }
      return count;
    }
  }
  function calcHowManyBookedInSlot(startStr: string, endStr: string) {
    if (rawTimeSlots && rawTimeSlots !== null) {
      var day = rawTimeSlots.find(
        (x) =>
          moment(x.absoluteDate).format('YYYY-MM-DD') ===
          moment(startStr).format('YYYY-MM-DD')
      );
      var count = 0;
      if (day) {
        for (let index = 0; index < day.timeSlots.length; index++) {
          const slot = day.timeSlots[index];
          let momentFromDate = moment(startStr)
            .startOf('day')
            .add(slot.fromTime.hours, 'hours')
            .add(slot.fromTime.minutes, 'minutes')
            .format(EVENT_DATE_FORMAT);
          let momentToDate = moment(startStr)
            .startOf('day')
            .add(slot.toTime.hours, 'hours')
            .add(slot.toTime.minutes, 'minutes')
            .format(EVENT_DATE_FORMAT);
          if (
            !(momentFromDate < moment(startStr).format(EVENT_DATE_FORMAT)) &&
            !(momentToDate > moment(endStr).format(EVENT_DATE_FORMAT))
          ) {
            count += slot.bookingCount;
          }
        }
      }
      return Number(count);
    } else {
      return 0;
    }
  }
  function isRecurringEnabled(startStr: string) {
    //recurring is only enabled if we have only recurring events on the date
    let nonRecurringEvents = calendarApi
      ?.getEvents()
      ?.filter(
        (x) =>
          moment(x.startStr).format(EVENT_DATE_ONLY_FORMAT) ===
            moment(startStr).format(EVENT_DATE_ONLY_FORMAT) &&
          !x.extendedProps?.isRecurring
      );
    var isEnabled = !nonRecurringEvents || nonRecurringEvents.length < 1;
    //setRecurringEnabled(isEnabled);
    return isEnabled;
  }

  function renderDialogs() {
    return (
      <>
        <ScheduleDialog
          open={showDialog}
          currentEvent={currentEvent}
          editEvent={editEvent}
          onCreateEvent={createEvent}
          onUpdateEvent={updateEvent}
          onDeleteEvent={deleteEvent}
          onCancel={cancelCreateEvent}
          recurringEnabled={true}
          currentBookingCount={currentNumberOfBookings}
          currentBookingCountForDay={currentBookingCountForDay}
          showBookings={true}
        ></ScheduleDialog>
        <ConfirmDialog
          title={translations.unsavedChanges[lang]}
          text={`${translations.unsavedChangesText[lang]}?`}
          open={calendarUpdatedDialog}
          onCancel={() => setCalendarUpdatedDialog(false)}
          onConfirm={confirmCalendarUpdateDialog}
          error
          confirmText={translations.continue[lang]}
          cancelText={translations.back[lang]}
        />
        <ConfirmDialog
          title={translations.noSchedule[lang]}
          text={translations.warningUpdateNoSchedule[lang]}
          open={displayUpdateWarningDialog}
          onCancel={() => setDisplayUpdateWarningDialog(false)}
          onConfirm={confirmUpdateWarningDialog}
          error
          confirmText={translations.save[lang]}
          cancelText={translations.cancel[lang]}
        />
        <ViewScheduleDialog
          open={scheduleDialogOpen}
          onClose={() => setScheduleDialogOpen(false)}
          locationId={location.value}
        />
      </>
    );
  }

  function renderOverflowSwitches() {
    return (
      <div className={classes.switchContainer}>
        <Button
          style={{ marginRight: theme.spacing(1) }}
          padding={false}
          onClick={() => setScheduleDialogOpen(true)}
        >
          <ScheduleIcon />
        </Button>
        <Button
          padding={false}
          onClick={() => history.push('/location-settings')}
        >
          {translations.back[lang]}
        </Button>
      </div>
    );
  }
};

export default Schedule;
