import borderControlStatuses from '../assets/json/borderControlStatuses.json';
import preScreeningStatuses from '../assets/json/preScreeningStatuses.json';
import observationStatuses from '../assets/json/observationStatuses.json';
import residenceStatuses from '../assets/json/residenceStatuses.json';
import countryStatuses from '../assets/json/countryStatuses.json';
import certificateType from '../assets/json/certificateType.json';
import flowStatusesCompleted from '../assets/json/flowStatusesCompleted.json';
import flowStatusesActive from '../assets/json/flowStatusesActive.json';
import accessList from '../assets/json/accessList.json';
import { IFormValues as ISearchFormValues } from '../components/SearchForm';
import { IFormValues as ICreatePassengerFormValues } from '../components/CreatePassengerForm';
import { IFormValues as IUserFormValues } from '../components/UserForm';
import { IFormValues as IResidenceSearchFormValues } from '../pages/ResidenceSearchPage';
import {
  accessControl,
  accessListType,
  borderControlStatus,
} from '../constants/enums';
import {
  checkIfRequireQuarantineResidency,
  compareFlowDate,
  getLocationLabel,
  getLocationOptions,
  toISOStringTimezone,
} from '../utils';
import { IVaccinationRegion } from '../interfaces/vaccinationRegion';
import { IResidenceLocation } from '../interfaces/residenceLocation';
import moment from 'moment';
import { DATE_AND_TIME_FORMAT, DATE_FORMAT } from '../constants';
import { CalendarEvent } from '../components/sampling/schedule/Schedule';
import transitions from '../assets/json/translations.json';

export class Passenger {
  firstName: string;
  lastName: string;
  serialNumber: string;
  sampleId: string;
  dateOfBirth: string;
  flightNumber: string;
  dateOfArrival: string;
  borderControlStatus: Partial<BorderControlStatus>;
  preScreeningStatus: PreScreeningStatus;
  residences: Array<Residence>;
  numberOfChildren: number;
  travelDetails?: string;
  isSecondTestCandidate: boolean;
  nationality: string;
  countryOfResidence: string;
  countryOfDeparture: string;
  residenceInfo: string;
  isSymtomSampleRequest?: boolean;
  willStayLongerThanTenDays: string;
  sampleTaken?: string;
  linkedSampling?: LinkedSampling;
  phoneNumbers: PhoneNumber[];
  email?: string;
  ssn?: string;
  observationStatus?: ObservationStatus;
  sampleTakenLocation?: string;
  skipSecondTestAndPcrBecauseOfAge: boolean;
  skipSecondTestAndPcrBecauseOfValidCertificate: boolean;
  requireQuarantineResidency: boolean;
  departureFromIceland?: string;
  countryStatus?: CountryStatus;
  flowStatusesCompleted?: FlowStatusCompleted[];
  flowStatusesActive?: FlowStatusActive;
  extraSamplingReqired: boolean;
  extraSamplingSelectedTestLocation: number;
  ainSamplingBarcode?: string;

  constructor(passenger: any) {
    const locations = getLocationOptions();

    this.firstName = passenger !== undefined ? passenger['firstName'] : '';
    this.lastName = passenger !== undefined ? passenger['lastName'] : '';
    this.serialNumber = passenger !== undefined ? passenger['barCode'] : '';
    this.sampleId =
      passenger !== undefined ? passenger['observationIdentifier'] : '';
    this.dateOfBirth = passenger !== undefined ? passenger['dateOfBirth'] : '';
    this.flightNumber =
      passenger !== undefined ? passenger['flightNumber'] : '';
    this.dateOfArrival =
      passenger !== undefined ? passenger['dateOfArrival'] : '';
    this.borderControlStatus = new BorderControlStatus(passenger['status']);
    this.residences =
      passenger !== undefined
        ? passenger.residences &&
          passenger.residences.map((i: any) => new Residence(i))
        : null;
    this.numberOfChildren =
      passenger !== undefined ? passenger.numberOfChildren : 0;
    this.travelDetails =
      passenger !== undefined ? passenger['travelDetails'] : '';
    this.isSecondTestCandidate =
      passenger !== undefined ? passenger['isSecondTestCandidate'] : false;
    this.nationality = passenger !== undefined ? passenger['nationality'] : '';
    this.countryOfResidence =
      passenger !== undefined ? passenger['countryOfResidence'] : '';
    this.countryOfDeparture =
      passenger !== undefined ? passenger['countryOfDeparture'] : '';
    this.residenceInfo = passenger !== undefined ? passenger.residenceInfo : '';
    this.isSymtomSampleRequest =
      passenger !== undefined ? passenger.isSymtomSampleRequest : false;
    this.willStayLongerThanTenDays =
      passenger !== undefined && passenger['willStayLongerThanTenDays'] !== null
        ? passenger['willStayLongerThanTenDays'] === true
          ? 'yes'
          : 'no'
        : 'unAnswered';
    this.sampleTaken =
      passenger !== undefined ? passenger['sampleTaken'] : undefined;
    this.linkedSampling =
      passenger !== undefined && passenger['linkedBarcodeInfo']
        ? new LinkedSampling(passenger['linkedBarcodeInfo'])
        : undefined;
    this.phoneNumbers =
      passenger !== undefined
        ? passenger.phoneNumbers &&
          passenger.phoneNumbers.map((i: any) => new PhoneNumber(i))
        : null;
    this.email =
      passenger !== undefined && passenger['email']
        ? passenger['email']
        : undefined;
        this.ssn =
        passenger !== undefined && passenger['ssn']
          ? passenger['ssn']
          : undefined;
    this.observationStatus =
      passenger !== undefined
        ? passenger['observationStatus'] !== null
          ? new ObservationStatus(passenger['observationStatus'])
          : undefined
        : undefined;
    this.sampleTakenLocation = passenger.sampleTakenLocation
      ? getLocationLabel(passenger.sampleTakenLocation, locations) || undefined
      : undefined;
    this.skipSecondTestAndPcrBecauseOfAge = Boolean(
      passenger && passenger.skipSecondTestAndPcrBecauseOfAge
    );
    this.skipSecondTestAndPcrBecauseOfValidCertificate = Boolean(
      passenger && passenger.skipSecondTestAndPcrBecauseOfValidCertificate
    );
    this.preScreeningStatus = new PreScreeningStatus(
      passenger['negPCRCertificateStatus']
    );
    this.requireQuarantineResidency = checkIfRequireQuarantineResidency(
      passenger.countryStatus === null || passenger.countryStatus === undefined
        ? -1
        : passenger.countryStatus
    );

    this.departureFromIceland =
      passenger?.dateOfDepartureFromIceland || undefined;
    this.countryStatus =
      passenger?.countryStatus !== undefined
        ? new CountryStatus(passenger.countryStatus)
        : undefined;
    this.flowStatusesCompleted =
      (passenger &&
        passenger.flowStatuses &&
        passenger.flowStatuses
          .sort(compareFlowDate)
          .map((item: any) => new FlowStatusCompleted(item))) ||
      undefined;
    this.flowStatusesActive =
      passenger && passenger.nextStatus && passenger.nextStatus !== 8
        ? new FlowStatusActive(passenger.nextStatus)
        : undefined;

    this.extraSamplingReqired = passenger?.extraSamplingReqired ? true : false;
    this.extraSamplingSelectedTestLocation =
      passenger?.extraSamplingSelectedTestLocation === 0 ? 0 : 1;
    this.ainSamplingBarcode =
      passenger !== undefined && passenger['ainSamplingBarcode']
        ? passenger['ainSamplingBarcode']
        : undefined;
  }
}

export class Residence {
  id: number;
  timeOfStayFrom: string;
  timeOfStayTo: string;
  addressStreet: string;
  addressNo: string;
  addressApartment: string;
  city: string;
  nameOfHotel: string;
  postalCode: string;

  constructor(residence: any) {
    this.id = residence !== undefined ? residence.id : 0;
    this.timeOfStayFrom =
      residence !== undefined ? residence.timeOfStayFrom : '';
    this.timeOfStayTo = residence !== undefined ? residence.timeOfStayTo : '';
    this.addressStreet = residence !== undefined ? residence.addressStreet : '';
    this.addressNo = residence !== undefined ? residence.addressNo : '';
    this.addressApartment =
      residence !== undefined ? residence.addressApartment : '';
    this.city = residence !== undefined ? residence.city : '';
    this.nameOfHotel = residence !== undefined ? residence.nameOfHotel : '';
    this.postalCode = residence !== undefined ? residence.postalCode : '';
  }
}

export class ResidencePerson {
  id: number;
  name: string;
  serialNumber: string;
  sampleId: string;
  dateOfBirth: string;
  flightNumber: string;
  dateOfArrival: string;
  borderControlStatus: Partial<BorderControlStatus>;
  preScreeningStatus: PreScreeningStatus;
  residences: Array<Residence>;
  numberOfChildren: number;
  travelDetails?: string;
  isSecondTestCandidate: boolean;
  nationality: string;
  countryOfResidence: string;
  countryOfDeparture: string;
  residenceInfo: string;
  isSymtomSampleRequest?: boolean;
  willStayLongerThanTenDays: string;
  sampleTaken?: string;
  linkedSampling?: LinkedSampling;
  phoneNumber: string;
  email?: string;
  observationStatus?: ObservationStatus;
  residenceStatus?: ResidenceStatus;
  sampleTakenLocation?: string;
  skipSecondTestAndPcrBecauseOfAge: boolean;
  skipSecondTestAndPcrBecauseOfValidCertificate: boolean;
  ssn?: string;
  gender: string;
  firstSampleDate: string;
  checkedInRoomNumber: string;
  checkedInDate?: string;
  checkedOutDate?: string;
  comment: string;
  food: string;
  estimatedDischargeDate: string | null;
  residenceLocation?: IResidenceLocation;

  constructor(passenger: any) {
    const locations = getLocationOptions();
    this.id = passenger !== undefined ? passenger.id : 0;
    this.name = passenger?.personName ? passenger.personName : '';

    this.serialNumber = passenger !== undefined ? passenger['barCode'] : '';
    this.sampleId =
      passenger !== undefined ? passenger['observationIdentifier'] : '';
    this.dateOfBirth = passenger !== undefined ? passenger['dateOfBirth'] : '';
    this.flightNumber = passenger?.flightNumber ? passenger.flightNumber : '';
    this.dateOfArrival = passenger?.created ? passenger.created : '';

    this.borderControlStatus = new BorderControlStatus(passenger['status']);
    this.residences =
      passenger !== undefined
        ? passenger.residences &&
          passenger.residences.map((i: any) => new Residence(i))
        : null;
    this.numberOfChildren =
      passenger !== undefined ? passenger.numberOfChildren : 0;
    this.travelDetails =
      passenger !== undefined ? passenger['travelDetails'] : '';
    this.isSecondTestCandidate =
      passenger !== undefined ? passenger['isSecondTestCandidate'] : false;
    this.nationality = passenger?.nationality ? passenger.nationality : '';
    this.countryOfResidence =
      passenger !== undefined ? passenger['countryOfResidence'] : '';
    this.countryOfDeparture = passenger.countryOfDeparture
      ? passenger?.countryOfDeparture
      : '';
    this.residenceInfo = passenger !== undefined ? passenger.residenceInfo : '';
    this.isSymtomSampleRequest =
      passenger !== undefined ? passenger.isSymtomSampleRequest : false;
    this.willStayLongerThanTenDays =
      passenger !== undefined && passenger['willStayLongerThanTenDays'] !== null
        ? passenger['willStayLongerThanTenDays'] === true
          ? 'yes'
          : 'no'
        : 'unAnswered';
    this.sampleTaken =
      passenger !== undefined ? passenger['sampleTaken'] : undefined;
    this.linkedSampling =
      passenger !== undefined && passenger['linkedBarcodeInfo']
        ? new LinkedSampling(passenger['linkedBarcodeInfo'])
        : undefined;
    this.phoneNumber =
      passenger !== undefined ? passenger.primaryPhoneNumber : '';
    this.email =
      passenger !== undefined && passenger['email']
        ? passenger['email']
        : undefined;
    this.observationStatus =
      passenger !== undefined
        ? passenger['observationStatus'] !== null
          ? new ObservationStatus(passenger['observationStatus'])
          : undefined
        : undefined;
    this.residenceStatus =
      passenger !== undefined
        ? passenger.status !== null
          ? new ResidenceStatus(passenger.status)
          : undefined
        : undefined;
    this.sampleTakenLocation = passenger.sampleTakenLocation
      ? getLocationLabel(passenger.sampleTakenLocation, locations) || undefined
      : undefined;
    this.skipSecondTestAndPcrBecauseOfAge = Boolean(
      passenger && passenger.skipSecondTestAndPcrBecauseOfAge
    );
    this.skipSecondTestAndPcrBecauseOfValidCertificate = Boolean(
      passenger && passenger.skipSecondTestAndPcrBecauseOfValidCertificate
    );
    this.preScreeningStatus = new PreScreeningStatus(
      this.skipSecondTestAndPcrBecauseOfAge
        ? { id: 5001 }
        : passenger['negPCRCertificateStatus']
    );
    this.ssn =
      passenger !== undefined
        ? passenger.personSsn && passenger.personSsn
        : undefined;
    this.gender = passenger?.gender ? passenger.gender : '';
    this.firstSampleDate =
      passenger !== undefined ? passenger['firstSampleDate'] : undefined;
    this.checkedInRoomNumber = passenger?.checkedInRoomNumber
      ? passenger.checkedInRoomNumber
      : '';
    this.checkedInDate = passenger?.checkedInDate
      ? passenger.checkedInDate
      : undefined;
    this.checkedOutDate = passenger?.checkedOutDate
      ? passenger.checkedOutDate
      : undefined;
    this.comment = passenger?.comment ? passenger.comment : '';
    this.food = passenger?.foodWishes ? passenger.foodWishes : '';
    this.estimatedDischargeDate = passenger?.estimatedDischargeDate
      ? passenger.estimatedDischargeDate
      : null;
    this.residenceLocation = passenger?.residenceLocation
      ? (passenger.residenceLocation as IResidenceLocation)
      : undefined;
  }
}

export class PhoneNumber {
  code: string;
  number: string;
  isMainNumber: boolean;
  isVerified: boolean;

  constructor(phoneNumber: any) {
    this.code = phoneNumber.code;
    this.number = phoneNumber.number;
    this.isMainNumber = phoneNumber.isMainNumber;
    this.isVerified = phoneNumber.isVerified;
  }
}

export class ObservationStatus {
  id: number;
  description: { is: string; en: string };

  constructor(observationStatus: any) {
    this.id = observationStatus !== undefined ? observationStatus.id : '';
    const status = observationStatuses.find((s) => s.id === this.id);
    this.description = status ? status.description : { is: '', en: '' };
  }
}

export class CountryStatus {
  id: number;
  description: { is: string; en: string };

  constructor(countryStatus: any) {
    this.id = countryStatus !== undefined ? countryStatus : '';
    const status = countryStatuses.find((s) => s.id === this.id);
    this.description = status ? status.description : { is: '', en: '' };
  }
}

export class CertificateType {
  id: number;
  description: { is: string; en: string };

  constructor(certificate: any) {
    this.id = certificate !== undefined ? certificate : 0;
    const status = certificateType.find((s) => s.id === this.id);
    this.description = status ? status.description : { is: '', en: '' };
  }
}

export class EuDccCertificate {
  ci: string;
  typeTranslation: { is: string; en: string }; 
  type: string;
  date: string;

  constructor(certificate: any) {
    this.ci = certificate?.ci ? certificate.ci : '';
    this.typeTranslation = certificate?.certificateType === 'Vaccination' ? transitions.vaccination : transitions.recovery;
    this.type = certificate?.certificateType ? certificate.certificateType : '';
    this.date = certificate?.date ? certificate.date : '';

  }
}

export class ResidenceStatus {
  id: number;
  description: { is: string; en: string };

  constructor(residenceStatus: any) {
    this.id = residenceStatus !== undefined ? residenceStatus : '';
    const status = residenceStatuses.find((s) => s.id === this.id);
    this.description = status ? status.description : { is: '', en: '' };
  }
}

export class BorderControlStatus {
  id: number;
  description: { is: string; en: string };

  constructor(borderControlStatus: any) {
    this.id =
      borderControlStatus !== undefined ? borderControlStatus['id'] : '';
    const status = borderControlStatuses.find((s) => s.id === this.id);
    this.description = status ? status.description : { is: '', en: '' };
  }
}

export class PreScreeningStatus {
  id: number;
  description: { is: string; en: string };

  constructor(preScreeningStatus: any) {
    this.id = preScreeningStatus !== undefined ? preScreeningStatus['id'] : 0;
    const status = preScreeningStatuses.find((s) => s.id === this.id);
    this.description = status ? status.description : { is: '', en: '' };
  }
}

export class FlowStatus {
  id: number;
  description: { is: string; en: string };
  descriptionDetails?: { is: string; en: string };
  createdDate?: string;
  changeByName?: string;
  changeBySsn?: string;

  constructor(flowStatus: any) {
    this.id = flowStatus !== undefined ? flowStatus.id : '';
    this.description = flowStatus.description
      ? flowStatus.description
      : { is: '', en: '' };
    this.descriptionDetails = flowStatus.descriptionDetails
      ? flowStatus.descriptionDetails
      : undefined;
    this.createdDate = flowStatus.createdDate
      ? flowStatus.createdDate
      : undefined;
    this.changeByName = flowStatus.changeByName;
    this.changeBySsn = flowStatus.changeBySsn;
  }
}

export class FlowStatusCompleted {
  id: number;
  description: { is: string; en: string };
  createdDate?: string;

  constructor(flowStatus: any) {
    this.id = flowStatus !== undefined ? flowStatus.status : '';
    const task = flowStatusesCompleted.find((s: any) => s.id === this.id);
    this.description = task ? task.description : { is: '', en: '' };
    this.createdDate =
      flowStatus !== undefined ? flowStatus.created : undefined;
  }
}

export class FlowStatusActive {
  id: number;
  description: { is: string; en: string };
  descriptionDetails: { is: string; en: string };

  constructor(flowStatus: any) {
    this.id = flowStatus !== undefined ? flowStatus : '';
    const task = flowStatusesActive.find((s: any) => s.id === this.id);
    this.description = task ? task.description : { is: '', en: '' };
    this.descriptionDetails = task
      ? task.descriptionDetails
      : { is: '', en: '' };
  }
}

export class User {
  mobile: string;
  ssn: string;
  name: string;
  location: string;
  accessList: Array<AccessItem>;
  hasError: boolean;
  sessionId: string;
  encodedSessionId: string;
  loginType: 'sampling' | 'vaccination';
  isRapidTestLocation: boolean;
  allowSampleTypeConversion: boolean;

  constructor(user: any) {
    this.mobile = user !== undefined ? user['mobile'] : null;
    this.ssn = user !== undefined ? user['ssn'] : null;
    this.name = user !== undefined ? user['name'] : null;
    this.location = user !== undefined ? user['location'] : null;
    this.accessList =
      user !== undefined
        ? user.accessList && user.accessList.map((i: any) => new AccessItem(i))
        : null;
    this.hasError = user !== undefined ? user['hasError'] : null;
    this.sessionId = user !== undefined ? user.sessionId : '';
    this.encodedSessionId = btoa(this.sessionId);
    this.encodedSessionId = btoa(this.sessionId);
    this.loginType =
      user !== undefined && user.loginType === 'vaccination'
        ? 'vaccination'
        : 'sampling';
    this.isRapidTestLocation =
      user !== undefined ? user['isRapidTestLocation'] : false;
    this.allowSampleTypeConversion =
      user !== undefined ? user['allowSampleTypeConversion'] : false;
  }
}

export class AccessItem {
  id: number;
  description: string;

  constructor(accessItem: any) {
    this.id = accessItem !== undefined ? accessItem['id'] : 0;
    this.description =
      accessItem !== undefined ? accessItem['description'] : '';
  }
}

export class SearchQuery {
  barCode?: string;
  firstName?: string;
  lastName?: string;
  flightNumber?: string;
  dateOfBirth?: string;
  arrivalsFromDate?: string;
  arrivalsToDate?: string;
  observationIdentifier?: string;
  ssn?: string;
  email?: string;
  phoneNumber?: string;

  constructor(form: ISearchFormValues) {
    this.barCode = form.sampleId || undefined;
    this.firstName = form.firstName || undefined;
    this.lastName = form.lastName || undefined;
    this.flightNumber = form.flightNumber || undefined;
    this.dateOfBirth = form.dateOfBirth
      ? form.dateOfBirth.toISOString()
      : undefined;
    this.arrivalsFromDate = form.arrivalsFromDate
      ? form.arrivalsFromDate.toISOString()
      : undefined;
    this.arrivalsToDate = form.arrivalsToDate
      ? form.arrivalsToDate.toISOString()
      : undefined;
    this.observationIdentifier = form.samplingNumber || undefined;
    this.ssn = form.ssn || undefined;
    this.email = form.email || undefined;
    this.phoneNumber = form.phoneNumber
      ? `${form.callingCode?.code || ''}${form.phoneNumber}`
      : undefined;
  }
}

export class CreatePassenger {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  countryOfDepartureCode: string;
  flightNumber?: string;
  arrivalDate: string;
  phoneCountryCode?: string;
  phoneNumber?: string;
  email?: string;
  ssn?: string;
  testType?: number;
  nationality: string;
  countryOfResidence: string;
  testLocation: number | null;
  isSymptomSampling?: boolean;
  residenceInfo: string;
  addressNo: string;
  addressApartment: string;
  city: string;
  postalCode: string;
  nameOfHotel: string;
  dateOfDepartureFromIceland?: string;
  samplingRequired?: boolean;
  selectedTestLocation?: number;
  isRapidTest?: boolean;

  constructor(form: ICreatePassengerFormValues) {
    this.firstName = form.firstName;
    this.lastName = form.lastName;
    this.dateOfBirth = form.dateOfBirth!.toISOString();
    this.gender = form.sex;
    this.countryOfDepartureCode = form.countryOfDeparture;
    this.flightNumber = form.flightNumber || undefined;
    this.arrivalDate = form.arrivalDate!.toISOString();
    this.phoneCountryCode = form.callingCode
      ? form.callingCode.value
      : undefined;
    this.phoneNumber = form.phoneNumber || undefined;
    this.email = form.email || undefined;
    this.ssn = form.ssn || undefined;
    this.testType =
      parseInt(form.testType) ===
        borderControlStatus.REGISTERED_FOR_SYMPTOM_SAMPLING ||
      parseInt(form.testType) === borderControlStatus.REGISTERED_FOR_RAPID_TEST
        ? borderControlStatus.REGISTERED_FOR_SAMPLING
        : parseInt(form.testType);
    this.nationality = form.nationality;
    this.countryOfResidence = form.countryOfResidence;
    this.testLocation = form.testLocation ? parseInt(form.testLocation) : null;
    this.isSymptomSampling = form.testLocation ? true : false;
    this.residenceInfo = form.residenceStreetName;
    this.addressNo = form.residenceStreetNumber;
    this.addressApartment = form.residenceApartmentNumber;
    this.city = form.residenceCity;
    this.postalCode = form.residencePostalCode;
    this.nameOfHotel = form.residenceAddressDetails;
    this.dateOfDepartureFromIceland = form.departureFromIceland
      ? toISOStringTimezone(form.departureFromIceland)
      : undefined;
    this.samplingRequired = form.extraSamplingReqired;
    this.selectedTestLocation = form.extraSamplingSelectedTestLocation;
    this.isRapidTest =
      form.testType ===
        borderControlStatus.REGISTERED_FOR_RAPID_TEST.toString() || undefined;
  }
}

export class CreateUser {
  name: string;
  userAccesses: number[];
  userVaccinationRegionAccesses?: number[];
  userResidenceLocationAccesses?: number[];
  samplingLocationsAccesses?: number[];

  constructor(form: IUserFormValues) {
    const access = form.borderControlAccess
      .map((item) => parseInt(item.value))
      .concat(form.vaccinationAccess.map((item) => parseInt(item.value)));
    this.name = form.name;
    this.userAccesses =
      (access.includes(accessControl.QUARANTINE_CONTROL) ||
        access.includes(accessControl.SAMPLING)) &&
      !access.includes(accessControl.SEARCH)
        ? [...access, accessControl.SEARCH]
        : access;
    this.userVaccinationRegionAccesses = form.vaccinationRegions.map((r) =>
      parseInt(r.value)
    );
    this.userResidenceLocationAccesses = form.residenceLocations.map((r) =>
      parseInt(r.value)
    );
    this.samplingLocationsAccesses = form.samplingLocations.map((r) =>
      parseInt(r.value)
    );
  }
}

export class UserItem {
  name: string;
  ssn: string;
  access: {
    id: number;
    description: { is: string; en: string };
  }[];
  borderControlAccess: {
    id: number;
    description: { is: string; en: string };
  }[];
  vaccinationAccesses: {
    id: number;
    description: { is: string; en: string };
  }[];
  vaccinationRegions?: IVaccinationRegion[];
  residenceLocations?: IResidenceLocation[];
  samplingLocations?: IResidenceLocation[];

  constructor(user: any) {
    const borderControlAccess: {
      id: number;
      description: { is: string; en: string };
    }[] = [];
    const vaccinationAccesses: {
      id: number;
      description: { is: string; en: string };
    }[] = [];

    if (user && user.userAccesses) {
      user.userAccesses.forEach((a: any) => {
        const accessItem = accessList.find(
          (item) =>
            item.id === a.id &&
            item.accessGroup === accessListType.BORDER_CONTROL
        );
        if (accessItem) {
          borderControlAccess.push(accessItem);
        } else {
        }
      });
      user.userAccesses.forEach((a: any) => {
        const accessItem = accessList.find(
          (item) =>
            item.id === a.id && item.accessGroup === accessListType.VACCINATION
        );
        if (accessItem) {
          vaccinationAccesses.push(accessItem);
        }
      });
    }
    this.borderControlAccess = borderControlAccess;
    this.vaccinationAccesses = vaccinationAccesses;
    // keeping all accesses in one array might be useful for other things
    this.access = this.borderControlAccess.concat(this.vaccinationAccesses);

    this.name = user && user.name ? user.name : '';
    this.ssn = user && user.ssn ? user.ssn : '';
    this.vaccinationRegions = user.vaccinationLocationAccesses
      ? user.vaccinationLocationAccesses
      : null;
    this.residenceLocations = user.residenceLocationListModel
      ? user.residenceLocationListModel
      : null;
    this.samplingLocations = user.samplingLocationAccesses
      ? user.samplingLocationAccesses
      : null;
  }
}

export class Transaction {
  transactionNo: string;
  authorizationNo: string;
  amountPaid: number;
  maskedCardNo: string;
  cardType: string;

  constructor(transaction: any) {
    this.transactionNo =
      transaction && transaction.transactionNo ? transaction.transactionNo : '';
    this.authorizationNo =
      transaction && transaction.authorizationNo
        ? transaction.authorizationNo
        : '';
    this.amountPaid =
      transaction && transaction.amountPaid ? transaction.amountPaid : 0;
    this.maskedCardNo =
      transaction && transaction.maskedCardNo ? transaction.maskedCardNo : '';
    this.cardType =
      transaction && transaction.cardType ? transaction.cardType : '';
  }
}

export class LinkedSampling {
  isFirst: boolean;
  linkedBarcode: string;

  constructor(linkedSampling: any) {
    this.isFirst = linkedSampling && linkedSampling.status === 1;
    this.linkedBarcode =
      linkedSampling && linkedSampling.linkedBarcode
        ? linkedSampling.linkedBarcode
        : '';
  }
}

export class SamplingSchedule {
  date: string;
  timeSlots: {
    timeSlot: string;
    totalNumberOfArrivals: number;
    finishedSampling: number;
  }[];

  constructor(schedule: any) {
    this.date = schedule && schedule.date ? schedule.date : '';
    this.timeSlots = schedule && schedule.timeSlots ? schedule.timeSlots : [];
  }
}

export class Location {
  id: string;
  key: string;
  label: string;

  constructor(location: any) {
    this.id = location && location.id;
    this.key = location && location.locationKey ? location.locationKey : '';
    this.label =
      location && location.locationDescription
        ? location.locationDescription
        : '';
  }
}

export class ExcelResidencePerson {
  name: string;
  ssn: string;
  gender: string;
  hotelArrivalDate: string;
  sampleTakenDate: string;
  nationality: string;
  countryOfDeparture: string;
  flightNumber: string;
  plannedCheckout: string;
  phoneNumber: string;
  email: string;

  getSsnOrDateOfBirth(residencePerson: ResidencePerson) {
    if (
      residencePerson?.ssn === undefined ||
      residencePerson?.ssn === null ||
      residencePerson?.ssn?.trim() === ''
    ) {
      return residencePerson.dateOfBirth
        ? moment(residencePerson.dateOfBirth)
            .format(DATE_FORMAT)
            .toString()
            .slice(0, 10)
        : '';
    }
    return residencePerson.ssn;
  }

  constructor(residencePerson: ResidencePerson) {
    //ATTENTION: THE ORDER OF PROPERTIES MATTERS HERE!!!!
    this.name = residencePerson.name || '';
    this.ssn = this.getSsnOrDateOfBirth(residencePerson);
    this.gender = residencePerson.gender || '';
    this.hotelArrivalDate = residencePerson.dateOfArrival
      ? moment(residencePerson.dateOfArrival).format(DATE_AND_TIME_FORMAT)
      : '';
    this.sampleTakenDate = residencePerson.firstSampleDate
      ? moment(residencePerson.firstSampleDate).format(DATE_AND_TIME_FORMAT)
      : '';
    this.nationality = residencePerson.nationality || '';
    this.countryOfDeparture = residencePerson.countryOfDeparture || '';
    this.flightNumber = residencePerson.flightNumber || '';
    this.plannedCheckout = '';
    this.phoneNumber = residencePerson.phoneNumber || '';
    this.email = residencePerson.email || '';
  }
}

export class CheckInModel {
  ResidencePersonId: number;
  Comment: string;
  FoodWishes: string;

  constructor(passenger: any) {
    this.ResidencePersonId = passenger !== undefined ? passenger.id : 0;
    this.Comment = passenger !== undefined ? passenger.comment : '';
    this.FoodWishes = passenger !== undefined ? passenger.food : '';
  }
}

export class ResidenceSearchQuery {
  barcode?: string;
  name?: string;
  birthDate?: string;
  flightNumber?: string;
  locationId?: number;
  status?: number;
  arrivalDateFrom?: string;
  arrivalDateTo?: string;

  constructor(form: IResidenceSearchFormValues) {
    this.barcode = form.barcode || undefined;
    this.name = form.name || undefined;
    this.birthDate = form.dateOfBirth
      ? toISOStringTimezone(form.dateOfBirth)
      : undefined;
    this.flightNumber = form.flightNumber || undefined;
    this.locationId = form.location ? parseInt(form.location.value) : undefined;
    this.status = form.status ? parseInt(form.status.value) : undefined;
    this.arrivalDateFrom = form.arrivalDateFrom
      ? toISOStringTimezone(form.arrivalDateFrom)
      : undefined;
    this.arrivalDateTo = form.arrivalDateTo
      ? toISOStringTimezone(form.arrivalDateTo)
      : undefined;
  }
}

class Slot {
  minutes: number;
  hours: number;

  constructor(slot: any) {
    this.minutes = slot['minutes'];
    this.hours = slot['hours'];
  }
}

export class TimeSlot {
  fromTime: Slot;
  toTime: Slot;
  maxInSlot: number;
  overflow: boolean;
  bookingCount: number;
  dayOfTheWeek?: number;
  absoluteDate?: string;
  isHoliday?: boolean;

  constructor(timeslot: any) {
    this.fromTime = new Slot(timeslot['fromTime']);
    this.toTime = new Slot(timeslot['toTime']);
    this.maxInSlot = timeslot['maxInSlot'];
    this.overflow = timeslot['overflow'];
    this.dayOfTheWeek =
      timeslot?.dayOfTheWeek || timeslot?.dayOfTheWeek === 0
        ? timeslot.dayOfTheWeek
        : undefined;
    this.absoluteDate = timeslot.absoluteDate;
    this.isHoliday = timeslot.isHoliday;
    this.bookingCount = timeslot.bookingCount;
  }
}

export class DaySchedule {
  dayOfTheWeek: number;
  timeSlots: Array<TimeSlot>;
  absoluteDate: string;
  isHoliday: boolean;

  constructor(schedule: any) {
    this.dayOfTheWeek = schedule['dayOfTheWeek'];
    this.timeSlots = schedule['timeSlots'].map((t: any) => new TimeSlot(t));
    this.absoluteDate = schedule.absoluteDate;
    this.isHoliday = schedule.isHoliday;
  }
}

export class LocationSchedule {
  dayOfTheWeek: number;
  timeSlots: Array<TimeSlot>;
  absoluteDate: string;
  isRecurring: boolean;
  isHoliday: boolean;
  bookingCount: number;

  constructor(schedule: any) {
    this.dayOfTheWeek = schedule['dayOfTheWeek'];
    this.absoluteDate = schedule.absoluteDate;
    this.isRecurring = schedule.isDefault;
    this.isHoliday = schedule.isHoliday;
    this.timeSlots = schedule['timeSlots'].map((t: any) => new TimeSlot(t));
    this.bookingCount = schedule.bookingCount;
  }
}

export class UpdateTimeslot {
  locationId: number;
  updateDayTimeSlot: DaySchedule;

  constructor(
    calendarEvents: Array<CalendarEvent>,
    locationId: number,
    dayOfTheWeek: number
  ) {
    this.locationId = locationId;
    const timeSlots = calendarEvents.map((ev) => {
      return new TimeSlot({
        fromTime: new Slot({
          hours: moment(ev.startStr).format('HH'),
          minutes: moment(ev.startStr).format('mm'),
        }),
        toTime: new Slot({
          hours: moment(ev.endStr).format('HH'),
          minutes: moment(ev.endStr).format('mm'),
        }),
        maxInSlot: ev.maxInSlot,
        dayOfTheWeek: dayOfTheWeek,
      });
    });
    this.updateDayTimeSlot = new DaySchedule({
      locationId: locationId,
      timeSlots: timeSlots,
      dayOfTheWeek: dayOfTheWeek,
    });
  }
}

export class UpdateTimeSlots {
  updatedTimeSlots: DaySchedule[];
  nonRecurringTimeSlots: DaySchedule[];
  locationId: number;
  fromDate: string;
  toDate: string;
  force: boolean;

  constructor(
    calendarEvents: Array<CalendarEvent>,
    nonRecurringEvents: Array<CalendarEvent>,
    locationId: string,
    daysOfTheWeek: number[],
    fromDate: string,
    toDate: string,
    force: boolean
  ) {
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.force = force;

    const timeSlots = calendarEvents.map((ev) => {
      return new TimeSlot({
        fromTime: new Slot({
          hours: moment(ev.startStr).format('HH'),
          minutes: moment(ev.startStr).format('mm'),
        }),
        toTime: new Slot({
          hours: moment(ev.endStr).format('HH'),
          minutes: moment(ev.endStr).format('mm'),
        }),
        maxInSlot: ev.maxInSlot,
        dayOfTheWeek: ev.dayOfTheWeek,
      });
    });
    const nonRecurringTimeSlots = nonRecurringEvents.map((ev) => {
      return new TimeSlot({
        fromTime: new Slot({
          hours: moment(ev.startStr).format('HH'),
          minutes: moment(ev.startStr).format('mm'),
        }),
        toTime: new Slot({
          hours: moment(ev.endStr).format('HH'),
          minutes: moment(ev.endStr).format('mm'),
        }),
        maxInSlot: ev.maxInSlot,
        absoluteDate: moment(ev.startStr).format('YYYY-MM-DD'),
        isHoliday: ev.isHoliday,
      });
    });

    this.updatedTimeSlots = daysOfTheWeek.map(
      (item) =>
        new DaySchedule({
          timeSlots: timeSlots.filter((slot) => item === slot.dayOfTheWeek),
          dayOfTheWeek: item,
        })
    );
    var absoluteDays = nonRecurringTimeSlots.map((item) => item.absoluteDate);
    absoluteDays = absoluteDays.filter((n, i) => absoluteDays.indexOf(n) === i); // filter out duplicates
    this.nonRecurringTimeSlots = absoluteDays.map(
      (day) =>
        new DaySchedule({
          timeSlots: nonRecurringTimeSlots.filter(
            (slot) => day === slot.absoluteDate
          ),
          dayOfTheWeek: moment(day).weekday,
          absoluteDate: moment(day).format('YYYY-MM-DD'),
          isHoliday: nonRecurringTimeSlots.filter(
            (slot) => day === slot.absoluteDate
          )[0].isHoliday,
        })
    );
    this.locationId = Number(locationId);
  }
}
export class ScheduleLocation {
  locationId: number;
  displayText: string;
  scheduleCutoffTimeHours: number;
  scheduleCutoffTimeMinutes: number;
  scheduleWeekendCutoffTimeHours: number;
  scheduleWeekendCutoffTimeMinutes: number;
  fullTextIS: string;
  fullTextEN: string;
  locationDescription: string;
  isActive: boolean;
  smsText: string;
  heilsuveraText: string;
  bookableAntigen: boolean;
  antigenHeilsuveraMessage: string;
  antigenEmailEN: string;
  antigenEmailIS: string;
  antigenSmsEN: string;
  antigenSmsIS: string;
  antigenHeilsuveraMessageEN: string;
  showInHeilsuvera: boolean;
  daysBetweenBookings?: number;
  isOverflowInHeilsuveraEnabled: boolean;
  constructor(data: any) {
    this.locationId = data.locationId;
    this.displayText = data.displayText;
    this.scheduleCutoffTimeHours = data.scheduleCutoffTimeHours;
    this.scheduleWeekendCutoffTimeHours = data.scheduleWeekendCutoffTimeHours;
    this.fullTextIS = data.fullTextIS;
    this.fullTextEN = data.fullTextEN;
    this.locationDescription = data.locationDescription;
    this.isActive = data.isActive;
    this.smsText = data.smsText;
    this.heilsuveraText = data.heilsuveraText;
    this.bookableAntigen = data.bookableAntigen;
    this.antigenHeilsuveraMessage = data.antigenHeilsuveraMessage;
    this.antigenEmailEN = data.antigenEmailEN;
    this.antigenEmailIS = data.antigenEmailIS;
    this.antigenSmsEN = data.antigenSmsEN;
    this.antigenSmsIS = data.antigenSmsIS;
    this.antigenHeilsuveraMessageEN = data.antigenHeilsuveraMessageEN;
    this.showInHeilsuvera = data.showInHeilsuvera;
    this.scheduleCutoffTimeMinutes = data.scheduleCutoffTimeMinutes;
    this.scheduleWeekendCutoffTimeMinutes =
      data.scheduleWeekendCutoffTimeMinutes;
    this.daysBetweenBookings = data?.daysBetweenBookings
      ? data.daysBetweenBookings
      : undefined;
    this.isOverflowInHeilsuveraEnabled = data.isOverflowInHeilsuveraEnabled;
  }
}

export class ScheduleOverflowStatus {
  isOverflowEnabled: boolean;
  isOverflowInHeilsuveraEnabled: boolean;
  constructor(data: any) {
    this.isOverflowEnabled = data.isOverflowEnabled;
    this.isOverflowInHeilsuveraEnabled = data.isOverflowInHeilsuveraEnabled;
  }
}
export class UpdateScheduleLocationCutOffTimesModel {
  locationId: number;
  scheduleCutoffTimeHours: number | null;
  scheduleCutoffTimeMinutes: number | null;
  scheduleWeekendCutoffTimeHours: number | null;
  scheduleWeekendCutoffTimeMinutes: number | null;
  constructor(
    locationId: number,
    cutOffHours?: number,
    cutOffMinutes?: number,
    weekendCutOffHours?: number,
    weekendCutOffMinutes?: number
  ) {
    this.locationId = locationId;
    this.scheduleCutoffTimeHours =
      cutOffHours !== undefined ? cutOffHours : null;
    this.scheduleCutoffTimeMinutes =
      cutOffMinutes !== undefined ? cutOffMinutes : null;
    this.scheduleWeekendCutoffTimeHours =
      weekendCutOffHours !== undefined ? weekendCutOffHours : null;
    this.scheduleWeekendCutoffTimeMinutes =
      weekendCutOffMinutes !== undefined ? weekendCutOffMinutes : null;
  }
}

export class ScheduleUpdatedBy {
  modifiedBy: string;
  modifiedDate: string;
  constructor(item: any) {
    this.modifiedBy = item?.modifiedBy ? item.modifiedBy : '';
    this.modifiedDate = item?.modifiedDate ? item.modifiedDate : '';
  }
}

export class ErrorInfo {
  errorCode: number;
  errorMessage: string;

  constructor(errorCode: number, errorMessage: string) {
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
  }
}
export interface INationalRegistryLookup {
  ssn: string;
}
export class NationalRegistryPerson {
  personSsn: string;
  personName: string;
  constructor(data: any) {
    this.personSsn = data.personSsn;
    this.personName = data.personName;
  }
}
export class TimeSlotBooking {
  passengerFullName: string;
  passengerSsn: string;
  passengerbarCode: string;
  scheduleDate: string;
  toTime: Slot;
  fromTime: Slot;
  isRecurring: Boolean;
  constructor(data: any) {
    this.passengerFullName = data.passengerFullName;
    this.passengerSsn = data.passengerSsn;
    this.passengerbarCode = data.passengerbarCode;
    this.scheduleDate = data.scheduleDate;
    this.toTime = data.toTime;
    this.fromTime = data.fromTime;
    this.isRecurring = data.isRecurring;
  }
}
export interface IUpdateVaccinationGuardianDecision {
  allowVaccination: number;
  comment: string;
}

export class ISolationStatus {
  fromDate?: string;
  toDate?: string;

  constructor(item: any) {
    this.fromDate = item?.fromDate ? item.fromDate : undefined;
    this.toDate = item?.toDate ? item.toDate : undefined;
  }
}

export class CertificateVariables {
  dateOfBirth?: string;
  dateOfSample?: string;
  dateOfRecovery?: string;
  isPcr: boolean;
  firstName: string;
  lastName: string;
  overrideNationalRegistry: boolean;
  ssn: string;
  observationIdentifier: string;
  observationOrigin: string;
  passportNumber: string;
  email: string;
  isPositive: boolean;

  constructor(item: any) {
    this.dateOfBirth = item?.dateOfBirth ? item.dateOfBirth : undefined;
    this.dateOfSample = item?.dateOfSample ? item.dateOfSample : undefined;
    this.dateOfRecovery = item?.dateOfRecovery
      ? item.dateOfRecovery
      : undefined;
    this.isPcr = item?.isPcr ? item.isPcr : false;
    this.firstName = item?.firstName ? item.firstName : '';
    this.lastName = item?.lastName ? item.lastName : '';
    this.overrideNationalRegistry = item?.overrideNationalRegistry
      ? item.overrideNationalRegistry
      : false;
    this.ssn = item?.ssn ? item.ssn : '';
    this.observationIdentifier = item?.observationIdentifier
      ? item.observationIdentifier
      : '';
    this.observationOrigin = item?.observationOrigin
      ? item.observationOrigin
      : '';
    this.passportNumber = item?.passportNumber ? item.passportNumber : '';
    this.email = item?.email ? item.email : '';
    this.isPositive = item?.isPositive ? item.isPositive : false;
  }
}
