import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  makeStyles,
  Grid,
  Paper,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import fuzzysort from 'fuzzysort';
import debounce from 'lodash.debounce';
import CloseIcon from '@material-ui/icons/Close';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { LangContext } from '../context/LangContext';
import { getRiskCountryColorFromStatus } from '../utils';
import translations from '../assets/json/translations.json';
import { IRiskCountry, RiskCountryStatus } from '../interfaces/riskCountry';
import {
  RISK_COUNTRIES_LIST_ITEM_HEIGHT,
  RISK_COUNTRIES_LIST_HEIGHT,
} from '../constants';

interface IProps {
  allCountries: IRiskCountry[];
  onClick: (country: IRiskCountry) => void;
}

const useStyles = makeStyles((theme) => ({
  searchBox: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  statusDot: {
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
    height: 10,
    width: 10,
    borderRadius: 5,
  },
}));

const RiskCountriesList: React.FC<IProps> = ({ allCountries, onClick }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [lang] = useContext(LangContext);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const searchResultsRef = useRef<FixedSizeList>(null);
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [searchInput, setSearchInput] = useState('');
  const [searchInputFilter, setSearchInputFilter] = useState('');
  const [searchHeight, setSearchHeight] = useState(0);
  const [filteredCountries, setFilteredCountries] = useState<IRiskCountry[]>(
    []
  );
  const debouncedSearchInput = useCallback(
    debounce(setSearchInputFilter, 500),
    []
  );

  const listHeightSm = RISK_COUNTRIES_LIST_ITEM_HEIGHT * 5;
  const listHeightLg = RISK_COUNTRIES_LIST_HEIGHT - searchHeight;

  useEffect(() => {
    const searchResults = searchInputFilter
      ? fuzzysort
          .go(searchInputFilter, allCountries, { keys: ['name'] })
          .map((c) => c.obj)
      : allCountries;
    setFilteredCountries(searchResults);
    if (searchResultsRef.current && searchInputFilter) {
      searchResultsRef.current.scrollTo(0);
    }
  }, [searchInputFilter, allCountries]);

  useEffect(() => {
    if (searchContainerRef.current) {
      setSearchHeight(searchContainerRef.current.offsetHeight);
    }
  }, [searchContainerRef]);

  return (
    <Paper elevation={2}>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.searchBox}
          ref={searchContainerRef}
        >
          {renderSearchBox()}
        </Grid>
        <Grid item xs={12} style={{ overflow: 'auto' }}>
          <FixedSizeList
            width="100%"
            height={smDown ? listHeightSm : listHeightLg}
            itemSize={RISK_COUNTRIES_LIST_ITEM_HEIGHT}
            itemCount={filteredCountries.length}
            ref={searchResultsRef}
          >
            {renderListItem}
          </FixedSizeList>
        </Grid>
      </Grid>
    </Paper>
  );

  function renderSearchBox() {
    return (
      <TextField
        fullWidth
        autoComplete="off"
        id="allCountries"
        variant="outlined"
        label={translations.allCountries[lang]}
        value={searchInput}
        inputProps={{ ref: searchInputRef }}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={() => {
                setSearchInput('');
                setSearchInputFilter('');
                focusSearchInput();
              }}
            >
              <CloseIcon />
            </IconButton>
          ),
        }}
        onChange={(e) => {
          setSearchInput(e.target.value);
          debouncedSearchInput(e.target.value);
        }}
      />
    );
  }

  function renderListItem(item: ListChildComponentProps) {
    const country = filteredCountries[item.index];

    return (
      <ListItem
        button
        divider
        disableRipple
        key={country.code}
        style={item.style}
        onClick={() => onClick(country)}
      >
        <ListItemText primary={country.name} />
        <div
          className={classes.statusDot}
          style={{
            backgroundColor: getRiskCountryColorFromStatus(country.status),
            opacity: country.status === RiskCountryStatus.WHITE ? 0 : 1,
          }}
        />
      </ListItem>
    );
  }

  function focusSearchInput() {
    if (searchInputRef && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }
};

export default RiskCountriesList;
