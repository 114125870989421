import React, { useContext } from 'react';
import { Grid, TextField, Typography, Switch } from '@material-ui/core';

import { LangContext } from '../../../context/LangContext';
import translations from '../../../assets/json/translations.json';

import moment, { Moment } from 'moment';
import { KeyboardTimePicker } from '@material-ui/pickers';

import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import ConfirmDialog from '../../ConfirmDialog';

interface IProps {
  open: boolean;
  allowOverflowHeilsuvera: boolean;
  showInHeilsuvera: boolean;
  cutoffTime: Moment | null;
  cutoffTimeWeekends: Moment | null;
  daysBetweenSamplings: string;
  onChange: (values: IFormValues, resetForm: any) => void;
  onClose: (val: boolean) => void;
}

export interface IFormValues {
  cutoffTime: Moment | null;
  cutoffTimeWeekends: Moment | null;
  daysBetweenSamplings: string;
  allowOverflowHeilsuvera: boolean;
  showInHeilsuvera: boolean;
}

type IFormik = FormikProps<IFormValues>;

const ScheduleSettingsForm: React.FC<IProps> = ({
  open,
  allowOverflowHeilsuvera,
  showInHeilsuvera,
  cutoffTime,
  cutoffTimeWeekends,
  daysBetweenSamplings,
  onChange,
  onClose,
}) => {
  const [lang] = useContext(LangContext);

  const schema = Yup.object().shape({
    cutoffTime: Yup.mixed().test(
      'valid-date',
      'Please enter a valid date',
      (val) => {
        if (val === null) {
          return true;
        }
        return moment(val, 'HH:mm').isValid();
      }
    ),

    cutoffTimeWeekends: Yup.mixed().test(
      'valid-date',
      'Please enter a valid date',
      (val) => {
        if (val === null) {
          return true;
        }
        return moment(val, 'HH:mm').isValid();
      }
    ),
  });

  return (
    <Formik
      initialValues={{
        cutoffTime: moment(cutoffTime).isValid()
          ? cutoffTime
          : (null as Moment | null),
        cutoffTimeWeekends: moment(cutoffTimeWeekends).isValid()
          ? cutoffTimeWeekends
          : (null as Moment | null),
        daysBetweenSamplings: daysBetweenSamplings,
        allowOverflowHeilsuvera: allowOverflowHeilsuvera,
        showInHeilsuvera: showInHeilsuvera,
      }}
      onSubmit={(values, { resetForm }) => onChange(values, resetForm)}
      validationSchema={schema}
      enableReinitialize
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <ConfirmDialog
            open={open}
            onCancel={() => onCloseSettingsDialog(formik)}
            title={translations.scheduleSettings[lang]}
            confirmText={translations.saveChanges[lang]}
            onConfirm={formik.handleSubmit}
            disableConfirm={formik.isSubmitting || !formik.dirty}
          >
            <Grid container spacing={2} direction="row">
              {renderFormFields(formik)}
            </Grid>
          </ConfirmDialog>
        </Form>
      )}
    </Formik>
  );

  function renderFormFields({
    values,
    errors,
    submitCount,

    handleBlur,
    setFieldValue,
  }: IFormik) {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <KeyboardTimePicker
            fullWidth
            autoComplete="off"
            id="cutoffTime"
            label={translations.cutoffTime[lang]}
            variant="inline"
            ampm={false}
            inputVariant="outlined"
            value={values.cutoffTime}
            mask="__:__"
            placeholder="__:__"
            invalidDateMessage=""
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setFieldValue('cutoffTime', e ? moment(e) : null)}
            onBlur={handleBlur}
            error={!!errors.cutoffTime && !!submitCount}
          />
        </Grid>
        <Grid item xs={12}>
          <KeyboardTimePicker
            fullWidth
            autoComplete="off"
            id="cutoffTimeWeekends"
            label={translations.cutoffTimeWeekends[lang]}
            variant="inline"
            ampm={false}
            inputVariant="outlined"
            value={values.cutoffTimeWeekends}
            mask="__:__"
            placeholder="__:__"
            invalidDateMessage=""
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) =>
              setFieldValue('cutoffTimeWeekends', e ? moment(e) : null)
            }
            onBlur={handleBlur}
            error={!!errors.cutoffTimeWeekends && !!submitCount}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoComplete="off"
            id="daysBetweenSamplings"
            label={translations.daysBetweenSamplings[lang]}
            value={values.daysBetweenSamplings}
            variant="outlined"
            inputProps={{
              type: 'text',
              pattern: '[0-9+]*',
              maxLength: 2,
            }}
            onChange={(e) =>
              e.target.validity.valid &&
              setFieldValue('daysBetweenSamplings', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: 'bold' }}>
            {translations.allowOverflowInHeilsuvera[lang]}
          </Typography>
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>{translations.off[lang]}</Grid>
              <Grid item>
                <Switch
                  id="allowOverflowHeilsuvera"
                  checked={values.allowOverflowHeilsuvera}
                  onChange={(e) =>
                    setFieldValue('allowOverflowHeilsuvera', e.target.checked)
                  }
                  color="primary"
                />
              </Grid>
              <Grid item>{translations.on[lang]}</Grid>
            </Grid>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: 'bold' }}>
            {translations.showInHeilsuvera[lang]}
          </Typography>
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>{translations.off[lang]}</Grid>
              <Grid item>
                <Switch
                  id="showInHeilsuvera"
                  checked={values.showInHeilsuvera}
                  onChange={(e) =>
                    setFieldValue('showInHeilsuvera', e.target.checked)
                  }
                  color="primary"
                />
              </Grid>
              <Grid item>{translations.on[lang]}</Grid>
            </Grid>
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }

  function onCloseSettingsDialog({ handleReset }: IFormik) {
    onClose(false);
    handleReset();
  }
};

export default ScheduleSettingsForm;
