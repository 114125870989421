import api from '../api';
import { IHttpAnswer } from '../interfaces/httpAnswer';
import { ICertificate } from '../interfaces/qrCertificate';
/* eslint-disable */
import {
  SearchReview,
  PassengerReview,
  PassengerFiles,
  SearchReviewResult,
  NextReviewId,
} from '../models/CertificateReviewModels';
/* eslint-enable */
export async function getReviews(
  SearchReview: SearchReview
): Promise<IHttpAnswer<SearchReviewResult | undefined>> {
  const res = await api.post(
    `/CertificateReview/GetReviews`,
    SearchReview,
    true
  );
  if (res.isOk) {
    return {
      data: new SearchReviewResult(res.data),
      isOk: res.isOk,
      statusCode: res.statusCode,
    };
  }
  return {
    data: undefined,
    isOk: res.isOk,
    statusCode: res.statusCode,
  };
}

export async function getReviewsById(
  id: string
): Promise<IHttpAnswer<PassengerReview | undefined>> {
  const res = await api.get(`/CertificateReview/GetReviewById/${id}`, true);
  if (res.isOk) {
    return {
      data: new PassengerReview(res.data),
      isOk: res.isOk,
      statusCode: res.statusCode,
    };
  }
  return {
    data: undefined,
    isOk: res.isOk,
    statusCode: res.statusCode,
  };
}

export async function getFilesByReviewId(
  id: string
): Promise<IHttpAnswer<Array<PassengerFiles>>> {
  const res = await api.get(
    `/CertificateReview/GetFilesByReviewId/${id}`,
    true
  );
  if (res.isOk && res.data) {
    const files = res.data.map((item: any) => new PassengerFiles(item));

    return {
      data: files,
      isOk: res.isOk,
      statusCode: res.statusCode,
    };
  }
  return {
    data: [],
    isOk: res.isOk,
    statusCode: res.statusCode,
  };
}

export async function DownloadFileById(
  id: number
): Promise<IHttpAnswer<Blob | undefined>> {
  const blob = await api.getBlob(`/CertificateReview/DownloadFileById/${id}`);
  if (blob) {
    return {
      data: blob,
      isOk: true,
      statusCode: 200,
    };
  } else {
    return {
      data: undefined,
      isOk: false,
      statusCode: 404,
    };
  }
}

export async function updateReview(
  reviewId: string,
  reviewStatus: number,
  rejectedStatus?: number,
  rejectedComment?: string,
  passengerBorderStatus?: number
): Promise<IHttpAnswer<boolean>> {
  const payload = {
    reviewStatus,
    rejectedStatus,
    rejectedComment,
    passengerBorderStatus,
  };
  const res = await api.put(
    `/CertificateReview/UpdateReview/${reviewId}`,
    payload,
    true
  );
  return {
    data: res.isOk,
    isOk: res.isOk,
    statusCode: res.statusCode,
  };
}

export async function GetNextReview(): Promise<
  IHttpAnswer<NextReviewId | undefined>
> {
  const res = await api.get(`/CertificateReview/GetNextReview`, true);
  if (res.isOk) {
    return {
      data: new NextReviewId(res.data),
      isOk: res.isOk,
      statusCode: res.statusCode,
    };
  }
  return {
    data: undefined,
    isOk: res.isOk,
    statusCode: res.statusCode,
  };
}

export async function getCertificateByFileId(
  fileId: number
): Promise<IHttpAnswer<ICertificate | undefined>> {
  const res = await api.get(
    `/CertificateReview/GetCertificateByFileId/${fileId}`,
    true
  );
  if (res.isOk) {
    return {
      data: res.data,
      isOk: res.isOk,
      statusCode: res.statusCode,
    };
  }
  return {
    data: undefined,
    isOk: res.isOk,
    statusCode: res.statusCode,
  };
}
