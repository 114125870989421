import React, {
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  Paper,
  Grid,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import debounce from 'lodash.debounce';
import fuzzysort from 'fuzzysort';

import Button from './Button';
import Dropdown from './Dropdown';
import { LangContext } from '../context/LangContext';
import { UserContext } from '../context/UserContext';
import translations from '../assets/json/translations.json';
import { VaccinationRegistrationRequest } from '../models/VaccinationModels';
import * as localStorageKeys from '../constants/localStorageKeys';
import { getYesNoNeitherOptions } from '../utils';

interface IProps {
  list: VaccinationRegistrationRequest[];
  localStorageKey: string;
  filterList: (members: VaccinationRegistrationRequest[]) => void;
  setRegionSelected: (onlyOne: boolean, location: string) => void;
  onChangeRegion: () => void;
}

const ALL_REGIONS_KEY = 'all_regions';

const VaccinationWatingListFilter: React.FC<IProps> = ({
  list,
  localStorageKey,
  filterList,
  setRegionSelected,
  onChangeRegion,
}) => {
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const searchRef = useRef<HTMLInputElement>();

  const [searchInput, setSearchInput] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [searchInputFilter, setSearchInputFilter] = useState('');
  const [numberOfVaccinations, setNumberOfVaccinations] = useState('');
  const [pregnantOption, setPregnantOption] = useState('neither');
  const [accuteAllergyOption, setAccuteAllergyOption] = useState('neither');
  const [usingMedicationOption, setUsingMedicationOption] = useState('neither');
  const debouncedSearchUpdate = useCallback(
    debounce(setSearchInputFilter, 500),
    []
  );

  const hasOnlyOneRegion = user?.vaccinationRegionModelList?.length === 1;
  const userRegions = getUserRegions();

  useEffect(() => {
    const oneRegion = user?.vaccinationRegionModelList?.length === 1;
    setSelectedRegion(
      oneRegion
        ? user?.vaccinationRegionModelList[0].id.toString()
        : ALL_REGIONS_KEY
    );
  }, [user]);

  // --> filtering the list
  useEffect(() => {
    const searchResults = searchInputFilter
      ? fuzzysort
          .go(searchInputFilter, list, { keys: ['personName'] })
          .map((s) => s.obj)
      : list;
    const filteredRegion = searchResults.filter(
      (item) =>
        selectedRegion === ALL_REGIONS_KEY ||
        item.vaccinationRegion.id === selectedRegion
    );
    const filteredVaccinations = filteredRegion.filter(
      (item) =>
        numberOfVaccinations === '' ||
        item.previousVaccinationsCount === parseInt(numberOfVaccinations)
    );
    const filteredPregnant = filteredVaccinations.filter(
      (item) =>
        pregnantOption === 'neither' ||
        item.isPregnant === (pregnantOption === 'true' ? true : false)
    );
    const filteredAccuteAllergy = filteredPregnant.filter(
      (item) =>
        accuteAllergyOption === 'neither' ||
        item.hasAnAccuteAllergy ===
          (accuteAllergyOption === 'true' ? true : false)
    );
    const filtered = filteredAccuteAllergy.filter(
      (item) =>
        usingMedicationOption === 'neither' ||
        item.isUsingImmonusuppresiveMedication ===
          (usingMedicationOption === 'true' ? true : false)
    );

    filterList(filtered);
  }, [
    list,
    filterList,
    searchInputFilter,
    selectedRegion,
    numberOfVaccinations,
    pregnantOption,
    accuteAllergyOption,
    usingMedicationOption,
  ]);

  useEffect(() => {
    setRegionSelected(
      !!selectedRegion && selectedRegion !== ALL_REGIONS_KEY,
      selectedRegion
    );
  }, [selectedRegion, setRegionSelected]);

  useEffect(() => {
    const savedSearch: string =
      localStorage.getItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_REQUEST_FILTER_SEARCH}`
      ) || '';
    const savedRegion: string =
      localStorage.getItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_REQUEST_FILTER_REGION}`
      ) || '';
    const availableRegions = user.vaccinationRegionModelList
      .filter((r: any) => !!r.id)
      .map((r: any) => r.id.toString());
    const savedNumberOfVaccinations: string =
      localStorage.getItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_REQUEST_FILTER_NUMBER_OF_VACCINATIONS}`
      ) || '';
    const savedPregnant: string =
      localStorage.getItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_REQUEST_FILTER_PREGNANT}`
      ) || '';
    const savedAccuteAllergy: string =
      localStorage.getItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_REQUEST_FILTER_ACCUTE_ALLERGY}`
      ) || '';
    const savedMedication: string =
      localStorage.getItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_REQUEST_FILTER_USING_MEDICATION}`
      ) || '';

    setSearchInput(savedSearch);
    setSearchInputFilter(savedSearch);
    if (savedRegion && availableRegions.includes(savedRegion)) {
      setSelectedRegion(savedRegion);
    }
    setNumberOfVaccinations(savedNumberOfVaccinations);
    setPregnantOption(savedPregnant);
    setAccuteAllergyOption(savedAccuteAllergy);
    setUsingMedicationOption(savedMedication);
    setFiltersLoaded(true);
  }, [localStorageKey, user.vaccinationRegionModelList]);

  useEffect(() => {
    if (filtersLoaded) {
      localStorage.setItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_REQUEST_FILTER_SEARCH}`,
        searchInputFilter
      );
      localStorage.setItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_REQUEST_FILTER_REGION}`,
        selectedRegion
      );
      localStorage.setItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_REQUEST_FILTER_NUMBER_OF_VACCINATIONS}`,
        numberOfVaccinations
      );
      localStorage.setItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_REQUEST_FILTER_PREGNANT}`,
        pregnantOption
      );
      localStorage.setItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_REQUEST_FILTER_ACCUTE_ALLERGY}`,
        accuteAllergyOption
      );
      localStorage.setItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_REQUEST_FILTER_USING_MEDICATION}`,
        usingMedicationOption
      );
    }
  }, [
    filtersLoaded,
    localStorageKey,
    searchInputFilter,
    selectedRegion,
    numberOfVaccinations,
    pregnantOption,
    accuteAllergyOption,
    usingMedicationOption,
  ]);

  useEffect(() => {
    if (selectedRegion && selectedRegion !== ALL_REGIONS_KEY) {
      onChangeRegion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  return (
    <Grid>
      <Paper elevation={2} style={{ padding: theme.spacing(2) }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ marginBottom: theme.spacing(1) }}
        >
          <Grid item>
            <Typography variant="h6">{translations.filter[lang]}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              autoFocus
              autoComplete="off"
              id="search"
              variant="outlined"
              inputRef={searchRef}
              label={translations.searchTitle[lang]}
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
                debouncedSearchUpdate(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              fullWidth
              id="regions"
              value={selectedRegion}
              label={translations.region[lang]}
              items={userRegions}
              onChange={(e) => setSelectedRegion(e.target.value as string)}
              disabled={hasOnlyOneRegion}
            />
          </Grid>
          <Grid
            item
            xs={smDown ? 12 : undefined}
            sm={smDown ? 6 : undefined}
            style={{ flex: !smDown ? 1 : undefined }}
          >
            <TextField
              fullWidth
              autoComplete="off"
              id="numberOfVaccinations"
              variant="outlined"
              label={translations.numberOfVaccinations[lang]}
              inputProps={{
                type: 'text',
                pattern: '[0-9]*',
                maxLength: '1',
              }}
              value={numberOfVaccinations}
              onChange={(e) =>
                e.target.validity.valid &&
                setNumberOfVaccinations(e.target.value)
              }
            />
          </Grid>
          <Grid
            item
            xs={smDown ? 12 : undefined}
            sm={smDown ? 6 : undefined}
            style={{ flex: !smDown ? 1 : undefined }}
          >
            <Dropdown
              fullWidth
              id="pregnant"
              value={pregnantOption}
              label={translations.pregnant[lang]}
              items={getYesNoNeitherOptions(lang)}
              onChange={(e) => setPregnantOption(e.target.value as string)}
            />
          </Grid>
          <Grid
            item
            xs={smDown ? 12 : undefined}
            sm={smDown ? 6 : undefined}
            style={{ flex: !smDown ? 1 : undefined }}
          >
            <Dropdown
              fullWidth
              id="accuteAllergy"
              value={accuteAllergyOption}
              label={translations.accuteAllergy[lang]}
              items={getYesNoNeitherOptions(lang)}
              onChange={(e) => setAccuteAllergyOption(e.target.value as string)}
            />
          </Grid>
          <Grid
            item
            xs={smDown ? 12 : undefined}
            sm={smDown ? 6 : undefined}
            style={{ flex: !smDown ? 1 : undefined }}
          >
            <Dropdown
              fullWidth
              id="usingImmonusuppresiveMedication"
              value={usingMedicationOption}
              label={translations.isUsingImmonusuppresiveMedication[lang]}
              items={getYesNoNeitherOptions(lang)}
              onChange={(e) =>
                setUsingMedicationOption(e.target.value as string)
              }
            />
          </Grid>
          <Grid item xs={smDown ? 12 : undefined} sm={smDown ? 3 : undefined}>
            <Button
              fullWidth={smDown}
              variant="outlined"
              onClick={clearFilters}
            >
              {translations.clear[lang]}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );

  function getUserRegions() {
    let regions = !hasOnlyOneRegion
      ? [{ label: translations.allRegions[lang], value: ALL_REGIONS_KEY }]
      : [];

    if (user?.vaccinationRegionModelList) {
      regions = regions.concat(
        user.vaccinationRegionModelList.map((r: any) => ({
          label: r.description || '',
          value: r.id ? r.id.toString() : '',
        }))
      );
    }

    return regions;
  }

  function clearFilters() {
    setSearchInput('');
    setSearchInputFilter('');
    setSelectedRegion(
      hasOnlyOneRegion
        ? user?.vaccinationRegionModelList[0].id.toString()
        : ALL_REGIONS_KEY
    );
    setNumberOfVaccinations('');
    setPregnantOption('neither');
    setAccuteAllergyOption('neither');
    setUsingMedicationOption('neither');
    focusSearchInput();
  }

  function focusSearchInput() {
    if (searchRef && searchRef.current) {
      searchRef.current.focus();
    }
  }
};

export default VaccinationWatingListFilter;
