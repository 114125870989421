import React, { useState, useContext, useRef, useEffect } from 'react';
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import Button from './Button';
import { TABLE_ROWS_OPTIONS, TABLE_ROWS_DEFAULT } from '../constants';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { Vaccine } from '../models/VaccinationModels';

interface IProps {
  vaccines: Vaccine[];
  editSelectedVaccine: (ssn: Vaccine) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  searchContainer: {
    padding: theme.spacing(3, 2, 1, 2),
    display: 'flex',
    alignItems: 'center',
  },

  gridContainer: {
    padding: theme.spacing(3, 2, 1, 2),
  },
  searchBox: {
    marginRight: theme.spacing(2),
  },
  checkBox: {
    marginRight: theme.spacing(2),
    minWidth: 140,
  },
  deleteButton: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    minWidth: 90,
  },
  editButton: {
    margin: theme.spacing(1),
    minWidth: 90,
  },
  noUsersFound: {
    padding: theme.spacing(2.5),
    paddingBottom: theme.spacing(4),
  },
}));

const VaccineList: React.FC<IProps> = ({ vaccines, editSelectedVaccine }) => {
  const classes = useStyles({});
  const [lang] = useContext(LangContext);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const searchInput = useRef<HTMLInputElement>(null);

  const [searchText, setSearchText] = useState('');
  const [hideInactive, setHideInactive] = useState(false);
  const [filteredVaccines, setFilteredVaccines] = useState<Vaccine[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const startIndex = currentPage * rowsPerPage;

  useEffect(() => {
    const filtered = vaccines.filter(
      (vaccine) =>
        vaccine.name.toLowerCase().includes(searchText.toLowerCase()) ||
        vaccine.lotNumber.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredVaccines(filtered);
    if (hideInactive) {
      const filteredActive = vaccines.filter(
        (vaccine) =>
          (vaccine.name.toLowerCase().includes(searchText.toLowerCase()) ||
            vaccine.lotNumber
              .toLowerCase()
              .includes(searchText.toLowerCase())) &&
          vaccine.vaccineIsActive
      );
      setFilteredVaccines(filteredActive);
    }
  }, [vaccines, searchText, hideInactive]);

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid
          item
          xs={smDown ? 12 : undefined}
          style={{ flex: !smDown ? 1 : undefined }}
        >
          <TextField
            fullWidth
            autoFocus
            autoComplete="off"
            id="search"
            label={translations.searchByVaccineOrLotNumber[lang]}
            variant="outlined"
            inputProps={{ ref: searchInput }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Grid>
        <Grid item xs={smDown ? 6 : undefined}>
          <FormControlLabel
            label={translations.hideInactive[lang]}
            control={
              <Checkbox
                color="primary"
                checked={hideInactive}
                onChange={(e) => setHideInactive(e.target.checked)}
              />
            }
          />
        </Grid>
        <Grid item xs={smDown ? 6 : undefined}>
          <Button variant="outlined" onClick={clearSearch} fullWidth={smDown}>
            {translations.clear[lang]}
          </Button>
        </Grid>
      </Grid>
      {filteredVaccines.length ? (
        <React.Fragment>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{translations.vaccine[lang]}</TableCell>
                  <TableCell>{translations.lotNumber[lang]}</TableCell>
                  <TableCell>{translations.vaccineDescription[lang]}</TableCell>
                  <TableCell>{translations.active[lang]}</TableCell>
                  <TableCell style={{ width: 220 }} align="center">
                    {translations.editLotNumber[lang]}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredVaccines
                  .slice(startIndex, startIndex + rowsPerPage)
                  .map((vaccine) => (
                    <TableRow key={vaccine.id}>
                      <TableCell>{vaccine.name}</TableCell>
                      <TableCell>{vaccine.lotNumber}</TableCell>
                      <TableCell>{vaccine.description}</TableCell>
                      <TableCell>
                        {vaccine.vaccineIsActive
                          ? translations.yes[lang]
                          : translations.no[lang]}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => editSelectedVaccine(vaccine)}
                          padding={false}
                          className={classes.editButton}
                        >
                          {translations.edit[lang]}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={translations.numberOfRowsPerPage[lang]}
            rowsPerPageOptions={TABLE_ROWS_OPTIONS}
            component="div"
            count={filteredVaccines.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={(_, p) => setCurrentPage(p)}
            onChangeRowsPerPage={(e) => {
              setCurrentPage(0);
              setRowsPerPage(parseInt(e.target.value, 10));
            }}
          />
        </React.Fragment>
      ) : (
        <Typography className={classes.noUsersFound} color="textSecondary">
          {translations.noUsersFound[lang]}
        </Typography>
      )}
    </div>
  );

  function clearSearch() {
    setSearchText('');
    setHideInactive(false);
    focusSearchInput();
  }

  function focusSearchInput() {
    if (searchInput && searchInput.current) {
      searchInput.current.focus();
    }
  }
};

export default VaccineList;
