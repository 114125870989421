import React, { RefObject, useContext } from 'react';
import {
  makeStyles,
  Grid,
  TextField,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Form, FormikProps } from 'formik';

import Button from './Button';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

interface IProps {
  formik: IFormik;
  inputRef: RefObject<HTMLInputElement>;
  disabled?: boolean;
  onClear?: () => void;
}

export interface IFormValues {
  serialNumber: string;
}

export type IFormik = FormikProps<IFormValues>;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
  },
}));

const SamplingForm: React.FC<IProps> = ({
  formik,
  inputRef,
  disabled,
  onClear,
}) => {
  const classes = useStyles({});
  const theme = useTheme();
  const [lang] = useContext(LangContext);
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const mdOnly = useMediaQuery(theme.breakpoints.only('md'));
  const mdOrXs = mdOnly || xsDown;

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    isSubmitting,
    dirty,
  } = formik;

  return (
    <Form onSubmit={handleSubmit}>
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={2}>
          {!mdOrXs && (
            <Grid item xs={12} sm={12}>
              <Typography variant="h5">
                {translations.inputSerialNumber[lang]}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              autoFocus
              autoComplete="off"
              id="serialNumber"
              label={translations.serialNumber[lang]}
              variant="outlined"
              value={values.serialNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{ ref: inputRef }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button fullWidth type="submit" disabled={isSubmitting || !dirty}>
              {translations.search[lang]}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              onClick={onClear || handleReset}
            >
              {translations.clear[lang]}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default SamplingForm;
