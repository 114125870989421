import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import Button from './Button';
import { UserContext } from '../context/UserContext';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import * as httpService from '../services/httpService';
import * as localStorageKeys from '../constants/localStorageKeys';
import { IconButton } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

interface IProps {
  loggedIn: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 3),
  },
  toolbar: {
    width: '100%',
    maxWidth: theme.dimensions.maxContentWidth,
    margin: 'auto',
    padding: 0,
  },
  title: {
    cursor: 'pointer',
    color: '#fff',
  },
  hideTitle: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  language: {
    backgroundColor: '#fff',
    color: '#000',
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  languageMargin: {
    marginRight: theme.spacing(2),
  },
  activeLang: {
    fontWeight: 600,
  },
  inactiveLang: {
    opacity: 0.7,
  },
  userInfo: {
    marginRight: theme.spacing(2),
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));

const Navbar: React.FC<IProps> = ({ loggedIn }) => {
  const classes = useStyles({});
  const history = useHistory();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const [userContext, setUserContext] = useContext(UserContext);
  const [lang, setLang] = useContext(LangContext);

  return (
    <AppBar position="static" className={classes.container}>
      <Toolbar className={classes.toolbar}>
        <Typography
          variant="h6"
          onClick={() => userContext && history.push('/home')}
          className={cx(classes.title, { [classes.hideTitle]: loggedIn })}
        >
          {userContext && userContext.loginType
            ? userContext.loginType === 'sampling'
              ? translations.samplingTitle[lang]
              : translations.vaccinationTitle[lang]
            : translations.title[lang]}
        </Typography>
        <div style={{ flex: 1 }} />
        <div className={classes.actions}>
          {loggedIn && (
            <div
              className={classes.userInfo}
              style={{ alignItems: 'flex-start' }}
            >
              <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                {getUserName()}
              </Typography>
              <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                {getUserLocation()}
              </Typography>
            </div>
          )}
          <Button
            padding={false}
            className={cx(classes.language, {
              [classes.languageMargin]: loggedIn,
            })}
            onClick={changeLanguage}
          >
            {lang === 'is' ? 'English' : 'Íslenska'}
          </Button>
          {loggedIn && (
            <Button
              color="secondary"
              padding={false}
              onClick={() => {
                httpService.logout();
                localStorage.removeItem(localStorageKeys.USER);
                localStorage.removeItem(
                  localStorageKeys.CERTIFICATE_REVIEW_FILTER_FORM
                );
                setUserContext(null);
                history.push('/login');
              }}
              style={{ whiteSpace: 'nowrap' }}
              className={classes.languageMargin}
            >
              {translations.logOut[lang]}
            </Button>
          )}
          {userContext?.loginType === 'sampling' && (
            <IconButton onClick={() => history.push('/help')}>
              <HelpOutlineIcon style={{ color: 'white' }}></HelpOutlineIcon>
            </IconButton>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );

  function changeLanguage() {
    const newLang = lang === 'is' ? 'en' : 'is';
    setLang(newLang);
    localStorage.setItem(localStorageKeys.LANGUAGE, newLang);
  }

  function getUserName() {
    if (userContext === null) {
      history.push('/login');
      return;
    }

    const name = userContext.name || '';
    if (xsDown) {
      const splitName = name.split(' ');
      if (splitName.length) {
        return splitName[0];
      }
    }
    return name;
  }

  function getUserLocation() {
    if (userContext?.locationLabel) {
      return userContext?.locationLabel;
    }
  }
};

export default Navbar;
