import {
  Passenger,
  User,
  SearchQuery,
  CreatePassenger,
  CreateUser,
  UserItem,
  Transaction,
  SamplingSchedule,
  Location,
  Residence,
  ResidencePerson,
  CheckInModel,
  ResidenceSearchQuery,
} from '../models/Models';
import {
  IGetResidenceListBySearchQuery,
  IUpdatePassengerInfo,
} from '../interfaces/passenger';
import { IHttpAnswer } from '../interfaces/httpAnswer';
import * as versionFile from '../version.json';
import * as localStorageKeys from '../constants/localStorageKeys';
import {
  IResidenceLocation,
  IResidenceStatus,
} from '../interfaces/residenceLocation';
import { IUpdateQuarantinePassengerInfo } from '../interfaces/quarantinePassenger';
import { IRiskCountry } from '../interfaces/riskCountry';
import { IQrCodeCertificate } from '../interfaces/qrCertificate';

const API_URL = process.env.REACT_APP_API_URL;

async function login(
  mobile: string,
  location: string,
  loginType: string
): Promise<IHttpAnswer<User>> {
  const version = `${versionFile.major}.${versionFile.minor}`;
  const loginRequest = {
    phoneNumber: mobile,
    location: location,
    version: version,
    loginType: loginType
  };
  const response = await fetch(`${API_URL}/Authentication/GetUserAccess`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(loginRequest),
  });
  if (response.ok) {
    const data = await response.json();
    return { data: data, isOk: response.ok, statusCode: response.status };
  }
  const data = await response.json();
  return { data: data, isOk: response.ok, statusCode: response.status };
}

async function logout(): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Authentication/InvalidateCurrentSession`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
      },
    }
  );
  return { data: true, isOk: response.ok, statusCode: response.status };
}

async function getLoginLocations(): Promise<IHttpAnswer<Location[]>> {
  const response = await fetch(`${API_URL}/Authentication/GetLoginLocations`);

  if (response.ok) {
    const data = await response.json();
    const locations = data.locations.map(
      (location: any) => new Location(location)
    );
    return {
      data: locations,
      isOk: response.ok,
      statusCode: response.status,
    };
  }

  return { data: [], isOk: response.ok, statusCode: response.status };
}

async function markSampleTaken(
  sampleId: string,
  numberOfChildren: number
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Passenger/SampleTaken/${sampleId}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ numberOfChildren }),
  });
  if (response.ok) {
    return { data: true, isOk: response.ok, statusCode: response.status };
  }
  return {
    data: false,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function confirmChild(barCode: string): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/ChildVerified/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );
  if (response.ok) {
    return { data: true, isOk: response.ok, statusCode: response.status };
  }
  return { data: false, isOk: response.ok, statusCode: response.status };
}

async function declineChild(barCode: string): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/ChildNotVerified/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );
  if (response.ok) {
    return { data: true, isOk: response.ok, statusCode: response.status };
  }
  return { data: false, isOk: response.ok, statusCode: response.status };
}

async function confirmQuarantine(
  barCode: string,
  numberOfChildren: number
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/QuarantineAccepted/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ numberOfChildren }),
    }
  );
  if (response.ok) {
    return { data: true, isOk: response.ok, statusCode: response.status };
  }
  return { data: false, isOk: response.ok, statusCode: response.status };
}

async function declineQuarantine(
  barCode: string,
  skipPayment: boolean = false
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/QuarantineDeclined/${barCode}/${skipPayment}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );
  if (response.ok) {
    return { data: true, isOk: response.ok, statusCode: response.status };
  }
  return { data: false, isOk: response.ok, statusCode: response.status };
}

async function confirmExemptPassenger(
  barCode: string,
  numberOfChildren: number
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/ConfirmExemptPassenger/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ numberOfChildren }),
    }
  );
  if (response.ok) {
    return { data: true, isOk: response.ok, statusCode: response.status };
  }
  return { data: false, isOk: response.ok, statusCode: response.status };
}

async function declineExemptPassenger(
  barCode: string,
  skipPayment: boolean = false
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/DeclineExemptPassenger/${barCode}/${skipPayment}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );
  if (response.ok) {
    return { data: true, isOk: response.ok, statusCode: response.status };
  }
  return { data: false, isOk: response.ok, statusCode: response.status };
}

async function skipPaymentForPassenger(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/SkipPaymentForPassenger/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );
  if (response.ok) {
    return { data: true, isOk: response.ok, statusCode: response.status };
  }
  return { data: false, isOk: response.ok, statusCode: response.status };
}

async function searchPassenger(
  query: SearchQuery
): Promise<IHttpAnswer<Array<Passenger>>> {
  const passengerList = new Array<Passenger>();
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Passenger/SearchForPassenger`, {
    method: 'POST',
    body: JSON.stringify(query),
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
  });
  if (response.status === 200) {
    const list: Array<Passenger> = await response.json();
    list.map((item) => passengerList.push(new Passenger(item)));
  }
  return {
    data: passengerList,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function getPassengerBySampleId(
  sampleId: string
): Promise<IHttpAnswer<Passenger | null>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/GetPassengerByObservationId/${sampleId}`,
    {
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    return {
      data: new Passenger(data),
      isOk: response.ok,
      statusCode: response.status,
    };
  }

  return { data: null, isOk: response.ok, statusCode: response.status };
}

async function getPassengerByRegistrationId(
  barCode: string
): Promise<IHttpAnswer<Passenger | null>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/GetPassengerByRegistrationId/${barCode}`,
    {
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    return {
      data: new Passenger(data),
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return { data: null, isOk: response.ok, statusCode: response.status };
}

async function getPassengersPayments(
  barCode: string
): Promise<IHttpAnswer<Transaction[] | null>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/GetPassengerPayments/${barCode}`,
    {
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    const newData = data.payments.map(
      (payment: Transaction) => new Transaction(payment)
    );
    return {
      data: newData,
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return { data: null, isOk: response.ok, statusCode: response.status };
}

async function createPassenger(passenger: CreatePassenger) {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Passenger/CreatePassenger`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(passenger),
  });

  if (response.ok) {
    return {
      data: await response.json(),
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return { data: null, isOk: response.ok, statusCode: response.status };
}

async function updateTravelDetails(barCode: string, text: string) {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/UpdateTravelDetails/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(text),
    }
  );

  if (response.ok) {
    return {
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return { isOk: response.ok, statusCode: response.status };
}

async function unpaidSampleNeededToQuarantine(barCode: string) {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/UnpaidSampleNeededToQuarantine/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.ok) {
    return {
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return { isOk: response.ok, statusCode: response.status };
}

async function checkIfSecondTestIsAllowed(barCode: string) {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/CheckIfSecondTestIsAllowed/${barCode}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.ok) {
    return {
      data: await response.json(),
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return { data: null, isOk: response.ok, statusCode: response.status };
}

async function sendToSecondSampling(barCode: string) {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/SendToSecondTest/${barCode}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.ok) {
    return {
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return { isOk: response.ok, statusCode: response.status };
}

async function createUser(ssn: string, user: CreateUser) {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Admin/AddUser/${ssn}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });

  if (response.ok) {
    return {
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return { isOk: response.ok, statusCode: response.status };
}

async function editUser(ssn: string, user: CreateUser) {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Admin/UpdateUser/${ssn}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });

  if (response.ok) {
    return {
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return { isOk: response.ok, statusCode: response.status };
}

async function getAllUsers(): Promise<IHttpAnswer<Array<UserItem>>> {
  const userList = new Array<UserItem>();
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Admin/GetUsers`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
  });
  if (response.status === 200) {
    const data: { users: any[] } = await response.json();
    data.users.map((item) => userList.push(new UserItem(item)));
  }
  return {
    data: userList,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function deleteSelectedUser(ssn: string) {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Admin/DeleteUser/${ssn}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    return {
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return { isOk: response.ok, statusCode: response.status };
}

async function getPrintLabels(
  numberOfLabels: number
): Promise<IHttpAnswer<string[]>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/GetSequence/${numberOfLabels}`,
    {
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return {
    data: response.ok ? await response.json() : [],
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function updatePrePrinted(barCode: string, samplingNumber: string) {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/UpdatePrePrinted/${samplingNumber}/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.ok) {
    return {
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  const data = await response.json();
  return { data: data, isOk: response.ok, statusCode: response.status };
}

async function getSymptomSamplingSchedule(
  arrivalDate: string,
  selectedLocation: string
) {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/GetSamplingArrivalsInfo/${selectedLocation}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(arrivalDate),
    }
  );

  return {
    data: response.ok ? new SamplingSchedule(await response.json()) : null,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function getSymptomSamplingScheduleLocations() {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Passenger/GetSamplingArrivalTypes`, {
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
  });
  if (response.ok) {
    const data = await response.json();
    return {
      data: response.ok
        ? data.arrivalTypes.length > 0
          ? data.arrivalTypes.map((item: any) => {
            return {
              label: item.locationDescription,
              value: item.locationId,
              loginLocationId: item.loginLocationId,
              bookableAntigen: item.bookableAntigen
            };
          }).sort((a: any, b: any) => (a.label > b.label ? 1 : -1))
          : null
        : null,
      isOk: response.ok,
      statusCode: response.status,
    };
  }

  return {
    data: null,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function changeToRegisteredWithCertificate(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/ChangeToValidCertificate/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function declineCertificate(
  barCode: string,
  newStatus: number,
  isBorderControl: boolean = false
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/ValidCertificateDeclined/${barCode}/${newStatus}?isBorderControl=${isBorderControl}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function acceptPCRPositivePreviousCertificate(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/CertificatePCRPositivePreviousAccepted/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function acceptAntibodyCertificate(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/CertificateAntibodiesAccepted/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function acceptVaccinationCertificate(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/CertificateVaccinationAccepted/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function acceptPreApprovedCertificate(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/CertificatePreApprovedAccepted/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function acceptOtherCertificateOrDocuemnts(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/CertificateOrDocumentAccepted/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function changeToTransit(barCode: string): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;
  const response = await fetch(
    `${API_URL}/Passenger/ChangeToInTransit/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function confirmTransit(barCode: string): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;
  const response = await fetch(
    `${API_URL}/Passenger/InTransitAccepted/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    }
  );
  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function declineTransit(
  barCode: string,
  newStatus: number
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;
  const response = await fetch(
    `${API_URL}/Passenger/InTransitDeclined/${barCode}/${newStatus}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function confirmFifthDayTesting(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;
  const response = await fetch(
    `${API_URL}/Passenger/FifthDayTestingAccepted/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    }
  );
  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function changeNegPcrCertificateStatus(
  barCode: string,
  newStatus: number
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;
  const response = await fetch(
    `${API_URL}/Passenger/ChangeNegPcrCertificateStatus/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ newStatus }),
    }
  );
  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function rejectNegPCRCertificate(
  barCode: string,
  comment: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/RejectNegPCRCertificateWithReason?barCode=${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(comment),
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function acceptNegPCRCertificate(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/AcceptNegPCRCertificate/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function notProvidedNegPCRCertificate(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/NotProvidedNegPCRCertificate/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function overruleNegPCRCertificateRejected(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/OverruleNegPCRCertificateRejected/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function updateResidence(
  barCode: string,
  residence: Partial<Residence>
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/UpdateResidence/${barCode}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(residence),
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function updatePassengerInfo(
  barCode: string,
  updatedInfo: IUpdatePassengerInfo
) {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/UpdatePassengerInfo/${barCode}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedInfo),
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function getIsFifthDaySamplingDisabled() {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/ApiHealth/DisableFifthDaySampling`, {
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    const data = await response.json();
    return {
      data: response.ok ? Boolean(data.disable) : false,
      isOk: response.ok,
      statusCode: response.status,
    };
  }

  return {
    data: false,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function getResidenceLocationsAdmin(): Promise<
  IHttpAnswer<Array<IResidenceLocation> | null>
> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Admin/GetResidenceLocations`, {
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    const data = await response.json();
    return {
      data: data.locations,
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return {
    data: null,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function getResidenceListBySearch(
  query: IGetResidenceListBySearchQuery
): Promise<IHttpAnswer<ResidencePerson[] | null>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Residence/GetResidenceListBySearch`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    }
  );
  if (response.status === 204) {
    return {
      data: [],
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  if (response.ok) {
    const data = await response.json();
    return {
      data: data.map((p: any) => new ResidencePerson(p)),
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return {
    data: null,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function setQuarantineHouseStatus(barCode: string, registered: boolean) {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/SetQuarantineResidenceStatus?barcode=${barCode}&mustQurantineInResidence=${registered}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function markResidencesAsExported(
  ids: number[]
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Residence/MarkResidencesAsExported`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(ids),
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function checkInResidences(
  locationId: string,
  roomNumber: string,
  ids: CheckInModel[]
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Residence/checkIn/${locationId}/${roomNumber}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(ids),
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function getCheckedInResidences(
  locationId: string
): Promise<IHttpAnswer<ResidencePerson[] | null>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Residence/GetCheckedIn/${locationId}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.ok) {
    const data = await response.json();
    return {
      data: data.map((p: any) => new ResidencePerson(p)),
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return {
    data: null,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function checkOutResidences(
  locationId: string,

  ids: number[]
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Residence/checkOut/${locationId}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(ids),
  });

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function updateResidencePerson(
  personId: string,
  body: IUpdateQuarantinePassengerInfo
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Residence/UpdateResidencePerson/?residencePersonId=${personId}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function searchForResidencePersons(
  query: ResidenceSearchQuery
): Promise<IHttpAnswer<ResidencePerson[]>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Residence/LookupResidencePerson`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  });

  if (response.status === 200) {
    const data = await response.json();
    return {
      data: data.map((p: any) => new ResidencePerson(p)),
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return {
    data: [],
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function getResidenceLocations(): Promise<
  IHttpAnswer<IResidenceLocation[] | null>
> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Residence/GetLocations`, {
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    const data = await response.json();
    return {
      data: data,
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return {
    data: null,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function getResidenceStatuses(): Promise<
  IHttpAnswer<IResidenceStatus[] | null>
> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Residence/GetStatuses`, {
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    const data = await response.json();
    return {
      data: data,
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return {
    data: null,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function getHighRiskCountries(): Promise<IHttpAnswer<IRiskCountry[]>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Admin/GetCountries`, {
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    const data = await response.json();
    return {
      data: data.sort((a: IRiskCountry, b: IRiskCountry) =>
        a.name.localeCompare(b.name)
      ),
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return {
    data: [],
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function updateRiskCountryStatus(
  countryCode: string,
  newStatus: number
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Admin/UpdateCountryQurantineStatus`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ countryCode, newStatus }),
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function checkoutPassenger(
  barCode: string,
  sendToQuarantineHotel?: boolean,
  comment?: string,
  airlineSurveillanceStatus?: number
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Passenger/CheckoutPassenger`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      barCode,
      sendToQuarantineHotel,
      comment,
      airlineSurveillanceStatus,
    }),
  });

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

// TODO: rename IRiskCountry?
async function getCountryCodeList(): Promise<IHttpAnswer<IRiskCountry[]>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Passenger/GetCountryCodeList`, {
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    const data = await response.json();
    return {
      data,
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return {
    data: [],
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function verifyQrCodeCertificate(
  barCode: string,
  qrCode: string
): Promise<IHttpAnswer<IQrCodeCertificate | null>> {
  try {
    const sessionId = new User(
      JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
    ).encodedSessionId;

    const response = await fetch(
      `${API_URL}/Passenger/VerifyQrCodeCertificate`,
      {
        headers: {
          Authorization: `Bearer ${sessionId}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ barcode: barCode, qrCode: qrCode }),
      }
    );

    return {
      data: response.ok ? await response.json() : null,
      isOk: response.ok,
      statusCode: response.status,
    };
  } catch {
    return {
      data: null,
      isOk: false,
      statusCode: 400,
    };
  }
}

async function verifyQrPCRCertificate(
  barCode: string,
  qrCode: string
): Promise<IHttpAnswer<IQrCodeCertificate | null>> {
  try {
    const sessionId = new User(
      JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
    ).encodedSessionId;

    const response = await fetch(
      `${API_URL}/Passenger/VerifyQrPCRCertificate`,
      {
        headers: {
          Authorization: `Bearer ${sessionId}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ barcode: barCode, qrCode: qrCode }),
      }
    );

    return {
      data: response.ok ? await response.json() : null,
      isOk: response.ok,
      statusCode: response.status,
    };
  } catch {
    return {
      data: null,
      isOk: false,
      statusCode: 400,
    };
  }
}

async function sendCertificateViaEmail(
  barCode: string,
  email: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/SendCertificateManuallyToEmail?barcode=${barCode}&email=${email}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function sendPositiveCertificateViaEmail(
  barCode: string,
  email: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/SendPositiveCertificateManuallyToEmail?barcode=${barCode}&email=${email}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function acceptPartialCertificateVaccination(
  barCode: string,
  numberOfChildren: number
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/PartialCertificateVaccinationAccepted/${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        numberOfChildren,
      }),
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function updateObservationIdentifierToTa(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(
    `${API_URL}/Passenger/UpdateObservationIdentifierToTa?barcode=${barCode}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return {
    data: response.ok,
    isOk: response.ok,
    statusCode: response.status,
  };
}

export {
  login,
  logout,
  getLoginLocations,
  searchPassenger,
  getPassengerBySampleId,
  markSampleTaken,
  getPassengerByRegistrationId,
  getPassengersPayments,
  confirmChild,
  declineChild,
  confirmQuarantine,
  declineQuarantine,
  confirmExemptPassenger,
  declineExemptPassenger,
  createPassenger,
  createUser,
  editUser,
  getAllUsers,
  deleteSelectedUser,
  skipPaymentForPassenger,
  unpaidSampleNeededToQuarantine,
  updateTravelDetails,
  checkIfSecondTestIsAllowed,
  sendToSecondSampling,
  getPrintLabels,
  updatePrePrinted,
  getSymptomSamplingSchedule,
  getSymptomSamplingScheduleLocations,
  changeToRegisteredWithCertificate,
  declineCertificate,
  acceptPCRPositivePreviousCertificate,
  acceptAntibodyCertificate,
  acceptVaccinationCertificate,
  acceptPreApprovedCertificate,
  acceptOtherCertificateOrDocuemnts,
  changeToTransit,
  confirmTransit,
  declineTransit,
  confirmFifthDayTesting,
  changeNegPcrCertificateStatus,
  rejectNegPCRCertificate,
  acceptNegPCRCertificate,
  notProvidedNegPCRCertificate,
  overruleNegPCRCertificateRejected,
  updateResidence,
  updatePassengerInfo,
  getIsFifthDaySamplingDisabled,
  getResidenceListBySearch,
  getResidenceLocationsAdmin,
  markResidencesAsExported,
  setQuarantineHouseStatus,
  checkInResidences,
  getCheckedInResidences,
  checkOutResidences,
  updateResidencePerson,
  searchForResidencePersons,
  getResidenceLocations,
  getResidenceStatuses,
  getHighRiskCountries,
  updateRiskCountryStatus,
  checkoutPassenger,
  getCountryCodeList,
  verifyQrCodeCertificate,
  verifyQrPCRCertificate,
  sendCertificateViaEmail,
  sendPositiveCertificateViaEmail,
  acceptPartialCertificateVaccination,
  updateObservationIdentifierToTa,
};
