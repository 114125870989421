import React, { useContext } from 'react';

import { makeStyles, Grid, Typography } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import Button from './Button';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import ConfirmQtFromAirlineSwitch from './ConfirmQtFromAirlineSwitch';

interface IProps {
  title: string;
  subtitles?: string[];
  backButton?: boolean;
  backButtonClick?: () => void;
  otherButton?: IButton;
  showQuestAirlineSwitch?: boolean;
}

export interface IButton {
  label: string;
  icon?: React.ReactNode;
  color?: 'inherit' | 'default' | 'primary' | 'secondary';
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: theme.dimensions.maxContentWidth,
    margin: '0 auto',
  },
  title: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: 35,
    },
  },
  spacing: {
    marginBottom: theme.spacing(1),
  },
  buttonSpacing: {
    marginLeft: theme.spacing(2),
  },
  subtitles: {
    fontSize: 18,
    paddingBottom: theme.spacing(1),
  },
}));

const TitleArea: React.FC<IProps> = ({
  title,
  subtitles,
  backButton = true,
  showQuestAirlineSwitch = false,
  backButtonClick,
  otherButton,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [lang] = useContext(LangContext);

  return (
    <div className={classes.container}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        <div>
          {showQuestAirlineSwitch && <ConfirmQtFromAirlineSwitch />}
          {otherButton && (
            <Button
              onClick={otherButton.onClick}
              padding={false}
              startIcon={otherButton.icon}
              className={classes.spacing}
              color={otherButton?.color ? otherButton.color : 'primary'}
            >
              {otherButton.label}
            </Button>
          )}
          {backButton && (
            <Button
              onClick={backButtonClick || (() => history.push('/home'))}
              padding={false}
              className={cx(classes.spacing, {
                [classes.buttonSpacing]: otherButton,
              })}
            >
              {translations.backToMenu[lang]}
            </Button>
          )}
        </div>
      </Grid>
      {subtitles && subtitles.length && (
        <div className={classes.subtitles}>
          {subtitles.map((subtitle, index) => (
            <Typography key={index} variant="subtitle1" color="textSecondary">
              {subtitle}
            </Typography>
          ))}
        </div>
      )}
    </div>
  );
};

export default TitleArea;
