import React, { useContext } from 'react';

import Layout from '../components/Layout';
import useNotifier from '../hooks/useNotifier';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import Section from '../components/Section';
import CreatePassengerForm, {
  IFormValues,
} from '../components/CreatePassengerForm';
import { CreatePassenger } from '../models/Models';
import {
  createPassenger,
  postSamplingResult,
} from '../services/httpService.v2';
import { useLocation } from 'react-router-dom';

const CreatePassengerPage: React.FC = () => {
  const { notifyError, notifySuccess } = useNotifier();
  const [lang] = useContext(LangContext);
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  return (
    <Layout useDefaultSpacing title={translations.registerPerson[lang]}>
      <Section>
        <CreatePassengerForm
          onSubmit={onSubmit}
          rapidQuery={query.get('rapid')?.toString() === 'true'}
        />
      </Section>
    </Layout>
  );

  async function onSubmit(values: IFormValues) {
    const response = await createPassenger(new CreatePassenger(values));
    if (response.data && response.data.barCode) {
      if (values.testResult) {
        const res = await postSamplingResult(
          response.data.barCode,
          Number(values.testResult)
        );
        if (res.isOk) {
          notifySuccess(translations.registerPassengerSuccess[lang]);
          return response.data.barCode as string;
        }
      } else {
        notifySuccess(translations.registerPassengerSuccess[lang]);
        return response.data.barCode as string;
      }
    }
    notifyError(translations.registerPassengerError[lang]);
    return null;
  }
};

export default CreatePassengerPage;
