import React, { ChangeEvent } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

interface IProps {
  options: IRadioOption[];
  value: string | null;
  color?: 'primary' | 'secondary' | 'default';
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  row?: boolean;
  label?: string;
  error?: boolean;
}

export interface IRadioOption {
  value: string;
  label: string;
}

const RadioButton: React.FC<IProps> = ({
  disabled,
  options,
  value,
  onChange,
  color = 'primary',
  row = false,
  label,
  error = false,
}) => {
  return (
    <div style={{ width: '100%' }}>
      <FormControl>
        {label && <FormLabel error={error}>{label}</FormLabel>}
        <RadioGroup value={value} onChange={onChange} row={row}>
          {options.map((item) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={<Radio color={color} disabled={disabled} />}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default RadioButton;
