import React, { useContext, useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Grid,
  useTheme,
  TextField,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Warning';
import * as Yup from 'yup';

import Button from './Button';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { Formik, Form, FormikProps } from 'formik';
import { ResidencePerson } from '../models/Models';

interface IProps {
  open: boolean;
  title: string;
  text?: string;
  confirmText?: string;
  cancelText?: string;
  loading?: boolean;
  disableConfirm?: boolean;
  error?: boolean;
  onConfirm: (values: IFormDialogValues) => Promise<void>;
  onCancel: () => void;
  checkedGroupMembers: ResidencePerson[];
}

export interface IFormDialogValues {
  hotelRoom: string;
  passengers: PassengerInfo[];
}

export type PassengerInfo = {
  id: number;
  name: string;
  comment: string;
  food: string;
};

type IFormik = FormikProps<IFormDialogValues>;

const CheckinPassengersDialog: React.FC<IProps> = ({
  open,
  title,
  text,
  children,
  confirmText,
  cancelText,
  loading,
  error,
  onConfirm,
  onCancel,
  checkedGroupMembers,
  ...props
}) => {
  const [lang] = useContext(LangContext);
  const theme = useTheme();
  const [disableConfirm, setDisableConfirm] = useState(false);

  const schema = Yup.object().shape({
    hotelRoom: Yup.string().required(),
    passengers: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string(),
        comment: Yup.string(),
        food: Yup.string(),
      })
    ),
  });

  useEffect(() => {
    if (!open) {
      setDisableConfirm(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="md"
      fullWidth
      onClick={() => disableConfirm && setDisableConfirm(false)}
    >
      <Formik
        onSubmit={(values: any) => onSubmit(values)}
        initialValues={getInitalValues()}
        validationSchema={schema}
        enableReinitialize
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <DialogTitle>
              <Grid container direction="row" alignItems="center">
                {error ? (
                  <ErrorIcon
                    fontSize="large"
                    color="error"
                    style={{ marginRight: theme.spacing(1) }}
                  />
                ) : null}
                <span
                  style={{
                    transform: error ? 'translateY(2px)' : undefined,
                  }}
                >
                  {title}
                </span>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ minHeight: 80 }} dividers>
              {text && <DialogContentText>{text}</DialogContentText>}
              {renderForm(formik)}
            </DialogContent>
            <DialogActions>{renderButtons(formik)}</DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );

  function renderForm(formik: IFormik) {
    const { values, errors, submitCount, handleBlur, handleChange } = formik;
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={0}
      >
        <Grid item xs={12} style={{ marginBottom: theme.spacing(2) }}>
          <TextField
            required
            fullWidth
            label={translations.hotelRoomNumber[lang]}
            autoFocus
            autoComplete="off"
            id="hotelRoom"
            variant="outlined"
            value={values.hotelRoom}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.hotelRoom && !!submitCount}
          ></TextField>
        </Grid>

        {values.passengers.map((item, index) => (
          <Grid
            container
            spacing={2}
            key={index}
            style={{ marginBottom: theme.spacing(1) }}
          >
            <Grid item style={{ flex: 1 }}>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography>{`#${index + 1} - ${item.name}`}</Typography>
              </div>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <TextField
                fullWidth
                label={translations.comment[lang]}
                autoComplete="off"
                variant="outlined"
                id={`passengers.${index}.comment`}
                onChange={handleChange}
                onBlur={handleBlur}
              ></TextField>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <TextField
                fullWidth
                label={translations.food[lang]}
                autoComplete="off"
                variant="outlined"
                id={`passengers.${index}.food`}
                onChange={handleChange}
                onBlur={handleBlur}
              ></TextField>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }

  function renderButtons(formik: IFormik) {
    const { isSubmitting } = formik;
    return (
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item style={{ flex: 1 }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-end"
            wrap="nowrap"
            spacing={1}
          >
            {loading && (
              <Grid item>
                <CircularProgress size={32} color="primary" />
              </Grid>
            )}

            <Grid item>
              <Button
                autoFocus
                padding={false}
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                type="submit"
              >
                {confirmText || translations.confirm[lang]}
              </Button>
            </Grid>

            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                padding={false}
                onClick={close}
              >
                {cancelText || translations.cancel[lang]}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function getInitalValues() {
    return {
      hotelRoom: '',
      passengers:
        checkedGroupMembers.map((member) => ({
          id: member.id,
          name: member.name,
          comment: '',
          food: '',
        })) || ([] as PassengerInfo[]),
    };
  }

  async function onSubmit(values: any) {
    await onConfirm(values);
  }

  function close() {
    onCancel();
    setDisableConfirm(false);
  }
};

export default CheckinPassengersDialog;
