import React, { useContext, useEffect, useState } from 'react';

import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  IconButton,
  TextField,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { CertificateType, EuDccCertificate, Passenger } from '../models/Models';
import { LangContext } from '../context/LangContext';
import ConfirmDialog from './ConfirmDialog';
import translations from '../assets/json/translations.json';
import * as httpService from '../services/httpService.v2';
import * as euDccService from '../services/euDccService';

import { accessControl } from '../constants/enums';
import EditIcon from '@material-ui/icons/Edit';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import { canChangeRapidTestResult, isAccessible, isRapidTest } from '../utils';
import { UserContext } from '../context/UserContext';
import { FormikProps, Formik, Form } from 'formik';
import { Moment } from 'moment';
import moment from 'moment';
import DatePicker from './DatePicker';
import Dropdown from './Dropdown';
import rapidTestStatuses from '../assets/json/rapidTestStatuses.json';
import { DATE_FORMAT } from '../constants';

import Autocomplete, { IOption } from './Autocomplete';

interface IProps {
  passenger: Passenger;
  onSendCertificate: (
    certificateData: ICertificateFormValues,
    onSuccess: () => void
  ) => Promise<void>;
  updateRapidTestStatus: (
    passenger: Passenger,
    newStatus: number,
    onSuccess: () => void
  ) => Promise<void>;
  onRevokeCertificate: (
    certificate: EuDccCertificate,
    invalidationReason: string,
    onSuccess: () => void
  ) => Promise<void>;
}

export interface ICertificateFormValues {
  dateOfBirth: Moment | null;
  dateOfSample: Moment | null;
  dateOfRecovery: Moment | null;
  isPcr: boolean;
  firstName: string;
  lastName: string;
  overrideNationalRegistry: boolean;
  ssn: string;
  observationIdentifier: string;
  observationOrigin: string;
  passportNumber: string;
  email: string;
  isPositive: boolean;
}

type IFormik = FormikProps<ICertificateFormValues>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyText: {
      display: 'inline-block',
    },
    button: {
      padding: theme.spacing(0),
      marginLeft: theme.spacing(1),
      marginTop: -5,
    },
    buttonIcon: {
      fontSize: 23,
    },
    inputContainer: {
      marginTop: theme.spacing(1),
    },
  })
);

const CreateCertificate: React.FC<IProps> = ({
  passenger,
  onSendCertificate,
  updateRapidTestStatus,
  onRevokeCertificate,
}) => {
  const classes = useStyles({});
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);

  const [openDialog, setOpenDialog] = useState(false);
  const [rapidTestDialog, setRapidTestDialog] = useState(false);
  const [historyDialog, setHistoryDialog] = useState(false);
  const [rapidTestStatus, setRapidTestStatus] = useState(
    passenger?.observationStatus?.id
      ? passenger.observationStatus.id.toString()
      : ''
  );
  const [certificateData, setCertificateData] =
    useState<ICertificateFormValues | null>(null);
  const [typeOfCertificate, setTypeOfCertificate] =
    useState<CertificateType | null>(null);
  const [certificateHistory, setCertificateHistory] = useState<
    EuDccCertificate[]
  >([]);
  const [selectedCertificate, setSelectedCertificate] =
    useState<IOption | null>(null);
  const [reasonForRevocation, setReasonForRevocation] = useState('');

  useEffect(() => {
    const fetchCertificateData = async () => {
      const response = await httpService.getCustomCertificateDefaultVariables(
        passenger.serialNumber
      );
      if (response.isOk) {
        const data = response.data;
        const certificateInitalData = {
          dateOfBirth: data?.dateOfBirth
            ? moment(data.dateOfBirth)
            : (null as Moment | null),
          dateOfSample: data?.dateOfSample
            ? moment(data.dateOfSample)
            : (null as Moment | null),
          dateOfRecovery: data?.dateOfRecovery
            ? moment(data.dateOfRecovery)
            : (null as Moment | null),
          isPcr: data?.isPcr ? data.isPcr : false,
          firstName: data?.firstName ? data.firstName : '',
          lastName: data?.lastName ? data.lastName : '',
          overrideNationalRegistry: data?.overrideNationalRegistry
            ? data.overrideNationalRegistry
            : false,
          ssn: data?.ssn ? data.ssn : '',
          observationIdentifier: data?.observationIdentifier
            ? data.observationIdentifier
            : '',
          observationOrigin: data?.observationOrigin
            ? data.observationOrigin
            : '',
          passportNumber: data?.passportNumber ? data.passportNumber : '',
          email: data?.email ? data.email : '',
          isPositive: data?.isPositive ? data.isPositive : false,
        };
        setCertificateData(certificateInitalData);
        checkCertificateType(certificateInitalData);
      }
    };

    const fetchCertificateHistory = async (ssn: string) => {
      const response = await euDccService.getEuDccCertificates(ssn);

      if (response.isOk && response.data) {
        setCertificateHistory(response.data);
      }
    };

    fetchCertificateData();
    if (passenger?.ssn) {
      fetchCertificateHistory(passenger.ssn);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passenger]);

  function getObservationStatusTranslation(passenger: Passenger) {
    if (passenger.observationStatus) {
      if (passenger.observationStatus.id === 0 && !passenger.sampleTaken) {
        return translations.noSampleTaken[lang];
      } else {
        return passenger.observationStatus.description[lang];
      }
    } else {
      return '';
    }
  }

  return (
    <React.Fragment>
      <>
        <Typography className={classes.emptyText} color="textSecondary">
          {getObservationStatusTranslation(passenger)}
        </Typography>
        {isAccessible(accessControl.CREATE_CERTIFICATE, user) && (
          <IconButton
            className={classes.button}
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            <MailOutlineIcon className={classes.buttonIcon} />
          </IconButton>
        )}
        {isRapidTest(passenger?.sampleId || '') &&
          canChangeRapidTestResult(
            passenger?.sampleTaken,
            passenger?.observationStatus?.id
          ) && (
            <IconButton
              className={classes.button}
              color="primary"
              onClick={() => setRapidTestDialog(true)}
            >
              <EditIcon className={classes.buttonIcon} />
            </IconButton>
          )}
        {!!certificateHistory.length && (
          <IconButton
            className={classes.button}
            color="secondary"
            onClick={() => setHistoryDialog(true)}
          >
            <RestorePageIcon className={classes.buttonIcon} />
          </IconButton>
        )}
      </>

      <Formik
        onSubmit={sendEmail}
        initialValues={
          certificateData
            ? certificateData
            : {
                dateOfBirth: null as Moment | null,
                dateOfSample: null as Moment | null,
                dateOfRecovery: null as Moment | null,
                isPcr: false,
                firstName: '',
                lastName: '',
                overrideNationalRegistry: false,
                ssn: '',
                observationIdentifier: ``,
                observationOrigin: '',
                passportNumber: '',
                email: '',
                isPositive: false,
              }
        }
        enableReinitialize
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>{renderDialog(formik)}</Form>
        )}
      </Formik>
    </React.Fragment>
  );

  function renderDialog(formik: IFormik) {
    return (
      <>
        <ConfirmDialog
          open={openDialog}
          title={`${translations.emailDialogTitle[lang]} ${
            typeOfCertificate?.id
              ? '(' + typeOfCertificate.description[lang] + ')'
              : ''
          }`}
          confirmText={translations.sendToSamplingText1[lang]}
          onConfirm={formik.handleSubmit}
          onCancel={() => onCloseDialog()}
        >
          {renderDialogContent(formik)}
        </ConfirmDialog>
        <ConfirmDialog
          open={rapidTestDialog}
          title={translations.changeTestResult[lang]}
          onConfirm={() => changeRapidTestStatus()}
          onCancel={() => onCloseRapidTestDialog()}
          disableConfirm={
            !rapidTestStatus ||
            passenger?.observationStatus?.id.toString() === rapidTestStatus
          }
        >
          {renderRapidTestDialogContent()}
        </ConfirmDialog>
        <ConfirmDialog
          open={historyDialog}
          title={translations.revokeCertificate[lang]}
          onCancel={() => onCloseHistoryDialog()}
          confirmText={translations.revokeCertificate[lang]}
          onConfirm={() => revokeSelectedCertificate()}
          disableConfirm={!selectedCertificate || !reasonForRevocation}
        >
          {renderHistoryDialogContent()}
        </ConfirmDialog>
      </>
    );
  }

  function renderDialogContent({
    values,
    setFieldValue,
    handleChange,
    handleBlur,
  }: IFormik) {
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <DatePicker
            required
            id="dateOfBirth"
            label={translations.dateOfBirth[lang]}
            value={values.dateOfBirth}
            onChange={(e) => setFieldValue('dateOfBirth', e ? moment(e) : null)}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            required
            id="dateOfSample"
            label={translations.dateOfSample[lang]}
            value={values.dateOfSample}
            onChange={(e) => {
              setFieldValue('dateOfSample', e ? moment(e) : null);
              checkCertificateType({
                ...values,
                dateOfSample: e ? moment(e) : null,
              });
            }}
            onBlur={handleBlur}
          />
        </Grid>

        <>
          <Grid item xs={12} sm={6}>
            <DatePicker
              id="dateOfRecovery"
              label={translations.dateOfRecovery[lang]}
              value={values.dateOfRecovery}
              onChange={(e) => {
                setFieldValue('dateOfRecovery', e ? moment(e) : null);
                checkCertificateType({
                  ...values,
                  dateOfRecovery: e ? moment(e) : null,
                });
              }}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
        </>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            //autoFocus
            autoComplete="off"
            id="firstName"
            label={translations.firstNames[lang]}
            variant="outlined"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            autoComplete="off"
            id="lastName"
            label={translations.lastName[lang]}
            variant="outlined"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormGroup>
            <FormControlLabel
              id="overrideNationalRegistry"
              value={values.overrideNationalRegistry}
              checked={values.overrideNationalRegistry}
              control={
                <Checkbox
                  color="primary"
                  onChange={(e) =>
                    setFieldValue('overrideNationalRegistry', e.target.checked)
                  }
                />
              }
              label={translations.overrideNationalRegistry[lang]}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            autoComplete="off"
            id="ssn"
            label={translations.ssn[lang]}
            variant="outlined"
            value={values.ssn}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            autoComplete="off"
            id="passportNumber"
            label={translations.passportNumber[lang]}
            variant="outlined"
            value={values.passportNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            autoComplete="off"
            id="email"
            label={translations.email[lang]}
            variant="outlined"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
    );
  }

  function renderRapidTestDialogContent() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Dropdown
            fullWidth
            required
            id="testResult"
            value={rapidTestStatus}
            label={translations.testResult[lang]}
            items={rapidTestStatuses.map((item) => ({
              label: item.description[lang],
              value: item.id.toString(),
            }))}
            onChange={(e: any) => setRapidTestStatus(e.target.value)}
          />
        </Grid>
      </Grid>
    );
  }

  function renderHistoryDialogContent() {
    return (
      <>
        <Autocomplete
          items={certificateHistory.map((item) => ({
            value: item.ci,
            label: `${item.typeTranslation[lang]} - ${moment(item.date).format(
              DATE_FORMAT
            )}`,
          }))}
          value={selectedCertificate}
          onChange={(val) => setSelectedCertificate(val as IOption | null)}
          label={translations.certificate[lang]}
        />
        <br />
        <TextField
          fullWidth
          label={translations.reasonForRevocation[lang]}
          autoComplete="off"
          variant="outlined"
          value={reasonForRevocation}
          onChange={(e) => setReasonForRevocation(e.target.value)}
        />
      </>
    );
  }

  async function checkCertificateType(values: ICertificateFormValues) {
    const payload = {
      dateOfBirth: values?.dateOfBirth ? values.dateOfBirth.toISOString() : '',
      dateOfSample: values?.dateOfSample
        ? values.dateOfSample.toISOString()
        : '',
      dateOfRecovery: values?.dateOfRecovery
        ? values.dateOfRecovery.toISOString()
        : undefined,
      isPcr: values?.isPcr ? values.isPcr : false,
      firstName: '',
      lastName: '',
      overrideNationalRegistry: values?.overrideNationalRegistry
        ? values.overrideNationalRegistry
        : false,
      ssn: values?.ssn ? values.ssn : undefined,
      observationIdentifier: values?.observationIdentifier
        ? values.observationIdentifier
        : undefined,
      observationOrigin: values?.observationOrigin
        ? values.observationOrigin
        : undefined,
      passportNumber: '',
      email: '',
      isPositive: values?.isPositive,
    };
    const response = await httpService.checkCustomCertificateSendType(payload);
    if (response.isOk && response.data) {
      setTypeOfCertificate(response.data);
    } else {
      setTypeOfCertificate(null);
    }
  }

  async function changeRapidTestStatus() {
    updateRapidTestStatus(passenger, Number(rapidTestStatus), () =>
      onCloseRapidTestDialog()
    );
  }

  async function revokeSelectedCertificate() {
    const selectedRevokeCertificate = certificateHistory.find(
      (item) => item.ci === selectedCertificate?.value
    );

    if (selectedRevokeCertificate && reasonForRevocation) {
      onRevokeCertificate(selectedRevokeCertificate, reasonForRevocation, () =>
        onCloseHistoryDialog()
      );
    }
  }

  async function sendEmail(values: ICertificateFormValues) {
    onSendCertificate(values, () => onCloseDialog());
  }

  function onCloseDialog() {
    setOpenDialog(false);
  }

  function onCloseRapidTestDialog() {
    setRapidTestDialog(false);
  }

  function onCloseHistoryDialog() {
    setHistoryDialog(false);
    setSelectedCertificate(null);
    setReasonForRevocation('');
  }
};

export default CreateCertificate;
