import React, { useContext } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { residenceStatus } from '../constants/enums';

import {
  IQuarantinePassengerDetails,
  IUpdateQuarantinePassengerInfo,
} from '../interfaces/quarantinePassenger';
import useNotifier from '../hooks/useNotifier';

import { ResidencePerson } from '../models/Models';
import Button from './Button';
import { DATE_AND_TIME_FORMAT, DATE_FORMAT } from '../constants';
import EditQuarantinePassengerInfo from './EditQuarantinePassengerInfo';
import { updateResidencePerson } from '../services/httpService';
import CopyButton from './CopyButton';

interface IProps {
  residencePerson: ResidencePerson;
  reloadSelectedPassenger?: (barcode: string) => Promise<void>;
  back: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  descriptionTitle: {
    display: 'inline-block',
    fontWeight: 'bold',
    paddingRight: 10,
  },
  descriptionDetails: {
    display: 'inline-block',
  },
  buttonGrid: {
    textAlign: 'right',
    marginTop: theme.spacing(3),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  topItems: {
    marginBottom: theme.spacing(1),
  },
  editButton: {
    textAlign: 'right',
  },
  emptyText: {
    display: 'inline-block',
  },
}));

const QuarantinePassengerDetails: React.FC<IProps> = ({
  residencePerson,
  reloadSelectedPassenger,
  back,
}) => {
  const [lang] = useContext(LangContext);
  const history = useHistory();
  const classes = useStyles();
  const { notifyError, notifySuccess } = useNotifier();

  return (
    <Paper elevation={2} className={classes.container}>
      <Grid container>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.editButton}>
            <EditQuarantinePassengerInfo
              residencePerson={residencePerson}
              updateInfo={updatePassengerInfo}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {getMemberGroupedDetails().map(renderGroupedInfo)}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} className={classes.buttonGrid}>
          <Grid container spacing={2} justify="flex-end">
            <Button onClick={() => back()} variant="outlined">
              {translations.back[lang]}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );

  function getMemberGroupedDetails(): IQuarantinePassengerDetails[][] {
    const details: IQuarantinePassengerDetails[][] = [];

    const personDetails: IQuarantinePassengerDetails[] = [
      {
        label: translations.name[lang],
        getVal(residencePerson: ResidencePerson) {
          return residencePerson.name;
        },
      },
      {
        label: translations.dateOfBirth[lang],
        getVal(residencePerson: ResidencePerson) {
          return moment(residencePerson.dateOfBirth).format(DATE_FORMAT);
        },
      },
      {
        label: translations.sex[lang],
        getVal(residencePerson: ResidencePerson) {
          return residencePerson.gender;
        },
      },
      {
        label: translations.phoneNumber[lang],
        getVal(residencePerson: ResidencePerson) {
          return residencePerson.phoneNumber;
        },
      },
      {
        label: translations.email[lang],
        getVal(residencePerson: ResidencePerson) {
          return residencePerson.email || '';
        },
      },
      {
        label: translations.serialNumber[lang],
        render(residencePerson: ResidencePerson) {
          return (
            <React.Fragment>
              <Typography className={classes.emptyText} color="textSecondary">
                {residencePerson.serialNumber}
              </Typography>
              <CopyButton text={residencePerson.serialNumber} />
            </React.Fragment>
          );
        },
      },
      {
        label: translations.nationality[lang],
        getVal(residencePerson: ResidencePerson) {
          return residencePerson.nationality;
        },
      },
      {
        label: translations.countryOfDeparture[lang],
        getVal(residencePerson: ResidencePerson) {
          return residencePerson.countryOfDeparture;
        },
      },
      {
        label: translations.flightNumber[lang],
        getVal(residencePerson: ResidencePerson) {
          return residencePerson.flightNumber;
        },
      },
      {
        label: translations.arrivalDate[lang],
        getVal(residencePerson: ResidencePerson) {
          return residencePerson.dateOfArrival;
        },
      },
      {
        label: translations.statusInTheProcess[lang],
        getVal(residencePerson: ResidencePerson) {
          return residencePerson?.residenceStatus?.description[lang] || '';
        },
      },
    ];

    details.push(personDetails);

    if (
      residencePerson.residenceStatus?.id === residenceStatus.CHECKED_IN ||
      residencePerson.residenceStatus?.id === residenceStatus.CHECKED_OUT
    ) {
      const hotelDetails: IQuarantinePassengerDetails[] = [
        {
          label: translations.checkIn[lang],
          getVal(residencePerson: ResidencePerson) {
            return residencePerson.checkedInDate
              ? moment(residencePerson.checkedInDate).format(
                  DATE_AND_TIME_FORMAT
                )
              : '';
          },
        },
        {
          label: translations.estimatedDischargeDate[lang],
          getVal(residencePerson: ResidencePerson) {
            return residencePerson.estimatedDischargeDate
              ? moment(residencePerson.estimatedDischargeDate).format(
                  DATE_AND_TIME_FORMAT
                )
              : '';
          },
        },
        {
          label: translations.location[lang],
          getVal(residencePerson: ResidencePerson) {
            return residencePerson?.residenceLocation?.name || '';
          },
        },

        {
          label: translations.hotelRoomNumber[lang],
          getVal(residencePerson: ResidencePerson) {
            return residencePerson.checkedInRoomNumber;
          },
        },

        {
          label: translations.comment[lang],
          getVal(residencePerson: ResidencePerson) {
            return residencePerson.comment;
          },
        },
        {
          label: translations.food[lang],
          getVal(residencePerson: ResidencePerson) {
            return residencePerson.food;
          },
        },
        {
          label: translations.secondSampling[lang],
          getVal(residencePerson: ResidencePerson) {
            return residencePerson?.observationStatus?.description[lang] || '';
          },
        },
      ];

      if (residencePerson.checkedOutDate) {
        hotelDetails.push({
          label: translations.checkOutDate[lang],
          getVal(residencePerson: ResidencePerson) {
            return residencePerson.checkedOutDate
              ? moment(residencePerson.checkedOutDate).format(
                  DATE_AND_TIME_FORMAT
                )
              : '';
          },
        });
      }

      details.push(hotelDetails);
    }

    return details;
  }

  function renderGroupedInfo(
    groupeInfo: IQuarantinePassengerDetails[],
    index: number
  ) {
    return (
      <Grid key={index} item xs={12}>
        <Grid container spacing={0}>
          {groupeInfo.map(renderInfoItem)}
        </Grid>
      </Grid>
    );
  }

  function renderInfoItem(
    { label, render, getVal = () => '', hide }: IQuarantinePassengerDetails,
    index: number
  ) {
    return hide && hide(residencePerson) ? null : (
      <Grid item xs={12} key={index} style={{ padding: 2 }}>
        <Typography className={classes.descriptionTitle}>{label}:</Typography>
        {render ? (
          render(residencePerson)
        ) : (
          <Typography
            className={classes.descriptionDetails}
            color="textSecondary"
          >
            {getVal(residencePerson)}
          </Typography>
        )}
      </Grid>
    );
  }

  async function updatePassengerInfo(info: IUpdateQuarantinePassengerInfo) {
    const response = await updateResidencePerson(
      residencePerson.id.toString(),
      info
    );

    if (response.isOk) {
      reloadSelectedPassenger &&
        (await reloadSelectedPassenger(residencePerson.serialNumber));
      notifySuccess(translations.passengerInfoUpdated[lang]);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }
};

export default QuarantinePassengerDetails;
