import React, { useRef, useState, useEffect } from 'react';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  makeStyles,
} from '@material-ui/core';
import cx from 'classnames';

interface IProps {
  id?: string;
  value?: string | string[];
  label: string;
  items: Array<{ value: string; label: string }>;
  onChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  className?: string;
  autoFocus?: boolean;
}

const useStyles = makeStyles((theme) => ({
  disabled: {
    '&>label': {
      opacity: `${0.65} !important`,
    },
  },
  error: {
    '&>label': {
      color: `${theme.palette.error.main} !important`,
    },
  },
}));

const Dropdown: React.FC<IProps> = ({
  id,
  value,
  label,
  items,
  onChange,
  error,
  disabled,
  required,
  fullWidth,
  className,
  autoFocus,
}) => {
  const classes = useStyles();
  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, [items]);

  return (
    <FormControl
      variant="outlined"
      fullWidth={fullWidth}
      required={required}
      className={cx(className, {
        [classes.disabled]: disabled,
        [classes.error]: error,
      })}
    >
      {label && <InputLabel ref={inputLabel}>{label}</InputLabel>}
      <Select
        autoFocus={autoFocus}
        name={id}
        value={value}
        error={error}
        onChange={onChange}
        disabled={disabled}
        labelWidth={labelWidth}
        multiple={value instanceof Array}
        MenuProps={{
          style: { maxHeight: 500 },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
