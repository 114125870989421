import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, useTheme } from '@material-ui/core';
import moment, { Moment } from 'moment';

import { IProps as IVaccinationSettingsProps } from './VaccinationSettings';
import ConfirmDialog from './ConfirmDialog';
import VaccinationComment from './VaccinationComment';
import GroupsInfo from './GroupsInfo';
import EditPhoneNumber from './EditPhoneNumber';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { VaccinationPerson } from '../models/VaccinationModels';
import {
  IVaccinationPersonDetails,
  IVaccinationPersonPhoneNumberUpdate,
} from '../interfaces/vaccinationPerson';
import * as vaccinationService from '../services/vaccinationService';
// import * as influenzaService from '../services/influenzaService';
import useNotifier from '../hooks/useNotifier';
import {
  IVaccinationComment,
  IVaccinationCommentStatus,
} from '../interfaces/vaccinationComment';
import {
  isAccessible,
  parseTemplateString,
  shouldShowErrorForBoosterVaccine,
  shouldShowWarningForBooster,
} from '../utils';
import VaccinationStatusTable, {
  IFormValues as IUpdateFormValues,
} from './VaccinationStatusTable';
import {
  /*  IInfluenzaVaccinationUpdate, */
  IVaccinationUpdate,
} from '../interfaces/vaccinationUpdate';
import { accessControl, VaccineType } from '../constants/enums';
import { UserContext } from '../context/UserContext';
import VaccinationPersonGroupedInfo, {
  IButton,
} from './VaccinationPersonGroupedInfo';
import { DATE_AND_TIME_FORMAT } from '../constants';
import LoadingIndicator from './LoadingIndicator';
import VaccinationSettingsGroup from './VaccinationSettingsGroup';

interface IProps {
  member: VaccinationPerson;
  groupId?: string;
  childGroup?: boolean;
  hasVaccinationAccess: boolean;
  hideSettings?: boolean;
  vaccinationSettings: IVaccinationSettingsProps;
  commentStatusList?: IVaccinationCommentStatus[] | null;
  back: () => void;
  reloadGroup?: () => Promise<void>;
}

const VaccinationGroupMemberDetails: React.FC<IProps> = ({
  member,
  groupId,
  hasVaccinationAccess,
  hideSettings = false,
  vaccinationSettings,
  commentStatusList,
  back,
  reloadGroup,
}) => {
  const [lang] = useContext(LangContext);
  const history = useHistory();
  const theme = useTheme();
  const { notifyError, notifySuccess } = useNotifier();

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [backDialogOpen, setBackDialogOpen] = useState(false);
  const [vaccineMismatchDialogOpen, setVaccineMismatchDialogOpen] =
    useState(false);
  const [mismatchDialogOpen, setMismatchDialogOpen] = useState(false);

  const [settingsErrorDialogOpen, setSettingsErrorDialogOpen] = useState(false);
  const [user] = useContext(UserContext);
  const [warningVaccinationDialogOpen, setWarningVaccinationDialogOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedMember, setSelectedMember] =
    useState<VaccinationPerson | null>(null);
  const [boosterVaccineErrorDialogOpen, setBoosterVaccineErrorDialogOpen] =
    useState(false);

  useEffect(() => {
    const fetchGroups = async () => {
      const response = await vaccinationService.getAllGroupsByPersonId(
        member.vaccinationPersonId || 0
      );
      if (response.isOk && response.data) {
        //setSelectedMemberChildDetails(response.data);
        const memberWithGroups: VaccinationPerson = {
          ...member,
          groups: response.data,
        };
        setSelectedMember(memberWithGroups);

        setLoading(false);
      } else {
        setSelectedMember({ ...member });
        setLoading(false);
      }
    };

    fetchGroups();
  }, [member]);

  useEffect(() => {
    if (
      member &&
      !member.secondVaccination.vaccinationDate &&
      vaccinationSettings
    ) {
      if (
        (member &&
          member.firstVaccination.vaccinationDate &&
          !member.secondVaccination.vaccinationDate &&
          vaccinationSettings.selectedVaccine &&
          vaccinationSettings.selectedVaccine.atc !==
            member.firstVaccination.atc) ||
        (member &&
          !member.secondVaccination.vaccinationDate &&
          vaccinationSettings.selectedLocation &&
          member.summoningLocation &&
          vaccinationSettings.selectedLocation.id !==
            member.summoningLocation.id)
      ) {
        setMismatchDialogOpen(true);
      }
    }
  }, [member, vaccinationSettings]);

  if (loading) {
    return (
      <Grid container justify="center" alignItems="center">
        <LoadingIndicator />
      </Grid>
    );
  }

  return (
    <React.Fragment>
      {vaccinationSettings && !hideSettings && (
        <div style={{ marginBottom: theme.spacing(3) }}>
          <VaccinationSettingsGroup {...vaccinationSettings} />
        </div>
      )}
      {selectedMember && (
        <VaccinationPersonGroupedInfo
          vaccinationPerson={selectedMember}
          vaccinationPersonDetails={getMemberDetails()}
          secondDetailsColumn={getSecondColumnDetails()}
          buttons={getButtons()}
        />
      )}
      {hasVaccinationAccess && renderDialogs()}
    </React.Fragment>
  );

  function renderDialogs() {
    return (
      <React.Fragment>
        <ConfirmDialog
          open={backDialogOpen}
          title={translations.back[lang]}
          text={translations.backToVaccinationGroupDialog[lang]}
          confirmText={translations.back[lang]}
          onConfirm={back}
          onCancel={() => setBackDialogOpen(false)}
        />
        <ConfirmDialog
          error
          open={vaccineMismatchDialogOpen}
          title={translations.vaccineMismatch[lang]}
          text={translations.vaccineMismatchText[lang]}
          onCancel={() => setVaccineMismatchDialogOpen(false)}
          cancelText={translations.ok[lang]}
        />
        <ConfirmDialog
          error
          open={mismatchDialogOpen}
          title={translations.warning[lang]}
          onCancel={() => setMismatchDialogOpen(false)}
          cancelText={translations.ok[lang]}
        >
          {renderMismatchDialogContent()}
        </ConfirmDialog>
        <ConfirmDialog
          error
          open={settingsErrorDialogOpen}
          title={translations.settings[lang]}
          text={translations.settingsErrorDialog[lang]}
          onCancel={() => setSettingsErrorDialogOpen(false)}
          cancelText={translations.ok[lang]}
        />
        <ConfirmDialog
          error
          open={warningVaccinationDialogOpen}
          title={translations.vaccinationWarningDialogTitle[lang]}
          text={translations.vaccinationWarningDialogText[lang]}
          onCancel={() => setWarningVaccinationDialogOpen(false)}
          onConfirm={() => {
            setWarningVaccinationDialogOpen(false);
            finishVaccination(true);
          }}
          cancelText={translations.cancel[lang]}
        />
        <ConfirmDialog
          error
          open={boosterVaccineErrorDialogOpen}
          title={translations.vaccinationBoosterErrorDialogTitle[lang]}
          text={parseTemplateString(
            translations.vaccinationBoosterErrorDialogText[lang],
            vaccinationSettings?.selectedVaccine?.numberOfPreviousVaccinationsRequired.toString() ||
              '2'
          )}
          onCancel={() => setBoosterVaccineErrorDialogOpen(false)}
          cancelText={translations.ok[lang]}
        />
      </React.Fragment>
    );
  }

  function getMemberDetails(): IVaccinationPersonDetails[][] {
    const details: IVaccinationPersonDetails[][] = [];
    const personDetails: IVaccinationPersonDetails[] = [
      {
        label: translations.name[lang],
        getVal(vaccinationPerson: VaccinationPerson) {
          return vaccinationPerson.name;
        },
      },
      {
        label: translations.SSN[lang],
        getVal(vaccinationPerson: VaccinationPerson) {
          return vaccinationPerson.ssn;
        },
      },
      {
        label: translations.phoneNumber[lang],
        render(vaccinationPerson: VaccinationPerson) {
          return (
            <EditPhoneNumber
              canEdit
              phoneNumber={vaccinationPerson.phoneNumber}
              submitChanges={updatePhoneNumber}
            />
          );
        },
      },
      {
        label: translations.serialNumber[lang],
        getVal(vaccinationPerson: VaccinationPerson) {
          return vaccinationPerson.serialNumber;
        },
      },
      {
        label: translations.summoningDate[lang],
        getVal(vaccinationPerson: VaccinationPerson) {
          return vaccinationPerson.nextSummoning?.date
            ? `${moment(vaccinationPerson.nextSummoning?.date).format(
                DATE_AND_TIME_FORMAT
              )} - ${vaccinationPerson.nextSummoning?.location.name || ''} `
            : '';
        },
      },
    ];

    if (
      !member?.secondVaccination.vaccinationDate &&
      member.summoningDate &&
      member.summoningLocation
    ) {
      personDetails.push({
        label: translations.summoningDate[lang],
        getVal(vaccinationPerson: VaccinationPerson) {
          return vaccinationPerson.summoningDate
            ? `${moment(vaccinationPerson.summoningDate).format(
                DATE_AND_TIME_FORMAT
              )} - ${vaccinationPerson.summoningLocation?.name || ''} `
            : '';
        },
      });
    }

    details.push(personDetails);

    details.push([
      {
        label: translations.comment[lang],
        render: (vaccinationPerson: VaccinationPerson) => (
          <VaccinationComment
            canEdit
            vaccinationComment={vaccinationPerson.vaccinationComment}
            commentStatusList={commentStatusList}
            updateComment={updateComment}
            deleteComment={deleteComment}
            locations={vaccinationSettings.locations}
          />
        ),
      },
    ]);

    details.push([
      {
        label: translations.vaccinations[lang],
        render: (vaccinationPerson: VaccinationPerson) => (
          <VaccinationStatusTable
            vaccinationPerson={vaccinationPerson}
            vaccinations={vaccinationPerson.vaccinations}
            vaccines={
              vaccinationSettings?.vaccines?.filter(
                (vaccine) => vaccine.vaccineType === VaccineType.COVID_19
              ) || null
            }
            locations={vaccinationSettings?.locations || null}
            canEdit={
              isAccessible(accessControl.VACCINATION_GROUP, user) ||
              isAccessible(accessControl.VACCINATION, user)
            }
            updateVaccination={updateVaccination}
            revokeVaccination={revokeVaccination}
          />
        ),
      },
    ]);

    /*     details.push([
      {
        label: translations.influenzaVaccinations[lang],
        render: (vaccinationPerson: VaccinationPerson) => (
          <VaccinationStatusTable
            vaccinationPerson={vaccinationPerson}
            vaccinations={vaccinationPerson.influenzaVaccinatnions}
            vaccines={
              vaccinationSettings?.vaccines?.filter(
                (vaccine) => vaccine.vaccineType === VaccineType.INFLUENZA
              ) || null
            }
            locations={vaccinationSettings?.locations || null}
            canEdit={
              isAccessible(accessControl.VACCINATION_GROUP, user) ||
              isAccessible(accessControl.VACCINATION, user)
            }
            updateVaccination={updateInfluenzaVaccination}
            revokeVaccination={revokeInfluenzaVaccination}
          />
        ),
      },
    ]); */

    return details;
  }

  function getSecondColumnDetails() {
    return [
      [
        {
          render: (vaccinationPerson: VaccinationPerson) => {
            return <GroupsInfo groups={vaccinationPerson.groups} />;
          },
        },
      ],
    ];
  }

  function getButtons() {
    const buttons: IButton[] = [];
    let canVaccinate = false;

    if (hasVaccinationAccess) {
      const previousVaccine = vaccinationSettings?.vaccines?.find(
        (v) => v.id === member.firstVaccination.codeId
      );

      const vaccinateButton: IButton = {
        label: !member.firstVaccination.vaccinationDate
          ? translations.finishFirstVaccination[lang]
          : !member.secondVaccination.vaccinationDate
          ? translations.finishSecondVaccination[lang]
          : parseTemplateString(
              translations.finishNextVaccination[lang],
              `${member.vaccinations.length + 1}`
            ),
        disabled: loadingUpdate,
        onClick: () => {
          finishVaccination(false);
        },
      };

      const getDaysUntilText = (previousDate: string, daysBetween: number) => {
        var daysUntil = moment(previousDate)
          .add(daysBetween, 'days')
          .startOf('day')
          .diff(moment(), 'days');
        const daysUntilStr = (daysUntil + 1).toString();
        if (daysUntilStr[daysUntilStr.length - 1] === '1') {
          return translations.vaccinationTimeNotPassedSingular[lang].replace(
            '$0',
            daysUntilStr
          );
        } else {
          return translations.vaccinationTimeNotPassedPlural[lang].replace(
            '$0',
            daysUntilStr
          );
        }
      };

      if (!member.firstVaccination.vaccinationDate) {
        buttons.push(vaccinateButton);
        canVaccinate = true;
      } else {
        if (!member.secondVaccination.vaccinationDate) {
          const daysBetween = previousVaccine
            ? previousVaccine.daysBetweenVaccinations
            : 0;
          const secondVaccinationDate = moment(
            member.firstVaccination.vaccinationDate
          )
            .add(daysBetween, 'days')
            .startOf('day');
          const secondVaccinationValid = moment().isAfter(
            secondVaccinationDate
          );
          canVaccinate = secondVaccinationValid;
          if (secondVaccinationValid) {
            buttons.push(vaccinateButton);
          } else {
            buttons.push({
              label: `${getDaysUntilText(
                member.firstVaccination.vaccinationDate,
                daysBetween
              )}`,
              disabled: true,
              onClick: () => {},
            });
          }
        } else {
          const lastVaccination =
            member.vaccinations[member.vaccinations.length - 1];
          const daysBetween =
            lastVaccination.boosterDaysBetweenVaccinations ||
            lastVaccination.daysBetweenVaccinations ||
            0;
          const nextVaccinationDate = moment(lastVaccination.vaccinationDate)
            .add(daysBetween, 'days')
            .startOf('day');
          const nextVaccinationValid = moment().isAfter(nextVaccinationDate);
          canVaccinate = nextVaccinationValid;
          if (nextVaccinationValid) {
            buttons.push(vaccinateButton);
          } else {
            if (lastVaccination.vaccinationDate) {
              buttons.push({
                label: `${getDaysUntilText(
                  lastVaccination.vaccinationDate,
                  daysBetween
                )}`,
                disabled: true,
                onClick: () => {},
              });
            }
          }
        }
      }
    }

    buttons.push({
      label: translations.back[lang],
      variant: 'outlined',
      disabled: loadingUpdate,
      onClick: canVaccinate ? () => setBackDialogOpen(true) : back,
    });

    return buttons;
  }

  async function updateVaccination(values: IUpdateFormValues) {
    if (member.ssn && values.vaccine) {
      const params = {
        personSsn: member.ssn,
        vaccinationId: values.id,
        vaccinationCodeId: parseInt(values.vaccine.value),
        locationId: parseInt(values.location),
        vaccinationDate: getDateTime(
          values.vaccinationDate,
          values.vaccinationTime
        ).toISOString(),
        isFirstVaccination: values.isFirstVaccination,
      } as IVaccinationUpdate;

      const response = await vaccinationService.updateVaccination(params);

      if (response.isOk) {
        reloadGroup && (await reloadGroup());
        notifySuccess(translations.vaccinationUpdated[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  /*   async function updateInfluenzaVaccination(values: IUpdateFormValues) {
    if (member && values.vaccine) {
      const params = {
        vaccinationCodeId: parseInt(values.vaccine.value),
        locationId: parseInt(values.location),
        vaccinationDate: getDateTime(
          values.vaccinationDate,
          values.vaccinationTime
        ).toISOString(),
      } as IInfluenzaVaccinationUpdate;

      const response = await influenzaService.updateVaccination(
        params,
        values.id
      );

      if (response.isOk) {
        reloadGroup && (await reloadGroup());
        notifySuccess(translations.vaccinationUpdated[lang]);
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  } */

  function getDateTime(dateValue: Moment | null, timeValue: Moment | null) {
    var date = moment(dateValue);
    var time = moment(timeValue, 'HH:mm');
    date = date.hour(time.get('hour'));
    date = date.minute(time.get('minutes'));

    return date;
  }

  async function finishVaccination(skipWarningDialog?: boolean) {
    if (
      !skipWarningDialog &&
      vaccinationSettings &&
      shouldShowWarningForBooster(member)
    ) {
      setWarningVaccinationDialogOpen(true);
    } else if (
      !vaccinationSettings ||
      !vaccinationSettings.selectedVaccine ||
      !vaccinationSettings.selectedLocation
    ) {
      setSettingsErrorDialogOpen(true);
    } else if (
      shouldShowErrorForBoosterVaccine(
        vaccinationSettings.selectedVaccine,
        member.vaccinations
      )
    ) {
      setBoosterVaccineErrorDialogOpen(true);
    } else {
      setLoadingUpdate(true);
      const response = await vaccinationService.vaccinate({
        personSsn: member.ssn,
        vaccinationCodeId: vaccinationSettings.selectedVaccine.id,
        locationId: vaccinationSettings.selectedLocation.id,
        isFirstVaccination: !member.vaccinations.length,
        groupId: groupId ? parseInt(groupId) : undefined,
      });

      if (response.isOk) {
        reloadGroup && (await reloadGroup());
        notifySuccess(translations.vaccinationFinished[lang]);
        back();
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(response.data || translations.errorOccurred[lang]);
        setLoadingUpdate(false);
      }
    }
  }

  /*   async function covidVaccination(payload: IVaccinate) {
    const response = await vaccinationService.vaccinate(payload);
    return response;
  }

  async function influenzaVaccination(payload: IVaccinate) {
    const response = await influenzaService.vaccinate(payload);
    return response;
  } */

  async function revokeVaccination(ssn: string, vaccinationId: number) {
    setLoadingUpdate(true);
    const response = await vaccinationService.cancelVaccination(
      ssn,
      vaccinationId
    );

    if (response.isOk) {
      reloadGroup && (await reloadGroup());
      notifySuccess(translations.vaccinationRevoked[lang]);
      setLoadingUpdate(false);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(response.data || translations.errorOccurred[lang]);
      setLoadingUpdate(false);
    }
  }

  /*   async function revokeInfluenzaVaccination(
    personSsn: string,
    vaccinationId: number
  ) {
    const response = await influenzaService.cancelVaccination(vaccinationId);

    if (response.isOk) {
      reloadGroup && (await reloadGroup());
      notifySuccess(translations.vaccinationRevoked[lang]);
    } else {
      notifyError(response.data || translations.errorOccurred[lang]);
    }
  } */

  async function updateComment(
    statusId: number | null,
    comment: string,
    locationId: number
  ) {
    if (groupId && member.vaccinationPersonId) {
      const params: IVaccinationComment = {
        comment: comment,
        statusId: statusId,
        groupId: parseInt(groupId),
        vaccinationPersonId: member.vaccinationPersonId,
        locationId,
      };
      const response =
        member.vaccinationComment.id !== undefined
          ? await vaccinationService.updateVaccinationComment(
              member.vaccinationComment.id,
              params
            )
          : await vaccinationService.addVaccinationComment(params);

      if (response.isOk) {
        reloadGroup && (await reloadGroup());
        notifySuccess(translations.commentAdded[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function deleteComment() {
    if (member.vaccinationComment.id !== undefined) {
      const response = await vaccinationService.deleteVaccinationComment(
        member.vaccinationComment.id
      );

      if (response.isOk) {
        reloadGroup && (await reloadGroup());
        notifySuccess(translations.commentDeleted[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    }
  }

  function renderMismatchDialogContent() {
    let vaccineMismatch = false;
    let locationMismatch = false;
    if (
      member &&
      member.firstVaccination.vaccinationDate &&
      !member.secondVaccination.vaccinationDate &&
      vaccinationSettings?.selectedVaccine &&
      vaccinationSettings.selectedVaccine.atc !== member.firstVaccination.atc
    ) {
      vaccineMismatch = true;
    }

    if (
      member &&
      !member.secondVaccination.vaccinationDate &&
      vaccinationSettings?.selectedLocation &&
      member.summoningLocation &&
      vaccinationSettings.selectedLocation.id !== member.summoningLocation.id
    ) {
      locationMismatch = true;
    }

    return (
      <Grid container spacing={3}>
        {vaccineMismatch && (
          <Grid item xs={12}>
            <Typography>{translations.vaccineMismatchText[lang]}</Typography>
          </Grid>
        )}

        {locationMismatch && (
          <Grid item xs={12}>
            <Typography>{`${translations.locationMismatchText[lang]}: ${member?.summoningLocation?.name} - ${vaccinationSettings?.selectedLocation?.name}`}</Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  async function updatePhoneNumber(newNumber: string) {
    if (member.vaccinationPersonId) {
      const params: IVaccinationPersonPhoneNumberUpdate = {
        ssn: member.ssn,
        newPhoneNumber: newNumber,
      };
      const response = await vaccinationService.updatePhoneNumber(params);

      if (response.isOk) {
        reloadGroup && (await reloadGroup());
        notifySuccess(translations.phoneNumberChanged[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }
};

export default VaccinationGroupMemberDetails;
