import React, { useContext, useState } from 'react';
import {
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import ConfirmDialog from './ConfirmDialog';
import { Context } from '../store/Store';
import theme from '../theme';

interface IProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (airlineSurveillanceStatus?: number) => void;
}

const BcsCheckoutDialog: React.FC<IProps> = ({ open, onCancel, onConfirm }) => {
  const [lang] = useContext(LangContext);
  const [state] = useContext(Context);
  const [airlineSurveillanceStatus, setAirlineSurveillanceStatus] = useState<
    number | null
  >(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const a = (event.target as HTMLInputElement).value;
    setAirlineSurveillanceStatus(parseInt(a));
  };

  function isConfirmDisable() {
    if (state.confirmQtFromAirline) {
      if (airlineSurveillanceStatus && airlineSurveillanceStatus > 0) {
        return false;
      }
      return true;
    }
    return false;
  }

  return (
    <ConfirmDialog
      open={open}
      title={translations.confirmRegistration[lang]}
      onCancel={() => {
        setAirlineSurveillanceStatus(null);
        onCancel();
      }}
      disableConfirm={isConfirmDisable()}
      onConfirm={() => {
        onConfirm(
          airlineSurveillanceStatus ? airlineSurveillanceStatus : undefined
        );
      }}
      confirmText={translations.confirm[lang]}
    >
      {renderContent()}
    </ConfirmDialog>
  );

  function renderContent() {
    if (state.confirmQtFromAirline) {
      return renderQtList();
    } else {
      return <Typography>{translations.printLabelWarning[lang]}</Typography>;
    }
  }

  function renderQtList() {
    return (
      <FormControl component="fieldset">
        <Typography>{translations.bscDialogHeader[lang]}</Typography>
        <RadioGroup
          style={{ marginTop: theme.spacing(1) }}
          aria-label="airlineSurveillanceStatus"
          name="airlineSurveillanceStatus"
          value={airlineSurveillanceStatus}
          onChange={handleChange}
        >
          <FormControlLabel
            value={1}
            control={<Radio color="primary" />}
            label={translations.preRegistration[lang]}
          />
          <FormControlLabel
            value={2}
            control={<Radio color="primary" />}
            label={translations.certificate[lang]}
          />
          <FormControlLabel
            value={3}
            control={<Radio color="primary" />}
            label={translations.preRegistrationAndCertificate[lang]}
          />
          <FormControlLabel
            value={90}
            control={<Radio color="primary" />}
            label={translations.nothingAbove[lang]}
          />
        </RadioGroup>
      </FormControl>
    );
  }
};

export default BcsCheckoutDialog;
