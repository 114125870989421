import React, { useContext } from 'react';
import { Grid, Typography, Paper, Theme } from '@material-ui/core';

import Layout from '../components/Layout';
import Section from '../components/Section';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { makeStyles } from '@material-ui/styles';
import Button from '../components/Button';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
const useStyles = makeStyles((theme: Theme) => ({
  select: {
    backgroundColor: 'white',
  },
  menu: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    flex: 1,
    padding: theme.spacing(3),
    margin: theme.spacing(3),
    textAlign: 'center',
    maxWidth: theme.dimensions.boxWidth,
  },
  typographyTitle: {
    padding: theme.spacing(2),
  },
}));

const LocationSettingsMenuPage: React.FC = () => {
  const { system } = useParams<any>();
  const [lang] = useContext(LangContext);

  const classes = useStyles();
  const history = useHistory();

  return (
    <Layout useDefaultSpacing title={translations.settings[lang]}>
      <div className={classes.root}>
        <Paper elevation={2} className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.typographyTitle} variant="h4">
                {translations.actions[lang]}
              </Typography>
            </Grid>
            <Section>
              {system === 'borderControl' && (
                <Grid container spacing={3} direction="column">
                  <React.Fragment>
                    <Grid item xs={12} sm={12}>
                      <Button
                        fullWidth
                        onClick={() => history.push('/access-control')}
                      >
                        {translations.accessControl[lang]}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button
                        fullWidth
                        onClick={() => history.push('/location-settings')}
                      >
                        {translations.schedule[lang]}
                      </Button>
                    </Grid>
                  </React.Fragment>
                </Grid>
              )}
              {system === 'vaccination' && (
                <Grid container spacing={3} direction="column">
                  <React.Fragment>
                    <Grid item xs={12} sm={12}>
                      <Button
                        fullWidth
                        onClick={() => history.push('/access-control')}
                      >
                        {translations.accessControl[lang]}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button
                        fullWidth
                        onClick={() => history.push('/lot-control/')}
                      >
                        {translations.lotNumber[lang]}
                      </Button>
                    </Grid>
                  </React.Fragment>
                </Grid>
              )}
            </Section>
          </Grid>
        </Paper>
      </div>
    </Layout>
  );
};

export default LocationSettingsMenuPage;
