import React, { useContext } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import { PreScreeningStatus as PreScreeningStatusModel } from '../models/Models';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { preScreeningStatus } from '../constants/enums';

interface IProps {
  status: PreScreeningStatusModel;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  exemptLabel: {
    position: 'absolute',
    left: '100%',
    marginLeft: theme.spacing(1),
    padding: '2px 8px',
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
}));

const PreScreeningStatus: React.FC<IProps> = ({ status }) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);
  const canSkipPCRTest = (status: number) => {
    return (
      status ===
        preScreeningStatus.NEGATIVE_PCR_TEST_NOT_NEEDED_BECAUSE_CERTIFICATE ||
      status === preScreeningStatus.NEGATIVE_PCR_TEST_NOT_NEEDED_BECAUSE_CHILD
    );
  };

  return (
    <div className={classes.container}>
      <Typography color="textSecondary">{status.description[lang]}</Typography>
      {canSkipPCRTest(status.id) && (
        <Typography className={classes.exemptLabel}>
          {status.id ===
          preScreeningStatus.NEGATIVE_PCR_TEST_NOT_NEEDED_BECAUSE_CHILD
            ? translations.child[lang]
            : translations.certificate[lang]}
        </Typography>
      )}
    </div>
  );
};

export default PreScreeningStatus;
