import React, { useContext, useEffect, useState } from 'react';
import { Grid, useTheme } from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import moment from 'moment';

import Layout from '../components/Layout';
import Section from '../components/Section';
import useNotifier from '../hooks/useNotifier';
import LoadingIndicator from '../components/LoadingIndicator';
import ReviewCertificateDetails from '../components/ReviewCertificate/ReviewCertificateDetails';
import DisplayReviewStatus from '../components/ReviewCertificate/DisplayReviewStatus';

import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

import { Passenger } from '../models/Models';
import * as certificateReviewService from '../services/certificateReviewService';
import { IPassengerDetails } from '../interfaces/passenger';
import { IPassengerReviewDetails } from '../interfaces/passengerReview';
import { DATE_FORMAT } from '../constants';
import { getPhoneNumberString } from '../utils';
import {
  PassengerFiles,
  PassengerReview,
} from '../models/CertificateReviewModels';
import { reviewCertificateStatuses } from '../constants/enums';

const ReviewPassengerCertificatesPage: React.FC<
  RouteComponentProps<{ id: string }>
> = ({ match }) => {
  const theme = useTheme();
  const [lang] = useContext(LangContext);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);

  const [personInfo, setpersonInfo] = useState<PassengerReview | null>(null);
  const [personFiles, setpersonFiles] = useState<PassengerFiles[] | null>(null);
  const id = match.params.id;

  const { notifyError, notifySuccess } = useNotifier();

  useEffect(() => {
    const fetchPassenger = async () => {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 300));
      const response = await certificateReviewService.getReviewsById(id);
      if (response.isOk) {
        setpersonInfo(response?.data || null);
        setLoading(false);
      } else {
        setLoading(false);
        notifyError(translations.errorOccurred[lang]);
      }
    };
    const fetchPassengerFiles = async () => {
      setLoadingFiles(true);
      await new Promise((resolve) => setTimeout(resolve, 300));
      const response = await certificateReviewService.getFilesByReviewId(id);
      if (response.isOk) {
        setpersonFiles(response?.data || null);
        setLoadingFiles(false);
      } else {
        setLoadingFiles(false);
        notifyError(translations.errorOccurred[lang]);
      }
    };
    fetchPassenger();
    fetchPassengerFiles();
    /* eslint-disable */
  }, [id]);

  return (
    <Layout
      useDefaultSpacing
      title={translations.passengerInfo[lang]}
      otherButton={{
        label: translations.certificateReview[lang],
        onClick: () => history.push('/certificate-review'),
      }}
    >
      <Section>
        {loading ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: theme.spacing(2) }}
          >
            <LoadingIndicator />
          </Grid>
        ) : (
          personInfo && (
            <ReviewCertificateDetails
              passengerReview={personInfo}
              passenger={personInfo.passenger}
              personalDetails={getPersonalDetails()}
              otherDetails={getOtherDetails()}
              extraSamplingDetails={getExtraSamplingDetals()}
              reviewDetails={getReviewDetails()}
              files={personFiles || []}
              loadingFiles={loadingFiles}
              changeStatusReview={changeStatusReview}
              getNextAvailablePassenger={getNextAvailablePassenger}
            />
          )
        )}
      </Section>
    </Layout>
  );

  function getPersonalDetails(): IPassengerDetails[] {
    return [
      {
        label: translations.lastName[lang],
        getVal: (passenger: Passenger) => passenger.lastName,
      },
      {
        label: translations.firstNames[lang],
        getVal: (passenger: Passenger) => passenger.firstName,
      },
      {
        label: translations.dateOfBirth[lang],
        getVal: (passenger: Passenger) =>
          passenger.dateOfBirth
            ? moment(passenger.dateOfBirth).format(DATE_FORMAT)
            : translations.notRegistered[lang],
      },
      {
        label: translations.phoneNumber[lang],
        getVal: getPhoneNumberString,
      },
      {
        label: translations.email[lang],
        getVal: (passenger: Passenger) => passenger.email || '',
      },
      {
        label: translations.numberOfChildren[lang],
        getVal: (passenger: Passenger) => passenger.numberOfChildren.toString(),
      },
      {
        label: translations.nationality[lang],
        getVal: (passenger: Passenger) => passenger.nationality,
      },
      {
        label: translations.countryOfResidence[lang],
        getVal: (passenger: Passenger) => passenger.countryOfResidence,
      },
    ];
  }

  function getOtherDetails(): IPassengerDetails[] {
    return [
      {
        label: translations.serialNumber[lang],
        getVal: (passenger: Passenger) => passenger.serialNumber,
      },
      {
        label: translations.countryOfDeparture[lang],
        getVal: (passenger: Passenger) =>
          passenger.countryOfDeparture || translations.notRegistered[lang],
      },
      {
        label: translations.departureFromIceland[lang],
        getVal: (passenger: Passenger) =>
          passenger.departureFromIceland
            ? moment(passenger.departureFromIceland).format(DATE_FORMAT)
            : translations.notRegistered[lang],
      },
    ];
  }

  function getExtraSamplingDetals(): IPassengerDetails[] {
    return [
      {
        label: translations.arrivalDate[lang],
        getVal: (passenger: Passenger) =>
          moment(passenger.dateOfArrival).format(DATE_FORMAT),
      },
      {
        label: translations.icelandRelationsShort[lang],
        getVal: (passenger: Passenger) =>
          passenger.extraSamplingReqired
            ? translations.yes[lang]
            : translations.no[lang],
      },
      {
        label: translations.samplingAtTheAirport[lang],
        getVal: (passenger: Passenger) =>
          passenger.extraSamplingSelectedTestLocation === 0
            ? translations.yes[lang]
            : translations.no[lang],
      },
    ];
  }

  function getReviewDetails(): IPassengerReviewDetails[] {
    return [
      {
        label: translations.reviewStatus[lang],
        render: (passengerExtra: PassengerReview) => (
          <DisplayReviewStatus status={passengerExtra.reviewStatus} />
        ),
      },
      {
        label: translations.resonForRejection[lang],
        getVal: (passengerExtra: PassengerReview) =>
          `${passengerExtra.rejectedStatus.description[lang]} ${
            passengerExtra?.rejectedComment
              ? '- ' + passengerExtra.rejectedComment
              : ''
          }`,
        hide: (passengerExtra: PassengerReview) =>
          passengerExtra.reviewStatus.id !== reviewCertificateStatuses.REJECT,
      },
    ];
  }

  async function changeStatusReview(
    reviewCertificateStatus: number,
    rejectStatus?: string,
    rejectComment?: string,
    passengerBorderStatus?: number
  ) {
    const response = await certificateReviewService.updateReview(
      id,
      reviewCertificateStatus,
      Number(rejectStatus),
      rejectComment || '',
      passengerBorderStatus
    );
    if (response.isOk) {
      if (reviewCertificateStatus === reviewCertificateStatuses.APPROVE) {
        notifySuccess(translations.reviewApproved[lang]);
      }
      if (reviewCertificateStatus === reviewCertificateStatuses.REJECT) {
        notifySuccess(translations.reviewRejected[lang]);
      }
      if (reviewCertificateStatus === reviewCertificateStatuses.UNRESOLVE) {
        notifySuccess(translations.reviewStatusChanged[lang]);
      }

      await getNextAvailablePassenger();
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function getNextAvailablePassenger() {
    const response = await certificateReviewService.GetNextReview();
    if (response.isOk && response.data?.id) {
      history.push(`/certificate-review/${response.data.id}`);
      setLoading(false);
    } else {
      history.push(`/certificate-review`);
    }
  }
};

export default ReviewPassengerCertificatesPage;
