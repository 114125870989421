import React, { useContext, useState } from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';

import Layout from '../components/Layout';
import Sampling from '../components/Sampling';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import useNotifier from '../hooks/useNotifier';
import { Passenger } from '../models/Models';
import { isReadyForSampling } from '../utils';
import ScheduleDialog from '../components/ScheduleDialog';

const SamplingPage: React.FC = () => {
  const { notifyError } = useNotifier();
  const [lang] = useContext(LangContext);

  const [passenger, setCurrentPassenger] = useState<Passenger | null>(null);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [backDialogOpen, setBackDialogOpen] = useState(false);

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  return (
    <Layout
      useDefaultSpacing
      title={translations.rapidTestSampling[lang]}
      otherButton={
        !passenger
          ? {
              label: translations.schedule[lang],
              icon: <ScheduleIcon />,
              onClick: () => setScheduleDialogOpen(true),
            }
          : undefined
      }
      backButtonClick={
        isReadyForSampling(passenger)
          ? () => setBackDialogOpen(true)
          : undefined
      }
    >
      <Sampling
        passenger={passenger}
        setCurrentPassenger={setCurrentPassenger}
        backDialogOpen={backDialogOpen}
        closeBackDialog={() => setBackDialogOpen(false)}
        isRapidTestSampling={true}
      />
      <ScheduleDialog
        open={scheduleDialogOpen}
        onClose={() => setScheduleDialogOpen(false)}
      />
    </Layout>
  );
};
export default SamplingPage;
