import React, { useContext, useState } from 'react';
import {
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from '@material-ui/core';
import moment, { Moment } from 'moment';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { KeyboardTimePicker } from '@material-ui/pickers';

import {
  VaccinationPerson,
  Vaccine,
  VaccinationLocation,
  Vaccination,
} from '../models/VaccinationModels';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { DATE_AND_TIME_FORMAT } from '../constants';
import EditButton from './EditButton';
import ConfirmDialog from './ConfirmDialog';
import Dropdown from './Dropdown';
import DatePicker from './DatePicker';
import {
  getFormattedVaccineName,
  getFormattedVaccineNameFromVaccination,
} from '../utils';
import AutocompleteGroup, { IGroupOption } from './AutocompleteGroup';

interface IProps {
  vaccinationPerson: VaccinationPerson;
  vaccinations: Vaccination[];
  vaccines: Vaccine[] | null;
  locations: VaccinationLocation[] | null;
  canEdit: boolean;
  updateVaccination: (values: IFormValues) => Promise<void>;
  revokeVaccination: (ssn: string, id: number) => Promise<void>;
}

export interface IFormValues {
  id: number;
  vaccine: IGroupOption | null;
  location: string;
  vaccinationDate: Moment | null;
  vaccinationTime: Moment | null;
  isFirstVaccination: boolean;
}

type IFormik = FormikProps<IFormValues>;

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderBottomWidth: 0,
    borderRadius: 4,
    marginTop: theme.spacing(1),
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  emptyText: {
    display: 'inline-block',
  },
}));

const schema = Yup.object().shape({
  vaccine: Yup.object().required(),
  location: Yup.string().required(),
  vaccinationDate: Yup.object().required(),
  vaccinationTime: Yup.mixed()
    .test(
      'valid-date',
      'Please enter a valid date',
      (val) => moment(val, 'HH:mm').isValid() && moment(val).isBefore(moment())
    )
    .required(),
});

const VaccinationStatusTable: React.FC<IProps> = ({
  vaccinationPerson,
  vaccinations,
  vaccines,
  locations,
  canEdit,
  updateVaccination,
  revokeVaccination,
}) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);

  const [editVaccination, setEditVaccination] = useState<Vaccination | null>(
    null
  );
  const [revokeVaccinationId, setRevokeVaccinationId] = useState<number | null>(
    null
  );
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  if (!vaccinations.length) {
    return (
      <Typography className={classes.emptyText} color="textSecondary">
        {translations.noVaccinationsFinished[lang]}
      </Typography>
    );
  }

  return (
    <>
      <TableContainer className={classes.container}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{translations.numberShort[lang]}</TableCell>
              <TableCell>{translations.finished[lang]}</TableCell>
              <TableCell>{translations.location[lang]}</TableCell>
              <TableCell>{translations.vaccine[lang]}</TableCell>
              {canEdit /* && vaccinations.find((x) => !x.readonly) */ && (
                <TableCell />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {vaccinations.map((vaccination, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {moment(vaccination.vaccinationDate).format(
                    DATE_AND_TIME_FORMAT
                  )}
                </TableCell>
                <TableCell>{vaccination.vaccinationLocationName}</TableCell>
                <TableCell>{vaccination.vaccineName}</TableCell>
                {canEdit && (
                  <TableCell>
                    {(!vaccination.readonly && (
                      <EditButton
                        onClick={() => setEditVaccination(vaccination)}
                      />
                    )) ||
                      ''}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {renderDialogs()}
    </>
  );

  function renderDialogs() {
    return (
      <>
        <Formik
          onSubmit={onUpdateVaccination}
          validationSchema={schema}
          initialValues={getInitalValues()}
          enableReinitialize
        >
          {(formik) => (
            <ConfirmDialog
              open={!!editVaccination}
              title={translations.changeVaccination[lang]}
              loading={loadingUpdate}
              leftButtonText={translations.revokeVaccination[lang]}
              leftButtonColor="secondary"
              disableConfirm={!formik.dirty}
              onConfirm={formik.handleSubmit}
              onCancel={() => setEditVaccination(null)}
              onLeftButtonClick={() => {
                const editId = editVaccination?.id || null;
                setEditVaccination(null);
                setRevokeVaccinationId(editId);
              }}
            >
              {renderFormFields(formik)}
            </ConfirmDialog>
          )}
        </Formik>
        <ConfirmDialog
          open={!!revokeVaccinationId}
          title={translations.revokeVaccination[lang]}
          text={`${translations.revokeVaccinationText[lang]} ${vaccinationPerson.name}?`}
          loading={loadingUpdate}
          onConfirm={onRevokeVaccination}
          onCancel={() => setRevokeVaccinationId(null)}
          confirmText={translations.revokeVaccination[lang]}
        />
      </>
    );
  }

  function renderFormFields({
    values,
    errors,
    submitCount,
    handleChange,
    handleBlur,
    setFieldValue,
  }: IFormik) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          {/* {vaccines && (
            <Dropdown
              fullWidth
              id="vaccine"
              value={values.vaccine}
              label={translations.vaccine[lang]}
              items={vaccines
                .sort(
                  (a, b) =>
                    Number(b.vaccineIsActive) - Number(a.vaccineIsActive)
                )
                .map((opt) => ({
                  value: opt.id.toString(),
                  label: `${getFormattedVaccineName(opt)}${
                    !opt.vaccineIsActive
                      ? ` - ${translations.inactive[lang].toUpperCase()}`
                      : ''
                  }`,
                }))}
              onChange={handleChange}
              error={!!errors.vaccine && !!submitCount}
              disabled={!vaccines || vaccines.length <= 1}
            />
          )} */}
          {vaccines && (
            <AutocompleteGroup
              fullWidth
              label={translations.vaccine[lang]}
              id="vaccine"
              value={values.vaccine}
              items={vaccines
                .map((v) => ({
                  label: getFormattedVaccineName(v),
                  value: v.id.toString(),
                  filter: v.name,
                }))
                .sort((a, b) => a.filter.localeCompare(b.filter))}
              error={!!errors.vaccine && !!submitCount}
              disabled={!vaccines || vaccines.length <= 1}
              onChange={(val) =>
                setFieldValue('vaccine', val as IGroupOption | null)
              }
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          {locations && (
            <Dropdown
              fullWidth
              id="location"
              value={values.location}
              label={translations.locationOfVaccination[lang]}
              items={locations.map((opt) => ({
                value: opt.id.toString(),
                label: opt.name,
              }))}
              onChange={handleChange}
              error={!!errors.location && !!submitCount}
              disabled={!locations || locations.length <= 1}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="vaccinationDate"
            label={translations.dateOfVaccination[lang]}
            value={values.vaccinationDate}
            muiProps={{ disableToolbar: true, disableFuture: true }}
            onChange={(e) => {
              setFieldValue('vaccinationDate', e);
              setFieldValue(
                'vaccinationTime',
                getVaccinationTime(values.vaccinationTime, e)
              );
            }}
            onBlur={handleBlur}
            error={!!errors.vaccinationDate && !!submitCount}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyboardTimePicker
            fullWidth
            autoComplete="off"
            id="vaccinationTime"
            label={translations.timeOfVaccination[lang]}
            variant="inline"
            ampm={false}
            inputVariant="outlined"
            value={values.vaccinationTime}
            mask="__:__"
            placeholder="__:__"
            helperText={''}
            onChange={(e) => {
              setFieldValue(
                'vaccinationTime',
                getVaccinationTime(e, values.vaccinationDate)
              );
            }}
            onBlur={handleBlur}
            error={!!errors.vaccinationTime && !!submitCount}
          />
        </Grid>
      </Grid>
    );
  }

  function getInitalValues() {
    const initDate = editVaccination?.vaccinationDate
      ? (moment(editVaccination.vaccinationDate) as Moment | null)
      : (null as Moment | null);
    const initTime = editVaccination?.vaccinationDate
      ? (moment(editVaccination.vaccinationDate) as Moment | null)
      : (null as Moment | null);
    const location = editVaccination?.vaccinationLocationName || '';

    return {
      id: editVaccination?.id || 0,
      vaccinationDate: initDate,
      vaccinationTime: getVaccinationTime(initTime, initDate),
      vaccine: editVaccination
        ? ({
            label: getFormattedVaccineNameFromVaccination(editVaccination),
            value: editVaccination.codeId?.toString(),
            filter: editVaccination.vaccineName,
          } as IGroupOption | null)
        : (null as IGroupOption | null),
      location:
        locations?.find((l) => l.name === location)?.id.toString() || '',
      isFirstVaccination: !!editVaccination?.isFirstVaccination,
    };
  }

  function getVaccinationTime(
    newTime: Moment | null,
    currentDate: Moment | null
  ) {
    if (newTime && currentDate) {
      const dateCopy = currentDate.clone();
      dateCopy.set({
        hour: newTime.hour(),
        minute: newTime.minute(),
      });
      return dateCopy;
    } else {
      return newTime;
    }
  }

  async function onUpdateVaccination(values: IFormValues) {
    setLoadingUpdate(true);
    await updateVaccination(values);
    setEditVaccination(null);
    setLoadingUpdate(false);
  }

  async function onRevokeVaccination() {
    if (revokeVaccinationId) {
      setLoadingUpdate(true);
      await revokeVaccination(vaccinationPerson.ssn, revokeVaccinationId);
      setRevokeVaccinationId(null);
      setLoadingUpdate(false);
    }
  }
};

export default VaccinationStatusTable;
