import React, { useState, useContext, useEffect } from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';

import { Passenger, BorderControlStatus } from '../models/Models';
import ConfirmDialog from './ConfirmDialog';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

interface IProps {
  open: boolean;
  title?: string;
  text?: string;
  passenger: Passenger;
  statusItems: IStatusItem[];
  onCancel: () => void;
}

export interface IStatusItem {
  statusIds: number[];
  label?: string;
  onConfirm: (passenger: Passenger) => void;
}

const ChangeStatusDialog: React.FC<IProps> = ({
  open,
  title,
  text,
  passenger,
  statusItems,
  onCancel,
}) => {
  const [lang] = useContext(LangContext);
  const [selectedStatusIndex, setSelectedStatusIndex] = useState<number | null>(
    null
  );

  const filteredItems = statusItems.filter(
    (item) =>
      passenger.borderControlStatus.id === undefined ||
      !item.statusIds.includes(passenger.borderControlStatus.id)
  );
  const selectedItem =
    selectedStatusIndex !== null && selectedStatusIndex < filteredItems.length
      ? filteredItems[selectedStatusIndex]
      : null;
  const onConfirm = selectedItem
    ? () => selectedItem.onConfirm(passenger)
    : () => null;

  useEffect(() => {
    if (open) {
      setSelectedStatusIndex(null);
    }
  }, [open]);

  return (
    <ConfirmDialog
      open={open}
      title={title || translations.changeStatus[lang]}
      text={text}
      disableConfirm={!selectedItem}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      {filteredItems.map(renderStatusItem)}
    </ConfirmDialog>
  );

  function renderStatusItem(item: IStatusItem, index: number) {
    let label = item.label;
    if (!label && item.statusIds.length) {
      label = new BorderControlStatus({ id: item.statusIds[0] }).description[
        lang
      ];
    }

    return label ? (
      <div key={index}>
        <FormControlLabel
          label={label}
          onClick={() => setSelectedStatusIndex(index)}
          control={
            <Radio color="primary" checked={index === selectedStatusIndex} />
          }
        />
      </div>
    ) : null;
  }
};

export default ChangeStatusDialog;
