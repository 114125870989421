import moment from 'moment';
import 'moment/locale/is';
import 'moment/locale/en-gb';
import { EVENT_DATE_FORMAT } from '../../../store/Constants';

export function calculateMaxPerSlot(
  start: string,
  end: string,
  maxInSlot: number,
  numberToAdd: number = 0
) {
  if (maxInSlot === 0) {
    return 0;
  }
  const endStr = moment(end, EVENT_DATE_FORMAT);
  const startStr = moment(start, EVENT_DATE_FORMAT);
  const diff = endStr.diff(startStr, 'minutes');
  const maxPerSlot = Math.round((diff / 15) * maxInSlot);
  return Number(maxPerSlot) + numberToAdd;
}
function calcNumberOfSlots(start: string, end: string) {
  const endStr = moment(end, EVENT_DATE_FORMAT);
  const startStr = moment(start, EVENT_DATE_FORMAT);
  const diff = endStr.diff(startStr, 'minutes');
  return Math.round(diff / 15);
}
export function calculateFreeSlots(
  currentBookings: number,
  maxInSlot: number,
  start: string,
  end: string,
  isRecurring: boolean
) {
  if (!isRecurring) {
    return calculateFreeSlotsForNewSlot(maxInSlot, start, end);
  } else {
    const numberOfSlots = calcNumberOfSlots(start, end);
    const count = maxInSlot * numberOfSlots - currentBookings;
    if (count < 0) {
      return 0;
    } else {
      return count;
    }
  }
}
export function calculateFreeSlotsForNewSlot(
  newSlotMaxInSlot: number,
  start: string,
  end: string
) {
  const numberOfSlots = calcNumberOfSlots(start, end);
  return newSlotMaxInSlot * numberOfSlots;
}

export function numberWithCommas(x?: number) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
export function getWeekDayNameInSecondDegree(
  weekDay: number | undefined,
  lang: string
) {
  if (lang === 'is') {
    switch (weekDay) {
      case 1:
        return 'mánudaga';
      case 2:
        return 'þriðjudaga';
      case 3:
        return 'miðvikudaga';
      case 4:
        return 'fimmtudaga';
      case 5:
        return 'föstudaga';
      case 6:
        return 'laugardaga';
      case 7:
        return 'sunnudaga';
      default:
        return '';
    }
  } else {
    if (weekDay) {
      return moment().lang(lang).weekday(weekDay).format('dddd') + 's';
    } else {
      return '';
    }
  }
}
