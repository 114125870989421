import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import LoadingIndicator from './LoadingIndicator';

interface IProps {
  loading?: boolean;
  error?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: theme.dimensions.maxContentWidth,
    margin: '0 auto',
  },
}));

const Section: React.FC<IProps> = ({ children, loading, error }) => {
  const classes = useStyles({});

  return <div className={classes.container}>{renderContent()}</div>;

  function renderContent() {
    if (error) {
      return <Typography variant="h4">{error}</Typography>;
    }

    if (loading) {
      return (
        <Grid container justify="center" alignItems="center">
          <LoadingIndicator />
        </Grid>
      );
    }

    return children;
  }
};

export default Section;
