import React, { useContext, useEffect, useState } from 'react';
import { Grid, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import moment from 'moment';

import Layout from '../components/Layout';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import useNotifier from '../hooks/useNotifier';
import { ResidencePerson } from '../models/Models';
import { parseTemplateString } from '../utils';
import Section from '../components/Section';

import QuarantineGuestsFilter from '../components/QuarantineGuestsFilter';
import { TABLE_ROWS_DEFAULT } from '../constants';
import {
  checkOutResidences,
  getCheckedInResidences,
} from '../services/httpService';

import QuarantinePassengerList from '../components/QuarantinePassengerList';
import Button from '../components/Button';
import theme from '../theme';

import LoadingIndicator from '../components/LoadingIndicator';
import ConfirmDialog from '../components/ConfirmDialog';
import QuarantinePassengerDetails from '../components/QuarantinePassengerDetails';

const QuarantineResidencePage: React.FC<
  RouteComponentProps<{ locationId: string }>
> = ({ match }) => {
  const { notifyError, notifySuccess } = useNotifier();
  const [lang] = useContext(LangContext);
  const history = useHistory();

  const [filteredPassengers, setFilteredPassengers] = useState<
    ResidencePerson[] | null
  >(null);
  const [allPassengers, setAllPassengers] = useState<ResidencePerson[] | null>(
    null
  );
  const [
    selectedPassenger,
    setSelectedPassenger,
  ] = useState<ResidencePerson | null>(null);
  const [checkedGroupMembers, setCheckedGroupMembers] = useState<
    ResidencePerson[] | null
  >(null);
  const [membersCheckbox, setMembersCheckbox] = useState<number[]>([]);
  const [, setSortedMembers] = useState<ResidencePerson[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [currentPage, setCurrentPage] = useState(0);

  const [loadingPassengers, setLoadingPassengers] = useState(true);
  const [loadingcheckin, setLoadingCheckin] = useState(false);
  const [checkinDialogOpen, setCheckinDialogOpen] = useState(false);

  const tableColumns: {
    [key: string]: {
      translation: { is: string; en: string };
      sort?: (a: ResidencePerson, b: ResidencePerson) => number;
    };
  } = {
    name: {
      translation: translations.name,
      sort: (a: ResidencePerson, b: ResidencePerson) =>
        a.name.localeCompare(b.name),
    },
    ssn: {
      translation: translations.dateOfBirth,
    },

    nationality: {
      translation: translations.nationality,
      sort: (a: ResidencePerson, b: ResidencePerson) =>
        a.nationality.localeCompare(b.nationality),
    },
    arrivalDate: {
      translation: translations.arrivalDate,
      sort: (a: ResidencePerson, b: ResidencePerson) => {
        if (!a.dateOfArrival) {
          return -1;
        }
        if (!b.dateOfArrival) {
          return 1;
        }
        return moment(a.dateOfArrival).isAfter(b.dateOfArrival) ? 1 : -1;
      },
    },
    hotelRoomNumber: {
      translation: translations.hotelRoomNumber,
      sort: (a: ResidencePerson, b: ResidencePerson) =>
        a.checkedInRoomNumber.localeCompare(b.checkedInRoomNumber),
    },
    dischargeDate: {
      translation: translations.estimatedDischargeDate,
      sort: (a: ResidencePerson, b: ResidencePerson) => {
        if (!a.estimatedDischargeDate) {
          return -1;
        }
        if (!b.estimatedDischargeDate) {
          return 1;
        }
        return moment(a.estimatedDischargeDate).isAfter(
          b.estimatedDischargeDate
        )
          ? 1
          : -1;
      },
    },
    comment: {
      translation: translations.comment,
      sort: (a: ResidencePerson, b: ResidencePerson) =>
        a.comment.localeCompare(b.comment),
    },
    food: {
      translation: translations.food,
      sort: (a: ResidencePerson, b: ResidencePerson) =>
        a.food.localeCompare(b.food),
    },
    secondSampling: {
      translation: translations.secondSampling,
      sort: (a: ResidencePerson, b: ResidencePerson) => {
        if (!a.observationStatus) {
          return -1;
        }
        if (!b.observationStatus) {
          return 1;
        }
        return a.observationStatus.id
          .toString()
          .localeCompare(b.observationStatus.id.toString());
      },
    },
  };

  useEffect(() => {
    onSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  return (
    <Layout
      useDefaultSpacing
      title={translations.guests[lang]}
      backButton
      backButtonClick={() => history.push('/quarantine-house')}
    >
      <Section>
        {renderButtons()}

        {allPassengers && allPassengers.length > 0 && (
          <Paper elevation={2} style={{ padding: theme.spacing(2) }}>
            <QuarantineGuestsFilter
              passengers={allPassengers || []}
              filterPassengers={setFilteredPassengers}
            />
          </Paper>
        )}
        {filteredPassengers && !loadingPassengers && !selectedPassenger && (
          <QuarantinePassengerList
            members={filteredPassengers}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={setRowsPerPage}
            onChangeSelectedMembers={onChangeSelectedMembers}
            membersCheckbox={membersCheckbox}
            setMembersCheckbox={setMembersCheckbox}
            onSetSortedMembers={setSortedMembers}
            tableColumns={tableColumns}
            tableColumnsKeys={Object.keys(tableColumns).map(
              (columnKey) => columnKey
            )}
            onClick={setSelectedPassenger}
          />
        )}
        {selectedPassenger && (
          <div style={{ marginTop: theme.spacing(2) }}>
            <QuarantinePassengerDetails
              residencePerson={selectedPassenger}
              reloadSelectedPassenger={reloadSelectedPassenger}
              back={() => setSelectedPassenger(null)}
            />
          </div>
        )}
        {loadingPassengers && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: theme.spacing(2) }}
          >
            <LoadingIndicator />
          </Grid>
        )}
      </Section>
      {renderDialog()}
    </Layout>
  );

  function renderButtons() {
    if (allPassengers && allPassengers.length > 0 && checkedGroupMembers) {
      return (
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}
          style={{ marginBottom: theme.spacing(2) }}
        >
          <Grid item>
            <Button
              padding={false}
              color="primary"
              onClick={() => {
                setCheckinDialogOpen(true);
              }}
            >
              {parseTemplateString(
                translations.checkOutPartialpersonsFromList[lang],
                checkedGroupMembers.length.toString(),
                allPassengers.length.toString()
              )}
            </Button>
          </Grid>
        </Grid>
      );
    }
  }

  function renderDialog() {
    return (
      <ConfirmDialog
        open={checkinDialogOpen}
        title={translations.checkOut[lang]}
        onConfirm={() => onCheckOutPassengers()}
        confirmText={translations.checkOutSubmit[lang]}
        onCancel={() => setCheckinDialogOpen(false)}
        loading={loadingcheckin}
        text={parseTemplateString(
          translations.confirmCheckOutOfPassengers[lang],
          (checkedGroupMembers &&
            checkedGroupMembers[0]?.residenceLocation?.name) ||
            ''
        )}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <List>
            {checkedGroupMembers &&
              checkedGroupMembers.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`#${index + 1} - ${item.name}`} />
                </ListItem>
              ))}
          </List>
        </Grid>
      </ConfirmDialog>
    );
  }

  async function onSearch(barcode?: string) {
    setLoadingPassengers(true);

    const response = await getCheckedInResidences(match.params.locationId);
    if (response.isOk) {
      setLoadingPassengers(false);
      if (response.data) {
        setFilteredPassengers(response.data);
        setAllPassengers(response.data);
        if (barcode) {
          const selectedPassengerNew = response.data.find(
            (item) => item.serialNumber === barcode
          );
          if (selectedPassengerNew) {
            setSelectedPassenger(selectedPassengerNew);
          }
        }
      } else {
        setFilteredPassengers([]);
        setAllPassengers([]);
      }
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
      setLoadingPassengers(true);
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function onCheckOutPassengers() {
    setLoadingCheckin(true);
    const response = await checkOutResidences(
      match.params.locationId,
      membersCheckbox
    );
    if (response.isOk) {
      notifySuccess('success');
      setLoadingCheckin(false);
      setCheckinDialogOpen(false);

      onSearch();
      setCheckedGroupMembers(null);
      setMembersCheckbox([]);
    } else {
      setLoadingCheckin(false);
      if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    }
  }

  function onChangeSelectedMembers(memberlist: ResidencePerson[] | null) {
    setCheckedGroupMembers(memberlist);
  }

  async function reloadSelectedPassenger(barcode: string) {
    await onSearch(barcode);
  }
};
export default QuarantineResidencePage;
