import React from 'react';
import Layout from '../components/Layout';
import Login from '../components/Login';
import useNotifier from '../hooks/useNotifier';

const LoginPage: React.FC = () => {
  const { notifyError } = useNotifier();

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  return (
    <Layout loggedIn={false}>
      <Login />
    </Layout>
  );
};
export default LoginPage;
