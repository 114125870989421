import React, { useContext, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  VaccinationPerson,
  VaccinationGroupPersons,
} from '../models/VaccinationModels';

import useNotifier from '../hooks/useNotifier';
import { LangContext } from '../context/LangContext';
import { UserContext } from '../context/UserContext';
import translations from '../assets/json/translations.json';
import Section from '../components/Section';
import Button from '../components/Button';
import EditVaccinationGroupDialog, {
  IDialogFormValues,
} from '../components/EditVaccinationGroupDialog';
import { Form, Formik, FormikProps } from 'formik';
import Dropdown from './Dropdown';
import { GROUP_COLUMNS, PRIORITY } from '../constants';
import LoadingIndicator from './LoadingIndicator';
import { IVaccinationGroupPersonList } from '../interfaces/vaccinationPerson';
import * as vaccinationService from '../services/vaccinationService';
import { IVaccinationGroupPersons } from '../interfaces/vaccinationGroupPersons';
import ConfirmDialog from './ConfirmDialog';
import { IVaccinationGroupDetails } from '../interfaces/vaccinationGroup';

interface IProps {
  editGroup?: IVaccinationGroupDetails;
  changesOnGroup?: (value: boolean) => void;
}

export interface IFormValues {
  groupName: string;
  description: string;
  priority: string;
  region: string;
}

type IFormik = FormikProps<IFormValues>;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    maxHeight: 400,
    minHeight: 400,
    '& th': {
      fontWeight: 600,
      fontSize: 15,
      backgroundColor: '#f5f5f5',
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  checkboxContainer: {
    marginTop: theme.spacing(1),
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  cancelIcon: {
    color: theme.palette.error.main,
  },
  warningIcon: {
    color: theme.palette.secondary.main,
  },
  loadingContainer: {
    textAlign: 'center',
    margin: 30,
  },
  registerPersonConntainer: {
    textAlign: 'right',
    marginTop: theme.spacing(2),
  },
}));

const CreateVaccinationGroup: React.FC<IProps> = ({
  editGroup,
  changesOnGroup,
}) => {
  const { notifyError, notifySuccess } = useNotifier();
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const idInput = useRef<HTMLInputElement>(null);
  const classes = useStyles({});
  const [tableRows, setTableRows] = useState<null | VaccinationPerson[]>(null);
  const [selectedRow, setSelectedRow] = useState<null | VaccinationPerson>(
    null
  );
  const [rowIndex, setRowIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSsnListDialog, setOpenSsnListDialog] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [clearTableDialog, setClearTableDialog] = useState(false);
  const [ssnInput, setSsnInput] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  const regions = user?.vaccinationRegionModelList
    ? user?.vaccinationRegionModelList
    : [];

  useEffect(() => {
    focusIdInput();
  }, []);

  useEffect(() => {
    if (editGroup && editGroup.members) {
      setTableRows([...editGroup.members]);
    }
  }, [editGroup]);

  const schema = Yup.object().shape({
    groupName: Yup.string().required(),
    priority: Yup.string().required(),
    region: Yup.string().required(),
  });

  return (
    <Section>
      <Formik
        onSubmit={(values) => submitForm(values)}
        validationSchema={schema}
        initialValues={{
          groupName: editGroup?.name || '',
          description: editGroup?.description || '',
          priority: editGroup?.priority.toString() || '',
          region:
            editGroup?.vaccinationRegion.id ||
            (regions.length === 1 ? regions[0].id : ''),
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Paper elevation={2} className={classes.paper}>
              <Grid container spacing={2}>
                {renderFormFields(formik)}
                {!tableRows && loading && renderLoading()}
                {renderTable()}
                {renderFormButtons(formik)}
              </Grid>
            </Paper>
          </Form>
        )}
      </Formik>

      {renderDialogs()}
    </Section>
  );

  function renderFormFields({
    values,
    errors,
    submitCount,
    handleChange,
    handleBlur,
  }: IFormik) {
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            autoFocus
            autoComplete="off"
            id="groupName"
            label={translations.groupName[lang]}
            placeholder={translations.nameMustBeDescriptive[lang]}
            variant="outlined"
            inputProps={{ ref: idInput }}
            value={values.groupName}
            error={!!errors.groupName && !!submitCount}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            autoFocus
            autoComplete="off"
            id="description"
            label={translations.description[lang]}
            variant="outlined"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            fullWidth
            required
            id="priority"
            value={values.priority}
            label={translations.priority[lang]}
            error={!!errors.priority && !!submitCount}
            items={PRIORITY.map((s) => ({
              label: `${s.key}: ${s.value}`,
              value: s.key,
            }))}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            fullWidth
            id="region"
            required
            value={values.region}
            label={translations.region[lang]}
            items={regions.map((r: any) => ({
              label: r.description || '',
              value: r.id || '',
            }))}
            onChange={handleChange}
            disabled={regions.length === 1}
          />
        </Grid>
      </React.Fragment>
    );
  }

  function renderFormButtons(formik: IFormik) {
    return (
      <React.Fragment>
        <Grid item xs={12} md={2} sm={4}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setClearTableDialog(true)}
          >
            {translations.clear[lang]}
          </Button>
        </Grid>
        <Grid item xs={12} md={2} sm={4}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => setOpenSsnListDialog(true)}
          >
            {translations.pastFromExcelButton[lang]}
          </Button>
        </Grid>
        <Grid item xs={12} md={3} sm={4}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => onOpenDialog(null, -1)}
          >
            {translations.addPerson[lang]}
          </Button>
        </Grid>
        <Grid item xs={12} md={5} sm={12}>
          {editGroup ? (
            <Button
              variant="contained"
              fullWidth
              disabled={showSubmitButton(formik)}
              type="submit"
            >
              {translations.updateGroup[lang]}
            </Button>
          ) : (
            <Button
              variant="contained"
              fullWidth
              disabled={
                !(
                  tableRows &&
                  tableRows.length > 0 &&
                  !submitting &&
                  tableRows.filter((a) => !a.verified).length === 0 &&
                  tableRows.filter((a) => !a.vaccinationAge).length === 0
                )
              }
              type="submit"
            >
              {`${translations.confirm[lang]} ${
                tableRows && tableRows?.length > 1
                  ? '(' + tableRows.length + ') ' + translations.rows[lang]
                  : ''
              }`}
            </Button>
          )}
        </Grid>
      </React.Fragment>
    );
  }

  function renderTable() {
    return (
      <Grid item xs={12}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {GROUP_COLUMNS.map((column) => (
                  <TableCell
                    key={column.key}
                    style={{
                      textAlign: column.key === 'verified' ? 'center' : 'left',
                    }}
                  >
                    {translations[column.translationKey][lang]}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows &&
                tableRows.map((row: any, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      onClick={() =>
                        onOpenDialog(row as VaccinationPerson, index)
                      }
                    >
                      {GROUP_COLUMNS.map((column) => {
                        const value = row[column.key];
                        if (column.key === 'verified') {
                          return (
                            <TableCell
                              key={column.key}
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              {value && row.vaccinationAge ? (
                                <CheckCircleIcon
                                  className={classes.checkIcon}
                                />
                              ) : value && !row.vaccinationAge ? (
                                <ErrorIcon
                                  className={classes.warningIcon}
                                ></ErrorIcon>
                              ) : (
                                <CancelIcon
                                  className={classes.cancelIcon}
                                ></CancelIcon>
                              )}
                            </TableCell>
                          );
                        } else if (column.key === 'name') {
                          return (
                            <TableCell key={column.key}>{value}</TableCell>
                          );
                        }
                        return <TableCell key={column.key}>{value}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  }

  function renderLoading() {
    return (
      <Grid item xs={12} className={classes.loadingContainer}>
        <LoadingIndicator></LoadingIndicator>
      </Grid>
    );
  }

  function submitForm(values: IFormValues) {
    if (tableRows) {
      setSubmitting(true);
      const group: IVaccinationGroupPersons = {
        group: {
          priority: parseInt(values.priority),
          name: values.groupName,
          vaccinationRegion: parseInt(values.region),
          description: values.description,
        },
        persons: tableRows.map((row) => new VaccinationGroupPersons(row)),
      };
      if (editGroup) {
        onEditGroup(group, parseInt(editGroup.id));
      } else {
        createGroup(group);
      }
    }
  }

  function createGroup(group: IVaccinationGroupPersons) {
    vaccinationService
      .addGroupWithPersons(group)
      .then((response) => {
        setSubmitting(false);
        if (response.isOk) {
          notifySuccess(translations.groupCreated[lang]);
          history.push('/groups');
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          notifyError(translations.errorOccurred[lang]);
        }
      })
      .catch(() => {
        setSubmitting(false);
        notifyError(translations.errorOccurred[lang]);
      });
  }

  function onEditGroup(group: IVaccinationGroupPersons, groupId: number) {
    vaccinationService
      .updateGroup(groupId, group)
      .then((response) => {
        setSubmitting(false);
        if (response.isOk) {
          notifySuccess(translations.groupUpdated[lang]);
          history.push(`/groups/${groupId}`);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          notifyError(translations.errorOccurred[lang]);
        }
      })
      .catch(() => {
        setSubmitting(false);
        notifyError(translations.errorOccurred[lang]);
      });
  }

  function submitSsnList(value: string) {
    setLoading(true);
    const lines = value.split('\n');
    const splitLines: IVaccinationGroupPersonList[] = lines.map((line) => {
      let splitLine = line.split('\t');
      if (splitLine[0]) {
        splitLine[0] = splitLine[0].trim().replace(/-/g, '');
      }
      if (splitLine[1]) {
        splitLine[1] = splitLine[1].trim().replace(/[^0-9]/g, '');
      }
      if (
        splitLine.length > 0 &&
        splitLine[0].length === 9 &&
        (splitLine[0][8] === '0' || splitLine[0][8] === '9')
      ) {
        splitLine[0] = `0${splitLine[0]}`;
      }

      return { ssn: splitLine[0], phone: splitLine[1] || '' };
    });

    vaccinationService
      .lookupVaccinationPersons(splitLines)
      .then((response) => {
        if (response.isOk && response.data) {
          let rows = response.data;
          if (tableRows) {
            rows = [...response.data, ...tableRows];
          }
          const sortedVerifiedRows = rows.sort(sortVerifiedPersons);
          const sortedRows = [...sortedVerifiedRows].sort(
            sortVaccinatinAgePersons
          );

          removeDuplicates(sortedRows);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          setLoading(false);
          notifyError(translations.errorOccurred[lang]);
        }
      })
      .catch(() => {
        notifyError(translations.errorOccurred[lang]);
        setLoading(false);
      });
  }

  function removeDuplicates(rows: VaccinationPerson[]) {
    const persons = rows.map((row) => new VaccinationGroupPersons(row));
    vaccinationService
      .removeDuplicatesVaccinationPersons(persons)
      .then((response) => {
        setLoading(false);
        if (response.isOk && response.data) {
          notifySuccess(translations.dataReadIn[lang]);
          closeSsnListDialog();
          setTableRows(response.data);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          notifyError(translations.errorOccurred[lang]);
        }
      })
      .catch(() => {
        notifyError(translations.errorOccurred[lang]);
        setLoading(false);
      });
  }

  function submitDialogForm(values: IDialogFormValues) {
    setDialogLoading(true);
    const newValue = [
      {
        ssn: values.ssn,
        phone: values.phoneNumber.trim().replace(/[^0-9]/g, ''),
      },
    ];
    vaccinationService
      .lookupVaccinationPersons(newValue)
      .then((response) => {
        setLoading(false);
        if (
          response.isOk &&
          tableRows &&
          response.data &&
          response.data[0].verified
        ) {
          const rows = tableRows;
          if (rowIndex >= 0) {
            rows[rowIndex] = response.data[0];
          } else {
            rows.unshift(response.data[0]);
          }
          const sortedVerifiedRows = rows.sort(sortVerifiedPersons);
          const sortedRows = [...sortedVerifiedRows].sort(
            sortVaccinatinAgePersons
          );
          setTableRows(sortedRows);
          setOpenDialog(false);
          notifySuccess(translations.rowChanged[lang]);
          setDialogLoading(false);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          if (
            response.isOk &&
            !tableRows &&
            response.data &&
            response.data[0].verified
          ) {
            setTableRows(response.data);
            setOpenDialog(false);
            notifySuccess(translations.rowChanged[lang]);
            setDialogLoading(false);
          } else {
            notifyError(translations.errorOccurred[lang]);
            setDialogLoading(false);
          }
        }
      })
      .catch(() => {
        notifyError(translations.errorOccurred[lang]);
        setDialogLoading(false);
      });
  }

  function duplicateSsn(ssn: string) {
    if (tableRows) {
      const duplicate = tableRows.find((row) => row.ssn === ssn);
      if (duplicate) {
        return true;
      }

      return false;
    } else {
      return false;
    }
  }

  function showSubmitButton(formik: IFormik) {
    if (
      tableRows &&
      !submitting &&
      tableRows.filter((a) => !a.verified).length === 0 &&
      tableRows.filter((a) => !a.vaccinationAge).length === 0 &&
      (formik.dirty ||
        (editGroup &&
          JSON.stringify(tableRows) !== JSON.stringify(editGroup.members)))
    ) {
      changesOnGroup && changesOnGroup(false);
      return false;
    }
    changesOnGroup && changesOnGroup(true);
    return true;
  }

  function removePerson() {
    const rows = tableRows;
    rows?.splice(rowIndex, 1);
    setTableRows(rows);
    setOpenDialog(false);
  }

  function clearTable() {
    setTableRows(null);
    setClearTableDialog(false);
  }

  function onOpenDialog(row: VaccinationPerson | null, index: number) {
    setRowIndex(index);
    setSelectedRow(row);
    setOpenDialog(true);
  }

  function closeSsnListDialog() {
    setOpenSsnListDialog(false);
    setSsnInput('');
  }

  function renderDialogs() {
    return (
      <React.Fragment>
        <EditVaccinationGroupDialog
          open={openDialog}
          onCancel={() => setOpenDialog(false)}
          onSubmit={submitDialogForm}
          row={selectedRow}
          loading={dialogLoading}
          removeRow={removePerson}
          duplicateSsn={duplicateSsn}
        ></EditVaccinationGroupDialog>
        <ConfirmDialog
          open={openSsnListDialog}
          title={translations.pasteFromExcelDocument[lang]}
          onConfirm={() => submitSsnList(ssnInput)}
          onCancel={() => closeSsnListDialog()}
          confirmText={translations.pasteIn[lang]}
          loading={loading}
        >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                autoFocus
                multiline
                rows={10}
                autoComplete="off"
                id="ssnList"
                label={translations.listOfSsn[lang]}
                variant="outlined"
                placeholder={translations.listOfSsnFromExcel[lang]}
                value={ssnInput}
                onChange={(e) => setSsnInput(e.target.value)}
              />
            </Grid>
          </Grid>
        </ConfirmDialog>
        <ConfirmDialog
          open={clearTableDialog}
          title={translations.clearTable[lang]}
          text={translations.clearTableText[lang]}
          onConfirm={() => clearTable()}
          onCancel={() => setClearTableDialog(false)}
          confirmText={translations.clearTable[lang]}
        />
      </React.Fragment>
    );
  }

  function focusIdInput() {
    if (idInput && idInput.current) {
      idInput.current.focus();
    }
  }

  function sortVerifiedPersons(a: VaccinationPerson, b: VaccinationPerson) {
    return a.verified === b.verified ? 0 : a.verified ? 1 : -1;
  }

  function sortVaccinatinAgePersons(
    a: VaccinationPerson,
    b: VaccinationPerson
  ) {
    return a.vaccinationAge === b.vaccinationAge
      ? 0
      : a.vaccinationAge
      ? 1
      : -1;
  }
};

export default CreateVaccinationGroup;
