import React, { useContext, useState, useEffect } from 'react';
import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Paper,
  TextField,
  Button as MuiButton,
  ButtonGroup,
} from '@material-ui/core';
import { Formik, Form, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import * as httpService from '../services/httpService';

import Button from './Button';
import Autocomplete, { IOption } from './Autocomplete';
import { UserContext } from '../context/UserContext';
import { LangContext } from '../context/LangContext';
import LoadingIndicator from './LoadingIndicator';
import translations from '../assets/json/translations.json';
import * as version from '../version.json';
import useNotifier from '../hooks/useNotifier';
import { Location } from '../models/Models';
import { getLocationLabel, clearLocalStorage } from '../utils';
import * as localStorageKeys from '../constants/localStorageKeys';

export interface IFormValues {
  phoneNumber: string;
  samplingLocation: IOption | null;
}

type IFormik = FormikProps<IFormValues>;
type ILoginType = 'sampling' | 'vaccination';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: `calc(100vh - ${theme.dimensions.navbarHeight}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      flex: 1,
      padding: theme.spacing(3),
      margin: theme.spacing(3),
      textAlign: 'center',
      maxWidth: theme.dimensions.boxWidth,
    },
    typographyTitle: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
      },
    },
    tabs: {
      width: '100%',
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    typographyError: {
      color: theme.palette.error.main,
    },
  })
);

const samplingSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(/^[0-9]{7}$/)
    .required(),
  samplingLocation: Yup.object().required(),
});

const vaccinationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(/^[0-9]{7}$/)
    .required(),
});

const Login: React.FC = () => {
  const [, setUserContext] = useContext(UserContext);
  const [lang] = useContext(LangContext);
  const classes = useStyles();
  const history = useHistory();
  const { notifyError } = useNotifier();

  const [loginType, setLoginType] = useState<ILoginType>('sampling');
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [initialSamplingLocation, setInitialSamplingLocation] =
    useState<IOption | null>(null);
  const [samplingLocationOptions, setSamplingLocationOptions] = useState<
    Location[] | null
  >(null);

  const isSamplingLogin = loginType === 'sampling';

  useEffect(() => {
    const getInitialValue = (
      savedValue: string | null,
      options: Location[]
    ) => {
      if (savedValue) {
        const savedOption = options.find((opt) => opt.key === savedValue);
        if (savedOption) {
          return { label: savedOption.label, value: savedOption.key };
        }
      }
      return null;
    };

    const loadLoginInfo = async () => {
      const { data: samplingLocations } = await httpService.getLoginLocations();
      const savedLoginType = localStorage.getItem(localStorageKeys.LOGIN_TYPE);
      const savedSamplingLocation = localStorage.getItem(
        localStorageKeys.LOCATION
      );

      setLoginType(
        savedLoginType === 'vaccination' ? 'vaccination' : 'sampling'
      );
      setSamplingLocationOptions(samplingLocations);
      setInitialSamplingLocation(
        getInitialValue(savedSamplingLocation, samplingLocations)
      );
      setLoadingInfo(false);
    };
    loadLoginInfo();
  }, []);

  /* useEffect(() => {
    const savedLoacation = localStorage.getItem(localStorageKeys.LOCATION);
    if (savedLoacation && initialSamplingLocation !== savedLoacation) {
      setInitialSamplingLocation(savedLoacation);
    }
  }, [initialSamplingLocation]); */

  if (loadingInfo) {
    return (
      <div className={classes.root}>
        <LoadingIndicator />
      </div>
    );
  }

  if (loading) {
    return (
      <div className={classes.root}>
        <Grid container spacing={2} className={classes.paper}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h4">
              {translations.authenticating[lang]}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <LoadingIndicator />
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Formik
        enableReinitialize
        initialValues={{
          phoneNumber: '',
          samplingLocation: initialSamplingLocation,
        }}
        onSubmit={isSamplingLogin ? samplingLogin : vaccinationLogin}
        validationSchema={isSamplingLogin ? samplingSchema : vaccinationSchema}
      >
        {renderLoginForm}
      </Formik>
    </div>
  );

  function renderLoginForm(formik: IFormik) {
    const {
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      submitCount,
    } = formik;

    return (
      <Form onSubmit={handleSubmit} style={{ margin: 'auto 0' }}>
        <Paper elevation={2} className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.typographyTitle} variant="h4">
                {translations.loginWithCertificates[lang]}
              </Typography>
            </Grid>
            <ButtonGroup
              disableElevation
              color="primary"
              className={classes.tabs}
            >
              <MuiButton
                style={{ flex: 1 }}
                variant={isSamplingLogin ? 'contained' : 'outlined'}
                onClick={() => setLoginType('sampling')}
              >
                {translations.sampling[lang]}
              </MuiButton>
              <MuiButton
                style={{ flex: 1 }}
                variant={isSamplingLogin ? 'outlined' : 'contained'}
                onClick={() => setLoginType('vaccination')}
              >
                {translations.vaccination[lang]}
              </MuiButton>
            </ButtonGroup>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                autoFocus
                autoComplete="off"
                id="phoneNumber"
                label={translations.phoneNumber[lang]}
                variant="outlined"
                inputProps={{
                  type: 'text',
                  pattern: '[0-9]*',
                  maxLength: '7',
                }}
                value={values.phoneNumber}
                onChange={(e) => e.target.validity.valid && handleChange(e)}
                onBlur={handleBlur}
                error={!!errors.phoneNumber && !!submitCount}
              />
            </Grid>
            {isSamplingLogin ? renderSamplingFields(formik) : null}
            <Grid item xs={12} sm={12}>
              <Button fullWidth type="submit" disabled={isSubmitting}>
                {translations.logIn[lang]}
              </Button>
            </Grid>
            {showError && (
              <Grid item xs={12} sm={12}>
                <Typography variant="body1" className={classes.typographyError}>
                  {translations.loginFailed[lang]}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Typography
            style={{
              textAlign: 'end',
              paddingTop: '10px',
              fontSize: '0.8rem',
            }}
            color="textSecondary"
          >
            Version {`${version.major}.${version.minor}`}
          </Typography>
        </Paper>
      </Form>
    );
  }

  function renderSamplingFields({
    values,
    handleChange,
    errors,
    submitCount,
    setFieldValue,
  }: IFormik) {
    return (
      <Grid item xs={12} sm={12} style={{ textAlign: 'left' }}>
        <Autocomplete
          fullWidth
          id="samplingLocation"
          value={values.samplingLocation}
          label={
            samplingLocationOptions
              ? translations.location[lang]
              : translations.loadingLocations[lang]
          }
          items={(samplingLocationOptions || []).map((l) => ({
            value: l.key,
            label: l.label,
          }))}
          onChange={(val) => setFieldValue('samplingLocation', val)}
          error={!!errors.samplingLocation && !!submitCount}
          disabled={!samplingLocationOptions}
        />
      </Grid>
    );
  }

  function samplingLogin(values: IFormValues) {
    if (values.samplingLocation?.value) {
      setLoading(true);
      setSamplingLocationOnSubmit(values.samplingLocation.value);

      httpService
        .login(values.phoneNumber, values.samplingLocation.value, 'sampling')
        .then((user: any) => {
          if (
            user.isOk &&
            (user.data.hasBorderControlAccess ||
              user.data.hasResidenceManagementAccess)
          ) {
            setShowError(false);
            const extendedUser = {
              ...user.data,
              loginType: 'sampling',
              locationLabel: getLocationLabel(
                user.data.location,
                samplingLocationOptions || []
              ),
            };
            setUserContext(extendedUser);
            localStorage.setItem(
              localStorageKeys.USER,
              JSON.stringify(extendedUser)
            );
            localStorage.setItem(
              localStorageKeys.LOCATION_OPTIONS,
              JSON.stringify(samplingLocationOptions)
            );
            localStorage.setItem(localStorageKeys.LOGIN_TYPE, 'sampling');
            history.push('/home');
          } else {
            if (user.data.errorCode === 403) {
              notifyError(translations.noAccessToSamplingLocation[lang]);
            } else {
              notifyError(
                user.data.errorMessage || translations.noSamplingAccess[lang]
              );
            }
            setShowError(true);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }

  function vaccinationLogin(values: IFormValues) {
    setLoading(true);
    httpService
      .login(values.phoneNumber, 'REY', 'vaccination')
      .then((user: any) => {
        if (user.isOk && user.data.hasVaccinationAccess) {
          setShowError(false);
          const extendedUser = {
            ...user.data,
            loginType: 'vaccination',
          };
          setUserContext(extendedUser);
          localStorage.setItem(
            localStorageKeys.USER,
            JSON.stringify(extendedUser)
          );
          localStorage.setItem(localStorageKeys.LOGIN_TYPE, 'vaccination');
          clearLocalStorage();
          history.push('/home');
        } else {
          notifyError(
            user.data.errorMessage || translations.noVaccinationAccess[lang]
          );
          setShowError(true);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  function setSamplingLocationOnSubmit(location: string) {
    const getInitialValue = (
      savedValue: string | null,
      options: Location[]
    ) => {
      if (savedValue) {
        const savedOption = options.find((opt) => opt.key === savedValue);
        if (savedOption) {
          return { label: savedOption.label, value: savedOption.key };
        }
      }
      return null;
    };
    if (samplingLocationOptions) {
      localStorage.setItem(localStorageKeys.LOCATION, location);
      setInitialSamplingLocation(
        getInitialValue(location, samplingLocationOptions)
      );
    }
  }
};

export default Login;
