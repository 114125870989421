import api from '../api';
import { IHttpAnswer } from '../interfaces/httpAnswer';
import { IVaccinate } from '../interfaces/vaccinate';
import { IInfluenzaVaccinationUpdate } from '../interfaces/vaccinationUpdate';

const _controller = 'Influenza';

export async function vaccinate(
  vaccinationInfo: IVaccinate
): Promise<IHttpAnswer<null>> {
  const response = await api.post(
    `/${_controller}/CreateInfluenzaVaccination`,
    vaccinationInfo
  );

  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

export async function updateVaccination(
  vaccinationInfo: IInfluenzaVaccinationUpdate,
  id: number
): Promise<IHttpAnswer<null>> {
  const response = await api.put(
    `/${_controller}/UpdateInfluenzaVaccination/${id}`,
    vaccinationInfo
  );

  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

export async function cancelVaccination(
  id: number
): Promise<IHttpAnswer<null>> {
  const response = await api.put(
    `/${_controller}/CancelInfluenzaVaccination/${id}`,
    {}
  );

  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}
