import React, { useContext, useEffect, useState } from 'react';
import { Grid, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Button from '../components/Button';
import LoadingIndicator from '../components/LoadingIndicator';

import useNotifier from '../hooks/useNotifier';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { TABLE_ROWS_DEFAULT } from '../constants';

import * as vaccinationService from '../services/vaccinationService';

import { VaccinationRegistrationRequest } from '../models/VaccinationModels';

import VaccinationWatingListFilter from '../components/VaccinationWatingListFilter';
import VaccinationWatingList from '../components/VaccinationWatingList';
import VaccinationWaitingListDetails from '../components/VaccinationWaitingListDetails';

// import moment from 'moment';

import * as localStorageKeys from '../constants/localStorageKeys';
import { UserContext } from '../context/UserContext';

import WaitingListGroupDialog from '../components/WaitingListGroupDialog';
import { IUpdateRegistrationForVaccination } from '../interfaces/RegisterForVaccination';

const VaccinationWaitingListPage: React.FC = () => {
  const { notifyError, notifySuccess } = useNotifier();
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const history = useHistory();

  const theme = useTheme();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);

  /*  const fromDate = moment().subtract(12, 'months');
  const toDate = moment(); */

  const [membersCheckbox, setMembersCheckbox] = useState<number[]>([]);
  const [checkedGroupMembers, setCheckedGroupMembers] = useState<
    VaccinationRegistrationRequest[] | null
  >(null);
  const [list, setList] = useState<VaccinationRegistrationRequest[] | null>(
    null
  );
  const [filteredList, setFilteredList] = useState<
    VaccinationRegistrationRequest[]
  >([]);
  const [regionSelected, setRegionSelected] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [groupDialoOpen, setGroupDialogOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] =
    useState<VaccinationRegistrationRequest | null>(null);

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  const fetchVaccineWatingList = async () => {
    const values = {
      regionId:
        user.vaccinationRegionModelList.length === 1
          ? user.vaccinationRegionModelList[0].id
          : undefined,
      //fromDate: fromDate.toISOString(),
      //toDate: toDate.toISOString(),
      registrationStatus: 0,
    };
    const response =
      await vaccinationService.getVaccinationRegistrationRequests(values);
    if (response.isOk) {
      setList(response.data);
    } else {
      setList([]);
    }
  };

  async function fetchVaccinationRegistrationRequest(id: number) {
    const res = await vaccinationService.getVaccinationRegistrationRequest(id);
    const person = res.data;
    if (person) {
      const newList = list?.filter((x) => x.id !== person?.id);
      newList?.push(person as VaccinationRegistrationRequest);
      setList(newList as VaccinationRegistrationRequest[]);
    }
  }

  function setSelectedPersonById(id: number) {
    const person = list?.find((x) => x.id === id);
    if (person) {
      setSelectedPerson(person);
    }
  }

  useEffect(() => {
    if (selectedPerson) {
      setSelectedPersonById(selectedPerson.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    fetchVaccineWatingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, user]);

  return (
    <Layout useDefaultSpacing title={translations.registerForVaccine[lang]}>
      <Section>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
          style={{ marginBottom: theme.spacing(2) }}
        >
          <Grid item>
            <Button
              padding={false}
              onClick={() => history.push('/register-for-vaccine')}
            >
              {translations.registerToWaitingList[lang]}
            </Button>
          </Grid>
          <Grid item>
            <Button
              padding={false}
              disabled={membersCheckbox.length === 0}
              onClick={() => setGroupDialogOpen(true)}
              color="secondary"
            >
              {getAddToGroupText()}
            </Button>
          </Grid>
        </Grid>
      </Section>
      <Section>{renderContent()}</Section>
      {renderDialogs()}
    </Layout>
  );

  function renderContent() {
    if (list === null) {
      return (
        <Grid container justify="center" alignItems="center">
          <LoadingIndicator />
        </Grid>
      );
    }

    if (selectedPerson) {
      return (
        <VaccinationWaitingListDetails
          selectedPerson={selectedPerson}
          back={async () => {
            setSelectedPerson(null);
            // refresh list
            await fetchVaccineWatingList();
          }}
          updateVaccinationRegistrationRequest={
            updateVaccinationRegistrationRequest
          }
          vaccinationRegistrationRequestDeleted={(id) => {
            const checkboxList = membersCheckbox.filter((x) => x !== id);
            setMembersCheckbox(checkboxList);
          }}
        />
      );
    }

    return (
      <React.Fragment>
        <VaccinationWatingListFilter
          list={list}
          localStorageKey={localStorageKeys.GROUP_VACCINATION_FILTER_KEY}
          filterList={setFilteredList}
          setRegionSelected={onChangeFilterRegion}
          onChangeRegion={onChangeRegion}
        />
        <VaccinationWatingList
          list={filteredList}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          regionSelected={regionSelected}
          setCurrentPage={setCurrentPage}
          setRowsPerPage={setRowsPerPage}
          membersCheckbox={membersCheckbox}
          setMembersCheckbox={setMembersCheckbox}
          onChangeSelectedMembers={onChangeSelectedMembers}
          onClick={(item) => setSelectedPersonById(item.id)}
        />
      </React.Fragment>
    );
  }

  function renderDialogs() {
    return (
      <>
        <WaitingListGroupDialog
          open={groupDialoOpen}
          onConfirm={addPersonsToGroup}
          onCancel={() => setGroupDialogOpen(false)}
          selectedFilterRegion={regionSelected ? selectedRegion : undefined}
        />
      </>
    );
  }

  function onChangeSelectedMembers(
    memberlist: VaccinationRegistrationRequest[] | null
  ) {
    setCheckedGroupMembers(memberlist);
  }

  function onChangeFilterRegion(onlyOne: boolean, location: string) {
    setRegionSelected(onlyOne);
    setSelectedRegion(location);
  }

  function getAddToGroupText() {
    const text = translations.moveFromWaitingListToGroup[lang];
    if (membersCheckbox.length > 0 && filteredList && filteredList.length > 0) {
      return `${text} (${membersCheckbox.length}/${filteredList.length})`;
    }

    return text;
  }

  function onChangeRegion() {
    setMembersCheckbox([]);
  }

  async function addPersonsToGroup(
    isExistingGroup: boolean,
    selectedGroup: string,
    groupName: string,
    groupDescription: string,
    regionId: string
  ) {
    if (checkedGroupMembers) {
      const persons = checkedGroupMembers?.map((item) => {
        return {
          personSsn: item.ssn,
          personName: item.personName,
          phonenumber: '',
          isValid: true,
          errorMessage: '',
        };
      });
      if (isExistingGroup) {
        addPersonsToExistingGroup(selectedGroup, persons);
      } else {
        const group = {
          priority: 10,
          name: groupName,
          vaccinationRegion: parseInt(regionId),
          description: groupDescription,
        };
        addPersonsToNewGroup(persons, group);
      }
    }
  }

  async function addPersonsToExistingGroup(
    selectedGroup: string,
    persons: any
  ) {
    const response = await vaccinationService.addPersonsToGroup(
      parseInt(selectedGroup),
      persons
    );
    if (response.isOk) {
      notifySuccess(translations.personsMovededToGroup[lang]);
      // refresh list
      setMembersCheckbox([]);
      await fetchVaccineWatingList();
      setGroupDialogOpen(false);
    } else if (response.statusCode === 401) {
      history.push('/login');
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function addPersonsToNewGroup(persons: any, group: any) {
    const response = await vaccinationService.addGroupWithPersons({
      group,
      persons,
    });
    if (response.isOk) {
      notifySuccess(translations.personsMovededToGroup[lang]);
      // refresh list
      setMembersCheckbox([]);
      await fetchVaccineWatingList();
      setGroupDialogOpen(false);
    } else if (response.statusCode === 401) {
      history.push('/login');
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }

  async function updateVaccinationRegistrationRequest(
    info: IUpdateRegistrationForVaccination
  ) {
    const response =
      await vaccinationService.updateVaccinationRegistrationRequest(info);
    if (response.isOk) {
      await fetchVaccinationRegistrationRequest(info.id);
      notifySuccess(translations.editPersonSuccess[lang]);
    } else if (response.statusCode === 401) {
      history.push('/login');
    } else {
      notifyError(translations.errorOccurred[lang]);
    }
  }
};

export default VaccinationWaitingListPage;
