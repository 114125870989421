import React from 'react';
import { TextField } from '@material-ui/core';
import {
  Autocomplete as MaterialUIAutocomplete,
  AutocompleteRenderInputParams,
} from '@material-ui/lab';

import theme from '../theme';

interface IProps {
  id?: string;
  label: string;
  value: ICountryItem | null;
  items: ICountryItem[];
  onChange: (event: React.ChangeEvent<{}>, value: ICountryItem | null) => void;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  className?: string;
  inputValue?: string;
  checkBox?: boolean;
}

export interface ICountryItem {
  name: string;
  code: string;
  status: number;
  flag: string;
}

const AutocompleteFlag: React.FC<IProps> = ({
  id,
  value,
  label,
  items,
  onChange,
  error,
  disabled,
  required,
  fullWidth,
  className,
}) => {
  return (
    <MaterialUIAutocomplete
      id={id}
      style={fullWidth ? { width: '100%' } : {}}
      value={value}
      options={items}
      disabled={disabled}
      className={className}
      onChange={(event, val) => onChange(event, val)}
      getOptionLabel={(opt) => opt.name}
      getOptionSelected={(opt, val) => val.code === opt.code}
      renderOption={(option) => (
        <React.Fragment>
          <img
            loading="lazy"
            width="20"
            style={{ marginRight: theme.spacing(1) }}
            src={require(`../assets/flags/${option.flag.toLowerCase()}.svg`)}
            alt=""
          />
          {option.name}
        </React.Fragment>
      )}
      renderInput={renderInput}
    />
  );

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <TextField
        {...params}
        variant="outlined"
        label={`${label}${required ? '*' : ''}`}
        error={error}
        disabled={disabled}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'off',
          form: {
            autoComplete: 'off',
          },
        }}
      />
    );
  }
};

export default AutocompleteFlag;
