import React, { useContext } from 'react';
import { Grid, TextField, Typography, useTheme } from '@material-ui/core';
import * as Yup from 'yup';

import ConfirmDialog from './ConfirmDialog';
import Dropdown from './Dropdown';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import {
  INationality,
  ISendCertificateValues,
} from '../interfaces/vaccinationSearch';
import { Formik, FormikProps } from 'formik';
import { VaccinationPerson } from '../models/VaccinationModels';

interface IProps {
  open: boolean;
  vaccinationPerson: VaccinationPerson;
  nationalities: INationality[] | null;
  error: boolean;
  onSubmit: (values: ISendCertificateValues) => Promise<void>;
  onCancel: () => void;
  nameFromRegistry: string;
}

type IFormik = FormikProps<ISendCertificateValues>;

const schema = Yup.object().shape({
  ssn: Yup.string().required(),
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  nationality: Yup.string().required(),
  passportNumber: Yup.string(),
});

const SendVaccinationCertificateDialog: React.FC<IProps> = ({
  open,
  vaccinationPerson,
  nationalities,
  error,
  onSubmit,
  onCancel,
  nameFromRegistry,
}) => {
  const [lang] = useContext(LangContext);
  const theme = useTheme();

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={getInitalValues()}
      validationSchema={schema}
      validateOnMount
      enableReinitialize
    >
      {(formik) => (
        <ConfirmDialog
          open={open}
          loading={formik.isSubmitting}
          title={translations.sendCertificate[lang]}
          confirmText={translations.send[lang]}
          disableConfirm={!formik.isValid}
          onConfirm={formik.handleSubmit}
          onCancel={onCancel}
        >
          {renderForm(formik)}
          {renderError()}
        </ConfirmDialog>
      )}
    </Formik>
  );

  function renderForm(formik: IFormik) {
    const { values, isSubmitting, handleChange } = formik;

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="name"
            autoComplete="off"
            variant="outlined"
            label={translations.name[lang]}
            value={values.name}
            onChange={handleChange}
            disabled={isSubmitting || !!vaccinationPerson.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled
            fullWidth
            id="nameFromRegistry"
            autoComplete="off"
            variant="outlined"
            label={translations.nameFromRegistry[lang]}
            value={nameFromRegistry}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="ssn"
            autoComplete="off"
            variant="outlined"
            label={translations.SSN[lang]}
            value={values.ssn}
            onChange={handleChange}
            disabled={isSubmitting || !!vaccinationPerson.ssn}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            autoFocus
            id="email"
            autoComplete="off"
            variant="outlined"
            label={translations.email[lang]}
            value={values.email}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={12}>
          <Dropdown
            fullWidth
            required
            id="nationality"
            value={values.nationality}
            label={
              nationalities === null
                ? translations.loadingCountries[lang]
                : translations.nationality[lang]
            }
            items={nationalities || []}
            onChange={handleChange}
            disabled={isSubmitting || nationalities === null}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="passportNumber"
            autoComplete="off"
            variant="outlined"
            label={translations.passportNumber[lang]}
            value={values.passportNumber}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </Grid>
      </Grid>
    );
  }

  function renderError() {
    if (!error) {
      return null;
    }

    return (
      <Typography
        variant="body2"
        style={{
          color: theme.palette.error.main,
          marginTop: theme.spacing(2),
        }}
      >
        {translations.sendVaccinationCertificateError[lang]}
      </Typography>
    );
  }

  function getInitalValues() {
    return {
      ssn: vaccinationPerson.ssn,
      name: vaccinationPerson.name,
      email: '',
      nationality: '',
      passportNumber: '',
    };
  }
};

export default SendVaccinationCertificateDialog;
