import React, { useContext, useState, useEffect } from 'react';
import {
  makeStyles,
  useTheme,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import moment from 'moment';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Error';
import ErrorIcon from '@material-ui/icons/AddCircle';

import ConfirmDialog from '../ConfirmDialog';
import { LangContext } from '../../context/LangContext';
import translations from '../../assets/json/translations.json';
import * as certificateReviewService from '../../services/certificateReviewService';

import {
  ICertificate,
  IVaccination,
  IValidationError,
} from '../../interfaces/qrCertificate';
import { DATE_FORMAT } from '../../constants';
import { qrCodeErrorSeverity } from '../../constants/enums';

import { Passenger } from '../../models/Models';
import { PassengerFiles } from '../../models/CertificateReviewModels';

interface IProps {
  open: boolean;
  passenger: Passenger;
  file: PassengerFiles | null;
  onCancel: () => void;
}

const useStyles = makeStyles((theme) => ({
  hideInput: {
    opacity: 0,
    height: 0,
  },
  infoLabel: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  infoValue: {
    color: theme.palette.text.secondary,
  },
  table: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderBottomWidth: 0,
    borderRadius: 4,
    marginTop: theme.spacing(2),
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  icon: {
    position: 'absolute',
    top: '-1px',
  },
  errors: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const DisplayQRCodeDialog: React.FC<IProps> = ({
  open,
  passenger,
  file,
  onCancel,
}) => {
  const [lang] = useContext(LangContext);
  const classes = useStyles();
  const theme = useTheme();
  const [certificate, setCertificate] = useState<ICertificate | null>(null);

  useEffect(() => {
    const fetchPassenger = async () => {
      if (file?.id) {
        const response = await certificateReviewService.getCertificateByFileId(
          file.id
        );
        if (response.isOk && response.data) {
          setCertificate(response.data);
        }
      }
    };
    if (file) {
      fetchPassenger();
    }
  }, [file]);

  return (
    <>
      <ConfirmDialog
        open={open}
        maxWidth={'md'}
        title={translations.qrCode[lang]}
        cancelText={translations.close[lang]}
        onCancel={onCancel}
      >
        {renderCertificate()}
      </ConfirmDialog>
    </>
  );

  function renderCertificate() {
    if (!certificate) {
      return null;
    }

    const certificateName = `${certificate.subject.firstName} ${certificate.subject.lastName}`;
    const registeredName = `${passenger.firstName} ${passenger.lastName}`;
    const certificateDateOfBirth = moment(certificate.subject.dateOfBirth);
    const registeredDateOfBirth = moment(passenger.dateOfBirth);

    return (
      <Grid container spacing={2} style={{ marginBottom: theme.spacing(1) }}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              {renderInfoItem(
                translations.certificateSystem[lang],
                certificate.system || ''
              )}
            </Grid>
            <Grid item>
              {renderInfoItem(
                translations.certificateType[lang],
                file?.fileType?.description[lang] || ''
              )}
            </Grid>
            <Grid item>
              {renderInfoItem(
                translations.issuer[lang],
                `${certificate.issuerInfo.name} (${certificate.issuerInfo.country})`
              )}
            </Grid>
            <Grid item>
              {renderInfoItem(
                translations.validBetween[lang],
                `${moment(certificate.issueAt).format(DATE_FORMAT)} - ${moment(
                  certificate.expires
                ).format(DATE_FORMAT)}`
              )}
            </Grid>
            {certificate.pcrNegativeTest && (
              <>
                <Grid item>
                  {renderInfoItem(
                    translations.pcrNegativeTestType[lang],
                    certificate.pcrNegativeTest.testType
                  )}
                </Grid>
                <Grid item>
                  {renderInfoItem(
                    translations.pcrNegativeTestResult[lang],
                    `${certificate.pcrNegativeTest.result} (${moment(
                      certificate.pcrNegativeTest.dateOfTest
                    ).format(DATE_FORMAT)})`
                  )}
                </Grid>
              </>
            )}
            {certificate.antibodiesTest && (
              <Grid item>
                {renderInfoItem(
                  translations.antibodiesTest[lang],
                  `${certificate.antibodiesTest.testType} (${moment(
                    certificate.antibodiesTest.dateOfTest
                  ).format(DATE_FORMAT)})`
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              {renderInfoItem(
                translations.nameOnCertificate[lang],
                certificateName
              )}
            </Grid>
            <Grid item>
              {renderInfoItem(
                translations.registeredName[lang],
                registeredName
              )}
            </Grid>
            <Grid item>
              {renderInfoItem(
                translations.dateOfBirthOnCertificate[lang],
                certificateDateOfBirth.format(DATE_FORMAT)
              )}
            </Grid>
            <Grid item>
              {renderInfoItem(
                translations.registeredDateOfBirth[lang],
                registeredDateOfBirth.format(DATE_FORMAT)
              )}
            </Grid>
          </Grid>
        </Grid>
        {!!certificate.vaccinations?.length && (
          <Grid item xs={12}>
            {renderVaccinations(certificate.vaccinations)}
          </Grid>
        )}
        {!!certificate.validationErrors?.length && (
          <Grid item xs={12}>
            <div className={classes.errors}>
              <Grid container spacing={2} direction="column">
                {certificate.validationErrors
                  .sort((a, b) => a.severity - b.severity)
                  .map((error, index) => (
                    <Grid item key={index}>
                      {renderErrorItem(error)}
                    </Grid>
                  ))}
              </Grid>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }

  function renderInfoItem(
    label: string,
    value: string,
    status?: 'success' | 'warning'
  ) {
    return (
      <Typography style={{ position: 'relative' }}>
        {status === 'success' ? (
          <SuccessIcon
            className={classes.icon}
            style={{ color: theme.palette.success.main }}
          />
        ) : status === 'warning' ? (
          <WarningIcon
            className={classes.icon}
            style={{ color: theme.palette.warning.light }}
          />
        ) : null}
        <span
          className={classes.infoLabel}
          style={{ marginLeft: status ? 30 : 0 }}
        >
          {label}:
        </span>
        <span className={classes.infoValue}>{value}</span>
      </Typography>
    );
  }

  function renderErrorItem(error: IValidationError) {
    return (
      <Typography style={{ position: 'relative' }} color="textPrimary">
        {error.severity === qrCodeErrorSeverity.ERROR ||
        error.severity === qrCodeErrorSeverity.INVALID ? (
          <ErrorIcon
            className={classes.icon}
            style={{
              color: theme.palette.error.main,
              transform: 'rotate(45deg)',
            }}
          />
        ) : error.severity === qrCodeErrorSeverity.WARNING ? (
          <WarningIcon
            className={classes.icon}
            style={{ color: theme.palette.warning.light }}
          />
        ) : (
          <InfoIcon
            className={classes.icon}
            style={{ color: theme.palette.primary.main }}
          />
        )}
        <span style={{ marginLeft: 35 }}>{error.message}</span>
      </Typography>
    );
  }

  function renderVaccinations(vaccinations: IVaccination[]) {
    return (
      <>
        <Typography className={classes.infoLabel}>
          {translations.vaccinations[lang]}:
        </Typography>
        <TableContainer className={classes.table}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{translations.vaccine[lang]}</TableCell>
                <TableCell>{translations.manufacturer[lang]}</TableCell>
                <TableCell>{translations.doses[lang]}</TableCell>
                <TableCell>{translations.date[lang]}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vaccinations.map((vaccination, index) => (
                <TableRow key={index}>
                  <TableCell>{vaccination.vaccineName}</TableCell>
                  <TableCell>{vaccination.manufacturer}</TableCell>
                  <TableCell>{vaccination.doseNumber}</TableCell>
                  <TableCell>
                    {moment(vaccination.vaccinationDate).format(DATE_FORMAT)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
};

export default DisplayQRCodeDialog;
