import React, { useContext, useRef, useState } from 'react';
import {
  Typography,
  Grid,
  Paper,
  TextField,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Button from '../components/Button';
import ConfirmDialog from '../components/ConfirmDialog';
import useNotifier from '../hooks/useNotifier';
import * as httpService from '../services/httpService';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { CefSharpShell } from '../helpers/CefSharpShell';
import { parseTemplateString } from '../utils';

export interface IFormValues {
  numberOfLabels: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

const schema = Yup.object().shape({
  numberOfLabels: Yup.number().min(1).max(100).required(),
});

const LABELS_MIN = 1;
const LABELS_MAX = 100;
const PRINT_INTERVAL_MS = 1000;

const PrintLabelsPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [lang] = useContext(LangContext);
  const { notifySuccess, notifyError } = useNotifier();
  const numberInput = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const shellHelper = new CefSharpShell();
  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  return (
    <Layout useDefaultSpacing title={translations.printLabels[lang]}>
      <Section>
        <Formik
          onSubmit={() => setDialogOpen(true)}
          validationSchema={schema}
          initialValues={{ numberOfLabels: '' }}
        >
          {({
            values,
            errors,
            dirty,
            handleChange,
            handleSubmit,
            handleReset,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5">
                      {translations.numberOfLabels[lang]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      autoFocus
                      autoComplete="off"
                      id="numberOfLabels"
                      variant="outlined"
                      disabled={loading}
                      label={translations.numberOfLabels[lang]}
                      inputProps={{
                        ref: numberInput,
                        type: 'number',
                        min: LABELS_MIN,
                        max: LABELS_MAX,
                      }}
                      value={values.numberOfLabels}
                      onChange={(e) =>
                        e.target.validity.valid && handleChange(e)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      type="submit"
                      disabled={loading || !dirty || !!errors.numberOfLabels}
                    >
                      {loading ? (
                        <CircularProgress size={26} color="inherit" />
                      ) : (
                        translations.print[lang]
                      )}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      disabled={loading}
                      onClick={() => {
                        handleReset();
                        focusNumberInput();
                      }}
                    >
                      {translations.clear[lang]}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              <ConfirmDialog
                open={dialogOpen}
                title={translations.printConfirmTitle[lang]}
                text={parseTemplateString(
                  translations.printConfirmText[lang],
                  values.numberOfLabels
                )}
                confirmText={translations.print[lang]}
                onConfirm={() => {
                  printLabels(values);
                  setDialogOpen(false);
                }}
                onCancel={() => setDialogOpen(false)}
              />
            </Form>
          )}
        </Formik>
      </Section>
    </Layout>
  );

  async function printLabels(values: IFormValues) {
    setLoading(true);
    try {
      const response = await httpService.getPrintLabels(
        parseInt(values.numberOfLabels)
      );
      if (response.isOk) {
        let time = 0;
        const promises = response.data.map((label) => {
          const promise = new Promise((resolve) =>
            setTimeout(() => {
              shellHelper.printBarcode(label);
              resolve();
            }, time)
          );
          time += PRINT_INTERVAL_MS;
          return promise;
        });
        await Promise.all(promises);
        notifySuccess(translations.printRequestSent[lang]);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        throw new Error();
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
    }
    setLoading(false);
  }

  function focusNumberInput() {
    if (numberInput && numberInput.current) {
      numberInput.current.focus();
    }
  }
};

export default PrintLabelsPage;
