import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import * as Yup from 'yup';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';

import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { Form, Formik, FormikProps } from 'formik';
import { VaccinationPerson } from '../models/VaccinationModels';
import * as vaccinationService from '../services/vaccinationService';
import { IVaccinationGroupPersonList } from '../interfaces/vaccinationPerson';
import Button from './Button';

interface IProps {
  open: boolean;
  loading?: boolean;
  row: null | VaccinationPerson;
  removeRow: () => void;
  onSubmit: (values: IDialogFormValues) => void;
  onCancel: () => void;
  duplicateSsn: (ssn: string) => boolean;
}

export interface IDialogFormValues {
  ssn: string;
  phoneNumber: string;
}

type IFormik = FormikProps<IDialogFormValues>;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  cancelIcon: {
    color: theme.palette.error.main,
  },
  duplicateIcon: {
    color: theme.palette.secondary.main,
  },
  statusText: {
    paddingLeft: theme.spacing(1),
  },
  ssnValidationContainer: {
    minHeight: '52px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const EditVaccinationGroupDialog: React.FC<IProps> = ({
  open,
  row,
  loading,
  removeRow,
  onSubmit,
  onCancel,
  duplicateSsn,
}) => {
  const [lang] = useContext(LangContext);
  const classes = useStyles();
  const idInputdialog = useRef<HTMLInputElement>(null);
  const [disableConfirm, setDisableConfirm] = useState(false);
  const [ssnLoading, setSsnLoading] = useState(false);
  const [validSsn, setValidSsn] = useState<null | VaccinationPerson>(null);

  useEffect(() => {
    if (!open) {
      setDisableConfirm(false);
    }
    if (open) {
      setTimeout(() => {
        focusidInputdialog();
      }, 200);
    }
    if (!open) {
      setDisableConfirm(false);
    }
  }, [open]);

  useEffect(() => {
    if (loading) {
      setDisableConfirm(true);
    }
  }, [loading]);

  useEffect(() => {
    if (row) {
      ssnLookup([{ ssn: row.ssn }]);
    }
  }, [row]);

  const schema = Yup.object().shape({
    ssn: Yup.string().min(10).required(),
  });

  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <Formik
        onSubmit={onSubmit}
        validationSchema={schema}
        initialValues={getInitalValues()}
        enableReinitialize
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <DialogTitle>
              {row
                ? translations.updatePerson[lang]
                : translations.addPersonToGroup[lang]}
            </DialogTitle>
            <DialogContent style={{ minHeight: 80 }} dividers>
              <Grid container spacing={2}>
                {renderFormFields(formik)}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container spacing={1} direction="row" alignItems="center">
                <Grid item style={{ flex: 1 }}>
                  {row && (
                    <Button
                      variant="contained"
                      padding={false}
                      error
                      onClick={() => removeRow()}
                    >
                      {translations.delete[lang]}
                    </Button>
                  )}
                </Grid>

                {loading && (
                  <Grid item>
                    <CircularProgress size={32} color="primary" />
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="contained"
                    padding={false}
                    disabled={showSubmitButton(formik)}
                    type="submit"
                  >
                    {translations.confirm[lang]}
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={close} variant="outlined" padding={false}>
                    {translations.cancel[lang]}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );

  function close() {
    onCancel();
  }

  function renderFormFields({
    values,
    errors,
    submitCount,
    handleChange,
    handleBlur,
    setFieldValue,
  }: IFormik) {
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            autoFocus
            autoComplete="off"
            id="ssn"
            label={translations.SSN[lang]}
            variant="outlined"
            inputProps={{ ref: idInputdialog, maxLength: 10 }}
            value={values.ssn}
            error={!!errors.ssn && !!submitCount}
            onChange={(e) => validateSsnInput(e.target.value, setFieldValue)}
            onBlur={handleBlur}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            autoFocus
            autoComplete="off"
            id="phoneNumber"
            label={translations.phoneNumber[lang]}
            variant="outlined"
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} className={classes.ssnValidationContainer}>
          {values.ssn.length === 10 && !ssnLoading && (
            <React.Fragment>
              {validSsn &&
              validSsn.verified &&
              validSsn.vaccinationAge &&
              (!duplicateSsn(values.ssn) || (row && values.ssn === row.ssn)) ? (
                <div className={classes.container}>
                  <CheckCircleIcon className={classes.checkIcon} />
                  <Typography
                    color="textSecondary"
                    className={classes.statusText}
                  >
                    {validSsn.name}
                  </Typography>
                </div>
              ) : validSsn &&
                validSsn.verified &&
                !validSsn.vaccinationAge &&
                (!duplicateSsn(values.ssn) ||
                  (row && values.ssn === row.ssn)) ? (
                <div className={classes.container}>
                  <ErrorIcon className={classes.duplicateIcon} />
                  <Typography
                    color="textSecondary"
                    className={classes.statusText}
                  >
                    {translations.personUnder5[lang]}
                  </Typography>
                </div>
              ) : duplicateSsn(values.ssn) &&
                (!row || row.ssn !== values.ssn) ? (
                <div className={classes.container}>
                  <ErrorIcon className={classes.duplicateIcon} />
                  <Typography
                    color="textSecondary"
                    className={classes.statusText}
                  >
                    {translations.personAlreadyInGroup[lang]}
                  </Typography>
                </div>
              ) : (
                <div className={classes.container}>
                  <CancelIcon className={classes.cancelIcon} />
                  <Typography
                    color="textSecondary"
                    className={classes.statusText}
                  >
                    {translations.personNotFound[lang]}
                  </Typography>
                </div>
              )}
            </React.Fragment>
          )}
          {ssnLoading && <CircularProgress size={32} color="primary" />}
        </Grid>
      </React.Fragment>
    );
  }

  function validateSsnInput(value: string, setFieldValue: any) {
    if (value.length === 10) {
      setSsnLoading(true);
      setFieldValue('ssn', value);
      setTimeout(() => {
        ssnLookup([{ ssn: value }]);
      }, 500);
    } else {
      setValidSsn(null);
      setSsnLoading(false);
      setFieldValue('ssn', value);
    }
  }

  function ssnLookup(value: IVaccinationGroupPersonList[]) {
    vaccinationService
      .lookupVaccinationPersons(value)
      .then((response) => {
        if (response.isOk && response.data) {
          setValidSsn(response.data[0]);
          setSsnLoading(false);
        } else {
          setValidSsn(null);
          setSsnLoading(false);
        }
      })
      .catch(() => {
        setValidSsn(null);
        setSsnLoading(false);
      });
  }

  function getInitalValues() {
    if (row) {
      return {
        ssn: row.ssn,
        phoneNumber: row.phoneNumber,
      };
    }
    return {
      ssn: '',
      phoneNumber: '',
    };
  }

  function showSubmitButton(formik: IFormik) {
    if (
      validSsn?.verified &&
      validSsn.vaccinationAge &&
      formik.dirty &&
      !disableConfirm
    ) {
      if (
        !duplicateSsn(formik.values.ssn) ||
        (row && formik.values.ssn === row.ssn)
      ) {
        return false;
      }
    }

    return true;
  }

  function focusidInputdialog() {
    if (idInputdialog && idInputdialog.current) {
      idInputdialog.current.focus();
    }
  }
};

export default EditVaccinationGroupDialog;
