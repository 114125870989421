import React from 'react';
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Collapse,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

interface IProps {
  items: IHelpItem[];
  onClick: (item: IHelpItem, index: number) => void;
  onClickSubItem: (item: IHelpSubItem, index: number) => void;
  listClass?: string;
}

interface IHelpItem {
  text: string;
  selected: boolean;
  subItems?: IHelpSubItem[];
}

interface IHelpSubItem {
  text: string;
  selected: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(6),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  })
);

const HelpItems: React.FC<IProps> = ({
  items,
  onClick,
  onClickSubItem,
  listClass,
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={2}>
      <List className={listClass}>
        {items.map((item, index) => (
          <>
            <ListItem
              key={index}
              button
              selected={item.selected}
              onClick={() => onClick(item, index)}
            >
              <ListItemText>
                <Typography style={{ fontWeight: 'bold' }}>
                  {item.text}
                </Typography>
              </ListItemText>
              {item?.subItems &&
                (item.selected ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            {item?.subItems &&
              item.selected &&
              renderSubItems(item.subItems, item.selected)}
          </>
        ))}
      </List>
    </Paper>
  );

  function renderSubItems(items: IHelpSubItem[], open: boolean) {
    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          {items.map((item, index) => (
            <ListItem
              key={index}
              button
              selected={item.selected}
              className={classes.nested}
              onClick={() => onClickSubItem(item, index)}
            >
              <ListItemText>{item.text}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>
    );
  }
};

export default HelpItems;
