import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import moment from 'moment';
import { accessControl } from '../constants/enums';

import NoResults from './NoResults';
import { Passenger } from '../models/Models';
import { DATE_FORMAT, TABLE_ROWS_OPTIONS } from '../constants';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { UserContext } from '../context/UserContext';
import { isAccessible } from '../utils';

interface IProps {
  passengers: Passenger[];
  currentPage: number;
  rowsPerPage: number;
  setCurrentPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
  onClick?: (passenger: Passenger) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
}));

const PassengerList: React.FC<IProps> = ({
  passengers,
  currentPage,
  rowsPerPage,
  setCurrentPage,
  setRowsPerPage,
  onClick,
}) => {
  const classes = useStyles({});
  const [lang] = useContext(LangContext);
  const [sortedPassengers, setSortedPassengers] = useState<Passenger[]>([]);
  const startIndex = currentPage * rowsPerPage;
  const [user] = useContext(UserContext);

  useEffect(() => {
    setSortedPassengers(
      passengers.sort((a, b) => a.lastName.localeCompare(b.lastName))
    );
  }, [passengers]);
  function getObservationStatusTranslation(passenger: Passenger) {
    if (passenger.observationStatus) {
      if (passenger.observationStatus.id === 0 && !passenger.sampleTaken) {
        return translations.noSampleTaken[lang];
      } else {
        return passenger.observationStatus.description[lang];
      }
    } else {
      return '';
    }
  }
  return (
    <Paper elevation={2} className={classes.container}>
      {passengers.length ? (
        <React.Fragment>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{translations.lastName[lang]}</TableCell>
                  <TableCell>{translations.firstNames[lang]}</TableCell>
                  <TableCell>{translations.flightNumber[lang]}</TableCell>
                  <TableCell>{translations.arrivalDate[lang]}</TableCell>
                  <TableCell>{translations.dateOfBirth[lang]}</TableCell>
                  {isAccessible(accessControl.SUPER_SEARCH, user) && (
                    <TableCell>{translations.result[lang]}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedPassengers
                  .slice(startIndex, startIndex + rowsPerPage)
                  .map((passenger) => (
                    <TableRow
                      hover={!!onClick}
                      key={passenger.serialNumber}
                      style={{ cursor: onClick ? 'pointer' : undefined }}
                      onClick={() => onClick && onClick(passenger)}
                    >
                      <TableCell>{passenger.lastName}</TableCell>
                      <TableCell>{passenger.firstName}</TableCell>
                      <TableCell>{passenger.flightNumber}</TableCell>
                      <TableCell>
                        {moment(passenger.dateOfArrival).format(DATE_FORMAT)}
                      </TableCell>
                      <TableCell>
                        {moment(passenger.dateOfBirth).format(DATE_FORMAT)}
                      </TableCell>
                      {isAccessible(accessControl.SUPER_SEARCH, user) && (
                        <TableCell>
                          {getObservationStatusTranslation(passenger)}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={translations.numberOfRowsPerPage[lang]}
            rowsPerPageOptions={TABLE_ROWS_OPTIONS}
            component="div"
            count={passengers.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={(_, p) => setCurrentPage(p)}
            onChangeRowsPerPage={(e) => {
              setCurrentPage(0);
              setRowsPerPage(parseInt(e.target.value, 10));
            }}
          />
        </React.Fragment>
      ) : (
        <NoResults message={translations.noPassengersFound[lang]} />
      )}
    </Paper>
  );
};

export default PassengerList;
