import React, { useContext } from 'react';
import {
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import moment from 'moment';
import { WaitingListVaccination } from '../models/VaccinationModels';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { DATE_AND_TIME_FORMAT } from '../constants';

interface IProps {
  vaccinations: WaitingListVaccination[];
}

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderBottomWidth: 0,
    borderRadius: 4,
    marginTop: theme.spacing(1),
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  emptyText: {
    display: 'inline-block',
  },
}));

const WaitingListVaccinationStatusTable: React.FC<IProps> = ({
  vaccinations,
}) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);

  if (!vaccinations.length) {
    return (
      <Typography className={classes.emptyText} color="textSecondary">
        {translations.noVaccinations[lang]}
      </Typography>
    );
  }

  return (
    <TableContainer className={classes.container}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{translations.numberShort[lang]}</TableCell>
            <TableCell>{translations.finished[lang]}</TableCell>
            <TableCell>{translations.vaccine[lang]}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vaccinations.map((vaccination, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                {moment(vaccination.vaccinationDate).format(
                  DATE_AND_TIME_FORMAT
                )}
              </TableCell>
              <TableCell>{vaccination.vaccineName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WaitingListVaccinationStatusTable;
