import React, { useContext, useState, useRef } from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  useTheme,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Button from '../../Button';
import {
  ChildCustodianType,
  childVaccinationCustodianPosition,
} from '../../../constants/enums';
import {
  ChildCustodians,
  VaccinationPerson,
} from '../../../models/VaccinationModels';
import translations from '../../../assets/json/translations.json';
import { LangContext } from '../../../context/LangContext';
import moment, { Moment } from 'moment';
import { DATE_FORMAT } from '../../../constants';
import Dropdown from '../../Dropdown';
import EditButton from '../../EditButton';
import ConfirmDialog from '../../ConfirmDialog';
import * as vaccinationService from '../../../services/vaccinationService';
import { IVaccinationGroupPersonList } from '../../../interfaces/vaccinationPerson';
import DatePicker from '../../DatePicker';

interface IProps {
  custodians: ChildCustodians[];
  canEdit?: boolean;
  updateChildGuardianDecision: (
    allowVaccinationStatus: number,
    comment: string,
    childGuardianId: number
  ) => Promise<void>;
  addGuardian: (
    ssn: string,
    name: string,
    dateOfBirth: string,
    allowVaccinationStatus: number,
    comment: string
  ) => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  descriptionTitle: {
    display: 'inline-block',
    fontWeight: 'bold',
    paddingRight: 10,
  },
  descriptionDetails: {
    display: 'inline-block',
  },
}));

const ChildCustodianPosition: React.FC<IProps> = ({
  custodians,
  updateChildGuardianDecision,
  addGuardian,
  canEdit,
}) => {
  const classes = useStyles();
  const MAX_COMMENT_LENGTH = 150;
  const [lang] = useContext(LangContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addGuardianDialogOpen, setAddGuardianDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCustodian, setSelectedCustodian] =
    useState<ChildCustodians | null>(null);
  const [commentText, setCommentText] = useState('');
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [ssnLoading, setSsnLoading] = useState(false);
  const [validSsn, setValidSsn] = useState<null | VaccinationPerson>(null);
  const [ssn, setSsn] = useState('');
  const [custodianNoSSN, setCustodianNoSSN] = useState(false);
  const [custodianName, setCustodianName] = useState('');
  const [custodianDOB, setCustodianDOB] = useState<Moment | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  var statusItems:
    | { value: string; label: string }[]
    | { value: string; label: string }[] = [];
  statusItems.push({
    value: '0',
    label: translations.haveNotTakenAPosition[lang],
  });
  statusItems.push({
    value: '1',
    label: translations.approved[lang],
  });
  statusItems.push({
    value: '2',
    label: translations.rejected[lang],
  });
  statusItems.push({
    value: '3',
    label: translations.notNow[lang],
  });

  const guardians = custodians?.filter(
    (item) => item.custodianType === ChildCustodianType.GUARDIAN
  );
  function openDialog(custodian: ChildCustodians) {
    if (custodian) {
      setSelectedCustodian(custodian);
      setCommentText(custodian.comment ? custodian.comment : '');
      if (custodian.allowVaccination !== undefined) {
        setSelectedStatus(custodian.allowVaccination.toString());
      }
      setDialogOpen(true);
    }
  }
  function isDirty() {
    return (
      selectedCustodian?.allowVaccination.toString() !== selectedStatus ||
      selectedCustodian.comment !== commentText
    );
  }

  function isDirtyAddGuardian() {
    return selectedStatus && (validSsn || (custodianName && custodianDOB));
  }

  if (guardians.length) {
    return (
      <Grid container spacing={0}>
        {guardians.map((item, index) => (
          <div style={{ marginBottom: 12 }}>
            <Grid item key={index} xs={12}>
              <div
                onClick={canEdit ? () => openDialog(item) : undefined}
                style={{
                  display: 'inline-block',
                  cursor: true ? 'pointer' : undefined,
                }}
              >
                <Typography className={classes.descriptionTitle}>
                  {item.name}:
                </Typography>
                {getText(item)}
                {canEdit && <EditButton onClick={() => openDialog(item)} />}
              </div>
            </Grid>
            {item?.comment && getCustodianComment(item.comment)}
            {true && renderDialog()}
          </div>
        ))}
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button
            onClick={() => setAddGuardianDialogOpen(true)}
            variant="outlined"
            padding={false}
          >
            {translations.addGuardian[lang]}
          </Button>
        </Grid>
      </Grid>
      {renderDialog()}
    </>
  );

  async function onSubmit() {
    if (isDirty()) {
      setLoadingUpdate(true);
      if (selectedCustodian) {
        await updateChildGuardianDecision(
          Number(selectedStatus),
          commentText,
          selectedCustodian.id
        );
      }
      setDialogOpen(false);
      setLoadingUpdate(false);
    }
  }

  async function onSubmitNewGuardian() {
    if (isDirtyAddGuardian()) {
      setLoadingUpdate(true);

      await addGuardian(
        validSsn ? validSsn.ssn : '',
        validSsn ? validSsn.name : custodianName,
        custodianDOB ? custodianDOB.toISOString() : '',
        Number(selectedStatus),
        commentText
      );

      setAddGuardianDialogOpen(false);
      setLoadingUpdate(false);
    }
  }

  function renderDialog() {
    return (
      <>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        >
          <DialogTitle>{`Breyta afstöðu foreldris ${selectedCustodian?.name}`}</DialogTitle>
          <DialogContent style={{ minHeight: 80 }} dividers>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} sm={12}>
                <Dropdown
                  fullWidth
                  id="allowVaccinationStatus"
                  value={selectedStatus}
                  label={translations.custodianPosition[lang]}
                  items={statusItems}
                  onChange={(e) => setSelectedStatus(e.target.value as string)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  multiline
                  autoComplete="off"
                  id="commentText"
                  variant="outlined"
                  rows={4}
                  inputRef={inputRef}
                  inputProps={{ maxLength: MAX_COMMENT_LENGTH }}
                  label={translations.comment[lang]}
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                />
                <Typography
                  color="textSecondary"
                  variant="body2"
                  style={{ marginTop: theme.spacing(1) }}
                >
                  {commentText.length}/{MAX_COMMENT_LENGTH}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1} direction="row" alignItems="center">
              {loadingUpdate && (
                <Grid item>
                  <CircularProgress size={32} color="primary" />
                </Grid>
              )}
              <Grid item>
                <Button
                  padding={false}
                  onClick={onSubmit}
                  color="primary"
                  variant="contained"
                  disabled={loadingUpdate || !isDirty()}
                >
                  {translations.confirm[lang]}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  padding={false}
                  color="primary"
                  variant="outlined"
                  onClick={() => setDialogOpen(false)}
                >
                  {translations.cancel[lang]}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <ConfirmDialog
          open={addGuardianDialogOpen}
          title={translations.addGuardian[lang]}
          onCancel={() => setAddGuardianDialogOpen(false)}
          onConfirm={() => onSubmitNewGuardian()}
          disableConfirm={loadingUpdate || !isDirtyAddGuardian()}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                label={translations.custodianDoesNotHaveSSN[lang]}
                control={
                  <Checkbox
                    id="createAnother"
                    color="primary"
                    checked={custodianNoSSN}
                    onChange={() => setCustodianNoSSN(!custodianNoSSN)}
                  />
                }
              />
            </Grid>
            {custodianNoSSN && (
              <>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id="name"
                    label={translations.name[lang]}
                    variant="outlined"
                    value={custodianName}
                    onChange={(e) => setCustodianName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    id="dateOfBirth"
                    label={translations.dateOfBirth[lang]}
                    value={custodianDOB}
                    onChange={(e) => setCustodianDOB(e ? moment(e) : null)}
                  />
                </Grid>
              </>
            )}
            {!custodianNoSSN && (
              <>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoFocus
                    autoComplete="off"
                    id="ssn"
                    label={`${translations.SSN[lang]}*`}
                    variant="outlined"
                    inputProps={{
                      maxLength: 10,
                      pattern: '[0-9+]*',
                    }}
                    value={ssn}
                    error={!validSsn && ssn.length === 10 && !ssnLoading}
                    helperText={
                      !validSsn && ssn.length === 10 && !ssnLoading
                        ? translations.SsnNotFound[lang]
                        : undefined
                    }
                    onChange={(e) =>
                      e.target.validity.valid &&
                      validateSsnInput(e.target.value)
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id="name"
                    label={translations.name[lang]}
                    variant="outlined"
                    value={validSsn?.name || ''}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={12}>
              <Dropdown
                fullWidth
                id="allowVaccinationStatus"
                value={selectedStatus}
                label={translations.custodianPosition[lang]}
                items={statusItems}
                onChange={(e) => setSelectedStatus(e.target.value as string)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                multiline
                autoComplete="off"
                id="commentText"
                variant="outlined"
                rows={4}
                inputRef={inputRef}
                inputProps={{ maxLength: MAX_COMMENT_LENGTH }}
                label={translations.comment[lang]}
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />
              <Typography
                color="textSecondary"
                variant="body2"
                style={{ marginTop: theme.spacing(1) }}
              >
                {commentText.length}/{MAX_COMMENT_LENGTH}
              </Typography>
            </Grid>
          </Grid>
        </ConfirmDialog>
      </>
    );
  }

  function ssnLookup(value: IVaccinationGroupPersonList[]) {
    vaccinationService
      .lookupVaccinationPersons2(value)
      .then((response) => {
        if (response.isOk && response.data) {
          setValidSsn(response.data as VaccinationPerson);
          setSsnLoading(false);
        } else {
          setValidSsn(null);
          setSsnLoading(false);
        }
      })
      .catch(() => {
        setValidSsn(null);
        setSsnLoading(false);
      });
  }

  function validateSsnInput(value: string) {
    if (value.length === 10) {
      setSsnLoading(true);
      setSsn(value);

      ssnLookup([{ ssn: value }]);
    } else {
      setValidSsn(null);
      setSsnLoading(false);
      setSsn(value);
    }
  }

  function getText(guardian: ChildCustodians) {
    if (
      guardian.allowVaccination === childVaccinationCustodianPosition.UNKNOWN
    ) {
      return (
        <Typography
          className={classes.descriptionDetails}
          color="textSecondary"
        >
          {translations.hasNotTakenAPosition[lang]}
        </Typography>
      );
    }
    if (guardian.allowVaccination === childVaccinationCustodianPosition.YES) {
      return (
        <Typography
          className={classes.descriptionDetails}
          color="textSecondary"
        >
          {`${translations.approved[lang]} - ${
            guardian?.decisionTaken
              ? moment(guardian.decisionTaken).format(DATE_FORMAT)
              : ''
          }`}
        </Typography>
      );
    }
    if (
      guardian.allowVaccination === childVaccinationCustodianPosition.NOT_NOW
    ) {
      return (
        <Typography
          className={classes.descriptionDetails}
          color="textSecondary"
        >
          {`${translations.notNow[lang]} - ${
            guardian?.decisionTaken
              ? moment(guardian.decisionTaken).format(DATE_FORMAT)
              : ''
          }`}
        </Typography>
      );
    }
    if (guardian.allowVaccination === childVaccinationCustodianPosition.NO) {
      return (
        <Typography
          className={classes.descriptionDetails}
          color="textSecondary"
        >
          {`${translations.rejected[lang]} - ${
            guardian?.decisionTaken
              ? moment(guardian.decisionTaken).format(DATE_FORMAT)
              : ''
          }`}
        </Typography>
      );
    }
  }

  function getCustodianComment(comment: string) {
    return (
      <Grid item xs={12}>
        <div
          style={{
            display: 'inline-block',
          }}
        >
          <Typography className={classes.descriptionTitle}>
            {translations.comment[lang]}:
          </Typography>
          <Typography
            className={classes.descriptionDetails}
            color="textSecondary"
          >
            {comment}
          </Typography>
        </div>
      </Grid>
    );
  }
};

export default ChildCustodianPosition;
