import React from 'react';
import { Typography, makeStyles, Grid, Paper } from '@material-ui/core';

import {
  IVaccinationPersonChildDetails,
  IVaccinationPersonDetails,
} from '../interfaces/vaccinationPerson';
import { ChildInfo, VaccinationPerson } from '../models/VaccinationModels';
import Button from './Button';
import theme from '../theme';

interface IProps {
  vaccinationPerson: VaccinationPerson;
  vaccinationPersonChildInfo?: ChildInfo;
  vaccinationPersonDetails: IVaccinationPersonDetails[][];
  secondDetailsColumn?: IVaccinationPersonDetails[][];
  childDetails?: IVaccinationPersonChildDetails[];
  buttons?: IButton[] | null;
  vaccinationTypeElement?: JSX.Element;
}

export interface IButton {
  label?: string;
  onClick: () => void;
  variant?: 'contained' | 'outlined';
  error?: boolean;
  icon?: () => React.ReactNode;
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  descriptionTitle: {
    display: 'inline-block',
    fontWeight: 'bold',
    paddingRight: 10,
  },
  descriptionDetails: {
    display: 'inline-block',
  },
  buttonGrid: {
    textAlign: 'right',
    marginTop: theme.spacing(3),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  topItems: {
    marginBottom: theme.spacing(1),
  },
}));

const VaccinationPersonGroupedInfo: React.FC<IProps> = ({
  vaccinationPerson,
  vaccinationPersonChildInfo,
  vaccinationPersonDetails,
  secondDetailsColumn,
  childDetails,
  buttons,
  vaccinationTypeElement,
}) => {
  const classes = useStyles();
  const filtered = Object.assign([], vaccinationPersonDetails);
  filtered.shift();

  return (
    <Paper elevation={2} className={classes.container}>
      <Grid container>
        <Grid container spacing={3}>
          {secondDetailsColumn ||
          (childDetails && vaccinationPersonChildInfo) ? (
            <>
              <Grid item xs={6} lg={6}>
                <Grid container spacing={3}>
                  {renderGroupedInfo(vaccinationPersonDetails[0], 0)}
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                {secondDetailsColumn && (
                  <Grid container spacing={3}>
                    {secondDetailsColumn.map(renderGroupedInfo)}
                  </Grid>
                )}
                {childDetails && vaccinationPersonChildInfo && (
                  <Grid
                    container
                    spacing={3}
                    style={{
                      marginTop: secondDetailsColumn && theme.spacing(3),
                    }}
                  >
                    {renderChildInfo()}
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} lg={secondDetailsColumn ? 6 : undefined}>
                <Grid container spacing={3}>
                  {filtered.map(renderGroupedInfo)}
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} lg={secondDetailsColumn ? 6 : undefined}>
              <Grid container spacing={3}>
                {vaccinationPersonDetails.map(renderGroupedInfo)}
              </Grid>
            </Grid>
          )}
        </Grid>
        {buttons && (
          <Grid item xs={12} sm={12} className={classes.buttonGrid}>
            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="flex-end"
            >
              {vaccinationTypeElement && (
                <Grid item>{vaccinationTypeElement}</Grid>
              )}
              {buttons && buttons.map(renderButton)}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );

  function renderGroupedInfo(
    groupeInfo: IVaccinationPersonDetails[],
    index: number
  ) {
    return (
      <Grid key={index} item xs={12}>
        <Grid container spacing={1}>
          {groupeInfo.map(renderInfoItem)}
        </Grid>
      </Grid>
    );
  }

  function renderInfoItem(
    { label, render, getVal = () => '', hide }: IVaccinationPersonDetails,
    index: number
  ) {
    return hide && hide(vaccinationPerson) ? null : (
      <Grid item xs={12} key={index}>
        {label && (
          <Typography className={classes.descriptionTitle}>{label}:</Typography>
        )}
        {render ? (
          render(vaccinationPerson)
        ) : (
          <Typography
            className={classes.descriptionDetails}
            color="textSecondary"
          >
            {getVal(vaccinationPerson)}
          </Typography>
        )}
      </Grid>
    );
  }

  function renderChildInfo() {
    return (
      <Grid item xs={12}>
        <Grid container spacing={1} direction="row">
          {childDetails?.map((item: any, index) => (
            <Grid item xs={12} key={index}>
              {!(item.hide && item.hide(vaccinationPersonChildInfo)) && (
                <>
                  {item?.label && (
                    <Typography className={classes.descriptionTitle}>
                      {item.label}:
                    </Typography>
                  )}
                  {item?.render ? (
                    item.render(vaccinationPersonChildInfo)
                  ) : (
                    <Typography
                      className={classes.descriptionDetails}
                      color="textSecondary"
                    >
                      {item.getVal(vaccinationPersonChildInfo)}
                    </Typography>
                  )}
                </>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }

  function renderButton(button: IButton, index: number) {
    if (buttons && buttons.length > 2) {
      return (
        <Grid item xs key={index} style={button.style}>
          <Button
            key={index}
            onClick={button.onClick}
            variant={button.variant || 'contained'}
            error={button.error}
            color={button.color || 'primary'}
            disabled={button.disabled}
            fullWidth
          >
            {button.icon && button.icon()}
            {button.label}
          </Button>
        </Grid>
      );
    }
    return (
      <Grid item key={index} style={button.style}>
        <Button
          key={index}
          onClick={button.onClick}
          variant={button.variant || 'contained'}
          error={button.error}
          color={button.color || 'primary'}
          disabled={button.disabled}
          fullWidth
        >
          {button.icon && button.icon()}
          {button.label}
        </Button>
      </Grid>
    );
  }
};

export default VaccinationPersonGroupedInfo;
