import React from 'react';

export type ILanguage = 'is' | 'en';

type IContextProps = [
  ILanguage,
  React.Dispatch<React.SetStateAction<ILanguage>>
];

export const LangContext = React.createContext<IContextProps>([
  'is',
  () => null,
]);
