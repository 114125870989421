import React, { useContext, useEffect, useState } from 'react';
import { Grid, useTheme } from '@material-ui/core';

import Layout from '../components/Layout';
import Section from '../components/Section';

import useNotifier from '../hooks/useNotifier';
import LoadingIndicator from '../components/LoadingIndicator';

import ReviewCertificateFilter, {
  IFormValues,
} from '../components/ReviewCertificate/ReviewCertificateFilter';
import ReviewCertificateList from '../components/ReviewCertificate/ReviewCertificateList';

import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

import { TABLE_ROWS_DEFAULT } from '../constants';

import * as certificateReviewService from '../services/certificateReviewService';
import * as localStorageKeys from '../constants/localStorageKeys';
import moment, { Moment } from 'moment';
import { PassengerReviewListItem } from '../models/CertificateReviewModels';
import { IOption } from '../components/Autocomplete';
import reviewCertificateStatuses from '../assets/json/CertificateReview/reviewCertificateStatuses.json';

const ReviewCertificatesPage: React.FC = () => {
  const theme = useTheme();
  const [lang] = useContext(LangContext);

  const { notifyError } = useNotifier();

  const [loadingPersons, setloadingPersons] = useState(false);
  const [initalLoad, setInitialLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [count, setCount] = useState(0);
  const [allPersons, setAllPersons] = useState<
    PassengerReviewListItem[] | null
  >(null);
  const [loadForm, setLoadForm] = useState<IFormValues | null>(null);
  const [formCleared, setFormCleared] = useState(false);
  const defaultReviewStatus = {
    label: reviewCertificateStatuses[0].description[lang],
    value: reviewCertificateStatuses[0].id.toString(),
  };

  const initialForm: IFormValues = {
    search: '',
    reviewStatus: null as IOption | null,
    arrivalFrom: moment().subtract(1, 'day') as Moment | null,
    arrivalTo: moment().add(4, 'day') as Moment | null,
  };

  useEffect(() => {
    const form = localStorage.getItem(
      `${localStorageKeys.CERTIFICATE_REVIEW_FILTER_FORM}`
    );
    const pageNumber = localStorage.getItem(
      `${localStorageKeys.CERTIFICATE_REVIEW_PAGE_NUMBER}`
    );
    const pageSize = localStorage.getItem(
      `${localStorageKeys.CERTIFICATE_REVIEW_PAGE_SIZE}`
    );

    if (pageNumber) {
      setCurrentPage(Number(pageNumber));
    }

    if (pageSize) {
      setRowsPerPage(Number(pageSize));
    }

    if (form) {
      const formJson = JSON.parse(form);

      const formValues: IFormValues = {
        search: formJson?.search || '',
        arrivalFrom: formJson?.arrivalFrom
          ? (moment(formJson.arrivalFrom) as Moment | null)
          : (null as Moment | null),
        arrivalTo: formJson?.arrivalTo
          ? (moment(formJson.arrivalTo) as Moment | null)
          : (null as Moment | null),
        reviewStatus: formJson?.reviewStatus || (null as IOption | null),
      };

      setLoadForm(formValues);
    } else {
      setLoadForm({ ...initialForm, reviewStatus: defaultReviewStatus });
    }
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  useEffect(() => {
    if (!initalLoad) {
      const form = localStorage.getItem(
        `${localStorageKeys.CERTIFICATE_REVIEW_FILTER_FORM}`
      );

      if (form) {
        const formJson = JSON.parse(form);

        const formValues: IFormValues = {
          search: formJson?.search || '',
          arrivalFrom: formJson?.arrivalFrom
            ? (moment(formJson.arrivalFrom) as Moment | null)
            : (null as Moment | null),
          arrivalTo: formJson?.arrivalTo
            ? (moment(formJson.arrivalTo) as Moment | null)
            : (null as Moment | null),
          reviewStatus: formJson?.reviewStatus || (null as IOption | null),
        };

        getPassengers(formValues);
      }
    }
    /* eslint-disable */
  }, [rowsPerPage, currentPage]);
  /* eslint-enable */

  return (
    <Layout useDefaultSpacing title={translations.certificateReview[lang]}>
      <Section>
        {loadForm && (
          <ReviewCertificateFilter
            onSubmit={getPassengers}
            onClear={() => {
              setFormCleared(true);
              setAllPersons(null);
              setCurrentPage(0);
              clearForm();
            }}
            loadForm={loadForm || undefined}
            formCleared={formCleared}
          />
        )}

        {loadingPersons ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: theme.spacing(2) }}
          >
            <LoadingIndicator />
          </Grid>
        ) : (
          allPersons && (
            <ReviewCertificateList
              passengers={allPersons}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              count={count}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setRowsPerPage}
            />
          )
        )}
      </Section>
    </Layout>
  );

  async function getPassengers(values: IFormValues, initialLoad?: boolean) {
    setloadingPersons(true);
    if (!initialLoad) {
      localStorage.setItem(
        `${localStorageKeys.CERTIFICATE_REVIEW_FILTER_FORM}`,
        JSON.stringify(values)
      );
      localStorage.setItem(
        `${localStorageKeys.CERTIFICATE_REVIEW_PAGE_NUMBER}`,
        JSON.stringify(currentPage)
      );
      localStorage.setItem(
        `${localStorageKeys.CERTIFICATE_REVIEW_PAGE_SIZE}`,
        JSON.stringify(rowsPerPage)
      );
    }

    const response = await certificateReviewService.getReviews({
      pageNumber: currentPage + 1,
      pageSize: rowsPerPage,
      searchText: values?.search || undefined,
      reviewStatus: values?.reviewStatus
        ? [Number(values.reviewStatus.value)]
        : undefined,
      arrivalDateFrom: values?.arrivalFrom
        ? values.arrivalFrom.toISOString()
        : undefined,
      arrivalDateTo: values?.arrivalTo
        ? values.arrivalTo.toISOString()
        : undefined,
    });

    if (response.isOk) {
      setAllPersons(response?.data?.items || []);
      setCount(response?.data?.count || 0);
      setloadingPersons(false);
      setFormCleared(false);
      if (initialLoad) {
        setInitialLoad(false);
        setLoadForm(values);
      }
    } else {
      setloadingPersons(false);
      setAllPersons(null);
      notifyError(translations.errorOccurred[lang]);
    }
  }

  function clearForm() {
    setLoadForm(initialForm);
  }
};

export default ReviewCertificatesPage;
