import React, { useState, useContext } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Chip,
} from '@material-ui/core';
import translations from '../../assets/json/translations.json';
import { LangContext } from '../../context/LangContext';
import theme from '../../theme';
import Button from '../Button';
import moment from 'moment';
import templateStrings from '../../assets/json/templateStrings.json';
import Interweave from 'interweave';

interface IProps {
  open: boolean;
  title: string;
  label: string;
  fieldName: string;
  maxLength: number;
  value: string;
  locationDescripton: string;
  userName: string;
  onClose: (confirmed: boolean, newValue: string) => void;
  requiredParams: string;
}
const useStyles = makeStyles((theme) => ({
  editButton: {
    marginLeft: theme.spacing(2),
  },
  preview: {
    fontFamily: 'Helvetica',
  },
  textField: {
    '&>.Mui-error': {
      textAlign: 'right',
    },
    '&>.Mui-disabled': {
      color: 'black',
    },
  },
}));

const UpdateFieldDialog: React.FC<IProps> = ({
  open,
  title,
  label,
  fieldName,
  maxLength,
  value,
  locationDescripton,
  userName,
  onClose,
  requiredParams,
}) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);
  const [updatedValue, setUpdatedFieldValue] = useState(value);
  const [previewValue, setPreviewValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const hasChanged = value !== updatedValue;
  const inputRef = React.useRef();
  const [selectionStart, setSelectionStart] = useState(-1);
  const updateSelectionStart = (
    event: React.SyntheticEvent<HTMLDivElement, Event>
  ) => {
    const target = event.target as HTMLInputElement;
    if (target.selectionStart !== null) {
      setSelectionStart(target.selectionStart);
    }
  };
  const closeDialog = (confirmed: boolean, newValue: string) => {
    if (confirmed) {
      if (validateTemplate(newValue)) {
        setLoading(true);
        onClose(confirmed, newValue);
      }
    } else {
      onClose(confirmed, value);
    }
  };
  const createMarkup = (value: string) => {
    return value;
  };

  const openPreview = () => {
    if (showPreview) {
      setShowPreview(false);
    } else {
      updatePreviewValue(updatedValue);
      setShowPreview(true);
    }
  };
  const updatePreviewValue = (updatedText: string) => {
    var value = updatedText;
    value = value.replace(/{FORNAFN}/g, userName ? userName.split(' ')[0] : '');
    value = value.replace(/{BARCODE}/g, 'EXAMPLEBARCODE123');
    value = value.replace(/{DAGS}/g, moment().format('DD.MM.YYYY'));
    value = value.replace(/{SLOT}/g, moment().format('HH:mm'));
    value = value.replace(/{SLOTFROM}/g, moment().format('HH:mm')); // replace with more current time
    value = value.replace(
      /{SLOTTO}/g,
      moment().add(15, 'minutes').format('HH:mm')
    ); // replace with more current time
    value = value.replace(/{STADSETNING}/g, locationDescripton);
    setPreviewValue(value);
  };
  const addTemplateString = (templateString: string) => {
    var newValue = updatedValue;
    if (
      selectionStart > -1 &&
      updatedValue &&
      updatedValue.length > selectionStart
    ) {
      newValue = `${updatedValue.substr(
        0,
        selectionStart
      )} ${templateString} ${updatedValue.substr(
        selectionStart,
        updatedValue.length
      )}`;
    } else {
      // if the selectedindex is bigger put the templatestring at the end
      newValue = `${updatedValue}${templateString}`;
    }
    if (newValue.length < maxLength) {
      setSelectionStart(selectionStart + templateString.length + 1);
      handleUpdateFieldValue(newValue);
    }
  };
  const hasTemplateString = (value: string) => {
    return updatedValue.includes(value);
  };
  const validateTemplate = (value: string) => {
    var valid = true;
    if (requiredParams && requiredParams.length > 0) {
      var params = requiredParams.split(',');
      for (let index = 0; index < params.length; index++) {
        const param = params[index];
        if (!value.includes(param)) {
          valid = false;
        }
      }
    }
    return valid;
  };
  const createErrorMessage = () => {
    var params = requiredParams.split(',');
    var errorTxt = translations.templateError[lang];
    for (let index = 0; index < params.length; index++) {
      const param = params[index];
      if (!updatedValue.includes(param)) {
        for (let i = 0; i < templateStrings.length; i++) {
          const string = templateStrings[i];
          if (string.parameter === param) {
            errorTxt += string.description[lang] + ',';
          }
        }
      }
    }
    return errorTxt;
  };
  const handleUpdateFieldValue = (value: string) => {
    setUpdatedFieldValue(value);
    updatePreviewValue(value);
  };
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => onClose(false, '')}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ minHeight: 80 }} dividers>
        <Grid container spacing={2} direction="column">
          <React.Fragment>
            <Grid item style={{ marginBottom: '20px' }}>
              {templateStrings.map((string) => {
                return (
                  <Chip
                    label={string.description[lang]}
                    variant={
                      hasTemplateString(string.parameter)
                        ? 'default'
                        : 'outlined'
                    }
                    onClick={() => addTemplateString(string.parameter)}
                  />
                );
              })}
            </Grid>
          </React.Fragment>
        </Grid>
        <Grid container spacing={2} direction="column">
          <Grid item xs={12} sm={12}>
            <TextField
              className={classes.textField}
              fullWidth
              multiline
              autoComplete="off"
              id={fieldName}
              variant="outlined"
              rows={12}
              onSelect={(event) => updateSelectionStart(event)}
              helperText={
                validateTemplate(updatedValue) ? '' : createErrorMessage()
              }
              error={!updatedValue || !validateTemplate(updatedValue)}
              inputProps={{ maxLength: maxLength }}
              label={label}
              value={updatedValue}
              onChange={(e) => handleUpdateFieldValue(e.target.value)}
              inputRef={inputRef}
            />
            <Typography
              color="textSecondary"
              variant="body2"
              style={{ marginTop: theme.spacing(1) }}
            >
              {translations.charactersLeft[lang]}{' '}
              {maxLength - updatedValue.length}
            </Typography>
          </Grid>
        </Grid>
        {showPreview && (
          <>
            <Grid container spacing={2} direction="column">
              <Grid
                item
                xs={12}
                sm={12}
                style={{ marginTop: '40px', marginBottom: '50px' }}
              >
                {fieldName.toLowerCase().includes('email') && (
                  <>
                    <Interweave
                      content={createMarkup(previewValue)}
                      className={classes.preview}
                    />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      style={{ marginTop: theme.spacing(1) }}
                    >
                      {translations.templateInfoText[lang]}
                    </Typography>
                  </>
                )}
                {!fieldName.toLowerCase().includes('email') && (
                  <>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      multiline
                      id={'previewTextField'}
                      variant="outlined"
                      rows={12}
                      value={previewValue}
                      disabled
                    />
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      style={{ marginTop: theme.spacing(1) }}
                    >
                      {translations.templateInfoText[lang]}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={1}
          direction="row"
          alignItems="center"
          justify="flex-end"
        >
          {loading && (
            <Grid item>
              <CircularProgress size={32} color="primary" />
            </Grid>
          )}
          <Grid item>
            <FormControlLabel
              style={{ marginRight: theme.spacing(4) }}
              label={
                showPreview
                  ? translations.previewOff[lang]
                  : translations.previewOn[lang]
              }
              control={
                <Checkbox
                  id="showPreview"
                  color="primary"
                  checked={showPreview}
                  onChange={() => openPreview()}
                />
              }
            />
          </Grid>

          <Grid item>
            <Button
              padding={false}
              onClick={() => closeDialog(true, updatedValue)}
              color="primary"
              variant="contained"
              disabled={
                loading ||
                !updatedValue ||
                !hasChanged ||
                !validateTemplate(updatedValue)
              }
              className={classes.editButton}
            >
              {translations.confirm[lang]}
            </Button>
          </Grid>

          <Grid item>
            <Button
              padding={false}
              color="primary"
              variant="outlined"
              onClick={() => closeDialog(false, updatedValue)}
            >
              {translations.cancel[lang]}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateFieldDialog;
