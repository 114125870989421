import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import moment from 'moment';

import NoResults from './NoResults';
import { VaccinationPerson } from '../models/VaccinationModels';
import {
  TABLE_ROWS_OPTIONS,
  DATE_FORMAT,
  DATE_AND_TIME_FORMAT,
} from '../constants';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

interface IProps {
  members: VaccinationPerson[];
  currentPage: number;
  rowsPerPage: number;
  setCurrentPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
  onClick?: (vaccinationPerson: VaccinationPerson) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
}));

const tableColumns: {
  [key: string]: {
    translation: { is: string; en: string };
    sort?: (a: VaccinationPerson, b: VaccinationPerson) => number;
  };
} = {
  name: {
    translation: translations.name,
    sort: (a: VaccinationPerson, b: VaccinationPerson) =>
      a.name.localeCompare(b.name),
  },
  firstVaccination: {
    translation: translations.firstVaccinationFinishedDate,
    sort: (a: VaccinationPerson, b: VaccinationPerson) => {
      if (!a.firstVaccination.vaccinationDate) {
        return -1;
      }
      if (!b.firstVaccination.vaccinationDate) {
        return 1;
      }
      return moment(a.firstVaccination.vaccinationDate).isAfter(
        b.firstVaccination.vaccinationDate
      )
        ? 1
        : -1;
    },
  },
  secondVaccination: {
    translation: translations.secondVaccinationFinishedDate,
    sort: (a: VaccinationPerson, b: VaccinationPerson) => {
      if (!a.secondVaccination.vaccinationDate) {
        return -1;
      }
      if (!b.secondVaccination.vaccinationDate) {
        return 1;
      }
      return moment(a.secondVaccination.vaccinationDate).isAfter(
        b.secondVaccination.vaccinationDate
      )
        ? 1
        : -1;
    },
  },
  vaccine: {
    translation: translations.vaccine,
    sort: (a: VaccinationPerson, b: VaccinationPerson) =>
      (a.firstVaccination.vaccineName || '').localeCompare(
        b.firstVaccination.vaccineName || ''
      ),
  },
  summoningDate: {
    translation: translations.summoningDate,
    sort: (a: VaccinationPerson, b: VaccinationPerson) => {
      if (!a.summoningDate) {
        return -1;
      }
      if (!b.summoningDate) {
        return 1;
      }
      return moment(a.summoningDate).isAfter(b.summoningDate) ? 1 : -1;
    },
  },
};

const VaccinationSearchPersonList: React.FC<IProps> = ({
  members,
  currentPage,
  rowsPerPage,
  setCurrentPage,
  setRowsPerPage,
  onClick,
}) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);

  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortedMembers, setSortedMembers] = useState<VaccinationPerson[]>([]);
  const startIndex = currentPage * rowsPerPage;

  useEffect(() => {
    if (sortKey && tableColumns[sortKey]) {
      const sorted = tableColumns[sortKey].sort
        ? [...members].sort(tableColumns[sortKey].sort)
        : [...members];
      setSortedMembers(sortDirection === 'asc' ? sorted : sorted.reverse());
    } else {
      setSortedMembers(members);
    }
  }, [members, sortKey, sortDirection]);

  return (
    <Paper elevation={2} className={classes.container}>
      {members.length ? (
        <React.Fragment>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {Object.keys(tableColumns).map((columnKey) => (
                    <TableCell key={columnKey}>
                      {tableColumns[columnKey].sort ? (
                        <TableSortLabel
                          active={sortKey === columnKey}
                          direction={sortDirection}
                          onClick={() => onSortClick(columnKey)}
                        >
                          {tableColumns[columnKey].translation[lang]}
                        </TableSortLabel>
                      ) : (
                        tableColumns[columnKey].translation[lang]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedMembers
                  .slice(startIndex, startIndex + rowsPerPage)
                  .map((member) => (
                    <TableRow
                      hover={!!onClick}
                      key={member.serialNumber}
                      style={{ cursor: onClick ? 'pointer' : undefined }}
                      onClick={() => onClick && onClick(member)}
                    >
                      <TableCell>{member.name}</TableCell>

                      <TableCell>
                        {member.firstVaccination.vaccinationDate
                          ? moment(
                              member.firstVaccination.vaccinationDate
                            ).format(DATE_FORMAT)
                          : ''}
                      </TableCell>
                      <TableCell>
                        {member.secondVaccination.vaccinationDate
                          ? moment(
                              member.secondVaccination.vaccinationDate
                            ).format(DATE_FORMAT)
                          : ''}
                      </TableCell>
                      <TableCell>
                        {member.firstVaccination.vaccineName}
                      </TableCell>
                      <TableCell>
                        {member.summoningDate
                          ? moment(member.summoningDate).format(
                              DATE_AND_TIME_FORMAT
                            )
                          : ''}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={translations.numberOfRowsPerPage[lang]}
            rowsPerPageOptions={TABLE_ROWS_OPTIONS}
            component="div"
            count={members.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={(_, p) => setCurrentPage(p)}
            onChangeRowsPerPage={(e) => {
              setCurrentPage(0);
              setRowsPerPage(parseInt(e.target.value, 10));
            }}
          />
        </React.Fragment>
      ) : (
        <NoResults message={translations.noVaccinationPersonFound[lang]} />
      )}
    </Paper>
  );

  function onSortClick(columnKey: string) {
    if (sortKey === columnKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(columnKey);
      setSortDirection('asc');
    }
  }
};

export default VaccinationSearchPersonList;
