import React, { useContext } from 'react';
import Layout from '../components/Layout';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import useNotifier from '../hooks/useNotifier';

const NotFoundPage: React.FC = () => {
  const { notifyError } = useNotifier();

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  const [lang] = useContext(LangContext);
  return (
    <Layout useDefaultSpacing title={translations.pageNotFound[lang]}></Layout>
  );
};
export default NotFoundPage;
