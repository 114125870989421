import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Integrations } from '@sentry/tracing';

import Store from './store/Store';

// only enable sentry on dev for now
if (process.env.NODE_ENV === 'development') {
  Sentry.init({
    dsn: 'https://51dc8f0b3d8f47d8812fdfad0d2573de@o466194.ingest.sentry.io/5997417',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Store>
    <App />
  </Store>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
