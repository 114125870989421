import React, { useContext, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Paper, useTheme, TextField } from '@material-ui/core';
import { Formik, Form, FormikProps } from 'formik';
import moment, { Moment } from 'moment';

import Layout from '../components/Layout';
import Section from '../components/Section';
import LoadingIndicator from '../components/LoadingIndicator';
import DatePicker from '../components/DatePicker';
import Button from '../components/Button';
import NoResults from '../components/NoResults';
import QuarantinePassengerList from '../components/QuarantinePassengerList';
import QuarantinePassengerDetails from '../components/QuarantinePassengerDetails';
import Autocomplete, { IOption } from '../components/Autocomplete';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import useNotifier from '../hooks/useNotifier';
import * as httpService from '../services/httpService';
import { ResidencePerson, ResidenceSearchQuery } from '../models/Models';
import { TABLE_ROWS_DEFAULT } from '../constants';
import {
  IResidenceLocation,
  IResidenceStatus,
} from '../interfaces/residenceLocation';
import { ITranslation } from '../interfaces/translation';
import residenceStatuses from '../assets/json/residenceStatuses.json';

export interface IFormValues {
  barcode: string;
  name: string;
  dateOfBirth: Moment | null;
  flightNumber: string;
  location: IOption | null;
  status: IOption | null;
  arrivalDateFrom: Moment | null;
  arrivalDateTo: Moment | null;
}

type IFormik = FormikProps<IFormValues>;

interface IResidenceStatusExt extends IResidenceStatus {
  description: ITranslation;
}

const ResidenceSearchPage: React.FC = () => {
  const [lang] = useContext(LangContext);
  const history = useHistory();
  const theme = useTheme();
  const barcodeInput = useRef<HTMLInputElement>(null);
  const { notifyError } = useNotifier();

  const [residencePersons, setResidencePersons] = useState<ResidencePerson[]>(
    []
  );
  const [selectedPerson, setSelectedPerson] = useState<ResidencePerson | null>(
    null
  );
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_DEFAULT);
  const [currentPage, setCurrentPage] = useState(0);
  const [locations, setLocations] = useState<IResidenceLocation[] | null>(null);
  const [statuses, setStatuses] = useState<IResidenceStatusExt[] | null>(null);
  const tableColumns = getTableColumns();

  useEffect(() => {
    const fetchInfo = async () => {
      const locationPromise = httpService.getResidenceLocations();
      const statusPromise = httpService.getResidenceStatuses();
      const [{ data: locationData }, { data: statusData }] = await Promise.all([
        locationPromise,
        statusPromise,
      ]);
      const availableStatuses: {
        [id: string]: ITranslation;
      } = {};
      residenceStatuses.forEach(
        (s) => (availableStatuses[s.id] = s.description)
      );

      setLocations(locationData || []);
      setStatuses(
        statusData
          ? statusData
              .filter((s) => availableStatuses[s.id])
              .map((s) => ({
                ...s,
                description: availableStatuses[s.id],
              }))
          : []
      );
    };
    fetchInfo();
  }, []);

  return (
    <Layout
      useDefaultSpacing
      title={translations.searchTitle[lang]}
      backButtonClick={() => history.push('/quarantine-house')}
    >
      <Formik
        initialValues={getInitValues()}
        onSubmit={(values) => searchForResidencePersons(values)}
      >
        {(formik) => (
          <Section>
            <Grid container spacing={3}>
              <Grid item sm={12} md={4}>
                {renderSearchForm(formik)}
              </Grid>
              <Grid item sm={12} md={8} style={{ flex: 1 }}>
                {renderResults(formik)}
              </Grid>
            </Grid>
          </Section>
        )}
      </Formik>
    </Layout>
  );

  function renderSearchForm(formik: IFormik) {
    return (
      <Form onSubmit={formik.handleSubmit}>
        <Paper elevation={2} style={{ padding: theme.spacing(3) }}>
          <Grid container spacing={2}>
            {renderFormFields(formik)}
            {renderButtons(formik)}
          </Grid>
        </Paper>
      </Form>
    );
  }

  function renderResults({ values, isSubmitting, submitCount }: IFormik) {
    if (isSubmitting) {
      return (
        <Grid container justify="center" alignItems="center">
          <LoadingIndicator />
        </Grid>
      );
    }

    if (selectedPerson) {
      return (
        <QuarantinePassengerDetails
          residencePerson={selectedPerson}
          back={() => setSelectedPerson(null)}
          reloadSelectedPassenger={async () => {
            await searchForResidencePersons(values, selectedPerson.id);
          }}
        />
      );
    }

    if (!residencePersons.length && submitCount > 0) {
      return <NoResults />;
    }

    return residencePersons.length ? (
      <QuarantinePassengerList
        topMargin={false}
        members={residencePersons}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        setCurrentPage={setCurrentPage}
        setRowsPerPage={setRowsPerPage}
        onClick={setSelectedPerson}
        tableColumns={tableColumns}
        tableColumnsKeys={Object.keys(tableColumns).map(
          (columnKey) => columnKey
        )}
      />
    ) : null;
  }

  function renderFormFields({
    values,
    handleChange,
    handleBlur,
    setFieldValue,
  }: IFormik) {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <TextField
            fullWidth
            autoFocus
            autoComplete="off"
            id="barcode"
            label={translations.serialNumber[lang]}
            variant="outlined"
            inputProps={{ ref: barcodeInput }}
            value={values.barcode}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <TextField
            fullWidth
            autoComplete="off"
            id="name"
            label={translations.name[lang]}
            variant="outlined"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <DatePicker
            id="dateOfBirth"
            label={translations.dateOfBirth[lang]}
            value={values.dateOfBirth}
            muiProps={{ disableFuture: true }}
            onChange={(e) => setFieldValue('dateOfBirth', e ? moment(e) : null)}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <TextField
            fullWidth
            id="flightNumber"
            autoComplete="off"
            label={translations.flightNumber[lang]}
            variant="outlined"
            value={values.flightNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <Autocomplete
            id="location"
            fullWidth
            value={values.location}
            label={
              locations
                ? translations.location[lang]
                : translations.loadingLocations[lang]
            }
            items={(locations || []).map((l) => ({
              value: l.id.toString(),
              label: l.name,
            }))}
            onChange={(val) => setFieldValue('location', val)}
            disabled={!locations}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <Autocomplete
            id="status"
            fullWidth
            value={values.status}
            label={
              statuses
                ? translations.status[lang]
                : translations.loadingStatusOptions[lang]
            }
            items={(statuses || []).map((s) => ({
              value: s.id.toString(),
              label: s.description[lang],
            }))}
            onChange={(val) => setFieldValue('status', val)}
            disabled={!statuses}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <DatePicker
            id="arrivalDateFrom"
            label={translations.arrivalsFrom[lang]}
            value={values.arrivalDateFrom}
            muiProps={{
              disableToolbar: true,
              maxDate: values.arrivalDateFrom || undefined,
            }}
            onChange={(e) =>
              setFieldValue('arrivalDateFrom', e ? moment(e) : null)
            }
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <DatePicker
            id="arrivalDateTo"
            label={translations.arrivalsTo[lang]}
            value={values.arrivalDateTo}
            muiProps={{
              disableToolbar: true,
              minDate: values.arrivalDateTo || undefined,
            }}
            onChange={(e) =>
              setFieldValue('arrivalDateTo', e ? moment(e) : null)
            }
            onBlur={handleBlur}
          />
        </Grid>
      </React.Fragment>
    );
  }

  function renderButtons({ handleReset, isSubmitting }: IFormik) {
    const clearForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleReset(e);
      setResidencePersons([]);
      setSelectedPerson(null);
      focusBarcodeInput();
    };

    return (
      <React.Fragment>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={isSubmitting}
          >
            {translations.search[lang]}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth variant="outlined" onClick={clearForm}>
            {translations.clear[lang]}
          </Button>
        </Grid>
      </React.Fragment>
    );
  }

  function getInitValues(): IFormValues {
    return {
      barcode: '',
      name: '',
      flightNumber: '',
      dateOfBirth: null,
      location: null,
      status: null,
      arrivalDateFrom: null,
      arrivalDateTo: null,
      // arrivalDateFrom: moment().subtract(1, 'days'),
      // arrivalDateTo: moment().add(1, 'days'),
    };
  }

  function getTableColumns(): {
    [key: string]: {
      translation: ITranslation;
      sort?: (a: ResidencePerson, b: ResidencePerson) => number;
    };
  } {
    return {
      name: {
        translation: translations.name,
        sort: (a: ResidencePerson, b: ResidencePerson) =>
          a.name.localeCompare(b.name),
      },
      ssn: {
        translation: translations.dateOfBirth,
      },

      arrivalDate: {
        translation: translations.arrivalDate,
        sort: (a: ResidencePerson, b: ResidencePerson) => {
          if (!a.dateOfArrival) {
            return -1;
          }
          if (!b.dateOfArrival) {
            return 1;
          }
          return moment(a.dateOfArrival).isAfter(b.dateOfArrival) ? 1 : -1;
        },
      },
      status: {
        translation: translations.status,
        sort: (a: ResidencePerson, b: ResidencePerson) => {
          if (!a.residenceStatus) {
            return -1;
          }
          if (!b.residenceStatus) {
            return 1;
          }
          return a.residenceStatus.id
            .toString()
            .localeCompare(b.residenceStatus.id.toString());
        },
      },
    };
  }

  function focusBarcodeInput() {
    if (barcodeInput && barcodeInput.current) {
      barcodeInput.current.focus();
    }
  }

  async function searchForResidencePersons(
    values: IFormValues,
    selectedId?: number
  ) {
    const query = new ResidenceSearchQuery(values);
    const response = await httpService.searchForResidencePersons(query);
    const updatedSelectedPerson = selectedId
      ? response.data.find((p) => p.id === selectedId) || null
      : null;

    setResidencePersons(response.data);
    setSelectedPerson(updatedSelectedPerson);

    if (!response.isOk) {
      if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.errorOccurred[lang]);
      }
    }
  }
};

export default ResidenceSearchPage;
