import React, { useContext } from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import HomePage from './pages/HomePage';
import SamplingPage from './pages/SamplingPage';
import SymptomSamplingPage from './pages/SymptomSamplingPage';
import SearchPage from './pages/SearchPage';
// import BorderControlPage from './pages/BorderControlPage';
import UserControlPage from './pages/UserControlPage';
import CreatePassengerPage from './pages/CreatePassengerPage';
import PrintLabelsPage from './pages/PrintLabelsPage';
import VaccinationHomePage from './pages/VaccinationHomePage';
import VaccinationPage from './pages/VaccinationPage';
/*import CreateVaccinationPersonPage from './pages/CreateVaccinationPersonPage'; */
import VaccinationSearchPage from './pages/VaccinationSearchPage';
import VaccinationGroupsPage from './pages/VaccinationGroupsPage';
import AddVaccinationGroupPage from './pages/AddVaccinationGroupPage';
import EditVaccinationGroupPage from './pages/EditVaccinationGroupPage';
import VaccinationGroupDetailsPage from './pages/VaccinationGroupDetailsPage';
import GroupVaccinationPage from './pages/GroupVaccinationPage';
import GroupVaccinationDetailsPage from './pages/GroupVaccinationDetailsPage';
import VaccinationOverviewPage from './pages/VaccinationOverviewPage';
import LotNumberControlPage from './pages/LotNumberControlPage';
import SummonAcrossGroupsPage from './pages/SummonAcrossGroupsPage';
import QuarantineHousePage from './pages/QuarantineHousePage';
import QuarantinePassengerListPage from './pages/QuarantinePassengerListPage';
import QuarantinePassengerListOldPage from './pages/QuarantinePassengerListOldPage';
import QuarantineResidencePage from './pages/QuarantineResidencePage';
import ResidenceSearchPage from './pages/ResidenceSearchPage';
import HighRiskCountriesPage from './pages/HighRiskCountriesPage';
import BorderControlSamplingPage from './pages/BorderControlSamplingPage';
import VaccinationWaitingListPage from './pages/VaccinationWaitingListPage';
import { UserContext } from './context/UserContext';
import { AccessItem } from './models/Models';
import { accessControl } from './constants/enums';
import { hasRapidTestAccess, hasSymptomSamplingAccess } from './utils';
import RapidTestSamplingPage from './pages/RapidTestSamplingPage';
import HelpPage from './pages/HelpPage';
import RegisterForVaccinePage from './pages/RegisterForVaccinePage';
import LocationSettingsPage from './pages/LocationSettingsPage';
import LocationSettingsMenuPage from './pages/LocationSettingsMenuPage';
import ReviewCertificatesPage from './pages/ReviewCertificatesPage';
import ReviewPassengerCertificatesPage from './pages/ReviewPassengerCertificatesPage';
import SchedulePage from './pages/SchedulePage';

const Routes = () => {
  const [context] = useContext(UserContext);
  const samplingRoutes = context?.loginType !== 'vaccination';

  return samplingRoutes ? getSamplingRouter() : getVaccinationRouter();

  function isAccessible(id: number) {
    const item = context?.accessList?.find((item: AccessItem) => {
      return item.id === id;
    });
    return item !== undefined;
  }

  function getSamplingRouter() {
    let quarantineHouseIndex = 0;
    let reviewCertificateIndex = 0;
    let scheduleSettingsIndex = 0;

    return (
      <BrowserRouter>
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Route path="/login" exact component={LoginPage} />
          {isAccessible(accessControl.TIME_COP) && (
            <Route
              path="/location-settings"
              exact
              component={LocationSettingsPage}
            />
          )}
          {(isAccessible(accessControl.SUPER_ADMIN) ||
            isAccessible(accessControl.TIME_COP)) && [
            <Route
              key={scheduleSettingsIndex++}
              path="/location-settings-menu/:system"
              exact
              component={LocationSettingsMenuPage}
            />,
            <Route
              key={scheduleSettingsIndex++}
              path="/schedule/:locationId"
              exact
              component={SchedulePage}
            />,
          ]}
          <Route path="/home" exact component={HomePage} />
          {isAccessible(accessControl.SAMPLING) && (
            <Route path="/sampling" exact component={SamplingPage} />
          )}
          {isAccessible(accessControl.QUARANTINE_CONTROL) && (
            <Route
              path="/border-control-sampling"
              exact
              component={BorderControlSamplingPage}
            />
          )}
          {isAccessible(accessControl.SAMPLING) &&
            hasSymptomSamplingAccess(context) && (
              <Route
                path="/symptom-sampling"
                exact
                component={SymptomSamplingPage}
              />
            )}
          {isAccessible(accessControl.SAMPLING) &&
            hasRapidTestAccess(context) && (
              <Route
                path="/rapid-test-sampling"
                exact
                component={RapidTestSamplingPage}
              ></Route>
            )}
          {isAccessible(accessControl.SEARCH) && (
            <Route path="/search" exact component={SearchPage} />
          )}
          {isAccessible(accessControl.SEARCH) && (
            <Route path="/search/:id" exact component={SearchPage} />
          )}
          {/* isAccessible(accessControl.QUARANTINE_CONTROL) && (
            <Route path="/border-control" exact component={BorderControlPage} />
          ) */}
          {isAccessible(accessControl.SEARCH) && (
            <Route
              path="/new-passenger"
              exact
              component={CreatePassengerPage}
            />
          )}
          {isAccessible(accessControl.SUPER_ADMIN) && (
            <Route path="/access-control" exact component={UserControlPage} />
          )}
          {isAccessible(accessControl.SAMPLING) &&
            hasSymptomSamplingAccess(context) && (
              <Route path="/print-labels" exact component={PrintLabelsPage} />
            )}
          {isAccessible(accessControl.HIGH_RISK_COUNTRIES) && (
            <Route
              path="/high-risk-countries"
              exact
              component={HighRiskCountriesPage}
            />
          )}
          <Route path="/help" exact component={HelpPage} />
          {/*  {isAccessible(accessControl.SAMPLING) && (
            <Route path="/pcr-desk" exact component={PCRDeskPage} />
          )} */}
          {isAccessible(accessControl.QUARANTINE_HOTEL) && [
            <Route
              key={quarantineHouseIndex++}
              path="/quarantine-house"
              exact
              component={QuarantineHousePage}
            />,
            <Route
              key={quarantineHouseIndex++}
              path="/quarantine-house-rooms"
              exact
              component={QuarantineHousePage}
            />,
            <Route
              key={quarantineHouseIndex++}
              path="/quarantine-house-individuals"
              exact
              component={QuarantineHousePage}
            />,
            <Route
              key={quarantineHouseIndex++}
              path="/quarantine-house-checkout"
              exact
              component={QuarantineHousePage}
            />,
            <Route
              key={quarantineHouseIndex++}
              path="/quarantine-passenger-list/:locationId"
              exact
              component={QuarantinePassengerListPage}
            />,
            <Route
              key={quarantineHouseIndex++}
              path="/quarantine-passenger-list-old/:locationId"
              exact
              component={QuarantinePassengerListOldPage}
            />,
            <Route
              key={quarantineHouseIndex++}
              path="/quarantine-residences/:locationId"
              exact
              component={QuarantineResidencePage}
            />,
            <Route
              key={quarantineHouseIndex++}
              path="/quarantine-house/search"
              exact
              component={ResidenceSearchPage}
            />,
          ]}
          {isAccessible(accessControl.CERTIFICATE_REVIEW) && [
            <Route
              key={reviewCertificateIndex++}
              path="/certificate-review"
              exact
              component={ReviewCertificatesPage}
            />,
            <Route
              key={reviewCertificateIndex++}
              path="/certificate-review/:id"
              exact
              component={ReviewPassengerCertificatesPage}
            />,
          ]}
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    );
  }

  function getVaccinationRouter() {
    return (
      <BrowserRouter>
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Route path="/login" exact component={LoginPage} />
          <Route path="/home" exact component={VaccinationHomePage} />
          {isAccessible(accessControl.VACCINATION) && (
            <Route path="/vaccination" exact component={VaccinationPage} />
          )}
          {isAccessible(accessControl.VACCINATION_SEARCH) && (
            <Route path="/search" exact component={VaccinationSearchPage} />
          )}
          {/*<Route
            path="/new-vaccination-person"
            exact
            component={CreateVaccinationPersonPage}
          /> */}
          {isAccessible(accessControl.VACCINATION_GROUP) && (
            <Route
              path="/group-vaccination"
              exact
              component={GroupVaccinationPage}
            />
          )}
          {isAccessible(accessControl.VACCINATION_GROUP) && (
            <Route
              path="/group-vaccination/:groupId"
              exact
              component={GroupVaccinationDetailsPage}
            />
          )}
          {isAccessible(accessControl.VACCINATION_GROUP_MANAGEMENT) && (
            <Route path="/groups" exact component={VaccinationGroupsPage} />
          )}
          {isAccessible(accessControl.VACCINATION_GROUP_MANAGEMENT) && (
            <Route
              path="/groups/new"
              exact
              component={AddVaccinationGroupPage}
            />
          )}
          {isAccessible(accessControl.VACCINATION_GROUP_MANAGEMENT) && (
            <Route
              path="/groups/:groupId"
              exact
              component={VaccinationGroupDetailsPage}
            />
          )}
          {isAccessible(accessControl.VACCINATION_GROUP_MANAGEMENT) && (
            <Route
              path="/groups/:groupId/edit"
              exact
              component={EditVaccinationGroupPage}
            />
          )}
          {isAccessible(accessControl.SUPER_ADMIN) && (
            <Route path="/access-control" exact component={UserControlPage} />
          )}
          {isAccessible(accessControl.SUPER_ADMIN) && (
            <Route path="/lot-control" exact component={LotNumberControlPage} />
          )}
          {(isAccessible(accessControl.VACCINATION) ||
            isAccessible(accessControl.VACCINATION_GROUP_MANAGEMENT)) && (
            <Route
              path="/vaccination-overview"
              exact
              component={VaccinationOverviewPage}
            />
          )}
          {isAccessible(accessControl.VACCINATION_GROUP_MANAGEMENT) && (
            <Route
              path="/summon-across-groups"
              exact
              component={SummonAcrossGroupsPage}
            />
          )}
          {isAccessible(accessControl.VACCINATION_GROUP_MANAGEMENT) && (
            <Route
              path="/register-for-vaccine"
              exact
              component={RegisterForVaccinePage}
            />
          )}
          {isAccessible(accessControl.VACCINATION_GROUP_MANAGEMENT) && (
            <Route
              path="/vaccination-waiting-list"
              exact
              component={VaccinationWaitingListPage}
            />
          )}
          {isAccessible(accessControl.SUPER_ADMIN) && (
            <Route
              path="/location-settings-menu/:system"
              exact
              component={LocationSettingsMenuPage}
            />
          )}
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    );
  }
};

export default Routes;
