import { POSResponse } from '../interfaces/posResponse';
import { Passenger } from '../models/Models';

export class CefSharpShell {
  protected CefSharp: any;
  public isShellMode: boolean;

  constructor() {
    this.CefSharp = (window as any).CefSharp;
    if (process.env.REACT_APP_POS_CONNECTED === 'true') {
      this.CefSharp = {
        PostMessage: function (obj: any) {
          obj.Callback({ success: true, statusText: 'OK' });
        },
      };
    }
    this.isShellMode = this.CefSharp !== undefined;
  }

  checkPosConnection(
    successCallback: (isConnected: boolean) => void,
    failCallback: (errorText: string) => void
  ) {
    if (this.isShellMode) {
      this.CefSharp.PostMessage({
        Type: 'PosCheckConnection',
        Callback: (reply: POSResponse) => {
          if (!reply.success) {
            failCallback(reply.statusText);
          }
          successCallback(reply.success);
        },
      });
    }
  }

  isPOSConfigured(successCallback: (shouldHavePos: boolean) => void) {
    if (this.isShellMode) {
      this.CefSharp.PostMessage({
        Type: 'PosCheckConfigured',
        Callback: (reply: POSResponse) => {
          if (reply.success) {
            successCallback(true);
          }
        },
      });
    }
  }

  chargeCard(
    amount: number,
    successCallback: (reply: POSResponse) => void,
    failCallback: (statusText: string) => void
  ) {
    if (this.isShellMode) {
      this.CefSharp.PostMessage({
        Type: 'Purchase',
        Amount: amount,
        Callback: (reply: POSResponse) => {
          if (!reply.success) {
            failCallback(reply.statusText);
          } else {
            successCallback(reply);
          }
        },
      });
    }
  }

  printLabel(passenger: Passenger | null) {
    if (this.isShellMode) {
      this.CefSharp.PostMessage({
        Type: 'PrintLabel',
        LabelBarcode: passenger?.sampleId,
        Callback: () => {},
      });
    }
  }

  printBarcode(barcode: string) {
    if (this.isShellMode) {
      this.CefSharp.PostMessage({
        Type: 'PrintLabel',
        LabelBarcode: barcode,
        Callback: () => {},
      });
    }
  }

  sendPOSBatch(
    successCallBack: (reply: POSResponse) => void,
    failCallback: (reply: POSResponse) => void
  ) {
    if (this.isShellMode) {
      this.CefSharp.PostMessage({
        Type: 'PosSendBatch',
        Callback: (reply: POSResponse) => {
          if (reply.success) {
            successCallBack(reply);
          } else {
            failCallback(reply);
          }
        },
      });
    }
  }
}
