import React, { useContext } from 'react';
import { makeStyles, Typography, useTheme } from '@material-ui/core';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Error';
import ErrorIcon from '@material-ui/icons/Cancel';
import { childVaccinationCustodianPosition } from '../../../constants/enums';
import translations from '../../../assets/json/translations.json';
import { LangContext } from '../../../context/LangContext';

interface IProps {
  status: number;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginBottom: -6,
  },
  infoValue: {
    color: theme.palette.text.secondary,
    marginLeft: 10,
  },
}));

const ChildVaccinationPosition: React.FC<IProps> = ({ status }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [lang] = useContext(LangContext);

  if (status === childVaccinationCustodianPosition.UNKNOWN) {
    return (
      <Typography component={'span'} style={{ position: 'relative' }}>
        <WarningIcon
          className={classes.icon}
          style={{ color: theme.palette.warning.light }}
        />
        <span className={classes.infoValue}>
          {translations.haveNotTakenAPosition[lang]}
        </span>
      </Typography>
    );
  }
  if (
    status === childVaccinationCustodianPosition.NOT_NOW ||
    status === childVaccinationCustodianPosition.NO
  ) {
    return (
      <Typography component={'span'} style={{ position: 'relative' }}>
        <ErrorIcon
          className={classes.icon}
          style={{ color: theme.palette.error.main }}
        />
        <span className={classes.infoValue}>
          {translations.cannotBeVaccinated[lang]}
        </span>
      </Typography>
    );
  }
  return (
    <Typography component={'span'} style={{ position: 'relative' }}>
      <SuccessIcon
        className={classes.icon}
        style={{ color: theme.palette.success.main }}
      />
      <span className={classes.infoValue}>
        {translations.canBeVaccinated[lang]}
      </span>
    </Typography>
  );
};

export default ChildVaccinationPosition;
