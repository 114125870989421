// a slimmer version of the httpService that uses the api wrapper in api.ts, will have to wait a bit before we use this as this file contains almost 70 functions that
import {
  Passenger,
  User,
  SearchQuery,
  CreatePassenger,
  CreateUser,
  UserItem,
  Transaction,
  SamplingSchedule,
  Location,
  Residence,
  ResidencePerson,
  CheckInModel,
  ResidenceSearchQuery,
  FlowStatus,
  ErrorInfo,
  NationalRegistryPerson,
  INationalRegistryLookup,
  ISolationStatus,
  CertificateVariables,
  CertificateType,
} from '../models/Models';
import {
  IGetResidenceListBySearchQuery,
  ISendCustomCertificate,
  ISendPositiveCertificateBasedOnBarcode,
  IUpdatePassengerInfo,
} from '../interfaces/passenger';
import { IHttpAnswer } from '../interfaces/httpAnswer';
import * as versionFile from '../version.json';
import {
  IResidenceLocation,
  IResidenceStatus,
} from '../interfaces/residenceLocation';
import { IUpdateQuarantinePassengerInfo } from '../interfaces/quarantinePassenger';
import { IRiskCountry } from '../interfaces/riskCountry';
import { IQrCodeCertificate } from '../interfaces/qrCertificate';
import api from '../api';
import flowStatusesCompleted from '../assets/json/flowStatusesCompleted.json';

const API_URL = process.env.REACT_APP_API_URL;

async function login(
  mobile: string,
  location: string
): Promise<IHttpAnswer<User>> {
  const version = `${versionFile.major}.${versionFile.minor}`;
  const loginRequest = {
    phoneNumber: mobile,
    location: location,
    version: version,
  };
  const response = await fetch(`${API_URL}/Authentication/GetUserAccess`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(loginRequest),
  });
  if (response.ok) {
    const data = await response.json();
    return { data: data, isOk: response.ok, statusCode: response.status };
  }
  const data = await response.json();
  return { data: data, isOk: response.ok, statusCode: response.status };
}

async function logout(): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Authentication/InvalidateCurrentSession`,
    null
  );
  return { data: true, isOk: response.isOk, statusCode: response.statusCode };
}
async function getLoginLocations(): Promise<IHttpAnswer<Location[]>> {
  const response = await api.get(`/Authentication/GetLoginLocations`);
  if (response.isOk) {
    const locations = response.data.locations.map(
      (location: any) => new Location(location)
    );
    return {
      data: locations,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return response;
}
async function checkIfLocationHasAnyTimeSlots(locationId : number): Promise<IHttpAnswer<boolean>> {
  const response = await api.get(
    `/Passenger/CheckIfLocationHasAnyTimeSlots?locationId=${locationId}`
  );
  return { data: response.data, isOk: response.isOk, statusCode: response.statusCode };
}



async function markSampleTaken(
  sampleId: string,
  numberOfChildren: number
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(`/Passenger/SampleTaken/${sampleId}`, {
    numberOfChildren,
  });
  if (response.isOk) {
    return { data: true, isOk: response.isOk, statusCode: response.statusCode };
  }
  return {
    data: false,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function confirmChild(barCode: string): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(`/Passenger/ChildVerified/${barCode}`, null);
  if (response.isOk) {
    return { data: true, isOk: response.isOk, statusCode: response.statusCode };
  }
  return { data: false, isOk: response.isOk, statusCode: response.statusCode };
}

async function declineChild(barCode: string): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/ChildNotVerified/${barCode}`,
    null
  );
  if (response.isOk) {
    return { data: true, isOk: response.isOk, statusCode: response.statusCode };
  }
  return { data: false, isOk: response.isOk, statusCode: response.statusCode };
}

async function confirmQuarantine(
  barCode: string,
  numberOfChildren: number
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(`/Passenger/QuarantineAccepted/${barCode}`, {
    numberOfChildren,
  });
  if (response.isOk) {
    return { data: true, isOk: response.isOk, statusCode: response.statusCode };
  }
  return { data: false, isOk: response.isOk, statusCode: response.statusCode };
}

async function declineQuarantine(
  barCode: string,
  skipPayment: boolean = false
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/QuarantineDeclined/${barCode}/${skipPayment}`,
    null
  );
  if (response.isOk) {
    return { data: true, isOk: response.isOk, statusCode: response.statusCode };
  }
  return { data: false, isOk: response.isOk, statusCode: response.statusCode };
}

async function confirmExemptPassenger(
  barCode: string,
  numberOfChildren: number
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/ConfirmExemptPassenger/${barCode}`,
    { numberOfChildren }
  );
  if (response.isOk) {
    return { data: true, isOk: response.isOk, statusCode: response.statusCode };
  }
  return { data: false, isOk: response.isOk, statusCode: response.statusCode };
}

async function declineExemptPassenger(
  barCode: string,
  skipPayment: boolean = false
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/DeclineExemptPassenger/${barCode}/${skipPayment}`,
    null
  );
  if (response.isOk) {
    return { data: true, isOk: response.isOk, statusCode: response.statusCode };
  }
  return { data: false, isOk: response.isOk, statusCode: response.statusCode };
}

async function skipPaymentForPassenger(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/SkipPaymentForPassenger/${barCode}`,
    null
  );
  if (response.isOk) {
    return { data: true, isOk: response.isOk, statusCode: response.statusCode };
  }
  return { data: false, isOk: response.isOk, statusCode: response.statusCode };
}

async function searchPassenger(
  query: SearchQuery
): Promise<IHttpAnswer<Array<Passenger>>> {
  const passengerList = new Array<Passenger>();

  const response = await api.post(`/Passenger/SearchForPassenger`, query);
  if (response.statusCode === 200) {
    const list: Array<Passenger> = await response.data;
    list.map((item) => passengerList.push(new Passenger(item)));
  }
  return {
    data: passengerList,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getPassengerBySampleId(
  sampleId: string
): Promise<IHttpAnswer<Passenger | null>> {
  const response = await api.get(
    `/Passenger/GetPassengerByObservationId/${sampleId}`
  );
  if (response.isOk) {
    return {
      data: new Passenger(response.data),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }

  return { data: null, isOk: response.isOk, statusCode: response.statusCode };
}

async function getPassengerByRegistrationId(
  barCode: string
): Promise<IHttpAnswer<Passenger | null>> {
  const response = await api.get(
    `/Passenger/GetPassengerByRegistrationId/${barCode}`
  );
  if (response.isOk) {
    return {
      data: new Passenger(response.data),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return { data: null, isOk: response.isOk, statusCode: response.statusCode };
}

async function getPassengersPayments(
  barCode: string
): Promise<IHttpAnswer<Transaction[] | null>> {
  const response = await api.post(
    `/Passenger/GetPassengerPayments/${barCode}`,
    null
  );
  if (response.isOk) {
    const newData = response.data.map(
      (payment: Transaction) => new Transaction(payment)
    );
    return {
      data: newData,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return { data: null, isOk: response.isOk, statusCode: response.statusCode };
}

async function createPassenger(passenger: CreatePassenger) {
  const response = await api.post(`/Passenger/CreatePassenger`, passenger);

  if (response.isOk) {
    return {
      data: response.data,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return { data: null, isOk: response.isOk, statusCode: response.statusCode };
}

async function postSamplingResult(barcode: string, result: number) {
  const response = await api.post(`/Passenger/PostSamplingResult`, {
    barcode,
    result,
  });

  if (response.isOk) {
    return {
      data: true,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return { data: null, isOk: response.isOk, statusCode: response.statusCode };
}

async function updateTravelDetails(barCode: string, text: string) {
  const response = await api.post(
    `/Passenger/UpdateTravelDetails/${barCode}`,
    text
  );

  if (response.isOk) {
    return {
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return { isOk: response.isOk, statusCode: response.statusCode };
}

async function unpaidSampleNeededToQuarantine(barCode: string) {
  const response = await api.post(
    `/Passenger/UnpaidSampleNeededToQuarantine/${barCode}`,
    null
  );

  if (response.isOk) {
    return {
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return { isOk: response.isOk, statusCode: response.statusCode };
}

async function checkIfSecondTestIsAllowed(barCode: string) {
  const response = await api.get(
    `/Passenger/CheckIfSecondTestIsAllowed/${barCode}`
  );

  if (response.isOk) {
    return {
      data: await response.data,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return { data: null, isOk: response.isOk, statusCode: response.statusCode };
}

async function sendToSecondSampling(barCode: string) {
  const response = await api.get(`/Passenger/SendToSecondTest/${barCode}`);

  if (response.isOk) {
    return {
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return { isOk: response.isOk, statusCode: response.statusCode };
}

async function createUser(ssn: string, user: CreateUser) {
  const response = await api.post(`/Admin/AddUser/${ssn}`, user);

  if (response.isOk) {
    return {
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return { isOk: response.isOk, statusCode: response.statusCode };
}

async function editUser(ssn: string, user: CreateUser) {
  const response = await api.put(`/Admin/UpdateUser/${ssn}`, user);

  if (response.isOk) {
    return {
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return { isOk: response.isOk, statusCode: response.statusCode };
}

async function getAllUsers(): Promise<IHttpAnswer<Array<UserItem>>> {
  const userList = new Array<UserItem>();

  const response = await api.get(`/Admin/GetUsers`);
  if (response.statusCode === 200) {
    const data: { users: any[] } = response.data;
    data.users.map((item) => userList.push(new UserItem(item)));
  }
  return {
    data: userList,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function deleteSelectedUser(ssn: string) {
  const response = await api.delete(`/Admin/DeleteUser/${ssn}`);

  if (response.isOk) {
    return {
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return { isOk: response.isOk, statusCode: response.statusCode };
}

async function getPrintLabels(
  numberOfLabels: number
): Promise<IHttpAnswer<string[]>> {
  const response = await api.get(`/Passenger/GetSequence/${numberOfLabels}`);

  return {
    data: response.isOk ? response.data : [],
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function updatePrePrinted(barCode: string, samplingNumber: string) {
  const response = await api.post(
    `/Passenger/UpdatePrePrinted/${samplingNumber}/${barCode}`,
    null
  );

  if (response.isOk) {
    return {
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  const data = response.data;
  return { data: data, isOk: response.isOk, statusCode: response.statusCode };
}

async function getSymptomSamplingSchedule(
  arrivalDate: string,
  selectedLocation: string
) {
  const response = await api.post(
    `/Passenger/GetSamplingArrivalsInfo/${selectedLocation}`,
    arrivalDate
  );

  return {
    data: response.isOk ? new SamplingSchedule(response.data) : null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getSymptomSamplingScheduleLocations() {
  const response = await api.get(`/Passenger/GetSamplingArrivalTypes`, true);
  if (response.isOk) {
    const data = response.data;
    return {
      data: response.isOk
        ? data.arrivalTypes.length > 0
          ? data.arrivalTypes
              .map((item: any) => {
                return {
                  label: item.locationDescription,
                  value: item.locationId,
                  loginLocations: item.loginLocations,
                  hasSchedule : item.hasSchedule
                };
              })
              .sort((a: any, b: any) => (a.label > b.label ? 1 : -1))
          : null
        : null,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }

  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function changeToRegisteredWithCertificate(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/ChangeToValidCertificate/${barCode}`,
    null
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function declineCertificate(
  barCode: string,
  newStatus: number,
  isBorderControl: boolean = false
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/ValidCertificateDeclined/${barCode}/${newStatus}?isBorderControl=${isBorderControl}`,
    null
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function acceptPCRPositivePreviousCertificate(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/CertificatePCRPositivePreviousAccepted/${barCode}`,
    {}
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function acceptAntibodyCertificate(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/CertificateAntibodiesAccepted/${barCode}`,
    {}
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function acceptVaccinationCertificate(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/CertificateVaccinationAccepted/${barCode}`,
    {}
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function acceptOtherCertificateOrDocuemnts(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/CertificateOrDocumentAccepted/${barCode}`,
    {}
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function changeToTransit(barCode: string): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/ChangeToInTransit/${barCode}`,
    null
  );
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function confirmTransit(barCode: string): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/InTransitAccepted/${barCode}`,
    {}
  );
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function declineTransit(
  barCode: string,
  newStatus: number
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/InTransitDeclined/${barCode}/${newStatus}`,
    null
  );
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function confirmFifthDayTesting(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/FifthDayTestingAccepted/${barCode}`,
    {}
  );
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function changeNegPcrCertificateStatus(
  barCode: string,
  newStatus: number
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/ChangeNegPcrCertificateStatus/${barCode}`,
    { newStatus }
  );
  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function rejectNegPCRCertificate(
  barCode: string,
  comment: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/RejectNegPCRCertificateWithReason?barCode=${barCode}`,
    comment
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function acceptNegPCRCertificate(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/AcceptNegPCRCertificate/${barCode}`,
    null
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function notProvidedNegPCRCertificate(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/NotProvidedNegPCRCertificate/${barCode}`,
    null
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function overruleNegPCRCertificateRejected(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/OverruleNegPCRCertificateRejected/${barCode}`,
    null
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function updateResidence(
  barCode: string,
  residence: Partial<Residence>
): Promise<IHttpAnswer<boolean>> {
  const response = await api.put(
    `/Passenger/UpdateResidence/${barCode}`,
    residence
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function updatePassengerInfo(
  barCode: string,
  updatedInfo: IUpdatePassengerInfo
) {
  const response = await api.put(
    `/Passenger/UpdatePassengerInfo/${barCode}`,
    updatedInfo
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getIsFifthDaySamplingDisabled() {
  const response = await api.get(`/ApiHealth/DisableFifthDaySampling`);

  if (response.isOk) {
    const data = response.data;
    return {
      data: response.isOk ? Boolean(data.disable) : false,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }

  return {
    data: false,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getResidenceLocationsAdmin(): Promise<
  IHttpAnswer<Array<IResidenceLocation> | null>
> {
  const response = await api.get(`/Admin/GetResidenceLocations`);

  if (response.isOk) {
    const data = response.data;
    return {
      data: data.locations,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getResidenceListBySearch(
  query: IGetResidenceListBySearchQuery
): Promise<IHttpAnswer<ResidencePerson[] | null>> {
  const response = await api.post(`/Residence/GetResidenceListBySearch`, query);
  if (response.statusCode === 204) {
    return {
      data: [],
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((p: any) => new ResidencePerson(p)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function setQuarantineHouseStatus(barCode: string, registered: boolean) {
  const response = await api.post(
    `/Passenger/SetQuarantineResidenceStatus?barcode=${barCode}&mustQurantineInResidence=${registered}`,
    null
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function markResidencesAsExported(
  ids: number[]
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(`/Residence/MarkResidencesAsExported`, ids);

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function checkInResidences(
  locationId: string,
  roomNumber: string,
  ids: CheckInModel[]
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Residence/checkIn/${locationId}/${roomNumber}`,
    ids
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getCheckedInResidences(
  locationId: string
): Promise<IHttpAnswer<ResidencePerson[] | null>> {
  const response = await api.get(`/Residence/GetCheckedIn/${locationId}`);

  if (response.isOk) {
    const data = response.data;
    return {
      data: data.map((p: any) => new ResidencePerson(p)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function checkOutResidences(
  locationId: string,

  ids: number[]
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(`/Residence/checkOut/${locationId}`, ids);

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function updateResidencePerson(
  personId: string,
  body: IUpdateQuarantinePassengerInfo
): Promise<IHttpAnswer<boolean>> {
  const response = await api.put(
    `/Residence/UpdateResidencePerson/?residencePersonId=${personId}`,
    body
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function searchForResidencePersons(
  query: ResidenceSearchQuery
): Promise<IHttpAnswer<ResidencePerson[]>> {
  const response = await api.post(`/Residence/LookupResidencePerson`, query);

  if (response.statusCode === 200) {
    const data = response.data;
    return {
      data: data.map((p: any) => new ResidencePerson(p)),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: [],
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getResidenceLocations(): Promise<
  IHttpAnswer<IResidenceLocation[] | null>
> {
  const response = await api.get(`/Residence/GetLocations`);

  if (response.isOk) {
    const data = response.data;
    return {
      data: data,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getResidenceStatuses(): Promise<
  IHttpAnswer<IResidenceStatus[] | null>
> {
  const response = await api.get(`/Residence/GetStatuses`);

  if (response.isOk) {
    const data = response.data;
    return {
      data: data,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getHighRiskCountries(): Promise<IHttpAnswer<IRiskCountry[]>> {
  const response = await api.get(`/Admin/GetCountries`);

  if (response.isOk) {
    const data = response.data;
    return {
      data: data.sort((a: IRiskCountry, b: IRiskCountry) =>
        a.name.localeCompare(b.name)
      ),
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: [],
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function updateRiskCountryStatus(
  countryCode: string,
  newStatus: number
): Promise<IHttpAnswer<boolean>> {
  const response = await api.put(`/Admin/UpdateCountryQurantineStatus`, {
    countryCode,
    newStatus,
  });

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function checkoutPassenger(
  barCode: string,
  sendToQuarantineHotel?: boolean,
  comment?: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(`/Passenger/CheckoutPassenger`, {
    barCode,
    sendToQuarantineHotel,
    comment,
  });

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

// TODO: rename IRiskCountry?
async function getCountryCodeList(): Promise<IHttpAnswer<IRiskCountry[]>> {
  const response = await api.get(`/Passenger/GetCountryCodeList`);

  if (response.isOk) {
    const data = response.data;
    return {
      data,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: [],
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function verifyQrCodeCertificate(
  barCode: string,
  qrCode: string
): Promise<IHttpAnswer<IQrCodeCertificate | null>> {
  try {
    const response = await api.post(`/Passenger/VerifyQrCodeCertificate`, {
      barcode: barCode,
      qrCode: qrCode,
    });
    return {
      data: response.isOk ? response.data : null,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  } catch {
    return {
      data: null,
      isOk: false,
      statusCode: 400,
    };
  }
}

async function verifyQrPCRCertificate(
  barCode: string,
  qrCode: string
): Promise<IHttpAnswer<IQrCodeCertificate | null>> {
  try {
    const response = await api.post(`/Passenger/VerifyQrPCRCertificate`, {
      barcode: barCode,
      qrCode: qrCode,
    });

    return {
      data: response.isOk ? response.data : null,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  } catch {
    return {
      data: null,
      isOk: false,
      statusCode: 400,
    };
  }
}

async function sendCertificateViaEmail(
  barCode: string,
  email: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/SendCertificateManuallyToEmail?barcode=${barCode}&email=${email}`,
    null
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function sendRecoveryCertificateViaEmail(
  barCode: string,
  email: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/SendRecoveryCertificateManuallyToEmail`,
    { barCode, email }
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function sendPositiveCertificateViaEmail(
  barCode: string,
  email: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/SendPositiveCertificateManuallyToEmail?barcode=${barCode}&email=${email}`,
    null
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function acceptPartialCertificateVaccination(
  barCode: string,
  numberOfChildren: number
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/PartialCertificateVaccinationAccepted/${barCode}`,
    { numberOfChildren }
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function updateObservationIdentifierToTa(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/UpdateObservationIdentifierToTa?barcode=${barCode}`,
    null
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getPassengerDetailFlowStatusByBarCode(
  barCode: string
): Promise<IHttpAnswer<FlowStatus[]>> {
  const res = await api.get(
    `/Passenger/GetPassengerDetailFlowStatusByBarCode/${barCode}`
  );
  if (res.isOk && res.data && res.data.length) {
    const list = res.data
      .filter((x: any) => x.status)
      .map((x: any) => {
        const id = x.status;
        const task = flowStatusesCompleted.find((s: any) => s.id === id);
        const description = task ? task.description : { is: '', en: '' };
        return {
          id: id,
          description: description,
          descriptionDetails: { is: '', en: '' },
          createdDate: x.created,
          changeByName: x.changeByName,
          changeBySsn: x.changeBySsn,
        } as FlowStatus;
      });
    return {
      data: list,
      isOk: res.isOk,
      statusCode: res.statusCode,
    };
  } else {
    return {
      data: [],
      isOk: res.isOk,
      statusCode: res.statusCode,
    };
  }
}

async function setPreCheckedPassengerToManualConfirmationPath(
  barCode: string
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/SetPreCheckedPassengerToManualConfirmationPath?barcode=${barCode}`,
    null
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function lookupInNationalRegistry(
  lookup: INationalRegistryLookup
): Promise<IHttpAnswer<NationalRegistryPerson | ErrorInfo>> {
  const response = await api.post(`/NationalRegistry/LookupPerson`, lookup);

  if (response.isOk && response.data.length > 0) {
    const data = response.data[0];

    if (data.isValid) {
      return {
        data: new NationalRegistryPerson(data),
        isOk: response.isOk,
        statusCode: response.statusCode,
      };
    } else {
      return {
        data: new ErrorInfo(404, data.errorMessage),
        isOk: false,
        statusCode: 404,
      };
    }
  }
  return {
    data: new ErrorInfo(response.statusCode, 'Something went wrong'),
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function canPrintBarCode(barCode: string): Promise<IHttpAnswer<boolean>> {
  const response = await api.get(`/Passenger/CanPrint/${barCode}`, false);

  if (response.isOk) {
    return {
      data: response.data.canPrint,
      isOk: response.isOk,
      statusCode: response.statusCode,
    };
  }
  return {
    data: false,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getIsolationStatus(
  barCode: string
): Promise<IHttpAnswer<ISolationStatus>> {
  const response = await api.get(
    `/Passenger/GetIsolationStatus/${barCode}`,
    false
  );

  return {
    data: new ISolationStatus(response.data),
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function getCustomCertificateDefaultVariables(
  barCode: string
): Promise<IHttpAnswer<CertificateVariables>> {
  const response = await api.get(
    `/Passenger/GetCustomCertificateDefaultVariablesForBarcode?barcode=${barCode}`,
    false
  );

  return {
    data: new CertificateVariables(response.data),
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function sendCustomCertificate(
  payload: ISendCustomCertificate
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(`/Passenger/SendCustomCertificate`, payload);

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function sendCertificateBasedOnBarcode(
  payload: ISendPositiveCertificateBasedOnBarcode
): Promise<IHttpAnswer<boolean>> {
  const response = await api.post(
    `/Passenger/SendCertificateBasedOnBarcode`,
    payload
  );

  return {
    data: response.isOk,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function checkIfCertificateAvailableBasedOnBarcode(
  barCode: string
): Promise<IHttpAnswer<CertificateType>> {
  const response = await api.get(
    `/Passenger/CheckIfCertificateAvailableBasedOnBarcode?barcode=${barCode}`,
    false
  );

  return {
    data: new CertificateType(response.data),
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

async function checkCustomCertificateSendType(
  payload: ISendCustomCertificate
): Promise<IHttpAnswer<CertificateType>> {
  const response = await api.post(
    `/Passenger/CheckCustomCertificateSendType`,
    payload
  );

  return {
    data: new CertificateType(response.data),
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

export {
  login,
  logout,
  getLoginLocations,
  searchPassenger,
  getPassengerBySampleId,
  markSampleTaken,
  getPassengerByRegistrationId,
  getPassengersPayments,
  confirmChild,
  declineChild,
  confirmQuarantine,
  declineQuarantine,
  confirmExemptPassenger,
  declineExemptPassenger,
  createPassenger,
  postSamplingResult,
  createUser,
  editUser,
  getAllUsers,
  deleteSelectedUser,
  skipPaymentForPassenger,
  unpaidSampleNeededToQuarantine,
  updateTravelDetails,
  checkIfSecondTestIsAllowed,
  sendToSecondSampling,
  getPrintLabels,
  updatePrePrinted,
  getSymptomSamplingSchedule,
  getSymptomSamplingScheduleLocations,
  changeToRegisteredWithCertificate,
  declineCertificate,
  acceptPCRPositivePreviousCertificate,
  acceptAntibodyCertificate,
  acceptVaccinationCertificate,
  acceptOtherCertificateOrDocuemnts,
  changeToTransit,
  confirmTransit,
  declineTransit,
  confirmFifthDayTesting,
  changeNegPcrCertificateStatus,
  rejectNegPCRCertificate,
  acceptNegPCRCertificate,
  notProvidedNegPCRCertificate,
  overruleNegPCRCertificateRejected,
  updateResidence,
  updatePassengerInfo,
  getIsFifthDaySamplingDisabled,
  getResidenceListBySearch,
  getResidenceLocationsAdmin,
  markResidencesAsExported,
  setQuarantineHouseStatus,
  checkInResidences,
  getCheckedInResidences,
  checkOutResidences,
  updateResidencePerson,
  searchForResidencePersons,
  getResidenceLocations,
  getResidenceStatuses,
  getHighRiskCountries,
  updateRiskCountryStatus,
  checkoutPassenger,
  getCountryCodeList,
  verifyQrCodeCertificate,
  verifyQrPCRCertificate,
  sendCertificateViaEmail,
  sendPositiveCertificateViaEmail,
  sendRecoveryCertificateViaEmail,
  acceptPartialCertificateVaccination,
  updateObservationIdentifierToTa,
  getPassengerDetailFlowStatusByBarCode,
  setPreCheckedPassengerToManualConfirmationPath,
  lookupInNationalRegistry,
  canPrintBarCode,
  getIsolationStatus,
  getCustomCertificateDefaultVariables,
  sendCustomCertificate,
  sendCertificateBasedOnBarcode,
  checkIfCertificateAvailableBasedOnBarcode,
  checkCustomCertificateSendType,
  checkIfLocationHasAnyTimeSlots
};
