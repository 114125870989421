import React, {
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  Paper,
  Grid,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import debounce from 'lodash.debounce';
import fuzzysort from 'fuzzysort';

import Button from './Button';
import Dropdown from './Dropdown';
import { LangContext } from '../context/LangContext';
import { UserContext } from '../context/UserContext';
import translations from '../assets/json/translations.json';
import { VaccinationGroup } from '../models/VaccinationModels';
import * as localStorageKeys from '../constants/localStorageKeys';

interface IProps {
  groups: VaccinationGroup[];
  localStorageKey: string;
  filterGroups: (members: VaccinationGroup[]) => void;
  setRegionSelected: (onlyOne: boolean) => void;
}

const ALL_REGIONS_KEY = 'all_regions';

const VaccinationGroupFilter: React.FC<IProps> = ({
  groups,
  localStorageKey,
  filterGroups,
  setRegionSelected,
}) => {
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const searchRef = useRef<HTMLInputElement>();

  const [searchInput, setSearchInput] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [searchInputFilter, setSearchInputFilter] = useState('');
  const debouncedSearchUpdate = useCallback(
    debounce(setSearchInputFilter, 500),
    []
  );

  const hasOnlyOneRegion = user?.vaccinationRegionModelList?.length === 1;
  const userRegions = getUserRegions();

  useEffect(() => {
    const oneRegion = user?.vaccinationRegionModelList?.length === 1;
    setSelectedRegion(
      oneRegion
        ? user?.vaccinationRegionModelList[0].id.toString()
        : ALL_REGIONS_KEY
    );
  }, [user]);

  useEffect(() => {
    const searchResults = searchInputFilter
      ? fuzzysort
          .go(searchInputFilter, groups, { keys: ['name', 'description'] })
          .map((s) => s.obj)
      : groups;
    const filtered = searchResults.filter(
      (group) =>
        selectedRegion === ALL_REGIONS_KEY ||
        group.vaccinationRegion.id === selectedRegion
    );
    filterGroups(filtered);
  }, [groups, filterGroups, searchInputFilter, selectedRegion]);

  useEffect(() => {
    setRegionSelected(!!selectedRegion && selectedRegion !== ALL_REGIONS_KEY);
  }, [selectedRegion, setRegionSelected]);

  useEffect(() => {
    const savedSearch: string =
      localStorage.getItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_GROUP_FILTER_SEARCH}`
      ) || '';
    const savedRegion: string =
      localStorage.getItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_GROUP_FILTER_REGION}`
      ) || '';
    const availableRegions = user.vaccinationRegionModelList
      .filter((r: any) => !!r.id)
      .map((r: any) => r.id.toString());

    setSearchInput(savedSearch);
    setSearchInputFilter(savedSearch);
    if (savedRegion && availableRegions.includes(savedRegion)) {
      setSelectedRegion(savedRegion);
    }
    setFiltersLoaded(true);
  }, [localStorageKey, user.vaccinationRegionModelList]);

  useEffect(() => {
    if (filtersLoaded) {
      localStorage.setItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_GROUP_FILTER_SEARCH}`,
        searchInputFilter
      );
      localStorage.setItem(
        `${localStorageKey}${localStorageKeys.VACCINATION_GROUP_FILTER_REGION}`,
        selectedRegion
      );
    }
  }, [filtersLoaded, localStorageKey, searchInputFilter, selectedRegion]);

  return (
    <Grid>
      <Paper elevation={2} style={{ padding: theme.spacing(2) }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ marginBottom: theme.spacing(1) }}
        >
          <Grid item>
            <Typography variant="h6">
              {translations.filterGroups[lang]}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid
            item
            xs={smDown ? 12 : undefined}
            sm={smDown ? 12 : undefined}
            style={{ flex: !smDown ? 1 : undefined }}
          >
            <TextField
              fullWidth
              autoFocus
              autoComplete="off"
              id="search"
              variant="outlined"
              inputRef={searchRef}
              label={translations.searchTitle[lang]}
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
                debouncedSearchUpdate(e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            xs={smDown ? 12 : undefined}
            sm={smDown ? 9 : undefined}
            style={{ flex: !smDown ? 1 : undefined }}
          >
            <Dropdown
              fullWidth
              id="regions"
              value={selectedRegion}
              label={translations.region[lang]}
              items={userRegions}
              onChange={(e) => setSelectedRegion(e.target.value as string)}
              disabled={hasOnlyOneRegion}
            />
          </Grid>
          <Grid item xs={smDown ? 12 : undefined} sm={smDown ? 3 : undefined}>
            <Button
              fullWidth={smDown}
              variant="outlined"
              onClick={clearFilters}
            >
              {translations.clear[lang]}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );

  function getUserRegions() {
    let regions = !hasOnlyOneRegion
      ? [{ label: translations.allRegions[lang], value: ALL_REGIONS_KEY }]
      : [];

    if (user?.vaccinationRegionModelList) {
      regions = regions.concat(
        user.vaccinationRegionModelList.map((r: any) => ({
          label: r.description || '',
          value: r.id ? r.id.toString() : '',
        }))
      );
    }

    return regions;
  }

  function clearFilters() {
    setSearchInput('');
    setSearchInputFilter('');
    setSelectedRegion(
      hasOnlyOneRegion
        ? user?.vaccinationRegionModelList[0].id.toString()
        : ALL_REGIONS_KEY
    );
    focusSearchInput();
  }

  function focusSearchInput() {
    if (searchRef && searchRef.current) {
      searchRef.current.focus();
    }
  }
};

export default VaccinationGroupFilter;
