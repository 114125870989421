import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Paper,
  TextField,
  FormControlLabel,
  Radio,
  useTheme,
} from '@material-ui/core';
import moment from 'moment';
import { Formik, Form, FormikProps } from 'formik';
import PrintIcon from '@material-ui/icons/Print';
import { useHistory } from 'react-router-dom';

import Button from './Button';
import ChildCounter from './ChildCounter';
import Section from './Section';
import ConfirmDialog from './ConfirmDialog';
import PassengerInfo, { IButton } from './PassengerInfo';
import NoResults from './NoResults';
import PreScreeningStatus from './PreScreeningStatus';
import { IPassengerDetails } from '../interfaces/passenger';
import * as httpService from '../services/httpService';
import * as httpService2 from '../services/httpService.v2';
import posService from '../services/posService';
import { Passenger, Transaction } from '../models/Models';
import useNotifier from '../hooks/useNotifier';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import {
  accessControl,
  borderControlStatus,
  preScreeningStatus,
} from '../constants/enums';
import {
  canSearchForPassenger,
  canSearchForPassengerRapidCode,
  hasRapidTestAccess,
  isAccessible,
  isNegPCRTestMandatory,
  isReadyForSampling,
  showPCRStatus,
  updateObservationIdentifierCheck,
} from '../utils';
import LoadingIndicator from './LoadingIndicator';
import { CefSharpShell } from '../helpers/CefSharpShell';
import { PosContext } from '../context/PosContext';
import { UserContext } from '../context/UserContext';
import SamplingStatus from './SamplingStatus';
import { DATE_FORMAT } from '../constants';
import CopyButton from './CopyButton';

interface IProps {
  passenger: Passenger | null;
  setCurrentPassenger: (passenger: Passenger | null) => void;
  backDialogOpen: boolean;
  closeBackDialog: () => void;
  isRapidTestSampling: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paperLeft: {
      padding: theme.spacing(3),
      textAlign: 'center',
    },
    paperRapidTest: {
      padding: theme.spacing(3),
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    printIcon: {
      paddingRight: theme.spacing(1),
    },
    paper: {
      flex: 1,
      padding: theme.spacing(3),
      margin: theme.spacing(3),
      textAlign: 'center',
      maxWidth: theme.dimensions.boxWidth,
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(15),
      },
    },
  })
);

export interface IFormValues {
  samplingNumber: string;
}

type IFormik = FormikProps<IFormValues>;
type IPCRRejectReason = 'not_valid' | 'not_provided';

const Sampling: React.FC<IProps> = ({
  passenger,
  setCurrentPassenger,
  backDialogOpen,
  closeBackDialog,
  isRapidTestSampling,
}) => {
  const [shouldHavePos] = useContext(PosContext);
  const shellHelper = new CefSharpShell();
  const classes = useStyles({});
  const { notifySuccess, notifyError } = useNotifier();
  const idInput = useRef<HTMLInputElement>(null);
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const [printingAllowed, setPrintingAllowed] = useState<boolean>(false);
  const history = useHistory();
  const theme = useTheme();

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [isPosConnected, setIsPosConnected] = useState<boolean | null>(false);
  const [declineChildDialogOpen, setDeclineChildDialogOpen] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
  const [convertToPCRTestDialogOpen, setConvertToPCRTestDialogOpen] =
    useState(false);
  const [declineExemptDialogOpen, setDeclineExemptDialogOpen] = useState(false);
  const [clearInputDialogOpen, setClearInputDialogOpen] = useState(false);
  const [skipPaymentDialogOpen, setSkipPaymentDialogOpen] = useState(false);
  const [printDialogOpen, setPrintDialogOpen] = useState(false);
  const [declineCertificateDialogOpen, setDeclineCertificateDialogOpen] =
    useState(false);
  const [declineTransitDialogOpen, setDeclineTransitDialogOpen] =
    useState(false);
  const [noResult, setNoResult] = useState(false);
  const [childCount, setChildCount] = useState(0);
  const [rejectPCRTestDialogOpen, setRejectPCRTestDialogOpen] = useState(false);
  const [rejectPCRTestReason, setRejectPCRTestReason] =
    useState<IPCRRejectReason | null>(null);

  const pcrAccepted =
    !!passenger &&
    (passenger.preScreeningStatus.id ===
      preScreeningStatus.NEGATIVE_PCR_TEST_ACCEPTED ||
      passenger.preScreeningStatus.id ===
        preScreeningStatus.NEGATIVE_PCR_TEST_OVERRULED);
  const pcrRejected =
    !!passenger &&
    (passenger.preScreeningStatus.id ===
      preScreeningStatus.NEGATIVE_PCR_TEST_REJECTED ||
      passenger.preScreeningStatus.id ===
        preScreeningStatus.NEGATIVE_PCR_TEST_NOT_PROVIDED);

  useEffect(() => {
    async function getCanPrintBarCodeStatus(passenger: Passenger) {
      const result = await httpService2.canPrintBarCode(passenger.serialNumber);
      setPrintingAllowed(result.data);
    }
    if (!passenger) {
      focusIdInput();
    } else {
      getCanPrintBarCodeStatus(passenger);
    }
  }, [passenger]);

  const childDetails: IPassengerDetails[][] = [
    [
      {
        label: translations.lastName[lang],
        getVal(passenger: Passenger) {
          return passenger.lastName;
        },
      },
      {
        label: translations.firstNames[lang],
        getVal(passenger: Passenger) {
          return passenger.firstName;
        },
      },
      {
        label: translations.dateOfBirth[lang],
        getVal(passenger: Passenger) {
          return passenger.dateOfBirth
            ? moment(passenger.dateOfBirth).format(DATE_FORMAT)
            : translations.notRegistered[lang];
        },
      },
      {
        label: translations.numberOfChildren[lang],
        render: (passenger: Passenger) => (
          <ChildCounter
            count={childCount}
            setCount={setChildCount}
            disabled={!isReadyForSampling(passenger)}
          />
        ),
        hide: (passenger: Passenger) => {
          return (
            !(
              isReadyForSampling(passenger) ||
              passenger.borderControlStatus.id ===
                borderControlStatus.SAMPLING_COMPLETED
            ) ||
            (isNegPCRTestMandatory(user, passenger) && !pcrAccepted)
          );
        },
      },
    ],
    [
      {
        label: translations.statusInTheProcess[lang],
        render: (passenger: Passenger) => (
          <SamplingStatus
            status={passenger.borderControlStatus}
            sampleTaken={passenger.sampleTaken}
          />
        ),
      },
      {
        label: translations.serialNumber[lang],
        getVal(passenger: Passenger) {
          return passenger.serialNumber;
        },
      },
      {
        label: translations.samplingNumber[lang],
        getVal(passenger: Passenger) {
          return passenger.sampleId || translations.samplingNumberMissing[lang];
        },
      },
      {
        label: translations.PCRStatus[lang],
        render: (passenger: Passenger) => (
          <PreScreeningStatus status={passenger.preScreeningStatus} />
        ),
        hide: (passenger: Passenger) => !showPCRStatus(user, passenger),
      },
      {
        label: translations.attention[lang],
        getVal() {
          return translations.rapidTestNotValid[lang];
        },
        hide: (passenger: Passenger) =>
          (!hasRapidTestAccess(user) && !passenger.ainSamplingBarcode) ||
          (!!hasRapidTestAccess(user) && !passenger.ainSamplingBarcode) ||
          !!canSearchForPassengerRapidCode(user),
      },
      {
        label: translations.rapidTestBarcode[lang],
        render(passenger: Passenger) {
          return (
            <React.Fragment>
              <Typography
                style={{ display: 'inline-block' }}
                color="textSecondary"
              >
                {passenger?.ainSamplingBarcode || ''}
              </Typography>
              <CopyButton text={passenger?.ainSamplingBarcode || ''} />
            </React.Fragment>
          );
        },
        hide: (passenger: Passenger) =>
          (!hasRapidTestAccess(user) && !passenger.ainSamplingBarcode) ||
          (!!hasRapidTestAccess(user) && !passenger.ainSamplingBarcode) ||
          !!canSearchForPassengerRapidCode(user),
      },
    ],
    [
      {
        label: translations.arrivalDate[lang],
        getVal(passenger: Passenger) {
          return moment(passenger.dateOfArrival).format(DATE_FORMAT);
        },
      },
    ],
  ];

  const renderSearch = (formik: IFormik) => {
    return (
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5">
              {translations.inputSerialNumber[lang]}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              autoFocus
              autoComplete="off"
              id={'samplingNumber'}
              label={translations.serialNumber[lang]}
              variant="outlined"
              inputProps={{ ref: idInput }}
              value={formik.values.samplingNumber}
              onChange={formik.handleChange}
              disabled={!!passenger}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              type="submit"
              disabled={formik.isSubmitting || !formik.dirty}
            >
              {translations.searchTitle[lang]}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              onClick={(e) => {
                if (
                  isReadyForSampling(passenger) &&
                  !(
                    isNegPCRTestMandatory(user, new Passenger(passenger)) &&
                    pcrRejected
                  )
                ) {
                  setClearInputDialogOpen(true);
                } else {
                  clearSearch(formik);
                }
              }}
            >
              {translations.clear[lang]}
            </Button>
          </Grid>
        </Grid>
      </Form>
    );
  };

  const renderRapidRedirect = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6">
            {translations.registerPersonToRapidTest[lang]}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            color="secondary"
            padding={false}
            onClick={() => history.push('/new-passenger?rapid=true')}
          >
            {translations.registerPerson[lang]}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderDialogs = (formik: IFormik) => {
    return (
      <React.Fragment>
        <ConfirmDialog
          loading={loadingUpdate}
          open={declineChildDialogOpen}
          title={translations.sendToSampling[lang]}
          text={`${translations.sendToSamplingText1[lang]} ${passenger?.lastName}, ${passenger?.firstName} ${translations.sendToSamplingText2[lang]}`}
          onConfirm={() => {
            setLoadingUpdate(true);
            httpService
              .declineChild(passenger?.serialNumber as string)
              .then((response) => {
                if (response.isOk) {
                  notifySuccess(translations.sendToSamplingConfirmation[lang]);
                  setDeclineChildDialogOpen(false);
                  setLoadingUpdate(false);
                  clearSearch(formik);
                } else if (response.statusCode === 401) {
                  notifyError(translations.status401[lang]);
                  history.push('/login');
                } else {
                  notifyError(translations.operationFailed[lang]);
                  setLoadingUpdate(false);
                }
              })
              .catch(() => {
                notifyError(translations.operationFailed[lang]);
                setLoadingUpdate(false);
              });
          }}
          onCancel={() => setDeclineChildDialogOpen(false)}
          confirmText={translations.sendToSampling[lang]}
        />
        <ConfirmDialog
          loading={loadingUpdate}
          open={declineDialogOpen}
          title={translations.sendToSampling[lang]}
          text={`${translations.sendToSamplingText1[lang]} ${passenger?.lastName}, ${passenger?.firstName} ${translations.sendToSamplingText2[lang]}`}
          onConfirm={() => declineQuarantine(passenger, formik)}
          onCancel={() => setDeclineDialogOpen(false)}
          confirmText={translations.sendToSampling[lang]}
        />
        <ConfirmDialog
          loading={loadingUpdate}
          open={declineExemptDialogOpen}
          title={translations.sendToSampling[lang]}
          text={`${translations.sendToSamplingText1[lang]} ${passenger?.lastName}, ${passenger?.firstName} ${translations.sendToSamplingText2[lang]}`}
          onConfirm={() => declineExempt(passenger, formik)}
          onCancel={() => setDeclineExemptDialogOpen(false)}
          confirmText={translations.sendToSampling[lang]}
        />
        <ConfirmDialog
          open={clearInputDialogOpen}
          title={translations.clearInputDialogTitle[lang]}
          text={translations.clearInputDialogText[lang]}
          onConfirm={() => {
            clearSearch(formik);
            setClearInputDialogOpen(false);
          }}
          onCancel={() => setClearInputDialogOpen(false)}
          confirmText={translations.clear[lang]}
        />
        <ConfirmDialog
          loading={loadingUpdate}
          open={skipPaymentDialogOpen}
          title={translations.skipPayment[lang]}
          text={`${translations.skipPaymentConfirmation[lang]}: ${passenger?.lastName}, ${passenger?.firstName}?`}
          onConfirm={() => skipPayment(passenger, formik)}
          onCancel={() => setSkipPaymentDialogOpen(false)}
          confirmText={translations.skipPayment[lang]}
        />
        <ConfirmDialog
          open={backDialogOpen}
          title={translations.backToMenu[lang]}
          text={translations.backDialogText[lang]}
          confirmText={translations.back[lang]}
          onConfirm={() => history.push('/home')}
          onCancel={closeBackDialog}
        />
        <ConfirmDialog
          loading={loadingUpdate}
          open={declineCertificateDialogOpen}
          title={translations.declineCertificate[lang]}
          text={translations.declineCertificateDialog[lang]}
          onConfirm={() => declineCertificate(passenger, formik)}
          onCancel={() => setDeclineCertificateDialogOpen(false)}
        />
        <ConfirmDialog
          loading={loadingUpdate}
          open={declineTransitDialogOpen}
          title={translations.declineTransit[lang]}
          text={translations.declineTransitDialog[lang]}
          onConfirm={() => declineTransit(passenger, formik)}
          onCancel={() => setDeclineTransitDialogOpen(false)}
        />
        <ConfirmDialog
          open={printDialogOpen}
          error={true}
          title={translations.printSamplingNumber[lang]}
          text={translations.printSamplingNumberAgain[lang]}
          onConfirm={() => {
            shellHelper.printLabel(passenger);
            setPrintDialogOpen(false);
          }}
          onCancel={() => setPrintDialogOpen(false)}
          cancelText={translations.no[lang]}
          confirmText={translations.yes[lang]}
        />
        <ConfirmDialog
          loading={loadingUpdate}
          open={rejectPCRTestDialogOpen}
          disableConfirm={!rejectPCRTestReason}
          title={translations.rejectNegativePCRTest[lang]}
          text={translations.rejectNegativePCRTestDialogText[lang]}
          onConfirm={() => {
            if (passenger && rejectPCRTestReason) {
              rejectPCRTestReason === 'not_valid'
                ? rejectNegativePCRTest(passenger, formik)
                : notProvidedNegativePCRTest(passenger, formik);
            }
          }}
          onCancel={() => setRejectPCRTestDialogOpen(false)}
        >
          <FormControlLabel
            label={translations.rejectPCRNotValid[lang]}
            style={{ color: theme.palette.text.secondary, display: 'flex' }}
            control={
              <Radio
                color="primary"
                checked={rejectPCRTestReason === 'not_valid'}
                onChange={(_, val) =>
                  setRejectPCRTestReason(val ? 'not_valid' : null)
                }
              />
            }
          />
          <FormControlLabel
            label={translations.rejectPCRNotProvided[lang]}
            style={{ color: theme.palette.text.secondary, display: 'flex' }}
            control={
              <Radio
                color="primary"
                checked={rejectPCRTestReason === 'not_provided'}
                onChange={(_, val) =>
                  setRejectPCRTestReason(val ? 'not_provided' : null)
                }
              />
            }
          />
        </ConfirmDialog>
        <ConfirmDialog
          loading={loadingUpdate}
          open={convertToPCRTestDialogOpen}
          title={translations.convertToPCRTest[lang]}
          text={translations.convertToPCRTestText[lang]}
          onConfirm={() =>
            convertToPCRTest(passenger?.serialNumber || '', formik)
          }
          onCancel={() => setConvertToPCRTestDialogOpen(false)}
          confirmText={translations.yes[lang]}
          cancelText={translations.no[lang]}
        />
      </React.Fragment>
    );
  };

  return (
    <Section>
      <div className={classes.root}>
        <Formik
          initialValues={{ samplingNumber: '' }}
          onSubmit={(values) => searchForPassenger(values)}
        >
          {(formik) => (
            <Grid container spacing={3}>
              <Grid item sm={12} md={5} lg={4}>
                <Paper elevation={2} className={classes.paperLeft}>
                  {renderSearch(formik)}
                </Paper>
                {isRapidTestSampling &&
                  isAccessible(accessControl.SEARCH, user) && (
                    <Paper elevation={2} className={classes.paperRapidTest}>
                      {renderRapidRedirect()}
                    </Paper>
                  )}
              </Grid>
              {paymentLoading ? (
                <div className={classes.root}>
                  <Grid container spacing={2} className={classes.paper}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h4">
                        {translations.paymentDialogText[lang]}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <LoadingIndicator />
                    </Grid>
                  </Grid>
                </div>
              ) : null}
              {passenger && !paymentLoading ? (
                <Grid item sm={12} md={7} lg={8}>
                  <PassengerInfo
                    passenger={passenger}
                    groupedDetails={childDetails}
                    isPosConnected={isPosConnected}
                    linkedSampling={
                      passenger.linkedSampling
                        ? {
                            isFirst: passenger.linkedSampling.isFirst,
                            onClick: () =>
                              searchForPassenger({
                                samplingNumber: passenger.linkedSampling
                                  ?.linkedBarcode as string,
                              }),
                          }
                        : undefined
                    }
                    buttons={
                      isReadyForSampling(passenger) ||
                      (passenger.borderControlStatus &&
                        passenger.borderControlStatus.id ===
                          borderControlStatus.REGISTERED_TO_TRANSIT)
                        ? negativePCRTestCheck(passenger, formik)
                        : ((passenger.borderControlStatus &&
                            passenger.borderControlStatus.id ===
                              borderControlStatus.SAMPLING_COMPLETED) ||
                            (passenger.borderControlStatus &&
                              passenger.borderControlStatus.id ===
                                borderControlStatus.CHECKED_OUT)) &&
                          !hidePrintButton(passenger.sampleTaken)
                        ? [
                            {
                              label: translations.reprint[lang],
                              onClick: () => setPrintDialogOpen(true),
                              icon() {
                                return (
                                  <PrintIcon className={classes.printIcon} />
                                );
                              },
                            },
                          ]
                        : passenger.borderControlStatus &&
                          passenger.borderControlStatus.id ===
                            borderControlStatus.REGISTERED_IN_QUARANTINE
                        ? [
                            {
                              label: translations.sendToSampling[lang],
                              disabled: loadingUpdate,
                              onClick: () => setDeclineDialogOpen(true),
                            },
                          ]
                        : passenger.borderControlStatus &&
                          passenger.borderControlStatus.id ===
                            borderControlStatus.EXEMPT_FROM_SAMPLING
                        ? [
                            {
                              label: translations.sendToSampling[lang],
                              disabled: loadingUpdate,
                              onClick: () => setDeclineExemptDialogOpen(true),
                            },
                          ]
                        : passenger.borderControlStatus &&
                          passenger.borderControlStatus.id ===
                            borderControlStatus.REGISTERED_WITH_CERTIFICATE
                        ? [
                            {
                              label: translations.sendToSampling[lang],
                              disabled: loadingUpdate,
                              onClick: () =>
                                setDeclineCertificateDialogOpen(true),
                            },
                          ]
                        : passenger.borderControlStatus &&
                          passenger.borderControlStatus.id ===
                            borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_NEEDED
                        ? [
                            {
                              label: translations.skipPayment[lang],
                              disabled: loadingUpdate,
                              onClick: () => setSkipPaymentDialogOpen(true),
                              error: true,
                            },
                          ]
                        : passenger.borderControlStatus &&
                          passenger.borderControlStatus.id ===
                            borderControlStatus.REGISTERED_FOR_SAMPLING_PAYMENT_NEEDED &&
                          isPosConnected
                        ? [
                            {
                              label: translations.payForSampling[lang],
                              variant: 'contained',
                              onClick: () =>
                                chargeForSampling(passenger, formik),
                            },
                            {
                              label: translations.skipPayment[lang],
                              disabled: loadingUpdate,
                              onClick: () => setSkipPaymentDialogOpen(true),
                              error: true,
                            },
                          ]
                        : passenger.borderControlStatus &&
                          passenger.borderControlStatus.id ===
                            borderControlStatus.CHILD_UNVERIFIED
                        ? [
                            {
                              label: translations.confirmChild[lang],
                              disabled: loadingUpdate,
                              onClick: () => confirmChild(formik),
                            },
                            {
                              label: translations.sendToSampling[lang],
                              disabled: loadingUpdate,
                              variant: 'outlined',
                              onClick: () => setDeclineChildDialogOpen(true),
                            },
                          ]
                        : passenger.borderControlStatus &&
                          passenger.borderControlStatus.id ===
                            borderControlStatus.PCR_CERTIFICATE_SAMPLING
                        ? [
                            {
                              label: translations.finishSampling[lang],
                              disabled: loadingUpdate,
                              onClick: () => confirmSample(formik),
                            },
                          ]
                        : null
                    }
                  />
                </Grid>
              ) : noResult ? (
                <Grid item sm={12} md={7} lg={8} style={{ flex: 1 }}>
                  <NoResults message={translations.noPassengerFound[lang]} />
                </Grid>
              ) : null}

              {renderDialogs(formik)}
            </Grid>
          )}
        </Formik>
      </div>
    </Section>
  );

  function negativePCRTestCheck(
    passenger: Passenger,
    formik: IFormik
  ): IButton[] {
    const registeredInTransit =
      passenger.borderControlStatus &&
      passenger.borderControlStatus.id ===
        borderControlStatus.REGISTERED_TO_TRANSIT;

    if (
      isNegPCRTestMandatory(user, passenger) &&
      passenger.preScreeningStatus.id ===
        preScreeningStatus.NEGATIVE_PCR_TEST_NOT_PROCESSED
    ) {
      return [
        {
          label: translations.acceptNegativePCRTest[lang],
          disabled: loadingUpdate,
          onClick: () => acceptNegativePCRTest(passenger, formik),
        },
        {
          label: translations.rejectNegativePCRTest[lang],
          variant: 'outlined',
          disabled: loadingUpdate,
          onClick: () => {
            setRejectPCRTestReason(null);
            setRejectPCRTestDialogOpen(true);
          },
        },
      ];
    }

    if (registeredInTransit) {
      return [
        {
          label: translations.sendToSampling[lang],
          disabled: loadingUpdate,
          onClick: () => setDeclineTransitDialogOpen(true),
        },
      ];
    }

    if (
      updateObservationIdentifierCheck(
        user,
        passenger.serialNumber,
        passenger.sampleId
      )
    ) {
      return [
        {
          label: translations.convertToPCRTest[lang],
          onClick: () => setConvertToPCRTestDialogOpen(true),
        },
        {
          label: translations.finishSampling[lang],
          disabled: true,
          onClick: () => confirmSample(formik),
        },
      ];
    }

    return [
      {
        label: translations.finishSampling[lang],
        disabled:
          (isNegPCRTestMandatory(user, passenger) && pcrRejected) ||
          loadingUpdate,
        onClick: () => confirmSample(formik),
      },
    ];
  }

  async function searchForPassenger(values: IFormValues) {
    if (
      canSearchForPassenger(
        user,
        values.samplingNumber,
        notifyError,
        lang,
        isRapidTestSampling
      )
    ) {
      return;
    }
    setIsPosConnected(false);
    httpService
      .getPassengerByRegistrationId(values.samplingNumber)
      .then((response) => {
        if (response.isOk) {
          if (shouldHavePos) {
            shellHelper.checkPosConnection(setIsPosConnected, notifyError);
          }
          setCurrentPassenger(response.data);
          setNoResult(false);
          focusIdInput();
          setChildCount(response.data?.numberOfChildren ?? 0);

          if (
            !hasRapidTestAccess(user) &&
            response.data?.ainSamplingBarcode &&
            canSearchForPassengerRapidCode(user)
          ) {
            searchForPassengerRapidCode(response.data.ainSamplingBarcode);
          }
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          setCurrentPassenger(null);
          setNoResult(true);
        }
      })
      .catch(() => {
        setCurrentPassenger(null);
        setNoResult(true);
      });
  }

  async function searchForPassengerRapidCode(rapidBarcode: string) {
    httpService
      .getPassengerByRegistrationId(rapidBarcode)
      .then((response) => {
        if (response.isOk) {
          if (shouldHavePos) {
            shellHelper.checkPosConnection(setIsPosConnected, notifyError);
          }
          setCurrentPassenger(response.data);
          setNoResult(false);
          focusIdInput();
          setChildCount(response.data?.numberOfChildren ?? 0);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          setCurrentPassenger(null);
          setNoResult(true);
        }
      })
      .catch(() => {
        setCurrentPassenger(null);
        setNoResult(true);
      });
  }

  function clearSearch(formik: IFormik) {
    setCurrentPassenger(null);
    setNoResult(false);
    setChildCount(0);
    focusIdInput();
    setRejectPCRTestReason(null);
    setLoadingUpdate(false);
    formik.handleReset();
    setPrintingAllowed(false);
  }

  function confirmSample(formik: IFormik) {
    setLoadingUpdate(true);
    httpService
      .markSampleTaken(passenger?.sampleId as string, childCount)
      .then((response) => {
        if (response.isOk) {
          shellHelper.printLabel(passenger);

          notifySuccess(translations.samplingFinished[lang]);
          setLoadingUpdate(false);
          clearSearch(formik);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          notifyError(translations.operationFailed[lang]);
          setLoadingUpdate(false);
        }
      })
      .catch(() => {
        notifyError(translations.operationFailed[lang]);
        setLoadingUpdate(false);
      });
  }

  function confirmChild(formik: IFormik) {
    setLoadingUpdate(true);
    httpService
      .confirmChild(passenger?.serialNumber as string)
      .then((response) => {
        if (response.isOk) {
          notifySuccess(translations.childConfirmed[lang]);
          setLoadingUpdate(false);
          clearSearch(formik);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          notifyError(translations.operationFailed[lang]);
          setLoadingUpdate(false);
        }
      })
      .catch(() => {
        notifyError(translations.operationFailed[lang]);
        setLoadingUpdate(false);
      });
  }

  async function declineQuarantine(
    passenger: Passenger | null,
    formik: IFormik
  ) {
    setLoadingUpdate(true);
    try {
      const response = await httpService.declineQuarantine(
        passenger?.serialNumber as string
      );
      if (response.isOk) {
        await searchForPassenger(formik.values);
        notifySuccess(translations.sendToSamplingConfirmation[lang]);
        setDeclineDialogOpen(false);
        setLoadingUpdate(false);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.operationFailed[lang]);
        setLoadingUpdate(false);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
      setLoadingUpdate(false);
    }
  }

  function declineExempt(passenger: Passenger | null, formik: IFormik) {
    setLoadingUpdate(true);
    httpService
      .declineExemptPassenger(passenger?.serialNumber as string)
      .then(async (response) => {
        if (response.isOk) {
          await searchForPassenger(formik.values);
          notifySuccess(translations.exemptDeclined[lang]);
          setDeclineExemptDialogOpen(false);
          setLoadingUpdate(false);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          notifyError(translations.operationFailed[lang]);
          setLoadingUpdate(false);
        }
      })
      .catch(() => {
        notifyError(translations.operationFailed[lang]);
        setLoadingUpdate(false);
      });
  }

  async function declineCertificate(
    passenger: Passenger | null,
    formik: IFormik
  ) {
    setLoadingUpdate(true);
    try {
      const response = await httpService.declineCertificate(
        passenger?.serialNumber as string,
        borderControlStatus.REGISTERED_FOR_SAMPLING
      );
      if (response.isOk) {
        await searchForPassenger(formik.values);
        notifySuccess(translations.sendToSamplingConfirmation[lang]);
        setDeclineCertificateDialogOpen(false);
        setLoadingUpdate(false);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.operationFailed[lang]);
        setLoadingUpdate(false);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
      setLoadingUpdate(false);
    }
  }

  async function declineTransit(passenger: Passenger | null, formik: IFormik) {
    setLoadingUpdate(true);
    try {
      const response = await httpService.declineTransit(
        passenger?.serialNumber as string,
        borderControlStatus.REGISTERED_FOR_SAMPLING
      );
      if (response.isOk) {
        await searchForPassenger(formik.values);
        notifySuccess(translations.sendToSamplingConfirmation[lang]);
        setDeclineTransitDialogOpen(false);
        setLoadingUpdate(false);
      } else if (response.statusCode === 401) {
        notifyError(translations.status401[lang]);
        history.push('/login');
      } else {
        notifyError(translations.operationFailed[lang]);
        setLoadingUpdate(false);
      }
    } catch {
      notifyError(translations.operationFailed[lang]);
      setLoadingUpdate(false);
    }
  }

  function chargeForSampling(passenger: Passenger | null, formik: IFormik) {
    setPaymentLoading(true);
    posService.getSamplingPrice().then((response) => {
      if (response.isOk) {
        const amount = response.data;
        shellHelper.chargeCard(
          amount,
          (reply: any) => {
            reply.amountPaid = amount;
            const transaction = new Transaction(reply);
            posService
              .markPassengerCharged(passenger?.serialNumber ?? '', transaction)
              .then(async () => {
                await searchForPassenger(formik.values);
                notifySuccess(reply.statusText);
                setPaymentLoading(false);
              });
          },
          (statusText) => {
            setPaymentLoading(false);
            notifyError(statusText);
          }
        );
      }
    });
  }

  function focusIdInput() {
    if (idInput && idInput.current) {
      idInput.current.focus();
    }
  }

  function skipPayment(passenger: Passenger | null, formik: IFormik) {
    setLoadingUpdate(true);
    httpService
      .skipPaymentForPassenger(passenger?.serialNumber as string)
      .then(async (response) => {
        if (response.isOk) {
          await searchForPassenger(formik.values);
          notifySuccess(translations.paymentSkipped[lang]);
          setSkipPaymentDialogOpen(false);
          setLoadingUpdate(false);
        } else if (response.statusCode === 401) {
          notifyError(translations.status401[lang]);
          history.push('/login');
        } else {
          notifyError(translations.operationFailed[lang]);
          setLoadingUpdate(false);
        }
      })
      .catch(() => {
        notifyError(translations.operationFailed[lang]);
        setLoadingUpdate(false);
      });
  }

  function hidePrintButton(sampleTaken?: string) {
    return !sampleTaken || !printingAllowed;
  }

  async function acceptNegativePCRTest(passenger: Passenger, formik: IFormik) {
    setLoadingUpdate(true);
    const response = await httpService.acceptNegPCRCertificate(
      passenger.serialNumber
    );
    if (response.isOk) {
      await searchForPassenger(formik.values);
      notifySuccess(translations.negativePCRTestAccepted[lang]);
      setLoadingUpdate(false);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.operationFailed[lang]);
      setLoadingUpdate(false);
    }
  }

  async function rejectNegativePCRTest(passenger: Passenger, formik: IFormik) {
    setLoadingUpdate(true);
    const response = await httpService.rejectNegPCRCertificate(
      passenger.serialNumber,
      ''
    );
    if (response.isOk) {
      notifySuccess(translations.negativePCRTestRejected[lang]);
      setRejectPCRTestDialogOpen(false);
      setLoadingUpdate(false);
      clearSearch(formik);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.operationFailed[lang]);
      setLoadingUpdate(false);
    }
  }

  async function notProvidedNegativePCRTest(
    passenger: Passenger,
    formik: IFormik
  ) {
    setLoadingUpdate(true);
    const response = await httpService.notProvidedNegPCRCertificate(
      passenger.serialNumber
    );
    if (response.isOk) {
      notifySuccess(translations.negativePCRTestRejected[lang]);
      setRejectPCRTestDialogOpen(false);
      setLoadingUpdate(false);
      clearSearch(formik);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.operationFailed[lang]);
      setLoadingUpdate(false);
    }
  }

  async function convertToPCRTest(serialNumber: string, formik: IFormik) {
    setLoadingUpdate(true);
    const response = await httpService.updateObservationIdentifierToTa(
      serialNumber
    );
    if (response.isOk) {
      await searchForPassenger(formik.values);
      notifySuccess(translations.convertToPCRTestSuccess[lang]);
      setConvertToPCRTestDialogOpen(false);
      setLoadingUpdate(false);
    } else if (response.statusCode === 401) {
      notifyError(translations.status401[lang]);
      history.push('/login');
    } else {
      notifyError(translations.operationFailed[lang]);
      setLoadingUpdate(false);
    }
  }
};

export default Sampling;
