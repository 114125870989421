import React, { useContext, useState, useEffect } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from './Button';
import ConfirmDialog from './ConfirmDialog';
import Autocomplete, { IOption } from './Autocomplete';
import { LangContext } from '../context/LangContext';
import { Passenger } from '../models/Models';
import { IUpdatePassengerInfo } from '../interfaces/passenger';
import translations from '../assets/json/translations.json';
import callingCodes from '../assets/json/callingCodes.json';
import moment, { Moment } from 'moment';
import DatePicker from './DatePicker';
import { canEditPassengerInfo, toISOStringTimezone } from '../utils';
import theme from '../theme';

interface IProps {
  passenger: Passenger;
  updateInfo: (
    info: IUpdatePassengerInfo,
    onSuccess: () => void
  ) => Promise<void>;
}

const schema = Yup.object().shape({
  lastName: Yup.string().required(),
  firstName: Yup.string().required(),
  email: Yup.string().email().required(),
  callingCode: Yup.object().required(),
  phoneNumber: Yup.string().required(),
  dateOfBirth: Yup.object().required(),
  icelandRelations: Yup.boolean().required(),
  samplingAtTheAirport: Yup.boolean().required(),
});

const EditPassengerInfo: React.FC<IProps> = ({ passenger, updateInfo }) => {
  const [lang] = useContext(LangContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [callingCodeOptions, setCallingCodeOptions] = useState<IOption[]>([]);

  useEffect(() => {
    const options = callingCodes
      .filter((c) => !!c.code)
      .map((c) => ({
        label: c.name + ` (+${c.code})`,
        value: c.code as string,
      }));
    setCallingCodeOptions(options);
  }, []);

  return (
    <React.Fragment>
      <Button
        padding={false}
        onClick={() => setDialogOpen(true)}
        endIcon={<EditIcon />}
        variant="outlined"
      >
        {translations.edit[lang]}
      </Button>
      {renderDialog()}
    </React.Fragment>
  );

  function renderDialog() {
    return (
      <Formik
        onSubmit={(values) =>
          updateInfo(
            {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneCountryCode: values.callingCode
                ? values.callingCode.value
                : '',
              phoneNumber: values.phoneNumber,
              dateOfBirth: toISOStringTimezone(values.dateOfBirth),
              dateOfDepartureFromIceland: values.departureFromIceland
                ? toISOStringTimezone(values.departureFromIceland)
                : undefined,
              extraSamplingReqired: values.icelandRelations,
              extraSamplingSelectedTestLocation: values.samplingAtTheAirport
                ? 0
                : 1,
            },
            () => setDialogOpen(false)
          )
        }
        validationSchema={schema}
        enableReinitialize
        initialValues={{
          lastName: passenger.lastName || '',
          firstName: passenger.firstName || '',
          email: passenger.email || '',
          callingCode: getMainCallingCode(),
          phoneNumber: getMainNumber(),
          dateOfBirth: moment(passenger.dateOfBirth),
          departureFromIceland: passenger.departureFromIceland
            ? (moment(passenger.departureFromIceland) as Moment | null)
            : (null as Moment | null),
          icelandRelations: passenger.extraSamplingReqired,
          samplingAtTheAirport:
            passenger.extraSamplingSelectedTestLocation === 0 ? true : false,
        }}
      >
        {({
          values,
          errors,
          submitCount,
          isSubmitting,
          isValid,
          dirty,
          handleChange,
          handleSubmit,
          handleBlur,
          handleReset,
          setFieldValue,
        }) => (
          <ConfirmDialog
            open={dialogOpen}
            title={translations.edit[lang]}
            loading={isSubmitting}
            disableConfirm={isSubmitting || !dirty}
            onConfirm={handleSubmit}
            onCancel={() => {
              setDialogOpen(false);
              handleReset();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  autoFocus
                  required
                  autoComplete="off"
                  id="lastName"
                  variant="outlined"
                  label={translations.lastName[lang]}
                  value={values.lastName}
                  error={!!errors.lastName && !!submitCount}
                  onChange={handleChange}
                  disabled={!canEditPassengerInfo(passenger)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  autoComplete="off"
                  id="firstName"
                  variant="outlined"
                  label={translations.firstNames[lang]}
                  value={values.firstName}
                  error={!!errors.firstName && !!submitCount}
                  onChange={handleChange}
                  disabled={!canEditPassengerInfo(passenger)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  autoComplete="off"
                  id="email"
                  variant="outlined"
                  label={translations.email[lang]}
                  value={values.email}
                  error={!!errors.email && !!submitCount}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  required
                  id="callingCode"
                  value={values.callingCode}
                  label={translations.countryCode[lang]}
                  items={callingCodeOptions}
                  onChange={(val) => setFieldValue('callingCode', val)}
                  error={!!errors.callingCode && !!submitCount}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  autoComplete="off"
                  id="phoneNumber"
                  variant="outlined"
                  label={translations.phoneNumber[lang]}
                  value={values.phoneNumber}
                  error={!!errors.phoneNumber && !!submitCount}
                  inputProps={{
                    type: 'text',
                    pattern: '[0-9+]*',
                  }}
                  onChange={(e) =>
                    !e.target.validity.patternMismatch && handleChange(e)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  id="dateOfBirth"
                  label={translations.dateOfBirth[lang]}
                  value={values.dateOfBirth}
                  onChange={(e) =>
                    setFieldValue('dateOfBirth', e ? moment(e) : null)
                  }
                  onBlur={handleBlur}
                  disabled={!canEditPassengerInfo(passenger)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  id="departureFromIceland"
                  label={translations.departureFromIceland[lang]}
                  value={values.departureFromIceland}
                  muiProps={{ disableToolbar: true }}
                  onChange={(e) =>
                    setFieldValue('departureFromIceland', e ? moment(e) : null)
                  }
                  onBlur={handleBlur}
                  disabled={!canEditPassengerInfo(passenger)}
                />
              </Grid>
              {showCheckbox(passenger) && (
                <>
                  <Grid item xs={12} sm={12} style={{ paddingBottom: 0 }}>
                    <FormControlLabel
                      style={{ marginRight: theme.spacing(4) }}
                      label={translations.icelandRelations[lang]}
                      control={
                        <Checkbox
                          id="icelandRelations"
                          color="primary"
                          checked={values.icelandRelations}
                          onChange={(e) =>
                            onSetIcelandRelations(
                              setFieldValue,
                              e.target.checked as boolean
                            )
                          }
                          disabled={!canEditPassengerInfo(passenger)}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControlLabel
                      style={{ marginRight: theme.spacing(4) }}
                      label={translations.samplingAtTheAirport[lang]}
                      control={
                        <Checkbox
                          id="samplingAtTheAirport"
                          color="primary"
                          checked={values.samplingAtTheAirport}
                          onChange={handleChange}
                          disabled={
                            !values.icelandRelations ||
                            !canEditPassengerInfo(passenger)
                          }
                        />
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </ConfirmDialog>
        )}
      </Formik>
    );
  }

  function onSetIcelandRelations(setFieldValue: any, value: boolean) {
    setFieldValue('icelandRelations', value);
    if (!value) {
      setFieldValue('samplingAtTheAirport', false);
    }
  }

  function getMainNumber() {
    if (passenger.phoneNumbers) {
      const mainNumber = passenger.phoneNumbers.find(
        (number) => number.isMainNumber
      );
      if (mainNumber) {
        return mainNumber.number;
      } else if (passenger.phoneNumbers.length > 0) {
        return passenger.phoneNumbers[0].number;
      }
    }
    return '';
  }

  function getMainCallingCode(): IOption | null {
    if (passenger.phoneNumbers) {
      const mainNumber = passenger.phoneNumbers.find(
        (number) => number.isMainNumber
      );
      if (mainNumber) {
        const codeOption = callingCodeOptions.find(
          (c) => c.value === mainNumber.code
        );
        if (codeOption) {
          return codeOption;
        }
      } else if (passenger.phoneNumbers.length > 0) {
        const codeOption = callingCodeOptions.find(
          (c) => c.value === passenger.phoneNumbers[0].code
        );
        if (codeOption) {
          return codeOption;
        }
      }
    }
    return null;
  }

  function showCheckbox(currentPassenger: Passenger) {
    if (!currentPassenger.flowStatusesActive) {
      return false;
    }
    if (currentPassenger.sampleTaken) {
      return false;
    }

    return true;
  }
};

export default EditPassengerInfo;
