import { User, Transaction } from '../models/Models';
import { IHttpAnswer } from '../interfaces/httpAnswer';
import * as localStorageKeys from '../constants/localStorageKeys';

const API_URL = process.env.REACT_APP_API_URL;

async function markPassengerCharged(
  barCode: string,
  transaction: Transaction
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Passenger/Pay/${barCode}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(transaction),
  });
  if (response.ok) {
    return { data: true, isOk: response.ok, statusCode: response.status };
  }
  return {
    data: false,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function getSamplingPrice(): Promise<IHttpAnswer<number>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Passenger/GetPrice/`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    const price = (await response.json()) as number;
    return {
      data: price,
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return {
    data: 0,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function getCalloutPrice(): Promise<IHttpAnswer<number>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Passenger/GetSpecialPrice/`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    const price = (await response.json()) as number;
    return {
      data: price,
      isOk: response.ok,
      statusCode: response.status,
    };
  }
  return {
    data: 0,
    isOk: response.ok,
    statusCode: response.status,
  };
}

async function markCalloutCharged(
  transaction: Transaction
): Promise<IHttpAnswer<boolean>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;

  const response = await fetch(`${API_URL}/Passenger/SpecialPay`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${sessionId}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(transaction),
  });

  if (response.ok) {
    return { data: true, isOk: response.ok, statusCode: response.status };
  }
  return {
    data: false,
    isOk: response.ok,
    statusCode: response.status,
  };
}

export default {
  markPassengerCharged,
  getSamplingPrice,
  getCalloutPrice,
  markCalloutCharged,
};
