import React, { useContext, useState } from 'react';
import { useTheme, Grid, Paper, Typography } from '@material-ui/core';

import Layout from '../components/Layout';
import Section from '../components/Section';
import HelpItems from '../components/HelpItems';
import HelpBarcodes from '../components/HelpBarcodes';
import FlowCharts from '../components/FlowCharts';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

const HELP_ITEMS = [
  {
    title: { is: 'Strikamerki', en: 'Barcodes' },
    component: <HelpBarcodes />,
  },
  {
    title: { is: 'Flæðirit', en: 'Flow charts' },
    component: <FlowCharts flowType={'certificate'} />,
    subItems: [
      {
        title: translations.certificate,
        component: <FlowCharts flowType={'certificate'} />,
      },
      {
        title: translations.sampling,
        component: <FlowCharts flowType={'sampling'} />,
      },
      {
        title: translations.transit,
        component: <FlowCharts flowType={'transit'} />,
      },
      {
        title: translations.quarantine,
        component: <FlowCharts flowType={'quarantine'} />,
      },
    ],
  },
];

const HelpPage: React.FC = () => {
  const [lang] = useContext(LangContext);
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedSubIndex, setSelectedSubIndex] = useState(0);

  return (
    <Layout useDefaultSpacing title={translations.help[lang]}>
      <Section>
        <Grid container spacing={3} direction="row">
          <Grid item xs={12} lg={4} sm={12}>
            <HelpItems
              items={HELP_ITEMS.map((item, index) => ({
                text: item.title[lang],
                selected: index === selectedIndex,
                subItems:
                  item.subItems && item.subItems.length > 0
                    ? item.subItems.map((subItem, subIndex) => ({
                        text: subItem.title[lang],
                        selected: subIndex === selectedSubIndex,
                      }))
                    : undefined,
              }))}
              onClick={(_, index) => {
                setSelectedIndex(index);
                setSelectedSubIndex(0);
              }}
              onClickSubItem={(_, index) => setSelectedSubIndex(index)}
            />
          </Grid>
          <Grid item xs={12} lg={8} sm={12}>
            <Paper elevation={2} style={{ padding: theme.spacing(3) }}>
              <Typography
                variant="h4"
                style={{ marginBottom: theme.spacing(3) }}
              >
                {renderHelperItemText()}
              </Typography>
              {renderHelperItemComponent()}
            </Paper>
          </Grid>
        </Grid>
      </Section>
    </Layout>
  );

  function renderHelperItemText() {
    const item = HELP_ITEMS[selectedIndex];
    if (item) {
      if (item?.subItems && item?.subItems?.length > 0) {
        return `${item.title[lang]} - ${item.subItems[selectedSubIndex].title[lang]}`;
      }
    }
    return item.title[lang];
  }

  function renderHelperItemComponent() {
    const item = HELP_ITEMS[selectedIndex];
    if (item) {
      if (item?.subItems && item?.subItems?.length > 0) {
        return item.subItems[selectedSubIndex].component;
      }
    }
    return item.component;
  }
};

export default HelpPage;
