import React from 'react';
import {
  makeStyles,
  Button as MuiButton,
  ButtonProps,
} from '@material-ui/core';
import cx from 'classnames';

interface IProps {
  padding?: boolean;
  success?: boolean;
  error?: boolean;
}

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2, 3),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  error: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  errorOutlined: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.main,
      backgroundColor: 'rgba(255,0,0,0.02)',
    },
  },
}));

const additionalProps = ['padding', 'success', 'error'];

const Button: React.FC<ButtonProps & IProps> = (props) => {
  const classes = useStyles();
  const { padding = true, success, error } = props;

  const allProps: { [key: string]: any } = { ...props };
  const muiProps = Object.keys(allProps)
    .filter((key) => !additionalProps.includes(key))
    .reduce((obj: { [key: string]: any }, key) => {
      obj[key] = allProps[key];
      return obj;
    }, {});

  return (
    <MuiButton
      color="primary"
      variant="contained"
      {...muiProps}
      className={cx(props.className, {
        [classes.padding]: padding,
        [classes.success]: success,
        [classes.error]: error && props.variant !== 'outlined',
        [classes.errorOutlined]: error && props.variant === 'outlined',
      })}
    />
  );
};

export default Button;
