import React, { useContext } from 'react';
import {
  makeStyles,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import parse from 'react-html-parser';

import { LangContext } from '../context/LangContext';
import help from '../assets/json/help.json';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderBottomWidth: 0,
    borderRadius: 4,
    '& th': {
      fontWeight: 600,
      fontSize: 15,
    },
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 15,
    },
  },
  infoItem: {
    marginBottom: theme.spacing(2),
  },
}));

const HelpBarcodes: React.FC = () => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TableContainer className={classes.container}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {help.barcodes.tableColumns.map((column, index) => (
                  <TableCell key={index}>{column[lang]}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {help.barcodes.tableRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.barcode}</TableCell>
                  <TableCell>{row.type[lang]}</TableCell>
                  <TableCell>{row.samplingNumber}</TableCell>
                  <TableCell>{parse(row.description[lang])}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        {help.barcodes.additionalInfo.map((info, index) => (
          <Typography
            key={index}
            variant="body2"
            color="textSecondary"
            className={
              index !== help.barcodes.additionalInfo.length - 1
                ? classes.infoItem
                : undefined
            }
          >
            * {parse(info[lang])}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};

export default HelpBarcodes;
