import React, { useContext, useEffect, useState } from 'react';
import { Grid, useTheme, useMediaQuery, TextField } from '@material-ui/core';
import moment, { Moment } from 'moment';
import * as Yup from 'yup';

import Button from './Button';
import Autocomplete, { IOption } from './Autocomplete';
import DatePicker from './DatePicker';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

import { Form, Formik, FormikProps } from 'formik';
import {
  VaccinationGroup,
  VaccinationLocation,
} from '../models/VaccinationModels';
import Dropdown from './Dropdown';
import { UserContext } from '../context/UserContext';
import { IVaccinationCommentStatus } from '../interfaces/vaccinationComment';
import * as vaccinationService from '../services/vaccinationService';
import { getYears } from '../utils';

/* 
const VACCINATION_STATUS_NEITHER_KEY = 'neither';
const VACCINATION_STATUS_FIRST_KEY = 'first';
const VACCINATION_STATUS_VACCINATED_KEY = 'vaccinated';
const VACCINATION_STATUS_OPTIONS: {
  [key: string]:
    | 'vaccinationNotStarted'
    | 'vaccinationOnlyFirstFinished'
    | 'hasNotReceivedBoosterDose';
} = {
  [VACCINATION_STATUS_NEITHER_KEY]: 'vaccinationNotStarted',
  [VACCINATION_STATUS_FIRST_KEY]: 'vaccinationOnlyFirstFinished',
  [VACCINATION_STATUS_VACCINATED_KEY]: 'hasNotReceivedBoosterDose',
}; */

interface IProps {
  onSubmit: (values: IFormValues, selectedGroups: IOption[]) => void;
  onClear: () => void;
  vaccines: IOption[];
  locations: VaccinationLocation[];
  comments: IVaccinationCommentStatus[];
}

export interface IFormValues {
  region: string;
  vaccine: IOption[] | null;
  location: IOption[] | null;
  vaccinationFrom: Moment | null;
  vaccinationTo: Moment | null;
  numberOfVaccinations: string;
  yearFrom: IOption | null;
  yearTo: IOption | null;
}

type IFormik = FormikProps<IFormValues>;

const SummonAcrossGroupsFilter: React.FC<IProps> = ({
  onSubmit,
  onClear,
  locations,
  vaccines,
  comments,
}) => {
  const theme = useTheme();
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [locationsForSelectedRegion, setLocationsForSelectedRegion] = useState<
    VaccinationLocation[]
  >([]);
  const [groupOptions, setGroupOptions] = useState<VaccinationGroup[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<IOption[]>([]);
  const [getFirstVaccinationCandidates, setGetFirstVaccinationCandidates] =
    useState(true);

  useEffect(() => {
    if (user.vaccinationRegionModelList.length === 1) {
      const value = user.vaccinationRegionModelList[0].id.toString();
      const newSelectedRegionLocations = locations.filter(
        (l) => l.regionId.toString() === value
      );
      setLocationsForSelectedRegion(newSelectedRegionLocations);
      vaccinationService.getGroups(value).then((response) => {
        if (response.isOk) {
          setGroupOptions(response.data || []);
        }
      });
    }
  }, [locations, user]);

  const schema = Yup.object().shape({
    region: Yup.string().required(),
    numberOfVaccinations: Yup.string().required(),
    vaccine: getFirstVaccinationCandidates
      ? Yup.array()
      : Yup.array().required(),
  });

  return (
    <Grid style={{ width: '100%' }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: theme.spacing(1) }}
      ></Grid>
      <Formik
        onSubmit={(values) => onSubmit(values, selectedGroups)}
        initialValues={getInitalValues()}
        validationSchema={schema}
        enableReinitialize
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>{renderFilters(formik)}</Form>
        )}
      </Formik>
    </Grid>
  );

  function renderFilters(formik: IFormik) {
    const {
      values,
      errors,
      submitCount,
      handleBlur,
      setFieldValue,
      isSubmitting,
    } = formik;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            required
            autoFocus
            autoComplete="off"
            id="numberOfVaccinations"
            variant="outlined"
            inputProps={{
              type: 'text',
              pattern: '[0-9+]*',
              maxLength: 2,
            }}
            label={translations.numberOfVaccinationsFinished[lang]}
            value={values.numberOfVaccinations}
            onChange={(e) => {
              if (e.target.validity.valid) {
                onSetVaccinationStatus(setFieldValue, e.target.value as string);
              }
              if (e.target.value === '') {
                setFieldValue('numberOfVaccinations', '');
              }
            }}
            error={!!errors.numberOfVaccinations && !!submitCount}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Dropdown
            required
            fullWidth
            id="region"
            value={values.region}
            label={translations.region[lang]}
            items={user.vaccinationRegionModelList.map((r: any) => ({
              label: r.description || '',
              value: r.id ? r.id.toString() : '',
            }))}
            onChange={(e) => onChangeRegion(e.target.value as string, formik)}
            disabled={
              !user.vaccinationRegionModelList ||
              user.vaccinationRegionModelList.length === 1
            }
            error={
              !!errors.region && !!submitCount && !getFirstVaccinationCandidates
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            required={!getFirstVaccinationCandidates}
            disabled={getFirstVaccinationCandidates}
            checkBox
            fullWidth
            id="vaccine"
            values={values.vaccine || []}
            label={translations.vaccineLastGiven[lang]}
            items={vaccines || []}
            onChange={(e: any) => {
              setFieldValue('vaccine', e as IOption[]);
            }}
            error={
              !!errors.vaccine &&
              !!submitCount &&
              !getFirstVaccinationCandidates
            }
          />
        </Grid>

        <>
          <Grid item xs={12} sm={12} md={6}>
            <Autocomplete
              checkBox
              fullWidth
              id="location"
              values={values.location || []}
              label={
                selectedGroups.length > 0
                  ? translations.locationDisabled[lang]
                  : translations.location[lang]
              }
              items={(locationsForSelectedRegion || []).map((l) => ({
                label: l.name,
                value: l.id.toString(),
              }))}
              onChange={(e: any) => {
                setFieldValue('location', e as IOption[]);
              }}
              error={!!errors.location && !!submitCount}
              disabled={
                !locationsForSelectedRegion ||
                locationsForSelectedRegion.length < 1 ||
                getFirstVaccinationCandidates ||
                selectedGroups.length > 0
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Autocomplete
              fullWidth
              required={getFirstVaccinationCandidates}
              checkBox
              id="groups"
              label={
                !!(values.location && values.location.length > 0)
                  ? translations.selectGroupsDisabled[lang]
                  : translations.selectGroups[lang]
              }
              onChange={(selectedItems: any) =>
                setSelectedGroups(selectedItems)
              }
              values={selectedGroups}
              items={groupOptions.map((item) => ({
                value: item.id.toString(),
                label: item.name,
              }))}
              disabled={
                !values.region ||
                !!(values.location && values.location.length > 0)
              }
              error={
                !!submitCount &&
                !!getFirstVaccinationCandidates &&
                !selectedGroups.length
              }
            />
          </Grid>
        </>

        <Grid
          item
          xs={smDown ? 12 : undefined}
          sm={smDown ? 6 : undefined}
          md={smDown ? 4 : 3}
          style={{ flex: !smDown ? 1 : undefined }}
        >
          <DatePicker
            id="vaccinationFrom"
            disabled={getFirstVaccinationCandidates}
            label={translations.vaccinationFinishedFrom[lang]}
            value={values.vaccinationFrom}
            muiProps={{
              disableToolbar: true,
              //maxDate: selectedToDate || undefined,
            }}
            onChange={(e) =>
              setFieldValue('vaccinationFrom', e ? moment(e) : null)
            }
            onBlur={handleBlur}
          />
        </Grid>

        <Grid
          item
          xs={smDown ? 12 : undefined}
          sm={smDown ? 6 : undefined}
          md={smDown ? 4 : 3}
          style={{ flex: !smDown ? 1 : undefined }}
        >
          <DatePicker
            required={!getFirstVaccinationCandidates}
            disabled={getFirstVaccinationCandidates}
            id="vaccinationTo"
            label={translations.vaccinationFinishedTo[lang]}
            value={values.vaccinationTo}
            muiProps={{
              disableToolbar: true,
              //minDate: selectedFromDate || undefined,
            }}
            onChange={(e) =>
              setFieldValue('vaccinationTo', e ? moment(e) : null)
            }
            onBlur={handleBlur}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            fullWidth
            id="yearFrom"
            value={values.yearFrom}
            label={translations.birthYearFrom[lang]}
            items={getYears({
              max: values.yearTo ? values.yearTo.value : undefined,
            })}
            onChange={(e) => setFieldValue('yearFrom', e)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            fullWidth
            id="yearTo"
            value={values.yearTo}
            label={translations.birthYearTo[lang]}
            items={getYears({
              min: values.yearFrom ? values.yearFrom.value : undefined,
            })}
            onChange={(e) => setFieldValue('yearTo', e)}
          />
        </Grid>

        <Grid item xs={smDown ? 12 : 6}>
          <Button
            fullWidth={true}
            variant="outlined"
            onClick={() => {
              clearForm(formik);
              setSelectedGroups([]);
              setGroupOptions([]);
              setGetFirstVaccinationCandidates(false);
            }}
          >
            {translations.clear[lang]}
          </Button>
        </Grid>
        <Grid item xs={smDown ? 12 : 6}>
          <Button
            fullWidth={true}
            type="submit"
            disabled={
              isSubmitting ||
              (getFirstVaccinationCandidates && !selectedGroups.length)
            }
          >
            {translations.search[lang]}
          </Button>
        </Grid>
      </Grid>
    );
  }

  function onSetVaccinationStatus(setFieldValue: any, value: string) {
    setFieldValue('numberOfVaccinations', value);
    setGetFirstVaccinationCandidates(value === '0' ? true : false);
    if (value === '0') {
      setFieldValue('vaccine', '');
      setFieldValue('location', '');
      setFieldValue('vaccinationFrom', null as Moment | null);
      setFieldValue('vaccinationTo', null as Moment | null);
    }
  }

  async function onChangeRegion(value: string, { setFieldValue }: IFormik) {
    setFieldValue('region', value);
    setFieldValue('location', '');
    const newSelectedRegionLocations = locations.filter(
      (l) => l.regionId.toString() === value
    );
    setLocationsForSelectedRegion(newSelectedRegionLocations);

    const response = await vaccinationService.getGroups(value);
    if (response.isOk) {
      setSelectedGroups([]);
      setGroupOptions(response.data || []);
    }
  }

  function clearForm({ handleReset }: IFormik) {
    handleReset();
    onClear();
  }

  function getInitalValues() {
    return {
      region:
        ((user.vaccinationRegionModelList.length === 1 &&
          user.vaccinationRegionModelList[0].id.toString()) as string) || '',
      vaccine: null as IOption[] | null,
      location: null as IOption[] | null,
      numberOfVaccinations: '',
      vaccinationFrom: null as Moment | null,
      vaccinationTo: null as Moment | null,
      yearFrom: null as IOption | null,
      yearTo: null as IOption | null,
    };
  }
};

export default SummonAcrossGroupsFilter;
