import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Layout from '../components/Layout';
import useNotifier from '../hooks/useNotifier';
import LoadingIndicator from '../components/LoadingIndicator';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import * as vaccinationService from '../services/vaccinationService';
import CreateVaccinationGroup from '../components/CreateVaccinationGroup';
import { IVaccinationGroupDetails } from '../interfaces/vaccinationGroup';
import ConfirmDialog from '../components/ConfirmDialog';

const EditVaccinationGroupPage: React.FC<
  RouteComponentProps<{ groupId: string }>
> = ({ match }) => {
  const history = useHistory();
  const [lang] = useContext(LangContext);
  const { notifyError } = useNotifier();

  const [editGroup, setEditGroup] = useState<IVaccinationGroupDetails | null>(
    null
  );
  const [loadingGroup, setLoadingGroup] = useState(true);
  const [goBack, setGoBack] = useState(true);
  const [backHomeDialog, setBackHomeDialog] = useState(false);
  const [backToGroupDialog, setBackToGroupDialog] = useState(false);

  if ((window as any).logPrinterError === undefined) {
    (window as any).logPrinterError = notifyError;
  }

  useEffect(() => {
    const fetchGroup = async () => {
      if (match.params.groupId) {
        const response = await vaccinationService.getPersonsInGroup(
          match.params.groupId
        );
        if (response.isOk) {
          setEditGroup(response.data);
        } else if (response.statusCode === 401) {
          history.push('/login');
        }
        setLoadingGroup(false);
      } else {
        setEditGroup(null);
        setLoadingGroup(false);
      }
    };
    fetchGroup();
  }, [match.params.groupId, history]);

  return (
    <Layout
      useDefaultSpacing
      title={getTitle()}
      otherButton={{
        label: translations.backToGroups[lang],
        onClick: () => {
          goBack ? history.push('/groups') : setBackToGroupDialog(true);
        },
      }}
      backButtonClick={!goBack ? () => setBackHomeDialog(true) : undefined}
    >
      {loadingGroup && (
        <Grid container justify="center" alignItems="center">
          <LoadingIndicator />
        </Grid>
      )}
      {editGroup && (
        <CreateVaccinationGroup
          editGroup={editGroup}
          changesOnGroup={changesOnGroup}
        ></CreateVaccinationGroup>
      )}
      {renderDialogs()}
    </Layout>
  );

  function changesOnGroup(value: boolean) {
    setGoBack(value);
  }

  function getTitle() {
    if (loadingGroup) {
      return undefined;
    }
    return editGroup ? editGroup.name : translations.groupNotFound[lang];
  }

  function renderDialogs() {
    return (
      <React.Fragment>
        <ConfirmDialog
          open={backHomeDialog}
          title={translations.backToMenu[lang]}
          text={translations.backHomeDialogTextGroupEdit[lang]}
          confirmText={translations.back[lang]}
          onConfirm={() => history.push('/home')}
          onCancel={() => setBackHomeDialog(false)}
        />
        <ConfirmDialog
          open={backToGroupDialog}
          title={translations.backToGroups[lang]}
          text={translations.backToGroupDialogTextGroupEdit[lang]}
          confirmText={translations.back[lang]}
          onConfirm={() => history.push('/groups')}
          onCancel={() => setBackToGroupDialog(false)}
        />
      </React.Fragment>
    );
  }
};

export default EditVaccinationGroupPage;
