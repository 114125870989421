import React, { useContext } from 'react';
import { makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Error';
import ErrorIcon from '@material-ui/icons/Cancel';
import { childVaccinationCustodianPosition } from '../../../constants/enums';
import translations from '../../../assets/json/translations.json';
import { LangContext } from '../../../context/LangContext';

interface IProps {
  status?: number;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginBottom: -6,
  },
  infoValue: {
    color: theme.palette.text.secondary,
    marginLeft: 10,
  },
}));

const ChildVaccinationPositionTooltip: React.FC<IProps> = ({ status }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [lang] = useContext(LangContext);

  if (status === childVaccinationCustodianPosition.UNKNOWN) {
    return (
      <Tooltip
        title={
          <Typography variant="subtitle1">
            {translations.haveNotTakenAPosition[lang]}
          </Typography>
        }
      >
        <WarningIcon
          className={classes.icon}
          style={{ color: theme.palette.warning.light }}
        />
      </Tooltip>
    );
  }
  if (
    status === childVaccinationCustodianPosition.NOT_NOW ||
    status === childVaccinationCustodianPosition.NO
  ) {
    return (
      <Tooltip
        title={
          <Typography variant="subtitle1">
            {translations.cannotBeVaccinated[lang]}
          </Typography>
        }
      >
        <ErrorIcon
          className={classes.icon}
          style={{ color: theme.palette.error.main }}
        />
      </Tooltip>
    );
  }
  if (status === childVaccinationCustodianPosition.YES) {
    return (
      <Tooltip
        title={
          <Typography variant="subtitle1">
            {translations.canBeVaccinated[lang]}
          </Typography>
        }
      >
        <SuccessIcon
          className={classes.icon}
          style={{ color: theme.palette.success.main }}
        />
      </Tooltip>
    );
  }

  return <></>;
};

export default ChildVaccinationPositionTooltip;
