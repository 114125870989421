import api from "../api";
import { IHttpAnswer } from "../interfaces/httpAnswer";
import { EuDccCertificate } from "../models/Models";

const _controller = 'EuDcc'


export async function getEuDccCertificates(
  ssn: string
): Promise<IHttpAnswer<EuDccCertificate[]>> {
  const response = await api.get(
    `/${_controller}/Get?ssn=${ssn}`
  );

  return {
    data: response?.data ? response.data.map((item: any) => new EuDccCertificate(item)) : [],
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}

export async function revokeCertificate(
  certificate: EuDccCertificate,
  invalidationReason: string

): Promise<IHttpAnswer<null>> {
  const response = await api.post(
    `/${_controller}/RevokeCertificate`,
    {ci: certificate.ci, certificateType: certificate.type, invalidationReason}
  );

  return {
    data: null,
    isOk: response.isOk,
    statusCode: response.statusCode,
  };
}