import { createBrowserHistory } from "history";
import * as versionFile from './version.json';
import * as localStorageKeys from './constants/localStorageKeys';
import { User } from './models/Models';
import { IHttpAnswer } from './interfaces/httpAnswer';
import { snackActions } from "./utils/SnackbarUtilsConfigurator";
import translations from './assets/json/translations.json';
import { ILanguage } from "./context/LangContext";
const API_URL = process.env.REACT_APP_API_URL;

interface IRequestConfig {
  method: string;
  body?: string;
  headers: Headers
}

async function apiRequest(path: string, method: string, payload: any, autoErrorHandling: boolean, headers: Headers | null): Promise<IHttpAnswer<any>> {
  const sessionId = new User(
    JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
  ).encodedSessionId;
  const rawLangValue = localStorage.getItem(localStorageKeys.LANGUAGE);
  const lang: ILanguage = rawLangValue === "en" ? rawLangValue : "is";
  const version = `${versionFile.major}.${versionFile.minor}`;
  var reqConfig: IRequestConfig = {
    method: method,
    headers: headers ? headers : new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${sessionId}`,
      "version": version,
    }),
    //credentials: "include", need to set cors headers on the api side for this to work
  };

  if (payload) {
    reqConfig.body = JSON.stringify(payload);
  }

  try {
    const res = await fetch(API_URL + path, reqConfig);
    // body is sometimes empty on DELETE responses
    let body;
    try {
      body = await res.json();
    } catch {
      body = {};
    }
    // by default this service will return the error back to the caller.
    // but ifautoErrorhandling is set to true there is no need to handle 401/403/more? errors as this function should be able to handle it
    if (!res.ok) {
      if (res.status === 401 && !autoErrorHandling) {
        const history = createBrowserHistory();
        const routesToIgnore = ["/", "/login"];

        if (!routesToIgnore.includes(history.location.pathname)) {
          history.push({
            pathname: "/login",
            state: {
              from: history.location,
            },
          });
          history.go(0);
        }
        snackActions.error(translations.status401[lang]);
      } else if (res.status === 403 && autoErrorHandling) {
        const history = createBrowserHistory();
        if (history.location.pathname !== "/login") {
          snackActions.error(translations.noAccessGeneric[lang]);
        }
        console.error(
          `Missing privileges ${res.status}: ${body.error?.message}`,
          { body: body }
        );
        return { data: body, isOk: res.ok, statusCode: res.status };
      } else {
        console.error(
          `Request to backend returned an error ${res.status}: ${body.error?.message}`,
          { body: body }
        );
        //will do a check if the bad request is because the version is too low, otherwise return the bad request
        if (res.status === 400 && body?.error?.includes("Version update needed")) {
          snackActions.warning(translations.versionUpdateNeeded[lang], 100000);
          setTimeout(function () { window.location.reload(); }, 10000);
        }
        return { data: body, isOk: res.ok, statusCode: res.status };
      }
    }
    return { data: body, isOk: res.ok, statusCode: res.status };
  } catch (err) {
    console.error("Request to backend failed", {
      err: err,
    });
    return {
      data: null, isOk: false, statusCode: 500
    };
  }
}

export default {
  // as there are so many components with their own error handling autoErrorHandling must be set to false for now
  get: async (path: string, autoErrorHandling = false) => {
    return apiRequest(path, "GET", null, autoErrorHandling, null);
  },

  post: async (path: string, payload: any, autoErrorHandling = false) => {
    return apiRequest(path, "POST", payload, autoErrorHandling, null);
  },

  put: async (path: string, payload: any, autoErrorHandling = false) => {
    return apiRequest(path, "PUT", payload, autoErrorHandling, null);
  },

  delete: async (path: string, payload: any = null, autoErrorHandling = false) => {
    return apiRequest(path, "DELETE", payload, autoErrorHandling, null);
  },

  fetch: async (path: string, method: string, payload: any = null) => {
    const version = `${versionFile.major}.${versionFile.minor}`;
    const sessionId = new User(
      JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {}
    ).encodedSessionId;
    return await fetch(`${API_URL}${path}`, {
      method: method,
      headers: {
        Authorization: `Bearer ${sessionId}`,
        'Content-Type': 'application/json',
        Pragma: "no-cache",
        "version": version,
      },
      body: JSON.stringify(payload),
    });
  },

  getBlob: async (path: string) => {
    const sessionId = new User(
      JSON.parse(localStorage.getItem(localStorageKeys.USER) ?? '{}') ?? {} ).encodedSessionId;
    
      const version = `${versionFile.major}.${versionFile.minor}`;
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      responseType: "blob",
      Pragma: "no-cache",
      Authorization: `Bearer ${sessionId}`,
      "version": version,
    });

    try{
      const res = await fetch(`${API_URL}${path}`, {
        method: 'GET',
        headers: headers
      });
      return res.blob();
    }
    catch(err){
      console.error("Request to backend failed", { err: err });
    }
  }
};

