import React, { useState } from 'react';
import { TextField, Checkbox } from '@material-ui/core';
import {
  Autocomplete as MaterialUIAutocomplete,
  AutocompleteRenderInputParams,
} from '@material-ui/lab';

import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';

interface IProps {
  id?: string;
  value?: IGroupOption | null;
  values?: IGroupOption[];
  label: string;
  items: IGroupOption[];
  onChange: (value: unknown) => void;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  className?: string;
  inputValue?: string;
  onInputChange?: (text: string) => void;
  checkBox?: boolean;
}

export interface IGroupOption {
  value: string;
  label: string;
  filter: string;
}

const AutocompleteGroup: React.FC<IProps> = ({
  id,
  value,
  values,
  label,
  items,
  onChange,
  error,
  disabled,
  required,
  fullWidth,
  className,
  inputValue = '',
  onInputChange,
  checkBox,
}) => {
  const [searchText, setSearchText] = useState(inputValue);

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" color="primary" />;

  if (values) {
    if (checkBox) {
      return renderCheckBox();
    }
    return (
      <MaterialUIAutocomplete
        id={id}
        multiple
        disableCloseOnSelect
        value={values}
        options={items}
        disabled={disabled}
        className={className}
        style={fullWidth ? { width: '100%' } : {}}
        onChange={(_, val) => {
          onChange(val);
          updateSearch('');
        }}
        getOptionLabel={(opt) => opt.label}
        getOptionSelected={(opt, val) => val.value === opt.value}
        groupBy={(option) => option.filter}
        renderOption={(opt) => opt.label}
        renderInput={renderInput}
        inputValue={searchText}
      />
    );
  }
  if (checkBox) {
    return renderCheckBox();
  }
  return (
    <MaterialUIAutocomplete
      id={id}
      value={value}
      options={items}
      disabled={disabled}
      className={className}
      style={fullWidth ? { width: '100%' } : {}}
      onChange={(_, val) => onChange(val)}
      getOptionLabel={(opt) => opt.label}
      getOptionSelected={(opt, val) => val.value === opt.value}
      groupBy={(option) => option.filter}
      renderOption={(opt) => opt.label}
      renderInput={renderInput}
    />
  );

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <TextField
        {...params}
        onChange={(e) => !!values && updateSearch(e.target.value)}
        variant="outlined"
        label={`${label}${required ? ' *' : ''}`}
        error={error}
        disabled={disabled}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'off',
          form: {
            autoComplete: 'off',
          },
        }}
      />
    );
  }

  function renderCheckBox() {
    return (
      <MaterialUIAutocomplete
        id={id}
        multiple
        disableCloseOnSelect
        value={values}
        options={items}
        disabled={disabled}
        className={className}
        style={fullWidth ? { width: '100%' } : {}}
        onChange={(_, val) => {
          onChange(val);
          updateSearch('');
        }}
        getOptionLabel={(opt) => opt.label}
        getOptionSelected={(opt, val) => val.value === opt.value}
        renderOption={(
          option: IGroupOption,
          { selected }: { selected: boolean }
        ) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </React.Fragment>
        )}
        renderInput={renderInput}
        inputValue={searchText}
      />
    );
  }

  function updateSearch(text: string) {
    setSearchText(text);
    if (onInputChange) {
      onInputChange(text);
    }
  }
};

export default AutocompleteGroup;
