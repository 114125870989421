import React, { useContext, useState, useEffect, useRef } from 'react';
import { TextField } from '@material-ui/core';

import { Passenger } from '../models/Models';
import EditButton from './EditButton';
import ConfirmDialog from './ConfirmDialog';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

interface IProps {
  passenger: Passenger;
  editable?: boolean;
  updateComment?: (comment: string) => Promise<void>;
}

const MAX_COMMENT_LENGTH = 2000;
const MAX_COMMENT_DISPLAY_LENGTH = 300;

const PassengerInfoComment: React.FC<IProps> = ({
  passenger,
  editable = true,
  updateComment,
}) => {
  const [lang] = useContext(LangContext);
  const commentInputRef = useRef<HTMLInputElement>(null);

  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [loadingCommentUpdate, setLoadingCommentUpdate] = useState(false);
  const [commentText, setCommentText] = useState('');

  useEffect(() => {
    if (!loadingCommentUpdate) {
      setCommentText(passenger.travelDetails || '');
    }
    if (commentDialogOpen) {
      setTimeout(focusCommentInput, 0);
    }
  }, [passenger, commentDialogOpen, loadingCommentUpdate]);

  return (
    <>
      {renderCommentInfo()}
      {renderCommentDialog()}
    </>
  );

  function renderCommentInfo() {
    if (!updateComment || !editable) {
      return passenger.travelDetails || translations.noComment[lang];
    }

    if (!passenger.travelDetails) {
      return (
        <span
          onClick={() => setCommentDialogOpen(true)}
          style={{ cursor: 'pointer' }}
        >
          {translations.addComment[lang]}
          <EditButton onClick={() => setCommentDialogOpen(true)} />
        </span>
      );
    }

    const currentComment = passenger.travelDetails;
    const displayComment =
      currentComment.length > MAX_COMMENT_DISPLAY_LENGTH
        ? `${currentComment.slice(0, MAX_COMMENT_DISPLAY_LENGTH)}...`
        : currentComment || translations.addComment[lang];

    return (
      <>
        {displayComment}
        <EditButton onClick={() => setCommentDialogOpen(true)} />
      </>
    );
  }

  function renderCommentDialog() {
    return updateComment ? (
      <ConfirmDialog
        open={commentDialogOpen}
        loading={loadingCommentUpdate}
        title={translations.comment[lang]}
        onConfirm={async () => {
          setLoadingCommentUpdate(true);
          await updateComment(commentText);
          setCommentDialogOpen(false);
          setLoadingCommentUpdate(false);
        }}
        onCancel={() => setCommentDialogOpen(false)}
        confirmText={translations.save[lang]}
        disableConfirm={commentText === (passenger.travelDetails || '')}
      >
        <TextField
          fullWidth
          autoFocus
          autoComplete="off"
          id="travelDetails"
          inputProps={{
            maxLength: MAX_COMMENT_LENGTH,
            ref: commentInputRef,
          }}
          multiline
          rows={10}
          variant="outlined"
          value={commentText}
          disabled={loadingCommentUpdate}
          placeholder={translations.comment[lang]}
          onChange={(e) => setCommentText(e.target.value)}
        />
      </ConfirmDialog>
    ) : null;
  }

  function focusCommentInput() {
    if (commentInputRef && commentInputRef.current) {
      commentInputRef.current.focus();
      commentInputRef.current.selectionStart = MAX_COMMENT_LENGTH;
    }
  }
};

export default PassengerInfoComment;
