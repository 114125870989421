import React, { useContext } from 'react';
import { makeStyles, IconButton } from '@material-ui/core';

import AssignmentIcon from '@material-ui/icons/Assignment';
import CopyToClipboard from 'react-copy-to-clipboard';
import useNotifier from '../hooks/useNotifier';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

interface IProps {
  text: string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(0),
    marginLeft: theme.spacing(1),
  },
  buttonIcon: {
    fontSize: 22,
  },
}));

const CopyButton: React.FC<IProps> = ({ text }) => {
  const classes = useStyles();
  const [lang] = useContext(LangContext);
  const { notifySuccess } = useNotifier();

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => notifySuccess(translations.copiedToClipboard[lang])}
    >
      <IconButton className={classes.button} color="primary">
        <AssignmentIcon className={classes.buttonIcon} />
      </IconButton>
    </CopyToClipboard>
  );
};

export default CopyButton;
