import React, { useContext } from 'react';
import { Typography, makeStyles, Grid, Paper } from '@material-ui/core';
import PortableWifiOffIcon from '@material-ui/icons/PortableWifiOff';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import cx from 'classnames';

import {
  IPassengerDetails,
  IUpdatePassengerInfo,
} from '../interfaces/passenger';
import { Passenger } from '../models/Models';
import Button from './Button';
import Print from './Print';
import EditPassengerInfo from './EditPassengerInfo';
import SendToQuarantineHouse from './SendToQuarantineHouse';
import { PosContext } from '../context/PosContext';
import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';

interface IProps {
  passenger: Passenger;
  passengerDetails?: IPassengerDetails[];
  groupedDetails?: IPassengerDetails[][];
  isPosConnected: boolean | null;
  buttons?: IButton[] | null;
  print?: boolean;
  linkedSampling?: {
    isFirst: boolean;
    onClick: () => void;
  };
  updateInfo?: (
    info: IUpdatePassengerInfo,
    onSuccess: () => void
  ) => Promise<void>;
  setQuarantineHouseStatus?: (
    registered: boolean,
    onSuccess: () => void
  ) => Promise<void>;
}

export interface IButton {
  label: string;
  onClick: () => void;
  variant?: 'contained' | 'outlined';
  error?: boolean;
  icon?: () => React.ReactNode;
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  descriptionTitle: {
    display: 'inline-block',
    fontWeight: 'bold',
    paddingRight: 10,
  },
  descriptionDetails: {
    display: 'inline-block',
  },
  buttonGrid: {
    textAlign: 'right',
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  topItems: {
    marginBottom: theme.spacing(1),
  },
  linkedItem: {
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(1),
    borderRadius: 20,
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  linkedSampling: {
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  topButtonContainer: {
    marginLeft: theme.spacing(2),
  },
}));

const PassengerInfo: React.FC<IProps> = ({
  passenger,
  passengerDetails = [],
  groupedDetails,
  isPosConnected,
  buttons,
  print,
  linkedSampling,
  updateInfo,
  setQuarantineHouseStatus,
}) => {
  const classes = useStyles({});
  const [lang] = useContext(LangContext);
  const [shouldHavePos] = useContext(PosContext);

  return (
    <Paper elevation={2} className={classes.container}>
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={classes.topItems}
      >
        {renderLinkedSampling()}
        <Grid item>
          {shouldHavePos && isPosConnected !== null ? (
            isPosConnected ? (
              <WifiTetheringIcon color="primary" />
            ) : (
              <PortableWifiOffIcon color="disabled" />
            )
          ) : null}
        </Grid>
        {setQuarantineHouseStatus && (
          <Grid item className={classes.topButtonContainer}>
            <SendToQuarantineHouse
              passenger={passenger}
              setQuarantineHouseStatus={setQuarantineHouseStatus}
            />
          </Grid>
        )}
        {updateInfo && (
          <Grid item className={classes.topButtonContainer}>
            <EditPassengerInfo passenger={passenger} updateInfo={updateInfo} />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={groupedDetails ? 3 : 2}>
            {groupedDetails
              ? groupedDetails.map(renderGroupedInfo)
              : passengerDetails.map(renderInfoItem)}
          </Grid>
        </Grid>
        {buttons && (
          <Grid item xs={12} sm={12} className={classes.buttonGrid}>
            {print && <Print passenger={passenger}></Print>}
            <Grid container spacing={2} justify="flex-end">
              {buttons && buttons.map(renderButton)}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );

  function renderGroupedInfo(groupedInfo: IPassengerDetails[], index: number) {
    return (
      <Grid item xs={12} key={index}>
        <Grid container spacing={1}>
          {groupedInfo.map(renderInfoItem)}
        </Grid>
      </Grid>
    );
  }

  function renderInfoItem(
    { label, render, getVal = () => '', hide }: IPassengerDetails,
    index: number
  ) {
    return hide && hide(passenger) ? null : (
      <Grid item xs={12} key={index}>
        <Typography className={classes.descriptionTitle}>{label}:</Typography>
        {render ? (
          render(passenger)
        ) : (
          <Typography
            className={classes.descriptionDetails}
            color="textSecondary"
          >
            {getVal(passenger)}
          </Typography>
        )}
      </Grid>
    );
  }

  function renderLinkedSampling() {
    if (!linkedSampling) {
      return null;
    }

    const renderLinkedItem = (isLink: boolean, text: string) => {
      return (
        <Grid item>
          <div
            onClick={() => isLink && linkedSampling.onClick()}
            className={cx(classes.linkedItem, {
              [classes.linkedSampling]: isLink,
            })}
          >
            <Typography variant="body2">{text}</Typography>
          </div>
        </Grid>
      );
    };

    return (
      <React.Fragment>
        {renderLinkedItem(
          !linkedSampling.isFirst,
          `${translations.sampling[lang]} 1`
        )}
        {renderLinkedItem(
          linkedSampling.isFirst,
          `${translations.sampling[lang]} 2`
        )}
      </React.Fragment>
    );
  }

  function renderButton(button: IButton, index: number) {
    if (buttons && buttons.length > 2) {
      return (
        <Grid item xs key={index} style={button.style}>
          <Button
            onClick={button.onClick}
            variant={button.variant || 'contained'}
            error={button.error}
            color={button.color || 'primary'}
            disabled={button.disabled}
            fullWidth
          >
            {button.icon && button.icon()}
            {button.label}
          </Button>
        </Grid>
      );
    }
    return (
      <Grid item key={index} style={button.style}>
        <Button
          onClick={button.onClick}
          variant={button.variant || 'contained'}
          error={button.error}
          color={button.color || 'primary'}
          disabled={button.disabled}
          fullWidth
        >
          {button.icon && button.icon()}
          {button.label}
        </Button>
      </Grid>
    );
  }
};

export default PassengerInfo;
