import React, { useContext, useState, useEffect } from 'react';
import { ButtonGroup, Grid, TextField } from '@material-ui/core';

import { LangContext } from '../context/LangContext';
import translations from '../assets/json/translations.json';
import { UserContext } from '../context/UserContext';
import { VaccinationGroup } from '../models/VaccinationModels';
import Autocomplete, { IOption } from './Autocomplete';
import ConfirmDialog from './ConfirmDialog';
import * as vaccinationService from '../services/vaccinationService';
import Dropdown from './Dropdown';
import Button from './Button';

interface IProps {
  open: boolean;
  onConfirm: (
    isExistingGroup: boolean,
    selectedGroup: string,
    groupName: string,
    groupDescription: string,
    regionId: string
  ) => Promise<void>;
  onCancel: () => void;
  selectedFilterRegion?: string;
}

const WaitingListGroupDialog: React.FC<IProps> = ({
  open,
  onConfirm,
  onCancel,
  selectedFilterRegion,
  ...props
}) => {
  const [lang] = useContext(LangContext);
  const [user] = useContext(UserContext);

  const hasOnlyOneRegion = user?.vaccinationRegionModelList?.length === 1;

  const [groupOptions, setGroupOptions] = useState<VaccinationGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<IOption | null>(null);
  const [regionOptions, setRegionOptions] = useState<IOption[]>([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [newGroupTitle, setNewGroupTitle] = useState('');
  const [newGroupDescription, setNewGroupDescription] = useState('');
  const [isExistingGroup, setIsExistingGroup] = useState(true);

  useEffect(() => {
    if (user?.vaccinationRegionModelList) {
      setRegionOptions(
        user.vaccinationRegionModelList.map((r: any) => ({
          label: r.description || '',
          value: r.id ? r.id.toString() : '',
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (open && selectedFilterRegion) {
      const region = user?.vaccinationRegionModelList.find(
        (item: any) => item.id.toString() === selectedFilterRegion
      );
      if (region) {
        setSelectedRegion(region.id.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterRegion, open]);

  useEffect(() => {
    if (hasOnlyOneRegion && !selectedRegion) {
      const value = user.vaccinationRegionModelList[0].id.toString();

      vaccinationService.getGroups(value).then((response) => {
        if (response.isOk) {
          setGroupOptions(response.data || []);
        }
      });
    } else if (selectedRegion) {
      vaccinationService.getGroups(selectedRegion).then((response) => {
        if (response.isOk) {
          setSelectedGroup(null);
          setGroupOptions(response.data || []);
        }
      });
    }
  }, [user, selectedRegion, hasOnlyOneRegion]);

  return (
    <ConfirmDialog
      open={open}
      title={translations.moveFromWaitingListToGroup[lang]}
      onConfirm={() => {
        onSubmit();
      }}
      onCancel={() => close()}
      disableConfirm={disableSubmit()}
    >
      {renderGroupDialogContent()}
    </ConfirmDialog>
  );

  function renderGroupDialogContent() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ width: '100%' }}>
          <ButtonGroup
            disableElevation
            color="primary"
            style={{ width: '100%' }}
          >
            <Button
              style={{ flex: 1 }}
              variant={isExistingGroup ? 'contained' : 'outlined'}
              onClick={() => setIsExistingGroup(true)}
              padding={false}
            >
              {translations.moveToGroup[lang]}
            </Button>
            <Button
              style={{ flex: 1 }}
              variant={isExistingGroup ? 'outlined' : 'contained'}
              onClick={() => setIsExistingGroup(false)}
              padding={false}
            >
              {translations.moveToNewGroup[lang]}
            </Button>
          </ButtonGroup>
        </Grid>
        {isExistingGroup && renderExistingGroupForm()}
        {!isExistingGroup && renderNewGroupForm()}
      </Grid>
    );
  }

  function renderExistingGroupForm() {
    return (
      <>
        <Grid item xs={12} style={{ width: '100%' }}>
          <Dropdown
            autoFocus
            fullWidth
            id="regions"
            value={selectedRegion}
            label={translations.region[lang]}
            items={regionOptions}
            onChange={(e) => setSelectedRegion(e.target.value as string)}
            disabled={hasOnlyOneRegion}
          />
        </Grid>
        <Grid item xs={12} style={{ width: '100%' }}>
          <Autocomplete
            fullWidth
            id="group"
            label={translations.groupsLabel[lang]}
            onChange={(selectedItem: any) => setSelectedGroup(selectedItem)}
            value={selectedGroup}
            items={groupOptions.map((item) => ({
              value: item.id.toString(),
              label: item.name,
            }))}
            disabled={!selectedRegion}
          />
        </Grid>
      </>
    );
  }

  function renderNewGroupForm() {
    return (
      <>
        <Grid item xs={12} style={{ width: '100%' }}>
          <Dropdown
            fullWidth
            id="regions"
            value={selectedRegion}
            label={translations.region[lang]}
            items={regionOptions}
            onChange={(e) => setSelectedRegion(e.target.value as string)}
            disabled={hasOnlyOneRegion}
          />
        </Grid>
        <Grid item xs={12} style={{ width: '100%' }}>
          <TextField
            fullWidth
            required
            autoFocus
            autoComplete="off"
            id="groupName"
            label={translations.groupName[lang]}
            placeholder={translations.nameMustBeDescriptive[lang]}
            variant="outlined"
            value={newGroupTitle}
            //error={!!errors.groupName && !!submitCount}
            onChange={(e) => setNewGroupTitle(e.target.value as string)}
          />
        </Grid>
        <Grid item xs={12} style={{ width: '100%' }}>
          <TextField
            fullWidth
            autoComplete="off"
            id="description"
            label={translations.description[lang]}
            variant="outlined"
            value={newGroupDescription}
            onChange={(e) => setNewGroupDescription(e.target.value as string)}
          />
        </Grid>
      </>
    );
  }

  function disableSubmit() {
    if (isExistingGroup && selectedGroup) {
      return false;
    }
    if (!isExistingGroup && selectedRegion && newGroupTitle) {
      return false;
    }
    return true;
  }

  async function onSubmit() {
    await onConfirm(
      isExistingGroup,
      selectedGroup?.value || '',
      newGroupTitle,
      newGroupDescription,
      selectedRegion
    );
    setSelectedGroup(null);
    setSelectedRegion('');
  }

  function close() {
    onCancel();
    setSelectedGroup(null);
    setSelectedRegion('');
  }
};

export default WaitingListGroupDialog;
